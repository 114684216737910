import { withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/AddCircle';
import Search from '@material-ui/icons/Search';
import classNames from 'classnames';
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import getFilterCategory from '../FilterCategories/';
import { FVM_BASE_URL, getUserLang } from '../../wp-rest-api/STEC-Communication';

const drawerWidth = 280 + 340;
const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth * theme.fvm.zoomFactor,
    width: `calc(100% - ${drawerWidth * theme.fvm.zoomFactor}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    // width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth * theme.fvm.zoomFactor,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  left: {
    marginLeft: `${340 * theme.fvm.zoomFactor}px`,
  },
  right: {
    marginRight: `${340 * theme.fvm.zoomFactor}px`,
  },
  drawerClose: {
    // width: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6),

    display: 'none',
  },
  content: {
    flexGrow: 1,
  },
  listItemText: {
    color: 'inherit',
    fontSize: theme.typography.fontSize,
    fontWeight: 'inherit',
    fontFamily: 'inherit',
    lineHeight: 'inherit',
    padding: 0,
  },
  addPlacemarker: {
    backgroundColor: '#1cdd43',
    '&:hover': {
      backgroundColor: '#1cdd43',
    },
    '&:focus': {
      backgroundColor: '#1cdd43',
    },
  },
  paperAnchorLeft: {
    marginLeft: `${340 * theme.fvm.zoomFactor}px`,
  },
  paperAnchorRight: {
    marginRight: `${340 * theme.fvm.zoomFactor}px`,
  },
});

class SecondaryFilterDrawers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      searchField: '',
    };

    this.handleDrawerClose = this.handleDrawerClose.bind(this);
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { open } = this.props;

    if (open !== this.state.open) {
      this.setState({ open });
    }
  }

  handleDrawerClose() {
    this.setState({ open: false });
    this.props.handleDrawerClose(false);
  }

  render() {
    const { open } = this.state;
    const { right, ListItems, classes, searchTerm, t, loadNextPage, fullHightScroll, foundItemCount } = this.props;
    const scrollSetting = {
      // This is important field to render the next data
      dataLength: ListItems.length,
      next: () => {
        loadNextPage();
      },
      // scrollThreshold: "400px",
      // hasMore: ListItems.length < 5000,
      hasMore: ListItems.length < foundItemCount,
      loader: <div style={{ textAlign: 'center' }}><h4>{t('messages.Loading')}...</h4></div>,
      endMessage: (
        <p style={{ textAlign: 'center' }}>
          {/*<b>End of list</b>*/}
        </p>),
      height: `calc(${fullHightScroll ? '100' : '65'}vh - 60px)`,
    };

    return (
      <Drawer
        className={classNames(classes.drawer, 'fvm-map-drawer', 'fvm-map-drawer-second_Layer', right ? 'right' : 'left')}
        classes={{
          paper: classNames({
            [classes.drawerClose]: !open,
            [classes.drawerOpen]: open,
          }),
        }}
        variant="persistent"
        anchor={right ? 'right' : 'left'}
        open={open}
      >
        <span
          className={
            classNames('fvm-filter-controls', {
              [classes.right]: right,
              [classes.left]: !right,
            })}
        >
          <span className="filter-search-field">
            <input
              // placeholder="PLZ oder Ort"
              placeholder={t('placeHolders.search')}
              type="search"
              value={searchTerm}
              onChange={this.props.onSearchTermChanged}
            />
          </span>
          <IconButton className="filter-search-icon" aria-label="Search">
            <Search/>
          </IconButton>
        </span>
        <Divider className="fvm-filter-list-divider"/>
        <InfiniteScroll
          className={
            classNames({
              [classes.right]: right,
              [classes.left]: !right,
            })
          }
          {...scrollSetting}
        >
          <List
            subheader={<div/>}
            className="fvm-filter-list-items"
          >
            <ListItem
              button
              // className={classNames(classes.addPlacemarker)}
              key="added-missing-train"
              onClick={() => {
                window.open(`${FVM_BASE_URL}${getUserLang() === 'de' ? '' : '/en'}/add-place-marker/`, '_blank');
              }}
            >
              <ListItemIcon className="fvm-filter-item-icon">
                <AddIcon style={{ fontSize: 24 }} color="primary" aria-label="Add"/>
              </ListItemIcon>
              <ListItemText
                classes={{
                  root: classes.listItemText,
                }}
                className="fvm-filter-item-body"
                primary={t('button.addNewPlaceMarker')}
                // primary="Add new marker"
              />

            </ListItem>
            {ListItems.map((results, index) =>
              <ListItem
                button
                key={`${results.name}-${results.id}`}
                onClick={() => this.props.handleItemSelected(results)}
              >
                <ListItemIcon className="fvm-filter-item-icon">
                  {getFilterCategory(this.props.catNames[results.categories]).secondary}
                </ListItemIcon>
                {
                  this.props.catNames[results.categories] === 'ships'
                    ? (
                      <ListItemText
                        classes={{
                          root: classes.listItemText,
                        }}
                        className="fvm-filter-item-body"
                        primary={results.name}
                        secondary={
                          <React.Fragment>
                            <Typography component="span" color="textPrimary">
                      <span className="fvm-sub-filter-item-category-name">
                        {t(`railationCategories.${this.props.catNames[results.categories]}`)}
                      </span>
                            </Typography>
                            {/*{results.contact.street}*/}
                            {/*<br />*/}
                            {/*{results.contact.postcode} {results.contact.city}*/}
                          </React.Fragment>
                        }
                      />
                    )

                    : (
                      <ListItemText
                        className="fvm-filter-item-body"
                        classes={{
                          root: classes.listItemText,
                        }}
                        primary={results.name}
                        secondary={
                          <React.Fragment>
                            <Typography component="span" color="textPrimary">
                      <span className="fvm-sub-filter-item-category-name">
                        {t(`railationCategories.${this.props.catNames[results.categories]}`)}
                      </span>
                            </Typography>
                            {results.contact.street}
                            <br/>
                            {results.contact.postcode} {results.contact.city}
                          </React.Fragment>
                        }
                      />
                    )
                }

              </ListItem>,
            )}
          </List>
        </InfiniteScroll>
      </Drawer>
    );
  }
}

SecondaryFilterDrawers.propTypes = {};

export default withStyles(styles, { withTheme: true })(SecondaryFilterDrawers);
