import React from 'react';
const Finance = () => {
  const a = {
    fill: '#002fc9',
  }
  const b = {
    fill: '#002fc9',
    opacity: 0,
  }
  const c = {
    fill: '#fff',
    stroke: 'stroke:rgba(0,0,0,0)',
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.999" height="33.999" viewBox="0 0 33.999 33.999">
      {/*<style>.a,.b{fill:#002fc9;}.b{opacity:0;}.c{fill:#fff;stroke:rgba(0,0,0,0);}</style>*/}
      <g transform="translate(-726 -544)">
        <g transform="translate(635 461.999)">
          <rect style={a} width="33.999" height="33.999" rx="5" transform="translate(91 82.001)" />
        </g>
        <g transform="translate(-300)">
          <rect style={{ ...a, ...b }} width="24" height="24" transform="translate(1031 549)" />
          <path
            style={c}
            d="M-1537,468a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-6,6A6,6,0,0,1-1537,468Zm2.25-.45v.9a.3.3,0,0,0,.3.3h.561c.049.275.1.466.1.466a3.378,3.378,0,0,0,3.537,2.534,4.666,4.666,0,0,0,1.329-.169.3.3,0,0,0,.2-.363l-.141-.548a.3.3,0,0,0-.142-.186.294.294,0,0,0-.233-.027,3.328,3.328,0,0,1-.945.131,1.856,1.856,0,0,1-1.922-1.4,3.486,3.486,0,0,1-.109-.439h3.162a.3.3,0,0,0,.3-.3v-.9a.3.3,0,0,0-.3-.3h-3.144a3.775,3.775,0,0,1,.091-.37,1.916,1.916,0,0,1,1.922-1.471,3.283,3.283,0,0,1,.944.132.3.3,0,0,0,.233-.026.3.3,0,0,0,.143-.186l.141-.55a.3.3,0,0,0-.2-.361,4.666,4.666,0,0,0-1.328-.168,3.334,3.334,0,0,0-3.537,2.479,5.29,5.29,0,0,0-.111.521h-.552A.3.3,0,0,0-1534.75,467.55ZM-1545,462.5a8.5,8.5,0,0,1,8.5-8.5,8.48,8.48,0,0,1,8.1,6h-2.118a6.489,6.489,0,0,0-5.983-4,6.507,6.507,0,0,0-6.5,6.5,6.489,6.489,0,0,0,4,5.983V470.6A8.48,8.48,0,0,1-1545,462.5Zm8,.5h-3v-1h3v-3h1v4Z"
            transform="translate(2578 97)"
          />
        </g>
      </g>
    </svg>
  );
};
export default Finance;
