import { decode } from 'jwt-simple';
import {
  deleteLogbookEntry,
  finaliseEntry,
  getLogbookEntries,
  getLogbookEntryById,
  saveLogbookEntry,
  updateLogbookEntry,
} from '../../../apis/logbook-apis';
import lsChangeDetector from 'js-localstorage-change-detector';
import { store } from '../../../index';
import { setHasOpenLogbookEntries, setLogbookEntriesSyncedTimestamp } from '../../../actions';

let userId = null;
let userOrganisations = [];
let syncInProgress = false;


export const decodeUnsavedEntries = (key = userId) => {
  if (!userId) {
    console.error(`decodeUnsavedEntries => Missing required data:: ${JSON.stringify({
      userId,
    }, null, 2)}`);
    return {};
  }

  let previousUnsavedItems = {};

  if (localStorage.getItem('unsavedLogbookItems')) {
    try {
      // previousUnsavedItems = decode(localStorage.getItem('unsavedLogbookItems'), key);
      const foundItems = JSON.parse(localStorage.getItem('unsavedLogbookItems'));
      if (foundItems?.hasOwnProperty(userId)) {
        previousUnsavedItems = foundItems[userId];
      }
    } catch (e) {
      console.log(e);
      previousUnsavedItems = {};
    }
  }
  return previousUnsavedItems;
};

export const decodeLogbookEntriesDeletionList = (key = userId) => {
  if (!userId) {
    console.error(`decodeLogbookEntriesDeletionList => Missing required data:: ${JSON.stringify({
      userId,
    }, null, 2)}`);
    return;
  }

  let previousItemsToDelete = {};
  if (localStorage.getItem('logbookItemsToDelete')) {
    try {
      // previousItemsToDelete = decode(localStorage.getItem('logbookItemsToDelete'), key);
      previousItemsToDelete = JSON.parse(localStorage.getItem('logbookItemsToDelete'));
    } catch (e) {
      console.log(e);
      previousItemsToDelete = {};
    }
  }
  if (previousItemsToDelete[userId] && typeof previousItemsToDelete[userId] === 'object') {
    return previousItemsToDelete[userId];
  }
  return {};
};

export const getCollectionByOrganisationId = (organisationId, vehicleRefId = null) => {
  if (!organisationId) {
    console.error('missing organisationId');
    return;
  }

  const locallyStoredEntriesByOrg = decodeUnsavedEntries();
  if (organisationId && vehicleRefId) {
    let entriesMap = {};
    Object
      .values(locallyStoredEntriesByOrg?.[organisationId]?.[vehicleRefId] || {})
      .forEach((entry) => {
        entriesMap[entry.header.logbookEntryId] = entry;
      });
    return entriesMap;
  }

  return Object
    .values(locallyStoredEntriesByOrg?.[organisationId] || {})
    .reduce((combinedLst, newItems) => ({
      ...combinedLst,
      ...newItems,
    }), {});
};


export const markLogbookEntriesForDeletion = (organisationId, entriesToDelete, callback = () => {
}) => {

  if (!organisationId || !entriesToDelete) {
    console.error(`markLogbookEntriesForDeletion => Missing required data:: ${JSON.stringify({
      organisationId,
      entriesToDelete,
    }, null, 2)}`);
    return;
  }

  if (!Array.isArray(entriesToDelete) && typeof entriesToDelete !== 'string') {
    console.error('entriesToDelete => must be a string of logbookEntryIds seperated by commas ","  or an array of' +
      ' logbookEntryIds');
    return;
  }

  const locallySavedEntries = decodeUnsavedEntries();
  const allEntriesBelongingToTheOrganisation = getCollectionByOrganisationId(organisationId);
  const entriesToDeleteFromServer = decodeLogbookEntriesDeletionList();

  if (!entriesToDeleteFromServer[organisationId]) {
    entriesToDeleteFromServer[organisationId] = {};
  }


  (typeof entriesToDelete === 'string' ? entriesToDelete.split(',') : entriesToDelete)
    .forEach((logbookEntryToDeleteId) => {
      const tempEntry = allEntriesBelongingToTheOrganisation[logbookEntryToDeleteId];
      if (tempEntry?.header?.vehicleIdRef) {
        delete locallySavedEntries[organisationId][tempEntry?.header?.vehicleIdRef][tempEntry?.header?.logbookEntryId];
      }

      entriesToDeleteFromServer[organisationId][logbookEntryToDeleteId] = true;
    });

  saveDataToLocalStorage('unsavedLogbookItems', locallySavedEntries);
  saveDataToLocalStorage('logbookItemsToDelete', entriesToDeleteFromServer);
  if (navigator.onLine) {
    deleteEntriesFromDb(callback);
  } else {
    if (typeof callback === 'function') {
      callback('only_deleted_locally');
    }
  }
};


const saveDataToLocalStorage = (localStorageKey, logBookEntriesJson, encryptionKey) => {
  // const encodedEntry = encode(previousUnsavedItems, userId);
  if (!localStorageKey || !logBookEntriesJson || !userId) {
    console.error(`Missing required data:: ${JSON.stringify({
      userId,
      logBookEntriesJson,
      localStorageKey,
    }, null, 2)}`);
    return;
  }

  const newEntriesObj = JSON.parse(JSON.stringify(logBookEntriesJson));
  cleanUpObject(newEntriesObj, ['inspections', 'vehicleState']);
  let foundItems = {};

  if (localStorage.getItem(localStorageKey)) {
    try {
      foundItems = JSON.parse(localStorage.getItem(localStorageKey));
      if (typeof foundItems !== 'object') {
        foundItems = {};
      }
    } catch (e) {
      console.log(e);
      foundItems = {};
    }
  }

  //update entries for the user or delete them
  if (Object.keys(newEntriesObj).length) {
    foundItems[userId] = newEntriesObj;
  } else {
    delete foundItems[userId];
  }

  // Save the updated entries to the general Obj or remove the localStorage item if empty
  if (Object.keys(foundItems).length) {
    const entriesStrJson = JSON.stringify(foundItems);
    localStorage.setItem(localStorageKey, entriesStrJson);
  } else {
    localStorage.removeItem(localStorageKey);
  }

  store.dispatch(setHasOpenLogbookEntries());
};
const sleep = ms => new Promise(r => setTimeout(r, ms));
export const addEntriesToLocalStorage = async ({ organisationId, logbookEntry }) => {
  if (!userId || !logbookEntry?.header?.logbookEntryId || !logbookEntry?.header?.vehicleIdRef) {
    console.error(`Missing required data:: ${JSON.stringify({
      userId,
      logbookEntryId: logbookEntry?.header?.logbookEntryId,
    }, null, 2)}`);
    return false;
  }

  let waitTime = 0;
  const isOnline = navigator.onLine;
  console.log({ isOnline, syncInProgress, waitTime });
  while (isOnline && syncInProgress && waitTime < 10) {
    console.log('waiting to add/update entry :: ...');
    await sleep(1000);
    waitTime++;
  }

  const previousUnsavedItems = decodeUnsavedEntries(userId);

  if (!previousUnsavedItems[organisationId] || typeof previousUnsavedItems[organisationId] !== 'object') {
    previousUnsavedItems[organisationId] = {};
  }

  if (!previousUnsavedItems[organisationId][logbookEntry.header.vehicleIdRef] || typeof previousUnsavedItems[organisationId][logbookEntry.header.vehicleIdRef] !== 'object') {
    previousUnsavedItems[organisationId][logbookEntry.header.vehicleIdRef] = {};
  }

  previousUnsavedItems[organisationId][logbookEntry.header.vehicleIdRef][logbookEntry.header.logbookEntryId] = logbookEntry;
  saveDataToLocalStorage('unsavedLogbookItems', previousUnsavedItems);

  if (isOnline) {
    return syncLogbookEntries();
  }

  return 'saved_locally';
};

export const deleteSavedEntry = ({ organisationId, vehicleIdRef, logbookEntryId }) => {
  if (!userId || !logbookEntryId || !vehicleIdRef) {
    console.error(`deleteSavedEntry => Missing required data:: ${JSON.stringify({
      userId,
      vehicleIdRef,
      logbookEntryId,
    }, null, 2)}`);
    return;
  }
  let msg = 'Entry not found';
  const previousUnsavedItems = decodeUnsavedEntries(userId);

  if (previousUnsavedItems[organisationId]) {
    msg = 'organisationId not found';
  }
  if (previousUnsavedItems[organisationId][vehicleIdRef]) {
    msg = 'vehicleIdRef not found';
  }

  if (previousUnsavedItems[organisationId]?.[vehicleIdRef]?.[logbookEntryId]) {
    delete previousUnsavedItems[organisationId][vehicleIdRef][logbookEntryId];
    saveDataToLocalStorage('unsavedLogbookItems', previousUnsavedItems);
    msg = 'entry deleted';
  }

  console.log(msg);
  return msg;
};

const sanitizeForSave = (logbookEntry) => {
  const isNewEntry = (logbookEntry?.header?.status === 'newEntry');
  const clonedEntryStr = JSON.stringify(logbookEntry);
  const clonedEntry = JSON.parse(clonedEntryStr);
  const headerUpdateProperties = [
    'remarks',
    'referenceLocation',
  ];

  const headerSaveProperties = [
    'vehicleIdRef',
    'remarks',
    'referenceTimestamp',
    'referenceLocation',
  ];

  if (clonedEntry?.stoppage?.hasOwnProperty('isStoppage')) {
    delete clonedEntry?.stoppage.isStoppage;
  }
  if (clonedEntry?.hasOwnProperty('sendOptionalNotification')) {
    delete clonedEntry?.sendOptionalNotification;
  }

  if (clonedEntry?.defects?.hasOwnProperty('isDefect')) {
    delete clonedEntry?.defects.isDefect;
  }
  if (clonedEntry?.inspections?.hasOwnProperty('selectedV')) {
    delete clonedEntry.inspections.selectedV;
  }

  if (clonedEntry?.hasOwnProperty('checkRequired')) {
    delete clonedEntry.checkRequired;
  }

  if (clonedEntry?.hasOwnProperty('unknownEntry')) {
    delete clonedEntry.unknownEntry;
  }

  if (clonedEntry?.hasOwnProperty('isA1Required')) {
    delete clonedEntry.isA1Required;
  }

  if (clonedEntry?.hasOwnProperty('lastComment')) {
    delete clonedEntry.lastComment;
  }

  if (clonedEntry?.hasOwnProperty('isUnSynced')) {
    delete clonedEntry.isUnSynced;
  }

  if (clonedEntry?.hasOwnProperty('signatureImage')) {
    delete clonedEntry.signatureImage;
  }

  if (isNewEntry) {
    Object.keys(clonedEntry.header).forEach((headerProp) => {
      if (!headerSaveProperties.includes(headerProp)) {
        delete clonedEntry.header[headerProp];
      }
    });
  } else {
    Object.keys(clonedEntry.header).forEach((headerProp) => {
      if (!headerUpdateProperties.includes(headerProp)) {
        delete clonedEntry.header[headerProp];
      }
    });
  }

  return clonedEntry;
};

export const deleteEntriesFromDb = async (callback = () => {
}) => {
  const entriesToDeleteFromServer = decodeLogbookEntriesDeletionList();
  return Promise.all(Object.keys(entriesToDeleteFromServer).map((organisationId) => {
    return Promise.all(Object.keys(entriesToDeleteFromServer[organisationId]).map((logbookEntryId) => {
      if (logbookEntryId && organisationId) {
        return deleteLogbookEntry(organisationId, logbookEntryId)
          .then(({ data, ...rest }) => {
            delete entriesToDeleteFromServer[organisationId][logbookEntryId];
            return 'successfully_deleted';
          })
          .catch((err) => {
            const { data } = err.response;

            if (data?.resultCodes?.includes('err_unknown_entry')) {
              delete entriesToDeleteFromServer[organisationId][logbookEntryId];
              return 'successfully_deleted';
            }

            if (data?.resultCodes?.includes('err_unknown_organisation')) {
              delete entriesToDeleteFromServer[organisationId][logbookEntryId];
              return 'err_unknown_organisation';
            }

            if (err?.response?.status === 403) {
              delete entriesToDeleteFromServer[organisationId][logbookEntryId];
              return 'err_forbidden_request';
            }

            if (err?.response?.status === 401) {
              return 'new_login_required';
            }

            return 'err_unknown';
          });
      }
      return false;
    })).then((resp) => {
      if (typeof callback === 'function') {
        const errorEntries = resp.filter((msg) => msg.startsWith('err_'));

        if (errorEntries.length) {
          callback(errorEntries.length === 1 ? errorEntries[0] : 'err_multiple_error_occurred');
          return resp;
        }

        if (resp.length) {
          callback(resp[0]);
          return resp;
        }
      }

      return typeof callback === 'string' ? resp : 'unknown_resp';

    }).finally(() => {
      saveDataToLocalStorage('logbookItemsToDelete', entriesToDeleteFromServer);
    });
  }));
};
export const sendUnsavedEntriesToDb = async ({
  unsavedEntries = decodeUnsavedEntries(),
}) => {
  if (!userId) {
    return;
  }
  if (!navigator.onLine) {
    return 'fail to sync: no internet connection';
  }

  // const unsavedEntries = decodeUnsavedEntries();
  const listOfOrganisations = Object.keys(unsavedEntries);

  return Promise.all(listOfOrganisations.map(async (organisationId) => {
    const listOfLocoIds = Object.keys(unsavedEntries[organisationId]);
    const cleanUpEntry = (entryToRemove) => {
      return deleteSavedEntry({
        organisationId,
        vehicleIdRef: entryToRemove.header.vehicleIdRef,
        logbookEntryId: entryToRemove.header.logbookEntryId,
      });
    };

    return Promise.all(listOfLocoIds.map(async (localVehicleIdRef) => {
      Object
        .keys(unsavedEntries[organisationId][localVehicleIdRef])
        .filter((entryId) => {
          return unsavedEntries[organisationId][localVehicleIdRef][entryId].header.status === 'finalized' ||
            unsavedEntries[organisationId][localVehicleIdRef][entryId].unknownEntry;
        })
        .forEach((entryId) => {
          delete unsavedEntries[organisationId][localVehicleIdRef][entryId];
          // cleanUpEntry(unsavedEntries[organisationId][localVehicleIdRef][entryId]);
        });

      const entryIds = Object
        .keys(unsavedEntries[organisationId][localVehicleIdRef])
        // save only unSynced entries
        .filter((entryId) => unsavedEntries[organisationId][localVehicleIdRef][entryId].isUnSynced && unsavedEntries[organisationId][localVehicleIdRef][entryId].header.status !== 'finalized');

      return Promise.all(entryIds.map((entryId) => {
        const rawLogbookEntry = JSON.parse(JSON.stringify(unsavedEntries[organisationId][localVehicleIdRef][entryId]));
        const isNewEntry = rawLogbookEntry?.header?.status === 'newEntry';
        const apiToBeUsed = isNewEntry ? saveLogbookEntry : updateLogbookEntry;
        const logbookEntry = sanitizeForSave(rawLogbookEntry);


        const updateEntry = (updateEntryId = rawLogbookEntry?.header?.logbookEntryId, tempEntry = null) => {
          if (tempEntry && unsavedEntries[organisationId][localVehicleIdRef]) {
            unsavedEntries[organisationId][localVehicleIdRef][updateEntryId] = tempEntry;
            return true;
          }
          return false;
        };

        const removeEntry = (updateEntryId = rawLogbookEntry?.header?.logbookEntryId) => {
          if (unsavedEntries[organisationId][localVehicleIdRef][updateEntryId]) {
            delete unsavedEntries[organisationId][localVehicleIdRef][updateEntryId];
            return true;
          }
          return false;
        };

        return apiToBeUsed({
          organisationId,
          logbookEntryId: rawLogbookEntry?.header?.logbookEntryId,
          logbookEntry: logbookEntry,
          sendOptionalNotification: rawLogbookEntry?.sendOptionalNotification,
        })
          .then(async ({ data }) => {
            const logbookEntryId = isNewEntry ? data?.resourceId : rawLogbookEntry?.header?.logbookEntryId;

            return getLogbookEntryById(organisationId, logbookEntryId)
              .then((savedEntry) => {
                const responseSavedLocally = updateEntry(savedEntry?.header?.logbookEntryId, savedEntry);

                const submitEntry = !!rawLogbookEntry?.signatureImage;
                //const submitEntry = savedEntry?.header?.isFinalizable && !!rawLogbookEntry?.signatureImage;
                const cleanUpLocallySavedData = responseSavedLocally && isNewEntry;
                return {
                  cleanUpLocallySavedData,
                  responseSavedLocally,
                  submitEntry,
                  savedEntry,
                };
              })
              .then(async ({ cleanUpLocallySavedData, submitEntry, savedEntry }) => {
                if (submitEntry) {
                  const { signatureImage } = rawLogbookEntry;
                  return finaliseEntry(organisationId, logbookEntryId, signatureImage)
                    .then(resp => {
                      removeEntry(savedEntry?.header?.logbookEntryId);
                      return {
                        logbookEntryId: rawLogbookEntry?.header?.logbookEntryId,
                        response: data,
                        cleanup: removeEntry(),
                      };
                    }).catch((err) => {
                      console.warn(err?.response?.status);
                      if ([400, 500].includes(err?.response?.status)) {
                        if (Array.isArray(err?.response?.data?.resultCodes)) {
                          if (err.response.data.resultCodes.includes('err_already_finalized')) {
                            return {
                              logbookEntryId: rawLogbookEntry?.header?.logbookEntryId,
                              response: data,
                              cleanup: removeEntry(savedEntry?.header?.logbookEntryId),
                            };
                          }
                        }
                      }

                      if (err?.response?.status > 400) {
                        if ([401].includes(err?.response.status)) {
                          console.warn(`Failed to:: Entry will be Finalised when you re-login `);
                        }

                        if ([500].includes(err?.response?.status)) {
                          console.warn(`Failed to:: server error. Will try again later`);
                        }

                        console.log(`Entry id:: ${rawLogbookEntry?.header?.logbookEntryId}`, err?.response?.data);

                      } else {
                        console.log(`Failed to:: Finalise entry`);
                        console.log(`Entry id:: ${rawLogbookEntry?.header?.logbookEntryId}`, err?.response?.data);
                        console.dir(err);
                      }
                      updateEntry(
                        savedEntry?.header?.logbookEntryId,
                        {
                          ...savedEntry,
                          //isUnSynced: true,
                          //signatureImage,
                        });

                      return {
                        logbookEntryId: rawLogbookEntry?.header?.logbookEntryId,
                        response: { ...data, ...(isNewEntry ? { logbookEntryId } : {}) },
                        cleanup: cleanUpLocallySavedData ? removeEntry() : 'nothing_to_cleanup',
                        err,
                      };
                    });
                } else if (cleanUpLocallySavedData) {
                  return {
                    logbookEntryId: rawLogbookEntry?.header?.logbookEntryId,
                    response: data,
                    cleanup: removeEntry(),
                  };
                }

                return {
                  logbookEntryId: rawLogbookEntry?.header?.logbookEntryId,
                  response: data,
                  cleanup: 'nothing_to_cleanup',
                };
              }).catch((err) => {
                console.log(err);
                console.log('data was saved but possibly not retrievable');
                return err;
              });
          })
          .catch((err) => {
            if ([400, 500, 404, 409].includes(err?.response?.status)) {
              if (Array.isArray(err?.response?.data?.resultCodes)) {
                if (err.response.data.resultCodes.includes('err_already_finalized')) {
                  return {
                    logbookEntryId: rawLogbookEntry?.header?.logbookEntryId,
                    response: err,
                    cleanup: cleanUpEntry(rawLogbookEntry),
                  };
                }

                if (err.response.data.resultCodes.includes('err_unknown_entry')) {
                  rawLogbookEntry.unknownEntry = true;
                  updateEntry(rawLogbookEntry?.header?.logbookEntryId, rawLogbookEntry);
                }
              }
            }

            console.log(`Failed to:: ${isNewEntry ? 'Create new entry' : 'Update entry'} `);
            console.log(`Entry id:: ${rawLogbookEntry?.header?.logbookEntryId}`);
            console.dir(err);
            return { logbookEntryId: rawLogbookEntry?.header?.logbookEntryId, response: err };
          });
      }));

    })).then((resp) => {
      return resp.flat();
    });
  })).then((resp) => {
    saveDataToLocalStorage('unsavedLogbookItems', unsavedEntries);
    return resp;
  });
};


export const setInitialValues = () => {
  if (localStorage.getItem('userToken')) {
    try {
      const { accountId, organisations } = decode(localStorage.getItem('userToken'), '', true);
      userId = accountId;
      userOrganisations = organisations;
    } catch (e) {
      console.log(e);
    }
  }
};


const deleteLocallyFinalizedEntries = async (locallyStoredEntriesByOrg = decodeUnsavedEntries()) => {
  if (locallyStoredEntriesByOrg) {
    const locObj = {};
    Object.keys(locallyStoredEntriesByOrg).forEach((orgId) => {
      locObj[orgId] = {};
      Object
        .keys(locallyStoredEntriesByOrg[orgId] || {})
        .forEach((locoID) => {
          Object.keys(locallyStoredEntriesByOrg[orgId][locoID]).forEach((logbookEntryId) => {
            locObj[orgId][logbookEntryId] = locallyStoredEntriesByOrg[orgId][locoID][logbookEntryId];
          });
          return Object.keys(locallyStoredEntriesByOrg[orgId][locoID]) || {};
        });
    });

    const chunkSize = 15;
    return Promise.all(Object.keys(locObj).map(async (orgId) => {
      const listOfEntryIds = Object.keys(locObj[orgId]);
      const promiseArray = [];
      const listOfResponseIds = [];
      for (let startPoint = 0, endPoint = chunkSize; startPoint < listOfEntryIds.length; startPoint += chunkSize, endPoint += chunkSize) {
        promiseArray.push(getLogbookEntries(
          orgId,
          undefined,
          undefined,
          //  `vehicleIdRef ne null and status eq 'finalized' and logbookEntryId in
          // ('${orgOfEntryIds[orgId].slice(startPoint, endPoint) .join('\',\'')})`
          `vehicleIdRef ne null and (logbookEntryId eq ${listOfEntryIds.slice(startPoint, endPoint).join(' or logbookEntryId eq ')})`,
        )
          .then((resp) => {
            return resp;
          })
          .then(({ items }) => {
            items.forEach(({ header: { vehicleIdRef, logbookEntryId, status } }) => {
              if (status === 'finalized') {
                delete locallyStoredEntriesByOrg[orgId][vehicleIdRef][logbookEntryId];
              }
              listOfResponseIds.push(logbookEntryId);
            });
          }));
      }

      await Promise.all(promiseArray);

      listOfEntryIds.forEach((entryId) => {
        if (!listOfResponseIds.includes(entryId) && locObj[orgId][entryId].header.status === 'created') {
          const unknownEntryHeader = locObj[orgId][entryId].header;
          locallyStoredEntriesByOrg[orgId][unknownEntryHeader.vehicleIdRef][unknownEntryHeader.logbookEntryId] = {
            ...locallyStoredEntriesByOrg[orgId][unknownEntryHeader.vehicleIdRef][unknownEntryHeader.logbookEntryId],
            unknownEntry: true,
          };
        }
      });

      return locallyStoredEntriesByOrg;
    })).then(() => {
      return locallyStoredEntriesByOrg;
    });
  }
  return locallyStoredEntriesByOrg;
};

localStorage.removeItem('syncInProgress');

export const syncLogbookEntries = async ({ callbackForSavedEntry } = {}) => {
  if (!navigator.onLine) {
    return 'system_is_offline';
  }

  if (syncInProgress) {
    console.log('canceled sync attempt');
    return 'sync_in_process';
  }
  syncInProgress = true;

  setInitialValues();
  let deletionProcessCompleted = false;
  let saveProcessCompleted = false;
  setTimeout(() => {
    //syncInProgress = false;
  }, 1000);

  const syncedEntriesResp = {};

  let locallyStoredEntriesByOrg = decodeUnsavedEntries();
  locallyStoredEntriesByOrg = await deleteLocallyFinalizedEntries().catch(() => {
  });

  await deleteEntriesFromDb().then((resp) => {
    syncedEntriesResp.deletedEntriesResponse = resp;
  }).catch(() => {
    console.log('Error Deleting');
  }).finally(() => {
    deletionProcessCompleted = true;
  });

  await sendUnsavedEntriesToDb({ unsavedEntries: locallyStoredEntriesByOrg }).then((resp) => {
    syncedEntriesResp.savedEntries = resp;
    if (typeof callbackForSavedEntry === 'function') {
      callbackForSavedEntry(resp);
    }
  }).catch(() => {
    console.log('Error Saving');
  }).finally(() => {
    saveProcessCompleted = true;
  });

  syncedEntriesResp.getEntriesResponse = Promise.all(
    userOrganisations.map(async ({ id: organisationId }) => {
      return getLogbookEntries(
        organisationId,
        100,
        undefined,
        `status eq 'created' and vehicleIdRef ne null`,
      ).then(async (resp) => {
        const { items } = await resp;

        if (items) {
          items.forEach((entry) => {
            if (entry?.header?.logbookEntryId &&
              !locallyStoredEntriesByOrg[organisationId]?.[entry.header.vehicleIdRef]?.[entry.header.logbookEntryId]?.isUnSynced) {
              // ensuring org
              if (!locallyStoredEntriesByOrg[organisationId]) {
                locallyStoredEntriesByOrg[organisationId] = {};
              }
              // ensuring vehicleIdRef
              if (!locallyStoredEntriesByOrg[organisationId][entry.header.vehicleIdRef]) {
                locallyStoredEntriesByOrg[organisationId][entry.header.vehicleIdRef] = {};
              }
              locallyStoredEntriesByOrg[organisationId][entry.header.vehicleIdRef][entry.header.logbookEntryId] = entry;
            }
          });
        }
        return items;
      }).catch((e) => {
        console.dir(e);
      });
    })).then((responseArray) => {

      saveDataToLocalStorage('unsavedLogbookItems', locallyStoredEntriesByOrg);
      triggerEntryReloading();
      return responseArray;
    }).catch((e) => {
      console.dir(e);
    }).finally(async () => {
      await sleep(1000);
      syncInProgress = false;
    });

  return syncedEntriesResp;
};

/// General utils to be moved
export const onConfirmRefresh = function (event) {
  event.preventDefault();
  return event.returnValue = '<div>you are in offline mode, refresh will require you to have internet connection</div>';
};

const cleanUpObject = (objectToCleanup, keyToFilter = []) => {

  Object.keys(objectToCleanup).filter(key => !keyToFilter.includes(key)).forEach(key => {
    if (objectToCleanup[key] && typeof objectToCleanup[key] === 'object' && cleanUpObject(objectToCleanup[key], keyToFilter) === null) {
      delete objectToCleanup[key];
    }
  });

  if (!Object.keys(objectToCleanup).length) {
    return null;
  }
};
const triggerEntryReloading = () => {
  setTimeout(() => store.dispatch(setLogbookEntriesSyncedTimestamp()), 300);
};

lsChangeDetector.addChangeListener('onChange', 'unsavedLogbookItems', triggerEntryReloading);
lsChangeDetector.addChangeListener('onChange', 'userToken', setInitialValues);

