/* eslint-disable no-undef */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// eslint-disable-next-line import/no-extraneous-dependencies
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
// File resources
// import translationDE from './de/de-translation.json';
// import translationEN from './en/en-translation.json';
export const DEFAULT_LOCALE_MAP = {
  en: 'en-GB',
  'en-GB': 'en-GB',
  'en-US': 'en-GB',
  de: 'de-DE',
  'de-DE': 'de-DE',
};
// const IS_LIVE = window.location.hostname === 'my.nrail.de';
const IS_DEV = window.location.hostname.match(/(localhost)|(dev\.parts)|(\d+\.\d+\.\d)/);
// const orderObject = (objToSort) => {
//   const newobj = {};
//
//   const keyListSorted = Object.keys(objToSort).sort((a, b) => {
//     if (a < b) {
//       return -1;
//     }
//     if (a > b) {
//       return 1;
//     }
//     return 0;
//   });
//   //
//   keyListSorted.forEach((key) => {
//     // if (objToSort[key] === Object(objToSort[key]) && !Array.isArray(objToSort[key]) ) {
//     if (objToSort[key] instanceof Object && objToSort[key].constructor === Object) {
//       newobj[key] = orderObject({ ...objToSort[key] });
//     } else {
//       newobj[key] = objToSort[key];
//     }
//   });
//
//   return newobj;
// };

// the translations
// const findHardCodedStrings = localStorage.getItem('isLanguageTest') ? '.test' : '';

const getTranslationPath = (locale, ns) => {
  const lng = locale.map((localeItem) => localeItem.split('-')[0]);
  const deploymentPath = `${
    process.env.REACT_APP_DEPLOYMENT_PATH ? `/${process.env.REACT_APP_DEPLOYMENT_PATH}` : ''
  }`;

  let path = `${deploymentPath}/locales/${lng}/${ns}.json?has-changed=${new Date().getTime()}`;
  if (IS_DEV) {
    path = `/locales/${lng}/${ns}.json?has-changed=${new Date().getTime()}`;
  }
  return path;
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // resources,
    backend: {
      // loadPath: '/locales/{{lng}}/{{ns}}.json',
      // loadPath: (lng, ns) => `/locales/${lng}/${ns}.json`,
      crossDomain: true,
      // loadPath: (lng, ns) => IS_LIVE || IS_DEV ? `/final/${lng}-${ns}.json` :
      // `https://nrail.staging.fuerstvonmartin.de/wp-json/fvm/v1/get-language/?file=${lng}${findHardCodedStrings}`,
      loadPath: (lng, ns) => getTranslationPath(lng, ns),
    },
    detection: {
      // order and from where user language should be detected
      order: window.location.origin.match(/^https:\/\/nrail/)
        ? ['path', 'htmlTag']
        : ['querystring', 'localStorage', 'cookie', 'navigator', 'htmlTag', 'subdomain', 'path'],

      // keys or params to lookup language from
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

      // optional expire and domain for set cookie
      // cookieMinutes: 10,
      // cookieDomain: 'myDomain',

      // optional htmlTag with lang attribute, the default is:
      htmlTag: document.documentElement,
    },
    // lng: 'de',
    // fallbackLng: ['de'], // use de if detected lng is not available
    fallbackLng: (code) => {
      if (!code || code === 'de'|| code.startsWith('de-')){
        return ['de-DE'];
      }
      const fallbacks = [code];

      // We maintain en-US and en-AU. Some regions will prefer en-AU.
      if (code === 'en' || code.startsWith('en-')) {
        fallbacks.push('en-GB');
        return fallbacks;
      }

      return fallbacks;
    },
    whitelist: ['en-GB', 'de-DE'],
    // keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      wait: true,
    },
  });

// if (location.origin.match(/^https:\/\/nrail/)) {
//   const slugsArray = location.pathname.split('/').filter(slugs => slugs);
//   localStorage.setItem('i18nextLng', slugsArray.length ? 'en' : 'de');
// }
//
export default i18n;
