import Joi from 'joi';
import joi from 'joi';

const requireDefect = Joi.when('isDefect', {
  is: true,
  then: Joi.required(),
});

const requireStoppage = Joi.when('isStoppage', {
  is: true,
  then: Joi.required(),
});

const MAX_INT = 2147483647;
export const logbookSchema = Joi.object({
  checkRequired: Joi.boolean(),
  header: Joi.object().keys({
    vehicleIdRef: Joi.number().empty(Joi.valid('', null)).max(MAX_INT).required(),
    remarks: Joi.string().empty(Joi.valid('', null)).max(4000),
    referenceTimestamp: Joi.date().empty(null).required(),
    referenceLocation: Joi.string().empty(Joi.valid('', null)).max(50),
  }),
  vehicleState: Joi.object().keys({
    hydraulicsState: Joi.string().empty(Joi.valid('', null)).valid('ok', 'not_ok'),
    vehicleOperatingHours_h: Joi.number().empty(Joi.valid('', null)).max(1e8),
    mileage_km: Joi.number().empty(Joi.valid('', null)).max(1e6),
    fuelFillingLevel_l: Joi.number().empty(Joi.valid('', null)).max(1e5),
    fuelAdded_l: Joi.number().empty(Joi.valid('', null)).max(1e5),
    sandState: Joi.string().empty(Joi.valid('', null)).valid('ok', 'not_ok'),
    motorOilState: Joi.string().empty(Joi.valid('', null)).valid('ok', 'not_ok'),
    isSandAdded: Joi.boolean().empty(null),
    motorOilAdded_l: Joi.number().empty(null).max(1e3)
  }).when(Joi.object({ 'sandState': Joi.equal('not_ok') }).unknown(), {
    then: Joi.object().keys({
      isSandAdded: Joi.required(),
    }),
  }).when(Joi.object({ 'motorOilState': Joi.equal('not_ok') }).unknown(), {
    then: Joi.object().keys({
      motorOilAdded_l: Joi.required(),
    }),
  }),
  inspections: Joi.object({
    selectedV: Joi.string().empty(Joi.valid('', null)),
    inspectionPerformedTimestamp_A1: Joi.date().empty(null),
    inspectionPerformedTimestamp_V1: Joi.date().empty(null),
    inspectionPerformedTimestamp_V3: Joi.date().empty(null),
    inspectionPerformedTimestamp_F1: Joi.date().empty(null),
  }),

  defects: Joi.object({
    isDefect: Joi.boolean(),
    measuresDescription: Joi.string().max(4000).empty(Joi.valid('', null)),
  }).when(Joi.object({ 'isDefect': Joi.equal(true) }).unknown(), {
    then: Joi.object().keys({
      state: Joi.string().empty(Joi.valid('', null)).valid('open', 'resolved').required(),
      description: Joi.string().max(4000).empty(Joi.valid('', null)).required(),
    }),
  }),
  stoppage: Joi.object({
    isStoppage: Joi.boolean(),
    endTimestamp: Joi.date().empty(null)
  }).when(Joi.object({ 'isStoppage': Joi.equal(true) }).unknown(), {
    then: Joi.object().keys({
      vehicleLocation: Joi.string().max(50).empty(Joi.valid('', null)).required(),
      trainNumber: Joi.string().max(50).empty(Joi.valid('', null)),
      state: Joi.string().optional().empty(Joi.valid('', null)),
      startTimestamp: Joi.date().empty(null).required(),
      endTimestamp: Joi.when('state', { is: Joi.exist().equal('resolved'), then: Joi.required() }),
      lastOperationMode: Joi.string().empty(Joi.valid('', null)),
      trainWeight_t: Joi.number().max(1e5).empty(Joi.valid('', null)),
      wasAuxiliaryVehicleUsed: Joi.boolean().empty(Joi.valid('', null)),
      errorCodesDescription: Joi.string().max(4000).empty(Joi.valid('', null)),
      description: Joi.string().max(4000).empty(Joi.valid('', null)).required(),
      affectedComponents: Joi.array().empty(null),
      remarks: Joi.string().max(4000).empty(Joi.valid('', null)),
      measuresDescription: Joi.string().max(4000).empty(Joi.valid('', null)),
    }),
    otherwise: Joi.object().keys({
      startTimestamp: Joi.any(),
      vehicleLocation: Joi.any(),
      description: Joi.any(),
    }),
  })
}).when(Joi.object({ checkRequired: Joi.equal(true) }).unknown(), {
  then: Joi.object({
    header: {
      referenceLocation: Joi.required(),
    },
    vehicleState: Joi.object({
      fuelAdded_l: Joi.required(),
      fuelFillingLevel_l: Joi.required(),
      hydraulicsState: Joi.required(),
      sandState: Joi.required(),
      motorOilState: Joi.required(),
      isSandAdded: Joi.when('sandState', { is: Joi.exist().equal('not_ok'), then: Joi.required(), otherwise: Joi.equal(null) }),
      motorOilAdded_l: Joi.when('motorOilState', {
        is: Joi.exist().equal('not_ok'),
        then: Joi.required(),
        otherwise: Joi.equal(null),
      }),
      mileage_km: Joi.required(),
      vehicleOperatingHours_h: Joi.required(),
    }),
    inspections: Joi.object({
      selectedV: Joi.required(),
      inspectionPerformedTimestamp_A1: Joi.when('isA1Required', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.allow(null),
      }),
      inspectionPerformedTimestamp_V1: Joi.when('selectedV', { is: Joi.exist().equal('V1'), then: Joi.required() }),
      inspectionPerformedTimestamp_V3: Joi.when('selectedV', { is: Joi.exist().equal('V3'), then: Joi.required() }),
    }),
    stoppage: Joi.object({
      state: requireStoppage,
      lastOperationMode: requireStoppage,
      wasAuxiliaryVehicleUsed: requireStoppage,
    }),
  }),
});


export const logbookSignatureSchema = Joi.object({
  signatureImage: Joi.string().required(),
});

export const logbookCommentSchema = Joi.object({
  remarks: Joi.string().max(4000).allow(null).empty(Joi.valid('', null)).required(),
  signatureImage: Joi.string().optional(),
});

export const logbookDefectCommentSchema = Joi.object({
  remarks: Joi.string().max(4000).allow(null).empty(Joi.valid('', null)).required(),
  newEntryState: Joi.string().required(),
  signatureImage: Joi.string().optional(),
});