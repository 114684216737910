import { Modal, Box } from '@material-ui/core';
import LoginForm from '../LoginForm';

const ModalLoginWindow = ({ open, ...props }) => {
  return (
    <Modal open={open}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: '100vh',
          alignItems: 'center',
          backdropFilter: 'blur(4px)',
        }}
      >
        <LoginForm isModalLogin {...props} />
      </Box>
    </Modal>
  );
};

export default ModalLoginWindow;
