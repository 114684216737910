import React, { Fragment, useEffect, useState } from 'react';
import {
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import NrlDatePicker, { NrlDateTimePicker, NrlTimePicker } from '../NrlDatePicker';
import { makeStyles } from '@material-ui/core/styles';
import { NumberFormatInt } from '../../NrlNumericInput';
import { Controller, UseFormReturn } from 'react-hook-form';
import { localeNumberFormatter } from '../../../StatelessFunctions/nummericManapulation';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  sectionLabel: {
    marginTop: 24,
    marginBottom: 16,
  },

  textField: {
    '& label[data-shrink=false]': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: 'calc(100% - 56px)',
      height: 18,
    },
  },
}));

const Header = props => {
  const classes = useStyles();
  const {
    useSplitDates = false,
    form: { control, formState: { errors } },
    isReadOnly,
    gridItemsSpacing,
    locale = undefined,
  } = props;

  const { t, i18n } = useTranslation('');
  const sharedProps = {
    disabled: isReadOnly
  }

  const [pickerProps] = useState({});

  const getJoiError = (errorResponse, options) => {
    const isDefaultRequired = !options?.label || errorResponse === 'any.required';
    return t([`joi.messages.${isDefaultRequired ? 'default.required' : errorResponse}`, 'joi.messages.'], { ...options, label: t('labels.field') });
  };

  return (
    <Fragment>
      <Typography variant="h6" className={classes.sectionLabel}>
        {t('labels.generalData')}
      </Typography>
      <Grid container spacing={gridItemsSpacing}>
        <Grid container item spacing={gridItemsSpacing}>

          {!useSplitDates ?
            <Grid item xs={12} sm={6}>
              <Controller
                fullwidth
                control={control}
                name="header.referenceTimestamp"
                render={({ field }) => (
                  <NrlDateTimePicker
                    fullwidth
                    {...sharedProps}
                    required
                    locale={locale}
                    error={!!errors.header?.referenceTimestamp}
                    helperText={
                      errors?.header?.referenceTimestamp?.type &&
                      getJoiError(errors?.header?.referenceTimestamp?.type, { label: t('labels.referenceTimestamp') })
                    }
                    label={t('labels.dateTime')}
                    time={field.value}
                    {...field}
                  />
                )}
              />
            </Grid>
            : (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    fullwidth
                    control={control}
                    name="header.referenceTimestamp"
                    render={({ field }) => (
                      <NrlDatePicker
                        fullwidth
                        {...sharedProps}
                        required
                        locale={locale}
                        label={t('labels.date')}
                        date={field.value}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    control={control}
                    name="header.referenceTimestamp"
                    render={({ field }) => (<NrlTimePicker
                      {...sharedProps}
                      required
                      label={t('labels.time')}
                      {...pickerProps['referenceTimestamp_tempTime']}
                      time={field.value}
                      {...field}
                    />)}
                  />
                </Grid>
              </>
            )
          }

          <Grid hidden item xs={12} sm={6}>
            <Controller
              control={control}
              name="header.vehicleIdRef"
              render={({ field }) => (
                <TextField
                  fullWidth
                  className={classes.textField}
                  {...sharedProps}
                  error={!!errors.header?.vehicleIdRef}
                  helperText={
                    errors?.header?.vehicleIdRef?.type &&
                    getJoiError(errors?.header?.vehicleIdRef?.type, { label: t('labels.referenceTimestamp') })
                  }
                  label={t('labels.vehicleIdRef')}
                  variant="outlined"
                  {...field}
                  disabled

                />)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="header.referenceLocation"
              render={({ field }) => (
                <TextField
                  fullWidth
                  className={classes.textField}
                  {...sharedProps}
                  error={!!errors.header?.referenceLocation}
                  helperText={
                    errors?.header?.referenceLocation?.type &&
                    getJoiError(errors?.header?.referenceLocation?.type, { label: t('labels.referenceTimestamp') })
                  }
                  label={t('labels.referenceLocation')}
                  variant="outlined"
                  {...field}
                />)}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="vehicleState.vehicleOperatingHours_h"
            render={({ field }) => (
              <TextField
                fullWidth
                className={classes.textField}
                {...sharedProps}
                variant="outlined"
                error={!!errors.vehicleState?.vehicleOperatingHours_h}
                helperText={
                  errors?.vehicleState?.vehicleOperatingHours_h?.type &&
                  getJoiError(
                    errors?.vehicleState?.vehicleOperatingHours_h?.type,
                    {
                      label: t('labels.vehicleOperatingHours_h'),
                      limit: localeNumberFormatter(1e8, i18n.language),
                      minLimit: 1,

                    })
                }
                label={t('labels.vehicleOperatingHours_h')}
                InputProps={{
                  inputComponent: NumberFormatInt,
                  endAdornment: (
                    <InputAdornment position="start">bh</InputAdornment>
                  ),
                }}
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="vehicleState.mileage_km"
            render={({ field }) => (
              <TextField
                fullWidth
                className={classes.textField}
                {...sharedProps}
                label={t('labels.mileage_km')}
                variant="outlined"
                InputProps={{
                  inputComponent: NumberFormatInt,
                  endAdornment: (
                    <InputAdornment position="start">km</InputAdornment>
                  ),
                }}
                error={!!errors.vehicleState?.mileage_km}
                helperText={
                  errors?.vehicleState?.mileage_km?.type &&
                  getJoiError(
                    errors?.vehicleState?.mileage_km?.type,
                    {
                      label: t('labels.mileage_km'),
                      limit: localeNumberFormatter(1e6, i18n.language),
                      minLimit: 1,
                    })
                }

                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Controller
            control={control}
            name="header.remarks"
            render={({ field }) => (
              <TextField
                fullWidth
                className={classes.textField}
                {...sharedProps}
                label={t('labels.remarks')}
                variant="outlined"
                error={!!errors.vehicleState?.remarks}
                helperText={
                  errors?.header?.remarks?.type &&
                  getJoiError(errors?.header?.remarks?.type, {
                    label: t('labels.remarks'),
                    limit: localeNumberFormatter(4e3, i18n.language)
                  })
                }
                rowsMax={12}
                rows={4}
                multiline
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

Header.propTypes = {
};

export default Header;
