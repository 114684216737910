import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
// import 'babel-polyfill';
import moment from 'moment';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';

import { BrowserRouter as Router } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { changedView, setDemoUser } from '../../actions';
import Railation from '../../Pages/Railation';
import { FVM_BASE_URL } from '../../wp-rest-api/STEC-Communication';

const USER_LANG = localStorage.getItem('i18nextLng');

if (USER_LANG === 'de') {
  moment.locale('de');
}
const zoomFactor = 1;

const myNrailTheme = createMuiTheme({
  typography: {
    // Use the system font.
    fontFamily: '"IBM Plex Sans", sans-serif;',
    fontSize: 14 * zoomFactor,
  },
  // palette: {
  //   primary: {
  //     // main: '#1cdd43',
  //   },
  // },
  fvm: {
    zoomFactor,
  },
});

class RailationEntry extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      loco: [],
      currentView: 'initial',
      isModalOpen: false,
      modalInfo: null,
    };

    /**
     ********************************
     *  Controls for status icons.
     ********************************
     * Off = Aus
     * Equipped = Maschine aufgerüstet
     * Standing = Motor an
     * Driving = Lok fährt
     */

    // This function can be reduced to only the necessary functions for each view
    this.appFunctions = {
      displayHtmlInModal: this.displayHtmlInModal.bind(this),
      t,
    };

    this.appConsts =
      {
        fvmURL: FVM_BASE_URL,
        fvmMyURL: FVM_BASE_URL.replace('https://', `http${process.env.REACT_APP_NRL_ENV ? '' : 's'}://my.`),
        stecBaseUrl: `https://nrail.stec-cloud.de/wt?id=c82f0bc3-6074-416b-8924-38e4b4b68e01${this.state.isDemoUser ? '&demo=true' : ''}`,
      };

    // if (location.origin.match(/^https:\/\/nrail/)) {
    //   const slugsArray = location.pathname.split('/').filter(slugs => slugs);
    //   localStorage.setItem('i18nextLng', slugsArray.length ? 'en' : 'de');
    // }
  }

  displayHtmlInModal({ overlayClassName, classNames, modalData }) {
    this.setState({
      modalInfo: {
        classNames,
        overlayClassName,
        modalData,
      },
      isModalOpen: true,
    });
  }

  renderRailationPage() {

    return (
      <MuiThemeProvider theme={myNrailTheme}>
        <div className="page fvm-railation-page">
          <main className="fvm-main-content ">
            <Railation
              appConsts={this.appConsts}
              {...this.appFunctions}
              setUserStateFunc={() => {
              }}
            />
          </main>
        </div>
      </MuiThemeProvider>
    );
  }

  render() {
    return (
      <Router>
        {this.renderRailationPage()}
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  activeUserLocomotiveListIds: state.activeUserLocomotiveListIds,
  currentView: state.currentView,
  isDemoUser: state.isDemoUser || false,
  locListUpdated: state.locListUpdated,
  userLocomotiveListObject: state.userLocomotiveList,
  isNavigationBarExpanded: state.isNavigationBarExpanded,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    newView: changedView,
    setDemoUser,
  }, dispatch)
);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RailationEntry));
