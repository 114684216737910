import React from 'react';
const SubCatServiceCenter = () => {
  const a = {
    fill: '#e5e5e5',
    opacity: 0,
  }
  const b = {
    fill: '#002fc9',
    fillRule: 'evenodd',
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {/*<style>.a{fill:#e5e5e5;opacity:0;}.b{fill:#002fc9;fill-rule:evenodd;}</style>*/}
      <g transform="translate(-441 -741)">
        <rect style={a} width="24" height="24" transform="translate(441 741)" />
        <path
          style={b}
          d="M-90.829,410.345l2.669,2.669a.8.8,0,0,1,0,1.131l-1.7,1.7a.8.8,0,0,1-1.13,0l-2.669-2.67a4.99,4.99,0,0,0,1.114,5.353,4.971,4.971,0,0,0,4.224,1.4l9.2,9.2a3,3,0,0,0,4.239,0,3,3,0,0,0,0-4.238l-9.2-9.2a4.973,4.973,0,0,0-1.4-4.223A4.993,4.993,0,0,0-90.829,410.345Zm15.068,17.894a1.748,1.748,0,0,1-2.472,0,1.747,1.747,0,0,1,0-2.472,1.747,1.747,0,0,1,2.472,0A1.747,1.747,0,0,1-75.761,428.239Z"
          transform="translate(537 333)"
        />
      </g>
    </svg>
  );
};
export default SubCatServiceCenter;
