import React from 'react';
import NumberFormat from 'react-number-format';

const DECIMAL_SEPARATORS = {
  en: '.',
  de: ',',
};

const THOUSAND_SEPARATORS = {
  en: ',',
  de: '.',
};

const NrlNumericInput = ({ inputRef, locale = 'en-GB', onChange, ...props }) => {
  const langKey = locale.split('-');
  return (
    <NumberFormat
      {...props}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator={DECIMAL_SEPARATORS[langKey]}
      thousandSeparator={THOUSAND_SEPARATORS[langKey]}
      isNumericString
    />
  );
};

NrlNumericInput.propTypes = {};

export function NumberFormatInt(props) {
  return NrlNumericInput({
    ...props,
    decimalSeparator: ",",
    thousandSeparator: ".",
    decimalScale: 0,
    allowNegative: false,
  });
}


function NumberFormatInputPostCode(props) {
  return NrlNumericInput({ ...props, allowNegative: false });
}

export default NrlNumericInput;
