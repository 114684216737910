import React from 'react';

const SubCatFuelStation = () => {
  const a = {
    fill: '#e5e5e5',
    opacity: 0,
  }
  const b = {
    fill: '#002fc9',
  }
  return (
    <svg className="fvm-sub-category-fuel_station" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {/*<style>.a{fill:#e5e5e5;opacity:0;}.b{fill:#002fc9;}</style>*/}
      <g transform="translate(-441 -693)">
        <rect style={a} width="24" height="24" transform="translate(441 693)" />
        <path
          style={b}
          d="M-76.656,510.313l-2.814-2.843-1.06,1.06,2.089,2.089A1.986,1.986,0,0,0-79,512a2,2,0,0,0,2,2v8.5a1,1,0,0,1-1,1,1,1,0,0,1-1-1v-5a1.5,1.5,0,0,0-1.5-1.5H-82v-7a2.006,2.006,0,0,0-2-2h-6a2.006,2.006,0,0,0-2,2v17h10v-8.5h1.5v5A2.5,2.5,0,0,0-78,525a2.5,2.5,0,0,0,2.5-2.5v-9.209A3.814,3.814,0,0,0-76.656,510.313ZM-84,513h-6v-4h6Z"
          transform="translate(537 189)"
        />
      </g>
    </svg>
  );
};
export default SubCatFuelStation;
