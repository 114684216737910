import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core';
// import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { NrlDateTimePicker } from '../NrlDatePicker';
import NrlRadioButtonGroup, { radioButtonSettings } from '../core/NrlRadioButtonGroup';
import moment from 'moment';
import NrlFormLabel from '../core/NrlFormLabel';
import { ReportProblemOutlined } from '@material-ui/icons';
import { Controller } from 'react-hook-form';

const useStyles = makeStyles(theme => ( {
  sectionLabel: {
    marginTop: 16,
    marginBottom: 16,
  },
  inspectionV: {
    display: 'flex',
    '& label': {
      marginBottom: 0,
    },
  },
  textFieldFormControlStyles: {
    marginTop: 8,
  },
  gridItemV: {
    marginTop: 16,
  },
  alertMessageBoxIcon: {
    color: '#ff9800',
  },
  alertMessageBox: {
    background: 'rgb(255, 244, 229)',
    marginBottom: 16,
    borderRadius: 4,
    boxShadow: 'none',
    color: 'rgb(102, 60, 0)',
    padding: '16px 8px',
    display: 'flex',
  },
  contentContainer: {
    padding: '0px 12px',
    whiteSpace: 'pre-line',
    height: '80%',
    '&> p': {
      padding: 2,
    },
  },
  timeInputGridItem: {},
  [theme.breakpoints.up(600)]: {
    timeInputGridItem: {
      maxWidth: 240,
    },
  },
} ));

const Inspections = props => {
  const classes = useStyles();
  const {
    t,
    isA1Required = false,
    form: { control, watch, formState: { errors } },
    isReadOnly,
    gridItemsSpacing,
    selectedV,
    handleVSelectionChange,
    minDateForPickers,
    lastInspectionPerformedTimestamp_A1,
    locale = undefined,
  } = props;
  const [isValid] = useState({});
  const [activeVAdditionalProps] = useState({});
  // const inspectionPerformedTimestamp_A1 = form.getValues('inspections.inspectionPerformedTimestamp_A1');
  const watchA1 = watch('inspections.inspectionPerformedTimestamp_A1');

  const preparationArray = [
    [
      { name: 'V1', hidden: selectedV !== 'V1' },
      { name: 'V3', hidden: selectedV !== 'V3' },
    ],
    [{ name: 'F1' }, { name: 'A1' }],
  ];

  const getJoiError = (errorResponse, options) => {
    // return t(`joi.messages.${errorResponse}`, options);
    const isDefaultRequired = !options?.label || errorResponse === 'any.required';
    return t([`joi.messages.${isDefaultRequired ? 'default.required' : errorResponse}`, 'joi.messages.'], { label: 'field' });
  };
  useEffect(() => {
    // console.log(watchA1);

  }, [watchA1]);
  const pickerIsReadOnly = {};

  if (isReadOnly) {
    pickerIsReadOnly.error = false;
    pickerIsReadOnly.helperText = null;
  }
  const sharedProps = {
    disabled: isReadOnly,
  };

  return (
    <Fragment>
      <Typography variant="h6" className={classes.sectionLabel}>
        {t('labels.sectionInspections')}
      </Typography>
      {!isReadOnly && isA1Required && ( !lastInspectionPerformedTimestamp_A1 || activeVAdditionalProps.inspectionPerformedTimestamp_A1 ) ?
        <Box
          minWidth={'100%'}
          key={`a1-error-msg`}
        >
          <Paper className={classes.alertMessageBox} elevation={0}>
            <ReportProblemOutlined className={classes?.alertMessageBoxIcon}/>
            <Box className={classes?.contentContainer} component="div">
              <Typography component="p">
                {
                  lastInspectionPerformedTimestamp_A1 ? t('labels.a1FieldIsRequired', {
                      date: moment(lastInspectionPerformedTimestamp_A1).format('DD.MM.YYYY'),
                      time: moment(lastInspectionPerformedTimestamp_A1).format('HH:mm'),
                    }) :
                    t('labels.a1DateWasNeverRecorded')
                }
              </Typography>
            </Box>
          </Paper>
        </Box> : null}
      <Grid container item spacing={gridItemsSpacing}>
        <Grid
          key={`service-and-examination-radio`}
          item
          xs={12}
          spacing={0}
          className={classes.inspectionV}
        >
          <FormControl
            component="fieldset"
            // required
            error={isValid.preparationService}
          >
            <NrlFormLabel
              error={!!( errors?.inspections && ( errors?.inspections[`inspectionPerformedTimestamp_V1`] || errors?.inspections[`inspectionPerformedTimestamp_V3`] || errors?.inspections.selectedV ) )}
              id="radio-button-labelVSelect"
              component="legend"
            >
              {t('labels.preparationService')}
            </NrlFormLabel>
            <NrlRadioButtonGroup
              row
              value={selectedV}
              onChange={handleVSelectionChange}
            >
              {['V1', 'V3'].map(name => (
                <FormControlLabel
                  {...sharedProps}
                  key={`service-and-examination-select-${name}`}
                  value={name}
                  control={<Radio {...radioButtonSettings} />}
                  label={t(name)}
                  labelPlacement="end"
                />
              ))}
            </NrlRadioButtonGroup>
          </FormControl>
        </Grid>

      </Grid>

      {preparationArray.map(group => (
        <Grid
          className={classes.textFieldFormControlStyles}
          container
          item
          spacing={gridItemsSpacing}
        >
          {group.map(({ name, hidden = false }) => (
            <Row
              name={name}
              hidden={hidden}
              control={control}
              locale={locale}
              {...sharedProps}
              error={!!( errors?.inspections && errors?.inspections[`inspectionPerformedTimestamp_${name}`] )}
              helperText={
                errors?.inspections?.[`inspectionPerformedTimestamp_${name}`]?.type &&
                getJoiError(
                  errors?.inspections?.[`inspectionPerformedTimestamp_${name}`]?.type,
                  { label: t(`labels.${name}`) },
                )
              }
              minDateForPickers={minDateForPickers}
              label={t(`labels.${name}`)}
              className={classes.timeInputGridItem}
            /> ))}
        </Grid>
      ))}
    </Fragment>
  );
};

const Row = ({
  control,
  helperText = '',
  name,
  locale,
  minDateForPickers,
  hidden = false,
  label,
  className,
  ...rest
}) => (
  <Grid
    hidden={hidden}
    key={`service-and-examination-${name}`}
    item
    xs={12}
    sm={6}
    className={className}
  >
    <Controller
      control={control}
      name={`inspections.inspectionPerformedTimestamp_${name}`}
      render={({ field }) => (
        <NrlDateTimePicker
          label={label}
          helperText={helperText}
          time={field.value}
          // minDate={minDateForPickers}
          minDate={moment(minDateForPickers).add(-1, 'day')}
          maxDate={moment(Date.now())}
          // maxDate={moment(minDateForPickers).add(1, 'day')}
          {...rest}
          {...field}
        />
      )}
    />
  </Grid>
);

Inspections.propTypes = {};

export default Inspections;
