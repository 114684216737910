import React from 'react';
const NortRail = () => {
  const a = {
    fill: '#002fc9',
  };
  const b = {
    opacity: 0,
  };
  const c = {
    fill: '#fff',
    fillRule: 'evenodd',
  };
  return (
    <svg  xmlns="http://www.w3.org/2000/svg" width="33.999" height="33.999" viewBox="0 0 33.999 33.999">
      {/*<style>.a,.b{fill:#002fc9;}.b{opacity:0;}.c{fill:#fff;stroke:rgba(0,0,0,0);}</style>*/}
      <g transform="translate(-726 -832)">
        <g transform="translate(635 749.999)">
          <rect style={a} width="33.999" height="33.999" rx="5" transform="translate(91 82.001)" />
        </g>
        <g transform="translate(-154 30)">
          <rect style={{ ...a, ...b }} width="24" height="24" transform="translate(885 806.999)" />
          <path
            style={c}
            d="M-806.3,628.432l1.373-2.637,2.539,3.173c-.2.013-.407.031-.614.031A9.985,9.985,0,0,1-806.3,628.432Zm2.13-4.093,1.868-3.589h3.045l2.781,5.816a9.956,9.956,0,0,1-4.193,2.149Zm-6.657.876,1.624-4.465h.24l3.012,3.766-1.729,3.321A10.045,10.045,0,0,1-810.825,625.215Zm13.233-4.465h4.43a9.943,9.943,0,0,1-2.189,4.685Zm-15.244,0h2.038l-1.054,2.9A9.96,9.96,0,0,1-812.837,620.75Zm5.8,0h3.049l-1.2,2.308Zm8.728-1.5-1.536-3.213.67-1.287h5.218A9.936,9.936,0,0,1-793,619c0,.084-.011.166-.013.25Zm-9.929,0-.286-.359,1.507-4.141h4.894l.6,1.257-1.687,3.243Zm-4.747,0c0-.085-.013-.167-.013-.25a10.027,10.027,0,0,1,.4-2.8l2.372,2.966-.028.079Zm1.148-4.5h3.223l-1.007,2.769Zm13.442-1.5,1.23-2.361a10.047,10.047,0,0,1,2.34,2.361Zm-2.783,0-2.027-4.239c.068,0,.137-.011.207-.011a9.929,9.929,0,0,1,4.55,1.1l-1.638,3.147Zm-5.295,0L-805,609.2c.068-.013.14-.019.21-.032l1.95,4.081Zm-4.7,0a10.013,10.013,0,0,1,4.374-3.5l-1.273,3.5Z"
            transform="translate(1700 199.999)"
          />
        </g>
      </g>
    </svg>
  );
};
export default NortRail;
