import { makeStyles } from '@material-ui/core/styles';

export const useAuthStyles = makeStyles(theme => ({
  additionalWrapper: {
    minHeight: '100vh',
  },
  innerFormContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  formGridContainer: {
    alignSelf: 'center',
    minHeight: '100vh',
    width: '100vw',
    backgroundImage:
      'url(https://my.nrail.de/assets/images/hero-gradient-nrail.jpg)',
    backgroundSize: 'cover',
    '& >*:not(:first-child)': {
      maxWidth: 420,
    },
  },
  loginForm: {
    maxHeight: 500,
    textAlign: 'center',
    // maxWidth: 500,
    maxWidth: 'calc(100vw - 32px)',
    width: 420,
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    '& >*': {
      marginBottom: 20,
    },
    '& input': {
      //height: '3rem',
    },

    backgroundColor: ' #fff',
    borderRadius: 10,
    boxShadow: '0 0 15px 0 rgb(0 0 0 / 50%)',
    padding: 30,
    minWidth: 280,
    marginLeft: 10,
    marginRight: 10,
  },
  title: {
    textAlign: 'center',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    '& h1, & p': {
      fontSize: 'calc(24px + (5200vw - 16640px)/1600)',
      fontWeight: 600,
    },
    '& h1': {
      borderBottom: '9px solid #fff',
      display: 'inline-block',
    },
  },
  languageSeparator: {
    '& > span': {
      cursor: 'pointer'
    },
    '& > span:not(:last-child)::after': {
      content: '"|"',
      padding: '2px 5px',
    },
  },
  formFooterText: {
    color: '#FFFFFF',
    '& a': {
      color: '#FFFFFF',
    },
    centerTextAlign: {},
  },
  [theme.breakpoints.up('sm')]: {
    rightTextAlign: {
      textAlign: 'right',
    },
  },
  [theme.breakpoints.down('xs')]: {
    centerTextAlign: {
      textAlign: 'center',
    },
  },
}));
