import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from 'react-i18next';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  dialog:{
    zIndex: '1400 !important',
  },
  FvmDialogContent: {
    minWidth: 200
  },
  centerContent:{
    textAlign: 'center',
    '& span, & p, & a':{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems:'center'
    }
  },
  centerActionButton:{
    justifyContent: 'center',
  }
}));

export default function FVMDialog({title, details, isDialogOpen, onCloseDialog = null, showCloseBtn=true, contentPosition}) {
  // const [open, setOpen] = React.useState(isDialogOpen);
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClose = () => {
    // setOpen(false);
    if(onCloseDialog){
      onCloseDialog();
    }
  };

  return (
    <div >
      <Dialog
        className={classes.dialog}
        maxWidth='lg'
        open={isDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        { title ? <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle> : null}
        <DialogContent
          className={classes.FvmDialogContent}
        >
          <DialogContentText id="alert-dialog-slide-description" className={contentPosition ? classes.centerContent : ''}>
            {details}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={contentPosition ? classes.centerActionButton: ''}>
          {showCloseBtn
            ? <Button onClick={handleClose} variant="outlined" size="small" color="primary">
              {t('button.close')}
            </Button> : null
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}