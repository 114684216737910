import React from 'react';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import TrainIcon from '@material-ui/icons/TrainOutlined';
import { makeStyles } from '@material-ui/core/styles';
import ViewTitle from '../ViewTitle';
import {  Grid } from '@material-ui/core';


const useStyles = makeStyles((theme) => ( {

  secondElement: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    '&>: nth-child(2)': {
      [theme.breakpoints.down('md')]: {
        justifyContent: 'end',
        display: 'flex',
      },
    },
  },
  fvmPageHeader: {
    width: '100%',
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 20,
    paddingLeft: 10,
    '& > div> div:nth-child(2)': {
      paddingLeft: 16,
    },
    '& > div': {
      display: 'flex',
      width: '100%',
    },
  },


  [theme.breakpoints.down('xs')]: {
    fvmPageHeader: {
      '& > div': {
        paddingLeft: 0,
        display: 'block',
      },
      '& > div> div:nth-child(2)': {
        paddingLeft: 0,
        paddingTop: 12,

        width: '100%',
      },
    },
  },
} ));


const ViewHeader = ({
  title = 'Pass Me A title',
  name = 'Please pass a valid name or ""',
  showChip = true,
  rightAlignChild,
  leftAlignChild,
}) => {
  const classes = useStyles();
  return (
    <header className={classes.fvmPageHeader}>
      <Grid container spacing={1}>
        <Grid
          style={{
            display: 'inline-flex',
            justifyContent: 'space-between',
          }}
          container
          item
          spacing={1}
        >
          <Grid item xs={12} lg={12}> <ViewTitle title={title}/> </Grid>
          {
            showChip && (
              <Grid
                item
                xs={12}
                className={classes.secondElement}
              >
                <Chip
                  label={name}
                  avatar={
                    <Avatar>
                      <TrainIcon/>
                    </Avatar>
                  }
                  className="fvm-locomotive-chip"
                />
              </Grid>
            )
          }
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          className={classes.secondElement}
        >
          {
            leftAlignChild ? leftAlignChild : null
          }
          {
            rightAlignChild ? rightAlignChild : null
          }
        </Grid>

      </Grid>
    </header>
  );
};

export default ViewHeader;
