import AccessTimeIcon from '@material-ui/icons/AccessTimeOutlined';
import CalendarTodayIcon from '@material-ui/icons/CalendarTodaySharp';
import ExitToAppIcon from '@material-ui/icons/ExitToAppOutlined';
import LanguageIcon from '@material-ui/icons/Language';
import MoreVert from '@material-ui/icons/MoreVert';
import Business from '@material-ui/icons/Business';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Dropdown, {
  DropdownContent,
  DropdownTrigger,
} from 'react-simple-dropdown';
import UserAvatar from '../UserAvatar';
import {
  changeUserLanguage,
  fetchUserData,
  selectUser,
  setUserActiveOrganisation,
} from '../../reducers/accounts/user';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel, Paper,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useEventListener } from '../../utils/Hooks/eventListner';
import NrlPrimaryButtonWhite from '../../Pages/Logbook/core/NrlPrimaryButton';
import DialogRadioGroup from '../Dialogs/DialogRadioGroup';
import { logout } from '../../apis/user-apis';
import { userLanguageChanged } from '../../actions';

//helper functions
const logUserOut = () => {
  window.location = 'login';
};

const getTime = () => moment().format('HH:mm');
const getDate = () => moment().format('DD.MM.YYYY');

const changeLang = (lang, i18n, dispatch) => {
  // i18n.changeLanguage().then(resp => {
  //   setTimeout(() => {
  //     console.fvmLog(resp);
  //     dispatch(changeUserLanguage(lang));
  //     dispatch(userLanguageChanged(lang)); // to be removed later
  //   }, 500);
  // });
  i18n.changeLanguage(lang, resp => {
    dispatch(userLanguageChanged(lang)); // to be removed later
    dispatch(changeUserLanguage(lang));
  });
};

/***
 *
 * @param userLang
 * @param t
 * @param i18n
 * @return {JSX.Element}
 * @constructor
 */
const SimpleLanguageSwitcher = ({ userLang, t, i18n }) => {
  const { language } = i18n;

  const currentView = useSelector(({ currentView }) => currentView);
  const dispatch = useDispatch();

  return (
    <div className="fvm-lang-switcher">
      <i className="fvm-inactive">
        <LanguageIcon />
      </i>
      <span
        className={language === 'de-DE' ? '' : 'fvm-inactive'}
        role="button"
        onClick={() => changeLang('de-DE', i18n, dispatch)}
      >
        {t('singleWords.german')}
      </span>
      <span
        className={language === 'en-GB' ? '' : 'fvm-inactive'}
        role="button"
        onClick={() => changeLang('en-GB', i18n, dispatch)}
      >
        {t('singleWords.english')}
      </span>
    </div>
  );
};
//
// function ConfirmationDialogRaw(props) {
//   const { onClose, open, t, ...other } = props;
//   const { status, user } = useSelector(selectUser);
//   const { organisations, activeOrganisationId } = user;
//   const [value, setValue] = React.useState(activeOrganisationId);
//   const radioGroupRef = React.useRef(null);
//   const dispatch = useDispatch();
//
//   React.useEffect(() => {
//     if (!open) {
//       setValue(activeOrganisationId);
//     }
//   }, [activeOrganisationId, open]);
//
//   const handleEntering = () => {
//     if (radioGroupRef.current != null) {
//       radioGroupRef.current.focus();
//     }
//   };
//
//   const handleCancel = () => {
//     onClose();
//   };
//
//   const handleOk = () => {
//     dispatch(setUserActiveOrganisation(value));
//     onClose(value);
//   };
//
//   const handleChange = (event) => {
//     setValue(event.target.value);
//   };
//
//   return (
//     <Dialog
//       maxWidth="xs"
//       onEntering={handleEntering}
//       aria-labelledby="confirmation-dialog-title"
//       open={open}
//       onClose={handleCancel}
//       {...other}
//     >
//       <DialogTitle id="confirmation-dialog-title">My Organisations</DialogTitle>
//       <DialogContent dividers>
//         <RadioGroup
//           ref={radioGroupRef}
//           aria-label="ringtone"
//           name="ringtone"
//           value={value}
//           onChange={handleChange}
//         >
//           {organisations && organisations.map(({ id, name }) => (
//             <FormControlLabel value={id} key={id} control={<Radio color="primary"/>} label={name}/>
//           ))}
//         </RadioGroup>
//       </DialogContent>
//       <DialogActions>
//         <NrlPrimaryButtonWhite onClick={handleOk} color="primary" variant='contained'>
//           {t('button.apply')}
//         </NrlPrimaryButtonWhite>
//         <Button autoFocus variant='outlined' onClick={handleCancel} color="primary">
//           {t('button.cancel')}
//         </Button>
//
//       </DialogActions>
//     </Dialog>
//   );
// }

// ConfirmationDialogRaw.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
//   value: PropTypes.string.isRequired,
// };

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '80%',
    maxHeight: 435,
  },
}));

/***
 *
 * @param menuItems
 * @param t
 * @param i18n
 * @param userLocaleChanged
 * @param classes
 * @return {JSX.Element}
 * @constructor
 */
const UserDropdownMenu = ({ menuItems, t, i18n, classes }) => {
  const dialogClasses = useStyles();
  const [open, setOpen] = React.useState(false);

  //redux states
  const { user } = useSelector(selectUser);
  const userStatus = useSelector(state => state.user.status);
  const currentView = useSelector(({ currentView }) => currentView);
  let {
    firstName,
    lastName,
    userAvatarUrl,
    settings,
    activeOrganisationId,
    organisations= [],
  } = user;

  //internal states
  const [active, setActive] = React.useState(false);
  const [time, setTime] = React.useState(getTime());
  const [date, setDate] = React.useState(getDate());
  const [userLang, setUserLang] = React.useState(settings.language);
  const [commentContainerRef, setCommentContainerRef] = React.useState(null);

  //handlers
  const changeTime = () => {
    setDate(getDate());
    setTime(getTime());
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOrgSelected = value => {
    dispatch(setUserActiveOrganisation(value));
    dispatch(fetchUserData());
    setOpen(false);
  };

  const handleClickOutside = useCallback(
    ({ target }) => {
      const isInsideMenu = commentContainerRef.contains(target);
      const userTrigger = document.getElementsByClassName('fvm-user')[0];
      const onUserTrigger = userTrigger ? userTrigger.contains(target) : null;
      if (active && !onUserTrigger && !isInsideMenu) {
        setActive(false);
      }
    },
    [commentContainerRef, active, setActive],
  );

  const handleCommentRef = ref => {
    if (ref) {
      setCommentContainerRef(ref);
    }
  };

  useEventListener('click', handleClickOutside, document);
  //redux and effect
  const dispatch = useDispatch();
  useEffect(() => {
    if (userStatus === 'idle') {
      dispatch(fetchUserData());
      setTimeout(() => {
        changeTime();
        setInterval(changeTime, 60000);
      }, (59 - new Date().getSeconds()) * 1080);
    }


  }, [userStatus, dispatch, activeOrganisationId, user]);

  return (
    <React.Fragment>
      <Dropdown
        className={classNames(classes.root)}
        active={active}
        onClick={() => {
          setActive(!active);
        }}
      >
        <DropdownTrigger>
          <div className="fvm-user">
            <UserAvatar
              className="fvm-user-avatar"
              userAvatarUrl={`${userAvatarUrl}`}
            />
            <div className="fvm-user-name-datetime">
              <div className="fvm-user-name">
                <span className={classNames(classes.header)}>
                  {firstName} {lastName}
                </span>
                <i>
                  <MoreVert />
                </i>
              </div>
              <div className="fvm-user-datetime">
                <AccessTimeIcon />
                <span className="fvm-user-time">{time}</span>
                <CalendarTodayIcon />
                <span className="fvm-user-date">{date}</span>
              </div>
            </div>
          </div>
        </DropdownTrigger>
          <DropdownContent>
            <Paper elevation={2}>
            <ul className="fvm-dropdown-group" ref={ref => handleCommentRef(ref)}>
              {menuItems.map(page => (
                <li
                  onClick={() => page.onClick}
                  className="fvm-dropdown-group-item"
                  key={page.path === 'dashboard' ? 'home' : page.path}
                >
                  <Link to={`/${page.path}`}>
                    <i
                      className={
                        currentView === page.path ? 'fvm-active' : 'fvm-inactive'
                      }
                    >
                      <page.icon />
                    </i>

                    <span className="fvm-menu-item-name">{t(page.name)}</span>
                  </Link>
                </li>
              ))}
              <li className="fvm-dropdown-group-item" key="lang">
                <SimpleLanguageSwitcher userLang={userLang} t={t} i18n={i18n} />
              </li>
              { organisations.length > 1 ? (
                <li
                  className="fvm-dropdown-group-item"
                  key="org-picker"
                  onClick={handleClickListItem}
                >
                  <div className="fvm-lang-switcher">
                    <i className={'fvm-inactive'}>
                      <Business />
                    </i>
                    <span className="fvm-menu-item-name">
                    {t('navigationMenu.activeOrg')}:{' '}
                      {
                        organisations.find(
                          ({ id }) => id === activeOrganisationId,
                        ).name
                      }
                  </span>
                  </div>
                </li>
              ) : null}

              <li className="fvm-dropdown-group-item" key="Login">
                <Link onClick={logout}>
                  <i className="fvm-inactive">
                    <ExitToAppIcon />
                  </i>
                  <span>{t('navigationMenu.logout')}</span>
                </Link>
              </li>
            </ul>
        </Paper>
          </DropdownContent>
      </Dropdown>
      <DialogRadioGroup
        classes={{
          paper: dialogClasses.paper,
        }}
        id="ringtone-menu"
        t={t}
        title={t('labels.myOrganisations')}
        keepMounted
        open={open}
        dialogText={true}
        value={activeOrganisationId}
        onClose={handleClose}
        optionsLst={organisations}
        onSelectionMade={handleOrgSelected}
      />
    </React.Fragment>
  );
};

UserDropdownMenu.propTypes = {};

export default UserDropdownMenu;
export { UserDropdownMenu, SimpleLanguageSwitcher };
