import React from 'react';
const Office = () => {

  const a = {
    fill: '#002fc9',
  };
  const b = {
    opacity: 0,
  };
  const c = {
    fill: '#fff',
    fillRule: 'evenodd',
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.999" height="33.999" viewBox="0 0 33.999 33.999">
      {/*<style>.a,.b{fill:#002fc9;}.b{opacity:0;}.c{fill:#fff;fill-rule:evenodd;}</style>*/}
      <g transform="translate(-726 -496)">
        <g transform="translate(635 413.999)">
          <rect style={a} width="33.999" height="33.999" rx="5" transform="translate(91 82.001)" />
        </g>
        <rect style={{ ...a, ...b }} width="24" height="24" transform="translate(731 501)" />
        <path
          style={c}
          d="M-75.5,803H-79v-7.5a1.5,1.5,0,0,0-1.5-1.5h-11a1.5,1.5,0,0,0-1.5,1.5V814h5v-5h4v5h10v-9.5A1.5,1.5,0,0,0-75.5,803ZM-87,805h-3v-3h3Zm0-5h-3v-3h3Zm5,5h-3v-3h3Zm0-5h-3v-3h3Zm5,10h-3v-3h3Z"
          transform="translate(827 -291)"
        />
      </g>
    </svg>
  );
};
export default Office;
