import moment from 'moment';
// import React from 'react';
// import ReactExport from 'react-data-export';
import FileSaver from 'file-saver';
import Excel from 'exceljs';
import AdmZip from 'adm-zip';
import json2csv from 'json2csv';

// const { ExcelSheet } = ReactExport;
const METRIC_FILTER_LIST = ['serviceHours', 'standingWithEngineOn', 'standbyAndIdleHours'];

const excelRowFormatter = (colHead, dataSet, {
  userDateFormat = 'DD.MM.YYYY',
  showDate = true,
  text = '',
  daysToSubtract = 0,
  isSumming = false,
  startingRow = 4,
  summingOffset = 3,
}) => colHead.map((headerKey) => {
  let value = (dataSet[headerKey] || 0);

  if (headerKey === 'Date') {
    return {
      value: `${text}${showDate ? ` (${moment(dataSet.Date, 'YYYYMMDD').subtract(daysToSubtract, 'day').format(userDateFormat)})` : ''}`,
    };
  }
  if (headerKey === 'Notifications') {
    return {
      value: '',
    };
  }

  if (isSumming) {
    return {
      value: `=SUM(INDIRECT(CONCATENATE(ADDRESS(${startingRow};COLUMN();4);":";ADDRESS(ROW() -  ${summingOffset};COLUMN();4))))`,
      numFmt: 'G',
    };
  }
  if (!['Date', 'Notifications', 'cumulativeSum'].includes(headerKey)) {
    value = Number((dataSet[headerKey] || 0).toFixed(2));
  }

  return { value, numFmt: '0.00%' };
});

const forceValToZero = (locoStartDate, locoStartEndDate, reportDate) => {
  return (locoStartDate && reportDate.isBefore(locoStartDate)) || (locoStartEndDate && reportDate.isAfter(locoStartEndDate));
};

const filterExportDataByID = ({ exportData, userLocomotiveListObject }) => {
  const returnVal = {};
  Array.isArray(exportData);
  if (Array.isArray(exportData)) {
    const defaultData = {
      drivingHours: 0,
      equippedHours: 0,
      mileageKm: 0,
      notifications: [],
      idleHours: 0,
      standByHours: 0,
      workingHours: 0,
      cumulativeSum: {
        drivingHours: 0,
        equippedHours: 0,
        mileageKm: 0,
        notifications: [],
        idleHours: 0,
        standByHours: 0,
        workingHours: 0,
      },
    };

    exportData.forEach(({ vehicleId, data }, index) => {
      const { startDate, endDate } = userLocomotiveListObject[vehicleId];
      returnVal[vehicleId] = data.map(({ date, thisDay, cumulativeSum }) => {
        if ((startDate || endDate) && forceValToZero(startDate, endDate, moment(date, 'YYYYMMDD'))) {
          return { date, ...defaultData };
        }
        return { date, ...thisDay, cumulativeSum };
      });
    });
  } else {
    Object.keys(exportData).forEach(vehicleId => {

      const { startDate, endDate } = userLocomotiveListObject[vehicleId]
      exportData[vehicleId] = exportData[vehicleId].filter(({ date }) => !((startDate || endDate) && forceValToZero(startDate, endDate, moment(date, 'YYYYMMDD'))));
    });

    return exportData;
  }

  return returnVal;
};

const filterExportDataByMetric = ({ exportData, userLocomotiveListObject }) => {
  const outputExportData = {};

  Object.keys(exportData).forEach((metricName) => {
    outputExportData[metricName] = exportData[metricName].map(({ date, thisDay, cumulativeSum }) => {
      // let newRowData = Object.assign({}, {date: moment(date).format(userDateFormat)}, thisDay, { cumulativeSum: { ...cumulativeSum } });
      let newRowData = Object.assign({}, thisDay, { date, cumulativeSum });
      const limitedLocosArr = Object.keys(thisDay).filter(tempLocoId => {
        const { startDate, endDate } = userLocomotiveListObject[tempLocoId];
        return startDate || endDate;
      });

      if (limitedLocosArr.length) {
        const reportDate = moment(date, 'YYYYMMDD');

        limitedLocosArr.forEach(tempLocoId => {
          const { startDate, endDate } = userLocomotiveListObject[tempLocoId];

          if (forceValToZero(startDate, endDate, reportDate)) {
            if (metricName === 'notifications') {
              newRowData[tempLocoId] = [];
              newRowData.cumulativeSum[tempLocoId] = [];
              return;
            }
            newRowData[tempLocoId] = 0;
            newRowData.cumulativeSum[tempLocoId] = 0;
          }
        });
      }
      return newRowData;
    });

  });

  return outputExportData;
};

const ExcelExporter = ({ fetchedData = {}, userLocomotiveListObject, t, isStandardExport = true, userDateFormat, groupById = true, isNotifications = false }) => {
  const filterExportData = groupById ? filterExportDataByID : filterExportDataByMetric;
  const today = new Date();
  const workbook = new Excel.Workbook();
  const data = filterExportData({ exportData: fetchedData, userLocomotiveListObject });
  const workSheetNamesArray = Object.keys(data).sort(metricAndTrainSorter);

  if (!workSheetNamesArray.length) {
    return;
  }

  global.fvmLog(workSheetNamesArray)

  workbook.creator = 'NRail GmbH';
  workbook.created = today;
  workbook.modified = today;
  workbook.lastPrinted = today;
  workbook.calcProperties.fullCalcOnLoad = true;

  const colStyles = {
    // Date: { numFmt: isNotifications ? 'DD.MM.YYYY HH:mm:ss' : 'DD.MM.YYYY' },
    date: { numFmt: userDateFormat },
  };
  const colWidths = {
    date: 20,
    idleHours: 35,
    standByHours: 35,
    eventLocation: 60,
  };

  workbook.views = [
    {
      x: 0, y: 0, width: 32000, height: 24000,
      firstSheet: 0, activeTab: 0, visibility: 'visible',
    },
  ];
    addWorksheetsStream({
      workbook,
      workSheetNamesArray,
      data,
      groupById,
      userLocomotiveListObject,
      t,
      colStyles,
      colWidths,
      userDateFormat,
      isStandardExport,
      isNotifications,
    });


  let fileName = `${moment().format('DD-MM-YYYY')}-NRail${isStandardExport ? t('labels.standard') : t('labels.accum')}-Export.xlsx`;
  if (isNotifications) {
    fileName = `${moment().format('DD-MM-YYYY')}-NRail-${t('labels.notifications')}-Export.xlsx`;
  }

  workbook.xlsx.writeBuffer().then(data => {
    const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(blob, fileName);
  });
};

const addWorksheetsStream = ({ workbook, workSheetNamesArray, data, groupById, userLocomotiveListObject, t, colStyles, colWidths, userDateFormat, isStandardExport, isNotifications }) => {
  workSheetNamesArray.filter(key => !METRIC_FILTER_LIST.includes(key)).forEach((sheetKey, index) => {
    const sheetName = (groupById ? (userLocomotiveListObject[sheetKey] ? userLocomotiveListObject[sheetKey].vehicle.name.replace(/\W+/ig, ' ') : sheetKey) : t(`tables.tHead.${sheetKey}`)).slice(0, 31);
    const worksheet = workbook.addWorksheet(sheetName);
    const rawDataSheetData = data[sheetKey];
    if (rawDataSheetData.length) {
      const columnNames = Object.keys(rawDataSheetData[0]).filter(key => !['Notifications', 'cumulativeSum', ...METRIC_FILTER_LIST].includes(key)).sort(metricAndTrainSorter);
      const wsColumns = columnNames.map(key => ({
        key,
        name: groupById || 'date' === key ? t(`tables.tHead.${key}`) : userLocomotiveListObject[key].vehicle.name,
        totalsRowFunction: key === 'date' ? 'none' : 'sum',
        totalsRowLabel: key === 'date' ? t('labels.excel.sum') : '',
      }));
      //START: Header Formatting
      columnNames.forEach(key => {

      });
      worksheet.addRow({});
      const sheetHeadderOffset = columnNames.length < 6 ? columnNames.length - 1 : 6;
      for (let colIndex = 1; colIndex < columnNames.length; colIndex += sheetHeadderOffset + 2) {

        const startIndex = worksheet.getCell(1, colIndex)._address;
        const endIndex = worksheet.getCell(1, colIndex + sheetHeadderOffset)._address;
        worksheet.mergeCells(`${startIndex}`, `${endIndex}`);
        worksheet.getCell(`${startIndex}`).value = sheetName;
        worksheet.getCell(`${startIndex}`).font = { bold: true, size: 18 };
        worksheet.getCell(`${startIndex}`).alignment = { vertical: 'middle', horizontal: 'center' };
      }
      worksheet.addRow({}); // adding empty row
      worksheet.addRow({}); // adding empty row

      worksheet.columns = columnNames.map(key => ({
        key,
        // header: t(`tables.tHead.${key}`),
        style: colStyles[key] || { numFmt: '0.00' },
        totalsRowFunction: key !== 'date' ? 'sum' : 'none',
        width: colWidths[key] || (groupById ? 20 : 31),
      }));
      //END: Header Formatting

      // START: Prepping entry above and below table
      const dataStartStanding = Object.assign({}, rawDataSheetData[0]);
      const dataEndStanding = Object.assign({}, rawDataSheetData[rawDataSheetData.length - 1]);
      if (isStandardExport) {
        columnNames.forEach((headerKey) => {
          if (headerKey === 'date') {
            dataStartStanding[headerKey] = `${t('labels.excel.standing')} (${moment(new Date(dataStartStanding.date)).format(userDateFormat)})`;
            dataEndStanding[headerKey] = `${t('labels.excel.standing')} (${moment(new Date(dataEndStanding.date)).format(userDateFormat)})`;
            return;
          }

          dataStartStanding[headerKey] = (dataStartStanding[headerKey] < 0) || !dataStartStanding[headerKey] ? 0 : dataStartStanding[headerKey];
          dataStartStanding[headerKey] = dataStartStanding.cumulativeSum[headerKey]
            ? dataStartStanding.cumulativeSum[headerKey] - dataStartStanding[headerKey]
            : 0;
          dataStartStanding[headerKey] = (dataStartStanding[headerKey] < 0) || !dataStartStanding[headerKey] ? 0 : dataStartStanding[headerKey];

          dataEndStanding[headerKey] = (dataEndStanding[headerKey] < 0) || !dataEndStanding[headerKey] ? 0 : dataEndStanding[headerKey];
          dataEndStanding[headerKey] = dataEndStanding[headerKey] || 0;
          dataEndStanding[headerKey] = dataEndStanding.cumulativeSum[headerKey]
            ? dataEndStanding.cumulativeSum[headerKey]
            : 0;
          dataEndStanding[headerKey] = (dataEndStanding[headerKey] < 0) || !dataEndStanding[headerKey] ? 0 : dataEndStanding[headerKey];
        });
        //END: Entry above and below table
        //START: Adding first row to sheet
        worksheet.addRow(dataStartStanding);
        worksheet.addRow({});
        //END: Adding first row to sheet
      }
      worksheet.addRow(wsColumns.map(col => col.name));
      // START: Adding main table
      rawDataSheetData.map(dataSet => excelExporterRowFormatter(dataSet, columnNames, isStandardExport)).forEach(data => {
        worksheet.addRow(data);
      });

      //END: Adding main table
      if (isStandardExport) {
        // START: Adding sum row
        worksheet.addRow([t('labels.excel.sum'), ...wsColumns.filter(({ key }) => key !== 'date').map(col => '')]);
        const sumRow = worksheet.lastRow;
        const sumRowIndex = sumRow._number;
        if (sumRowIndex) {
          worksheet.lastRow._cells.forEach(({ _address }) => {
            const cellLetter = _address.replace(/\d+/ig, '');
            if (cellLetter !== 'A') {
              worksheet.getCell(_address).value = { formula: `SUM(${cellLetter}7:${cellLetter}${sumRowIndex - 1})` };
            }
          });
          // worksheet.lastRow._cells.forEach(({ _address }) => global.fvmLog(_address.replace(/\d+/ig, '')));
        }


        //END: Adding sum row
        // START: Adding last row to sheet
        worksheet.addRow({});
        worksheet.addRow(dataEndStanding);
        //END: Adding last row to sheet
      }
    } else {
      worksheet.addRow({});
      const startIndex = 'A';
      const endIndex = 'G';
      worksheet.mergeCells(`${startIndex}1`, `${endIndex}1`);
      worksheet.getCell(`${startIndex}1`).value = sheetName;
      worksheet.getCell(`${startIndex}1`).font = { bold: true, size: 18 };
      worksheet.getCell(`${startIndex}1`).alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.addRow({});
      worksheet.addRow({});
      worksheet.mergeCells(`${startIndex}3`, `${endIndex}3`);
      worksheet.getCell(`${startIndex}3`).value = t('shortMsgs.export.noDataAvailable');
      worksheet.getCell(`${startIndex}3`).font = { bold: true, size: 14 };
    }
  });
};

const metricAndTrainSorter = (a, b) => {
  const metricOrder = ['date', 'workingHours', 'equippedHours', 'standByHours', 'mileageKm', 'drivingHours', 'idleHours'];
  // const metricOrder = ['date', 'workingHours', 'equippedHours', 'idleHours', 'drivingHours', 'mileageKm', 'idleHours', 'standingWithEngineOn'];
  const aIndex = metricOrder.indexOf(a);
  const bIndex = metricOrder.indexOf(b);

  // sorting the metric headers on sheets and in tables
  if ((aIndex > -1) && (bIndex > -1)) {
    if (aIndex < bIndex) return -1;
    if (aIndex > bIndex) return 1;
  }
  // ensures that the date is in first position
  if (aIndex === 0) {
    return -1;
  }
  if (bIndex === 0) {
    return 1;
  }
  // regular sort
  if (a < b) return -1;
  if (a > b) return 1;

  return 0;
};

const excelExporterRowFormatter = (dataSet, columnNames, isStandardExport) => {
  const oldDataSet = isStandardExport ? dataSet : { ...dataSet, ...dataSet.cumulativeSum };
  const newDataSet = {};
  columnNames.forEach((headerKey) => {
    if (headerKey === 'date') {
      newDataSet[headerKey] = new Date(oldDataSet.date);
      return;
    }
    newDataSet[headerKey] = (oldDataSet[headerKey] < 0) || !oldDataSet[headerKey] ? 0 : oldDataSet[headerKey];
  });

  return columnNames.map(key => newDataSet[key]);
};

//CSV EXPORTING
const CSVExporter = ({ fetchedData, selectExportOptions, userLocomotiveListObject, t, userDateFormat, outputFormat, groupById }) => {
  const zip = AdmZip();
  const { accum } = selectExportOptions;
  const processedData = groupById
    ? filterExportDataByID({ exportData: fetchedData, userLocomotiveListObject })
    : filterExportDataByMetric({ exportData: fetchedData, userLocomotiveListObject });
  const data = [].concat(...Object.values(processedData));

  Object.keys(selectExportOptions).filter(selectionKey => selectExportOptions[selectionKey]).forEach(selectionKey => {
    Object.keys(processedData).filter(colName => !['cumulativeSum', ...METRIC_FILTER_LIST].includes(colName)).forEach((key) => {
      const fields = ['date', ...Object.keys(data[0]).filter(colName => !['date', 'cumulativeSum', ...METRIC_FILTER_LIST].includes(colName))].sort(metricAndTrainSorter).map((headerColName) => {
        if (headerColName === 'date') {
          return {
            label: t('tables.tHead.date'),
            value: (row, labelInfo) => moment(row[headerColName], 'YYYYMMDD').format(userDateFormat),
          };
        }
        const tempHeaderColName = outputFormat === 'by-id' ? t(`tables.tHead.${headerColName}`) : userLocomotiveListObject[headerColName].vehicle.name;
        return {
          label: tempHeaderColName,
          value: (row, labelInfo) => `${row[headerColName]}`,
          key: headerColName,
          stringify: true,
        };
      });

      const csv = json2csv.parse(
        selectionKey === 'accum' && accum ? processedData[key].map(({ date, cumulativeSum }) => ({ date, ...cumulativeSum })) : processedData[key],
        { fields, quote: '"' },
      );

      const name = outputFormat === 'by-id' ? userLocomotiveListObject[key].vehicle.name : t(`tables.tHead.${key}`);

      // Outputting CSV
      zip.addFile(
        `${selectionKey === 'accum' && accum ? t('labels.accum') : t('labels.standard')}/${moment().format('DD-MM-YYYY')}-Nrail-Export-${name.replace(/\//ig, ' ')}.csv`,
        Buffer.alloc(csv.length, csv),
        '');
    });
  });

  const output = new Blob([zip.toBuffer()], { type: 'text/plain;charset=utf-8' });
  FileSaver.saveAs(output, `${moment().format('DD-MM-YYYY')}-Nrail-Export.zip`);
};

export {
  excelRowFormatter,
  ExcelExporter,
  CSVExporter,
  filterExportDataByID,
  filterExportDataByMetric,
};