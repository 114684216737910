import { withStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch/Switch';
import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';

import {
  clickedHeatMap,
  clickedRailation,
  clickedShowRailation,
  clickedShowRailway,
  clickedShowSatellite,
  clickedTrackingMap,
  clickedTwitter,
  dateChanged,
  detailedDrawClosed,
} from '../../actions';
import FvmGAItemTracker from '../../components/FvmGAItemTracker';
// import TwitterTicker from '../../components/TwitterTicker';
import { getCurrentView } from '../../StatelessFunctions/nummericManapulation';

const styles = theme => ( {
  root: {
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
  },
  colorSwitchBase: {
    color: '#DFE3E6',
    '&$colorChecked': {
      color: '#999FB4',
      '& + $colorBar': {
        backgroundColor: '#DFE3E6',
      },
    },
  },
  railationButton: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  railationText: {
    fontSize: theme.typography.fontSize,
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'flex',
    alignItems: 'center',
  },
  switchLabels: {
    '& label': {
      flexWrap: 'wrap',
    },
  },
} );

class MapControls extends Component {

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      searchField: '',
      focusedInput: null,
      ...props.period,
    };

    this.toggleShowRailway = this.toggleShowRailway.bind(this);
    this.toggleShowRailation = this.toggleShowRailation.bind(this);
    this.toggleShowSatellite = this.toggleShowSatellite.bind(this);
    this.toggleHeatMapActive = this.toggleHeatMapActive.bind(this);
    this.toggleTrackingMapActive = this.toggleTrackingMapActive.bind(this);
    this.toggleShowSatellite = this.toggleShowSatellite.bind(this);
    this.toggleIsTwittter = this.toggleIsTwittter.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.toggleRailation = this.toggleRailation.bind(this);
    this.saveDate = this.saveDate.bind(this);
    this.isDateBlocked = this.isDateBlocked.bind(this);
    this.updateDates = this.updateDates.bind(this);

    if (typeof SVGElement.prototype.blur == 'undefined') {
      SVGElement.prototype.blur = function () {
      };
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeUserLocoId, isTrackingDisabled } = this.props;
    if (activeUserLocoId !== prevProps.activeUserLocoId) {
      if (isTrackingDisabled && ( isTrackingDisabled !== prevProps.isTrackingDisabled )) {
        this.props.onClickTrackingMap(false);
        this.props.onClickHeatMap(true);
        return;
      }
      this.updateDates();
    }
  }

  componentWillUnmount() {
    this.props.onClickRailation(false);
  }

  /**
   *
   * */
  toggleShowRailway() {
    const isShowRailway = !this.props.isShowRailway;
    this.props.onClickShowRailway(isShowRailway);
  }

  toggleShowSatellite() {
    const isShowSatellite = !this.props.isShowSatellite;
    this.props.onClickShowSatellite(isShowSatellite);
  }

  toggleShowRailation() {
    const isShowRailation = !this.props.isShowRailation;
    this.props.onClickedShowRailation(isShowRailation);
  }

  toggleIsTwittter() {
    const isTwitter = !this.props.isTwitter;
    this.props.onClickedTwitter(isTwitter);
  }

  toggleHeatMapActive() {
    const isHeatMap = !this.props.isHeatMap;
    this.props.onClickHeatMap(isHeatMap);

    if (!isHeatMap) {
      const isTrackingMap = true;
      this.props.onClickTrackingMap(isTrackingMap);
    }
  }

  toggleTrackingMapActive() {
    const isTrackingMap = !this.props.isTrackingMap;
    this.props.onClickTrackingMap(isTrackingMap);

    if (!isTrackingMap) {
      const isHeatMap = true;
      this.props.onClickHeatMap(isHeatMap);
    }
  }

  saveDate() {
    const { endDate, startDate } = this.state;

    if (endDate && endDate._isAMomentObject && startDate && startDate._isAMomentObject) {
      this.props.onDateChange({ startDate, endDate });
    }

    if (true) {
      this.setState({ focusedInput: null });
    }
  }

  isTrackingPage() {
    return ['trackingmap'].includes(this.props.currentView);
  }

  handleDateChange({ startDate, endDate }) {
    this.setState({ startDate, endDate });
  }

  toggleRailation(e) {
    const { isRailationDrawOpen } = this.props;
    // e.stopPropagation();
    this.props.onClickRailation(!isRailationDrawOpen);
    if (isRailationDrawOpen) {
      this.props.detailedDrawClosed(isRailationDrawOpen);
    }
  }

  isDateBlocked(date) {
    const { activeUserLocoId, userLocomotiveListObject, currentView } = this.props;
    const { startDate } = activeUserLocoId && userLocomotiveListObject[activeUserLocoId];
    const { endDate } = activeUserLocoId && userLocomotiveListObject[activeUserLocoId];

    if (currentView === 'trackingmap' && date.isAfter(moment().startOf('day').subtract(1, 'seconds'))) {
      return true;
    }
    if (endDate && moment(date).subtract(1, 'day').isAfter(endDate)) {
      return true;
    }
    if (startDate && date.isBefore(startDate)) {
      return true;
    }

    return date.isAfter(moment().subtract(1, 'seconds'));
  }

  updateDates() {
    if (!this.state.startDate || !this.state.endDate) {
      return;
    }

    const { activeUserLocoId, userLocomotiveListObject } = this.props;
    const { startDate } = activeUserLocoId && userLocomotiveListObject[activeUserLocoId];
    const { endDate } = activeUserLocoId && userLocomotiveListObject[activeUserLocoId];

    const changeStartDate = startDate && ( startDate.isAfter(this.state.startDate) || ( endDate && this.state.startDate.isAfter(endDate) ) );
    const changeEndDate = endDate && endDate.isBefore(this.state.endDate);

    if (changeStartDate && changeEndDate) {
      if (endDate && this.state.startDate.isAfter(endDate)) {
        this.setState({ startDate: moment(endDate).subtract(7, 'days'), endDate }, this.saveDate);
        return;
      }
      this.setState({ startDate, endDate }, this.saveDate);
      return;
    }

    if (changeStartDate) {
      if (endDate && this.state.startDate.isAfter(endDate)) {
        this.setState({ startDate: moment(endDate).subtract(7, 'days'), endDate }, this.saveDate);
        return;
      }
      this.setState({ startDate, endDate: this.state.endDate }, this.saveDate);
      return;
    }

    if (changeEndDate) {
      this.setState({ startDate: this.state.startDate, endDate }, this.saveDate);
    }
  }

  render() {
    const {
      isShowRailway,
      isShowSatellite,
      isShowRailation,
      isTrackingMap,
      isHeatMap,
      t,
      classes,
      currentView,
      // isTwitter,
      isRailationDrawOpen,
      isRailation,
      isDatePickerDisabled,
      isTrackingDisabled,
      reportCategory,
    } = this.props;

    return (
      <div
        className={`fvm-controls ${classNames(classes.switchLabels)}`}
      >
        <ul className="">

          {
            // isRailation && (
            //   <FvmGAItemTracker
            //     category={'Map Controls'}
            //     label={`Railation`}
            //     action={`Toggled`}
            //     value={!isRailationDrawOpen ? 1 : 0}
            //
            //   >
            //     <li className="fvm-list-seperator">
            //       <IconButton
            //         color="inherit"
            //         aria-label="Open drawer"
            //         onClick={this.toggleRailation}
            //       >
            //         {isRailationDrawOpen
            //           ? <Close
            //             color="primary"
            //           />
            //           : <MenuIcon
            //             className={classNames(classes.railationButton)}
            //           />
            //         }
            //       </IconButton>
            //       <span
            //         className={classNames(classes.railationText)} role="button" onClick={this.toggleRailation}
            //       >
            //       {t('Railation')}
            //     </span>
            //     </li>
            //   </FvmGAItemTracker>
            // )
          }
          {['trackingmap', 'reporting', 'notification', 'logbook', 'mapbox', 'mapbox-vs-google', 'google-maps'].includes(currentView) && (
            <FvmGAItemTracker
              category={reportCategory || 'Map Controls'}
              label={`Calendar`}
              action={`Date field selected`}
            >
              <li>
                <FormControlLabel
                  disabled={isDatePickerDisabled}
                  className="fvm-date-picker-listelm"
                  labelPlacement="start"
                  control={
                    <DateRangePicker
                      renderCalendarInfo={() => <div className="fvm-date-picker-controls">
                        <button
                          onClick={() => {
                            document.querySelector('.fvm-controls .DateRangePickerInput_clearDates').click();
                          }}
                        >
                          {t('button.delete')}
                        </button>
                        <button onClick={this.saveDate}>{t('button.apply')}</button>
                      </div>}
                      keepOpenOnDateSelect
                      isOutsideRange={() => false}
                      readOnly
                      showClearDates
                      appendToBody
                      reopenPickerOnClearDates
                      displayFormat="DD.MM.YYYY"
                      startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                      startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                      endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                      endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                      onDatesChange={this.handleDateChange}
                      focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                      onClose={this.saveDate}
                      firstDayOfWeek={1}
                      hideKeyboardShortcutsPanel
                      block
                      small
                      noBorder
                      minimumNights={0}
                      startDatePlaceholderText={t('placeHolders.startDate')}
                      endDatePlaceholderText={t('placeHolders.endDate')}
                      isDayBlocked={this.isDateBlocked}
                    />
                  }
                />
              </li>
            </FvmGAItemTracker>
          )}
          {this.isTrackingPage() && (
            <FvmGAItemTracker
              category={'Map Controls'}
              label={`Tracking Map Switch`}
              action={`Toggled`}
              value={isTrackingMap}
            >
              <li className="fvm-trackingMap-switch">
                <FormControlLabel
                  label={t('labels.trackingMap')}
                  className="db-switch"
                  labelPlacement="start"
                  disabled={isTrackingDisabled}
                  classes={{
                    root: classes.root,
                    label: classes.root,
                  }}
                  control={
                    <Switch
                      onChange={this.toggleTrackingMapActive}
                      checked={isTrackingMap}
                      // disabled={isTrackingDisabled}
                      value="trackingmap"
                      color="primary"
                      classes={{
                        switchBase: classes.colorSwitchBase,
                      }}
                    />
                  }
                />
              </li>
            </FvmGAItemTracker>
          )}
          {this.isTrackingPage() && (
            <FvmGAItemTracker
              category={'Map Controls'}
              label={`Heat Map Switch`}
              action={`Toggled`}
              value={!isHeatMap ? 1 : 0}
            >
              <li className="fvm-heatmap-switch fvm-list-seperator">
                <FormControlLabel
                  label={t('labels.heatmap')}
                  className="db-switch"
                  labelPlacement="start"
                  classes={{
                    root: classes.root,
                    label: classes.root,
                  }}
                  control={
                    <Switch
                      onChange={this.toggleHeatMapActive}
                      checked={isHeatMap}
                      value="heatmap"
                      color="primary"
                      classes={{
                        switchBase: classes.colorSwitchBase,
                      }}
                    />
                  }
                />
              </li>
            </FvmGAItemTracker>
          )}
          {/*{['trackingmap', 'livedata', 'railation'].includes(currentView) && (*/}
          {/*  <li />*/}
          {/*)}*/}
          {['trackingmap', 'dashboard', 'livedata', 'railation', 'mapbox', 'mapbox-vs-google', 'google-maps'].includes(currentView) && (
            <FvmGAItemTracker
              category={'Map Controls'}
              label={`Railway View Switch`}
              action={`Toggled`}
              value={!isShowRailway ? 1 : 0}
            >
              <li className="fvm-railWayView-switch">
                <FormControlLabel
                  label={t('labels.railWayView')}
                  className="db-switch"
                  labelPlacement="start"
                  classes={{
                    root: classes.root,
                    label: classes.root,
                  }}
                  control={
                    <Switch
                      onChange={this.toggleShowRailway}
                      checked={isShowRailway}
                      value="showrailway"
                      size={this.props.zoomFactor < 1 ? 'small' : 'medium'}
                      color="primary"
                      classes={{
                        switchBase: classes.colorSwitchBase,
                        root: classes.root,
                      }}
                    />
                  }
                />
              </li>
            </FvmGAItemTracker>
          )}
          {['trackingmap', 'dashboard', 'livedata', 'railation', 'mapbox', 'mapbox-vs-google', 'google-maps'].includes(this.props.currentView) && (
            <FvmGAItemTracker
              category={'Map Controls'}
              label={`Map View Switch`}
              action={`Toggled`}
              value={!isShowRailation ? 1 : 0}
            >
              <li className="fvm-satelliteView-switch">
                <FormControlLabel
                  label={t('labels.satelliteView')}
                  className={classNames(classes.toolbarControlSwitch)}
                  labelPlacement="start"
                  classes={{
                    root: classes.root,
                    label: classes.root,
                  }}
                  control={
                    <Switch
                      onChange={this.toggleShowSatellite}
                      checked={isShowSatellite}
                      size={this.props.zoomFactor < 1 ? 'small' : 'medium'}
                      value="showsatellite"
                      color="primary"
                      classes={{
                        switchBase: classes.colorSwitchBase,
                      }}
                    />
                  }
                />
              </li>
            </FvmGAItemTracker>
          )}
          {['trackingmap', 'dashboard', 'livedata', 'railation', 'mapbox', 'mapbox-vs-google', 'google-maps'].includes(this.props.currentView) && (
            <FvmGAItemTracker
              category={'Map Controls'}
              label={`Railation Switch`}
              action={`Toggled`}
              value={!isShowRailation ? 1 : 0}
            >
              <li className="fvm-satelliteView-switch">
                <FormControlLabel
                  label={t('labels.railation')}
                  className={classNames(classes.toolbarControlSwitch)}
                  labelPlacement="start"
                  classes={{
                    root: classes.root,
                    label: classes.root,
                  }}
                  control={
                    <Switch
                      onChange={this.toggleShowRailation}
                      checked={isShowRailation}
                      size={this.props.zoomFactor < 1 ? 'small' : 'medium'}
                      value="showrailation"
                      color="primary"
                      classes={{
                        switchBase: classes.colorSwitchBase,
                      }}
                    />
                  }
                />
              </li>
            </FvmGAItemTracker>
          )}

          {/*{*/}
          {/*  ['railation'].includes(this.props.currentView) && (*/}
          {/*    <FvmGAItemTracker*/}
          {/*      category={'Map Controls'}*/}
          {/*      label={`Twitter News Switch`}*/}
          {/*      action={`Toggled`}*/}
          {/*      value={!isTwitter ? 1 : 0}*/}
          {/*    >*/}
          {/*      <li className="fvm-twitter-switch">*/}
          {/*        <FormControlLabel*/}
          {/*          label={t('labels.twitterSwitch')}*/}
          {/*          labelPlacement="start"*/}
          {/*          classes={{*/}
          {/*            root: classes.root,*/}
          {/*            label: classes.root,*/}
          {/*          }}*/}
          {/*          control={*/}
          {/*            <Switch*/}
          {/*              onChange={this.toggleIsTwittter}*/}
          {/*              checked={isTwitter}*/}
          {/*              size={ this.props.zoomFactor < 1 ? "small" : "medium"}*/}
          {/*              value="showTweets"*/}
          {/*              color="primary"*/}
          {/*              classes={{*/}
          {/*                switchBase: classes.colorSwitchBase,*/}
          {/*              }}*/}
          {/*            />*/}
          {/*          }*/}
          {/*        />*/}
          {/*      </li>*/}
          {/*    </FvmGAItemTracker>*/}
          {/*  )*/}
          {/*}*/}
        </ul>
        {/*<ul*/}
        {/*  className={classNames('railation-twitter-list',*/}
        {/*    ['dashboard'].includes(this.props.currentView) || isRailation ? 'full-width-logged-in' : '',*/}
        {/*    isRailation ? 'full-width-railation' : '',*/}
        {/*  )}*/}
        {/*>*/}
        {/*  {*/}
        {/*    ['railation'].includes(this.props.currentView) && (*/}
        {/*      <FvmGAItemTracker*/}
        {/*        category={'Map Controls'}*/}
        {/*        label={`Twitter News Switch`}*/}
        {/*        action={`Toggled`}*/}
        {/*      >*/}
        {/*        <li className="fvm-twitter-list-item">*/}
        {/*          <TwitterTicker t={this.props.t} />*/}
        {/*        </li>*/}
        {/*      </FvmGAItemTracker>*/}
        {/*    )*/}
        {/*  }*/}


        {/*</ul>*/}

        {/*// To be uncommented when Railaition is done for the new maps*/}
        {/*<ul*/}
        {/*  className={classNames(isRailation ? 'full-width-railation' : '')}*/}
        {/*>*/}
        {/*  {*/}
        {/*    !isRailation && ['trackingmap', 'dashboard', 'mapbox', 'mapbox-vs-google', 'google-maps'].includes(this.props.currentView) && (*/}
        {/*      <FvmGAItemTracker*/}
        {/*        category={'Map Controls'}*/}
        {/*        label={`Railation`}*/}
        {/*        action={`Toggled`}*/}
        {/*        value={!isRailationDrawOpen ? 1 : 0}*/}
        {/*      >*/}
        {/*        <li className="raliation-control-list-item-2">*/}
        {/*          <IconButton*/}
        {/*            color="inherit"*/}
        {/*            aria-label="Open drawer"*/}
        {/*            onClick={this.toggleRailation}*/}
        {/*          >*/}
        {/*            {*/}
        {/*              isRailationDrawOpen*/}
        {/*              ? <Close*/}
        {/*                color="primary"*/}
        {/*              />*/}
        {/*              : <MenuIcon*/}
        {/*                className={classNames(classes.railationButton)}*/}
        {/*              />*/}
        {/*            }*/}
        {/*          </IconButton>*/}
        {/*          <span*/}
        {/*            className={classNames(classes.railationText)} role="button" onClick={this.toggleRailation}*/}
        {/*          >*/}
        {/*          {t('Railation')}*/}
        {/*        </span>*/}

        {/*        </li>*/}
        {/*      </FvmGAItemTracker>*/}
        {/*    )*/}
        {/*  }*/}
        {/*</ul>*/}
      </div>
    );
  }
}

const mapStateToProps = state => ( {
  period: state.period,
  activeUserLocoId: [...state.activeUserLocomotiveListIds].pop() || 0,
  currentView: getCurrentView(), // temp test solution
  isShowRailway: state.showRailway,
  isShowSatellite: state.showSatellite,
  isShowRailation: state.showRailation,
  isTrackingMap: state.trackingMap,
  isRailationDrawOpen: state.railationDrawOpen,
  isTwitter: state.isTwitter,
  isHeatMap: state.heatMap,
  isDemoUser: state.isDemoUser,
  locListUpdated: state.locListUpdated,
  userLocomotiveListObject: state.userLocomotiveList,
  userLocomotiveList: Object.values(state.userLocomotiveList).map(obj => obj),
  zoomFactor: window.localStorage.getItem('zoomActive'),
} );

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    onClickHeatMap: clickedHeatMap,
    onClickedTwitter: clickedTwitter,
    onClickShowRailway: clickedShowRailway,
    onClickShowSatellite: clickedShowSatellite,
    onClickedShowRailation: clickedShowRailation,
    onClickTrackingMap: clickedTrackingMap,
    onClickRailation: clickedRailation,
    onDateChange: dateChanged,
    detailedDrawClosed,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapControls));
