import React from 'react';
const SubCatPersonnel = () => {


  const a = {
    fill: '#e5e5e5',
    opacity: 0,
  }
  const b = {
    fill: '#002fc9',
    stroke: 'rgba(0,0,0,0)',
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {/*<style>.a{fill:#e5e5e5;opacity:0;}.b{fill:#002fc9;stroke:rgba(0,0,0,0);}</style>*/}
      <g transform="translate(-441 -645)">
        <rect style={a} width="24" height="24" transform="translate(441 645)" />
        <path
          style={b}
          className="b"
          d="M-810,624v-1a7,7,0,0,1,7-7h2a7,7,0,0,1,7,7v1Zm4.5-12.5A3.5,3.5,0,0,1-802,608a3.5,3.5,0,0,1,3.5,3.5A3.5,3.5,0,0,1-802,615,3.5,3.5,0,0,1-805.5,611.5Z"
          transform="translate(1255 41)"
        />
      </g>
    </svg>
  );
};
export default SubCatPersonnel;
