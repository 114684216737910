import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles(theme => ( {
  titleStyles: {
    paddingTop: 10,
    marginBottom: 10,
  },
  inlineStyles: {
    display: 'inline !important',
  },

} ));
const ViewTitle = ({ title, inline, variant }) => {
  const classes = useStyles();
  const { inlineStyles, titleStyles } = inline ? classes : {};
  return (
    <Typography
      className={[titleStyles, inline ? inlineStyles : ''].join(' ')}
      variant={variant || 'h1'}
    >
      {title}
    </Typography>
  );
};

export default ViewTitle;