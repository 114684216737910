import { Button, Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import TilesIcon from '@material-ui/icons/ViewModuleSharp';
import ListIcon from '@material-ui/icons/ViewStreamSharp';
import classNames from 'classnames';
import moment from 'moment';
import React, { Component, Suspense } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  clickedSelectAllDevices,
  clickedSelectAllDriving,
  clickedSelectAllEquipped,
  clickedSelectAllOff,
  clickedSelectAllStanding,
  setFetchActiveLocoListData, setHideLoginUntilOnline,
  setIsHaveCranes, setIsLocoControlHidden,
  setRemoveFocusId,
  setUpdateView,
} from '../../actions';
import {
  changedView,
  locomotiveListLayout,
  setDataSheetStaticData,
  setlastDataReceivedTimestamp,
  setUserMadeInitialDeviceSelection,
  stecConnect,
  updateActiveCraneIds,
  updateTargetLocomotiveId,
  updateTrackedLocomotiveId,
  updateUserLocomotivesList,
} from '../../actions/index';
import FvmGAItemTracker from '../../components/FvmGAItemTracker';
import BootstrapTooltip from '../../components/FvmMaterilUiTooltip';
import LoadingSpinner from '../../components/LoadingSpinner';
import LocomotiveListItem from '../../components/LocomotiveListItem';
import getStatusIcon from '../../components/LocomotiveStatusIcons';
import SearchBar from '../../containers/SearchBar/index';
import { FUEL_CONSUMPTION_DEFAULTS } from '../../globalConstants/defaultValues';
import {
  fetchDatasheet,
  fetchSTECDashboardData,
  fetchSTECSimpleData,
  fetchUserDevices,
} from '../../wp-rest-api/STEC-Communication';

import styles from './styles';
import { VEHICLE_STATUS_NAMES } from '../../globalConstants/vechicelConsts';
import { getCurrentView } from '../../StatelessFunctions/nummericManapulation';
import {
  onConfirmRefresh,
  syncLogbookEntries,
} from '../../components/Logbook/logbook-utils/utils';
import LocoListIcon from '@material-ui/icons/DirectionsSubwayOutlined';
import IconButton from '@material-ui/core/IconButton';


class LocomotiveList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAllDropDown: 'select',
      selectAllDropDownOpen: false,
      listViewActive: false,
      index: 0,
      noResponseIds: [],
    };

    this.setUserLocomotives = this.setUserLocomotives.bind(this);
    this.onClickSelectAll = this.onClickSelectAll.bind(this);
    this.onClickFocusLoco = this.onClickFocusLoco.bind(this);
    this.onClickTrackLoco = this.onClickTrackLoco.bind(this);
    this.onClickLocomotive = this.onClickLocomotive.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.determineIsCardActive = this.determineIsCardActive.bind(this);
    this.selectAllItemsDropdownChange = this.selectAllItemsDropdownChange.bind(this);
    this.setSelectAllDropDownOpen = this.setSelectAllDropDownOpen.bind(this);
    this.removeFocus = this.removeFocus.bind(this);
    this.fetchActiveLocoList = this.fetchActiveLocoList.bind(this);
    this.clearPolling = this.clearPolling.bind(this);
    // STEC Functions
    // this.fetchSimpleData = debounce(this.fetchSimpleData.bind(this), 200);
    this.fetchSimpleData = this.fetchSimpleData.bind(this);
    // Helper functions
  }

  componentDidMount() {
    this.props.onChangedView();
    //window.removeEventListener('blur', this.clearPolling);
    this.pollingFunctionEvens();
  }

  componentWillUnmount() {
    this.clearPolling();
  }

  showSnackBar(message, variant, duration = 3000) {
    this.props.enqueueSnackbar(message, {
      variant: variant,
      autoHideDuration: duration,
    });
  }

  fetchActiveLocoList() {
    const vehicleIds = this.props.activeUserLocomotiveListIds.length ? this.props.activeUserLocomotiveListIds : Object.keys(this.props.userLocomotiveListObject);

    // if(!!bvcthis.props.user.accessRights?.['gateway/telematics']?.canRead){
    fetchSTECDashboardData(vehicleIds.join(','))
      .then((resp) => this.updateUserLocoFromServer(resp, [...vehicleIds]))
      .catch((e) => {
        console.error(e);
        return [];
      })
      .finally(() => {
        this.props.setFetchActiveLocoListData(false);
        this.props.setUpdateView(true);
      });
    // }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      removeFocusId,
      languageFilesReloaded,
      lastDataReceivedTimestamp,
      userDeviceList,
      selectAllLoco,
      selectAllDriving,
      selectAllStanding,
      selectAllEquipped,
      selectAllOff,
      isSingleSelect,
      selectAllDevices,
      toggleAllCranes,
      activeUserLocomotiveListIds,
      isFetchActiveLocoListData,
      user,
      isLoggedIn,
      isOnline,
    } = this.props;

    if (isOnline !== prevProps?.isOnline) {
      if (isOnline) {
        window.removeEventListener('beforeunload', onConfirmRefresh);
        this.props.setHideLoginUntilOnline(false);
        this.syncLogbook(true);
      } else {
        this.props.setHideLoginUntilOnline(false);
        window.addEventListener('beforeunload', onConfirmRefresh);
      }
    }

    if (
      selectAllLoco !== prevProps.selectAllLoco ||
      selectAllDriving !== prevProps.selectAllDriving ||
      selectAllStanding !== prevProps.selectAllStanding ||
      selectAllEquipped !== prevProps.selectAllEquipped ||
      selectAllOff !== prevProps.selectAllOff ||
      isSingleSelect !== prevProps.isSingleSelect ||
      selectAllDevices !== prevProps.selectAllDevices ||
      toggleAllCranes !== prevProps.toggleAllCranes ||
      selectAllLoco !== prevProps.selectAllLoco ||
      activeUserLocomotiveListIds.join(',') !== prevProps.activeUserLocomotiveListIds.join(',')
    ) {
      this.refreshLocos({});
    }

    if (isFetchActiveLocoListData && ( isFetchActiveLocoListData !== prevProps.isFetchActiveLocoListData )) {
      this.fetchActiveLocoList();
    }

    if (userDeviceList.length && !this.state.index) {
      this.setState({ index: userDeviceList.length < 21 ? userDeviceList.length : 19 });
    }

     if (prevProps?.isLoggedIn !== isLoggedIn ) {
       console.log('settingup')
       //this.pollingFunctionEvens();
     }

    if (lastDataReceivedTimestamp !== prevProps.lastDataReceivedTimestamp) {
      this.refreshLocos({ locoChanged: true, timeStampChange: true });
    }
    if (removeFocusId && removeFocusId !== prevState.removeFocusId) {
      this.removeFocus();
    }
    if (languageFilesReloaded !== prevProps.languageFilesReloaded) {
      this.setState({ componentLanguageUpdateTrigger: languageFilesReloaded });
      this.getDatasheetTableData();
    }

    if (
      user?.oldLoginId !== prevProps?.user?.oldLoginId ||
      ( user?.accountId && user?.activeOrganisationId &&
        (
          user?.activeOrganisationId !== prevProps?.user?.activeOrganisationId
          || user?.accountId !== prevProps?.user?.accountId
        )
      )
    ) {
     this.syncLogbook(true);
      //this.pollingFunctionEvens();
      this.getWpUserLocoData(this.setUserLocomotives);
    }

    if (!isOnline && prevProps.isOnline !== isOnline) {
      if (localStorage.getItem('userLocoList')) {
        this.updateUserLocoFromServer(JSON.parse(localStorage.getItem('userLocoList')), undefined);
      }
    }
  }


  pollingFunctionEvens = () => {
    console.log(this.props?.isLoggedIn,  !this.generalUpdateId)

    if(this.props?.isLoggedIn && !this.generalUpdateId) {
      this.pollingSetup();
      setTimeout(()=> window.addEventListener('focus', this.pollingSetup), 300);
    }
  };


  syncLogbook = (giveFeedback = false) => {
    syncLogbookEntries({
      successCallback: () => giveFeedback ? this.showSnackBar(this.props.t('labels.logbookUnsavedEntriesSynced'), 'success') : null,
    }).then((resp) => {
      if (resp === 'sync_in_process') {
        console.log('sync_in_process');
      }
    });
  };

  determineIsCardActive(loco, previousState) {
    const {
      selectAllLoco,
      selectAllDriving,
      selectAllStanding,
      selectAllEquipped,
      selectAllOff,
      isSingleSelect,
      selectAllDevices,
    } = this.props;

    const activeFilters = [];
    if (!isSingleSelect) {
      if (selectAllDriving || selectAllLoco || selectAllDevices) {
        activeFilters.push(VEHICLE_STATUS_NAMES.DRIVING);
      }
      if (selectAllStanding || selectAllLoco || selectAllDevices) {
        activeFilters.push(VEHICLE_STATUS_NAMES.STANDING);
      }
      if (selectAllEquipped || selectAllLoco || selectAllDevices) {
        activeFilters.push(VEHICLE_STATUS_NAMES.EQUIPPED);
      }
      if (selectAllOff || selectAllLoco || selectAllDevices) {
        activeFilters.push(VEHICLE_STATUS_NAMES.OFF);
      }
    }

    if (activeFilters.length && loco.live) {
      return activeFilters.includes(loco.live.statusVehicle);
    }

    return previousState;
  }

  pollingSetup = () => {
    this.clearPolling();
    if (this.props?.isOnline && this.props?.isLoggedIn) {
      this.fetchSimpleData();
      this.syncLogbook(false);
    }

    const generalUpdateId = setInterval(() => {
      if (this.props?.isOnline && this.props?.isLoggedIn) {
        this.fetchSimpleData();
        if (['dashboard', 'livedata'].includes(getCurrentView())) {
          setTimeout(() => {
            if (this.props.activeUserLocomotiveListIds.length) {
              this.fetchActiveLocoList();
            }
          }, 15 * 1000);
        }
      }
    }, 30 * 1000);

    const generalLogbookPollingId = setInterval(() => {
      if (this.props?.isOnline && this.props?.isLoggedIn) {
        this.syncLogbook(false);
      }
    }, 30 * 1000);

    this.generalLogbookPollingId = generalLogbookPollingId;
    this.generalUpdateId = generalUpdateId;

    return { generalUpdateId, generalLogbookPollingId };
  }

  clearPolling() {
    if (this.generalUpdateId) {
      clearInterval(this.generalUpdateId);
      this.generalUpdateId = undefined;
    }
    if (this.generalLogbookPollingId) {
      console.log('generalLogbookPollingId removed',this.generalLogbookPollingId)
      clearInterval(this.generalLogbookPollingId);
      this.generalLogbookPollingId = undefined;
    }
  }

  refreshLocos({ timeStampChange = false }) {
    const {
      activeUserLocomotiveListIds,
      userLocomotiveListObject,
      selectAllLoco,
      selectAllDriving,
      selectAllStanding,
      selectAllEquipped,
      selectAllOff,
      userDeviceList,
      isSingleSelect,
      selectAllDevices,
      toggleAllCranes,
    } = this.props;

    const activeFilters = [];

    if (isSingleSelect && activeUserLocomotiveListIds.length > 0) {
      let singleActive = [...activeUserLocomotiveListIds].pop();
      this.props.onClickedSelectAllDevices(false, true);

      if (this.props.currentView === 'trackingmap' && this.props.userLocomotiveListObject[singleActive].isCrane) {
        singleActive = 0;
      }
      this.setAllLocoInActive([singleActive], userLocomotiveListObject, timeStampChange);
    }

    if (!isSingleSelect) {

      if (selectAllDriving || selectAllLoco || selectAllDevices) {
        activeFilters.push(VEHICLE_STATUS_NAMES.DRIVING);
      }
      if (selectAllStanding || selectAllLoco || selectAllDevices) {
        activeFilters.push(VEHICLE_STATUS_NAMES.STANDING);
      }
      if (selectAllEquipped || selectAllLoco || selectAllDevices) {
        activeFilters.push(VEHICLE_STATUS_NAMES.EQUIPPED);
      }
      if (selectAllOff || selectAllLoco || selectAllDevices) {
        activeFilters.push(VEHICLE_STATUS_NAMES.OFF);
      }

      if (activeFilters.length || timeStampChange) {
        let newLst = [];
        let newCranLst = [];
        if (activeFilters.length) {
          newLst = [...userDeviceList].filter(loco => loco.isTrain).filter(loco => activeFilters.includes(loco.live.statusVehicle)).map(loco => loco.vehicleId);
          newCranLst = [...userDeviceList].filter(loco => loco.isCrane).filter(loco => activeFilters.includes(loco.live.statusVehicle)).map(loco => loco.vehicleId);
          // newCranLst = [...userDeviceList].filter(loco => loco.isCrane && (toggleAllCranes ||
          // selectAllDevices)).map(crane => crane.vehicleId);
        } else {
          newLst = [...userDeviceList].filter(loco => loco.isCardActive).map(loco => loco.vehicleId);
        }
        this.setAllLocoInActive([...newLst, ...newCranLst], userLocomotiveListObject, timeStampChange);
      }
    }
  }

  onClickLocomotive(loco) {
    const editedElements = {};
    // copying the old loco and updating:  isCardActive and isFocused
    editedElements[loco.vehicleId] = Object.assign(
      {},
      this.props.userLocomotiveListObject[loco.vehicleId],
      { isCardActive: !loco.isCardActive, isFocused: false, activeTime: new Date() },
    );

    if (loco.isFocused) {
      this.removeFocus();
    }
    this.props.setUserMadeInitialDeviceSelection(true);
    this.props.onClickedSelectAllDevices(false, true);
    this.props.updateUserLocomotivesList({ ...this.props.userLocomotiveListObject, ...editedElements });
  }

  onClickFocusLoco(loco) {
    const editedElements = {};
    editedElements[loco.vehicleId] = Object.assign(
      {},
      this.props.userLocomotiveListObject[loco.vehicleId],
      { isFocused: !loco.isFocused },
    );

    if (this.props.targetedUserLocomotiveId !== loco.vehicleId && this.props.targetedUserLocomotiveId) {
      editedElements[this.props.targetedUserLocomotiveId] = Object.assign(
        {},
        this.props.userLocomotiveListObject[this.props.targetedUserLocomotiveId],
        { isFocused: false },
      );
    }

    // updating the redux state with the new list of locomotives
    this.props.updateUserLocomotivesList({ ...this.props.userLocomotiveListObject, ...editedElements });
    this.props.updateTargetLocomotiveId(!loco.isFocused ? loco.vehicleId : 0);
  }

  onClickTrackLoco(loco) {
    const editedElements = {};
    editedElements[loco.vehicleId] = Object.assign(
      {},
      this.props.userLocomotiveListObject[loco.vehicleId],
      // { isTracking: !loco.isTracking },
    );

    if (this.props.targetedUserLocomotiveId !== loco.vehicleId && this.props.targetedUserLocomotiveId) {
      editedElements[this.props.targetedUserLocomotiveId] = Object.assign(
        {},
        this.props.userLocomotiveListObject[this.props.targetedUserLocomotiveId],
        // { isTracking: false },
      );
    }

    // updating the redux state with the new list of locomotives
    this.props.updateUserLocomotivesList({ ...this.props.userLocomotiveListObject, ...editedElements });
    this.props.updateTrackedLocomotiveId(!loco.isTracking ? loco.vehicleId : 0);
  }

  removeFocus(removeFocusId = this.props.targetedUserLocomotiveId) {
    this.onClickFocusLoco(this.props.userLocomotiveListObject[removeFocusId]);
    this.props.setRemoveFocusId(null);
  }

  onClickSelectAll() {
    const { userDeviceList, userLocomotiveListObject } = this.props;
    const filteredUserLst = [...userDeviceList].filter(train => train.isVisible);
    const filteredUserLstIds = filteredUserLst.map(loco => loco.vehicleId);
    const isAtLeastOneActiveItem = !!filteredUserLst.find(train => train.isCardActive);
    const filteredLocoObj = {};
    filteredUserLstIds.forEach(locoId => filteredLocoObj[locoId] = userLocomotiveListObject[locoId]);
    this.props.setUserMadeInitialDeviceSelection(true);
    this.props.onClickedSelectAllDevices(false, true);

    this.setAllLocoInActive(isAtLeastOneActiveItem ? [] : filteredUserLstIds, filteredLocoObj);
  }

  setAllLocoInActive(exceptTheseLoco = [], userLocomotiveListObject = this.props.userLocomotiveListObject, clearInitialLoad = true) {
    const newLocoObj = {};
    Object.values(userLocomotiveListObject).map(loco => loco.vehicleId).forEach((vehicleId) => {
      newLocoObj[vehicleId] = Object.assign({}, userLocomotiveListObject[vehicleId], {
        isCardActive: false,
        isFocused: false,
      });
    });

    if (this.props.targetedUserLocomotiveId && !exceptTheseLoco.find(id => this.props.targetedUserLocomotiveId === id)) {
      this.removeFocus();
    }

    exceptTheseLoco.filter(id => !!id).forEach((vehicleId) => {
      newLocoObj[vehicleId] = Object.assign({}, userLocomotiveListObject[vehicleId], { isCardActive: true });
    });

    this.props.updateUserLocomotivesList({ ...this.props.userLocomotiveListObject, ...newLocoObj });
  }

  selectAllItemsDropdownChange(event) {
    const { userDeviceList, userLocomotiveListObject } = this.props;
    const { value } = event.target;
    if (value === 'deselect') {
      const filteredLocoObj = {};
      const filteredUserLst = [...userDeviceList].filter(train => train.isVisible).map(loco => loco.vehicleId);
      filteredUserLst.forEach(locoId => filteredLocoObj[locoId] = userLocomotiveListObject[locoId]);
      this.setAllLocoInActive(value === 'select' ? filteredUserLst : [], filteredLocoObj);
    } else {
      if (value === 'selectAll') {
        this.setAllLocoInActive(userDeviceList.filter(({ vehicleId }) => vehicleId).map(({ vehicleId }) => vehicleId));
      }
      if (value === 'deselectAll') {
        this.setAllLocoInActive();
      }
    }
    this.props.setUserMadeInitialDeviceSelection(true);
    this.props.onClickedSelectAllDevices(false, true);

    this.setState({ selectAllDropDown: value });
  }

  setSelectAllDropDownOpen(selectAllDropDownOpen) {
    this.setState({ selectAllDropDownOpen });
  }

  async getWpUserLocoData(asyncCallbackFunc) {
    const userId = this.props?.user?.oldLoginId;
    if (!userId) {
      return;
    }

    this.showSpinner(0);

    if (!this.props.isOnline) {
      if (localStorage.getItem('userLocoList')) {
        asyncCallbackFunc(JSON.parse(localStorage.getItem('userLocoList')));
        this.hideSpinner(3000);
        return;
      }
    }

    fetchUserDevices({ userId })
      .then(({ data }) => {
        asyncCallbackFunc(data.items);
      }).catch(e => {
      this.showConnectionError();
      asyncCallbackFunc([{}]);
    }).finally(() => {
      this.hideSpinner(3000);
    });
  }

  async setUserLocomotives(nrailObj) {
    const locoList = {};
    const selectAllLoco = ['railation'].includes(this.props.currentView);
    const userLiveData = await this.fetchSimpleData(true);
    const userHasCranes = false;
    userLiveData.forEach(loco => locoList[loco.vehicleId] = { ...loco });

    nrailObj.forEach(({ vehicle, todays = {}, ...locoObj }, sortIndex) => {
      if (vehicle) {

        const isCrane = vehicle?.model === 'Kran';
        if (!userHasCranes && isCrane) {
          const userHasCranes = true;
          this.props.setIsHaveCranes(true);
        }

        locoList[locoObj.vehicleId] = {
          ...locoList[locoObj.vehicleId],
          ...locoObj,
          vehicle: {
            ...vehicle,
            name: this.props.isDevelopment ? `${vehicle.name} (${locoObj.vehicleId})` : vehicle.name,
            type: vehicle.model === 'Kran' ? 'crane' : vehicle.type,
          },
          isTrain: vehicle.model !== 'Kran',
          isCrane: isCrane,
          isEloc: vehicle.engine === 'electric',
          live: { statusVehicle: 'off' },
          todays,
          // to be reviewed
          // name: `${vehicle.name} (${locoObj.vehicleId})`,
          startDate: locoObj.startDate ? moment(locoObj.startDate, 'DD.MM.YYYY') : null,
          endDate: locoObj.endDate ? moment(locoObj.endDate, 'DD.MM.YYYY') : null,
          fuelConsumption: locoObj.fuelConsumption ? JSON.parse(locoObj.fuelConsumption) : { ...FUEL_CONSUMPTION_DEFAULTS },

          isCardActive: selectAllLoco,
          isVisible: true,
          isFocused: false,
          activeTime: new Date(),
          sortIndex: sortIndex + 1,
        };
      }
    });

    Object.keys(locoList).forEach(key => {
      if (!locoList[key].fuelConsumption) {
        // delete locoList[key];
      }
    });

    const lastItem = Object.values(locoList).sort((a, b) => a.sortIndex - b.sortIndex).pop();
    if (lastItem) {
      locoList[lastItem.vehicleId].activeTime = new Date();
    }

    this.props.updateUserLocomotivesList(locoList, true);

    if (selectAllLoco) {
      this.props.onClickedSelectAllDevices(true, true);
    }
    this.getDatasheetTableData();
    this.fetchActiveLocoList();
  }

  showConnectionError() {
    const { enqueueSnackbar } = this.props;

    if (!this.props.isStecConnectionLost) {
      enqueueSnackbar('Connection Error', { variant: 'error', autoHideDuration: 10000 });
    }
  }

  async fetchSimpleData(returnValue = false) {
    const vehiclesIdLst = Object.keys(this.props.userLocomotiveListObject);

    if (vehiclesIdLst?.length) {
      const { data, isError } = await fetchSTECSimpleData(vehiclesIdLst.join(','));

      const { enqueueSnackbar } = this.props;
      if (isError && !this.props.isStecConnectionLost) {
        // enqueueSnackbar('Connection Error', { variant: 'error', autoHideDuration: 5000 });
      }

      if (!isError && this.props.isStecConnectionLost) {
        // enqueueSnackbar('Connected', { variant: 'success', autoHideDuration: 5000 });
      }

      this.props.stecConnect(isError);

      if (!isError) {
        this.props.setlastDataReceivedTimestamp(new Date().getTime());
        this.updateUserLocoFromServer(data);
        this.props.setUpdateView(true);
        if (returnValue) {
          return [...data];
        }
      }
    }
    return [];
  }

  updateUserLocoFromServer(serverJsonResponse, vehicleIds = [], forceOverwrite = false) {
    const updatedUserList = {};
    const responseList = [];
    serverJsonResponse?.forEach((newLoc) => {
      if (this.props.userLocomotiveListObject[newLoc.vehicleId]) {
        updatedUserList[newLoc.vehicleId] = Object.assign(
          {},
          {
            ...this.props.userLocomotiveListObject[newLoc.vehicleId],
            ...newLoc,
            live: { ...this.props.userLocomotiveListObject[newLoc.vehicleId].live, ...newLoc.live },
            todays: { ...this.props.userLocomotiveListObject[newLoc.vehicleId].todays, ...newLoc.todays },
            timestamp: { ...this.props.userLocomotiveListObject[newLoc.vehicleId].timestamp, ...newLoc.timestamp },
            isCardActive: this.determineIsCardActive(newLoc, this.props.userLocomotiveListObject[newLoc.vehicleId].isCardActive),
          },
          { isNoResponse: false },
        );

        if (this.props.userLocomotiveListObject[newLoc.vehicleId].isCrane) {
          updatedUserList[newLoc.vehicleId].isCardActive = this.props.userLocomotiveListObject[newLoc.vehicleId].isCardActive;
        }
        responseList.push(newLoc.vehicleId);
      }
    });
    this.setState({
      noResponseIds: vehicleIds.filter(tempLocoId => !responseList.includes(tempLocoId)),
    });

    if (forceOverwrite) {
      this.props.updateUserLocomotivesList({ ...updatedUserList }, forceOverwrite);
    } else {
      this.props.updateUserLocomotivesList({ ...this.props.userLocomotiveListObject, ...updatedUserList });
    }
  }

  renderItem(loco) {
    const showGpsFixedControls = ['dashboard', 'livedata', 'mapbox', 'mapbox-vs-google', 'google-maps'].includes(this.props.currentView);
    const { noResponseIds } = this.state;
    const { vehicle } = loco;
    const IsMovingIcon = getStatusIcon({ status: loco.live.statusVehicle, type: vehicle.type }, this.props.t);

    return (
      <LocomotiveListItem
        disabled={['trackingmap'].includes(this.props.currentView) && loco.isCrane}
        onClickLocomotive={() => this.onClickLocomotive(loco)}
        onClickFocusLoco={() => this.onClickFocusLoco(loco)}
        onClickTrackLoco={() => this.onClickTrackLoco(loco)}
        key={loco.vehicleId} // must have a key
        {...loco}
        t={this.props.t}
        isNoResponse={noResponseIds.includes(loco.vehicleId)}
        showGpsFixedControls={showGpsFixedControls}
        listViewActive={this.props.isListView}
        IsMovingIcon={IsMovingIcon}
      />
    );
  }

  async getDatasheetTableData() {
    const vehicleId = Object.keys(this.props.userLocomotiveListObject)[0];
    if (vehicleId) {
      const staticTableInfo = await fetchDatasheet({ vehicleId });
      const datasheetKeys = Object.keys(staticTableInfo);
      datasheetKeys.forEach(key => {
        staticTableInfo[key] = '';
      });

      this.props.setDataSheetStaticData({ ...staticTableInfo });
    }
  }

  showSpinner = (timeOutToShow = 500) => {
    const spinnerTimeoutID = setTimeout(
      () => this.setState({ isLoading: true }),
      timeOutToShow,
    );
    this.setState({ spinnerTimeoutID });
  };

  hideSpinner = (
    timeOutToShow = 500,
    spinnerTimeoutID = this.state.spinnerTimeoutID,
  ) => {
    spinnerTimeoutID && clearTimeout(spinnerTimeoutID);
    setTimeout(() => this.setState({ isLoading: false }), 500);
  };

  render() {
    const { isListView, t, userDeviceList, classes, selectAllDevices } = this.props;
    const heightFromTop = {
      dashboard: 305,
    };
    const { index, selectAllDropDownOpen, isLoading } = this.state;
    const scrollSetting = {
      // This is important field to render the next data
      dataLength: [...userDeviceList.filter(train => train.isVisible)].splice(0, index).length,
      next: () => {
        this.setState({ index: this.state.index + 10 });
      },
      // scrollThreshold: "200px",
      // hasMore: index < this.props.userDeviceList.length,
      hasMore: index < [...userDeviceList.filter(train => train.isVisible)].length,
      // loader: <p style={{ textAlign: 'center' }}><h4>Loading...</h4></p>,
      loader: <div style={{ textAlign: 'center' }}><h4>{t('messages.Loading')}...</h4></div>,

      endMessage: (
        <p style={{ textAlign: 'center' }}>
          {/*<b>End of list</b>*/}
        </p> ),
      height: `calc(100vh - (${heightFromTop[this.props.currentView] || 235}px * ${this.props.isZoomEighty ? 0.8 : 1})`,
    };

    const { hideControls } = this.props;
    const activeAndVisibleLocoMotiveIds = userDeviceList.filter(train => train.isCardActive).filter(train => train.isVisible);
    const activeLocomotiveIds = userDeviceList.filter(train => train.isCardActive);
    const filteredLocoMotiveIds = userDeviceList.filter(train => train.isVisible);

    return (
      <aside
        className={classNames(hideControls || this.props.isLocoControlHidden ? 'fvm-hide' : classes.fvmLocControl)}>
        {
          !hideControls
            ? ( <section className="fvm-sp-vertical-locomotive-list">
              <div className="fvm-controls">
                <div className="fvm-view-switcher">
                  <div>{t(`labels.${this.props.isHaveCranes ? 'fleetList' : 'locomotiveList'}`)}</div>
                  <div className={'fvm-pointer'}>
                    <BootstrapTooltip
                      title={t('tooltip.hideLocControls')}

                    >
                      <IconButton
                        size="small"
                        onClick={() => {
                          this.props.setIsLocoControlHidden(!this.props.isLocoControlHidden);
                        }}
                        key="loc-toggle-list-item"
                      >
                        <LocoListIcon/>
                      </IconButton>
                    </BootstrapTooltip>


                    <FvmGAItemTracker
                      key={'tiles-view-tracker'}
                      category={'Loc Controls'}
                      label={`Tiles view`}
                      action={`Select`}
                      onClick={() => {
                        this.props.updateLocomotiveListLayout(false);
                      }}
                    >
                      <IconButton
                        size="small"
                        key="tilesView-iconButton"
                      >
                        <BootstrapTooltip
                          title={t('tooltip.tilesView')}
                        >
                          <TilesIcon className={!isListView ? 'fvm-active' : 'fvm-inactive'}/>
                        </BootstrapTooltip>
                      </IconButton>
                    </FvmGAItemTracker>
                    <FvmGAItemTracker
                      category={'Loc Controls'}
                      key={'list-view-tracker'}
                      label={`List view`}
                      action={`Select`}
                      onClick={() => {
                        this.props.updateLocomotiveListLayout(true);
                      }}
                    >
                      <BootstrapTooltip
                        title={t('tooltip.listView')}

                      >
                        <IconButton
                          size="small"
                          key="tilesView-iconButton"
                          className={isListView ? 'fvm-active' : 'fvm-inactive'}
                        >

                          <ListIcon className={isListView ? 'fvm-active' : 'fvm-inactive'}/>
                        </IconButton>
                      </BootstrapTooltip>

                    </FvmGAItemTracker>

                  </div>
                </div>
                <ul className="">
                  <li>
                    <div className="fvm-list-con-txt">
                      <SearchBar t={t}/>
                    </div>
                  </li>
                  {!this.props.isSingleSelect && (
                    <li>
                      <div className="fvm-multi-select fvm-list-con-txt">{t('labels.selectAllControls')}
                        {
                          activeLocomotiveIds.length ?
                            <span
                              className={classes.selectedLocsTxt}>{`(${activeLocomotiveIds.length} ${t('singleWords.selected')})`}</span> : null
                        }
                      </div>
                      <div className="fvm-list-con-txt">
                        <FvmGAItemTracker
                          category={'Loc Controls'}
                          label={`Select all toggler`}
                          action={`Toggled`}
                          value={!selectAllDevices ? 1 : 0}
                        >
                          <FormControlLabel
                            control={<Checkbox
                              checked={!!activeAndVisibleLocoMotiveIds.length && ( activeAndVisibleLocoMotiveIds.length === filteredLocoMotiveIds.length )}
                              className={classes.selectAllCheckbox}
                              color="primary"
                              // onClick={e => e.stopPropagation()}
                              onChange={this.onClickSelectAll}
                              value="selectAllFiltered"
                              indeterminate={!!activeAndVisibleLocoMotiveIds.length && ( activeAndVisibleLocoMotiveIds.length < filteredLocoMotiveIds.length )}
                              inputProps={{
                                'aria-label': 'indeterminate checkbox',
                              }}
                            />}
                            className={classes.selectAllCheckboxLabel}
                            label={
                              <ArrowDropDown
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  this.setSelectAllDropDownOpen(!selectAllDropDownOpen);
                                }}
                              />}
                            // label={`${t('button.selectAll')} ${activeLocoMotiveIds.length ? '(' +
                            // activeLocoMotiveIds.length + ' ' + t('singleWords.selected') + ')' : ''} `}
                          />
                          <Select
                            labelId="demo-simple-select-helper-label"
                            className={classes.selectAllDropdown}
                            id="select-all-dropdown"
                            open={selectAllDropDownOpen}
                            IconComponent={() => null}
                            onClose={() => this.setSelectAllDropDownOpen(false)}
                            value="select"
                            onChange={this.selectAllItemsDropdownChange}
                          >
                            {/*<MenuItem value="select">{`${t('button.selectAll')} ${activeLocoMotiveIds.length ? '(' + activeLocoMotiveIds.length + ' ' + t('singleWords.selected') + ')' : ''} `}</MenuItem>*/}
                            {/*<MenuItem value="select">{`Select All ${activeLocoMotiveIds.length}/${userDeviceList.length}`}</MenuItem>*/}
                            <ListSubheader>{`${t('singleWords.selected')} ${activeAndVisibleLocoMotiveIds.length}/${filteredLocoMotiveIds.length}`}</ListSubheader>
                            <MenuItem
                              className="d-none"
                              value="select"
                            >
                              {`${userDeviceList.length} ${t('singleWords.devices')} `}

                              {/*{`${userDeviceList.length} devices (${t('singleWords.selected')} ${activeLocomotiveIds.length})`}*/}
                              {/*{`${t('singleWords.selected')} ${activeAndVisibleLocoMotiveIds.length}/${filteredLocoMotiveIds.length}`}*/}
                            </MenuItem>
                            <MenuItem value="deselect">{t('button.deselect')}</MenuItem>
                            <MenuItem value="selectAll">{t('button.selectAll')}</MenuItem>
                            <MenuItem value="deselectAll">{t('button.deselectAll')}</MenuItem>
                          </Select>
                          {/*<div className="fvm-radio-button" onClick={e => this.onClickSelectAll(e)}>*/}

                          {/*  <i className={false ? 'fvm-fill-black' : 'fvm-inactive'}>*/}
                          {/*    {*/}
                          {/*      (activeLocoMotiveIds.length === 0 && <FVMTh />) ||*/}
                          {/*      (activeLocoMotiveIds.length === userDeviceList.length && <FVMDotCircle />) ||*/}
                          {/*      <FVMRadioDash />*/}
                          {/*    }*/}
                          {/*  </i>*/}
                          {/*  <span*/}
                          {/*    className={classNames(classes.root)}*/}
                          {/*  >*/}
                          {/*    {*/}
                          {/*      t('button.selectAll')}{activeLocoMotiveIds.length ? ` (${activeLocoMotiveIds.length} ${t('singleWords.selected')})` : ''*/}
                          {/*  }*/}

                          {/*  </span>*/}
                          {/* */}

                          {/*</div>*/}
                        </FvmGAItemTracker>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
              <div className={`fvm-locomotive-list ${isListView ? 'fvm-list-view' : 'fvm-thumbnail-view'}`}>
                <Suspense fallback={<LoadingSpinner/>}>
                  <InfiniteScroll
                    {...scrollSetting}
                  >
                    <ul>{[...userDeviceList.filter(train => train.isVisible)].splice(0, index).map(this.renderItem)}</ul>
                  </InfiniteScroll>
                </Suspense>
              </div>
              {isLoading ? <LoadingSpinner/> : null}
            </section> ) : null
        }

      </aside>
    );
  }
}

const mapStateToProps = state => ( {
  removeFocusId: state.removeFocusFromID,
  isDemoUser: state.isDemoUser,
  isOnline: state.isOnline,
  isLocoControlHidden: state.isLocoControlHidden,
  isListView: state.isListView,
  targetedUserLocomotiveId: state.targetedUserLocomotiveId,
  currentView: state.currentView,
  isTrackingMap: state.trackingMap,
  // activeUserLocomotiveList: findActiveLocoList(state.userLocomotiveList.filter(loco => loco.isTrain)),
  activeUserLocomotiveListIds: state.activeUserLocomotiveListIds,
  selectAllDevices: state.selectAllDevices,
  selectAllLoco: state.selectAllLoco,
  selectAllDriving: state.selectAllDriving,
  selectAllStanding: state.selectAllStanding,
  selectAllEquipped: state.selectAllEquipped,
  selectAllOff: state.selectAllOff,
  selectAllCranes: state.selectAllCranes,
  toggleAllCranes: state.toggleAllCranes,
  isSingleSelect: state.isSingleSelect,
  isHaveCranes: state.isHaveCranes,
  userLocomotiveListObject: state.userLocomotiveList,
  userDeviceList: Object.values(state.userLocomotiveList).sort((a, b) => a.sortIndex - b.sortIndex),
  isStecConnectionLost: state.isStecConnectionLost,
  isZoomEighty: state.isZoomEighty,
  isInitialUserDeviceSelectionCompleted: state.isInitialUserDeviceSelectionCompleted,
  hideControls: ['profile', 'faq', 'useradministration'].includes(state.currentView),
  languageFilesReloaded: state.languageFilesReloaded,
  lastDataReceivedTimestamp: state.lastDataReceivedTimestamp,
  isFetchActiveLocoListData: state.isFetchActiveLocoListData,
  user: state.user.user,
  isDevelopment: state.isDevelopment,
  isLoggedIn: state.isLoggedIn,
} );

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    updateUserLocomotivesList,
    setRemoveFocusId,
    setIsHaveCranes,
    // updateActiveLocomotiveIds,
    setIsLocoControlHidden,

    updateActiveCraneIds,
    updateTargetLocomotiveId,
    updateTrackedLocomotiveId,
    updateLocomotiveListLayout: locomotiveListLayout,
    onClickedSelectAllDevices: clickedSelectAllDevices,
    onChangedView: changedView,
    setDataSheetStaticData: setDataSheetStaticData,
    clickedSelectAllDriving,
    clickedSelectAllStanding,
    clickedSelectAllEquipped,
    clickedSelectAllOff,
    stecConnect,
    setHideLoginUntilOnline,
    setlastDataReceivedTimestamp,
    setUserMadeInitialDeviceSelection,
    setFetchActiveLocoListData,
    setUpdateView,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(LocomotiveList));

