import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useEntryStatusStyles = makeStyles(() => ({
  chip: {
    borderRadius: 4,
  },
  resolvedEntry: {
    color: '#06DE3A',
    backgroundColor: '#06DE3A1F',
    // border: '1px solid #06DE3A1F',
  },
  openEntry: {
    color: '#FF0000',
    backgroundColor: '#FF00001F',
    // border: '1px solid #FF00001F',
  },
  errorEntry: {
    color: '#FF0000',
    backgroundColor: '#FF00001F',
  },
  unknownEntry: {
    color: '#FF0000',
    backgroundColor: '#FF00001F',
  },

  unSyncedEntry: {
    color: '#FF0000',
    backgroundColor: '#FF00001F',
  },
  createdEntry: {
    color: '#ff9800',
    backgroundColor: '#FF00001F',
    // border: '1px solid #FF00001F',
  },

  draftEntry: {
    color: '#ff9800',
    backgroundColor: '#FF00001F',
    // border: '1px solid #FF00001F',
  },


  withoutDefectsEntry: {
    color: '#FF6600',
    backgroundColor: '#FF66001F',
    border: 'none',
    opacity: 1,
  },

  withDefectsEntry: {
    color: '#FF0000',
    backgroundColor: '#FF66001F',
    border: 'none',
    opacity: 1,
  },

  offlineModeEntry: {
    color: '#ff9800',
    backgroundColor: 'rgb(255, 244, 229)',
  },
}));

const sateMap = {
  draft: 'draft',
  finalized: 'finalized',
  created: 'created',
  newEntry: 'created',
  started: 'created',
  unknownEntry: 'unknownEntry',
  error: 'error',
  unSynced: 'unSynced',
  offlineMode: 'offlineMode',
  open: 'open',
  resolved: 'resolved',
  'true': 'open',
  'false': 'resolved',
  withoutDefects: 'withoutDefects',
  withDefects: 'withDefects',
};

const LogbookEntryState = ({ state, classNames = '', label = 'LABEL NOT SET!!' }) => {
  const classes = useEntryStatusStyles();
  const foundState = sateMap[state];

  return <Chip className={[classes.chip, classes[`${foundState}Entry`], classNames].join(' ')} size="small"
    label={foundState ? label : 'STATE NOT' +
      ' SET'} color={'primary'} />;
};

LogbookEntryState.propTypes = {
  state: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default LogbookEntryState;