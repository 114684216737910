import React from 'react';

const SubCatFinance = () => {
  const a = {
    fill: '#e5e5e5',
    opacity: 0,
  };
  const b = {
    fill: '#002fc9',
    stroke: 'rgba(0,0,0,0)',
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        {/*<style>.a{fill:#e5e5e5;opacity:0;}.b{fill:#002fc9;stroke:rgba(0,0,0,0);}</style>*/}
      </defs>
      <g transform="translate(-441 -549)">
        <rect style={a} width="24" height="24" transform="translate(441 549)" />
        <path
          style={b}
          d="M-1219,468a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-6,6A6,6,0,0,1-1219,468Zm2.25-.45v.9a.3.3,0,0,0,.3.3h.561c.05.275.1.466.1.466a3.377,3.377,0,0,0,3.537,2.534,4.658,4.658,0,0,0,1.328-.169.3.3,0,0,0,.2-.363l-.142-.548a.3.3,0,0,0-.142-.186.294.294,0,0,0-.233-.027,3.323,3.323,0,0,1-.945.131,1.855,1.855,0,0,1-1.922-1.4,3.288,3.288,0,0,1-.109-.439h3.161a.3.3,0,0,0,.3-.3v-.9a.3.3,0,0,0-.3-.3h-3.143a3.775,3.775,0,0,1,.091-.37,1.914,1.914,0,0,1,1.922-1.471,3.279,3.279,0,0,1,.944.132.3.3,0,0,0,.233-.026.3.3,0,0,0,.143-.186l.142-.55a.3.3,0,0,0-.2-.361,4.659,4.659,0,0,0-1.328-.168,3.333,3.333,0,0,0-3.537,2.479s-.061.218-.112.521h-.552A.3.3,0,0,0-1216.75,467.55ZM-1227,462.5a8.5,8.5,0,0,1,8.5-8.5,8.478,8.478,0,0,1,8.1,6h-2.118a6.489,6.489,0,0,0-5.983-4,6.507,6.507,0,0,0-6.5,6.5,6.489,6.489,0,0,0,4,5.983V470.6A8.48,8.48,0,0,1-1227,462.5Zm8,.5h-3v-1h3v-3h1v4Z"
          transform="translate(1670 97)"
        />
      </g>
    </svg>
  );
};
export default SubCatFinance;
