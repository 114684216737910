const didLocoSelectionChange = (updatedLocoObjects , oldLocoObjects = {})=>{
  const updateKey = updatedLocoObjects.join('#');
  const oldKeys = oldLocoObjects.join('#');

  return updateKey !== oldKeys;
}


const removeInitialUserLogin = ()  => {
  if (localStorage.getItem('fvm_user_initial_load')) {
    localStorage.removeItem('fvm_user_initial_load');
  }
}

export {
  didLocoSelectionChange,
  removeInitialUserLogin
}