import React, { useState } from 'react';
//import './style.scss';

const UserAvatar = ({ userAvatarUrl, className, onClick }) => {
  const [fallbackCalled, setFallbackCalled] = useState(false);
  return (
    <div
      className={className + ' user-avatar'}
      onClick={onClick ? onClick : () => {
      }}
    >
      <img
        className="img-fluid"
        src={userAvatarUrl}
        alt="user"
        onError={(e) => {
          setFallbackCalled(true)
          if (!fallbackCalled && e) {
            e.target.src = '/assets/images/default-user-image.png'
          }
        }}
      />

    </div>
  );
};

export default UserAvatar;
