import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { blue, brown, green, orange, purple, red } from '@material-ui/core/colors';
import { Box, Button, CardHeader, Grid, withStyles } from '@material-ui/core';
import LogbookEntryState from '../core/LogbookEntryState';

const useStyles = makeStyles(({ headerHeight = 56 }) => ({
  container: {
    // border: '1px solid #dfe3e6'
    display: 'flex',
    alignItems: 'center',
    '& >*': {
      marginRight: 24,
    },
    '& >*:nth-child(3)': {
      marginLeft: 'auto',
      marginRight: 0,
    },
  },
  buttonContainer: {
    '& >*:not(:last-child)': {
      marginRight: 8,
    }
  },
  header: {
    height: headerHeight,
    paddingLeft: 0,
    paddingRight: 0,
  },
  avatarColorRed: {
    backgroundColor: red[500],
  },
  avatarColorGreen: {
    backgroundColor: green[500],
  },
  avatarColorBlue: {
    backgroundColor: blue[500],
  },
  avatarColorPurple: {
    backgroundColor: purple[500],
  },
  avatarColorOrange: {
    backgroundColor: orange[500],
  },
  avatarColorBrown: {
    backgroundColor: brown[500],
  },

}));

const LogBookHeader = withStyles({
  action: {
    marginTop: 0,
  },
})(CardHeader);

const colors = ['Purple', 'Red', 'Green', 'Blue', 'Orange', 'Brown'];

const LocomotiveSectionHeader = ({
  locoName = 'Name Required',
  subheader = '',
  entry = {},
  className = '',
  backGroundColorNumber = 0,
  entryState,
  allowDelete,
  allowRecreateEntry,
  recreateEntry = () => {
  },
  deleteEntry = () => {
  },
  t,
  entryCreator,
  ...props
}) => {
  const classes = useStyles();
  const [fallbackCalled, setFallbackCalled] = useState(false);

  return (
    <div className={`${className} ${classes.container}`}>
      <LogBookHeader
        className={classes.header}
        avatar={
          <Avatar
            aria-label="user-avatar"
            className={!props.locoImgUrl ? classes[`avatarColor${colors[backGroundColorNumber]}`] : ''}
            src={props.locoImgUrl || '/assets/images/loc-default.jpg'}
          >
            {
              props.locoImgUrl
                ? props.locoImgUrl
                : null
              // : (locoName || 'Unknown')?.match(/\b[a-zA-Z0-9]/g).splice(0, 3)
            }
          </Avatar>
        }
        // action={entryState ?  <LogbookEntryState state={entryState}  label={t(`labels.${entryState}Entry`)}/> : null}
        title={locoName || t('labels.names')}
      />
      <LogBookHeader
        className={classes.header}
        avatar={
          <Avatar
            aria-label="user-avatar"
            className={!entryCreator.userAvatarUrl ? classes[`avatarColor${colors[backGroundColorNumber]}`] : ''}
            alt="Remy Sharp" src={entryCreator.userAvatarUrl}
            onError={(e) => {
              setFallbackCalled(true)
              if (!fallbackCalled && e) {
                e.target.src = '/assets/images/default-user-image.png';
              }
            }}
          >
            {entryCreator.userAvatarUrl}
          </Avatar>
        }
        title={`${entryCreator.firstName} ${entryCreator.lastName}`}
      />
      <Box className={classes.buttonContainer}>
        {entry?.defects &&
          <Box item hidden={!entryState} sx={{ textTransform: 'capitalize' }}>
            {t('labels.defects')}: {entryState === 'none' ? t('labels.no') : <LogbookEntryState state={entryState} label={t(`labels.${entryState}Entry`)} />}
          </Box>
        }
        <Button
          hidden={!allowRecreateEntry}

          color="primary"
          variant="outlined"
          size="small"
          onClick={recreateEntry}
        >
          {t('button.recreateEntry')}
        </Button>

        <Button
          hidden={!allowDelete}
          color="secondary"
          variant="outlined"
          size="small"
          onClick={deleteEntry}
        >
          {t('button.delete')}
        </Button>
      </Box>

    </div>
  );
};

LocomotiveSectionHeader.propTypes = {
  imgUrl: PropTypes.string,
  locoName: PropTypes.string,
  subheader: PropTypes.string,
  backGroundColorNumber: PropTypes.number,
};

export default LocomotiveSectionHeader;