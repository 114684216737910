import React from 'react';
const Ship = () => {

  const a = {
    fill: '#fff',
  };
  const b = {
    opacity: 0,
  };
  const c = {
    fill: '#0d95b1',
    stroke: 'rgba(0,0,0,0)',
  };
  const d = {
    filter: 'url(#a)',
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"  width="33.999" height="33.999"
      viewBox="0 0 43 43"
    >
      {/*<style>.a,.b{fill:#fff;}.b{opacity:0;}.c{fill:#0d95b1;stroke:rgba(0,0,0,0);}.d{filter:url(#a);}</style>*/}
      <defs>
        <filter id="a" x="0" y="0" width="43" height="43" filterUnits="userSpaceOnUse">
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1.5" result="b" />
          <feFlood floodOpacity="0.149" />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(-201.5 -28.5)">
        <g style={d} transform="matrix(1, 0, 0, 1, 201.5, 28.5)">
          <circle style={a} cx="17" cy="17" r="17" transform="translate(4.5 4.5)" />
        </g>
        <rect style={b} width="24" height="24" transform="translate(211 38)" />
        <path
          style={c}
          d="M-1297,228.5l-2-5h5l1,1h11l1-1h2l-3,5Zm12-5v-2h3v2Zm-4,0v-2h3v2Zm-4,0v-2h3v2Zm-5-1v-5h1v-.6l2-.9v1.5h1v5Zm13-2v-2h3v2Zm-4,0v-2h3v2Zm-4,0v-2h3v2Z"
          transform="translate(1512 -172.5)"
        />
      </g>
    </svg>
  );
};
export default Ship;
