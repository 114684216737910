import axios from 'axios';
import { generateToken } from 'wordpress-jwt-auth';

class RestCommunication {
  constructor(appFunctions, appConst) {
    this.siteBaseUrl = appConst.fvmURL;

    this.getToken = this.getToken.bind(this);
    this.changeEmailPassword = this.changeEmailPassword.bind(this);
    this.updateUserProfileImage = this.updateUserProfileImage.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.appFunctions = appFunctions || {};
  }

  async changeEmailPassword(userPassEmail) {
    const { data, currentPass } = userPassEmail;
    const currentEmail = localStorage.getItem('fvm_wp_api_user_email');

    const userConnection = await generateToken(this.siteBaseUrl, currentEmail, currentPass);

    if (typeof Storage !== 'undefined' && userConnection.token) {
      // updating user wp token
      localStorage.setItem('fvm_wp_api_token', userConnection.token);
      const resp = await axios.post(`${this.siteBaseUrl}/wp-json/wp/v2/users/me`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}`,
        },
      });

      if (resp.data && resp.data.email) {
        // update email address in local storage
        localStorage.setItem('fvm_wp_api_user_email', resp.data.email);
        if (this.appFunctions.updateUserInfo) {
          this.appFunctions.updateUserInfo();
        }
      }
      return resp;
    }
    return { status: 500 };
  }

  getUserData() {
    // axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem('fvm_wp_api_token')}`;
    axios.get(`${this.siteBaseUrl}/wp-json/fvm/v1/user/userdata`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
    }).then((response) => {
      if (response && response.data) {
        const {userId, imageurl, firstName, lastName, locale } =  response.data;
        localStorage.setItem('fvm_user_profile_image', `${imageurl}?${Date.now()}`);
        localStorage.setItem('userId', `${userId}`);
        localStorage.setItem('fvm_wp_api_user_firstName', firstName);
        localStorage.setItem('fvm_wp_api_user_lastName', lastName);
        if(!localStorage.getItem('i18nextLng')){
          localStorage.setItem('i18nextLng', locale);
        }
        localStorage.setItem('savedUserLang', locale);
        if (this.appFunctions.updateUserInfo) {
          this.appFunctions.updateUserInfo();
        }
      }
    });
  }

  async deleteUserProfileImage() {
    const imageChangeResponse = await axios.delete(`${this.siteBaseUrl}/wp-json/fvm/v1/user/profileimg`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}`,
      },
    });

    const { imageurl } = imageChangeResponse.data;
    if (imageurl) {
      localStorage.setItem('fvm_user_profile_image', imageurl);
      if (this.appFunctions.updateUserInfo) {
        this.appFunctions.updateUserInfo();
      }
    }
    return imageChangeResponse;
  }

  async updateUserProfileImage() {
    const imageData = new FormData();
    imageData.append('image', document.getElementById('image-upload').files[0]);

    const imageChangeResponse = await axios.post(`${this.siteBaseUrl}/wp-json/fvm/v1/user/profileimg`, imageData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}`,
      },
    });

    const { imageurl } = imageChangeResponse.data;
    if (imageurl) {
      localStorage.setItem('fvm_user_profile_image', `${imageurl}?${Date.now()}`);

      if (this.appFunctions.updateUserInfo) {
        this.appFunctions.updateUserInfo();
      }
    }
    return imageChangeResponse;
  }

  async updateUserLocale(lang) {
    const data = { locale: lang };
    const localeChangeResponse = await axios.post(`${this.siteBaseUrl}/wp-json/fvm/v1/user/locale`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}`,
      },
    });

    const { locale } = localeChangeResponse.data;
    if (locale) {
      localStorage.setItem('i18nextLng', locale);
      localStorage.setItem('savedUserLang', locale);
      if (this.appFunctions.updateUserInfo) {
        this.appFunctions.updateUserInfo();
      }
    }

    return localeChangeResponse;
  }

  getToken(username, password, callbackfunc, errorCallback) {
    generateToken(this.siteBaseUrl, username, password).then((userConnection) => {
      if (typeof Storage !== 'undefined') {
        // Store
        localStorage.setItem('fvm_wp_api_token', userConnection.token);
        localStorage.setItem('fvm_wp_api_user_email', userConnection.user_email);
        localStorage.setItem('fvm_wp_api_user_display_name', userConnection.user_display_name);
        // localStorage.setItem('userToken', userConnection.userToken);
        localStorage.setItem('fvmTest', userConnection.fvmTest);
      }
    }).then(() => {
      if (this.appFunctions.updateUserInfo) {
        this.appFunctions.updateUserInfo();
      }
      callbackfunc();
      this.getUserData(true);
    }).catch((error) => {
      errorCallback(true);
    });
  }

  async getDatasheetTableData(asyncCallbackFunc) {
    this.setState({ isLoading: true });
    const resp = await axios.get(`${this.props.appConsts.fvmURL}/wp-json/fvm/v1/locomotive/`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
    });
    asyncCallbackFunc(resp.data);
  }
}

export default RestCommunication;
