import { withStyles } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { changedView } from '../actions';
import ViewTitle from '../components/ViewTitle';
import { trackButtonClick } from '../containers/GATracker';
import { getFaqs } from '../wp-rest-api/STEC-Communication';

const styles = () => ({
  expanstionTitle: {
    width: `${100}%`,
    fontSize: '1.5rem',
  },
});

/**
 *
 */
class Faq extends Component {
  constructor(props) {
    super(props);

    this.state = {
      faqs: [],
    };
    this.getFvmFaqs();
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.languageFilesReloaded !== prevProps.languageFilesReloaded){
      this.setState({ componentLanguageUpdateTrigger: this.props.languageFilesReloaded });
      this.getFvmFaqs();
    }
  }

  async getFvmFaqs() {
    const faqs = await getFaqs();
    this.setState({ faqs });
  }

  biuldFaqa(faqs) {
    const { classes } = this.props;

    return faqs.map((faq, index) => {
      return (
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => {
              trackButtonClick('FAQ', `${faq.title.replace(/['"]+/g, '')}`, 'Toggled');
            }}
          >
            <Typography
              className={classNames(classes.expanstionTitle)}
            >{index + 1}. {faq.title.replace(/['"]+/g, '')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="fvm-faq-content" dangerouslySetInnerHTML={{ __html: faq.content }} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });

  }

  render() {
    const { t } = this.props;
    const { faqs } = this.state;

    return (
      <article className="fvm-page fvm-page-faq">
        <header className="fvm-page-header">
          <ViewTitle title={t('navigationMenu.faq')}/>
        </header>

        <div className="fvm-faq">
          {this.biuldFaqa(faqs)}
        </div>
      </article>
    );
  }
}

const mapStateToProps = state => ({
  languageFilesReloaded: state.languageFilesReloaded,
});
const mapDispatchToProps = dispatch => (
  bindActionCreators({
    newView: changedView,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Faq));
