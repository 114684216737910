import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';
const gridSize = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
  xl: 3,
};

const useStyles = makeStyles(theme => ({
  outerGrid: {
    maxHeight: 'calc(100vh - 160px - 70px)',
    backgroundColor: '#FFFFFF',
    border: '1px solid rgb(223, 227, 230)',
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  gridItems: {
    borderRight: '1px solid #DFE3E6',
  },
}));

const SelectableGridArea = ({ listItems, useAvatar = true, toggleSelection, selected, filterTerm }) => {
  const classes = useStyles();
  const searchTermLowerCase = filterTerm.toLowerCase();
  return (
    <Grid
      className={classNames(classes.outerGrid)}
      container
      direction="row"
      spacing={1}
    >
      {
        listItems.filter(loco => !filterTerm || (filterTerm && loco.name.toLowerCase().match(searchTermLowerCase))).map(loco =>
          <Grid
            key={`${loco.name}-grid-item`}

            item
            className={classNames(classes.gridItems)}
            {...gridSize}
          >
            <ListItem
              key={loco.name}
              onClick={() => toggleSelection(loco.id)}
              button
            >

              {
                useAvatar
                  ? <ListItemAvatar>
                    <Avatar
                      alt={`Avatar ${loco.name}`}
                      src={loco.image}
                    />
                  </ListItemAvatar>
                  : null
              }
              <ListItemText
                primaryTypographyProps={{ className: classNames(classes.root) }}
                primary={loco.name}
              />
              <Checkbox
                onChange={() => {
                }}
                checked={selected.includes(loco.id)}
                tabIndex={-1}
                color="primary"
              />
            </ListItem>
          </Grid>)
      }
    </Grid>
  );
};

export default SelectableGridArea;