import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React from 'react';
import { DateRangePicker } from 'react-dates';
import { useTranslation } from 'react-i18next';
import FvmGAItemTracker from '../FvmGAItemTracker';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  upperControlsCheckbox: {
    paddingLeft: '0',
    maxWidth: 300,
    minWidth: 250,
    width: 'calc(100% / 4)',
  },
  upperControlsInput: {
    '&:after': {
      borderBottom: 'none',
    },
    '&:before': {
      borderBottom: 'none',
    },
  },
  formGroup: {
    minWidth: '100%',
    display: 'inline-flex',
    flexDirection: 'row',
  },
  searchFieldContaier: {
    paddingLeft: 5,
    backgroundColor: '#FFFFFF !important',
    border: '1px solid #DFE3E6 !important',
    fontSize: '14px !important',
    '&:before, &:after': {
      display: 'none',
    },
  },
}));

const isDateBlocked = (date) => {
  return date.isAfter(moment().subtract(1, 'seconds'));
};

export default function SelectionAreaControls({
                                                allSelected,
                                                indeterminate,
                                                selectedItems,
                                                handleSelectAll,
                                                handleDateChange,
                                                hasDatePicker,
                                                period = {
                                                  startDate: null,
                                                  endDate: null,
                                                },
                                                onSearchChange = input => {
                                                },
                                              }) {
  const classes = useStyles();
  const [filterTerm, setFilterChange] = React.useState('');
  const [focusedInput, setFocusedInput] = React.useState(null);
  const [startDate, setStartDate] = React.useState(period.startDate);
  const [endDate, setEndDate] = React.useState(period.endDate);
  const { t } = useTranslation();

  const onFilterChange = (event) => {
    setFilterChange(event.target.value);
    onSearchChange(event.target.value);
  };
  const onDateChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const saveDate = () => {
    if (endDate && endDate._isAMomentObject && startDate && startDate._isAMomentObject) {
      handleDateChange({ startDate, endDate });
    }
    setFocusedInput(null);
  };

  return (
    <List className={classes.formGroup}>
      <ListItem
        key="selectAllCheckbox"
        className={classes.upperControlsCheckbox}
      >
        <FormControlLabel
          control={
            <FvmGAItemTracker
              category={'Export'}
              label={'Export Drawer Select All'}
              action={'Toggle'}
            >
              <Checkbox
                indeterminate={indeterminate}
                checked={allSelected}
                onChange={handleSelectAll}
                color="primary"
              />
            </FvmGAItemTracker>
          }
          label={`${t('button.selectAll')} ${selectedItems? `(${selectedItems} ${t('singleWords.selected')})`:''} `}
        />
      </ListItem>

      <ListItem
        key="SearchField"
        className={classes.upperControlsCheckbox}
      >
        <FormControlLabel
          control={
            <Input
              type="text"
              label={t('placeHolders.filterInput')}
              value={filterTerm}
              onChange={onFilterChange}
              // classes={{ und classes.searchFieldContaier.underline }}
              className={classes.searchFieldContaier}
              placeholder={t('placeHolders.filterInput')}
              // margin="normal"
              variant="outlined"
            />}
        />
      </ListItem>
      {
        hasDatePicker ? (<ListItem
          key="datePicker"
          className={classes.upperControlsCheckbox}
        >
          <FormControlLabel
            control={
              <DateRangePicker
                renderCalendarInfo={() => <div className="fvm-date-picker-controls">
                  <button
                    onClick={() => {
                      document.querySelector('.fvm-controls .DateRangePickerInput_clearDates').click();
                    }}
                  >
                    {t('button.delete')}
                  </button>
                  <button onClick={saveDate}>{t('button.apply')}</button>
                </div>}
                keepOpenOnDateSelect
                isOutsideRange={() => false}
                readOnly
                showClearDates
                appendToBody
                reopenPickerOnClearDates
                displayFormat="DD.MM.YYYY"
                startDate={startDate} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={onDateChange}
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={setFocusedInput} // PropTypes.func.isRequired,
                onClose={saveDate}
                firstDayOfWeek={1}
                hideKeyboardShortcutsPanel
                block
                small
                noBorder
                minimumNights={0}
                startDatePlaceholderText={t('placeHolders.startDate')}
                endDatePlaceholderText={t('placeHolders.endDate')}
                isDayBlocked={isDateBlocked}
              />
            }
            labelPlacement="start"

            // label={`${t('button.selectAll')}`}
          />
        </ListItem>) : null
      }
    </List>
  );
};
