import CheckCircleIcon from '@material-ui/icons/CheckCircleSharp';
import GpsFixedIcon from '@material-ui/icons/GpsFixedOutlined';
import GSMFullIcon from '@material-ui/icons/SignalCellular4BarSharp';
import GSMEmptyIcon from '@material-ui/icons/SignalCellular4BarSharp';
import GPS1PlusIcon from '@material-ui/icons/SignalWifi1BarSharp';
import GPS5PlusIcon from '@material-ui/icons/SignalWifi2BarSharp';
import GPS10PlusIcon from '@material-ui/icons/SignalWifi3BarSharp';
import GPS15PlusIcon from '@material-ui/icons/SignalWifi4BarSharp';
import GPS0Icon from '@material-ui/icons/SignalWifi4BarSharp';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FvmGAItemTracker from '../FvmGAItemTracker';
import BootstrapTooltip from '../FvmMaterilUiTooltip';

// import NavigationIcon from '@material-ui/icons/Navigation';

class LocomotiveListItem extends Component {

  constructor(props) {
    super(props);
    const { live, vehicle } = props;
    const { GPSSignal, GSMSignal } = live;

    this.state = {
      vehicleId: props.vehicleId,
      name: vehicle.name,
      isCardActive: props.isCardActive,
      isFocused: props.isFocused,
      image: vehicle.image,
      GPSSignal: GPSSignal,
      GSMSignal: GSMSignal,
      IsMovingIcon: props.IsMovingIcon,
      displayFallback: !vehicle.image,
      displayFallbackUrl: `/assets/images/${this.props.isCrane ? 'crane' : 'loc'}-default-img.png`,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { live, IsMovingIcon, vehicle } = this.props;
    const { GPSSignal, GSMSignal } = live;

    if (GPSSignal !== prevProps.live.GPSSignal || GSMSignal !== prevProps.live.GSMSignal || IsMovingIcon !== prevProps.IsMovingIcon) {
      this.setState({ GPSSignal, GSMSignal, IsMovingIcon });
    }
    if (prevProps.vehicle.image !== vehicle.image) {
      this.setState({ displayFallback: vehicle.image.match('loc-default') && true });
    }
  }

  retriveGpsData() {
    const { t } = this.props;
    return {
      0: {
        icon: <GPS0Icon/>,
        textValue: '0',
        className: 'fvm-status-inactive',
        tooltip: t('tooltip.noGpsSignal'),
      },
      1: {
        icon: <GPS1PlusIcon/>,
        textValue: '1+',
        className: 'fvm-status-active',
        tooltip: t('tooltip.veryPoorGpsSignal'),
      },
      2: {
        icon: <GPS5PlusIcon/>,
        textValue: '5+',
        className: 'fvm-status-active',
        tooltip: t('tooltip.poorGpsSignal'),
      },
      3: {
        icon: <GPS10PlusIcon/>,
        textValue: '10+',
        className: 'fvm-status-active',
        tooltip: t('tooltip.goodGpsSignal'),
      },
      4: {
        icon: <GPS15PlusIcon/>,
        textValue: '15+',
        className: 'fvm-status-active',
        tooltip: t('tooltip.veryGoodGpsSignal'),
      },
    };
  }

  render() {
    const { listViewActive, isCardActive, isNoResponse, IsMovingIcon, showGpsFixedControls, t, live, vehicleId, vehicle, disabled } = this.props;
    const { displayFallback, displayFallbackUrl } = this.state;
    const { statusVehicle } = live;
    const activeResponse = isCardActive && isNoResponse === false;
    const activeNoResponse = isCardActive && isNoResponse;
    const isTrainOff = statusVehicle === 'Off';
    const GPSIData = this.retriveGpsData();
    const gpsIndex = isTrainOff ? 0 :
      (this.state.GPSSignal > 14 && 4) ||
      (this.state.GPSSignal > 10 && 3) ||
      (this.state.GPSSignal > 4 && 2) ||
      (this.state.GPSSignal > 0 && 1) ||
      0;

    return (
      <li className={`locomotive-card ${!this.props.isVisible ? 'hide-locomotive-card' : ''}`}>
        <div
          onClick={this.props.onClickLocomotive}
          className={`fvm-cp-locomotive ${activeNoResponse ? 'not-connected' : ''} ${activeResponse ? 'active' : 'fvm-loco-card-focus-inactive'}`}
        >
          <span className="fvm-active fvm-cp-icon ">

            {activeResponse ? (
              <i className="fvm-loco-card-target-active">
                <CheckCircleIcon/>
              </i>) : null
            }
            {activeNoResponse ? <i className="fvm-fill-red"><CheckCircleIcon/></i> : null}
            {
              activeResponse && showGpsFixedControls && (
                <FvmGAItemTracker
                  category={'Loc Controls'}
                  label={`Focus locomotive toggler`}
                  action={`Toggled`}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.onClickFocusLoco();
                  }}
                >
                  <BootstrapTooltip
                    key={`loco-item-focused-${vehicleId}`}
                    title={t('tooltip.focusLocomotive')}
                  >
                    <i
                      className={`${this.props.isFocused ? 'fvm-loco-card-focus-active' : 'fvm-loco-card-focus-inactive'}`}
                    >
                      <GpsFixedIcon/>
                    </i>
                  </BootstrapTooltip>
                </FvmGAItemTracker>
              )
            }
            {/*{activeResponse && showGpsFixedControls && (*/}
            {/*  <i*/}
            {/*    className={`${this.props.isTracking ? 'fvm-loco-card-focus-active' : 'fvm-loco-card-focus-inactive'}`}*/}
            {/*  >*/}
            {/*    <NavigationIcon*/}
            {/*      onClick={(e) => {*/}
            {/*        e.stopPropagation();*/}
            {/*        this.props.onClickTrackLoco();*/}
            {/*      }}/>*/}
            {/*  </i>*/}
            {/*  )}*/}

          </span>
          <div className={'fvm-cp-image fvm-display-default-icon '}>
            {
              !listViewActive && (
                <img
                  className="img-fluid"
                  src={!displayFallback ? vehicle.image : displayFallbackUrl}
                  alt="locomotive"
                  onError={(e) => {
                    this.setState({ displayFallback: true });
                    if (!displayFallback && e) {
                      // e.target.src = 'https://my.nrail.de/assets/images/loc-default-img.png'
                      e.target.src = displayFallbackUrl;
                    }
                  }
                  }
                />
              )
            }
            {/*{ !listViewActive && displayFallback && (<i className={this.props.isCrane ? 'is-crane' : ''}>{IsMovingIcon.icon}</i>) }*/}

          </div>
          <div className="fvm-cp-body-wrapper"> {/* IE11 fix for vertical justify-content */}
            <div className="fvm-cp-body">
              <p className="fvm-cp-title">{vehicle.name}</p>
              <div className="fvm-status-icons">
                <div>
                  <BootstrapTooltip
                    key={`loco-item-status-${vehicleId}`}
                    title={IsMovingIcon ? IsMovingIcon.tooltip : ''}
                  >
                    <i>
                      {IsMovingIcon ? IsMovingIcon.icon : null}
                    </i>
                  </BootstrapTooltip>
                </div>
                <div>
                  <BootstrapTooltip
                    key={`loco-item-gsm-${vehicleId}`}
                    title={`${(this.state.GSMSignal > 0) && !isTrainOff ? t('tooltip.gsmSignal') : t('tooltip.noGsmSignal')}`}
                  >
                    <i
                      className={`${(this.state.GSMSignal > 0) && !isTrainOff ? 'fvm-status-active' : 'fvm-status-inactive'}`}
                    >
                      {(this.state.GSMSignal > 0) && !isTrainOff ? <GSMFullIcon/> : <GSMEmptyIcon/>}
                    </i>
                  </BootstrapTooltip>
                </div>
                <div>
                  <BootstrapTooltip
                    title={GPSIData[gpsIndex].tooltip}
                  >
                    <i
                      className={`gps-icon ${GPSIData[gpsIndex].className}`}
                    >
                      {GPSIData[gpsIndex].icon}
                    </i>
                  </BootstrapTooltip>
                  <span>{GPSIData[gpsIndex].textValue}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {disabled ? <div className={'fvm-loco-disabled'}/> : null}

      </li>
    );
  }
}

/**
 * Controlling the property type that can be passed to LocomotiveListItem
 * */
LocomotiveListItem.propTypes = {
  isCardActive: PropTypes.bool.isRequired,
  // isTracking: PropTypes.bool.isRequired,
  listViewActive: PropTypes.bool.isRequired,
};

export default LocomotiveListItem;
