import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NrlFormLabel from '../core/NrlFormLabel';
import { defectsAuthScheme } from '../../../utils/validation_scheme';
import { Controller } from 'react-hook-form';
import NrlRadioButtonGroup from '../core/NrlRadioButtonGroup';
import { useTranslation } from 'react-i18next';
import BannerNotification from '../../BannerNotification';

const useStyles = makeStyles(theme => ({
  switch: {
    paddingTop: 21,
    marginLeft: 0,
    // paddingBottom:16,
  },
  defaultMessageNotificationStyles: {
    width: '100%',
    padding: '16px 16px !important',
  },
  sectionLabel: {
    marginTop: 16,
    marginBottom: 16,
  },
  collapse: {
    minWidth: '100%',
  },
  textFieldFormControlStyles: {
    // marginTop: 8,
    // marginBottom: 8,
  },
  [theme.breakpoints.up(768)]: {
    timeInputGridItem: {
      maxWidth: '20%',
      flexBasis: '20%',
    },
  },
}));
const DefectsTabPanel = props => {
  const classes = useStyles();
  const {
    hidden,
    t,
    form: { control, formState: { errors } },
    isReadOnly,
    isDefect,
    handleDefectsToggled,
    entryState = null
  } = props;

  const getJoiError = (errorResponse, options) => {
    // return t(`joi.messages.${errorResponse}`, options);
    const isDefaultRequired = !options?.label || errorResponse === 'any.required';
    return t([`joi.messages.${isDefaultRequired ? 'default.required' : errorResponse}`, 'joi.messages.'], { label: 'field' });
  };

  const radioButtonSettings = {
    size: 'small',
    color: 'primary',
    labelPlacement: 'right',
  };

  const OPEN_RESOLVED_LST = [
    { label: 'labels.yes', value: 'resolved' },
    { label: 'labels.no', value: 'open' },
  ];

  const sharedProps = {
    disabled: isReadOnly,
  };

  return (
    <Box
      className={classes.tabContainerItem}
      hidden={hidden}
      key={'tab-panel-defects'}
    >
      <FormControlLabel
        className={classes.switch}
        control={
          <Switch
            {...sharedProps}
            checked={isDefect}
            onChange={handleDefectsToggled}
            name="isDefect"
            color="primary"
          />
        }
        labelPlacement="start"
        label={
          <Typography
            variant="h6"
            component={'span'}
            className={classes.sectionLabel}
          >
            {t('labels.defects')}
          </Typography>
        }
      />
      <Collapse in={isDefect} className={classes.collapse}>
        <Grid container spacing={4}>
          {[{ name: 'description', required: true }, { name: 'measuresDescription' }].map(({ name, ...props }) => (
            <Grid key={`defects-${name}`} className={classes.textFieldFormControlStyles} xs={12} item>
              <Controller
                control={control}
                name={`defects.${name}`}
                render={({ field }) => (
                  <TextField
                    {...sharedProps}
                    error={!!errors.defects?.[name]}

                    helperText={
                      errors?.defects?.[name]?.type &&
                      getJoiError(
                        errors?.defects?.[name]?.type,
                        { label: t(`labels.${name}`) },
                      )
                    }
                    fullWidth
                    label={t(`labels.${name}`)}
                    variant="outlined"
                    rows={4}
                    multiline
                    {...props}
                    {...field}
                  />
                )}
              />
            </Grid>
          ))}

          <Grid className={classes.textFieldFormControlStyles} xs={12} item>
            <FormControl component="fieldset" required>
              <NrlFormLabel
                required
                error={!!errors.defects?.state}
                // helperText={errors?.defects?.state?.type  ? joiTrans(errors?.defects?.state?.type) : ''}
                helperText={
                  errors?.defects?.state?.type &&
                  getJoiError(errors?.defects?.state?.type, { label: t('labels.state') })
                }
                component="legend">
                {t('labels.defectsFixed')}
              </NrlFormLabel>
              <Controller
                control={control}
                name={'defects.state'}
                render={({ field }) => (
                  <NrlRadioButtonGroup
                    row
                    aria-label={t('labels.defectsFixed')}
                    {...field}
                  >
                    {[...OPEN_RESOLVED_LST].map(({ label, value }, index) => (
                      <FormControlLabel
                        {...sharedProps}
                        value={value}
                        control={<Radio {...radioButtonSettings} />}
                        label={t(`${label}`)}
                        labelPlacement="end"
                      />
                    ))}
                  </NrlRadioButtonGroup>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Collapse>

      {((props?.form?.getValues()?.defects?.state !== entryState) && entryState) && (
        <Grid item hidden={false} xs={12}>
          <BannerNotification type={"info"} classNames={classes.defaultMessageNotificationStyles} message={t('labels.statusUpdated', { entry_state: t(`labels.${entryState}`) })} />
        </Grid>
      )}
    </Box>
  );
};

DefectsTabPanel.propTypes = {};

export default DefectsTabPanel;
