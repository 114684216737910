import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  DialogContentText,
} from '@material-ui/core';
import NrlPrimaryButtonWhite from '../../../Pages/Logbook/core/NrlPrimaryButton';

const DialogRadioGroup = props => {
  const {
    onClose,
    open,
    t,
    title,
    optionsLst = null,
    buttonLabels,
    dialogText = null,
    ...other
  } = props;
  const [value, setValue] = React.useState(other.value);
  const radioGroupRef = React.useRef(null);

  React.useEffect(
    open => {
      if (!open) {
        setValue(other.value);
      }
    },
    [other.value],
  );

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    props.onSelectionMade(value);
    onClose();
  };

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      maxWidth="xs"
      onEntering={handleEntering}
      aria-labelledby="radio-dialog-title"
      open={open}
      onClose={handleCancel}
      {...other}
    >
      {title && <DialogTitle id="radio-dialog-title">{title}</DialogTitle>}
      <DialogContent dividers>
        {/* {'0xffff' | 1} */}
        {/* 0101 0010 */}
        {dialogText && (
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
        )}
        {optionsLst && Array.isArray(optionsLst) && (
          <RadioGroup
            ref={radioGroupRef}
            aria-label="modal-radio-options"
            name="modal-radio-options"
            value={value}
            onChange={handleChange}
          >
            {optionsLst.map(({ id, name }) => (
              <FormControlLabel
                value={id}
                key={`modal-radio-options-${id}`}
                control={<Radio color="primary" />}
                label={name}
              />
            ))}
          </RadioGroup>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="outlined"
          onClick={handleCancel}
          color="primary"
        >
          {buttonLabels && buttonLabels.cancel
            ? buttonLabels.cancel
            : t('button.cancel')}
        </Button>
        <NrlPrimaryButtonWhite
          onClick={handleOk}
          disabled={value === null}
          color="primary"
          variant="contained"
        >
          {buttonLabels && buttonLabels.confirm
            ? buttonLabels.confirm
            : t('button.apply')}
        </NrlPrimaryButtonWhite>
      </DialogActions>
    </Dialog>
  );
};

DialogRadioGroup.propTypes = {
  // onClose: PropTypes.func.isRequired,
  // open: PropTypes.bool.isRequired,
  // value: PropTypes.string.isRequired,
};

export default DialogRadioGroup;
