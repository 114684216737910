import React from 'react';
const SubCatTrainLoadingPoint = () => {
  const a = {
    fill: '#e5e5e5',
    opacity: 0,
  };
  const b = {
    fill: '#002fc9',
    stroke: 'rgba(0,0,0,0)',
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      {/*<style>.a{fill:#e5e5e5;opacity:0;}.b{fill:#002fc9;stroke:rgba(0,0,0,0);}</style>*/}
      <g transform="translate(-441 -789)">
        <rect style={a} width="24" height="24" transform="translate(441 789)" />
        <path
          style={b}
          d="M-763,443l-3-6-3,6h-1l2-10-1-8h-5v5h-1v-5h-3v-1l9-1,1,2,4,8,2,10Zm-15-6v-6h7v6Z"
          transform="translate(1223 368)"
        />
      </g>
    </svg>
  );
};
export default SubCatTrainLoadingPoint;
