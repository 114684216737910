import { Icon } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import InfoOutlinedIcon from '../../node_modules/@material-ui/icons/InfoOutlined';
import { changedView, setFetchActiveLocoListData, stecConnect, setUpdateView } from '../actions';
import BootstrapTooltip from '../components/FvmMaterilUiTooltip';
import LoadingSpinner from '../components/LoadingSpinner';
import getStatusIcon from '../components/LocomotiveStatusIcons';
import NrailBootstrapTable from '../components/NrailBootstrapTable/index';
import MapControls from '../containers/MapControls';
import MapIframe from '../containers/MapIframe/index';

import {
  getCurrentView,
  kmFormatTableCol,
  setNumberUnit,
  timeFormatTableCol,
} from '../StatelessFunctions/nummericManapulation';
import FVMGoogleMaps from '../containers/FVMGoogleMaps';
import { didLocoSelectionChange } from '../utils/loco-helpers';


const buildTableHead = (t, getCellWithUnit, eLocFormatterLocal, tableColSort) => {
  return [
    {
      dataField: 'live.statusVehicle',
      text: t('Status'),
      sort: true,
      sortFunc: (a, b, order, key, rowA, rowB) => {
        const aObj = getStatusIcon({ status: rowA.live.statusVehicle, type: rowA.vehicle.type }, t);
        const bObj = getStatusIcon({ status: rowB.live.statusVehicle, type: rowB.vehicle.type }, t);
        if (order === 'desc') {
          return bObj.value - aObj.value;
        }
        return aObj.value - bObj.value; // asc
      },
      formatter: (cell, row) => {
        const machineStat = getStatusIcon({ status: row.live.statusVehicle, type: row.vehicle.type }, t);
        if (machineStat) {
          return <BootstrapTooltip title={machineStat.tooltip}><Icon>{machineStat.icon}</Icon></BootstrapTooltip>;
        }
        return null;
      },
    },
    {
      dataField: 'vehicle.name',
      text: t('tables.tHead.locomotive'),
      sort: true,
    },
    {
      dataField: 'live.speed',
      text: t('tables.tHead.speed'),
      formatter: (cell, row) => getCellWithUnit(cell, row, 'km/h', t),
      sort: true,
      sortFunc: tableColSort,
    },
    {
      dataField: 'total.mileageKm',
      // dataField: 'dailyMileageKm',
      text: t('tables.tHead.kmDrivenTotal'),
      formatter: (cell, row) => {
        return getCellWithUnit(
          cell,
          row,
          'km',
          t,
        );
      },
      sort: true,
      sortFunc: tableColSort,
    },
    {
      dataField: 'todays.workingHours',
      text: t('tables.tHead.dailyWorkingHours'),
      formatter: (cell, row) => eLocFormatterLocal(cell, row, t),
      sort: true,
      sortFunc: tableColSort,
    },

  ];
};


/**
 * ### General Description
 * ---
 * The view displays an overview of all the users locomotives.
 *
 * The view is divided into two section:
 *
 * - The map
 * - Liva Data
 *
 * ### The Map
 *
 * The dashboard __Map Section__ was designed to give this a geographical representation of his assets(cranes,
 * locomotives). The section allows the user to see and interact with the items on the map. The section con be
 * further divided into two: the map controls and the map itself.
 *
 * The map controls is located at the top of the map and provides additional functionalities and ways of interacting
 * with map. Please refer to the Map section of this documentation for a more detailed description of the map and
 * its functionalities.
 *
 * ### Live Data
 *
 * The __Live Data Section__ was designed to the user an overview of the most important information about an asset
 * at a glance. This information is displayed in a tabular format as depicted below:
 *
 * | Status | Locomotive | Velocity | Overall Mileage| Operating Hours|
 * |:-------|-----------:|---------:|---------------:|---------------:|
 *
 * Additional to this there is the Loc control located to the left of the main view. A detailed description can be
 * found under the components section of this documentation.
 */
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.updateLiveTableData = this.updateLiveTableData.bind(this);
    this.fetchDashboardData = debounce(this.fetchDashboardData.bind(this), 400);

    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleClearAll = this.handleClearAll.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.eLocFormatterLocal = this.eLocFormatterLocal.bind(this);
    this.getCellWithUnit = this.getCellWithUnit.bind(this);
    this.isElogIsCrane = this.isElogIsCrane.bind(this);
    this.tableColSort = this.tableColSort.bind(this);
    this.getActiveLocs = this.getActiveLocs.bind(this);
    this.getDashboardLiveDataForInitialLoad = this.getDashboardLiveDataForInitialLoad.bind(this);

    this.state = {
      liveDataTable: {
        data: [],
      },
      open: false,
      tweets: [],
      activeUserLocomotiveListIds: props.activeUserLocomotiveListIds,
    };

    this.commonClassNames = 'section-heading fvm-dashboard-table';
  }

  isElogIsCrane(a) {
    const { isElog, isCrane } = a;
    return isElog || isCrane || false;
  }

  tableColSort(a, b, order, name, row) {
    const multiplier = order === 'desc' ? -1 : 1;
    const aVal = a && this.isElogIsCrane(a) ? multiplier * 1000000000000000 : a;
    const bVal = b && this.isElogIsCrane(b) ? multiplier * 1000000000000000 : b;

    if (order === 'desc') {
      return bVal - aVal;
    }
    return aVal - bVal; // asc
  }

  componentDidMount() {
    this.props.setUpdateView(false);
    if (this.props.locoListLoaded) {
      this.setState({ triggerInitialLoad: true }, this.fetchDashboardData);
    }
    // this.setintervalDashboardId = setInterval(this.fetchDashboardData, 30000); // used to keep dashboard live data up-to-date
    window.addEventListener('message', this.receiveMessage, false);

    if (!!Object.keys(this.props.user.accessRights).length) {
      if (!this.props.user.accessRights?.['gateway/telematics']?.canRead) {
        window.location.href = "/logbook-all-entries";
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.setintervalDashboardId);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      locoListLoaded,
    } = props;

    const newState = {};

    if (locoListLoaded && !state.locoListLoaded && !state.triggerInitialLoad) {
      newState.triggerInitialLoad = true;
    }

    return Object.keys(newState).length ? newState : null;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectAllDriving,
      selectAllStanding,
      selectAllEquipped,
      selectAllOff,
      isUpdateView,
      locoListLoaded
    } = prevProps;

    if (
      this.props.activeUserLocomotiveListIds.length > prevProps.activeUserLocomotiveListIds.length ||
      (this.props.activeUserLocomotiveListIds.filter(vehicleId => !prevProps.activeUserLocomotiveListIds.includes(vehicleId)).length)
    ) {
      this.fetchDashboardData();
      // controls loading symbol to only render when a train is clicked and not when the list is updated based on the train status
      if (!selectAllOff && !selectAllEquipped && !selectAllStanding && !selectAllDriving) {
        this.updateLoadingState(true);
      }
    }

    if ((isUpdateView !== this.props.isUpdateView)) {
      this.updateLiveTableData(Object.values(this.props.userLocomotiveListObject));
      setTimeout(() => {
        this.props.setUpdateView(false);
        this.updateLoadingState(false);
      }, 150);
    }

    if (didLocoSelectionChange(this.props.activeUserLocomotiveListIds, prevProps.activeUserLocomotiveListIds)) {
      // if (this.props.activeUserLocomotiveListIds.length < prevProps.activeUserLocomotiveListIds.length) {
      this.removeDeselectedLoco(this.props.activeUserLocomotiveListIds);
    }

    if (this.props.locoListLoaded && !this.props.isInitialUserDeviceSelectionCompleted && this.state.triggerInitialLoad) {
      this.setState({ locoListLoaded, triggerInitialLoad: false }, this.fetchDashboardData);
    }

    if (didLocoSelectionChange(this.props.activeUserLocomotiveListIds, prevProps.activeUserLocomotiveListIds) && !this.props.activeUserLocomotiveListIds.length && this.props.isInitialUserDeviceSelectionCompleted) {
      this.updateLiveTableData([]);
    }

    if (this.props.languageFilesReloaded !== prevProps.languageFilesReloaded) {
      const { data } = this.state.liveDataTable;
      // resetting the table to allow language update
      // this.updateLiveTableData([]);
      this.fetchDashboardData();
      // setTimeout(() => this.updateLiveTableData(data.map(entry => ({...entry}))), 500);
    }

    if (this.props.user.accessRights != prevProps.user.accessRights) {
      if (!!Object.keys(this.props.user.accessRights).length) {
        if (!this.props.user.accessRights?.['gateway/telematics']?.canRead) {
          window.location.href = "/logbook-all-entries";
        }
      }
    }
  }

  getDashboardLiveDataForInitialLoad() {
    if (localStorage.getItem('fvm_user_initial_load')) {
      if (Object.keys(this.props.userLocomotiveListObject).length) {
        this.fetchDashboardData();
      } else {
        setTimeout(this.getDashboardLiveDataForInitialLoad, 3000);
      }
    }
  }

  eLocFormatterLocal(cell, row, t) {
    if (row.isCrane) {
      return t('labels.crane');
    }

    if (row.isEloc) {
      return (
        <span>
          {t('labels.eLoc')}
          <BootstrapTooltip
            title={`${t('tooltip.dailyEquippedHours')} : ${timeFormatTableCol(row.dailyEquippedHours, t)} \n ${t('tooltip.dailyMileage')}: ${kmFormatTableCol(row.dailyMileageKm)}`}
          >
            <i className="fvm-info-icon">
              <InfoOutlinedIcon />
            </i>
          </BootstrapTooltip>
        </span>);
    }

    return timeFormatTableCol(cell, t);
  }

  getCellWithUnit(cell, row, unit, t) {
    if (row.isCrane) {
      return t('labels.crane');
    }

    return setNumberUnit(cell, unit, t('tables.tRow.notApplicable'));
  }

  getActiveLocs() {

    return this.props.isInitialUserDeviceSelectionCompleted
      ? this.props.activeUserLocomotiveListIds
      : Object.keys(this.props.userLocomotiveListObject);
  }

  fetchDashboardData(activeUserLocomotiveListIds = this.getActiveLocs()) {
    this.props.setFetchActiveLocoListData(true);
  }

  updateLoadingState(status, time = 0) {
    setTimeout(() => {
      this.setState({ isLoading: status });
    }, time);
  }

  removeDeselectedLoco(activeUserLocomotiveListIds) {
    const { liveDataTable } = this.state;
    const data = liveDataTable.data.filter(loco => activeUserLocomotiveListIds.includes(loco.vehicleId));
    this.setState({
      liveDataTable: { data },
    });
  }

  updateLiveTableData(serverJsonResponse) {
    const data = serverJsonResponse.map((trainObj) => {
      const newTrainVals = {
        ...this.props.userLocomotiveListObject[trainObj.vehicleId],
        ...trainObj, // must come after the old loco data!!!
      };

      return newTrainVals;
    }).filter(loco => !this.props.isInitialUserDeviceSelectionCompleted || this.props.activeUserLocomotiveListIds.includes(loco.vehicleId));

    this.setState({
      liveDataTable: { data },
    });
  }

  handleDrawerOpen() {
    this.setState({ open: !this.state.open });
  }

  handleDrawerClose() {
    this.setState({ open: false });
  }

  handleClearAll() {
    const { selected } = this.state;
    Object.keys(selected).forEach((item) => {
      selected[item] = false;
    });
    const secondLevel = false;
    this.setState({ focusedItem: null, selected, secondLevel });
  }

  handleChange(selectedCheckbox) {
    const { selected } = this.state;
    selected[selectedCheckbox] = !selected[selectedCheckbox];
    const secondLevel = Object.values(selected).includes(true);
    this.setState({ selected, secondLevel });
  }

  render() {
    const { liveDataTable, isLoading } = this.state;
    const { t, isRailationDrawOpen } = this.props;
    const defaultSorted = [{
      dataField: 'vehicle.name',
      order: 'asc',
    }];

    const { data } = liveDataTable;

    return (
      <article className="fvm-page fvm-page-dashboard">
        <div className="fvm-map-page-toolbar-container">
          <Toolbar
            className="fvm-map-page-toolbar"
            disableGutters
          >
            {t ? <MapControls t={t} /> : null}
          </Toolbar>
        </div>
        <div className="fvm-general-page-content">
          <section className="fvm-sp fvm-sp-map-iframe container-fluid">
            <div className="row">
              <div className="col fvm-map-iframe map-detailed-page-control">
                {
                  Object.keys(this.props.userLocomotiveListObject).length > 0
                    // ? (localStorage.getItem('fvmfeatureactive') ? <FVMGoogleMaps t={t} railationFeatures={[]}/> :
                    ? <FVMGoogleMaps t={t} railationFeatures={[]} />
                    : null
                }
              </div>
            </div>
          </section>
          <section className="fvm-sp fvm-sp-live-data container-fluid">
            <div className="row">
              <div className="col">
                {
                  (data) && (
                    <NrailBootstrapTable
                      keyField="vehicleId"
                      bootstrap4
                      className={this.commonClassNames}
                      title={t('tables.caption.liveData')}
                      defaultSorted={defaultSorted}
                      columns={buildTableHead(t, this.getCellWithUnit, this.eLocFormatterLocal, this.tableColSort)}
                      data={data}
                    />)
                }
              </div>
            </div>
          </section>
        </div>
        {isLoading || this.props.isMapLoading ? <LoadingSpinner /> : null}
      </article>
    );
  }
}

Dashboard.propTypes = {
  newView: PropTypes.func.isRequired,
  isDemoUser: PropTypes.bool.isRequired,
  activeUserLocomotiveListIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  userLocomotiveListObject: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  selectAllDriving: state.selectAllDriving,
  selectAllStanding: state.selectAllStanding,
  selectAllEquipped: state.selectAllEquipped,
  selectAllOff: state.selectAllOff,
  locoListLoaded: !!Object.keys(state.userLocomotiveList).length,
  activeUserLocomotiveListIds: state.activeUserLocomotiveListIds,
  isDemoUser: state.isDemoUser,
  isRailationDrawOpen: state.railationDrawOpen,
  locListUpdated: state.locListUpdated,
  userLocomotiveListObject: state.userLocomotiveList,
  isMapLoading: state.isMapLoading,
  isStecConnectionLost: state.isStecConnectionLost,
  isFvmFeaturesActive: state.isFvmFeaturesActive,
  userSessionID: state.userSessionID,
  languageFilesReloaded: state.languageFilesReloaded,
  isInitialUserDeviceSelectionCompleted: state.isInitialUserDeviceSelectionCompleted,
  isUpdateView: state.isUpdateView,
  user: state.user.user,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    newView: changedView,
    setFetchActiveLocoListData: setFetchActiveLocoListData,
    stecConnect: stecConnect,
    setUpdateView: setUpdateView,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
