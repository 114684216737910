import React from 'react';
import { trackButtonClick } from '../../containers/GATracker';

const FvmGAItemTracker = ({ category, label, action, value, children, onClick}) => {
  const newChild = React.Children.map(children, (child, index) => {


    return React.cloneElement(child, {
      index,
      onClick: (e) => {
        if (value !== undefined) {
          trackButtonClick(category, label, action, value);
        } else {
          trackButtonClick(category, label, action);
        }
        if(onClick){
          onClick(e);
        }

        if (child.props.onClick) {
          child.props.onClick(e);
        }
      },
    });
  });

  return newChild;
};

// class FvmGAItemTracker extends Component {
//   render() {
//     return( <div>{this.props.children}</div>
//     )
//   }
// }


export default FvmGAItemTracker;