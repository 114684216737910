import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper } from '@material-ui/core';
import { ReportProblemOutlined, NetworkWifi, Info } from '@material-ui/icons';
import PermScanWifiIcon from '@material-ui/icons/PermScanWifi';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const bannerMap = {
  warn: {
    icon: ReportProblemOutlined,
    backgroundColor: 'rgb(255, 244, 229)',
    iconColor: '#ff9800',
    color: 'rgb(102, 60, 0)',
  },
  error: {
    icon: ReportProblemOutlined,
    backgroundColor: '#F44336D0',
    color: '#FFFFFF',
    background: '#F443360A',
  },
  info: {
    icon: Info,
    backgroundColor: 'rgba(224, 247, 250, 0.5)',
    iconColor: '#2196F3',
    color: '#2196F3',
    padding: 20,
  },

  noConnection: {
    icon: PermScanWifiIcon,
    backgroundColor: '#F44336',
    color: '#FFFFFF',
    background: '#F443360A',
  },
};


const useStyles = ({ type = 'warn', alignment = 'left', size }) => makeStyles((theme) => ({
  alertMessageBox: {
    paddingLeft: 12,
    background: bannerMap[type].backgroundColor,
    borderRadius: 4,
    boxShadow: 'none',
    color: bannerMap[type].color,
    padding: size === 'small' ? '4px 4px' : '16px 8px',
    display: 'flex',
    justifyContent: alignment,
  },
  alertMessageBoxIcon: {
    color: bannerMap[type].iconColor,
  },
  contentContainer: {
    padding: '0px 12px',
    whiteSpace: 'pre-line',
    height: '80%',
    '&> p': {
      padding: 2,
    },
  },

}))();


const BannerNotification = ({ type = 'warn', alignment = 'left', message, size = 'small', ...props }) => {
  const classes = useStyles({ type, alignment, size });
  const IconComponent = bannerMap[type].icon || bannerMap.warn.icon;
  return (
    <Paper className={classNames(classes.alertMessageBox, props.classNames)} elevation={0}>
      <IconComponent className={classes.alertMessageBoxIcon} />
      <Box className={classes?.contentContainer} component="div">
        <Typography component="p"> {message}</Typography>
      </Box>
    </Paper>
  );
};


const useStylesNoConnection = () => makeStyles((theme) => ({
  noConnectionContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1500,
  },

}))();

export const NotConnectionBanner = () => {
  const { t } = useTranslation('');
  const isOnline = useSelector(({ isOnline }) => isOnline);
  const classes = useStylesNoConnection();

  return (
    <Box className={classes.noConnectionContainer} hidden={isOnline}>
      <BannerNotification alignment="center" message={t('labels.noConnection')} type="noConnection" height={30} />
    </Box>
  );
};


export default BannerNotification;