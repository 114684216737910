import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_de_DE from '@amcharts/amcharts4/lang/de_DE';
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { withStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import BarChart from '@material-ui/icons/BarChart';
import Close from '@material-ui/icons/Close';
import PieChart from '@material-ui/icons/PieChart';
import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';
// import ReactTooltip from 'react-tooltip';
import Accumulative from '../../assets/svgs/accumilative.jsx';

import { getCurrentView } from '../../StatelessFunctions/nummericManapulation';
import { fetchGraphReport, fetchPieChartData } from '../../wp-rest-api/STEC-Communication';
import FvmGAItemTracker from '../FvmGAItemTracker';
import BootstrapTooltip from '../FvmMaterilUiTooltip';
import LoadingSpinner from '../LoadingSpinner';

const drawerWidth = '80%';
const styles = theme => ( {
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    padding: '20px',
    backgroundColor: '#F8F8F8',
    // maxWidth: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6),
  },
  content: {
    flexGrow: 1,
  },
  listItemText: {
    fontWeight: '700',
    fontSize: theme.typography.fontSize,
  },
  inline: {
    display: 'inline',
    maxWidth: '20%',
  },
  outerGrid: {
    maxHeight: 'calc(100vh - 160px - 81px)',
    backgroundColor: '#FFFFFF',
  },
  gridItems: {
    borderRight: '1px solid #DFE3E6',
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  root: {
    whiteSpace: 'normal',
    fontSize: 14,
  },
  exportButtons: {
    backgroundColor: '#FFFFFF',
  },
  sheetTypeRadio: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      marginLeft: '18px',
    },
    display: 'inline-flex',
    flexDirection: 'row',
    marginLeft: '18px',
  },
  sheetType: {
    display: 'flex',
  },
  sheetTypeLabel: {
    alignItems: 'center',
    display: 'flex',
    padding: '24px 0',
  },
  upperControlsCheckbox: {
    marginLeft: '-30px',
    minWidth: '275px',
  },
  upperControlsInput: {
    paddingLeft: '15px !important',
    backgroundColor: '#FFFFFF !important',
    border: '1px solid #DFE3E6 !important',
    boxShadow: 'unset !important',
  },
  flexSpacebetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  graphContainer: {
    width: '100%',
    height: 'calc(100vh - 160px - 81px)',
    backgroundColor: '#FFFFFF',
    border: '1px solid #DFE3E6',
  },
  drawLoader: {
    margin: '0 -20px',
  },
  zoomed: {
    zoom: '80%',
  },
} );

class ReportGraphing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      checked: props.activeUserLocoId ? [props.activeUserLocoId] : [],
      exportData: {},
      outputFormat: props.outputFormat,
      focusedInput: false,
      selectAll: false,
      isLoading: false,
      ...props.period,
      locoList: Object.values(props.userLocomotiveListObject),
      filterTerm: '',
      activeGraph: props.activeGraph,
      selectedSlice: {},
    };

    this.metricNames = {
      equippedHours: props.t('graph.EquippedHours'),
      workingHours: props.t('graph.WorkingHours'),
      standingEngineOn: props.t('graph.StandingWithEngineOn'),
      totalStandingNoMovement: props.t('graph.StandingNoMovement'),
      idelTimeWithoutEnginInStartMode: props.t('graph.idelTimeWithoutEnginInStartMode'),
      idleTimeWIthEnginRunning: props.t('graph.idleTimeWIthEnginRunning'),
      drivingHours: props.t('graph.DrivingHours'),
    };

    this.charts = {
      xyChart: false,
      pie: false,
    };
    this.selectedSlice = undefined;
    this.handleClearAll = this.handleClearAll.bind(this);
    this.fetchReportExportData = this.fetchReportExportData.bind(this);
    this.onSheetOutputChange = this.onSheetOutputChange.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.saveDate = this.saveDate.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.updateChart = this.updateChart.bind(this);
    this.changeChart = this.changeChart.bind(this);
    this.isDateBlocked = this.isDateBlocked.bind(this);
  }

  componentDidMount() {
    const { activeGraph } = this.props;
    this.updateChart(activeGraph);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { open, activeUserLocoId, userLocomotiveListObject, period } = this.props;
    const { locoList } = this.state;
    const { startDate, endDate } = prevProps;

    if (open !== this.state.open) {
      this.setState({ open });
    }
    if (activeUserLocoId !== prevProps.activeUserLocoId) {
      this.setState({ checked: [activeUserLocoId] });
    }
    if (Object.values(userLocomotiveListObject).length !== locoList.length) {
      this.setState({ locoList: Object.values(userLocomotiveListObject) });
    }

    if (startDate && endDate && ( period.startDate.format('YYYYMMDD') !== startDate.format('YYYYMMDD') ||
      period.endDate.format('YYYYMMDD') !== endDate.format('YYYYMMDD') )) {
      this.setState({ startDate: { ...period.startDate }, endDate: { ...period.endDate } });
      this.handleDateChange(period);
    }
  }

  componentWillUnmount() {
    if (this.charts.pie) {
      this.charts.pie.dispose();
    }
    if (this.charts.xyChart) {
      this.charts.xyChart.dispose();
    }
  }

  handleClearAll() {
    this.props.handleClearAll(false);
  }

  handleToggle(value) {
    const { checked, locoList } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
      selectAll: newChecked.length === locoList.length,
    });
  }

  saveDate() {
    this.setState({ focusedInput: false });
    this.fetchReportExportData(this.state.activeGraph);
  }

  getIdleTimeRunningEngin(metricObj) {
    return metricObj.EquippedHours - metricObj.StandingNoMovement;
  }

  idelTimeWithoutEnginInStartMode(metricObj) {
    return metricObj.StandingNoMovement - metricObj.StandingEngineOn;
  }

  async fetchReportExportData(activeGraph) {
    const { isDemoUser, userSessionID, activeUserLocoId, locoFuelConsValues, isEloc } = this.props;
    const { checked, startDate, endDate } = this.state;
    const currentView = getCurrentView();
    const groupByType = 'by-metric';
    this.setState({ isLoading: true });
    let resp;

    if (activeGraph === 'pieChart') {
      const metricObj = await fetchPieChartData({
        vehicleId: activeUserLocoId,
        // isDemoUser,
        startDate,
        endDate,
      });

      this.setState({ exportData: resp });
      // const metricObj = resp[0];
      // const metrics = Object.keys(metricObj);

      const getSlice = (key, index, subSlices = []) => {
        return {
          category: this.metricNames[key],
          toolTip: !isEloc && key === 'totalStandingNoMovement' ? `${this.metricNames.standingEngineOn} + ${this.metricNames.idelTimeWithoutEnginInStartMode}` : this.metricNames[key],
          key,
          subSlices,
          val: key === 'totalStandingNoMovement' ? metricObj.idleHours.total + metricObj.standByHours.total : metricObj[key].total,
          // fuelConsumption,
          fuelConsumption: 0,
          // hidden: !this.props.showGraphs[key],
          id: index,
          color: key === 'totalStandingNoMovement' ? '#F956FF' : '#06DE3A',
          labelHeight: 10,
        };
      };
      const subSlices = [];
      if (!isEloc) {
        subSlices.push(
          {
            color: '#FF8A00',
            category: this.metricNames.standingEngineOn,
            toolTip: this.metricNames.standingEngineOn,
            val: metricObj.idleHours.total,
            // fuelConsumption: isEloc ? 0 : metricObj.StandingEngineOn * locoFuelConsValues.standingWithEngRunning,
            fuelConsumption: 0,
            id: 1,
            labelHeight: 55,
          },
          {
            color: '#5AAAFA',
            category: this.metricNames.idelTimeWithoutEnginInStartMode,
            toolTip: this.metricNames.idelTimeWithoutEnginInStartMode,
            val: metricObj.standByHours.total,
            fuelConsumption: 0,
            id: 1,
            labelHeight: 30,
          },
        );
      }


      const pieData = [getSlice('drivingHours', 0), getSlice('totalStandingNoMovement', 1, subSlices)];
      this.buildPieChart(pieData);
      this.charts.pie.rawData = [getSlice('drivingHours', 0), getSlice('totalStandingNoMovement', 1, subSlices)];


      setTimeout(() => {
        const selectedSliceTemp = {};
        this.charts.pie.data.forEach((entry) => {
          selectedSliceTemp[entry.id] = true;
        });
        this.setState({ selectedSlice: { ...selectedSliceTemp } });
        this.charts.pie.data = this.splitPieChartData(selectedSliceTemp);
      }, 2000);
    }

    if (activeGraph === 'xyChart' || activeGraph === 'xyChartAccum') {
      resp = await fetchGraphReport({
        vehicleId: checked,
        startDate,
        endDate,
      });

      if (activeUserLocoId === this.props.activeUserLocoId) {
        const prepareChartData = (rawData) => rawData.map((item) => {
          ['equippedHours', 'workingHours', 'standByHours', 'idleHours'].forEach(itemKey => {
            if (item[itemKey] < 0) {
              item[itemKey] = 0;
            }
          });

          return {
            ...item,
            // fuelConsumption: isEloc ? 0 : (item.mileageKm * locoFuelConsValues.totalLetersPerKm) +
            // (item.standingWithEngineOnHours * locoFuelConsValues.standingWithEngRunning),
            date: isDemoUser ? moment(item.date, 'YYYYMMDD').add(4, 'week').toDate() : moment(item.date, 'YYYYMMDD').toDate(),
          };
        });


        const dateObjMap = {};
        const isCumulative = activeGraph === 'xyChartAccum';
        if (isCumulative) {
          resp.forEach(({ date, cumulativeSum }) => {
            dateObjMap[date] = { date, ...cumulativeSum };
          });
        } else {
          resp.forEach(({ date, thisDay }) => {
            dateObjMap[date] = { date, ...thisDay };
          });
        }

        const preparedChartData = prepareChartData(Object.values(dateObjMap)).reverse();
        // console.log(preparedChartData)

        this.buildChart(preparedChartData);
      }
    }

    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1500);
  }

  onSheetOutputChange(event) {
    this.setState({
      outputFormat: event.target.value,
      exportData: {},
    });
  }

  handleDateChange({ startDate, endDate }) {
    this.setState({ startDate, endDate });
  }

  onFilterChange(event) {
    const filterTerm = event.target.value;
    const re = new RegExp(filterTerm, 'i');
    this.setState({
      locoList: filterTerm.trim() ? Object.values(this.props.userLocomotiveListObject)
                                          .filter(loco => re.exec(loco.vehicle.name) && true)
        : Object.values(this.props.userLocomotiveListObject),
      filterTerm,
    });
  }

  handleSelectAll(event) {
    const { locoList } = this.state;
    const selectAll = event.target.checked;
    const checked = selectAll ? locoList.map(loco => loco.vehicleId) : [];

    this.setState({
      checked,
      selectAll,
    });
  }

  buildChart(data = []) {

    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    const chart = am4core.create('fvm-graph-elm', am4charts.XYChart);
    this.charts.xyChart = chart;
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    // dateAxis.renderer.min = 0;

    chart.data = data;
    // // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.itemContainers.template.paddingTop = 5;
    chart.legend.itemContainers.template.paddingBottom = 5;
    // chart.numberFormatter.numberFormat = '#.00';
    chart.responsive.enabled = true;

    // chart.legend.itemContainers.template.events.on('hit', (ev) => {
    //   global.fvmLog('Clicked on: ', ev.target.dataItem.dataContext.name, ev.target.dataItem);
    // });
    // // Add cursor
    const chartData = [

      // {
      //   field: 'equippedHours', // Einsatzdauer
      //   name: this.props.t('graph.EquippedHours'),
      //   bulletType: 'circle',
      //   color: '#42AEB8',
      //   line: true,
      // },
      {
        field: 'workingHours', // Betriebsstunden
        name: this.props.t('graph.WorkingHours'),
        bulletType: 'circle',
        color: '#CB1B4A',
        line: true,
      },

      {
        field: 'drivingHours', // Fahrtstunden
        name: this.props.t('graph.DrivingHours'),
        bulletType: 'circle',
        color: '#06DE3A',
        line: true,
      },
      // {
      //   field: 'standByHours', // Standzeit mit Motor
      //   name: this.props.t('graph.StandingWithEngineOn'),
      //   bulletType: 'rectangle',
      //   color: '#FF8A00',
      //   line: true,
      // },
      {
        field: 'idleHours', // Gesamtstandzeit
        // name: this.props.t('graph.StandingNoMovement'),
        name: this.props.t('graph.idleHours'),
        bulletType: 'circle',
        // color: '#F956FF',
        color: '#FF8A00',
        line: true,
      },
    ];
    const chartData2 = [
      {
        field: 'mileageKm',
        name: this.props.t('graph.KmDriven'),
        bulletType: 'circle',
        // color: '#001044',
        color: '#a5a6ac',
        strokeWidth: 3.5,
        line: ['xyChartAccum'].includes(this.state.activeGraph),
      },
    ];

    const chartData3 = [
      {
        field: 'fuelConsumption', // Gesamtstandzeit
        name: this.props.t('graph.fuelConsumption'),
        bulletType: 'circle',
        color: '#CAEE56',
        line: true,
      },
    ];

    this.createAxisAndSeries(chartData2, true, 'km');
    // if (this.props.locoFuelConsValues.isActive) {
    //   this.createAxisAndSeries(chartData3, false, 'l');
    // }
    const series = this.createAxisAndSeries(chartData, false, 'h', true);

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.strokeDasharray = '';
    // Enable export
    const chatTypeTitle = this.props.t(`graph.title.${this.state.activeGraph === 'xyChart' ? 'xy' : 'accumulative'}`);
    chart.exporting.menu = new am4core.ExportMenu();
    // chart.exporting.filePrefix = 'Working';
    chart.exporting.filePrefix = `${this.props.name} (${chatTypeTitle}) ${this.state.startDate.format('DD.MM.YYYY')}-${this.state.endDate.format('DD.MM.YYYY')}`;

    chart.exporting.menu.items = [
      {
        // label: 'Export',
        label: '...',
        menu: [
          {
            label: 'Image',
            menu: [
              { type: 'png', label: 'PNG' },
              { type: 'jpg', label: 'JPG' },
              { type: 'gif', label: 'GIF' },
              { type: 'svg', label: 'SVG' },
            ],
          },
          {
            label: 'Print', type: 'print',
          },
        ],
      },
    ];

    // this.createAxisAndSeries("hits", "Hits", true, "rectangle");

    // Add scrollbar
    chart.scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX.series.push(series);
    chart.scrollbarX.scrollbarChart.seriesContainer.hide();

    const title = chart.titles.create();
    title.text = `
      [bold]${chatTypeTitle}: ${this.props.name}\n[/]
      ${this.state.startDate.format('DD.MM.YYYY')} - ${this.state.endDate.format('DD.MM.YYYY')}`;
    title.textAlign = 'middle';

    // chart.durationFormatter.durationFormat = 'hh';
    chart.durationFormatter.durationFormat = 'hh \'h\' mm \'min\'';
    chart.durationFormatter.baseUnit = 'hour';

    //Language settings
    chart.language.locale = localStorage.getItem('i18nextLng') === 'de' ? am4lang_de_DE : am4lang_en_US;
  }

  // Create series
  createAxisAndSeries(fieldArray, opposite = false, units = 'h', disableBullets = false, scrollBar = false) {
    const { t } = this.props;
    let finalSeries = null;
    const valueAxis = this.charts.xyChart.yAxes.push(units === 'h' ? new am4charts.ValueAxis() : new am4charts.ValueAxis());
    valueAxis.cursorTooltipEnabled = false;

    if (units === 'l') {
      valueAxis.baseUnit = 'leters';
      valueAxis.title.text = t('graph.axis.leters');
    }

    if (units === 'h') {
      valueAxis.baseUnit = 'hour';
      valueAxis.title.text = t('graph.axis.duration');
    }
    if (units === 'km') {
      valueAxis.title.text = t('graph.axis.distance');
    }

    fieldArray.forEach((fieldItem) => {
      const { field, name, color, line } = fieldItem;
      const series = line ? this.charts.xyChart.series.push(new am4charts.LineSeries()) : this.charts.xyChart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = 'date';
      series.strokeWidth = fieldItem.strokeWidth ? fieldItem.strokeWidth : 2;
      series.yAxis = valueAxis;
      series.name = name;
      series.hidden = !this.props.showGraphs[fieldItem.field];
      series.tooltipText = units === 'h' ? '{name}: [bold]{valueY.formatDuration()}[/]' : '{name}: [bold]{valueY}[/]' + ` ${units}`;
      // series.tensionY = 0.8;
      series.fill = am4core.color(color);
      series.stroke = am4core.color(color);

      finalSeries = series;
    });

    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 1.5;
    valueAxis.renderer.opposite = opposite;
    if (this.state.activeGraph !== 'xyChartAccum') {
      valueAxis.min = 0;
    }
    valueAxis.renderer.labels.template.adapter.add('text', text => text + units);

    return finalSeries;
  }

  buildPieChart(data = []) {
    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    const chart = am4core.create('fvm-graph-elm', am4charts.PieChart);
    chart.innerRadius = am4core.percent(15);
    chart.radius = am4core.percent(50);
    chart.data = data;
    chart.responsive.enabled = true;

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.itemContainers.template.padding(0, 0, 40, 0);

    chart.exporting.filePrefix = `${this.props.name} (${this.props.t('graph.title.pie')}) ${this.state.startDate.format('DD.MM.YYYY')}-${this.state.endDate.format('DD.MM.YYYY')}`;

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = [
      {
        // label: 'Export',
        label: '...',
        menu: [
          {
            label: 'Image',
            menu: [
              { type: 'png', label: 'PNG' },
              { type: 'jpg', label: 'JPG' },
              { type: 'gif', label: 'GIF' },
              { type: 'svg', label: 'SVG' },
            ],
          },
          {
            label: 'Print', type: 'print',
          },
        ],
      },
    ];

    chart.language.locale = localStorage.getItem('i18nextLng') === 'de' ? am4lang_de_DE : am4lang_en_US;
    // chart.baseUnit = 'hours';
    chart.durationFormatter.durationFormat = 'hh \'h\' mm \'min\'';
    chart.durationFormatter.baseUnit = 'hour';

    const title = chart.titles.create();
    title.text = `
      [bold]${this.props.t('graph.title.pie')}: ${this.props.name}\n[/]
      ${this.state.startDate.format('DD.MM.YYYY')} - ${this.state.endDate.format('DD.MM.YYYY')}`;
    title.textAlign = 'middle';

    this.charts.pie = chart;
    this.createPieSeries();
  }

  splitPieChartData(selectedSlice) {
    const newData = [...this.charts.pie.rawData];
    const activeSlices = Object.keys(selectedSlice).filter(key => selectedSlice[key] && newData[key] && newData[key].subSlices);
    const selectedData = [];
    if (activeSlices.length) {
      activeSlices.forEach((id) => {
        if (newData[id].subSlices.length) {
          selectedData[id] = newData[id].subSlices.map(catDat => (
            {
              ...catDat,
              pulled: true,
            } ));
          return;
        }
        selectedData[id] = [{
          ...newData[id],
          pulled: true,
        }];
      });
    }

    let finalPie = [];
    newData.forEach((slices, index) => {
      if (activeSlices.includes(`${index}`)) {
        finalPie = finalPie.concat(...selectedData[index]);
      } else {
        finalPie = finalPie.concat(slices);
      }
    });

    return [...finalPie];
  }

  // Create Pie Series
  createPieSeries() {
    const pieSeries = this.charts.pie.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.category = 'category';
    pieSeries.dataFields.value = 'val';
    pieSeries.dataFields.hidden = 'hidden';
    pieSeries.dataFields.color = 'color';
    pieSeries.labels.template.text = '{category}: {value.percent.formatNumber("#.0")}% ' +
      '({value.value.formatDuration()})';

    if (this.props.locoFuelConsValues.isActive) {
      // pieSeries.labels.template.text += `\n${this.props.t('graph.fuelConsumption')}:` +
      // '{fuelConsumption.formatNumber("#.0")} l';
      pieSeries.labels.template.text += `\n${this.props.t('graph.fuelConsumption')}: {fuelConsumption.formatNumber("#.0")} l`;
    }
    pieSeries.slices.template.tooltipText = '{toolTip}';
    pieSeries.labels.template.adapter.add('fill', (color, target) => {
      if (target.dataItem && ( target.dataItem.values.value.percent < 20 )) {
        return am4core.color('#000');
      }
      return color;
    });

    pieSeries.slices.template.adapter.add('fill', (color, target) => {
      if (target.dataItem.dataContext) {
        return am4core.color(target.dataItem.dataContext.color);
      }
      return am4core.color(color);
    });

    // Put a thick white border around each Slice
    pieSeries.slices.template.stroke = am4core.color('#FFFFFF');
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template.cursorOverStyle = [
      {
        property: 'cursor',
        value: 'pointer',
      },
    ];

    pieSeries.slices.template.propertyFields.isActive = 'pulled';
    pieSeries.slices.template.events.on('hit', (event) => {
      const sliceId = event.target.dataItem.dataContext.id;

      if (sliceId !== undefined) {
        this.selectedSlice = event.target.dataItem.dataContext.id;
        this.setState({
          selectedSlice: {
            ...this.state.selectedSlice,
            [sliceId]: !this.state.selectedSlice[sliceId],
          },
        });
        pieSeries.data = this.splitPieChartData({
          ...this.state.selectedSlice,
        });
      } else {
        pieSeries.data = this.splitPieChartData({ ...this.state.selectedSlice });
      }
    });

    // pieSeries.alignLabels = false;
    // pieSeries.labels.template.radius = am4core.percent(10);
    // pieSeries.labels.template.padding(0, 10, 0, 10);

    pieSeries.alignLabels = false;
    pieSeries.labels.template.radius = am4core.percent(10);

    pieSeries.labels.template.adapter.add('radius', (radius, target) => {
      if (target.dataItem) {
        return am4core.percent(target.dataItem.dataContext.labelHeight);
      }

      return radius;
    });
    // pieSeries.ticks.template.disabled = true;

    // Create a base filter effect (as if it's not there) for the hover to return to
    const shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter());
    shadow.opacity = 0;

    // Create hover state
    const hoverState = pieSeries.slices.template.states.getKey('hover'); // normally we have to create the hover state,
                                                                         // in this case it already exists

    // Slightly shift the shadow and make it more prominent on hover
    const hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
    hoverShadow.opacity = 0.7;
    hoverShadow.blur = 5;

    return pieSeries;
  }

  updateChart(activeGraph) {
    this.fetchReportExportData(activeGraph);
  }

  changeChart(activeGraph) {
    this.setState({ activeGraph });
    this.updateChart(activeGraph);
  }

  isDateBlocked(date) {
    const { activeUserLocoId, userLocomotiveListObject } = this.props;
    const { startDate } = activeUserLocoId && userLocomotiveListObject[activeUserLocoId];
    const { endDate } = activeUserLocoId && userLocomotiveListObject[activeUserLocoId];

    if (endDate && moment(date).subtract(1, 'day').isAfter(endDate)) {
      return true;
    }
    if (startDate && date.isBefore(startDate)) {
      return true;
    }

    return date.isAfter(moment().subtract(1, 'seconds'));
  }

  render() {
    const { isLoading, activeGraph } = this.state;
    const { classes, t, open } = this.props;

    return (
      <Drawer
        className={classNames(classes.drawer, this.props.isZoomEighty ? classes.zoomed : '')}
        classes={{
          paper: classNames({ [classes.drawerOpen]: open, [classes.drawerClose]: !open }),
        }}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={() => this.props.handleDrawClose()}
      >
        <div className="fvm-report-upper-controls">
          <div className={classNames(classes.flexSpacebetween)}>
            <h1>{t('drawer.title.graphing')}</h1>
            <IconButton
              color="inherit"
              aria-label="Open Close"
              onClick={() => this.props.handleDrawClose()}
              children={<Close/>}
            />

          </div>
          <div className={classNames(classes.formGroup)}>
            <List
              key="DateFissseld"
            >
              <FvmGAItemTracker
                category={'Graphing Drawer'}
                label={`Calendar`}
                action={`Date field selected`}
              >
                <ListItem
                  key="DateField"
                  // disableGutters
                  className={classNames(classes.upperControlsCheckbox)}
                >
                  <FormControlLabel
                    // label={t('labels.calendar')}
                    className="fvm-date-picker-listelm"
                    labelPlacement="start"
                    control={
                      <DateRangePicker
                        renderCalendarInfo={() => <div className="fvm-date-picker-controls">
                          <button
                            onClick={() => {
                              document.querySelector('.fvm-report-upper-controls .DateRangePickerInput_clearDates').click();
                            }}
                          >
                            {t('button.delete')}
                          </button>
                          <button onClick={this.saveDate}>{t('button.apply')}</button>
                        </div>}
                        keepOpenOnDateSelect
                        isOutsideRange={() => false}
                        readOnly
                        showClearDates
                        appendToBody
                        reopenPickerOnClearDates
                        displayFormat="DD.MM.YYYY"
                        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                        onDatesChange={this.handleDateChange}
                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                        onClose={this.saveDate}
                        firstDayOfWeek={1}
                        hideKeyboardShortcutsPanel
                        block
                        small
                        noBorder
                        minimumNights={0}
                        startDatePlaceholderText={t('placeHolders.startDate')}
                        endDatePlaceholderText={t('placeHolders.endDate')}
                        isDayBlocked={this.isDateBlocked}
                      />
                    }
                  />
                </ListItem>
              </FvmGAItemTracker>
            </List>
            <div>

              <span>
                <FvmGAItemTracker
                  category={'Graphing Drawer'}
                  label={'Cumulative Graph'}
                  action={'View'}
                >
                  <BootstrapTooltip
                    title={t('tooltip.accumulative')}
                    onClick={() => activeGraph !== 'xyChartAccum' && this.changeChart('xyChartAccum')}
                  >
                       <IconButton
                         color="inherit"
                         aria-label="Open drawer"
                         label={'Railation'}
                         children={
                           <Accumulative
                             color={activeGraph === 'xyChartAccum' ? 'primary' : ''}
                           />
                         }
                       />
                  </BootstrapTooltip>
                </FvmGAItemTracker>

              </span>
              <span>
                <FvmGAItemTracker
                  category={'Graphing Drawer'}
                  label={'XY Graph'}
                  action={'View'}
                  onClick={() => activeGraph !== 'xyChart' && this.changeChart('xyChart')}
                >
                  <BootstrapTooltip
                    title={t('tooltip.xy')}
                  >

                      <IconButton

                        color="inherit"
                        aria-label="Open drawer"
                        label={'Railation'}
                        children={
                          <BarChart
                            color={activeGraph === 'xyChart' ? 'primary' : 'none'}
                          />
                        }
                      />
                  </BootstrapTooltip>
                </FvmGAItemTracker>

              </span>
              <span>
                <FvmGAItemTracker
                  category={'Graphing Drawer'}
                  label={'Pie Chart'}
                  action={'View'}
                  onClick={() => activeGraph !== 'pieChart' && this.changeChart('pieChart')}
                >
                  <BootstrapTooltip
                    title={t('tooltip.pie')}
                  >
                    <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                      label={'Railation'}
                      children={
                        <PieChart
                          color={activeGraph === 'pieChart' ? 'primary' : ''}
                        />
                      }
                    />
                 </BootstrapTooltip>
                </FvmGAItemTracker>

              </span>
            </div>
          </div>
        </div>
        <div
          id="fvm-graph-elm"
          className={classNames(classes.graphContainer)}
        >
        </div>
        {isLoading ? <LoadingSpinner classNames={classNames(classes.drawLoader)}/> : null}
      </Drawer>
    );
  }
}

ReportGraphing.propTypes = {};

export default withStyles(styles, { withTheme: true })(ReportGraphing);

