import { withStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Drawer from '@material-ui/core/Drawer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import Input from '@material-ui/core/Input';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Close from '@material-ui/icons/Close';
// import AdmZip from 'adm-zip';
import classNames from 'classnames';
// import { saveAs } from 'file-saver';

// import json2csv from 'json2csv';
import moment from 'moment';
import React, { Component } from 'react';
// import ReactExport from 'react-data-export';
import { DateRangePicker } from 'react-dates';
import ReactTooltip from 'react-tooltip';
import { trackButtonClick } from '../../containers/GATracker';

import { getCurrentView } from '../../StatelessFunctions/nummericManapulation';
import { fetchSTECDetailedReport } from '../../wp-rest-api/STEC-Communication';
import FvmGAItemTracker from '../FvmGAItemTracker';

import LoadingSpinner from '../LoadingSpinner';
import {
  CSVExporter,
  ExcelExporter,
  filterExportDataByMetric,
  filterExportDataByID,
} from '../../utils/FVMExport';

// const { ExcelFile } = ReactExport;

const drawerWidth = '80%';
const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    padding: '20px',
    backgroundColor: '#F8F8F8',
    // maxWidth: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6),
  },
  content: {
    flexGrow: 1,
  },
  listItemText: {
    fontWeight: '700',
    fontSize: theme.typography.fontSize,
  },
  inline: {
    display: 'inline',
    maxWidth: '20%',
  },
  outerGrid: {
    maxHeight: 'calc(100vh - 160px - 81px)',
    backgroundColor: '#FFFFFF',
  },
  gridItems: {
    borderRight: '1px solid #DFE3E6',
  },
  formGroup: {
    display: 'inline-flex',
    flexDirection: 'row',
  },

  root: {
    whiteSpace: 'normal',
    ...theme.typography,
  },
  exportButtons: {
    backgroundColor: '#FFFFFF',
  },
  sheetTypeRadio: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      marginLeft: '18px',
    },
    display: 'flex',
    flexDirection: 'row',
    // flexWrap: 'nowrap',
    marginLeft: '18px',
    label: {
      marginBottom: '0',
    },
  },
  radioButtonLabel: {
    marginBottom: '0',
  },

  sheetType: {
    // display: 'flex',
  },
  sheetTypeLabel: {
    alignItems: 'center',
    display: 'flex',
    padding: '24px 0',
  },
  upperControlsCheckbox: {
    paddingLeft: '0',
    minWidth: '275px',
  },
  upperControlsInput: {
    '&:after': {
      borderBottom: 'none',
    },
    '&:before': {
      borderBottom: 'none',
    },
  },
  searchFieldContaier: {
    // margin: theme.spacing(6),
    // paddingLeft: '15px !important',
    paddingLeft: 5,
    backgroundColor: '#FFFFFF !important',
    border: '1px solid #DFE3E6 !important',
    // boxShadow: 'unset !important',
    // margin: 0,
    fontSize: '14px !important',
    underline: {
      '&:before': {
        display: 'none',
      },
    },
  },
  flexSpacebetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawLoader: {
    margin: '0 -20px',
  },
  zoomed: {
    zoom: '80%',
  },
  exportInfoIcon: {
    fontSize: 14,
    marginLeft: 5,
  },
});

class ReportExportDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      isExportOption: false,
      checked: props.activeUserLocoId ? [props.activeUserLocoId] : [],
      exportData: {},
      outputFormat: props.outputFormat,
      focusedInput: null,
      triggerExcelDownload: false,
      selectAll: false,
      ...props.period,
      locoList: Object.values(props.userLocomotiveListObject),
      filterTerm: '',
      workBook: [],
      workBookAccum: [],
      selectExportOptions: {
        standard: true,
        accum: false,
      },
      outputFileType: 'exportFormatExcel',
    };

    this.handleClearAll = this.handleClearAll.bind(this);
    this.fetchReportExportData = this.fetchReportExportData.bind(this);
    this.onSheetOutputChange = this.onSheetOutputChange.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.saveDate = this.saveDate.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.renderExportOptions = this.renderExportOptions.bind(this);
    this.onOutputFileTypeChange = this.onOutputFileTypeChange.bind(this);
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { open, activeUserLocoId, userLocomotiveListObject, period } = this.props;
    const { locoList, filterTerm } = this.state;
    const { startDate, endDate } = prevProps.period;

    if (open !== this.state.open) {
      this.setState({ open });
    }
    if (activeUserLocoId !== prevProps.activeUserLocoId) {
      this.setState({ checked: [activeUserLocoId] });
    }
    if (!filterTerm && (Object.values(userLocomotiveListObject).length !== locoList.length)) {
      this.setState({ locoList: Object.values(userLocomotiveListObject) });
    }

    if (startDate && endDate && (period.startDate.format('YYYYMMDD') !== startDate.format('YYYYMMDD') ||
      period.endDate.format('YYYYMMDD') !== endDate.format('YYYYMMDD'))) {
      this.setState({ ...period });
      this.handleDateChange(period);
    }
  }

  handleClearAll() {
    this.props.handleClearAll(false);
  }

  handleToggle(value) {
    const { checked, locoList } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
      selectAll: newChecked.length === locoList.length,
      exportData: {},
    });
  }

  saveDate() {
    this.setState({ focusedInput: null });
  }

  async fetchReportExportData() {
    const { isDemoUser, userSessionID, activeUserLocoId } = this.props;
    const { checked, outputFormat, startDate, endDate, exportData } = this.state;
    const dataFields = ['reportExportRequest', 'StandingWithEngineOn', 'DrivingHours'];
    const currentView = getCurrentView();
    // const groupByType = 'by-id';
    const groupByType = outputFormat;

    if (exportData && Object.keys(exportData).length) {
      return { ...exportData };
    }

    this.setState({ isLoading: true });

    const resp = await fetchSTECDetailedReport({
      vehicleIds: checked.join(','),
      skip: 0,
      top: Math.ceil(checked.length / 10) *10,
      // orderBy: 'desc',
      // orderBy: 'desc',
      // isDemoUser,
      // userSessionID,
      startDate,
      endDate,
      dataFields,
      groupByType,
    });


    if (activeUserLocoId === this.props.activeUserLocoId) {
      // this.setState({
      //   exportData: this.processLimitedUser(resp, outputFormat),
      // });

      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 500);

      return resp;
    }

    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 500);
    return false;
  }

  forceValToZero(locoStartDate, locoStartEndDate, reportDate) {
    return (locoStartDate && reportDate.isBefore(locoStartDate)) || (locoStartEndDate && reportDate.isAfter(locoStartEndDate));
  }

  onSheetOutputChange(event) {
    this.setState({
      outputFormat: event.target.value,
      exportData: {},
    });
  }

  onOutputFileTypeChange(event) {
    this.setState({
      outputFileType: event.target.value,
      // exportData: {},
    });
  }

  handleDateChange({ startDate, endDate }) {
    this.setState({ startDate, endDate, exportData: {} });
  }

  onFilterChange(event) {
    const filterTerm = event.target.value;
    const re = new RegExp(filterTerm, 'i');
    this.setState({
      locoList: filterTerm.trim() ? Object.values(this.props.userLocomotiveListObject)
                                          .filter(loco => re.exec(loco.vehicle.name) && true)
        : Object.values(this.props.userLocomotiveListObject),
      filterTerm,
    });
  }

  handleSelectAll(event) {
    const { locoList } = this.state;
    const selectAll = event.target.checked;
    const checked = selectAll ? locoList.filter(loco => !loco.isCrane).map(loco => loco.vehicleId) : [];

    this.setState({
      checked,
      selectAll,
      exportData: {},
    });
  }

  processLimitedUser(exportData, outputFormat) {

    if (outputFormat === 'by-id') {
      return filterExportDataByID(exportData, this.props.userLocomotiveListObject);
    }

    if (outputFormat === 'by-metric') {
      return filterExportDataByMetric(exportData, this.props.userLocomotiveListObject);
    }

    return {};
  }

  handleChange(key) {
    const { selectExportOptions } = this.state;
    selectExportOptions[key] = !selectExportOptions[key];
    this.setState({ selectExportOptions });
  }

  renderExportOptions() {
    const { selectExportOptions, outputFormat, outputFileType } = this.state;
    const { classes, t } = this.props;
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open
      >
        <DialogTitle id="confirmation-dialog-title">{t('labels.exportOptions')}</DialogTitle>
        <DialogContent dividers>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t('labels.dataOfInterest')}</FormLabel>
            <FormGroup className={classNames(classes.sheetTypeRadio, classes.root)}>
              {
                Object.keys(selectExportOptions).map(expType => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => this.handleChange(expType)}
                          value={`${expType}`}
                          checked={selectExportOptions[expType]}
                          color="primary"
                        />}
                      label={t(`labels.${expType}`)}
                    />
                  );
                })
              }
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t('labels.dataStructure')}</FormLabel>
            <RadioGroup
              className={classNames('fvm-radio-button-group', classes.sheetTypeRadio, classes.root)}
              aria-label="Sheets-By"
              name="sheetType"
              value={outputFormat}
              onChange={this.onSheetOutputChange}
            >
              <FormControlLabel
                className={classNames(classes.radioButtonLabel, classes.root)}
                value="by-id"
                control={<Radio color="primary"/>}
                // label={t('button.exportFormatMetrics')}
                label={
                  <span>
                    {t('button.exportFormatMetrics')}
                    <i
                      className=""
                      data-tip={`<div> <img src="/assets/images/export-by-metric.png"></div>`}
                      data-html
                    >
                    <InfoOutlinedIcon className={classNames(classes.exportInfoIcon)}/>
                  </i>
                  </span>}
              >
              </FormControlLabel>
              <FormControlLabel
                className={classNames(classes.radioButtonLabel, classes.root)}
                value="by-metric"
                control={<Radio color="primary"/>}
                label={
                  <span>
                    {t('button.exportFormatLocos')}
                    <i
                      className=""
                      data-tip={`<div> <img src="/assets/images/export-by-train.png"></div>`}
                      data-html
                    >
                    <InfoOutlinedIcon className={classNames(classes.exportInfoIcon)}/>
                  </i>
                  </span>}
              />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">

            <FormLabel component="legend">{t('labels.exportFileType')}</FormLabel>
            <RadioGroup
              className={classNames('fvm-radio-button-group', classes.sheetTypeRadio, classes.root)}
              aria-label="Sheets-By"
              name="outputFileType"
              value={outputFileType}
              onChange={this.onOutputFileTypeChange}
            >
              <FormControlLabel
                className={classNames(classes.radioButtonLabel, classes.root)}
                value="exportFormatExcel"
                control={<Radio color="primary"/>}
                // label={'Excel Option Selected'}
                label={t('labels.excelOptionSelected')}
              />
              <FormControlLabel
                className={classNames(classes.radioButtonLabel, classes.root)}
                value="exportFormatCsv"
                // label={'CSV Option Selected'}
                label={t('labels.csvOptionSelected')}
                control={<Radio color="primary"/>}
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <FvmGAItemTracker
            category={'Export'}
            label={'Cancel'}
            action={'Cancel Exporting'}
          >
            <Button
              onClick={() => {
                // trackButtonClick('Export', 'Cancel', 'cancel');
                this.setState({ isExportOption: false });
              }}
              color="primary"
            >
              {t('button.cancel')}
            </Button>
          </FvmGAItemTracker>
          <FvmGAItemTracker
            category={'Export'}
            label={`Exported ${Object.keys(selectExportOptions).filter(expType => selectExportOptions[expType]).join(',')} | ${outputFormat} | ${outputFileType}`}
            action={'downloading'}
          >
            <Button
              onClick={async () => {
                this.setState({ isExportOption: false }); // closing modal
                const fetchedData = await this.fetchReportExportData();
                const { userDateFormat, userLocomotiveListObject } = this.props;
                const { outputFormat } = this.state;
                if (outputFileType === 'exportFormatExcel' && fetchedData) {
                  Object.keys(selectExportOptions).forEach(opt => {
                    if (selectExportOptions[opt]) {
                      ExcelExporter({
                        fetchedData,
                        userLocomotiveListObject,
                        t,
                        userDateFormat,
                        isStandardExport: opt === 'standard' && selectExportOptions.standard,
                        groupById: outputFormat === 'by-id',
                      });
                    }

                  });
                }

                if (outputFileType === 'exportFormatCsv' && fetchedData) {
                  CSVExporter({
                    fetchedData,
                    selectExportOptions,
                    userLocomotiveListObject,
                    t,
                    userDateFormat,
                    groupById: outputFormat === 'by-id',
                    outputFormat,
                  });
                }
              }}
              color="primary"
            >
              Ok
            </Button>
          </FvmGAItemTracker>
        </DialogActions>
        <ReactTooltip className="fvm-tooltip fvm-tooltip-img" type="light"/>
      </Dialog>
    );
  }

  render() {
    const {
      checked,
      locoList,
      selectAll,
      isExportOption,
      isLoading,
    } = this.state;
    const { classes, t, open } = this.props;
    const gridSize = {
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      xl: 3,
    };
    // const { userLocomotiveListObject } = this.props;

    return (
      <Drawer
        className={classNames(classes.drawer, this.props.isZoomEighty ? classes.zoomed : '')}
        classes={{
          paper: classNames({ [classes.drawerOpen]: open, [classes.drawerClose]: !open }),
        }}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={() => this.props.handleDrawClose()}
      >
        <div className="fvm-report-upper-controls">
          <div className={classNames(classes.flexSpacebetween)}>
            <h1>{t('labels.exportAssistant')}</h1>
            <IconButton
              color="inherit"
              aria-label="Open Close"
              onClick={() => this.props.handleDrawClose()}
              children={<Close/>}
            />

          </div>
          <div className={classNames(classes.formGroup)}>
            <ListItem
              key="selectAllCheckbox"
              className={classNames(classes.upperControlsCheckbox)}
            >
              <FormControlLabel
                control={
                  <FvmGAItemTracker
                    category={'Export'}
                    label={'Export Drawer Select All'}
                    action={'Toggle'}
                  >
                    <Checkbox
                      checked={selectAll}
                      onChange={this.handleSelectAll}
                      indeterminate={!selectAll && !!checked.length}
                      value="selectAll"
                      color="primary"
                    />
                  </FvmGAItemTracker>
                }
                label={`${t('button.selectAll')} ${checked.length ? `(${checked.length} ${t('singleWords.selected')})` : ''}`}
              />
            </ListItem>
            <ListItem
              key="SearchField"
            >
              <FormControlLabel
                control={
                  <Input
                    type="text"
                    label={this.props.t('placeHolders.filterInput')}
                    value={this.state.filterTerm}
                    onChange={this.onFilterChange}
                    classes={{ underline: classes.searchFieldContaier.underline }}
                    className={classNames('reportFilteriInput', classes.searchFieldContaier)}
                    placeholder={this.props.t('placeHolders.filterInput')}
                    // margin="normal"
                    variant="outlined"
                  />}
              />
            </ListItem>
            <ListItem
              key="DateField"
            >
              <FvmGAItemTracker
                category={'Export'}
                label={`Export Draw Calendar`}
                action={`Date field selected`}
              >
                <FormControlLabel
                  className={classNames('fvm-date-picker-listelm')}
                  labelPlacement="start"
                  control={
                    <DateRangePicker
                      renderCalendarInfo={() => <div className="fvm-date-picker-controls">
                        <button
                          onClick={() => {
                            document.querySelector('.fvm-report-upper-controls .DateRangePickerInput_clearDates').click();
                          }}
                        >
                          {t('button.delete')}
                        </button>
                        <button onClick={this.saveDate}>{t('button.apply')}</button>
                      </div>}
                      keepOpenOnDateSelect
                      isOutsideRange={() => false}
                      readOnly
                      showClearDates
                      appendToBody
                      reopenPickerOnClearDates
                      displayFormat="DD.MM.YYYY"
                      startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                      startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                      endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                      endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                      onDatesChange={this.handleDateChange}
                      focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                      onClose={this.saveDate}
                      firstDayOfWeek={1}
                      hideKeyboardShortcutsPanel
                      block
                      small
                      noBorder
                      minimumNights={0}
                      startDatePlaceholderText={t('placeHolders.startDate')}
                      endDatePlaceholderText={t('placeHolders.endDate')}
                      isDayBlocked={date => (date.isAfter(moment().add(1, 'days').startOf('day').subtract(1, 'seconds'))
                      )}
                    />
                  }
                />
              </FvmGAItemTracker>
            </ListItem>

          </div>


        </div>
        <div className="fvm-report-loco-list-container">
          <Grid
            className={classNames(classes.outerGrid)}
            container
            direction="row"
            spacing={1}
          >
            {
              locoList.filter(loco => !loco.isCrane).map(({ vehicleId, vehicle }) =>
                <Grid
                  key={`${vehicle.name}-grid-item`}

                  item
                  className={classNames(classes.gridItems)}
                  {...gridSize}
                >
                  <ListItem
                    key={vehicle.name}
                    onClick={() => this.handleToggle(vehicleId)}
                    button
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={`Avatar ${vehicle.name}`}
                        src={vehicle.image}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primaryTypographyProps={{ className: classNames(classes.root) }}
                      primary={vehicle.name}
                    />
                    <Checkbox
                      onChange={() => {
                      }}
                      checked={checked.indexOf(vehicleId) !== -1}
                      tabIndex={-1}
                      color="primary"
                    />
                  </ListItem>
                </Grid>)
            }

          </Grid>
        </div>
        <div className="fvm-report-lower-controls">
          <span></span>
          <div className="fvm-report-export-buttons">
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              className={classNames('fvm-save-button', classes.exportButtons)}
              disabled={!checked.length}
              onClick={() => {
                this.setState({ isExportOption: true });
                trackButtonClick('Export', 'Export Options', 'Open');
              }}
            >
              {t('button.export')}
            </Button>
            {/*<Button*/}
            {/*  variant="outlined"*/}
            {/*  color="primary"*/}
            {/*  size="medium"*/}
            {/*  onClick={async () => {*/}
            {/*    this.setState({ isExportOption: false }); // closing modal*/}
            {/*    const fetchedData = await this.fetchReportExportData();*/}
            {/*    const { userDateFormat } = this.props;*/}
            {/*    const {  outputFormat } = this.state;*/}

            {/*    if (fetchedData) {*/}
            {/*      ExcelExporter({ fetchedData, userLocomotiveListObject, t, userDateFormat, isStandardExport: true, groupById: outputFormat === 'vehicleId'});*/}
            {/*    }*/}
            {/*  }}*/}
            {/*>*/}
            {/*  FVM Export Tester*/}
            {/*</Button>*/}

            {/*{*/}
            {/*  workBook.length && triggerExcelDownload*/}
            {/*    ? <ExcelFile*/}
            {/*      filename={`${moment().format('DD-MM-YYYY')}-NRail-Export`}*/}
            {/*      hideElement*/}
            {/*    >*/}
            {/*      {workBook}*/}
            {/*    </ExcelFile>*/}
            {/*    : null*/}
            {/*}*/}
            {/*{*/}
            {/*  workBookAccum.length && triggerExcelDownload*/}
            {/*    ? <ExcelFile*/}
            {/*      filename={`${moment().format('DD-MM-YYYY')}-NRail-Accumulated-Export`}*/}
            {/*      hideElement*/}
            {/*    >*/}
            {/*      {workBookAccum}*/}
            {/*    </ExcelFile>*/}
            {/*    : null*/}
            {/*}*/}
          </div>
        </div>

        {isExportOption && this.renderExportOptions()}
        {isLoading ? <LoadingSpinner classNames={classNames(classes.drawLoader)}/> : null}

      </Drawer>
    );
  }
}

ReportExportDrawer.propTypes = {};

export default withStyles(styles)(ReportExportDrawer);
