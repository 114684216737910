import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Box } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useSelector } from 'react-redux';

import { selectUser } from '../../reducers/accounts/user';

const useStyles = makeStyles(theme => ({
  accessDeniedContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&> div': {
      width: '40%',
    },
  },
  alertContainer: {
    background: 'transparent',
    borderRadius: 4,
    color: 'rgb(97, 26, 21)',
    border: '1px solid #f44336',
    padding: '16px 8px',
    display: 'flex',
  },
  errorIcon: {},
  contentContainer: {
    padding: '0px 12px',
    height: '80%',
    '&> p': {
      padding: 2,
    },
  },
}));

const AccessDenied = props => {
  const classes = useStyles();
  const { t } = props;
  const { accessRights } = useSelector(selectUser).user;
  return (
    <div className={classes.accessDeniedContainer}>
      {Object.keys(accessRights).length ? (
        <Paper className={classes.alertContainer}>
          <ErrorOutlineIcon color="error" className={classes.errorIcon} />
          <Box className={classes.contentContainer} component="div">
            <Typography component="p">
              {t('labels.logbookPermissionDenied')}
            </Typography>
          </Box>
        </Paper>
      ) : null}
    </div>
  );
};

export default AccessDenied;
