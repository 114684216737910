import React from 'react';
const ServiceCenter = () => {

  const a = {
    fill: '#002fc9',
  };
  const b = {
    opacity: 0,
  };
  const c = {
    fill: '#fff',
    fillRule: 'evenodd',
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.999" height="33.999" viewBox="0 0 33.999 33.999">
      <g transform="translate(-726 -736)">
        <g transform="translate(635 653.999)">
          <rect style={a} width="33.999" height="33.999" rx="5" transform="translate(91 82.001)" />
        </g>
        <g transform="translate(520 104)">
          <rect style={{ ...a, ...b}} width="24" height="24" transform="translate(211 637)" />
          <path
            style={c}
            d="M-90.829,410.345l2.669,2.669a.8.8,0,0,1,0,1.131l-1.7,1.7a.8.8,0,0,1-1.13,0l-2.669-2.67a4.99,4.99,0,0,0,1.114,5.353,4.971,4.971,0,0,0,4.224,1.4l9.2,9.2a3,3,0,0,0,4.239,0,3,3,0,0,0,0-4.238l-9.2-9.2a4.973,4.973,0,0,0-1.4-4.223A4.993,4.993,0,0,0-90.829,410.345Zm15.068,17.894a1.748,1.748,0,0,1-2.472,0,1.747,1.747,0,0,1,0-2.472,1.747,1.747,0,0,1,2.472,0A1.747,1.747,0,0,1-75.761,428.239Z"
            transform="translate(307 229)"
          />
        </g>
      </g>
    </svg>
  );
};
export default ServiceCenter;
