import React from 'react';
const Education = () => {
  const a = {
    fill: '#002fc9',
  }
  const b = {
    fill: '#002fc9',
    opacity: 0,
  }
  const c = {
    fill: '#fff',
    stroke: 'rgba(0, 0, 0, 0)',
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.999" height="33.999" viewBox="0 0 33.999 33.999">
      {/*<style>.a,.b{fill:#002fc9;}.b{opacity:0;}.c{fill:#fff;stroke:rgba(0,0,0,0);}</style>*/}
      <g transform="translate(-726 -352)">
        <g transform="translate(635 269.999)">
          <rect style={a} width="33.999" height="33.999" rx="5" transform="translate(91 82.001)" />
        </g>
        <g transform="translate(635 270)">
          <rect style={{ ...a, ...b }} width="24" height="24" transform="translate(96 87)" />
          <path
            style={c}
            d="M-1811.984,712.938l1-3v-8.386l-9,3.886-11-4.749,11-4.75,11,4.75-1,.431v8.818l1,3Zm-15-4v-5l7,2.891,7-2.891.062,5a16.823,16.823,0,0,1-7.062,2A16.391,16.391,0,0,1-1826.985,708.937Z"
            transform="translate(1927.969 -605.875)"
          />
        </g>
      </g>
    </svg>
  );
};
export default Education;
