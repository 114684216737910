import Drawer from '@material-ui/core/Drawer';
import LocoListIcon from '@material-ui/icons/DirectionsSubwayOutlined';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch/Switch';
import LogBookIcon from '@material-ui/icons/AssignmentOutlined';
// import LogBookDraftIcon from '@material-ui/icons/Create';
import LogBookDraftIcon from '../../assets/svgs/FilterSubCategoies/logbook-open-entries';
// import LogBookDraftIcon from '@material-ui/icons/DraftsOutlined';
// import LogBookDraftIcon from '@material-ui/icons/Notes';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/DashboardSharp';
import DatasheetIcon from '@material-ui/icons/DescriptionOutlined';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturnOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';

import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';

import ProfileIcon from '@material-ui/icons/PermIdentityOutlined';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswerOutlined';
import LiveDataIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ReportingIcon from '@material-ui/icons/ShowChartOutlined';
import TrackingIcon from '@material-ui/icons/TrackChangesSharp';
import UserAdmin from '@material-ui/icons/People';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextFormatOutlinedIcon from '@material-ui/icons/TextFormatOutlined';

import classNames from 'classnames';
import React, { Component, Suspense } from 'react';
import connect from 'react-redux/es/connect/connect';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  changedView,
  clickedFvmFeatureSwitch,
  clickedNavigationBarMenuButton, setIsLocoControlHidden,
} from '../../actions';
import { getCurrentView } from '../../StatelessFunctions/nummericManapulation';
import FvmGAItemTracker from '../FvmGAItemTracker';
import LoadingSpinner from '../LoadingSpinner';
import styles from './styles';

import { Button } from '@material-ui/core';

const allPages = (t, accessRights) => {
  console.log(accessRights?.['gateway/telematics']?.canRead);
  return [
    {
      name: t('navigationMenu.dashboard'),
      path: 'dashboard',
      icon: DashboardIcon,
      viewIsAllowed: accessRights?.['gateway/telematics']?.canRead,
    },
    {
      name: t('navigationMenu.liveData'),
      path: 'livedata',
      icon: LiveDataIcon,
      viewIsAllowed: accessRights?.['gateway/telematics']?.canRead,
    },
    {
      name: t('navigationMenu.reporting'),
      path: 'reporting',
      icon: ReportingIcon,
      viewIsAllowed: accessRights?.['gateway/telematics']?.canRead,
    },
    {
      name: t('navigationMenu.trackingmap'),
      path: 'trackingmap',
      icon: TrackingIcon,
      viewIsAllowed: accessRights?.['gateway/telematics']?.canRead,
    },
    {
      name: t('navigationMenu.notifications'),
      path: 'notification',
      icon: NotificationsIcon,
      viewIsAllowed: accessRights?.['gateway/telematics']?.canRead,
    },
    {
      name: t('navigationMenu.logbook'),
      path: 'logbook-all-entries',
      icon: LogBookIcon,
      viewIsAllowed: accessRights.logbook_entries?.canRead,
    },
    {
      name: t('navigationMenu.logbookDraftEntries'),
      path: 'logbook-draft-entries',
      subEntry: false,
      icon: LogBookDraftIcon,
      viewIsAllowed: accessRights.logbook_entries?.canRead,
    },
    {
      name: t('navigationMenu.datasheet'),
      path: 'datasheet',
      icon: DatasheetIcon,
      hasDivider: true,
      viewIsAllowed: accessRights?.['gateway/telematics']?.canRead,
    },
    // {
    //   name: t('navigationMenu.faq'),
    //   path: 'faq',
    //   icon: QuestionAnswerIcon,
    //   viewIsAllowed: true,
    // },
    {
      name: t('navigationMenu.useAdministration'),
      path: 'useradministration',
      icon: UserAdmin,
      hasDivider: true,

      viewIsAllowed: false,
    },
  ];
};
const getSettingMenuItems = t => {
  return [
    {
      name: t('navigationMenu.back'),
      path: 'dashboard',
      icon: KeyboardReturnIcon,
      viewIsAllowed: true,
    },
    {
      name: t('navigationMenu.profile'),
      path: 'profile',
      icon: ProfileIcon,
      viewIsAllowed: true,
    },
    // {
    //   name: t('navigationMenu.notificationSettings'),
    //   path: 'notificationsettings',
    //   icon: SettingsIcon,
    //   viewIsAllowed: true,
    // },
  ];
};

class NavigationSidebar extends Component {
  constructor(props) {
    super(props);
    const ZOOM_ADJUSTED = window.localStorage.getItem('zoomActive');

    this.state = {
      selectedFontSize: ZOOM_ADJUSTED ? Number(ZOOM_ADJUSTED) : 1,
      myUserRole: '',
      currentView: props.currentView,
      isDevDatabase: localStorage.getItem('isTestProductionApis'),
      isLogbookTest: localStorage.getItem('isLogbookTest'),
      zoomActive: localStorage.getItem('zoomActive'),
      isFvmFeature: localStorage.getItem('fvmfeatureactive'),
      open: props.isNavigationBarExpanded,
    };

    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.setUpView = this.setUpView.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    // this.setUpView();
    if (this.state.isFvmFeature) {
      this.props.clickedFvmFeatureSwitch(true);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { currentView, languageFilesReloaded } = this.props;
    if (languageFilesReloaded !== prevProps.languageFilesReloaded) {
      this.setState({ componentLanguageUpdateTrigger: languageFilesReloaded });
    }
    if (currentView !== prevProps.currentView) {
      this.setState({ currentView });
    }
  }


  handleDrawerOpen() {
    this.props.clickedNavigationBarMenuButton(true);
    localStorage.setItem('isNavigationBarExpanded', true);
    this.setState({ open: true });
  }

  handleDrawerClose() {
    this.props.clickedNavigationBarMenuButton(false);
    // localStorage.setItem('isNavigationBarExpanded', false);
    localStorage.removeItem('isNavigationBarExpanded');
    this.setState({ open: false });
  }

  async setUpView() {
    const { userIsAllowed, myUserRole } = {};
    // const { userIsAllowed, myUserRole } = await isUserPermittedToSeePage();
    this.state.userCanViewPage = userIsAllowed;
    if (this.state.userCanViewPage) {
      this.setState({ userCanViewPage: userIsAllowed });
      this.setState({ myUserRole });
    }
    window.reload();
  }

  onDevItemClicked = LSKey => {
    this.props.clickedFvmFeatureSwitch(!this.props.isFvmFeaturesActive);
    if (!localStorage.getItem(LSKey)) {
      localStorage.setItem(LSKey, true);
    } else {
      localStorage.removeItem(LSKey);
    }

    window.location.reload();
  };

  handleChange(event) {
    const selectedFontSize = event.target.value;
    this.setState({ selectedFontSize });
    localStorage.setItem('zoomActive', `${selectedFontSize}`);
    const newUrl = `${window.location}`;
    window.location = newUrl;
  }

  render() {
    const specialViews = ['profile', 'notificationsettings'];
    const { open, myUserRole, selectedFontSize, currentView } = this.state;
    const settingMenu = specialViews.includes(currentView);
    const { isDevelopment, isDev, classes, t, hasOpenLogbookEntries } = this.props;
    const menu = settingMenu
      ? getSettingMenuItems(t)
      : allPages(t, this.props.user.accessRights)
        .filter(({ path }) => path !== 'logbook-draft-entries' || (path === 'logbook-draft-entries' && hasOpenLogbookEntries));

    return (
      <Suspense fallback={<LoadingSpinner />}>
        <Drawer
          variant="permanent"
          className={classNames(
            this.props.className,
            classes.drawer,
            classes.fvmMainNavigation,
          )}
          classes={{
            paper: classNames(classes.fvmMainNavigationFistDiv, {
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
              [classes.paperAnchorLeft]: true,
            }),
          }}
          open={open}
        >
          <div
            className={classNames(
              classes.fvmDrawerControls,
              open
                ? classes.fvmDrawerControlsOpenedButton
                : classes.fvmDrawerControlsClosedButton,
            )}
          >
            <FvmGAItemTracker
              category={'Navigation'}
              label={'Drawer toggler'}
              action={`Toggled`}
              value={open ? 1 : 0}
            >
              {open ? (
                <IconButton onClick={this.handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              ) : (
                <IconButton onClick={this.handleDrawerOpen}>
                  <MenuIcon />
                </IconButton>
              )}
            </FvmGAItemTracker>
          </div>

          <div>
            <nav>
              <List className={classNames(classes.fvmMenuList)}>
              {this.props.isLocoControlHidden ? (
                  <>
                    <Divider />
                    <NavLink
                      to={`#`}
                      key={`nave-my-locos-page`}
                      className={classNames(
                        classes.fvmMenuListAnchor,
                        classes.fvmNavLinkLi,
                      )}
                    >
                    <ListItem
                      button
                      onClick={() => {
                        this.props.setIsLocoControlHidden(!this.props.isLocoControlHidden);
                      }}
                      key="my-locomotives"
                      className={classNames(
                        classes.fvmNavLinkFstDiv
                      )}
                    >
                      <ListItemIcon
                        classes={{
                          root: classes.listItemText,
                        }}
                      >
                      <i
                            className={classNames(
                              classes.fvmNavLinkIcon,
                              !open ? classes.fvmNavLinkIconClosed : '',
                            )}
                          >
                            <LocoListIcon />
                          </i>
                      </ListItemIcon>
                      <ListItemText
                          classes={{
                            primary: classNames(
                              classes.fvmMenuListItemInner,
                              classes.root,
                              classes.listItemText,
                            ),
                          }}
                          className={classNames(classes.fvmMenuListItemOuter)}
                          primary={<span style={{ fontWeight: 'bold' }}>{t('navigationMenu.myLocomotives')}</span>}
                        />
                    </ListItem>
                    </NavLink>
                  </>
                ) : null}
                {[...menu]
                  .filter(
                    ({ path, viewIsAllowed }) =>
                      path !== 'railation' && viewIsAllowed,
                  )
                  .map((page, index) => (
                    <NavLink
                      to={`/${page.path}`}
                      key={`nave-${page.name}-page`}
                      className={classNames(
                        classes.fvmMenuListAnchor,
                        classes.fvmNavLinkLi,
                      )}
                    >
                      <ListItem
                        button
                        key={page.name}
                        className={classNames(
                          !open ? classes.fvmNavLinkFstDiv : '',
                        )}
                      >
                        <ListItemIcon
                          classes={{
                            root: classes.listItemText,
                          }}
                        >
                          {/*{*/}
                          {/*  page.subEntry ? <i*/}
                          {/*  className={classNames(*/}
                          {/*    classes.fvmNavLinkIcon,*/}
                          {/*    currentView === page.path*/}
                          {/*      ? 'fvm-active'*/}
                          {/*      : 'fvm-inactive',*/}
                          {/*    open && page.subEntry ? classes.navMenuSubEntry : '',*/}
                          {/*    !open ? classes.fvmNavLinkIconClosed : '',*/}
                          {/*  )}*/}
                          {/*>*/}
                          {/*  <SubdirectoryArrowRight/>*/}
                          {/*</i> : null}*/}
                          <i
                            className={classNames(
                              classes.fvmNavLinkIcon,
                              currentView === page.path
                                ? 'fvm-active'
                                : 'fvm-inactive',
                              open && page.subEntry ? classes.navMenuSubEntry : '',
                              !open ? classes.fvmNavLinkIconClosed : '',
                            )}
                          >
                            <page.icon />
                          </i>
                        </ListItemIcon>
                        <ListItemText
                          classes={{
                            primary: classNames(
                              classes.fvmMenuListItemInner,
                              classes.root,
                              classes.listItemText,
                            ),
                          }}
                          className={classNames(classes.fvmMenuListItemOuter)}
                          primary={page.name}
                        />
                      </ListItem>
                      {page.hasDivider ? (
                        <Divider className={classNames(classes.divider)} />
                      ) : null}
                    </NavLink>
                  ))}
                {isDevelopment ? (
                  <React.Fragment>
                    <Divider className />

                    {[
                      {
                        label: 'Dev Tools',
                        LSKey: 'fvmDevTools',
                      },
                    ].map(({ label, LSKey }) => (
                      <li
                        key="production-switch"
                        className={classNames(classes.fvmNavLinkLi)}
                      >
                        <ListItem
                          button
                          key="production-switch"
                          className={classNames(
                            !open ? classes.fvmNavLinkFstDiv : '',
                          )}
                        >
                          <FormControlLabel
                            label={label}
                            className={classNames(
                              classes.fvmMenuListItemOuter,
                              classes.listItemText,
                            )}
                            classes={{
                              root: classes.root,
                              label: classNames(
                                classes.root,
                                classes.fvmMenuListItemInner,
                              ),
                            }}
                            control={
                              <Switch
                                onChange={() => this.onDevItemClicked(LSKey)}
                                checked={localStorage.getItem(LSKey)}
                                value={LSKey}
                                size={'small'}
                                color="secondary"
                                className={classNames(
                                  !open ? classes.fvmNavLinkIconClosed : '',
                                  classes.listItemText,
                                )}
                              />
                            }
                          />
                        </ListItem>
                      </li>
                    ))}
                    {localStorage.getItem('fvmDevTools') ? (
                      <li
                        key="zoom-adjuster"
                        className={classNames(
                          classes.fvmNavLinkLi,
                          'mt-2 w-100',
                          classes.zoomLevelContainer,
                        )}
                      >
                        {/*<ListItem button key="fvm-zoom">*/}
                        <RadioGroup
                          className={classNames(
                            classes.fontSizeRadioButton,
                            !open ? 'ml-4' : '',
                          )}
                          value={`${selectedFontSize}`}
                          onChange={this.handleChange}
                          row={!open}
                        >
                          <FormControlLabel
                            value={0.8}
                            className={classes.radioButtonLabel}
                            control={
                              <Radio
                                icon={
                                  <TextFormatOutlinedIcon
                                    color={
                                      selectedFontSize === 0.8
                                        ? 'primary'
                                        : 'disabled'
                                    }
                                    value={0.8}
                                    fontSize="small"
                                  />
                                }
                              />
                            }
                          />
                          <FormControlLabel
                            value={1}
                            className={classes.radioButtonLabel}
                            control={
                              <Radio
                                icon={
                                  <TextFormatOutlinedIcon
                                    color={
                                      selectedFontSize === 1
                                        ? 'primary'
                                        : 'disabled'
                                    }
                                    fontSize="default"
                                  />
                                }
                              />
                            }
                          />
                          <FormControlLabel
                            value={1.2}
                            className={classes.radioButtonLabel}
                            control={
                              <Radio
                                icon={
                                  <TextFormatOutlinedIcon
                                    color={
                                      selectedFontSize === 1.2
                                        ? 'primary'
                                        : 'disabled'
                                    }
                                    fontSize="large"
                                  />
                                }
                              />
                            }
                          />
                        </RadioGroup>
                        {/*</ListItem>*/}
                      </li>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </List>
            </nav>
          </div>

          {isDevelopment && localStorage.getItem('fvmDevTools') ? (
            <div className={classes.reloadButtonContainer}>
              <Button
                onClick={() => {
                  if (caches) {
                    caches.keys().then(function (names) {
                      for (let name of names) caches.delete(name);
                    });
                  }
                  localStorage.removeItem('userSuggestReloadTs');
                  setTimeout(() => window.location.reload(), 700);
                }}
                color="secondary"
                size="small"
                variant="contained"
                aria-label="Hard reload"
              >
                {open ? 'Hard Reload' : 'R'}
              </Button>
            </div>
          ) : null}


        </Drawer>
      </Suspense>
    );
  }
}

const mapStateToProps = state => ({
  currentView: getCurrentView(),
  hasOpenLogbookEntries: state.hasOpenLogbookEntries,
  isFvmFeaturesActive: state.isFvmFeaturesActive,
  isNavigationBarExpanded: state.isNavigationBarExpanded,
  isLocoControlHidden: state.isLocoControlHidden,
  isDevelopment: state.isDevelopment,
  languageFilesReloaded: state.languageFilesReloaded,
  user: state.user.user,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clickedFvmFeatureSwitch,
      clickedNavigationBarMenuButton,
      changedView,
      setIsLocoControlHidden,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(NavigationSidebar));
