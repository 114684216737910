import {
  ACTIVE_CRANE_LIST,
  ACTIVE_LOCOMOTIVE_LIST,
  CLOSE_DETAILED_DRAW,
  CURRENT_VIEW,
  DATA_LOADING,
  DATA_SHEET_STATIC_DATA,
  DATE_CHANGED,
  DEMO_USER,
  FVM_FEATURE,
  FVM_USER_SESSION_ID,
  HEAT_MAP,
  IS_LOC_CONTROL_HIDDEN,
  LOGBOOK_ENTRIES_SYNCED_TIMESTAMP,
  IS_HAVE_CRANES,
  IS_LIVE_SYSTEM,
  IS_RAILATION,
  IS_NEW_RECEIVED_TIMESTAMP,
  IS_REMOVE_FOCUS_ID,
  IS_TWITTER,
  USER_MADE_INITIAL_DEVICE_SELECTION,
  LIST_VIEW,
  LOCALE_CHANGED,
  LOCOMOTIVE_CARD_CLICKED,
  MAP_LOADING,
  NAV_BAR_EXPANDED,
  SEARCH_TERM_CHANGED,
  SELECT_ALL_DEVICES,
  SELECT_ALL_LOCO,
  SELECT_DRIVING,
  SELECT_EQUIPPED,
  SELECT_OFF,
  SELECT_STANDING,
  SHOW_RAILWAY,
  SHOW_SATELLITE,
  SHOW_RAILATION,
  STEC_CONNECT,
  TARGET_LOCOMOTIVE,
  TRACKED_LOCOMOTIVE,
  TOGGLE_ALL_CRANES,
  TRACKING_MAP,
  UPDATE_USER_LOCOMOTIVE_LIST,
  FETCH_ACTIVE_LOCO_LIST_DATA,
  UPDATE_VIEW,
  HAS_INTERNET_CONNECTION,
  IS_HIDE_LOGIN_UNTIL_ONLINE,
  HAS_OPEN_LOGBOOK_ENTRIES,
  NRL_ACCESS_TOKEN,
  IS_USER_LOGGED_IN,
} from '../actionTypes';

import { getCurrentView } from '../StatelessFunctions/nummericManapulation';
import { FVM_BASE_URL } from '../wp-rest-api/STEC-Communication';
import { isOnline } from '../reducers/locomotive-reducer';

const clickedLocomotive = (loco) => {
  return {
    type: LOCOMOTIVE_CARD_CLICKED,
    payload: loco,
  };
};

const newUserSearch = (loco) => {
  return {
    type: SEARCH_TERM_CHANGED,
    payload: loco,
  };
};

const cacheLocoList = (locoObj) => {
  if (typeof locoObj === 'object' && Object.keys(locoObj).length) {
    const tempLocoCacheObj = {};
    const respMap = Object
      .keys(locoObj)
      // .filter(key => key < 80)
      .map((tempId) => {
        const { vehicleId, vehicle } = locoObj[tempId];
        tempLocoCacheObj[tempId] = {
          vehicle,
          vehicleId,
        };
        return { vehicleId, vehicle };
      });

    localStorage.setItem('userLocoList', JSON.stringify(respMap));
  }
};

const updateUserLocomotivesList = (loco, forceOverwrite = false) => {
  setlastDataReceivedTimestamp(new Date().getTime());
  cacheLocoList(loco);
  return {
    type: UPDATE_USER_LOCOMOTIVE_LIST,
    payload: loco,
    forceOverwrite,
  };
};

const updateActiveLocomotiveIds = (locoIdLst) => {
  return {
    type: ACTIVE_LOCOMOTIVE_LIST,
    payload: locoIdLst.filter(id => !!id),
  };
};
const updateActiveCraneIds = (locoIdLst) => {
  return {
    type: ACTIVE_CRANE_LIST,
    payload: locoIdLst.filter(id => !!id),
  };
};
const updateTargetLocomotiveId = (locoIdLst) => {
  return {
    type: TARGET_LOCOMOTIVE,
    payload: locoIdLst,
  };
};
const updateTrackedLocomotiveId = (locoIdLst) => {
  return {
    type: TRACKED_LOCOMOTIVE,
    payload: locoIdLst,
  };
};

const clickedSelectAllLoco = (isSelectAllLoco, applyToGroup = false) => {
  return {
    type: SELECT_ALL_LOCO,
    payload: {
      isSelectAllLoco,
      applyToGroup,
    },
  };
};
const clickedSelectAllDevices = (isSelectAllDevices, applyToGroup = false) => {
  return {
    type: SELECT_ALL_DEVICES,
    payload: {
      isSelectAllDevices,
      applyToGroup,
    },
  };
};
const clickedSelectAllDriving = (isSelectAllLoco) => {
  return {
    type: SELECT_DRIVING,
    payload: isSelectAllLoco,
  };
};
const clickedSelectAllStanding = (isSelectAllLoco) => {
  return {
    type: SELECT_STANDING,
    payload: isSelectAllLoco,
  };
};
const clickedSelectAllEquipped = (isSelectAllLoco) => {
  return {
    type: SELECT_EQUIPPED,
    payload: isSelectAllLoco,
  };
};
const clickedSelectAllOff = (isSelectAllLoco) => {
  return {
    type: SELECT_OFF,
    payload: isSelectAllLoco,
  };
};
const clickedSelectAllCranes = (isSelectAllCranes) => {
  return {
    type: TOGGLE_ALL_CRANES,
    payload: isSelectAllCranes,
  };
};

const singleSelect = (view) => {
  return {
    type: CURRENT_VIEW,
    payload: !['dashboard', 'notification'].includes(view),
  };
};

const changedView = (view = getCurrentView()) => {
  return {
    type: CURRENT_VIEW,
    payload: view,
  };
};

const clickedShowRailway = (isShowRailway) => {
  return {
    type: SHOW_RAILWAY,
    payload: isShowRailway,
  };
};

const clickedHeatMap = (isHeatmap) => {
  return {
    type: HEAT_MAP,
    payload: isHeatmap,
  };
};

const clickedTwitter = (isTwitter) => {
  return {
    type: IS_TWITTER,
    payload: isTwitter,
  };
};

const clickedShowSatellite = (isShowSatallite) => {
  return {
    type: SHOW_SATELLITE,
    payload: isShowSatallite,
  };
};
const clickedShowRailation = (isShowRailation) => {
  return {
    type: SHOW_RAILATION,
    payload: isShowRailation,
  };
};

const clickedTrackingMap = (isTrackingMap) => {
  return {
    type: TRACKING_MAP,
    payload: isTrackingMap,
  };
};

const dateChanged = (newDates) => {
  return {
    type: DATE_CHANGED,
    payload: newDates,
  };
};

const loadingData = (isLoadpingData) => {
  return {
    type: DATA_LOADING,
    payload: isLoadpingData,
  };
};
const mapLoadingData = (isLoadpingData) => {
  return {
    type: MAP_LOADING,
    payload: isLoadpingData,
  };
};

const setDemoUser = (isDemoUser) => {
  return {
    type: DEMO_USER,
    payload: isDemoUser,
  };
};

const locomotiveListLayout = (isListView) => {
  return {
    type: LIST_VIEW,
    payload: isListView,
  };
};
const setDataSheetStaticData = (isLietsView) => {
  return {
    type: DATA_SHEET_STATIC_DATA,
    payload: isLietsView,
  };
};
const clickedFvmFeatureSwitch = (isFvmFeaturesActive) => {
  return {
    type: FVM_FEATURE,
    payload: isFvmFeaturesActive,
  };
};

const stecConnect = (connsctionStatus) => {
  return {
    type: STEC_CONNECT,
    payload: connsctionStatus,
  };
};

const clickedNavigationBarMenuButton = (isNavigationBarexpanded) => {
  return {
    type: NAV_BAR_EXPANDED,
    payload: isNavigationBarexpanded,
  };
};

const setUserSessionId = (newSessionID) => {
  return {
    type: FVM_USER_SESSION_ID,
    payload: newSessionID,
  };
};
const setIsLive = () => {
  return {
    type: IS_LIVE_SYSTEM,
    // payload:  !(!FVM_BASE_URL.match(/fuerstvonmartin/) || !FVM_BASE_URL.match(/dev2/)),
    payload: ['nrail.de', 'nrailone.dev2.fuerstvonmartin.de'].includes(FVM_BASE_URL),
  };
};

const setRemoveFocusId = (id) => {
  return {
    type: IS_REMOVE_FOCUS_ID,
    payload: id,
  };
};

const setIsHaveCranes = (status) => {
  return {
    type: IS_HAVE_CRANES,
    payload: status,
  };
};

const setlastDataReceivedTimestamp = (netTimeStamp) => {
  return {
    type: IS_NEW_RECEIVED_TIMESTAMP,
    payload: netTimeStamp,
  };
};

const clickedRailation = (isRailation) => {
  return {
    type: IS_RAILATION,
    payload: isRailation,
  };
};

const detailedDrawClosed = (status) => {
  return {
    type: CLOSE_DETAILED_DRAW,
    payload: status,
  };
};

const userLanguageChanged = (newLocale) => {
  return {
    type: LOCALE_CHANGED,
    payload: newLocale,
  };
};

const setUserMadeInitialDeviceSelection = (status = false) => {
  return {
    type: USER_MADE_INITIAL_DEVICE_SELECTION,
    payload: status,
  };
};
const setFetchActiveLocoListData = (status = false) => {
  return {
    type: FETCH_ACTIVE_LOCO_LIST_DATA,
    payload: status,
  };
};

const setUpdateView = (status = false) => {
  return {
    type: UPDATE_VIEW,
    payload: status,
  };
};

const setIsOnline = (status = { type: navigator.onLine ? 'online' : 'offline' }) => {
  return {
    type: HAS_INTERNET_CONNECTION,
    payload: status?.type === 'online',
  };
};


const setHasOpenLogbookEntries = (status = !!localStorage.getItem('unsavedLogbookItems')) => {
  return {
    type: HAS_OPEN_LOGBOOK_ENTRIES,
    payload: status,
  };
};

const setLogbookEntriesSyncedTimestamp = (status = Date.now()) => {
  return {
    type: LOGBOOK_ENTRIES_SYNCED_TIMESTAMP,
    payload: status,
  };
};

const setIsLocoControlHidden = (status = false) => {
  return {
    type: IS_LOC_CONTROL_HIDDEN,
    payload: status,
  };
};
const setHideLoginUntilOnline = (status = false) => {
  return {
    type: IS_HIDE_LOGIN_UNTIL_ONLINE,
    payload: status,
  };
};
const setIsLoggedIn = (status = false) => {
  return {
    type: IS_USER_LOGGED_IN,
    payload: status,
  };
};


export {
  changedView,
  setRemoveFocusId,
  clickedLocomotive,
  clickedSelectAllLoco,
  clickedSelectAllDevices,
  clickedSelectAllDriving,
  clickedSelectAllStanding,
  clickedSelectAllEquipped,
  clickedSelectAllOff,
  clickedSelectAllCranes,
  clickedRailation,
  updateUserLocomotivesList,
  clickedHeatMap,
  clickedTwitter,
  clickedShowRailway,
  clickedShowSatellite,
  clickedShowRailation,
  clickedTrackingMap,
  dateChanged,
  loadingData,
  mapLoadingData,
  updateActiveLocomotiveIds,
  updateTargetLocomotiveId,
  updateTrackedLocomotiveId,
  setDemoUser,
  locomotiveListLayout,
  setDataSheetStaticData,
  clickedFvmFeatureSwitch,
  stecConnect,
  clickedNavigationBarMenuButton,
  updateActiveCraneIds,
  userLanguageChanged,
  setUserSessionId,
  setIsLive,
  detailedDrawClosed,
  setIsHaveCranes,
  newUserSearch,
  setlastDataReceivedTimestamp,
  setUserMadeInitialDeviceSelection,
  setFetchActiveLocoListData,
  setUpdateView,
  setIsOnline,
  setHasOpenLogbookEntries,
  setIsLocoControlHidden,
  setHideLoginUntilOnline,
  setLogbookEntriesSyncedTimestamp,
  setIsLoggedIn,
};
