import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useTranslation } from 'react-i18next';
import FVMDialog from '../FVMDialog';
import MarkdownRenderer from 'react-markdown-renderer';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  card: {
    width: 345,
    // border: `solid 1px ${theme.palette.primary.main}`,
    position: 'absolute',
    left: 15,
    bottom: 0,
    zIndex: 3000,
  },
  hideCard:{
    display: 'none',
  },
  media: {
    height: 140,
  },
  cardAction: {
    justifyContent: 'space-between',
  },

  detailsButton: {
    marginLeft: 'auto',
    color: 'blue',
  },
}));

export default function MediaCard({ title, mainText, rejectButton, approveButton, details, imgUrl, releaseNotes, hidden, releaseNotesTitle, isAbsolute = true}) {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleIsDialogClosed = () => {
    if (details && details.callBk && !rejectButton.hidden) {
      details.callBk();
    }
    setIsDialogOpen(false);
  };
  const handleIsDialogOpen = () => {
    setIsDialogOpen(true);
  };

  return (
    <Card className={classNames(isAbsolute ? classes.card : '', hidden ? classes.hideCard : '')}>
      <LinearProgress />
      {
        imgUrl ?
          (
            <CardMedia
              className={classes.media}
              image={imgUrl}
              title="Contemplative Reptile"
            />
          ) : null
      }

      <CardContent>
        {
          title ?
            (
              <Typography gutterBottom variant="h5" component="h2">
                {title}
              </Typography>
            ) : null
        }

        <Typography variant="body2" color="textSecondary" component="p">
          {mainText}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardAction} >
        <span>
          {
            rejectButton ?
              (
                <Button hidden={rejectButton.hidden} size="small" color="primary" onClick={rejectButton.callBk}>
                  {t(rejectButton.text)}
                </Button>
              ) : null
          }
          {
            approveButton ?
              (
                <Button size="small" color="primary" onClick={approveButton.callBk}>
                  {t(approveButton.text)}
                </Button>
              ) : null
          }
        </span>
        {
          details && (rejectButton.hidden || !isDialogOpen)?
            (
              <span className={classes.detailsButton} role="button" onClick={handleIsDialogOpen}>
                {t(details.text)}

              </span>
            ) : null
        }

      </CardActions>
      {
        isDialogOpen ?
          <FVMDialog
            isDialogOpen={isDialogOpen}
            title={releaseNotesTitle}
            details={<MarkdownRenderer markdown={releaseNotes}/>}
            onCloseDialog={handleIsDialogClosed}
          /> : null
      }
    </Card>
  );
}
