import joi from 'joi';


export const headerAuthScheme = joi.object({
  vehicleIdRef: joi.number(),
  referenceLocation: joi.string().allow('').max(50).optional(),
  remarks: joi.string().allow('', null).max(4000),
  referenceTimestamp_date: joi.date(),
  referenceTimestamp_time: joi.date(),
});

export const vehicleStateAuthScheme = ({ sandState = 'ok', motorOilState = 'ok' })=> joi.object({
  hydraulicsState: joi.string(),
  vehicleOperatingHours_h: joi.number().min(0),
  mileage_km: joi.number().min(0),
  fuelFillingLevel_l: joi.number(),
  fuelAdded_l: joi.number(),
  sandState: joi.string(),
  isSandAdded: sandState === 'not_ok' ? joi.boolean(): joi.boolean().allow(null) ,
  motorOilState: joi.string(),
  motorOilAdded_l: motorOilState === 'not_ok' ?  joi.number() : joi.number().allow(null),
  motorOilFillingLevel_l: joi.number(),
});

export const inspectionsAuthScheme = (activeV = '', a1Required=false) =>
  joi.object({
    inspectionPerformedTimestamp_A1: a1Required ? joi.date() :  joi.date().allow(null).optional(),
    inspectionPerformedTimestamp_V1:
      activeV === 'V1' ? joi.date() : joi.date().allow(null).optional(),
    inspectionPerformedTimestamp_V3:
      activeV === 'V3' ? joi.date() : joi.date().allow(null).optional(),
    inspectionPerformedTimestamp_F1: joi.date().allow(null).optional(),
    preparationService: joi.string()
  });
export const defectsAuthScheme = joi.object({
  description: joi.string().max(4000).optional(),
  measuresDescription: joi.string().max(4000).allow('', null).optional(),
  state: joi.string().max(50),
});


export const stoppageAuthScheme = (state)=> joi.object({
  vehicleLocation: joi.string().max(50).optional(),
  trainNumber: joi.string().allow('', null).max(50).optional(),
  isResolved: joi.boolean(),
  lastOperationMode: joi.string().optional(),
  wasAuxiliaryVehicleUsed: joi.boolean().optional(),
  errorCodesDescription: joi.string().allow('').max(4000).optional(),
  affectedComponents: joi.object().optional(),
  description: joi.string().allow('', null).max(4000),
  remarks: joi.string().max(4000).allow('', null),
  measuresDescription: joi.string().allow('', null).max(4000),
  startTimestamp: joi.date(),
  startTimestamp_date: joi.date(),
  startTimestamp_time:  joi.date(),
  endTimestamp_time:  state === 'resolved' ? joi.date() : joi.date().allow(null).optional(),
  endTimestamp_date: state === 'resolved' ? joi.date() :  joi.date().allow(null).optional(),
  endTimestamp: state === 'resolved' ? joi.date() :  joi.date().allow(null).optional(),
  state: joi.string(),
});
export const commentAuthScheme = joi.object({
  newEntryState: joi.string(),
  remarks: joi.string().max(4000).optional(),
});
export const signatureImageAuthScheme = joi.object({
  signatureImage: joi.optional(),
});

export const entryAuthScheme = arr => {
  let optionalObj = {};
  arr
    .map(({ key, isActive }) => conditionalAuthScheme(key, isActive))
    .forEach(obj => (optionalObj = { ...optionalObj, ...obj }));
  // .flat()
  return joi.object({
    header_vehicleIdRef: joi.number(),
    header_referenceLocation: joi.string().allow('').max(50).optional(),
    header_remarks: joi.string().allow(null).max(4000),
    header_referenceTimestamp: joi.date(),
    header_referenceTimestamp_time: joi.date(),
    vehicleState_hydraulicsState: joi.string(),
    vehicleState_vehicleOperatingHours_h: joi.number(),
    vehicleState_mileage_km: joi.number().min(0),
    vehicleState_fuelFillingLevel_l: joi.number(),
    vehicleState_fuelAdded_l: joi.number(),
    vehicleState_sandState: joi.string(),
    vehicleState_isSandAdded: joi.boolean().allow(null),
    vehicleState_motorOilState: joi.string(),
    vehicleState_motorOilAdded_l: joi.number().allow('', null).optional(),
    vehicleState_motorOilFillingLevel_l: joi.number(),
    inspections_inspectionPerformedTimestamp_A1: joi.date().allow(null).optional(),
    inspections_inspectionPerformedTimestamp_V1: joi.date().allow(null),
    inspections_inspectionPerformedTimestamp_V3: joi.date().allow(null),
    inspections_inspectionPerformedTimestamp_F1: joi.date().allow(null).optional(),
    defects_description: joi.string().allow('').max(4000),
    defects_measuresDescription: joi.string().allow('', null).max(4000),
    defects_state: joi.optional().allow(''),
    stoppage_vehicleLocation: joi.string().max(50).allow(''),
    stoppage_trainNumber: joi.string().max(50).allow(''),
    //
    stoppage_startTimestamp: joi.date().optional().allow(null),
    stoppage_startTimestamp_time: joi.date().optional().allow(null),
    stoppage_endTimestamp: joi.date().optional().allow(null),
    stoppage_endTimestamp_time: joi.date().optional().allow(null),
    //
    stoppage_lastOperationMode: joi.string().allow(null),
    stoppage_wasAuxiliaryVehicleUsed: joi.boolean().allow(null),
    stoppage_errorCodesDescription: joi.string().max(4000).allow(''),
    stoppage_affectedComponents: joi.object(),
    stoppage_description: joi.string().max(4000).allow('', null),
    stoppage_measuresDescription: joi.string().max(4000).allow(''),
    stoppage_remarks: joi.string().max(4000).allow('', null),
    stoppage_state: joi.string().allow('', null).optional(),
    preparationService: joi.string(),

    // header_vehicleIdRef: joi.number(),
    // header_referenceLocation: joi.string().allow("").max(50).optional(),
    // header_remarks: joi.string().allow(null).max(4000),
    // header_referenceTimestamp: joi.date(),
    // vehicleState_hydraulicsState: joi.string().allow(null),
    // vehicleState_vehicleOperatingHours_h: joi.number(),
    // vehicleState_mileage_km: joi.number().min(0),
    // vehicleState_fuelFillingLevel_l: joi.number(),
    // vehicleState_fuelAdded_l: joi.number(),
    // vehicleState_sandState: joi.string(),
    // vehicleState_isSandAdded: joi.boolean().allow(null),

    // vehicleState_motorOilAdded_l: joi.number(),
    // vehicleState_motorOilFillingLevel_l: joi.number(),
    // inspections_inspectionPerformedTimestamp_A1: joi.object(),
    // inspections_inspectionPerformedTimestamp_V1: joi.date(),
    // inspections_inspectionPerformedTimestamp_V3: joi.date(),
    // inspections_inspectionPerformedTimestamp_F1: joi.date(),
    // vehicleState_motorOilState: joi.string(),
    ...optionalObj,
  });
};
const conditionalAuthScheme = (key, isActive) => {
  if (!isActive) {
    return {};
  }
  const optionalAuthObject = {
    defects: () => ({
      defects_description: joi.string().max(4000).required(),
      defects_state:  joi.string().max(50),
    }),
    stoppage: () => ({
      stoppage_vehicleLocation: joi.string().max(50),
      stoppage_isResolved: joi.boolean(),
      stoppage_startTimestamp: joi.date(),
      stoppage_startTimestamp_time: joi.date(),
      stoppage_lastOperationMode: joi.string(),
      stoppage_wasAuxiliaryVehicleUsed: joi.boolean(),
      stoppage_affectedComponents: joi.object().required(),
      // stoppage_description: joi.string().max(4000),
      stoppage_state: joi.string(),
    }),
    vehicleState_motorOilState: () => ({
      vehicleState_motorOilAdded_l: joi.number(),
    }),
    vehicleState_sandState: () => ({
      vehicleState_isSandAdded: joi.boolean(),
    }),
    stoppage_state: () => ({
      stoppage_endTimestamp: joi.date(),
      stoppage_endTimestamp_time: joi.date(),
    }),
    inspections_inspectionPerformedTimestamp_V1: () => ({
      inspections_inspectionPerformedTimestamp_V1: joi.date(),
    }),
    inspections_inspectionPerformedTimestamp_V3: () => ({
      inspections_inspectionPerformedTimestamp_V3: joi.date(),
    }),
    inspections_inspectionPerformedTimestamp_A1: () => ({
      inspections_inspectionPerformedTimestamp_A1: joi.date(),
    }),
    preparationService: () => ({
      preparationService: joi.string().required(),
    }),

  };
  return optionalAuthObject[key]();
};
