import { getAccPersonApi, getAccSettingsApi } from './index';
// Persons

export const getPersonAccount = async (accountId) => {
  const { data } = await (await getAccPersonApi()).getPersonAccount(accountId);
  return data;
};

export const getPersonAccountSettings = async () => {
  const { data } = await (await getAccSettingsApi()).getAccountSettings().catch(error => ({ data: {} }));
  if (typeof data !== 'object') {
    return { data };
  }
  return data;
};

export const UpdatePersonAccountSettings = async (accountSetting) => {
  return await (await getAccSettingsApi()).updateAccountSettings(accountSetting);
};

export const addToPersonAccountSettings = async (accountSetting) => {
  const storedSetting = await getPersonAccountSettings();
  return await (await getAccSettingsApi()).updateAccountSettings({ ...storedSetting, ...accountSetting });
};


export const getPersonAccounts = async (queryParams = { orderby: ['accountName'] }) => {
  const { search, top, skip, filter, count, orderby, select } = queryParams;
  const { data } = await (await getAccPersonApi()).getPersonAccounts(search, top, skip, filter, count, orderby, select).catch(e => ({
    data: { message: e + '' },
  }));

  return data;
};

export const createPersonAccount = async (personData) => {
  const { data } = await (await getAccPersonApi()).createPersonAccount(personData).catch(e => ({
    data: { message: e + '' },
  }));

  return data;
};


export const updatePersonAccount = async (accountId, updateInfo) => {
  const { status } = await (await getAccPersonApi()).updatePersonAccount(accountId, updateInfo).catch(e => ({
    status: { message: e + '' },
  }));
  return { status };
};

export const updatePersonAccountAvatarImage = async (accountId, avatarImage) => {
  return await (await getAccPersonApi())
    .updatePersonAccountAvatarImage(accountId, { avatarImage })
    .catch(err => ({
      status: err.status,
      message: err.message,
    }));
};

export const deletePersonAccount = async (accountId) => {
  return await (await getAccPersonApi())
    .deletePersonAccountAvatarImage(accountId)
    .catch(err => ({
      status: err.status,
      message: err.message,
    }));
};
