import React from 'react';
const SubCatEducation = () => {
  const a = {
    fill: '#e5e5e5',
    opacity: 0,
  };
  const b = {
    fill: '#002fc9',
    stroke: 'rgba(0,0,0,0)',
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.522" height="24" viewBox="0 0 24.522 24">
        {/*<style>.a{fill:#e5e5e5;opacity:0;}.b{fill:#002fc9;stroke:rgba(0,0,0,0);}</style>*/}
      <g transform="translate(-440.723 -357)">
        <rect style={a} width="24" height="24" transform="translate(441 357)" />
        <path
          style={b}
          d="M-791,625l1-3v-8.386l-9,3.886-11-4.75L-799,608l11,4.749-1,.432V622l1,3Zm-15-4v-5l7,2.892,7-2.892.062,5A16.828,16.828,0,0,1-799,623,16.4,16.4,0,0,1-806,621Z"
          transform="translate(1251.984 -247.938)"
        />
      </g>
    </svg>
  );
};
export default SubCatEducation;
