// const zoomFactor = 1;
// const drawerWidth = 210 * zoomFactor;
// const fontWeightBold = 700;
// const fvmColorBg03 = 'rgb(240,243,246)'; // bg-04 grey // #DFE3E6
// const fvmColorBg04 = 'rgb(223,227,230)'; // bg-04 grey // #DFE3E6
// const bodyColor = 'rgb(21,41,53)'; // bg-04 grey // #DFE3E6
// const brandPrimary = 'rgb(6,222,58)'; // bg-04 grey // #DFE3E6

const styles = theme => ({
  root: {
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
  },
  fvmLocControl: {
    width: 270 * theme.fvm.zoomFactor,
    minWidth: 270 * theme.fvm.zoomFactor,
    fontSize: theme.typography.fontSize,
  },
  selectAllCheckboxLabel: {
    marginRight: 0,
  },
  selectAllCheckbox: {
    paddingRight: 0,
  },

  selectAllDropdown: {
    width: 180,
    flexDirection: 'row-reverse',
    minWidth: '2px',
    '&>div': {
      padding: '0 !important',
    },
    '&>div:focus': {
      backgroundColor: '#ffffff',
    },
    '&:before, &:after': {
      display: 'none',
    },
  },
  selectedLocsTxt:{
    fontWeight: 400,
    paddingLeft: 3
  }
});

export default styles;
