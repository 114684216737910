


class CustomLogging {
  constructor(title) {
    this.title = {
      body: title || "---",
      color: "darkgrey",
      size: "1rem"
    };

    this.body = {
      color: "#008f68",
      size: "1rem"
    };
  }

  setTitleStyle({ color, size }) {
    if (color !== undefined) this.title.color = color;
    if (size !== undefined) this.title.size = size;
  }

  setBodyStyle({ color, size }) {
    if (color !== undefined) this.body.color = color;
    if (size !== undefined) this.body.size = size;
  }

  getErrorObject(){
    try { throw Error('') } catch(err) { return err; }
  }


  log(body = "") {
    if(process.env.REACT_APP_FVM_BASE_URL){
      // the second line is now the body because the first references the content after the first %c for the title
      // const err = this.getErrorObject();
      // const caller_line = err.stack.split("\n")[4];
      // const caller_line = err.stack.split("\n")[4];
      // const index = caller_line.indexOf("at ");
      // const clean = caller_line.match(/(?:\().*(?:\))/, caller_line);
      // const [Arguments] = arguments;
      console.log(
        `%c **** ${this.title.body} ****: \n%c type: ${typeof body} \n value: `,
        `color: ${this.title.color}; font-weight: bold; font-size: ${
          this.title.size
        };`,

        `color: ${this.body.color}; font-weight: bold; font-size: ${
          this.body.size
        }; text-shadow: 0 0 5px rgba(0,0,0,0.2);`,
        ...arguments,
        // `\n%c line: `,
        // 'color: darkgrey; font-weight: bold; font-size: 0.8rem; text-shadow: 0 0 5px rgba(0,0,0,0.2);',
      // clean
      );
      // console.log(body);
    }
  }
}

const error = new CustomLogging('fvm-error');
const log = new CustomLogging('fvm-log');
const warn = new CustomLogging('fvm-warning');

error.setTitleStyle({ color: 'red', size: '1rem' });
warn.setTitleStyle({ color: 'yellow', size: '1rem' });
log.setTitleStyle({ color: 'orange', size: '1rem' });

window.fvm = {
  warn: val => warn.log(val),
  error: val => error.log(val),
  log: val => log.log(val),
};

console.logFvm = val => warn.log(val);
console.warnFvm = val => warn.log(val);
console.errorFvm = val => log.log(val);
export default CustomLogging;