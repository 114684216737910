import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import TrainIcon from '@material-ui/icons/TrainOutlined';
import Badge from '@material-ui/core/Badge';
import React from 'react';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  activeChip:{
    '&>div':{
      backgroundColor: theme.palette.primary.main,
      minHeight: 28,
      minWidth: 28,
    }
  },
  deviceIcon:{
    color: '#FFFFFF !important'
  }
}));



const DeviceChip = ({ className, name, selectedLocos, disabled, onClick}) => {
  const classes = useStyles();
  return (
    <Badge
      onClick={onClick}
      className={className}
      badgeContent={selectedLocos}
      color="primary"
      max={1000}

    >
      <Chip
        className={disabled ? '' : classes.activeChip}
        label={name || ''}
        avatar={
          <Avatar>
            <TrainIcon
              className={classes.deviceIcon}
            />
          </Avatar>
        }
      />
    </Badge>

  );
};

export default DeviceChip;