import React from 'react';
const CraneStatus = (props) => {

  const styles = {
    a: {
      fill: '#fff700',
    },
    b: {
      fill: '#152a35',
    },
    c: {
      stroke: 'none',
    },
    d: {
      fill: 'none',
    },
    e: {
      filter: 'url(#a)',
    },
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43">
      <defs>
        <filter id="a" x="0" y="0" width="43" height="43" filterUnits="userSpaceOnUse">
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1.5" result="b" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(-1563.5 -658.5)">
        <g transform="translate(586 144.205)">
          <g style={styles.e} transform="matrix(1, 0, 0, 1, 977.5, 514.29)">
            <g style={styles.a} transform="translate(4.5 4.5)">
              <circle style={styles.c} cx="17" cy="17" r="17" />
              <circle style={styles.d} cx="17" cy="17" r="16.5" />
            </g>
          </g>
        </g>
        <g transform="translate(493 92)">
          <path
            style={styles.b}
            d="M1082,594v1h1.092a1.482,1.482,0,0,0-.092.5,1.5,1.5,0,0,0,3,0,1.482,1.482,0,0,0-.092-.5h11.184a1.482,1.482,0,0,0-.092.5,1.5,1.5,0,0,0,3,0,1.482,1.482,0,0,0-.092-.5H1101v-1Z"
          />
          <path
            style={styles.b}
            d="M1099,584a2,2,0,1,0,3.889-.629l-.952.318A.985.985,0,0,1,1102,584a1,1,0,1,1-1-1h0v-5h-1l-15,8v2.2l3,1.8h-6v3h19l-1.143-6H1093v3l-5-3,12-7.5v2.778A1.991,1.991,0,0,0,1099,584Zm-5,4h5.03l.381,2H1094Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default CraneStatus;
