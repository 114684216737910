import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { bindActionCreators } from 'redux';
import { changedView, mapLoadingData, setFetchActiveLocoListData, updateUserLocomotivesList } from '../../actions';
import { connect } from 'react-redux';
import { Snackbar, Tooltip, withStyles } from '@material-ui/core';
import { Loader } from 'google-maps';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import MediaCard from '../../components/MediaCard';
import FVMTrackedButton from '../../components/TrackedButton';
import NavigationIcon from '@material-ui/icons/Navigation';
import { getCurrentView, timeFormatTableCol } from '../../StatelessFunctions/nummericManapulation';
import { VEHICLE_STATUS_NAMES } from '../../globalConstants/vechicelConsts';
import { didLocoSelectionChange } from '../../utils/loco-helpers';
import moment from 'moment';
import * as RalationItems from '../../assets/static-files/ralation-items.json'
import LoadingSpinner from '../../components/LoadingSpinner';

const TRACKING_ZOOM_LEVEL = 12;
const GM_TOKEN = process.env.REACT_APP_GOOGLE_MAPS_API_KEY !== undefined ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : ''

// let GM_TOKEN = 'AIzaSyC_5KlzV1TYmZP7wpmnleOzC6CQOjeZz4w';
// if (process.env.GOOGLE_MAPS_API_KEY){
//   GM_TOKEN = GOOGLE_MAPS_API_KEY 'AIzaSyC_5KlzV1TYmZP7wpmnleOzC6CQOjeZz4w';
// }
const styles = theme => ({

  recenterButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  gMapsDevHider: {
    '&>div:not(:first-child)': {
      'background-color': 'red !important',
      display: 'none',
    },
    // '&~div:not(:first-child)': {
  },
  recenterButton: {
    position: 'absolute',
    marginTop: -45,
  },

  fvmMapTooltip: {
    '& ul': {
      display: 'table',
      minWidth: 210,
      width: '100%',
      listStyle: 'none',
      padding: 0,
      margin: '12px 0 0',
    },
    '& li': {
      fontWeight: 400,
      display: 'table-row',
      justifyContent: 'space-between',
      fontSize: 14,
      '& span': {
        minWidth: 20,
        display: 'table-cell',
      },
    },
  },
  fvmMapTooltipTitle: {
    fontSize: 14,
    maxWidth: 280,
    // whiteSpace: 'nowrap',
    fontFamily: 'IBM Plex Sans,Roboto,Arial,sans-serif',
    fontWeight: 700,
  },

  fvmRailationTooltipLine: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    fontFamily: 'IBM Plex Sans,Roboto,Arial,sans-serif',
    fontWeight: 400,
    display: 'flex',
    '& > p:first-child': {
      width: 50,
      marginRight: 50
    },
    '& > p:not(:first-child)': {
      wordBreak: 'break-all',
      whiteSpace: 'normal',
    },
    '& ul': {
      margin: 0
    }
  },
  // .fvm-map-tooltip .map-tooltip-title {
  //   font-size: 14px;
  //   white-space: nowrap;
  //   font-family: IBM Plex Sans,Roboto,Arial,sans-serif;
  //   line-height: 14px;
  //   font-weight: 700;
  //   /*margin-bottom: 10px;*/
  // }
});

const markerIcons = {
  driving: '/assets/images/locostatus/Train-Moving.png',
  standby: '/assets/images/locostatus/Train-Ignition-On.png',
  idle: '/assets/images/locostatus/Train-Motor-Running.png',
  off: '/assets/images/locostatus/Train-Engine-Off.png',
  crane: '/assets/images/locostatus/Crane-Icon.png',
  // railation: '/assets/images/locostatus/Crane-Icon.png',
  railation: '/assets/images/locostatus/Wartung-und-Instandhaltung.png',
  fallbackStatus: '/assets/images/locostatus/fallbackStatus.png',
};

/**
 * @class FVMGoogleMaps
 * ### General Description
 * ---
 * The Component is used to render google maps.
 *
 *The component is used on the following views:
 * - Dashboard
 * - Liva Data
 * - Tracking Map
 *
 */
class FVMGoogleMaps extends Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.isSetupComplete = false;
    this.state = {
      trackFocusedDevice: false,
      lng: 9.5,
      lat: 51,
      zoom: 4,
      mapBoundaries: {},
      showHoverCard: false,
      showMessage: ''
    };
    this.mapTrainMarkers = [];
    this.railationFeaturesMap = {};
    this.railationFeatures = (RalationItems.default || []).filter(({ coordinates }) => coordinates[0] !== undefined).map(({ id, ...items }, index) => {
      const newId = `R${id}`;
      return {
        ...items,
        id: newId,
      }
    });

    this.railationFeatures.forEach(({ ...items }, index) => {
      this.railationFeaturesMap[items.id] = {
        ...items,
      }
    });

    this.updateDeviceMarkersOnMap = this.updateDeviceMarkersOnMap.bind(this);
    this.updateGeneralMapBehaviour = this.updateGeneralMapBehaviour.bind(this);
    this.fitMapToMarkers = this.fitMapToMarkers.bind(this);
    this.setMarkers = this.setMarkers.bind(this);
    this.drawTracks = this.drawTracks.bind(this);
    this.drawHeatmap = this.drawHeatmap.bind(this);
    this.getToolTipHtml = this.getToolTipHtml.bind(this);
    this.getRailationToolTipHtml = this.getRailationToolTipHtml.bind(this);
    this.buildMap = this.buildMap.bind(this);
    this.focusMapTo = this.focusMapTo.bind(this);
    this.handleToolTipPosition = this.handleToolTipPosition.bind(this);
    // this.cleanDom();
  }

  componentDidMount() {
    const { activeUserLocoId, heatMapData, isTrackingMap, isHeatMap } = this.props;

    const options = {
      libraries: ['visualization'],
      language: localStorage.getItem('i18nextLng'),
    };

    // setTimeout(buildMap, 1000);
    this.buildMap(options).then(() => {
      if (['trackingmap'].includes(this.props.currentView) && activeUserLocoId) {
        if (activeUserLocoId && (isTrackingMap || isHeatMap) && heatMapData.vehicleId && heatMapData.track.length) {
          this.fitMapToTracks(heatMapData.track);
        } else {
          this.fitMapToMarkers();
        }
      }
    }).then(() => {
      if (this.props.targetedUserLocomotiveId) {
        this.setState({ trackFocusedDevice: true }, () => setTimeout(() => this.focusMapTo({ trackFocusedDevice: true }), 3000));
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      userLocomotiveListObject,
      languageFilesReloaded,
      activeUserLocomotiveListIds,
      targetedUserLocomotiveId,
      activeUserLocoId,
      currentView,
      railationFeatures,
      heatMapData,
      isTrackingMap,
      isHeatMap,
    } = this.props;
    const { isShowRailway, isShowSatellite, trackingMapData, isShowRailation } = this.props;

    const locoIdChanged = prevProps.activeUserLocomotiveListIds && didLocoSelectionChange(activeUserLocomotiveListIds, prevProps.activeUserLocomotiveListIds);
    const focusedIdChanged = targetedUserLocomotiveId !== prevProps.targetedUserLocomotiveId;

    const trainIdChanged = prevProps.heatMapData && (prevProps.trackingMapData.vehicleId !== trackingMapData.vehicleId || prevProps.heatMapData.vehicleId !== heatMapData.vehicleId);
    const trackingMapSwitched = prevProps.isTrackingMap !== isTrackingMap;
    const heatMapSwitched = prevProps.isHeatMap !== isHeatMap;
    const dateChanged = (heatMapData && prevProps.heatMapData) && ((heatMapData.period.startDate.format('YYYYMMDD') !== prevProps.heatMapData.period.startDate.format('YYYYMMDD')) ||
      (heatMapData.period.endDate.format('YYYYMMDD') !== prevProps.heatMapData.period.endDate.format('YYYYMMDD')));


    const isShowRailwayChanged = isShowRailway !== prevProps.isShowRailway;
    const isShowSatelliteChanged = isShowSatellite !== prevProps.isShowSatellite;
    const isShowRailationChanged = isShowRailation !== prevProps.isShowRailation;

    if (railationFeatures.length && !this.railationFeatures.length) {
      // this.railationFeatures = railationFeatures;
    }


    if (languageFilesReloaded !== prevProps.languageFilesReloaded) {
      const options = {
        libraries: ['visualization'],
        language: localStorage.getItem('i18nextLng'),
        zoom: this.map.getZoom(),
        center: this.map.getCenter(),
      };

      this.isSetupComplete = false;
      this.buildMap(options, true);

      // this.forceUpdate();
    }
    // global.fvmLog(this.isSetupComplete, 'hmmm');

    if (['livedata', 'dashboard'].includes(currentView) && this.props.isUpdateView && (prevProps.isUpdateView !== this.props.isUpdateView)) {
      this.updateDeviceMarkersOnMap();
    }

    if (this.isSetupComplete) {
      if (isShowRailwayChanged || isShowSatelliteChanged || trainIdChanged || trackingMapSwitched || heatMapSwitched || dateChanged) {

        this.updateGeneralMapBehaviour({
          isShowRailwayChanged,
          isShowSatelliteChanged,
          trainIdChanged,
          trackingMapSwitched,
          heatMapSwitched,
          dateChanged,
        });
      }
      if ((isShowRailationChanged || locoIdChanged) && (['livedata', 'dashboard'].includes(currentView))) {
        this.updateDeviceMarkersOnMap();
        if ((prevProps.isUpdateView !== this.props.isUpdateView) && this.props.isUpdateView) {
          this.updateDeviceMarkersOnMap();
        }
      }

      if (focusedIdChanged && (['livedata', 'dashboard'].includes(currentView))) {
        if (userLocomotiveListObject[targetedUserLocomotiveId]) {
          this.setState({ trackFocusedDevice: true });
          this.focusMapTo({ trackFocusedDevice: true });
          this.setMapZoom();
        } else {
          this.fitMapToMarkers();
        }
      }

      if (['trackingmap'].includes(currentView) && (locoIdChanged || (heatMapData.vehicleId !== prevProps.heatMapData.vehicleId) || dateChanged)) {
        if (activeUserLocoId && (isTrackingMap || isHeatMap) && ((heatMapData.vehicleId && heatMapData.track.length) || (trackingMapData.features.length))) {
          if (isTrackingMap) {
            // this.fitMapToTracks();
          } else {
            this.fitMapToTracks(heatMapData.track);
          }

        } else {
          this.fitMapToMarkers();
        }
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }


  /**
   * @description The method is used to focus the map to a particular marker/locomotive
   * @param {Object} userLoco a locomotive object to which the focus should be applied
   */

  focusMapTo({ userLoco = this.props.userLocomotiveListObject[this.props.targetedUserLocomotiveId], trackFocusedDevice = this.state.trackFocusedDevice }) {
    if (!userLoco) {
      return;
    }
    const { position } = userLoco.live;
    if (!(position?.lng && position?.lat)) {
      console.log("called---");
      this.setState({ showMessage: 'No postion data available for this box' });
      setTimeout(() => {
        this.setState({ showMessage: '' });
      }, 5000);
      return;
    }

    if (trackFocusedDevice) {
      this.map.setCenter({ ...position });
      // this.map.setCenter({ lat: position.coordinates[0], lng: position.coordinates[1] });
    }
  }

  cleanDom() {
    const scripts = document.querySelectorAll('script[src*=\'maps.googleapis.com/maps-api-\'], script[src*=\'maps.googleapis.com/maps/api/js\']');
    for (let i = 0; i < scripts.length; i++) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }
  }

  buildMap = async (options, forceLoad = false) => {
    // this.props.showLoading(true);
    if (!this.mapContainer) {
      setTimeout(this.buildMap, 1000);
    }

    let isLanguageChange = false;
    if (forceLoad) {
      // this.map.mapUrl =
      // `https://maps.google.com/maps?ll=54.559218,12.510665&z=5&t=m&hl=${options.language}&mapclient=apiv3`;
      window.Nrail = null;
      this.cleanDom();
      isLanguageChange = true;
    }

    if (!(window.Nrail && window.Nrail.gMaps)) {
      const loader = new Loader(GM_TOKEN, options);
      this.gMaps = await loader.load();
      window.Nrail = {
        gMaps: this.gMaps,
      };
    } else {
      const { gMaps } = window.Nrail;
      this.gMaps = gMaps;
    }

    let { zoom, center } = options;
    center = center ? { lat: center.lat(), lng: center.lng() } : { lat: 51, lng: 9.5 };

    this.map = new this.gMaps.maps.Map(this.mapContainer, {
      center,
      zoom: zoom || 5,
      minZoom: 4,
      clickableIcons: false,
      maxZoom: 21,
      zoomControl: true,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      // collisionBehavior: this.gMaps.maps.collisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY,
      zoomControlOptions: {
        position: this.gMaps.maps.ControlPosition.LEFT_TOP,
      },
    });

    this.setMarkers({ isLanguageChange });

    this.railwayImageMapType = new this.gMaps.maps.ImageMapType({
      name: 'railway-tiles',
      getTileUrl: function (coord, zoom) {
        return ['http://a.tiles.openrailwaymap.org/standard/',
          zoom, '/', coord.x, '/', coord.y, '.png'].join('');
      },
      tileSize: new this.gMaps.maps.Size(256, 256),
    });

    this.map.addListener('zoom_changed', () => {
      setTimeout(() => {
        const trackFocusedDevice = this.state.trackFocusedDevice && this.map.getZoom() === TRACKING_ZOOM_LEVEL;
        this.setState({ trackFocusedDevice });
      }, 250);
    });
    this.map.addListener('drag', () => {
      setTimeout(() => {
        this.setState({ trackFocusedDevice: false });
      }, 250);
    });

    // this.updateGeneralMapBehaviour(this.props);
    // this.updateGeneralMapBehaviour({
    //   isShowRailwayChanged: true,
    //   isShowSatelliteChanged: true,
    //   trainIdChanged: true,
    //   trackingMapSwitched: true,
    //   heatMapSwitched: true,
    //   dateChanged: true,
    // });

    if (['livedata', 'dashboard'].includes(this.props.currentView)) {
      // this.props.setFetchActiveLocoListData(true);

      this.timerId = setInterval(async () => {
        // global.fvmLog('going: ', {vehicleIds: [...this.props.activeUserLocomotiveListIds]})
        // const response = await fetchMapLiveData({vehicleIds: [...this.props.activeUserLocomotiveListIds]});
        //
        // response.forEach(({ vehicleId,live,todays, timestamp }) => {
        //   this.props.userLocomotiveListObject[vehicleId] = {
        //     ...this.props.userLocomotiveListObject[vehicleId],
        //     live: {
        //       ...this.props.userLocomotiveListObject[vehicleId].live,
        //       ...live
        //     },
        //     todays: {
        //       ...this.props.userLocomotiveListObject[vehicleId].todays,
        //       ...todays
        //     },
        //     timestamp: {
        //       ...this.props.userLocomotiveListObject[vehicleId].timestamp,
        //       ...live
        //     }
        //   }
        // });
        this.focusMapTo({});
        // this.props.updateUserLocomotivesList({...this.props.userLocomotiveListObject, });
        this.updateDeviceMarkersOnMap();
      }, 15000);
    }
  };

  /**
   * @function
   * @description The function is iused to set the zoom level of the map.
   * @param {int} zoomLevel
   * @returns {} `void`
   */

  setMapZoom(zoomLevel = TRACKING_ZOOM_LEVEL) {
    this.map.setZoom(zoomLevel);
  }

  handleToolTipPosition(e, locId = null, show = true, isRailation) {
    let { clientX, clientY, offsetX, offsetY } = e.domEvent;
    let hoverCardPosition = {
      display: 'none',
      zIndex: -300,
    };
    let showHoverCard = false;
    let hoveCardHtml = null;

    if (show) {
      const { bottom, left } = this.mapContainer.getBoundingClientRect();
      // const { bottom, left } = this.hoverCardContainer.getBoundingClientRect();
      clientX = clientX - offsetX;
      clientY = clientY - offsetY + 30;

      /**
       * Used to keep tooltip in bounds
       */

      // if (this.props.isLocalEnv) {
      if (clientX < (left + 120)) {
        clientX = (left + 180);
      }

      if (clientY > (bottom - 155)) {
        clientY = (clientY - 185);
      }
      // }

      hoverCardPosition = {
        left: clientX,
        top: clientY,
        position: 'fixed',
        zIndex: -1,
      };

      showHoverCard = true;
      hoveCardHtml = isRailation ? this.getRailationToolTipHtml(locId, false) : this.getToolTipHtml(locId, false);

    }

    this.setState({ hoverCardPosition, showHoverCard, hoveCardHtml });
  }

  /**
   * @function
   * @description The function is used to set the initial position of the markers on the map.
   * @returns {} `void`
   */
  async setMarkers({ isLanguageChange = false }) {
    if (
      !Object.keys(this.props.userLocomotiveListObject).length ||
      (!Object.values(this.props.userLocomotiveListObject).find(({ live }) => live && live.position))
    ) {

      setTimeout(() => this.setMarkers({ isLanguageChange }), 500);

      return;

    }
    const { map } = this;
    const { userLocomotiveListObject } = this.props;

    const createMarkerObject = ({ vehicleId, live, vehicle, dailyDrivingHours, ActualGpsSpeed, bearingAngle }) => {
      const { statusVehicle, position } = live;
      const { name } = vehicle;
      return {
        'type': 'Feature',
        'id': vehicleId,
        name,
        properties: {
          id: vehicleId,
          statusVehicle,
          dailyDrivingHours,
          bearingAngle,
          ActualGpsSpeed,
          live,
        },
        icon: {
          url: userLocomotiveListObject[vehicleId].isCrane ? markerIcons.crane : (markerIcons[statusVehicle] || markerIcons['off']),
          // size: new  this.gMaps.maps.Size(statusVehicle === 'Crane' ? 15 : 32, 32, 'px', 'px', ),
          // origin: new  this.gMaps.maps.Point(0, 0),
          scaledSize: new this.gMaps.maps.Size(statusVehicle === 'Crane' ? 38 : 40, statusVehicle === 'Crane' ? 38 : 40), // scaled
          // size
          // The anchor for this image is the base of the flagpole at (0, 32).
          // anchor: new  this.gMaps.maps.Point(0, 200),
          // rotation: degree,
          isRailationItem: false,
          rotation: bearingAngle,
          forceShowToolTip: false,
          id: vehicleId,
        },
        'geometry': {
          'type': 'Point',
          'coordinates': [position.lat, position.lng],
        },
      };
    };
    let trainMarkers = Object.keys(userLocomotiveListObject)
      .filter(id => this.props.userLocomotiveListObject[id].live.position)// remove vehicles without a position
      .map(id => userLocomotiveListObject[id])
      .map(loco => createMarkerObject(loco));

    const markers = [...trainMarkers, ...(this.railationFeatures.map((feature, index) => ({
      ...feature,
      'properties': {
        ...feature?.properties,
        id: feature?.id,
        statusVehicle: 'railation',
      },
      icon: {
        url: markerIcons.railation,
        scaledSize: new this.gMaps.maps.Size(38, 38), // scaled size
        forceShowToolTip: false,
        id: feature?.id,
        isRailationItem: true,
      },
      'geometry': {
        'type': 'Point',
        'coordinates': feature?.coordinates,
      },
    })))];

    const hoverInfoWindow = new this.gMaps.maps.InfoWindow({
      disableAutoPan: true,
    });

    const newMarkers = markers.map((marker, index) => {
      const newMakerItem = new this.gMaps.maps.Marker({
        position: { lat: marker.geometry.coordinates[0], lng: marker.geometry.coordinates[1] },
        icon: { ...marker.icon },
        fvmIconData: { ...marker.icon },
        anchor: new this.gMaps.maps.Point(10, 50),
        // title: marker.name,
        vehicleId: marker.icon.id,
        visible: false,
      });

      const infoWindow = new this.gMaps.maps.InfoWindow({
        // disableAutoPan: true,
        // pixelOffset: new this.gMaps.maps.Size(200, 5)
      });

      // infoWindow.closeButton = null;
      newMakerItem.addListener('click', (e) => {
        const tooltipHtml = marker.icon?.isRailationItem ? this.getRailationToolTipHtml(marker.properties.id) : this.getToolTipHtml(marker.properties.id);
        infoWindow.setContent(tooltipHtml);
        if (newMakerItem.keepOpen) {
          infoWindow.close(map, newMakerItem);
          newMakerItem.keepOpen = false;
          return;
        }
        newMakerItem.keepOpen = true;
        hoverInfoWindow.close(map, newMakerItem);
        infoWindow.open(map, newMakerItem);
        this.handleToolTipPosition(e, null, false, marker.icon?.isRailationItem);
      });

      newMakerItem.addListener('mouseover', (e) => {
        if (newMakerItem.keepOpen) {
          this.handleToolTipPosition(e, null, false, marker.icon?.isRailationItem);

        } else {
          this.handleToolTipPosition(e, marker.properties.id, undefined, marker.icon?.isRailationItem);
        }

        // to be removed if accepted
        // if (!newMakerItem.keepOpen) {
        //   const hoverToolTip = this.getToolTipHtml(marker.properties.id);
        //   hoverInfoWindow.setContent(hoverToolTip);
        //   hoverInfoWindow.open(map, newMakerItem);
        //   hoverInfoWindow.setPosition(newMakerItem.getPosition());
        // } else {
        //   hoverInfoWindow.close(map, newMakerItem);
        // }
      });

      newMakerItem.addListener('mouseout', (e) => {
        this.handleToolTipPosition(e, null, false);

        if (!newMakerItem.keepOpen) {
          hoverInfoWindow.close(map, newMakerItem);
        }
      });

      newMakerItem.closeInfoWindow = () => {
        infoWindow.close(map, marker);
      };

      return newMakerItem;
    });

    // const newLocationMarkers = markers.map((marker, index) => {
    //   const newMakerItem = new this.gMaps.maps.Marker({
    //     position: { lat: marker.geometry.coordinates[0], lng: marker.geometry.coordinates[1] },
    //     icon: { ...marker.icon },
    //     fvmIconData: { ...marker.icon },
    //     anchor: new this.gMaps.maps.Point(10, 50),
    //     // title: marker.name,
    //     vehicleId: marker.icon.id,
    //     visible: false,
    //   });
    //
    //   const infoWindow = new this.gMaps.maps.InfoWindow({
    //     // disableAutoPan: true,
    //     // pixelOffset: new this.gMaps.maps.Size(200, 5)
    //   });
    //
    //   // infoWindow.closeButton = null;
    //   newMakerItem.addListener('click', (e) => {
    //     const tooltipHtml = this.getToolTipHtml(marker.properties.id);
    //     infoWindow.setContent(tooltipHtml);
    //     if (newMakerItem.keepOpen) {
    //       infoWindow.close(map, newMakerItem);
    //       newMakerItem.keepOpen = false;
    //       return;
    //     }
    //     newMakerItem.keepOpen = true;
    //     hoverInfoWindow.close(map, newMakerItem);
    //     infoWindow.open(map, newMakerItem);
    //     this.handleToolTipPosition(e, null, false);
    //   });
    //
    //   newMakerItem.addListener('mouseover', (e) => {
    //     if (newMakerItem.keepOpen) {
    //       this.handleToolTipPosition(e, null, false);
    //
    //     } else {
    //       this.handleToolTipPosition(e, marker.properties.id);
    //     }
    //   });
    //
    //   newMakerItem.addListener('mouseout', (e) => {
    //     this.handleToolTipPosition(e, null, false);
    //
    //     if (!newMakerItem.keepOpen) {
    //       hoverInfoWindow.close(map, newMakerItem);
    //     }
    //   });
    //
    //   newMakerItem.closeInfoWindow = () => {
    //     infoWindow.close(map, marker);
    //   };
    //
    //   return newMakerItem;
    // });

    this.MarkerCuster = new MarkerClusterer(null, { ...newMarkers }, {
      ignoreHidden: true,
      // gridSize: 80,
      gridSize: 50,
      styles: Array.from(new Array(4), (Counter, index) => ({
        width: 30 + ((index + 1) * 5),
        height: 30 + ((index + 1) * 5),
        textColor: '#000000',
        textSize: 16,
        className: `custom-cluster-icon-${index + 1}`,
      })),
      styless: [
        {
          width: 30,
        },
        {
          width: 35,
        },
        {
          width: 40,
        },
        {
          width: 45,
        },
      ],
      clusterClass: 'custom-cluster-icon',
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    });

    this.isSetupComplete = true;
    this.mapTrainMarkers = newMarkers;
    // setTimeout( ()=> document.querySelector('[class^="FVMGoogleMaps-gMapsDevHider"] button.dismissButton').click(),
    // 3000)
    // this.setState({ isMarkerClusterDefined })

    this.updateDeviceMarkersOnMap(this.MarkerCuster);
    !isLanguageChange && this.fitMapToMarkers();

    if (['trackingmap'].includes(this.props.currentView)) {
      // this.drawHeatmap(rawData);
      // this.drawTracks(rawData);
    }
    // this.props.showLoading(false);

  }

  /**
   * @function
   * @description The function is used to add the drawing options to the map.
   * @param {googleMapInstance} gMaps
   * @returns {}
   */

  addDrawingOptionsToMap(gMaps) {
    const { map } = this;
    const commonDrawOptions = {
      fillColor: '#1cdd43',
      fillOpacity: 0.4,
      editable: true,
      draggable: true,
    };
    const drawingManager = new gMaps.maps.drawing.DrawingManager({
      // drawingMode: gMaps.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: gMaps.maps.ControlPosition.TOP_CENTER,
        drawingModes: ['circle', 'polygon', 'polyline', 'rectangle'],
      },
      polygonOptions: {
        ...commonDrawOptions,

      },
      rectangleOptions: {
        ...commonDrawOptions,
      },
      circleOptions: {
        ...commonDrawOptions,
        strokeWeight: 0.5,
        clickable: true,
        editable: true,
        draggable: true,
        zIndex: 1,
      },
    });
    drawingManager.setMap(map);
  }

  /**
   * @function
   * @description The method is used to generate the html used in the tooltips when hovering on a icon on the map.
   * @param {int} locoId
   * @returns {}
   */

  getRailationToolTipHtml(itemId, returnAsString = true) {
    const { classes } = this.props;

    const {
      name,
      category,
      street,
      plz,
      city,
      website,
    } = this.railationFeaturesMap[itemId];

    const outputComponent = (
      <React.Fragment>
        <div className={classes.fvmMapTooltip} style={{ minWidth: 150, maxWidth: 280, overflow: 'hidden' }}>
          <div className={classes.fvmMapTooltipTitle}><p>{name}</p></div>
          {category ? <div className={classes.fvmRailationTooltipLine}><p>Category</p> <p>{category}</p></div> : null}
          {
            street ?
              <div className={classes.fvmRailationTooltipLine} >
                <p>Address</p>
                <ul>
                  <li>{street}</li>
                  <li>{plz}</li>
                  <li>{city}</li>
                </ul>
              </div>
              : null
          }
          {website ? <div className={classes.fvmRailationTooltipLine}><p>website</p><p><a href={website}>{website}</a></p></div> : null}
        </div>

      </React.Fragment>);
    if (returnAsString) {
      return ReactDOMServer.renderToString(outputComponent);
    }
    return outputComponent;

    // return ReactDOMServer.renderToString(outputComponent);
  }

  getToolTipHtml(locoId, returnAsString = true) {
    const { t, userLocomotiveListObject, classes } = this.props;
    const {
      vehicle,
      timestamp,
      live,
      todays,
    } = userLocomotiveListObject[locoId];

    const { statusVehicle, position } = live;
    const { name } = vehicle;

    const machineStatus = {
      off: {
        tooltip: t('tooltip.machineStatusOff'),
      },
      standby: {
        tooltip: t('tooltip.machineStatusSystemsOn'),
      },
      idle: {
        tooltip: t('tooltip.machineStatusMotorOn'),
      },
      driving: {
        tooltip: t('tooltip.machineStatusDriving'),
      },
    };

    const setDrawerLocoId = () => this.setState({ drawerLocoId: locoId });

    const outputComponent = (
      <React.Fragment>
        <div className={classes.fvmMapTooltip} style={{ minWidth: 150 }}>
          <div className={classes.fvmMapTooltipTitle}>{name}</div>
          {
            userLocomotiveListObject[locoId].isCrane
              ? getCraneToolTip({ timestamp, live, t })
              : getTrainToolTip({ statusVehicle, live, todays, machineStatus, t })
          }
        </div>
      </React.Fragment>);

    if (returnAsString) {
      return ReactDOMServer.renderToString(outputComponent);
      return ReactDOMServer.renderToString(
        <MediaCard
          title=""
          isAbsolute={false}
          imgUrl={userLocomotiveListObject[locoId].vehicle.image}
          mainText={outputComponent}
          approveButton={
            {
              text: 'label.openLiveData',
              // callBk: setDrawerLocoId,
              callBk: setDrawerLocoId,
            }
          }
        />);
    } else {
      return outputComponent;
      return (
        <MediaCard
          title=""
          imgUrl={userLocomotiveListObject[locoId].vehicle.image}
          mainText={outputComponent}
          // , rejectButton, approveButton, details, imgUrl, releaseNotes, hidden, releaseNotesTitle
          approveButton={
            {
              text: 'label.liveData',
              callBk: setDrawerLocoId,
            }
          }
        />
      );
    }
  }

  /**
   * @function
   * @description The method is used to update the positions of the marker periodically. It is called on a
   *   setTimeInterval as well as on component will receive update
   * @param {Object} options
   * @param {array} options.activeUserLocomotiveListIds list of active ids
   * @param {Object} options.userLocomotiveListObject Lis of all locomotive object visible to the use
   * @param {boolean} options.showAllDevices Should all devices be shown on the map or not.
   * @returns {}
   *
   */

  async updateDeviceMarkersOnMap(markerCuster) {
    if (!['livedata', 'dashboard'].includes(this.props.currentView)) {
      return;
    }
    const { map, mapTrainMarkers } = this;
    const showAllDevices = (this.props.currentView === 'dashboard') && !this.props.isInitialUserDeviceSelectionCompleted;
    const { activeUserLocomotiveListIds, userLocomotiveListObject } = this.props;
    // if (!this.MarkerCuster) {
    //   return;
    // }
    const tempMap = markerCuster?.getMap() || this.MarkerCuster?.getMap() || map;
    if (!this.MarkerCuster?.getMap()) {
      this.MarkerCuster?.setMap(tempMap);
    }


    // const mapsLiveLocoObj = {};
    // let response = [];
    //
    //   response = await fetchMapLiveData({
    //     vehicleIds: [
    //       ...(this.props.activeUserLocomotiveListIds.length ? this.props.activeUserLocomotiveListIds : Object.keys(userLocomotiveListObject))
    //     ]
    //   });
    //
    //
    // response.forEach(({ vehicleId, ...tempMapLocoObj }) => mapsLiveLocoObj[vehicleId] = {...tempMapLocoObj});

    mapTrainMarkers.forEach((marker) => {


      if (!marker.fvmIconData?.isRailationItem && userLocomotiveListObject[marker.fvmIconData?.id]) {
        const { statusVehicle, position, bearingAngle } = userLocomotiveListObject[marker.fvmIconData.id].live || {};
        // const { bearingAngle } = mapsLiveLocoObj[marker.fvmIconData.id] ? mapsLiveLocoObj[marker.fvmIconData.id].live || {} : {};
        marker.fvmIconData.url = userLocomotiveListObject[marker.fvmIconData.id].isCrane ? markerIcons.crane : (markerIcons[statusVehicle] || markerIcons['fallbackStatus']);
        marker.icon.url = userLocomotiveListObject[marker.fvmIconData.id].isCrane ? markerIcons.crane : (markerIcons[statusVehicle] || markerIcons['fallbackStatus']);

        if (statusVehicle === VEHICLE_STATUS_NAMES.DRIVING) {
          marker.icon.url = `${markerIcons[statusVehicle]}#fvm-maker-${marker.fvmIconData.id}`;
          setTimeout(() => {
            const imgMarker = document.querySelector(`img[src='${marker.icon.url}']`);
            if (imgMarker) {
              imgMarker.parentElement.style.overflow = `unset`;
              let directionMarker = null;
              if (imgMarker.parentElement.hasChildNodes()) {
                directionMarker = [...imgMarker.parentElement.childNodes].find(node => node.className === 'fvm-driving-marker');
              }

              if (directionMarker) {

                directionMarker.style.transform = `rotate(${bearingAngle}deg)`;

              } else {
                const newElm = document.createElement('div');
                newElm.className = 'fvm-driving-marker';
                newElm.style.transform = `rotate(${bearingAngle}deg)`;
                imgMarker.parentElement.appendChild(newElm);
              }
            }
          }, 1500);
        }
        marker.setPosition({ ...position });
      }

      const isMarkerVisible = (showAllDevices && !marker.fvmIconData?.isRailationItem) || (!marker.fvmIconData?.isRailationItem && activeUserLocomotiveListIds.includes(marker.fvmIconData?.id)) || (this.props.isShowRailation && marker.fvmIconData?.isRailationItem);
      marker.setVisible(isMarkerVisible);
      !isMarkerVisible && marker.closeInfoWindow(map, marker); // removing the info window for an open marker
    });

    this.MarkerCuster?.repaint();
  }

  /**
   * @function
   * @description The method is used to fit the boundaries of the map to show all the user locomotives
   * @param {LatLangBounds} bounds
   * @returns {}
   *
   */

  fitMapToMarkers(focusTarget = this.mapTrainMarkers) {
    const bounds = new this.gMaps.maps.LatLngBounds();
    focusTarget.filter(({ position }) => (position?.lat !== 0) && (position?.lat !== 0)).forEach(({ position }) => bounds.extend(position));
    this.map.fitBounds(bounds);
  }

  fitMapToTracks(positions) {
    if (!positions) {
      return;
    }

    const bounds = new this.gMaps.maps.LatLngBounds();

    if (this.props.isTrackingMap) {
      positions.forEach(pos => pos.forEachLatLng(latLang => bounds.extend(latLang)));
      this.map.fitBounds(bounds);
      return;
    }


    positions.forEach(({ point }) => bounds.extend(new this.gMaps.maps.LatLng(point)));
    this.map.fitBounds(bounds);
  }

  /**
   * @function
   * @description The method is used update controls features of the map
   * @param {Object} props
   * @param {boolean} props.isShowRailway shows or hides the railway tracks of on a map
   * @param {boolean} props.isShowSatellite switches between street and satellite view
   * @param {boolean} props.isTrackingMap shows or hides the traces of a locomotive
   * @param {boolean} props.isHeatMap shows or hides the traces of a locomotive
   * @returns {}
   *
   */

  updateGeneralMapBehaviour({ isShowRailwayChanged, isShowSatelliteChanged, trainIdChanged, trackingMapSwitched, heatMapSwitched, dateChanged }) {
    const { map, props } = this;
    const { isShowRailway, isShowSatellite, isTrackingMap, isHeatMap, trackingMapData, heatMapData, isRailationActive } = this.props;

    if (isShowRailwayChanged) {
      if (isShowRailway) {
        map.overlayMapTypes.push(this.railwayImageMapType);
      }

      if (!isShowRailway) {
        map.overlayMapTypes
          .removeAt(map.overlayMapTypes.getArray().findIndex(({ name }, index) => name === this.railwayImageMapType.name));
      }
    }

    if (isShowSatelliteChanged) {
      if (isShowSatellite) {
        map.setMapTypeId('hybrid');
        // map.setMapTypeId('satellite');
      }
      if (!isShowSatellite) {
        map.setMapTypeId('roadmap');
      }
    }

    if (['trackingmap'].includes(props.currentView)) {

      if (trackingMapData.period) {

        if (trainIdChanged || trackingMapSwitched || heatMapSwitched || dateChanged) {

          if (trainIdChanged || dateChanged || trackingMapSwitched) {
            // removing the traces of the old device id
            this.trackingmapLayer && this.trackingmapLayer.forEach(feature => {
              this.map.data.remove(feature);
            });

            if (trackingMapData && isTrackingMap) {
              this.trackingmapLayer = map.data.addGeoJson(trackingMapData);
              const posArray = [];
              map.data.forEach(feature => posArray.push(feature.getGeometry()));
              posArray.length && this.fitMapToTracks(posArray.filter(pos => pos));


              map.data.setStyle(function (feature) {
                const dashTracksColor = feature.getProperty('color');
                const source = feature.getProperty('source');
                const lineType = feature.getProperty('lineType');
                return {
                  strokeColor: dashTracksColor,
                  strokeWeight: 4,
                  strokeOpacity: 1,
                  ...source === 'model' && lineType === 'dashed'
                    ? {
                      strokeWeight: 1,
                      strokeOpacity: 0.4,
                      icons: [{
                        icon: {
                          path: 'M 0,-1 0,1',
                          strokeOpacity: 1,
                          scale: 4,
                        },
                        offset: '0',
                        repeat: '20px',
                      }],
                    }
                    : {},
                };
              });
            }
          } else if (isTrackingMap) {
            this.trackingmapLayer && this.trackingmapLayer.forEach(feature => {
              // feature.visible(isTrackingMap);
            });
          }
        }
      }

      if (heatMapData.period) {

        if (trainIdChanged || trackingMapSwitched || heatMapSwitched || dateChanged) {
          if (trainIdChanged || dateChanged) {
            if (heatMapData) {

              this.drawHeatmap(heatMapData, isHeatMap); //Drawing initial heatmap visibility depends on isHeatMap
              if (!isTrackingMap) {
                this.fitMapToTracks(heatMapData.track);
              }
            }
          } else if (heatMapSwitched) {
            this.setHeatmap(isHeatMap); // showing or hiding heatmap
          }
        }
      }
    }
  }

  /**
   * @function
   * @description The method is used update the controls features of the map such as: railway, street-view, heatmap,
   *   Traces...
   * @param {Object} data geoJson of the tracing information
   * @returns {}
   *
   */
  drawTracks(trackingMapData = this.props.trackingMapData, isTrackingMap = this.props.isTrackingMap) {
    const { gMaps } = this;

    const testerJson = [];
    const rawJson = trackingMapData.features
      .map(({ geometry }) => geometry.coordinates)
      .map(pointsArr => pointsArr.forEach(points => {
        testerJson.push({ lat: points[0], lng: points[1] });
      },
      ));
    const lineSymbol = {
      path: 'M 0,-1 0,1',
      strokeOpacity: 1,
      scale: 4,
    };

    this.trackingMap = new gMaps.maps.Polyline({
      path: testerJson,
      // strokeColor: '#FFFFFF',
      strokeColor: '#000000',
      strokeOpacity: 1.0,
      strokeWeight: 2,
      // icons: [
      //   {
      //     icon: lineSymbol,
      //     offset: "0",
      //     repeat: "20px",
      //   },
      // ],
    });

    if (!this.trackingMap) {
      // this.trackingMap.setMap(null);
      this.trackingMap = new gMaps.maps.Polyline({
        path: testerJson,
        // strokeColor: '#FFFFFF',
        strokeColor: '#000000',
        strokeOpacity: 1.0,
        strokeWeight: 2,
        // icons: [
        //   {
        //     icon: lineSymbol,
        //     offset: "0",
        //     repeat: "20px",
        //   },
        // ],
      });
    } else {
      this.trackingMap.setPath(testerJson);
    }
    this.setTrackingMap(isTrackingMap);
  }

  /**
   * @function
   * @description The method is used update the controls features of the map such as: railway, street-view, heatmap,
   *   Traces...
   * @param {Object} data geoJson of the tracing information
   * @returns {}
   *
   */

  drawHeatmap(heatMapData = this.props.heatMapData, isHeatMap = this.props.isHeatMap) {
    const { gMaps } = this;
    const rawJson = heatMapData.track.map(({ point, location, weight }) => {
      return {
        location: new gMaps.maps.LatLng(point),
        weight,
      };
    });
    if (!this.heatmap) {
      this.heatmap = new gMaps.maps.visualization.HeatmapLayer({
        data: rawJson,
        map: null,
      });
    } else {
      this.heatmap.setData(rawJson);
    }
    this.setHeatmap(isHeatMap);
  }

  /**
   * @function
   * @description The method is used update the controls features of the map such as: railway, street-view, heatmap,
   *   Traces...
   * @param {boolean} sets the status of the heatmap
   * @returns {}
   *
   */
  setHeatmap(isHeatmap = this.props.isHeatMap) {
    const { map, heatmap } = this;
    const gradient = [
      'rgba(0, 255, 255, 0)',
      'rgba(0, 255, 255, 1)',
      'rgba(0, 191, 255, 1)',
      'rgba(0, 127, 255, 1)',
      'rgba(0, 63, 255, 1)',
      'rgba(0, 0, 255, 1)',
      'rgba(0, 0, 223, 1)',
      'rgba(0, 0, 191, 1)',
      'rgba(0, 0, 159, 1)',
      'rgba(0, 0, 127, 1)',
      'rgba(63, 0, 91, 1)',
      'rgba(127, 0, 63, 1)',
      'rgba(191, 0, 31, 1)',
      'rgba(255, 0, 0, 1)',
    ];

    if (heatmap) {
      heatmap.setMap(isHeatmap ? map : null, []);
      heatmap.set('gradient', gradient);
    }
  }

  /**
   * @function
   * @description The method is used update the controls features of the map such as: railway, street-view, heatmap,
   *   Traces...
   * @param {boolean} isTrackingMap the status of the tracking-map
   * @returns {}
   *
   */
  setTrackingMap(isTrackingMap = this.props.isTrackingMap) {
    const { map, trackingMap } = this;
    if (trackingMap) {
      trackingMap.setMap(isTrackingMap ? map : null);
    }
  }

  render() {
    const { classes, targetedUserLocomotiveId, t, userLocomotiveListObject } = this.props;
    const { hoverCardPosition, trackFocusedDevice, showHoverCard, hoveCardHtml, drawerLocoId } = this.state;
    const showRecenter = !trackFocusedDevice && targetedUserLocomotiveId;
    const drawerOpen = true;
    return (
      <div style={{ height: '100%' }}>
        <div style={{ height: '100%', width: `calc(100% - ${drawerOpen ? 340 : 0} px)` }}>
          <div
            className={window.location.origin === 'http://localhost:3000' ? classes.gMapsDevHider : ''}
            ref={el => this.mapContainer = el} style={{ height: 'calc(100% - 0px)' }}
          >
          </div>

          {/*<div ref={el => this.mapContainer = el}></div>*/}
          <div className={classes.recenterButtonContainer}>
            {
              showRecenter ?
                <FVMTrackedButton
                  className={classes.recenterButton}
                  btnText={t("button.recenter")}
                  variant="contained"
                  hidden={['trackingmap'].includes(this.props.currentView)}
                  trackingInfo={{
                    category: 'Maps',
                    label: 'Recenter',
                    action: 'Open',
                  }}
                  icon={<NavigationIcon />}
                  color="primary"
                  onClick={() => {
                    this.setMapZoom();
                    this.setState({ trackFocusedDevice: true });
                    this.focusMapTo({ trackFocusedDevice: true });

                  }}
                /> : null
            }
          </div>
        </div>

        {/*<LiveDataDrawer*/}
        {/*  detailedItem={null}*/}
        {/*  open={drawerOpen}*/}
        {/*  selected={null}*/}
        {/*  catNames={()=>{}}*/}
        {/*  clearFocuedItem={()=>{}}*/}
        {/*  right*/}
        {/*  loco={drawerLocoId ? userLocomotiveListObject[drawerLocoId] : {}}*/}
        {/*  handleChange={()=>{}}*/}
        {/*  handleItemSelected={()=>{}}*/}
        {/*  t={t}*/}
        {/*/>*/}

        {
          showHoverCard
            ? (<HtmlTooltip
              open
              title={hoveCardHtml}
              ref={el => this.hoverCardContainer = el}
            >
              <span style={{ ...hoverCardPosition }}></span>
            </HtmlTooltip>)
            : null
        }
        {/*{!this.props.isParentLoading && this.state.isLoading ? <LoadingSpinner/> : null}*/}
        <Snackbar
          open={this.state.showMessage === '' ? false : true}
          autoHideDuration={6000}
          onClose={() => { }}
          severity="info"
          message={this.state.showMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        />
      </div>
    );
  }
}

const getTrainToolTip = ({ statusVehicle, live = {}, todays = {}, machineStatus, t }) => {
  return (
    <ul>
      {
        live.timestampPosition ? (
          <li><span>{t('tooltip.map.timestamp')}</span><span></span><span>{moment(new Date(live.timestampPosition)).format('DD.MM.YYYY HH:mm:ss')} </span>
          </li>
        ) : null
      }

      <li>
        <span>{t('tooltip.map.idleHours')}</span>
        <span></span>
        <span>{timeFormatTableCol(todays.idleHours, t)}</span>
      </li>
      <li>
        <span>{t('tooltip.map.drivingTime')}</span>
        <span></span>
        <span>{timeFormatTableCol(todays.drivingHours, t)}</span>
      </li>
      <li><span>{t('tooltip.map.speed')}</span><span></span><span>{live.speed} {t('units.kmPerHr')}</span></li>
      {/*<li><span>${t('tooltip.map.machineStatus')}</span><span></span><span>${statusVehicleText}</span></li>*/}
      <li><span>{t('tooltip.map.machineStatus')}</span><span></span><span>{machineStatus[statusVehicle].tooltip}</span>
      </li>
    </ul>);
};

const getCraneToolTip = ({ timestamp, live, t }) => {

  return (
    <ul>
      <li><span>{t('tooltip.map.coordinates')}</span><span></span><span>{live.position.lat} {live.position.lng}</span></li>
      <li><span>{t('tooltip.map.timestamp')}</span><span></span><span>{moment(new Date(timestamp.timestampRecentRawDataStatus)).format('DD.MM.YYYY HH:mm:ss')} </span>
      </li>
    </ul>
  );
};

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    // backgroundColor: '#f5f5f9',
    backgroundColor: '#FFFFFF',
    background: 'linear-gradient(#FFFFFF,#FFFFFF)',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: 10,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
    minWidth: 210,
    maxWidth: 900,
  },
}))(Tooltip);

const mapStateToProps = state => ({
  activeUserLocoId: [...state.activeUserLocomotiveListIds].pop() || 0,
  activeUserLocomotiveListIds: state.activeUserLocomotiveListIds || [],
  currentView: getCurrentView(), // temp test solution
  targetedUserLocomotiveId: state.targetedUserLocomotiveId,
  trackedUserLocomotiveId: state.trackedUserLocomotiveId,
  isDemoUser: state.isDemoUser || false,
  userLocomotiveListObject: state.userLocomotiveList || [],
  languageFilesReloaded: state.languageFilesReloaded,
  isShowRailway: state.showRailway,
  isShowSatellite: state.showSatellite,
  isShowRailation: state.showRailation,
  isTrackingMap: state.trackingMap,
  isHeatMap: state.heatMap,
  isLocalEnv: state.isLocalEnv,
  isInitialUserDeviceSelectionCompleted: state.isInitialUserDeviceSelectionCompleted,
  isUpdateView: state.isUpdateView,

});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    newView: changedView,
    updateUserLocomotivesList,
    showLoading: mapLoadingData,
    setFetchActiveLocoListData: setFetchActiveLocoListData,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(FVMGoogleMaps));




const getKeyValue = (obj = {}, keyString, separator = '.') => keyString.split(separator).reduce((possibleObj, key) => possibleObj?.[key], obj);