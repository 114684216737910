import React, { useEffect, useState } from 'react';
import { Avatar, Box, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import LogbookEntryState from '../core/LogbookEntryState';
import AddComment from '../addComment';
import { getLogbookEntryComments, getLogbookEntryCommentSignature } from '../../../apis/logbook-apis';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  avatar: {
    width: 32,
    height: 32,
  },
  sectionLabel: {
    marginTop: 16,
    marginBottom: 16,
  },
  chip: {
    marginLeft: 16,
    // borderRadius: 4,
  },
  resolvedEntry: {
    color: '#06DE3A',
    backgroundColor: '#06DE3A1F',
    border: '1px solid #06DE3A1F',
  },
  openEntry: {
    color: '#FF0000',
    backgroundColor: '#FF00001F',
    border: '1px solid #FF00001F',
  },
  commentHeader: {
    paddingBottom: 0,
    paddingTop: 8,
  },
  commentBody: {
    marginLeft: 48,
    paddingTop: 0,
    paddingBottom: 0,
    '&:last-child': {
      paddingBottom: 0,
    },

  },
  sigContainer: {
    width: 150,
    height: 75,
  },

  commentListContainer: {
    overflow: 'auto',
    paddingBottom: 32,
    paddingTop: 24,
    paddingLeft: 8,
    // flex: '0 0 500px',
    // maxHeight: '40vh',
    // backgroundColor: '#F8F9FA',
  },
  commenterName: {
    fontWeight: 700,
  },
  noComment: {
    padding: 20,
  },
  signatureImage: {
    maxWidth: 150,
  },
  textFieldFormControlStyles: {
    // marginTop: 8,
    // marginBottom: 8,
    // '&:first-child': {},
  },
  commentTab: {
    marginLeft: -24,
    marginRight: -24,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  addComment: {
    marginBottom: -32,
    marginTop: 'auto',
    padding: '0 8px',
    // backgroundColor: '#F8F9FA',
    boxShadow: ' 0px 0px 24px #00000029',
    borderTop: '1px solid #E0E0E0',
  },
}));


const Comments = ({ t, title, logbookEntryId, organisationId, isUserMakingComment, commentSaved, showSnackBar, user = {}, entry = {} }) => {
  const classes = useStyles();
  const [comments, setComments] = useState([]);
  const isOnline = useSelector(({ isOnline }) => isOnline);

  const fetchLatestComments = () => {
    const commentsSignatures = {};
    getLogbookEntryComments(
      organisationId,
      logbookEntryId,
    ).then(({ data }) => {
      const { items } = data;
      if (items) {
        Promise.all(items.map(commentEntry => {
          const imageGetter = (imagePlacement, logbookEntryCommentId) => {
            return getLogbookEntryCommentSignature(
              organisationId,
              logbookEntryId,
              logbookEntryCommentId,
            ).then((signatureUrl) => {
              if (signatureUrl) {
                const fileReader = new FileReader();
                fileReader.onload = e => {
                  commentEntry.signatureUrl = e.target.result;
                  // commentsSignatures[logbookEntryCommentId] = e.target.result;
                };
                fileReader.readAsDataURL(signatureUrl);
              }
            });
          };

          return imageGetter(
            commentEntry.signatureUrl,
            commentEntry.logbookEntryCommentId,
          );
        })).then((resp) => {
          setComments(items || []);
        });
      }
    }).catch((err) => {
      showSnackBar(`${t('labels.error')} (${err?.response?.status})`, 'error');
      console.dir(err);
    });
  };

  useEffect(() => {
    if (logbookEntryId && organisationId && isOnline && user.accessRights?.['logbook_entries/comments']?.canRead) {
      fetchLatestComments();
    }

  }, [logbookEntryId, organisationId]);
  useEffect(() => {
    if (commentSaved && user.accessRights?.['logbook_entries/comments']?.canRead) {
      fetchLatestComments();

    }
  }, [commentSaved]);

  return (
    <Grid container spacing={0}
      className={[classes.commentListContainer, isUserMakingComment ? 'commenting' : ''].join(' ')}>
      {title ? <Typography variant="h6" className={classes.sectionLabel}>{title}</Typography> : null}
      {comments.length
        ? [...comments].map(comment => {
          return (
            <Grid item xs={12}>
              <CardHeader
                className={classes.commentHeader}
                avatar={
                  <Avatar
                    className={classes.avatar}
                    aria-label="user-commentator-avatar"
                    src={'https://nrail.staging.fuerstvonmartin.de/wp-content/uploads/nrail_user_images/Maximilian.Mueller.jpg?1626843395740'}
                  />
                }
                title={<CommentTitle t={t} defects={!!entry?.defects?.state} classes={classes}{...comment} />}
              />
              <CardContent className={classes.commentBody}>
                <Typography variant="body1">{comment.remarks}
                </Typography>
                <div className={classes.sigContainer}>
                  {
                    comment.signatureUrl
                      ? <img className={classes.signatureImage} alt={t('labels.commentSigAltTest')}
                        src={comment.signatureUrl} />
                      : null
                  }
                </div>
              </CardContent>
            </Grid>
          );
        })
        : <Typography className={classes.noComment} variant="body1">{t('labels.noComments')}</Typography>
      }
    </Grid>
  );
};

const CommentTitle = ({ creatorAccountFirstName, creatorAccountLastName, createTimestamp, newEntryState, t, defects = false }) => {
  const classes = useStyles();
  const createdMomentDateObj = moment(createTimestamp);
  return (
    <div>
      <Typography variant="body1">
        <span className={classes.commenterName}>{`${creatorAccountFirstName} ${creatorAccountLastName}  `}</span>
        <span>{` ${createdMomentDateObj.format('DD.MM.YYYY')} um ${createdMomentDateObj.format('HH.mm')} Uhr`}</span>
      </Typography>
      {defects &&
        <div style={{ textTransform: 'capitalize', }}>
          {t('labels.defects')}:{newEntryState === 'none' ? ` ${t('labels.no')}` : <LogbookEntryState classNames={classes.chip} state={newEntryState} label={t(`labels.${newEntryState}Entry`)} />}
        </div>
      }
    </div>
  );
};


const CommentTabPanel = ({
  t,
  title = null,
  onResetForm = () => { },
  showSnackBar = () => { },
  comments,
  hidden,
  userCanComment,
  user = {},
  commentSaved,
  handleSaveComment,
  logbookEntryId,
  organisationId,
  entry = {}
}) => {
  const classes = useStyles();
  const [isUserMakingComment, setIsUserMakingComment] = React.useState(false);

  useEffect(() => {
    if (commentSaved) {
      onResetForm();
    }
  }, [commentSaved]);

  // commentSaved
  return (
    <Box
      className={`${classes.commentTab}`}
      hidden={hidden}
      key={`tab-panel-comments`}
    >
      <Comments
        t={t}
        organisationId={organisationId}
        logbookEntryId={logbookEntryId}
        title={title}
        user={user}
        entry={entry}
        showSnackBar={showSnackBar}
        commentSaved={commentSaved}
        comments={comments?.items}
        isUserMakingComment={isUserMakingComment}
      />
      {
        userCanComment && user?.accessRights?.['logbook_entries/comments'].canCreate ? (
          <div className={classes.addComment}>
            <AddComment
              t={t}
              user={user}
              entry={entry}
              commentSaved={commentSaved}
              setIsUserMakingComment={setIsUserMakingComment}
              onSaveComment={handleSaveComment}
            />
          </div>
        ) : null
      }

    </Box>
  );
};


CommentTabPanel.propTypes = {};

export default CommentTabPanel;
