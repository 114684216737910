import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import { generateTestAttribute } from '../../../utils/helperUtilities';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getUserLang } from '../../../wp-rest-api/STEC-Communication';
import { useAuthStyles } from '../authStyles';
import { useDispatch } from 'react-redux';
import { userLanguageChanged } from '../../../actions';
import { changeUserLanguage } from '../../../reducers/accounts/user';
import i18n from '../../../i18n';
import LoginForm from '../LoginForm';

/**
 * page shown to the end user
 * @component
 * @return {JSX.Element} React component
 * @example
 * return (<Login />)
 */
const Login = ({ isFrontpage = true, t, ...props }) => {
  const componentName = Login.displayName || '';
  const classes = useAuthStyles();
  const dispatch = useDispatch();


  const changeLang = lang => {
    i18n.changeLanguage(lang, resp => {
      dispatch(userLanguageChanged(lang)); // to be removed later
      dispatch(changeUserLanguage(lang));
    });
  };

  return (
    <Grid
      container
      className={isFrontpage ? `${classes.additionalWrapper}` : ''}
      {...generateTestAttribute(componentName, 'component')}
      justify="center"
      direction="column"
      alignItems="center"
      {...props}
    >
      <Grid
        justify="center"
        direction="column"
        className={isFrontpage ? `${classes.formGridContainer}` : ''}
        alignItems="center"
        spacing={2}
        container
      >
        <Grid
          item
          sx={12}
          className={isFrontpage ? classes.innerFormContainer : ''}
          direction={'column'}
        >
          <div className={classes.title}>
            <h1>{t('labels.smartData')}</h1>
            <p>{t('labels.reExperienceRailway')}!</p>
          </div>
          <LoginForm t={t} {...props} />
        </Grid>
        <Grid
          className={classes.formFooterText}
          item
          container
          sx={12}
          spacing={2}
        >
          <Grid
            className={`${classes.languageSeparator}  ${classes.centerTextAlign}`}
            xs={12}
            sm={6}
            item
          >
            <span onClick={() => changeLang('de-DE')}>
              {t('singleWords.german')}
            </span>
            <span onClick={() => changeLang('en-GB')}>
              {t('singleWords.english')}
            </span>
          </Grid>
          <Grid
            className={`${classes.rightTextAlign} ${classes.centerTextAlign}`}
            item
            xs={12}
            sm={6}
          >
            <Link to="/reset-passkey">{t('labels.forgottenPassword')}</Link>
          </Grid>
        </Grid>
        <Grid
          className={classes.formFooterText}
          item
          container
          sx={12}
          justify={'center'}
        >
          <Grid item>
            <a
              href={`https://nrail.de/${
                getUserLang() === 'de' ? '' : 'en/'
              }datenschutz`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('labels.privacyPolicy')}
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Login.propTypes = {
  /**
   * Use to tell how the component should be rendered. If true the component will render in the center
   * of the page. If false it will render left aligned.
   */
  isFrontpage: PropTypes.bool,
};

//For dynamic key, id and test attribute creation
Login.displayName = 'Login';
export default Login;
