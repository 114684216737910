import { Button, withStyles } from '@material-ui/core';

const NrlPrimaryButtonWhite = withStyles((theme) => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: '#06DE3A',
    '&:hover': {
      backgroundColor: '#06DE3A',
    },
  },
}))(Button);


export default NrlPrimaryButtonWhite;

