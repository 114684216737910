import AddIcon from '@material-ui/icons/Add';
import SyncIcon from '@material-ui/icons/Sync';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { changedView } from '../../../actions';
import ViewHeader from '../../../components/ViewHeader';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import FVMDrawer from '../../../components/FVMDrawer';
import LogBookTable from '.././LogbookTable';
import {
  getVehicleMetadataInOrganisation,
} from '../../../apis/logbook-apis';
import Toolbar from '@material-ui/core/Toolbar';
import { Box, Button, List, ListItem } from '@material-ui/core';
import FVMTrackedButton from '../../../components/TrackedButton';
import ReactPaginate from 'react-paginate';
import DialogTwoButtons from '../../../components/Dialogs/DialogTwoButtons';
import LoadingSpinner from '../../../components/LoadingSpinner';
import LogbookEntryForm from '../../../components/Logbook/LogbookEntryForm';
import { createNewEntry } from '../../../components/Logbook/LogbookEntryForm/helper';
import LogbookEntryState from '../../../components/Logbook/core/LogbookEntryState';
import {
  getCollectionByOrganisationId,
  markLogbookEntriesForDeletion, syncLogbookEntries,
} from '../../../components/Logbook/logbook-utils/utils';
import { buildTableColumns } from './logbook-page-helpers';

const styles = theme => ( {
  headerWrapperClass: {
    '& th:nth-child(3)': {
      width: 216,
    }
  },
  fvmTableData: {
    height: 'calc(100vh - 370px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 370px)',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 410px)',
    },
    border: ' 1px solid #dfe3e6',
    borderLeft: 0,
    overflow: 'auto',
    '& div > table': {
      minWidth: 2100,
      '& > thead > tr > th ': {
        position: 'sticky',
        top: 0,
        background: '#F0F3F6',
        boxShadow: 'inset 0.5px -1px 0 #dfe3e6',

      },

      '& td ': {
        boxShadow: 'inset 0.5px -0.5px 0 #dfe3e6',
      },

      '& > thead > tr > th, & > thead > tr ': {
        whiteSpace: 'nowrap',
        border: 0,
      },

      '& > thead > tr > th[data-row-selection]': {
        width: 48,
      },
    },
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: -15,
  },
  logbookHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > header': {
      minWidth: 260,
    },
  },
  articleClass: {
    position: 'relative',
  },

  clickableTableItem: {
    cursor: 'pointer',
    color: 'blue',
  },
  lastSyncedTs: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  bootStrapTablePagination: {
    '& ul li a': {
      textAlign: 'center',
      minHeight: 40,
      minWidth: 40,
      margin: '12px 2.5px',
      backgroundColor: '#ffffff',
      color: theme.palette.primary.main,
      fontSize: 16,
      border: `${theme.palette.primary.main} solid 1px`,
      '& a': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: '#ffffff',
        border: `${theme.palette.primary.main} solid 1px`,
      },
    },
    '& ul': {
      marginBottom: 0,
      justifyContent: 'center',
      '& .page-item.active .page-link': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: '#ffffff',
        border: `${theme.palette.primary.main} solid 1px`,
      },
    },
  },

  exportAllPagesOptions: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '53px',
    padding: 16,
    '& > button': {
      marginRight: 8,
    },
    '& > p': {
      marginBottom: 0,
    },
  },
  exportModal: {
    width: 435,
    maxHeight: 435,
  },
} );

/**
 *
 */
class LogbookDrafts extends Component {
  constructor(props) {
    super(props);
    const { t, classes } = this.props;

    const savedLogbookSettings = this.getUserSettings();
    this.state = {
      liveDataStaticCol: buildTableColumns({
        t, classes, openLogEntryById: this.openLogEntryById, userLocoListObj: this.props.userLocomotiveListObject,
      }),
      entriesMap: {},
      selectedTableItem: {},
      topNumberOfEntries: 15,
      skip: 0,
      logbookEntryId: null,
      showUserInteractionDialog: false,
      showUserInteractionDialogButtonLabels: { confirm: t('button.yes'), cancel: t('button.no') },
      userInteractionAcceptCallback: () => {
      },
      userInteractionDialogTitle: null,
      userInteractionDialogText: null,
      isDrawerOpen: false,
      isEntryModified: false,
      isLoading: false,
      lastInspectionPerformedTimestamp_A1: null,
      isDialogOpen: false,
      activeUserLocoId: props.activeUserLocoId,
      name: props.name,
      logbook: {
        columns: buildTableColumns({
          t, classes, openLogEntryById: this.openLogEntryById, userLocoListObj: this.props.userLocomotiveListObject,
        }),
        data: [],
      },
      isFormActive: false,
      tableData: [],
      comments: { items: [] },
      orderby: savedLogbookSettings?.orderby || ['createTimestamp desc'],
      totalNumberOfSelectEntries: 0,
      isSelectALlEntries: 0,
    };
  }

  componentDidMount() {
    if (Object.keys(this.props.userLocomotiveListObject).length && this.props.user?.activeOrganisationId) {
      syncLogbookEntries({}).then((resp) => {
        // if(resp === 'system_is_offline'){
        //   console.log('system_is_offline');
        //   this.handleGetEntries();
        // }
      }).finally(() => {
        this.handleGetEntries();
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeUserLocoId, name, activeOrganisationId, user, lastLogbookSyncedTimeStamp } = this.props;
    if (lastLogbookSyncedTimeStamp !== prevProps.lastLogbookSyncedTimeStamp) {
      this.handleGetEntries(undefined, true);
    }

    if (
      prevProps.activeUserLocoId !== activeUserLocoId ||
      ( ( prevProps.activeOrganisationId !== activeOrganisationId ) && Object.keys(this.props.userLocomotiveListObject).length )
    ) {
      this.setState({ activeUserLocoId });
      this.setState({ name });
      this.setState({ tableData: [] });
      this.setState({ selectedTableItem: {} });
      this.handleLanguageChanged(user.settings.language);
      this.setState({ skip: 0 });
      this.handleGetEntries();
      this.setState({
        totalNumberOfSelectEntries: 0,
        isSelectALlEntries: false,
        isOnAllPagesSelectALlEntries: false,
        selectedTableItem: {},
      });
    }
    if (user.settings.language !== prevProps.user.settings.language) {
      this.handleLanguageChanged(user.settings.language);
    }

    if (
      Object.keys(this.props.userLocomotiveListObject).length !==
      Object.keys(prevProps.userLocomotiveListObject).length
    ) {
      this.handleGetEntries();
    }

    if (user.accessRights !== prevProps.user.accessRights) {
      // console.log(user.accessRights)
    }
  }

  componentWillUnmount() {
    clearInterval(this.updaterId);
  }

  closeModal = () => {
    this.setState({ isFormActive: false, isDialogOpen: false });
  };

  handleLanguageChanged = languageFilesReloaded => {
    this.setState({
      liveDataStaticCol: buildTableColumns({
        t: this.props.t,
        classes: this.props.classes,
        openLogEntryById: this.openLogEntryById,
        userLocoListObj: this.props.userLocomotiveListObject,
      }),
    });

    this.setState({
      tableData: this.state.tableData.map(
        ({ header, defects, stoppage, lastComment, vehicleState }) => ( {
          ...header,
          header,
          defects,
          stoppage,
          lastComment,
          vehicleState,
          language: languageFilesReloaded,
        } ),
      ),
    });
  };

  openLogEntryById = async e => {
    e.stopPropagation();
    this.setState({ commentsSignatures: {} });
    this.setState({ comments: { items: [] } });
    if (e.target.getAttribute('data-entry-id')) {
      this.updateActiveEntryId(e.target.getAttribute('data-entry-id'));
    }
  };

  updateActiveEntryId = (logbookEntryId) => {
    this.setState({ logbookEntryId });
    const activeEntry = logbookEntryId && this.state?.entriesMap?.[logbookEntryId];
    if (activeEntry) {
      this.setState({ isDrawerOpen: true, activeEntry });
    } else {
      this.closeDrawer();
    }

  };

  closeDrawer = () => {
    this.setState({ isDrawerOpen: false });
    this.setState({ logbookEntryId: null });
    this.setState({
      showUserInteractionDialog: false,
      showUserInteractionDialogButtonLabels: {
        confirm: this.props.t('button.yes'),
        cancel: this.props.t('button.no'),
      },
      userInteractionAcceptCallback: () => {
      },
      userInteractionDialogTitle: null,
      userInteractionDialogText: null,
    });
    this.setState({ isEntryModified: false });
  };

  handleCloseFormDrawer = (ignoreEntryModified = false) => {
    if (this.state.isEntryModified || !ignoreEntryModified) {
      this.setState({
        showUserInteractionDialog: true,
        userInteractionAcceptCallback: () => this.closeDrawer(true),
        userInteractionDialogTitle: this.props.t('logbook.labels.unsavedChangesTitle'),
        showUserInteractionDialogButtonLabels: {
          confirm: this.props.t('button.yes'),
          cancel: this.props.t('button.no'),
        },
        userInteractionDialogText: this.props.t('logbook.labels.unsavedChangesBody'),
      });
      return;
    }
    this.closeDrawer(false);
  };

  updateUserSettings(newLogbookSettings) {
    let logbookSettings = this.getUserSettings();
    window.localStorage.setItem('logbookSettings', JSON.stringify({ ...logbookSettings, ...newLogbookSettings }));
  }

  getUserSettings() {
    let logbookSettings = {};
    const logbookJson = window.localStorage.getItem('logbookSettings');
    if (logbookJson) {
      logbookSettings = JSON.parse(logbookJson);
    }
    return logbookSettings;
  }

  handleTableSort = (type, { sortField, sortOrder }) => {
    const adjustedSortField = sortField.replace('.', '_');

    const orderby = [`${adjustedSortField} ${sortOrder}`];
    this.setState({ orderby });
    this.updateUserSettings({ orderby });
    this.handleGetEntries();
  };

  handleGetEntries = (isSync = false, silentUpdate = false, newActiveId) => {
    if (!silentUpdate) {
      this.showSpinner(isSync ? 0 : undefined);
    }

    let entriesMap = {};
    const loadEntries = (resp) => {
      const locallyStoredEntries = getCollectionByOrganisationId(this.props?.activeOrganisationId, this.props.activeUserLocoId || null);
      entriesMap = {
        ...locallyStoredEntries,
      };

      this.setState({ entriesMap, pagination: {} });
      this.setState({
        tableData: Object.values(entriesMap).map(
          ({ header, ...rest }) => ( {
            ...header,
            header,
            ...rest,
            language: this.props.languageFilesReloaded,
          } ),
        ),
      });

      if (newActiveId) {
        this.updateActiveEntryId(newActiveId);
      }

      if (this.state.isOnAllPagesSelectALlEntries) {
        const selectedTableItem = { ...this.state.selectedTableItem };
        Object.keys(this.state.entriesMap).forEach(
          itemKey => {
            if (selectedTableItem[itemKey] === undefined) {
              selectedTableItem[itemKey] = true;
            }
          });
        this.setState({ selectedTableItem });
      }
      if (this.props?.activeUserLocoId && this.props?.activeOrganisationId) {
        getVehicleMetadataInOrganisation(this.props.activeOrganisationId, undefined, undefined, `vehicleId eq ${this.props.activeUserLocoId}`).then(({ items }) => {
          this.handleLastInspectionPerformedTimestamp_A1Changed(items[0]?.lastInspectionPerformedTimestamp_A1 || null);
        });
      }
    };
    loadEntries();
    this.hideSpinner(isSync ? 1500 : undefined);

  };

  showSpinner = (timeOutToShow = 500) => {
    const spinnerTimeoutID = setTimeout(
      () => this.setState({ isLoading: true }),
      timeOutToShow,
    );
    this.setState({ spinnerTimeoutID });
    return spinnerTimeoutID;
  };

  hideSpinner = (
    spinnerTimeoutID = this.state.spinnerTimeoutID,
    timeOutToShow = 500,
  ) => {
    spinnerTimeoutID && clearTimeout(this.state.spinnerTimeoutID);
    setTimeout(() => this.setState({ isLoading: false }), timeOutToShow);
  };

  showSnackBar = (message, variant, duration = 3000) => {
    this.props.enqueueSnackbar(message, {
      variant: variant,
      autoHideDuration: duration,
    });
  };

  handleLastInspectionPerformedTimestamp_A1Changed = (a1Date) => {
    this.setState({ lastInspectionPerformedTimestamp_A1: a1Date });
  };

  handleIsEntryModified = (val = true) => {
    this.setState({ isEntryModified: val });
  };

  syncEntries = (isSync = true) => {
    this.showSpinner(isSync ? 0 : undefined);
    syncLogbookEntries()
      .catch((err) => {
        console.dir(err);
        this.showSnackBar(`${this.props.t('labels.error')} (${err?.response?.status})`, 'error');
      })
      .finally(() => {
        this.hideSpinner(isSync ? 1500 : undefined);
      });
  };

  render() {
    const {
      isLoading,
      showUserInteractionDialog,
      userInteractionDialogTitle,
      userInteractionDialogText,
      showUserInteractionDialogButtonLabels,
      userInteractionAcceptCallback,
      activeUserLocoId,
      isDrawerOpen,
      entriesMap,
      logbookEntryId,
      comments,
      commentsSignatures,
      pagination,
      topNumberOfEntries,
      activeEntry,
      selectedTableItem,
      tableData,
      liveDataStaticCol,
    } = this.state;

    const totalNumberSelected = Object.values(selectedTableItem).filter((item) => !!item).length;
    const { t, classes, locale, user } = this.props;
    const { vehicleIdRef } =
      logbookEntryId && entriesMap[logbookEntryId]
        ? entriesMap[logbookEntryId].header
        : {};
    const { image, name } = this.props.userLocomotiveListObject[activeUserLocoId || vehicleIdRef]?.vehicle || {};
    const headerHeight = 57;
    return (
      <article className={classes.articleClass}>
        <Toolbar className="fvm-map-page-toolbar">
          <List>
            <ListItem>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => this.syncEntries(true)}
                disabled={!this.props.isOnline}
                startIcon={<SyncIcon/>}
              >
                Sync
              </Button>
            </ListItem>
          </List>

          {/*<Chip label={'sync'}/>*/}
          <List>

            {
              user?.accessRights?.logbook_entries?.canCreate ? (
                <ListItem>
                  <FVMTrackedButton
                    trackingInfo={{
                      category: 'Logbook',
                      label: 'New Logbook Entry',
                      action: 'Open',
                    }}
                    disabled={!activeUserLocoId}
                    icon={<AddIcon/>}
                    btnText={t('button.addNewLogbookEntry')}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    onClick={() => {
                      this.setState({
                        isDrawerOpen: true,
                        activeEntry: createNewEntry({
                          header: {
                            creatorAccountLastName: user?.firstName,
                            creatorAccountFirstName: user?.lastName,
                            commentsCount: 0,
                          },
                        }),
                      });
                    }}
                  />
                </ListItem>
              ) : null
            }
          </List>
        </Toolbar>
        <div className={classes.logbookHeaderContainer}>
          <ViewHeader
            title={`${t('navigationMenu.logbook')} - ${t('navigationMenu.logbookDraftEntries')}`}
            name={this.props.userLocomotiveListObject[activeUserLocoId]?.vehicle?.name || t('labels.noDeviceSelected')}
            rightAlignChild={
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={() => {
                  this.setState({
                    showUserInteractionDialog: true,
                    userInteractionDialogTitle: this.props.t(`logbook.labels.deleteEntryTitle_${totalNumberSelected > 1 ? 'other' : 'one'}`, { count: totalNumberSelected }),
                    userInteractionDialogText: this.props.t(`logbook.labels.confirmDeletionOfSelectedEntry_${totalNumberSelected > 1 ? 'other' : 'one'}`, { count: totalNumberSelected }),
                    showUserInteractionDialogButtonLabels: {
                      confirm: this.props.t('button.delete'),
                      cancel: this.props.t('button.cancel'),
                      color: 'secondary',
                    },
                    userInteractionAcceptCallback: () => markLogbookEntriesForDeletion(
                      this.props.activeOrganisationId,
                      Object
                        .keys(this.state.selectedTableItem)
                        .filter((key) => this.state.selectedTableItem[key]),
                      (response) => {
                        const responseMap = {
                          'only_deleted_locally': {
                            label: `logbook_entries_${response}`,
                            variant: 'success'
                          },
                           'successfully_deleted': {
                            label: `logbook_entries_${response}`,
                            variant: 'success'
                          },
                          'err_already_finalized': {
                            label: response,
                            variant: 'error'
                          },
                          'err_forbidden_request': {
                            label: response,
                            variant: 'error'
                          },
                          'err_multiple_error_occurred': {
                            label: response,
                            variant: 'error'
                          },
                          'err_unknown': {
                            label: response,
                            variant: 'error'
                          },

                          'new_login_required': {
                            label: response,
                            variant: 'error'
                          }
                        }

                        const {label, variant}  =   responseMap[typeof response === 'string' ? response: 'err_unknown'] || responseMap.err_unknown;
                          this.showSnackBar(
                            t(
                              `labels.${label}`,
                              { count: Object.keys(this.state.selectedTableItem).length }),
                            variant);

                          if(variant === 'success'){
                            this.setState({ selectedTableItem: {} });
                            this.handleGetEntries(true);
                          }
                      },
                    ),
                  });

                }}
                disabled={!totalNumberSelected}
              >
                {t('button.delete')}
              </Button>
            }
          />
        </div>
        <PaginationProvider
          pagination={paginationFactory({
            custom: true,
            sizePerPage: 15,
            totalSize: tableData.length,
          })}
        >
          {
            ({ paginationProps, paginationTableProps }) => (
              <>
                <LogBookTable
                  data={tableData}
                  columns={liveDataStaticCol}
                  className={classes.fvmTableData}
                  baseProps={{
                    // remote: { sort: true },
                    headerWrapperClasses: classes.headerWrapperClass,
                    noDataIndication: () => t('labels.noEntriesFound'),
                    onTableChange: this.handleTableSort,
                    selectRow: {
                      mode: 'checkbox',
                      selected: Object.keys(this.state.selectedTableItem).filter(key => this.state.selectedTableItem[key]),
                      onSelect: (row, isSelect) => {
                        const selectedTableItem = { ...this.state.selectedTableItem };
                        selectedTableItem[row.logbookEntryId] = isSelect;
                        this.setState({
                          selectedTableItem,
                          isSelectALlEntries: ( Object.keys(selectedTableItem).length === pagination?.foundCount ),
                          totalNumberOfSelectEntries: this.state.totalNumberOfSelectEntries + ( isSelect ? 1 : -1 ),
                        });
                      },

                      onSelectAll: (isSelect, rows) => {
                        const selectedTableItem = { ...this.state.selectedTableItem };
                        rows.forEach(({ logbookEntryId }) => {
                          selectedTableItem[logbookEntryId] = isSelect;
                        });

                        let totalSelectedInCurrentList = 0;
                        Object.keys(this.state.entriesMap).forEach(entryId => {
                          if (this.state.selectedTableItem[entryId]) {
                            totalSelectedInCurrentList += 1;
                          }
                        });

                        const differenceInNumberOfSelectEntries = this.state.totalNumberOfSelectEntries - totalSelectedInCurrentList;
                        const totalNumberOfSelectEntries = differenceInNumberOfSelectEntries + ( isSelect ? Object.keys(this.state.entriesMap).length : 0 );

                        this.setState({
                          selectedTableItem: totalNumberOfSelectEntries ? selectedTableItem : {},
                          totalNumberOfSelectEntries,
                          isOnAllPagesSelectALlEntries: totalNumberOfSelectEntries ? this.state.isOnAllPagesSelectALlEntries : false,
                          isSelectALlEntries: totalNumberOfSelectEntries ? ( Object.keys(selectedTableItem).length === pagination?.foundCount ) : false,
                        });
                      },
                    },
                    bordered: false,
                    ...paginationTableProps,
                  }}
                  keyField="logbookEntryId"
                />
                <div className={classNames(classes.bootStrapTablePagination)}>
                  <div className={classNames(classes.lastSyncedTs)}>
                    {t('labels.lastSyncedTimestamp')}: {this.props?.lastLogbookSyncedTimeStamp ? new Date(this.props?.lastLogbookSyncedTimeStamp).toLocaleTimeString(user.settings.language, {
                    year: 'numeric',
                    // month: "short",
                    month: 'long',
                    day: 'numeric',
                  }) : ''}
                  </div>
                  <PaginationListStandalone
                    {...paginationProps}
                  />

                </div>
              </>
            )
          }
        </PaginationProvider>

        <FVMDrawer
          isOpen={isDrawerOpen && activeEntry}
          drawerWidth={`${100}%`}
          height={`calc(100vh - ${headerHeight}px)`}
          maxWidth={868}
          drawerPadding={0}
          drawerHeaderPadding="10px 24px"
          onClose={this.handleCloseFormDrawer}
          title={<p>
            {t('drawer.title.logbook')} {
            entriesMap[logbookEntryId]?.header?.status === 'finalized' || entriesMap[logbookEntryId]?.signatureImage
              ? <LogbookEntryState state="finalized" size="small" label={t('logbook.labels.finalized')}/>
              : <LogbookEntryState state="draft" size="small" label={t('logbook.labels.started')}/>
          }
          </p>}
          headerVariant="h6"
        >
          <LogbookEntryForm
            locale={locale}
            isOnline={this.props.isOnline}
            locoImgUrl={image}
            t={this.props.t}
            locoName={name || activeEntry?.header.vehicleIdRef || 'Missing Vehicle Ref'}
            lastInspectionPerformedTimestamp_A1={this.state.lastInspectionPerformedTimestamp_A1}
            closeDrawer={this.handleCloseFormDrawer}
            comments={comments}
            organisationId={this.props.activeOrganisationId}
            setIsFormModified={this.handleIsEntryModified}
            updateActiveEntryId={this.updateActiveEntryId}
            entry={activeEntry}
            responseIndicator={this.showSnackBar}
            updateLogbookEntriesLst={(newActiveId) => this.handleGetEntries(undefined, undefined, newActiveId)}
            activeLocoId={activeUserLocoId}
          />
        </FVMDrawer>
        {showUserInteractionDialog ? (
          <DialogTwoButtons
            classes={{
              paper: classes.exportModal,
            }}
            id="entry-for-decision"
            title={userInteractionDialogTitle}
            dialogText={userInteractionDialogText}
            t={t}
            keepMounted
            value={true}
            open={showUserInteractionDialog}
            buttonLabels={showUserInteractionDialogButtonLabels}
            onClose={() => {
              this.setState({ showUserInteractionDialog: false });
            }}
            onSelectionMade={userInteractionAcceptCallback}
          />
        ) : null}
        {isLoading && this.props.isOnline ? <LoadingSpinner/> : null}
      </article>
    );
  }
}

LogbookDrafts.propTypes = {
  newView: PropTypes.func.isRequired,
  showDialog: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  activeUserLocoId: PropTypes.number.isRequired,
  appConsts: PropTypes.object.isRequired,
  isDemoUser: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ( {
  selectTro: state.targetedUserLocomotiveId || 0,
  activeUserLocoId: [...state.activeUserLocomotiveListIds].pop() || 0,
  isDemoUser: state.isDemoUser || false,
  lastLogbookSyncedTimeStamp: state.lastLogbookSyncedTimeStamp,
  locListUpdated: state.locListUpdated,
  userLocomotiveListObject: state.userLocomotiveList,
  activeOrganisationId: state.user.user.activeOrganisationId,
  isOnline: state.isOnline,
  user: state.user.user,
} );

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      newView: changedView,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LogbookDrafts));
