const FUEL_CONSUMPTION_DEFAULTS = {
  isActive: true,
  usageTypePercentage: 50,
  longDistance: {
    usagePercentage: 50,
    light: 0,
    heavy: 0,
  },
  shortDistance: {
    usagePercentage: 50,
    light: 0,
    heavy: 0,
  },
  standingWithEngRunning: 0,
  totalLetersPerKm: 0,
};

export {
  FUEL_CONSUMPTION_DEFAULTS,
};


