import { List, ListItem } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import GetApp from '@material-ui/icons/GetApp';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { changedView } from '../actions';
import LoadingSpinner from '../components/LoadingSpinner';
import NotificationSettings from '../components/NotificationSetting';
import FVMDrawer from '../components/FVMDrawer';
import NrailBootstrapTable from '../components/NrailBootstrapTable/index';
import SelectionDrawer from '../components/SelectionDrawer';
import FVMTrackedButton from '../components/TrackedButton';
import ViewTitle from '../components/ViewTitle';
import MapControls from '../containers/MapControls';
import { getCurrentView } from '../StatelessFunctions/nummericManapulation';
import { ExcelExporter } from '../utils/FVMExport';
import { fetchSTECNotifications } from '../wp-rest-api/STEC-Communication';
import ReactPaginate from 'react-paginate';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import TrainIcon from '@material-ui/icons/TrainOutlined';

const buildTableHead = (t, userLocomotiveListObject, apiSort) => {

  return [
    {
      dataField: 'timestamp',
      text: t('tables.tHead.date'),
      sort: true,
      // sortFunc: (a, b, order) =>  apiSort(`timestamp ${order}`),
      formatter: cell => `${moment(cell).format('DD.MM.YYYY, HH:mm:ss')}`,
    },
    {
      dataField: 'vehicleId',
      text: t('tables.tHead.locomotive'),
      // formatter: cell => `${cell} dd`,
      formatter: cell => `${userLocomotiveListObject[cell].vehicle.name}`,
      sort: true,
      // sortFunc: (a, b, order) => apiSort(`name ${order}`),
    },
    {
      dataField: 'type',
      text: t('tables.tHead.notificationType'),
      formatter: cell => `${cell}`,
      // sortFunc: (a, b, order) => apiSort(`type ${order}`),
      sort: true,
    },
    {
      dataField: 'note',
      text: t('tables.tHead.notes'),
    },
    {
      dataField: 'location',
      text: t('tables.tHead.eventLocation'),
      formatter: cell => `${cell}`,
      sort: true,
      // sortFunc: (a, b, order) => apiSort(`location ${order}`),
    }];
};

/**
 *
 */
class Notification extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;

    this.LiveDataStaticCol = [
      {
        dataField: 'NotificationDate',
        text: t('tables.tHead.date'),
        sort: true,
        formatter: cell => `${moment(cell).format('DD.MM.YYYY, HH.mm.ss')}`,
      },
      {
        dataField: 'vehicleId',
        text: t('tables.tHead.locomotive'),
        formatter: cell => `${this.props.userLocomotiveListObject[cell].vehicle.name}`,
        sort: true,
      },
      {
        dataField: 'NotificationType',
        text: t('tables.tHead.notificationType'),
        formatter: cell => `${cell}`,
        sort: true,
      }];

    this.state = {
      orderBy: 'timestamp desc',
      isLoading: false,
      liveDataTableData: [],
      activeUserLocomotiveListIds: [...props.activeUserLocomotiveListIds],
      activeUserLocoId: props.activeUserLocoId,
      userLocomotiveListObject: { ...props.userLocomotiveListObject },
      pagination: {
        totalCount: 0,
        page: 1,
        totalPages: 16,
        pagingCounter: 0,
        hasPrevPage: false,
        hasNextPage: false,
        prevPage: null,
        nextPage: null,
      },
    };

    this.commonClassNames = 'section-heading';
    // this.updateTable = this.updateTable.bind(this);
    // this.fetchNewData = this.fetchNewData.bind(this);
    // props.newView();
  }

  componentDidMount() {
    // this.props.newView();
    if (this.props.period && this.props.activeUserLocomotiveListIds.length) {
      this.updateLoadingState(true);
      this.fetchNewData(this.props.period, this.props.activeUserLocomotiveListIds);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      period,
      activeUserLocomotiveListIds,
      activeUserLocoId,
      languageFilesReloaded,
    } = this.props;

    const locoChanged = activeUserLocomotiveListIds.join(',') !== prevProps.activeUserLocomotiveListIds.join(',');

    if (period && activeUserLocomotiveListIds.length) {
      if (period.startDate.format('YYYYMMDD') !== prevProps.period.startDate.format('YYYYMMDD') ||
        period.endDate.format('YYYYMMDD') !== prevProps.period.endDate.format('YYYYMMDD') ||
        locoChanged
      ) {
        this.fetchNewData(period, activeUserLocomotiveListIds);
      }
    }
    if (prevProps.languageFilesReloaded !== languageFilesReloaded) {
      this.setState({ componentLanguageUpdateTrigger: languageFilesReloaded });
    }
    if (locoChanged) {
      this.setState({
        activeUserLocomotiveListIds: [...activeUserLocomotiveListIds],
        activeUserLocoId: activeUserLocoId,
      });
    }
    if (!activeUserLocomotiveListIds.length && prevProps.activeUserLocomotiveListIds.length) {
      this.updateTable([]);

    }

  }

  fetchNewData = async (period, activeUserLocomotiveListIds, page = 1, orderBy = this.state.orderBy) => {
    const { isDemoUser, userSessionID } = this.props;
    const currentView = getCurrentView();

    if (activeUserLocomotiveListIds.length) {
      this.updateLoadingState(true);
      fetchSTECNotifications({
        vehicleIds: activeUserLocomotiveListIds,
        orderBy: orderBy,
        currentView,
        isDemoUser,
        // page,
        top: 50,
        skip: 30,
        userSessionID, ...period,
      }).then(resp => {
        const { items, pagination } = resp;
        this.updateTable(items ? items : []);
        this.setState({ pagination });
      }).finally(() => this.updateLoadingState(false));
    }
  };

  fetchExportData(period, activeUserLocomotiveListIds, page = 1) {
    const { isDemoUser, userSessionID } = this.props;
    const currentView = getCurrentView();

    if (activeUserLocomotiveListIds.length) {
      return fetchSTECNotifications({
        vehicleIds: activeUserLocomotiveListIds,
        orderBy: 'timestamp desc',
        currentView,
        isDemoUser,
        // page,
        top: 8000000,
        skip: 30,
        userSessionID,
        ...period,
      }).then(resp => {
        const { items } = resp;
        return items;
      });
    }
  };

  updateTable = (newNotifications = []) => {
    this.setState({
      liveDataTableData: newNotifications,
    });
    this.updateLoadingState(false);
  };


  updateLoadingState(status, time = 0) {
    setTimeout(() => {
      this.setState({ isLoading: status });
    }, time);
  }

  closeNotificationExport = () => this.setState({ isOpenNotExportDrawer: false });

  sortTable = (orderBy) => {
    this.setState({ orderBy });
    this.fetchNewData(this.props.period, this.props.activeUserLocomotiveListIds, 1, orderBy);
  };

  render() {
    const { liveDataTableData, isLoading, isOpen, isOpenNotExportDrawer, pagination } = this.state;
    const { t, appConsts, userLocomotiveListObject } = this.props;
    const columns = buildTableHead(t, userLocomotiveListObject, this.sortTable);
    const vehicleIdArray = Object.keys(this.props.userLocomotiveListObject);
    const data = vehicleIdArray.map((vehicleId) => {
      const { name, image } = this.props.userLocomotiveListObject[vehicleId]?.vehicle || {};
      return { name, id: Number(vehicleId), image };
    });

    console.log(this.props.period)

    return (

      <article className="fvm-page fvm-page-notifications">
        <div className="fvm-map-page-toolbar-container">
          <Toolbar
            className="fvm-map-page-toolbar"
          >
            <MapControls t={this.props.t} />
            <List>
              <ListItem>
                <FVMTrackedButton
                  trackingInfo={{
                    category: 'Notifications Export',
                    label: 'Notifications Export Drawer Opened',
                    action: 'Open',
                  }}
                  icon={<GetApp />}
                  btnText={t('button.export')}//Must adjust
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={() => {
                    this.setState({ isOpenNotExportDrawer: true });
                  }}
                />
              </ListItem>
              {
                this.props.isDevelopment ? (
                  <ListItem disableGutters>
                    <FVMTrackedButton
                      trackingInfo={{
                        category: 'Notifications Settings',
                        label: 'Notifications Settings Drawer Opened',
                        action: 'Open',
                      }}
                      icon={<SettingsIcon />}
                      btnText={t('button.notificationSettings')}
                      variant="outlined"
                      color="primary"
                      size="medium"
                      onClick={() => {
                        this.setState({ isOpen: true });
                      }}
                    />
                  </ListItem>
                ) : null
              }

              <ListItem disableGutters>
                <FVMTrackedButton
                  trackingInfo={{
                    category: 'Notifications Settings',
                    label: 'Notifications Settings Quick Export',
                    action: 'Open',
                  }}
                  btnText="Quick Export"
                  variant="outlined"
                  disabled={!liveDataTableData.length}
                  hidden={!this.props.isDevelopment}
                  color="primary"
                  size="medium"
                  onClick={
                    async () => {
                      this.setState({ isExportOption: false }); // closing modal
                      const rawData = await this.fetchExportData(this.props.period, this.props.activeUserLocomotiveListIds);
                      const fetchedData = {};
                      this.props.activeUserLocomotiveListIds.forEach(vehicleId => {
                        fetchedData[vehicleId] = [];
                      });

                      if (rawData) {
                        rawData.forEach(notObj => {
                          const { vehicleId, timestamp, location, type, note } = notObj;
                          const newNotObj = { date: timestamp, notificationType: type, eventLocation: location, notes: note };
                          fetchedData[vehicleId] ? fetchedData[vehicleId].push(newNotObj) : fetchedData[vehicleId] = [newNotObj];
                        });
                        ExcelExporter({
                          fetchedData: fetchedData,
                          userLocomotiveListObject,
                          t,
                          userDateFormat: 'DD.MM.YYYY HH:mm:ss',
                          isStandardExport: false,
                          isNotifications: true,
                        });
                      }
                    }}
                />

              </ListItem>
            </List>
          </Toolbar>
        </div>
        <div className="fvm-general-page-content">

          <header className="fvm-page-header ">
            <ViewTitle title={t('navigationMenu.notifications')} inline />
            <div className="fvm-action-bar">
              {/*<Chip*/}
              {/*  label={name || ''}*/}
              {/*  avatar={*/}
              {/*    <Avatar>*/}
              {/*      <TrainIcon/>*/}
              {/*    </Avatar>*/}
              {/*  }*/}
              {/*  className="fvm-locomotive-chip"*/}
              {/*/>*/}
            </div>
          </header>
          <section className="fvm-sp fvm-sp-notifications container-fluid">
            <div className="col">
              <div className="col">
                {
                  columns
                    ? (
                      <NrailBootstrapTable
                        keyField="Id"
                        className={this.commonClassNames + ' notification-table'}

                        baseProps={{
                          remote: { sort: true },
                          onTableChange: (type, { sortField, sortOrder, data }) => {
                            const field = {
                              vehicleId: 'nameLocomotive',
                            };
                            const order = sortOrder === 'desc' ? ' desc' : '';
                            this.sortTable(`${field[sortField] || sortField}${order}`);
                          },

                        }}
                        columns={columns}
                        data={liveDataTableData}

                      />
                    ) : null
                }

              </div>
              {
                liveDataTableData.length
                  ? (
                    <div className="pagination-container">
                      <ReactPaginate
                        pageCount={pagination.totalPages}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={({ selected }) => {
                          if (selected >= 0) {
                            this.fetchNewData(this.props.period, this.props.activeUserLocomotiveListIds, selected + 1);
                          }
                        }}
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        containerClassName={'fvm-pagination-lst'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'fvm-pagination-active'}
                      />
                    </div>

                  ) : null
              }
            </div>
          </section>
        </div>
        {isLoading ? <LoadingSpinner /> : null}
        {/*<ReactTooltip className="fvm-tooltip" />*/}
        <FVMDrawer
          isOpen={isOpen}
          height={'calc(100vh - 81px - 300px)'}
          onClose={() => {
            this.setState({ isOpen: false });
          }}
          title={t('navigationMenu.notificationSettings')}
        >
          <NotificationSettings
            appConsts={appConsts}
            hideTitle={true}
            userLocomotiveListObject={userLocomotiveListObject}
            singleSelect={false}
            onClose={() => this.setState({ isOpen: false })}
            t={t}
          />
        </FVMDrawer>
        {
          isOpenNotExportDrawer
            ? <SelectionDrawer
              isOpen={isOpenNotExportDrawer}
              onClose={() => this.setState({ isOpenNotExportDrawer: false })}
              gridData={data}
              period={this.props.period}
              title={t('drawer.title.locoToApplyNotificationSettingsTo')}
              selectedItems={[...this.props.activeUserLocomotiveListIds]}
              actionButtonsArray={[
                {
                  disabled: this.props.isDemoUser,
                  name: t('button.export'),
                  color: 'primary',
                  onClick: async (data) => {
                    this.setState({ isExportOption: false }); // closing modal
                    const rawData = await this.fetchExportData(data.period, data.selectedItems);
                    const fetchedData = {};
                    console.log(rawData)
                    data.selectedItems.forEach(vehicleId => {
                      fetchedData[vehicleId] = [];
                    });

                    if (rawData) {
                      rawData.forEach(notObj => {
                        const { vehicleId, timestamp, location, type, note } = notObj;
                        const newNotObj = { date: timestamp, notificationType: type, eventLocation: location, notes: note };
                        fetchedData[vehicleId] ? fetchedData[vehicleId].push(newNotObj) : fetchedData[vehicleId] = [newNotObj];
                      });
                      ExcelExporter({
                        fetchedData: fetchedData,
                        userLocomotiveListObject,
                        t,
                        // userDateFormat,
                        userDateFormat: 'DD.MM.YYYY HH:mm:ss',
                        isStandardExport: false,
                        isNotifications: true,
                      });
                    }
                  },
                  trackingInfo: {
                    category: 'Notifications',
                    label: 'Exported Button Clicked',
                    action: 'Export',
                  },
                },
                {
                  name: t('button.cancel'), onClick: this.closeNotificationExport, trackingInfo: {
                    category: 'Notifications',
                    label: 'Notification Export Drawer Closed',
                    action: 'Close',
                  },
                },
              ]}
            /> : null
        }

      </article>
    );
  }
}

Notification.propTypes = {
  newView: PropTypes.func.isRequired,
  isDemoUser: PropTypes.bool.isRequired,
  activeUserLocomotiveListIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  userLocomotiveListObject: PropTypes.objectOf(PropTypes.object).isRequired,
  period: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  period: state.period,
  activeUserLocoId: [...state.activeUserLocomotiveListIds].pop() || 0,
  activeUserLocomotiveListIds: state.activeUserLocomotiveListIds,
  isDemoUser: state.isDemoUser,
  locListUpdated: state.locListUpdated,
  userLocomotiveListObject: state.userLocomotiveList,
  isFvmFeaturesActive: state.isFvmFeaturesActive,
  userSessionID: state.userSessionID,
  languageFilesReloaded: state.languageFilesReloaded,
  isDevelopment: state.isDevelopment,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    newView: changedView,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Notification);