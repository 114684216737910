import { withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ClearAll from '@material-ui/icons/ClearAll';
import classNames from 'classnames';
import React, { Component } from 'react';
import getFilterCategory from '../FilterCategories/';

const drawerWidth = 340;
const styles = theme => ({
  drawer: {
    // width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth * theme.fvm.zoomFactor,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6),
    // width: 0,
    display: 'none',
  },
  content: {
    flexGrow: 1,
  },
  listItemText: {
    whiteSpace: 'pre-wrap',
    fontSize: theme.typography.fontSize,
  },
});

class PrimaryFilterDrawers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.handleClearAll = this.handleClearAll.bind(this);
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { open } = this.props;

    if (open !== this.state.open) {
      this.setState({ open });
    }
  }

  handleClearAll() {
    // this.setState({ open: false });
    this.props.handleClearAll(false);
  }

  render() {
    const { open } = this.state;
    const { right, ListItems, classes, selected, t } = this.props;

    return (
      <Drawer
        className={classNames(classes.drawer, 'fvm-map-drawer')}
        classes={{
          paper: classNames({ [classes.drawerOpen]: open, [classes.drawerClose]: !open }),
        }}
        variant="persistent"
        anchor={right ? 'right' : 'left'}
        open={open}
      >
        <span
          role="button"
          className={`filter-close-button fvm-filter-controls${right ? ' right' : ' left'}`}
          onClick={this.handleClearAll}
        >
          <IconButton onClick={() => {}}>
            <ClearAll />
          </IconButton>
          <span className="filter-close-button-text" onClick={this.handleClearAll}>Filter zurücksetzen</span>
        </span>
        <Divider className="fvm-filter-list-divider" />

        <List>
          {ListItems.map(cat =>
            <ListItem
              key={cat.Name}
              button
              onClick={() => this.props.handleChange(cat.Name)}
            >
              <ListItemIcon className="fvm-filter-item-icon">
                {getFilterCategory(cat.Name).main}
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.listItemText,
                }}
                primary={t(`railationCategories.${cat.Name}`)}
              />
              <Checkbox
                tabIndex={-1}
                color="primary"
                checked={selected[cat.Name]}
                onChange={() => {
                }}
                value={cat.Name}
              />
            </ListItem>,
          )}
        </List>
      </Drawer>
    );
  }
}


PrimaryFilterDrawers.propTypes = {
};

export default withStyles(styles, { withTheme: true })(PrimaryFilterDrawers);
