import moment from 'moment/moment';
import LogbookEntryState from '../../../components/Logbook/core/LogbookEntryState';
import React from 'react';
import { SyncIcon, SyncOutlined, SyncProblem, SyncProblemOutlined } from '@material-ui/icons';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useEntryStyles = makeStyles(() => ({
  inlineFlexTableRow: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
  },
  synced: {
    color: '#06DE3A',
  },
  error: {
    color: '#FF0000',
  },
  unknownEntry: {
    color: '#FF0000',
  },


}));

const EntryStateCell = ({ isUnSynced, state, t, }) => {
  const classes = useEntryStyles();
  const entriesStateMap = {
    created: 'started',
    unknownEntry: 'unknownEntry',
    newEntry: 'started',
    finalized: 'finalized',
    unSynced: 'unSynced',
    error: 'error',
  };
  return (
    <Grid styles={{
      display: 'inline-flex',
      flexWrap: 'nowrap',
    }} className={classes.inlineFlexTableRow} container spacing={1}>
      <Grid item>
        <LogbookEntryState
          state={state}
          label={t(`logbook.labels.${entriesStateMap[state]}`)}
        />
      </Grid>
      <Grid item>
        {isUnSynced
          ? <SyncProblemOutlined className={classes.error} fontSize="small" color='secondary' />
          : <SyncOutlined className={classes.synced} fontSize="small" color='primary' />}
      </Grid>
    </Grid>
  )
}

export const buildTableColumns = ({ t, classes, openLogEntryById, userLocoListObj, user = {} }) => {
  // const tableColumns = useEntryStatusStyles();
  let columns = [
    {
      dataField: 'header.logbookEntryId',
      text: 'Entry ID',
      hidden: true,
    },
    {
      dataField: 'header.vehicleNumber',
      text: t('logbook.tableHead.vehicleNumber'),
      formatter: (cell, row) => (
        <div
          className={classes.clickableTableItem}
          data-entry-id={row.logbookEntryId}
          onClick={openLogEntryById}
        >
          {cell || userLocoListObj[row?.header?.vehicleIdRef]?.vehicle?.fabricNr || 'Name Unknown'}
        </div>
      ),
      sort: true,
    },
    {
      dataField: 'lastUpdateTimestamp',
      text: t('logbook.tableHead.lastSyncedTimestamp'),
      formatter: (cell, row) => moment(cell).format('DD.MM.YYYY HH:mm'),
      sort: true,
      classNames: 'hello',
      style: {
        width: '210px',
      }
    },
    {
      dataField: 'header.status',
      text: t('logbook.tableHead.entryState'),
      sort: true,
      formatter: (cell, { isUnSynced, unknownEntry, signatureImage, ...rest }) => {
        const state = unknownEntry ? localEntryStateMap['unknownEntry'] : cell;
        const newState = signatureImage ? 'finalized' : state;
        return (
          <EntryStateCell cell={cell} isUnSynced={isUnSynced} t={t} state={newState} />
        );
      },
    },
    {
      dataField: 'referenceTimestamp',
      text: t('logbook.tableHead.referenceDate'),
      formatter: (cell, row) => moment(cell).format('DD.MM.YYYY HH:mm'),
      sort: true,

    },

    {
      dataField: 'referenceLocation',
      text: t('logbook.tableHead.place'),
      sort: true,
    },
    {
      dataField: 'vehicleState.vehicleOperatingHours_h',
      text: t('logbook.tableHead.operatingHours'),
      sort: true,
    },
    {
      dataField: 'defects.state',
      text: t('logbook.tableHead.defects'),
      formatter: (cell, { defects }) =>
        !defects ? (
          <span className={classes.capitalized}>{t('logbook.labels.no')}</span>
        ) : (
          <LogbookEntryState
            state={
              defects.measuresDescription ? 'withDefects' : 'withoutDefects'
            }
            label={t(
              `logbook.labels.${defects.measuresDescription ? 'withDefects' : 'withoutDefects'
              }`,
            )}
          />
        ),
      sort: true,
    },
    {
      dataField: 'entryState',
      text: t('logbook.tableHead.resolutionState'),
      sort: true,
      formatter: (cell, row, { isUnSynced, unknownEntry }) => (
        row?.defects?.state ? <LogbookEntryState
          classNames={cell?.match(/\s+/) ? '' : classes.capitalized}
          state={isUnSynced || unknownEntry ? localEntryStateMap[isUnSynced ? 'unSynced' : 'error'] : cell}
          label={t(`logbook.labels.${isUnSynced ? 'unSynced' : cell}`)}
        />
          : null
      ),
    },
    {
      dataField: 'stoppage.state',
      text: t('logbook.tableHead.stoppage'),
      sort: true,
      formatter: (cell, { stoppage }) =>
        !stoppage ? (
          <span className={classes.capitalized}>{t(`labels.no`)}</span>
        ) : (
          <LogbookEntryState
            state={cell}
            label={
              stoppage.state === 'resolved'
                ? t(`labels.malfunctionExistsResolved`)
                : t('labels.malfunctionExistsOpen')
            }
          />
        ),
      headerStyle: (column, colIndex) => {
        return { width: '190px', minWidth: '190px' };
      },
    },
    {
      dataField: 'commentsCount',
      text: t('logbook.tableHead.commentCount'),
      sort: true,
    },
    {
      dataField: 'lastComment.creatorAccountLastName',
      text: t('logbook.tableHead.lastCommentCreatorName'),
      formatter: (cell, { lastComment }) =>
        lastComment
          ? `${lastComment.creatorAccountLastName}, ${lastComment.creatorAccountFirstName}`
          : '',
      sort: true,
    },
    {
      dataField: 'creatorAccountLastName',
      text: t('logbook.tableHead.creatorName'),
      formatter: (cell, row) =>
        `${row.creatorAccountLastName}, ${row.creatorAccountFirstName}`,
      sort: true,
    },
    {
      dataField: 'createTimestamp',
      text: t('logbook.tableHead.creationDate'),
      formatter: (cell, row) => moment(cell).format('DD.MM.YYYY HH:mm'),
      sort: true,
    },
  ];

  if (!user.accessRights?.['logbook_entries/comments']?.canRead) {
    columns = columns.filter(f => !['commentsCount', 'lastComment.creatorAccountLastName'].includes(f?.dataField))
  }

  return columns;
};

const localEntryStateMap = {
  error: 'error',
  unknownEntry: 'unknownEntry',
  unSynced: 'unSynced',
};