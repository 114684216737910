import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import React from 'react';
import ViewTitle from '../ViewTitle';

const useStyles = ({ drawerWidth= '80%', maxWidth, height, drawerPadding, drawerHeaderPadding }) => makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    maxWidth,
    minHeight: '80vh',
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    padding: drawerPadding,
    maxWidth,
    // height: height ? height : 'calc(100vh - 81px - 300px)',
    minHeight: '80vh',
    // display:'flex',
    // flexDirection: 'column'
  },
  drawerHeader: {
    padding: drawerHeaderPadding,
    display: 'inline-flex',
    justifyContent:'space-between',
    minHeight: 56
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 0,
    zIndex: 300,
  },
  actionButtons: {
    bottom: 0,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    minWidth: 'calc(100% - 20px)',
    padding: '10px 20px',
    '&>button': {
      marginLeft: 20,
    },
    // backgroundColor:'#FFFFFF'
  },
  mainContent: {
    ...(!height ? {} : { height }),

    // height: height ? `calc(${height} - 88px)` : 'calc(100vh - 81px - 300px)',
    // position:'relative',
  },
  controls: {
    overflow: 'hidden',
  },
}));

export default function FVMDrawer({
                                    isOpen,
                                    onClose,
                                    title = 'Insert a title',
                                    controls,
                                    actionButtons,
                                    children,
                                    drawerWidth = '80%',
                                    maxWidth = '80vw',
                                    height,
                                    drawerPadding = 20,
                                    drawerHeaderPadding = 0,
                                    headerVariant
                                  }) {
  const classes = useStyles({ drawerWidth, maxWidth, height, drawerPadding, drawerHeaderPadding})();
  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      open={isOpen}
      anchor="right"
      onClose={onClose}
    >
      <div className={classes.drawerHeader}>
        <ViewTitle variant={headerVariant} title={title}/>
        <div >
          <IconButton
            className={classes.closeButton}
            color="inherit"
            aria-label="Open Close"
            onClick={onClose}
            children={<Close/>}
          />
        </div>
      </div>

      {
        controls
          ? <div className={classes.controls}>{controls}</div>
          : null
      }
      <div className={classes.mainContent}> {children}</div>
      {
        actionButtons
          ? <div className={classes.actionButtons}>{actionButtons}</div>
          : null
      }
    </Drawer>
  );
};
