import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { AccessTimeOutlined, CalendarTodayOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import enLocale from 'date-fns/locale/en-GB';
import deLocale from 'date-fns/locale/de';

const localeMap = {
  'en-GB': enLocale,
  'en': enLocale,
  'de': deLocale,
  'de-DE': deLocale,
};
const useStyles = makeStyles(() => ({
  datetimeInputFormControl: {
    '& > div': {
      width: '100%',
    },
  },
  pickerIcon: {
    '& > div': {
      paddingRight: 4,
    },
  },
}));

const NrlDatePicker = ({
  label = 'Date Picker',
  date = null,
  fullWidth = true,
  disableFuture = 'true',
  required = false,
  onChange,
  locale = 'en',
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const handleDateChange = date => {
    onChange(date);
  };
  // console.log('date props: ', props);
  return (
    <React.Fragment>
      <div className={fullWidth ? classes.datetimeInputFormControl : ''}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
          <KeyboardDatePicker
            className={classes.pickerIcon}
            disableToolbar
            disableFuture={disableFuture}
            keyboardIcon={<CalendarTodayOutlined />}
            inputVariant="outlined"
            invalidDateMessage={t('validation.invalidTime')}
            maxDateMessage={t('validation.maxDateExceeded')}
            minDateMessage={t('validation.minDateExceeded')}
            name={props.name}
            variant="inline"
            required={required}
            format="dd.MM.yyyy"
            // id="date-picker-inline"
            label={label}
            value={date}
            // onBlur={props.onBlur}
            // disabled={props.disabled}
            {...props}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
    </React.Fragment>
  );
};

NrlDatePicker.propTypes = {};

const NrlTimePicker = ({
  label = 'Time Picker',
  fullWidth = true,
  time = null,
  required = false,
  onChange,
  errMsg = null,
  locale = 'en',
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleDateChange = date => {
    // setSelectedDate(date);
    onChange(date);
  };
  // console.log('Time props: ', props);

  return (
    <React.Fragment>
      <div className={fullWidth ? classes.datetimeInputFormControl : ''}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
          <KeyboardTimePicker
            className={classes.pickerIcon}
            //disabled={props.disabled}
            keyboardIcon={<AccessTimeOutlined />}
            inputVariant="outlined"
            invalidDateMessage={t('validation.invalidTime')}
            maxDateMessage={t('validation.maxDateExceeded')}
            minDateMessage={t('validation.minDateExceeded')}
            required={required}
            // onBlur={props.onBlur}
            // helperText={props.helperText}
            //{...(props.error ? {error : props.error}: {})}
            {...props}
            ampm={false}
            // id="time-picker"
            variant="inline"
            label={label}
            value={time}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            InputAdornmentProps={{}}
          />
        </MuiPickersUtilsProvider>
      </div>
    </React.Fragment>
  );
};

NrlTimePicker.propTypes = {};


const NrlDateTimePicker = ({
  label = 'Date Time Picker',
  fullWidth = true,
  time = null,
  required = false,
  onChange,
  errMsg = null,
  locale = 'en',
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleDateChange = date => {
    // setSelectedDate(date);
    onChange(date);
  };

  return (
    <React.Fragment>
      <div className={fullWidth ? classes.datetimeInputFormControl : ''}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
          <KeyboardDateTimePicker
            className={classes.pickerIcon}
            //keyboardIcon={<AccessTimeOutlined />}
            keyboardIcon={<CalendarTodayOutlined />}
            inputVariant="outlined"
            invalidDateMessage={t('validation.invalidTime')}
            maxDateMessage={t('validation.maxDateExceeded')}
            minDateMessage={t('validation.minDateExceeded')}
            required={required}
            {...props}
            ampm={false}
            variant="inline"
            label={label}
            value={time}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change datetime',
            }}
            format="dd.MM.yyyy @ HH:mm"
          />
        </MuiPickersUtilsProvider>
      </div>
    </React.Fragment>
  );
};

NrlDateTimePicker.propTypes = {};

export default NrlDatePicker;
export { NrlTimePicker, NrlDateTimePicker };
