import React from 'react';
const SubCatShip = () => {
  const a = {
    fill: '#e5e5e5',
    opacity: 0,
  }
  const b = {
    fill: '#002fc9',
    stroke: 'rgba(0,0,0,0)',
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g transform="translate(-441 -309)">
        <rect style={a} width="24" height="24" transform="translate(441 309)" />
        <path
          style={b}
          d="M-808,620.5l-2-5h5l1,1h11l1-1h2l-3,5Zm12-5v-2h3v2Zm-4,0v-2h3v2Zm-4,0v-2h3v2Zm-5-1v-5h1v-.6l2-.9v1.5h1v5Zm13-2v-2h3v2Zm-4,0v-2h3v2Zm-4,0v-2h3v2Z"
          transform="translate(1253 -293.5)"
        />
      </g>
    </svg>
  );
};
export default SubCatShip;
