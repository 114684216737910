import Toolbar from '@material-ui/core/Toolbar';
import moment from 'moment';
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { changedView, clickedSelectAllDevices, dateChanged } from '../actions';
import MapControls from '../containers/MapControls';
import MapIframe from '../containers/MapIframe/index';

/**
 *
 */
class Railation extends Component {
  constructor(props) {
    super(props);
    props.onDateChange({
      startDate: moment().locale('en-gb').startOf('day').subtract(7, 'days'),
      endDate: moment().locale('en-gb').startOf('day').subtract(1, 'days'),
    });
    this.state = {
      open: false,
      tweets: [],
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);

    props.newView('railation');
  }

  componentDidMount() {
    this.props.clickedSelectAllDevices(true);
  }

  handleDrawerOpen() {
    this.setState({ open: !this.state.open });
  }

  render() {
    return (
      <article className="fvm-page fvm-page-tracking">
        <Toolbar
          className="fvm-map-page-toolbar fvm-map-railation-controls"
          disableGutters
        >
          {this.props.t ? <MapControls t={this.props.t} isRailation /> : null}
        </Toolbar>
        <section className="fvm-sp fvm-sp-map-iframe no-gutters container-fluid mb-0">
          <div className="row">
            <div className="col fvm-map-iframe map-detailed-page-control">
              {/*{*/}
              {/*  <MapIframe*/}
              {/*    isRailation*/}
              {/*    t={this.props.t}*/}
              {/*    open={this.props.isRailationDrawOpen}*/}
              {/*    currentView="railation"*/}
              {/*  />*/}
              {/*}*/}
            </div>
          </div>
        </section>
        {/*{this.props.isShowLoading ? <LoadingSpinner /> : null}*/}
      </article>
    );
  }
}

Railation.propTypes = {
  newView: PropTypes.func.isRequired,
  isShowLoading: PropTypes.bool.isRequired,
  userLocomotiveListObject: PropTypes.objectOf(PropTypes.object).isRequired,
};
const mapStateToProps = state => ({
  isShowLoading: state.isMapLoading,
  userLocomotiveListObject: state.userLocomotiveList,
  isRailationDrawOpen: state.railationDrawOpen,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    onDateChange: dateChanged,
    newView: changedView,
    clickedSelectAllDevices,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Railation);
