import { withStyles } from "@material-ui/core"
import classNames from "classnames"
import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"

const styles = (theme) => ({
  root: theme.root,
  tableThCell: {
    fontWeight: 700,
  },

  tableStyles: {
    root: {
      fontSize: theme.typography.fontSize,
      color: "inherit",
      " caption": {
        fontSize: theme.typography.fontSize,
      },
    },
  },
  timeStampCompaonent: {
    fontSize: 11,
    fontStyle: "italic",
  },
})

const NrailBootstrapTable = ({
  keyField,
  data,
  columns,
  title,
  className,
  baseProps,
  defaultSorted,
  timeStamp = null,
  classes,
}) => {
  // const tempTime = moment();
  // const showDate = !!(timeStamp && data.length);
  return (
    <div
      className={classNames(
        "nrail-bootstrap-table",
        className,
        classes.tableStyles.root
      )}
    >
      <BootstrapTable
        keyField={keyField}
        data={data}
        columns={columns}
        bootstrap4
        //bordered={false}
        striped
        hover
        caption={title}
        {...baseProps}
        defaultSorted={defaultSorted}
      />
      {/*{*/}
      {/*  showDate &&(*/}
      {/*    <span className={classNames(classes.timeStampCompaonent)}>*/}
      {/*      1) {tempTime.format('DD.MM.YYYY HH:mm:ss')}<sup>*</sup>*/}
      {/*    </span>*/}
      {/*  )*/}
      {/*}*/}
    </div>
  )
}
export default withStyles(styles, { withTheme: true })(NrailBootstrapTable)
