import React from 'react';
const StatusDriving = () => {
  return (
    <svg width="60px" height="16px" viewBox="0 0 60 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Component/Toggle/Status-Alternativ/4" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#06de3a" id="Background">
          <rect x="0" y="0" width="60" height="16" rx="8"></rect>
        </g>
      </g>
    </svg>
  );
};
export default StatusDriving;
