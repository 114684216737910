import { RadioGroup, withStyles } from '@material-ui/core';

const NrlRadioButtonGroup = withStyles((theme) => ({
  root: {
    '&focused':{
      color: 'inherit',
    }
  },
}))(RadioGroup);

export const radioButtonSettings = {
  size: 'small',
  color: 'primary',
};

export default NrlRadioButtonGroup;

