import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateUserLocomotivesList } from '../../actions';

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = { searchField: '' };
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    this.onInputChange({ target: { value: '' } });
  }

  onInputChange(event) {
    const searchField = event.target.value;
    const re = new RegExp(searchField, 'i');
    const newLocoObj = {};
    const isSearchFieldEmpty = !searchField.trim();
    this.props.userLocomotiveList.forEach((loco) => {
      // setting visible
      const isVisible = isSearchFieldEmpty || (re.exec(loco.vehicle.name) && true);
      newLocoObj[loco.vehicleId] = Object.assign({}, loco, { isVisible });
    });

    this.setState({ searchField });
    this.props.filterLocomotiveList(newLocoObj);
  }

  render() {
    return (
      <form onSubmit={(e) => {e.preventDefault();}}>
        <input
          className="fvm-loc-filter"
          type="text"
          value={this.state.searchField}
          style={{ marginBottom: '5px' }}
          onChange={this.onInputChange}
          placeholder={this.props.t(`placeHolders.${this.props.isHaveCranes ? 'filterFleet' : 'filterInput'}`)}
        />
      </form>
    );
  }
}

SearchBar.propTypes = {
  userLocomotiveList: PropTypes.arrayOf(PropTypes.object).isRequired,
  filterLocomotiveList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userLocomotiveList: Object.values(state.userLocomotiveList),
  isHaveCranes: state.isHaveCranes,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  filterLocomotiveList: locoLst => dispatch(updateUserLocomotivesList(locoLst)),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
