import React from 'react';
import PropTypes from 'prop-types';
import NrailBootstrapTable from '../../../components/NrailBootstrapTable';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const demoColumns = [
  { dataField: 'dataFieldA', text: 'Column A' },
  { dataField: 'dataFieldB', text: 'Column A' },
];

const stableStyles = makeStyles(() => ( {
  tableContainer: {
    marginBottom: 8,
  },
}));
const LogBookTable = ({
  data = [],
  columns,
  className,
  title = '',
  baseProps,
  ...props
}) => {
  const classes = stableStyles();
  return (
    <section className={classNames('fvm-sp-logbook-table', 'container-fluid', classes.tableContainer, className)}>
      <NrailBootstrapTable
        data={data}
        columns={columns && columns.length ? columns : demoColumns}
        title={
          columns && columns.length
            ? title
            : 'Demo Table: Please check the column and data attribute '
        }
        {...props}
        baseProps={{
          selectRow: {
            mode: 'checkbox',
          },
          ...baseProps,
        }}
      />
    </section>
  );
};

LogBookTable.propTypes = {
  keyField: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  title: PropTypes.string,
  classes: PropTypes.object,
};

export default LogBookTable;
