import React from 'react';
const Factory = () => {
  const a = {
    fill: '#002fc9',
  }
  const b = {
    fill: '#002fc9',
    opacity: 0,
  }
  const c = {
    fill: '#fff',
    fillRule: 'evenodd',
}

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.999" height="33.999" viewBox="0 0 33.999 33.999">
      <g transform="translate(-726 -448)">
        <g transform="translate(635 365.999)">
          <rect style={a} width="33.999" height="33.999" rx="5" transform="translate(91 82.001)" />
        </g>
        <g transform="translate(577 215)">
          <rect style={{ ...a, ...b }} width="24" height="24" transform="translate(154 238)" />
          <path
            style={c}
            d="M-78,699v8.2l-5-3.2v3.2l-5-3.2v3.2l-5-3.2v12h19V699Zm-9,14h-3v-2h3Zm5,0h-3v-2h3Zm5,0h-3v-2h3Z"
            transform="translate(250 -458)"
          />
        </g>
      </g>
    </svg>
  );
};
export default Factory;
