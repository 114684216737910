import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import FvmGAItemTracker from '../FvmGAItemTracker';
import SyncIcon from '@material-ui/icons/Sync';

const useStyles = makeStyles(theme => ({
  icon: {
    '& svg': {
      marginRight: 5,
    },
    '& span': {
      whiteSpace: 'nowrap',
    }
  },
}));
const FVMTrackedButton = ({ icon, btnText, trackingInfo, className = '', ...props }) => {
  const classes = useStyles();
  return (
    <FvmGAItemTracker
      {...trackingInfo}
    >
      <Button
        className={`${className} ${classes.icon} ${classes.btnText}`}
        {...props}
        startIcon={icon ? icon : null}

      >
        {btnText}
      </Button>
    </FvmGAItemTracker>
  );
};

FVMTrackedButton.propTypes = {};

export default FVMTrackedButton;
