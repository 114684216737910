import moment from 'moment';
import { getCurrentView } from '../StatelessFunctions/nummericManapulation';

import {
  CLOSE_DETAILED_DRAW,
  DATA_LODING,
  DATA_SHEET_STATIC_DATA,
  DATE_CHANGED,
  DEMO_USER,
  FVM_FEATURE,
  FVM_USER_SESSION_ID,
  HAS_OPEN_LOGBOOK_ENTRIES,
  HAS_INTERNET_CONNECTION,
  IS_HIDE_LOGIN_UNTIL_ONLINE,
  HEAT_MAP,
  IS_LOC_CONTROL_HIDDEN,
  IS_HAVE_CRANES,
  IS_LIVE_SYSTEM,
  IS_RAILATION,
  IS_REMOVE_FOCUS_ID,
  IS_NEW_RECEIVED_TIMESTAMP,
  IS_TWITTER,
  IS_ZOOMED_EIGHTY,
  USER_MADE_INITIAL_DEVICE_SELECTION,
  LIST_VIEW,
  LOCALE_CHANGED,
  LOCOMOTIVE_CARD_CLICKED,
  MAP_LOADING,
  NAV_BAR_EXPANDED,
  SELECT_ALL_DEVICES,
  SELECT_ALL_LOCO,
  SELECT_DRIVING,
  SELECT_EQUIPPED,
  SELECT_OFF,
  SELECT_STANDING,
  SHOW_RAILWAY,
  SHOW_SATELLITE,
  SHOW_RAILATION,
  STEC_CONNECT,
  TARGET_LOCOMOTIVE,
  TRACKED_LOCOMOTIVE,
  TOGGLE_ALL_CRANES,
  TRACKING_MAP,
  UPDATE_USER_LOCOMOTIVE_LIST,
  LOGBOOK_ENTRIES_SYNCED_TIMESTAMP,
  FETCH_ACTIVE_LOCO_LIST_DATA,
  UPDATE_VIEW,
  NRL_ACCESS_TOKEN,
  IS_DEVELOPMENT,
  IS_USER_LOGGED_IN, IS_SESSION_VALID,
} from '../actionTypes';
import { LOCATION_CHANGE } from 'react-router-redux';

const initialNaveStatus = () => {
  // return JSON.parse((localStorage.getItem('nrailUserData') || '{}');
  const width = (window.innerWidth > 0) ? window.innerWidth : window.screen?.width;
  if(width < 480){
    return false;
  }
  const initStat = localStorage.getItem('isNavigationBarExpanded');
  return initStat === 'true' || initStat === null || (typeof initStat === 'undefined');
};

const userLocomotiveList = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER_LOCOMOTIVE_LIST:
      return Object.assign({}, action.forceOverwrite ? {} : state, action.payload);
    case LOCOMOTIVE_CARD_CLICKED:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const activeUserLocomotiveList = (state = [], action) => {
  switch (action.type) {
    case UPDATE_USER_LOCOMOTIVE_LIST:
      return Object.values(Object.assign({}, state, action.payload)).filter(loco => loco.isCardActive);
    case LOCOMOTIVE_CARD_CLICKED:
      return Object.values(Object.assign({}, state, action.payload)).filter(loco => loco.isCardActive);
    default:
      return state;
  }
};

const selectAllDevices = (state = false, action) => {
  switch (action.type) {
    case SELECT_ALL_DEVICES:
      return action.payload.isSelectAllDevices;
    default:
      return state;
  }
};

const selectAllLoco = (state = false, action) => {
  switch (action.type) {
    case SELECT_ALL_LOCO:
      return action.payload.isSelectAllLoco;
    case SELECT_ALL_DEVICES:
      if (action.payload.applyToGroup) {
        return action.payload.isSelectAllDevices;
      }
      return state;
    default:
      return state;
  }
};
const selectAllDriving = (state = false, action) => {
  switch (action.type) {
    case SELECT_DRIVING:
      return action.payload;
    case SELECT_ALL_LOCO:
      if (action.payload.applyToGroup) {
        return action.payload.isSelectAllLoco;
      }
      return state;
    case SELECT_ALL_DEVICES:
      if (action.payload.applyToGroup) {
        return action.payload.isSelectAllDevices;
      }
      return state;
    default:
      return state;
  }
};
const selectAllStanding = (state = false, action) => {
  switch (action.type) {
    case SELECT_STANDING:
      return action.payload;
    case SELECT_ALL_LOCO:
      if (action.payload.applyToGroup) {
        return action.payload.isSelectAllLoco;
      }
      return state;
    case SELECT_ALL_DEVICES:
      if (action.payload.applyToGroup) {
        return action.payload.isSelectAllDevices;
      }
      return state;
    default:
      return state;
  }
};
const selectAllEquipped = (state = false, action) => {
  switch (action.type) {
    case SELECT_EQUIPPED:
      return action.payload;
    case SELECT_ALL_LOCO:
      if (action.payload.applyToGroup) {
        return action.payload.isSelectAllLoco;
      }
      return state;
    case SELECT_ALL_DEVICES:
      if (action.payload.applyToGroup) {
        return action.payload.isSelectAllDevices;
      }
      return state;
    default:
      return state;
  }
};
const selectAllOff = (state = false, action) => {
  switch (action.type) {
    case SELECT_OFF:
      return action.payload;
    case SELECT_ALL_LOCO:
      if (action.payload.applyToGroup) {
        return action.payload.isSelectAllLoco;
      }
      return state;
    case SELECT_ALL_DEVICES:
      if (action.payload.applyToGroup) {
        return action.payload.isSelectAllDevices;
      }
      return state;
    default:
      return state;
  }
};
const toggleAllCranes = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_ALL_CRANES:
      return action.payload;
    case SELECT_ALL_DEVICES:
      if (action.payload.applyToGroup) {
        return action.payload.isSelectAllDevices;
      }
      return state;
    default:
      return state;
  }
};

const isNavigationBarExpanded = (state = initialNaveStatus(), action) => {
  switch (action.type) {
    case NAV_BAR_EXPANDED:
      return action.payload;
    default:
      return state;
  }
};
const multiSelectViewArray = ['dashboard', 'notification', 'railation', 'mapbox', 'mapbox-vs-google', 'google-maps'];
const isSingleSelect = (state = !multiSelectViewArray.includes(getCurrentView()), action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return !multiSelectViewArray.includes(getCurrentView());
    default:
      return state;
  }
};

const currentView = (state = getCurrentView(), action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return getCurrentView();
    default:
      return state;
  }
};

const heatMap = (state = false, action) => {
  switch (action.type) {
    case HEAT_MAP:
      return action.payload;
    default:
      return state;
  }
};

const trackingMap = (state = true, action) => {
  switch (action.type) {
    case TRACKING_MAP:
      return action.payload;
    default:
      return state;
  }
};

const showSatellite = (state = false, action) => {
  switch (action.type) {
    case SHOW_SATELLITE:
      return action.payload;
    default:
      return state;
  }
};
const showRailation = (state = false, action) => {
  switch (action.type) {
    case SHOW_RAILATION:
      return action.payload;
    default:
      return state;
  }
};

const showRailway = (state = false, action) => {
  switch (action.type) {
    case SHOW_RAILWAY:
      return action.payload;
    default:
      return state;
  }
};

const period = (state = {
  // startDate: moment().startOf('day').subtract(10, 'months'),
  startDate: moment().startOf('day').subtract(2, 'weeks'),
  endDate: moment().startOf('day'),
}, action) => {
  switch (action.type) {
    case DATE_CHANGED:
      return action.payload;
    default:
      return state;
  }
};

const isShowLoading = (state = false, action) => {
  switch (action.type) {
    case DATA_LODING:
      return action.payload;
    default:
      return state;
  }
};

const isMapLoading = (state = false, action) => {
  switch (action.type) {
    case MAP_LOADING:
      return action.payload;
    default:
      return state;
  }
};

const isStecConnectionLost = (state = false, action) => {
  switch (action.type) {
    case STEC_CONNECT:
      return action.payload;
    default:
      return state;
  }
};

const activeUserLocomotiveListIds = (state = [], action) => {
  switch (action.type) {
    // case ACTIVE_LOCOMOTIVE_LIST:
    //   return action.payload;
    case UPDATE_USER_LOCOMOTIVE_LIST:
      return Object.values(Object.assign({}, state, action.payload))
        .filter(loco => loco.isCardActive)
        .sort((locoA, locoB) => locoA.activeTime - locoB.activeTime)
        .map(loco => loco.vehicleId);
    default:
      return state;
  }
};

const targetedUserLocomotiveId = (state = 0, action) => {
  switch (action.type) {
    case TARGET_LOCOMOTIVE:
      return action.payload;
    default:
      return state;
  }
};
const trackedUserLocomotiveId = (state = 0, action) => {
  switch (action.type) {
    case TRACKED_LOCOMOTIVE:
      return action.payload;
    default:
      return state;
  }
};


const isDemoUser = (state = false, action) => {
  switch (action.type) {
    case DEMO_USER:
      return action.payload;
    default:
      return state;
  }
};
const isTwitter = (state = true, action) => {
  switch (action.type) {
    case IS_TWITTER:
      return action.payload;
    default:
      return state;
  }
};
const isListView = (state = false, action) => {
  switch (action.type) {
    case LIST_VIEW:
      return action.payload;
    default:
      return state;
  }
};

const dataSheetStaticData = (state = {}, action) => {
  switch (action.type) {
    case DATA_SHEET_STATIC_DATA:
      return action.payload;
    default:
      return state;
  }
};

const isFvmFeaturesActive = (state = false, action) => {
  switch (action.type) {
    case FVM_FEATURE:
      return action.payload;
    default:
      return state;
  }
};

const userSessionID = (state = '12453', action) => {
  switch (action.type) {
    case FVM_USER_SESSION_ID:
      return action.payload;
    default:
      return state;
  }
};
const getIslive = () => process.env.REACT_APP_FVM_ENV === 'production';
const getIsLocalEnv = () => window.location.origin === 'http://localhost:3000';
// const getIslive = () => process.env.REACT_APP_FVM_ENV === 'production';

const isLive = (state = getIslive(), action) => {
  switch (action.type) {
    case IS_LIVE_SYSTEM:
      return getIslive();
    default:
      return state;
  }
};

const isLocalEnv = (state = getIsLocalEnv(), action) => {
  switch (action.type) {
    case IS_LIVE_SYSTEM:
      return getIsLocalEnv();
    default:
      return state;
  }
};

const isNrailHome = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const railationDrawOpen = (state = isNrailHome(), action) => {
  switch (action.type) {
    case IS_RAILATION:
      return action.payload;
    default:
      return state;
  }
};

const removeFocusFromID = (state = 0, action) => {
  switch (action.type) {
    case IS_REMOVE_FOCUS_ID:
      return action.payload;
    default:
      return state;
  }
};

const isZoomEighty = (state = false, action) => {
  switch (action.type) {
    case IS_ZOOMED_EIGHTY:
      return action.payload;
    default:
      return state;
  }
};

const isHaveCranes = (state = false, action) => {
  switch (action.type) {
    case IS_HAVE_CRANES:
      return action.payload;
    default:
      return state;
  }
};

const closeDetailedDraw = (state = false, action) => {
  switch (action.type) {
    case CLOSE_DETAILED_DRAW:
      return action.payload;
    default:
      return state;
  }
};

const languageFilesReloaded = (state = localStorage.getItem('i18nextLng'), action) => {
  switch (action.type) {
    case LOCALE_CHANGED:
      return localStorage.getItem('i18nextLng');
    default:
      return state;
  }
};

const lastDataReceivedTimestamp = (state = 0, action) => {
  switch (action.type) {
    case IS_NEW_RECEIVED_TIMESTAMP:
      return action.payload;
    default:
      return state;
  }
};
const isInitialUserDeviceSelectionCompleted = (state = false, action) => {
  switch (action.type) {
    case USER_MADE_INITIAL_DEVICE_SELECTION:
      return action.payload;
    default:
      return state;
  }
};
const isFetchActiveLocoListData = (state = false, action) => {
  switch (action.type) {
    case FETCH_ACTIVE_LOCO_LIST_DATA:
      return action.payload;
    default:
      return state;
  }
};
const isUpdateView = (state = false, action) => {
  switch (action.type) {
    case UPDATE_VIEW:
      return action.payload;
    default:
      return state;
  }
};

const isLoggedIn = (state = localStorage.getItem('userToken') && true, action) => {
  switch (action.type) {
    case IS_USER_LOGGED_IN:
      return action.payload;
    default:
      return state;
  }
};
const isSessionValid = (state = !!localStorage.getItem('userToken'), action) => {
  switch (action.type) {
    case IS_SESSION_VALID:
      return action.payload || false;
    default:
      return state;
  }
};

const accessToken = (state = false, action) => {
  switch (action.type) {
    case NRL_ACCESS_TOKEN:
      return action.payload;
    default:
      return state;
  }
};


const isDevelopment = (state = window.location.origin.match(/(localhost)|(dev\.my\.nrail)/), action) => {
  switch (action.type) {
    case IS_DEVELOPMENT:
      return action.payload;
    default:
      return state;
  }
};

const isOnline = (status = navigator.onLine, action ) => {
  switch (action.type) {
    case HAS_INTERNET_CONNECTION:
      return action.payload;
    default:
      return status;
  }
};
//setHideLoginUntilOnline
const isHideLoginUntilOnline = (status = false, action ) => {
  switch (action.type) {
    case IS_HIDE_LOGIN_UNTIL_ONLINE:
      return action.payload;
    default:
      return status;
  }
};


const isLocoControlHidden = (status = false, action ) => {
  switch (action.type) {
    case IS_LOC_CONTROL_HIDDEN:
      return action.payload;
    default:
      return status;
  }
};

const hasOpenLogbookEntries = (status = !!localStorage.getItem('unsavedLogbookItems'), action ) => {
  switch (action.type) {
    case HAS_OPEN_LOGBOOK_ENTRIES:
      return action.payload;
    default:
      return status;
  }
};


const lastLogbookSyncedTimeStamp = (status = null, action ) => {
  switch (action.type) {
    case LOGBOOK_ENTRIES_SYNCED_TIMESTAMP:
      return action.payload;
    default:
      return status;
  }
};




// const userId = (state = localStorage.getItem('userId'), action) => {
//   switch (action.type) {
//     case LOCALE_CHANGED:
//       return localStorage.getItem('userId');
//     default:
//       return state;
//   }
// };

//
// const currentView = (state = "", action) =>{
//     // const pathname = action.payload.pathname;
//   switch (action.type) {
//     case LOCATION_CHANGE:
//       return action.payload.pathname;
//     default:
//       return state;
//   }
// }

export {
  removeFocusFromID,
  languageFilesReloaded,
  isNavigationBarExpanded,
  activeUserLocomotiveList,
  activeUserLocomotiveListIds,
  currentView,
  selectAllLoco,
  selectAllDevices,
  selectAllDriving,
  selectAllStanding,
  selectAllEquipped,
  toggleAllCranes,
  selectAllOff,
  isSingleSelect,
  userLocomotiveList,
  showSatellite,
  showRailation,
  trackingMap,
  showRailway,
  period,
  heatMap,
  isTwitter,
  isHaveCranes,
  railationDrawOpen,
  isShowLoading,
  isMapLoading,
  targetedUserLocomotiveId,
  trackedUserLocomotiveId,
  isDemoUser,
  isListView,
  dataSheetStaticData,
  isFvmFeaturesActive,
  isStecConnectionLost,
  userSessionID,
  isLive,
  isDevelopment,
  isLocalEnv,
  isZoomEighty,
  closeDetailedDraw,
  lastDataReceivedTimestamp,
  isInitialUserDeviceSelectionCompleted,
  isFetchActiveLocoListData,
  isUpdateView,
  accessToken,
  isLoggedIn,
  isSessionValid,
  isOnline,
  hasOpenLogbookEntries,
  lastLogbookSyncedTimeStamp,
  isLocoControlHidden,
  isHideLoginUntilOnline,
  // userId,
};
