import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import DeleteIcon from '@material-ui/icons/DeleteSharp';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import * as EmailValidator from 'email-validator';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
// import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from 'redux';
import { changedView, userLanguageChanged } from '../actions';
import FvmGAItemTracker from '../components/FvmGAItemTracker';
import BootstrapTooltip from '../components/FvmMaterilUiTooltip';
import UserAvatar from '../components/UserAvatar/index.jsx';
import ViewTitle from '../components/ViewTitle';
import RestCommunication from '../wp-rest-api/RestCommunication';

import { makeStyles } from '@material-ui/core/styles';
import { updateUserPassword } from '../apis/user-apis';
import {
  addToPersonAccountSettings,
  deletePersonAccount,
  updatePersonAccountAvatarImage,
} from '../apis/accounts-persons-api';
import { getOrientation } from 'get-orientation/browser';
import { getRotatedImage } from '../utils/image-processing/canvasUtils';
import ImageUpload from '../components/ImageUpload';
import { Modal, Typography } from '@material-ui/core';
import { store } from '../index';
import {
  fetchUserData,
  setDefaultUserImage,
  setUserAvatarUrlRefresh,
} from '../reducers/accounts/user';
import { getPasswordValidator } from './AuthenticationViews/ResetPassword';

const useStyles = makeStyles(theme => ( {
  addPhotoIcon: {
    position: 'absolute',
    opacity: 0.1,
    height: 120,
    width: 120,
    top: 0,
    '&:hover': {
      opacity: 1,
    },
  },
} ));


const UserUploadForm = props => {
  const classes = useStyles();

  return (
    <div className="fvm-change-user-image">
      <div className="fvm-image" role="button">
        <UserAvatar className="profile-page" userAvatarUrl={props.imagePath}/>
        <span>
          <IconButton
            className={classes.addPhotoIcon}
            onClick={() => {
              window.document.getElementById('image-upload').click();
            }}
          >
            <AddAPhoto color="primary"/>
          </IconButton>
        </span>
      </div>
      <input
        style={{ display: 'none' }}
        name="fvm_image_uploads"
        onChange={props.uploadImageChanged}
        id="image-upload"
        type="file"
      />
    </div>
  );
};
const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

/**
 *
 */
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLang: localStorage.getItem('savedUserLang'),
      form: {
        email: {
          current: props.email,
          new: '',
          confirm: '',
        },
        password: {
          current: '',
          new: '',
          confirm: '',
        },
      },
      changePassword: false,
      changeEmail: false,
      changeAvatar: false,
      // imagePath: `${props.imageUrl}?${Date.now()}`,
      imagePath: props.user.userAvatarUrl,
      isNewImage: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onImageChangesSaved = this.onImageChangesSaved.bind(this);
    this.getEmailForm = this.getEmailForm.bind(this);
    this.getPasswordForm = this.getPasswordForm.bind(this);
    this.uploadImageChanged = this.uploadImageChanged.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    const appFunctions = {
      updateUserInfo: props.updateUserInfo,
    };
    this.myComm = new RestCommunication(appFunctions, props.appConsts);
    this.passwordValidator = getPasswordValidator(props.t);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { form } = this.state;
    form.email.current = this.props.email;
    if (this.props.imageUrl !== prevProps.imageUrl) {
      this.setState({
        form,
        imagePath: `${this.props.imageUrl}?${Date.now()}`,
      });
    }
    if (this.props.languageFilesReloaded !== prevProps.languageFilesReloaded) {
      this.setState({
        componentLanguageUpdateTrigger: this.props.languageFilesReloaded,
      });
    }
    if (this.props.user.userAvatarUrl !== prevProps.user.userAvatarUrl) {
      this.setState({ imagePath: this.props.user.userAvatarUrl });
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const group = target.name.split('-');
    const { form } = this.state;
    form[group[1]][group[0]] = value;

    this.setState({ form });
  }

  async uploadImageChanged(e) {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      // apply rotation if needed
      const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }

      this.setState({ imageDataUrl });
    }
  }

  handleLanguageChange(e) {
    const userLang = e.target.value;
    this.setState({ userLang });
  }

  getEmailForm() {
    const { email } = this.state.form;
    const { t, user } = this.props;

    return (
      <div>
        <div className="fvm-form-control">
          <label htmlFor="current-email">{t('labels.currentEmail')}</label>
          <input
            name="current-email"
            type="email"
            value={user.accountName}
            disabled
          />
        </div>
      </div>
    );
  }

  getPersonalInfo() {
    const { t, user } = this.props;

    return (
      <form>
        <div className="fvm-form-control">
          <label htmlFor="firstname">{t('labels.firstName')}</label>
          <input name="firstname" type="text" value={user.firstName} disabled/>
        </div>
        <div className="fvm-form-control">
          <label htmlFor="lastname">{t('labels.lastName')}</label>
          <input name="lastname" type="text" value={user.lastName} disabled/>
        </div>
      </form>
    );
  }

  getLanguageForm() {
    const { t } = this.props;

    return (
      <FormControl component="fieldset">
        {/*<FormLabel component="legend">Language options:</FormLabel>*/}
        <RadioGroup
          aria-label="language"
          name="lang"
          value={this.state.userLang}
          onChange={this.handleLanguageChange}
          color="primary"
        >
          <FormControlLabel
            control={<Radio color="primary"/>}
            label={t('singleWords.english')}
            type="radio"
            value="en"
          />
          <FormControlLabel
            control={<Radio color="primary"/>}
            label={t('singleWords.german')}
            type="radio"
            value="de"
          />
        </RadioGroup>
      </FormControl>
    );
  }

  getPasswordForm(errorList = []) {
    const { password } = this.state.form;
    const { t } = this.props;

    return (
      <div onSubmit={e => e.preventDefault()}>
        <div className="fvm-form-control">
          <label htmlFor="current-password">
            {t('labels.currentPassword')}
          </label>
          <input
            name="current-password"
            type="password"
            autoComplete="current-password"
            value={password.current}
            onChange={this.handleInputChange}
          />
        </div>
        <div className="fvm-form-control">
          <label htmlFor="new-password">{t('labels.newPassword')}</label>
          <input
            name="new-password"
            type="password"
            autoComplete="new-password"
            value={password.new}
            onChange={this.handleInputChange}
          />

        </div>
        <div className="fvm-form-control">
          <label htmlFor="confirm-password">
            {t('labels.confirmNewPassword')}
          </label>
          <input
            name="confirm-password"
            type="password"
            autoComplete="confirm-password"
            value={password.confirm}
            onChange={this.handleInputChange}
          />
        </div>

        <Typography component="span" color="error" className="fvm-form-control">
          {/*{errorList.map(error => <p>{error.message}</p>)}*/}
          {/*{errorList.map(error => <p>{t(`profilePage.fields.password.error.${error.validation}`)}</p>)}*/}
          {errorList.map(error => <p>{error.message}</p>)}
        </Typography>
      </div>
    );
  }

  onImageChangesSaved({ imageDataUrl, imgBase64 }) {
    this.setState({
      imagePath: imageDataUrl,
      imgBase64,
      imageDataUrl: null,
      isNewImage: true,
    });
  }

  validateForm() {
    const { email, password } = this.state.form;
    const fieldValidation = {
      email: false,
      password: false,
      errorList: {
        password: [],
      },
    };

    if (
      email.new === email.confirm &&
      password.new === password.confirm &&
      password.confirm === '' &&
      email.confirm === ''
    ) {
      fieldValidation.email = false;
      fieldValidation.password = false;
      return fieldValidation;
    }
    // email
    if (email.new === email.confirm) {
      // true
      fieldValidation.email =
        email.new === '' || EmailValidator.validate(email.new);
    }
    // password
    if (password.confirm) {
      fieldValidation.errorList.password = this.passwordValidator.validate(password.new, { details: true });
      fieldValidation.password =
        password.new === '' || !!fieldValidation.errorList.password.length;


      fieldValidation.errorList.password.forEach(err => {
        if(err?.validation === 'not'){
          let foundChars = password.new.match(this.passwordValidator.additionCheckRegexPattern, 'ig')
          foundChars =  [...new Set(foundChars)]
          err.message = this.props.t(
            'profilePage.fields.password.error.not',
            { specialChars: `"${foundChars.join('" ')}"`}
          );

        }
      })
    }

    if (password.confirm && password.new !== password.confirm) {
      fieldValidation.errorList.password.push(
        {
          validation: 'passwordDoesNotMatch',
          arguments: 8,
          message:  this.props.t('profilePage.fields.password.error.passwordDoesNotMatch')
        },
      );
    }

    fieldValidation.password = password.current && !fieldValidation.errorList.password.length && password.new === password.confirm;

    return fieldValidation;
  }

  render() {
    const { form, imagePath, imageDataUrl, imgBase64 } = this.state;
    const { email, password } = form;
    const validFields = this.validateForm();
    const { t, i18n } = this.props;
    const isNewImage =
      this.props.isDemoUser || ( imgBase64 && !!imgBase64.length );

    return (
      <article className="fvm-page fvm-page-profile">
        <div className="fvm-general-page-content">
          <header className="fvm-page-header">
            <ViewTitle title={t('navigationMenu.profile')}/>
          </header>
          <section className="fvm-sp fvm-sp-user-login-info container-fluid">
            <div className="row">
              <div className="col">
                <header className="fvm-section-header">
                  <h2 className="fvm-section-title">
                    {t('labels.personalInfo')}
                  </h2>
                </header>
              </div>
            </div>
            <form onSubmit={e => e.preventDefault()}>
              <div className="row">
                <div className="col-4">{this.getPasswordForm(validFields?.errorList?.password)}</div>
                <div className="col-4 offset-1">{this.getEmailForm()}</div>
              </div>
            </form>
            <div className="row">
              <div className="col-9">
                <FvmGAItemTracker
                  category={'Profile'}
                  label={'Save Email and Password'}
                  action={'Save'}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={
                      this.props.isDemoUser ||
                      !( validFields.password && validFields.email )
                    }
                    className="fvm-btn fvm-save-button fvm-right-save-button"
                    onClick={async e => {
                      e.preventDefault();
                      const formData = {
                        data: {},
                      };
                      if (email.new !== '') {
                        formData.data.email = email.new;
                      }
                      if (password.new !== '') {
                        formData.data.password = password.new;
                      }
                      if (password.current !== '') {
                        formData.currentPass = password.current;
                      }

                      const resp = await updateUserPassword(
                        password.new,
                        password.current,
                      );

                      let dialog = {
                        title: '',
                        contentText: t('profilePage.fields.password.error.api.failed'),
                        actionButtons: [],
                        time: 4000,
                      };

                      if ([204, 200].includes(resp.status)) {
                        dialog = {
                          title: '',
                          contentText: t('profilePage.fields.password.error.api.success'),
                          actionButtons: [],
                          time: 2000,
                        };
                        const { form } = this.state;
                        form.email.current = resp.data.email;
                        form.email.new = '';
                        form.email.confirm = '';
                        form.password.current = '';
                        form.password.new = '';
                        form.password.confirm = '';

                        this.setState({ form });
                      }
                      this.props.showDialog({ ...dialog });
                    }}
                  >
                    {t('button.save')}
                  </Button>
                </FvmGAItemTracker>
              </div>
            </div>
          </section>

          <section className="fvm-sp fvm-sp-user-personal-info container-fluid">
            <div className="row">
              <div className="col">
                <header className="fvm-section-header">
                  <h2 className="fvm-section-title">
                    {t('labels.personalInfo')}
                  </h2>
                </header>
              </div>
            </div>
            <div className="row">
              <div className="col-4">{this.getPersonalInfo()}</div>
              <div className="col-4 offset-1">
                <UserUploadForm
                  imagePath={imagePath}
                  uploadImageChanged={this.uploadImageChanged}
                />
                {imageDataUrl ? (
                  <Modal open={true} onClose={() => {
                  }}>
                    <ImageUpload
                      imageSrc={imageDataUrl}
                      onImageChangesSaved={this.onImageChangesSaved}
                    />
                  </Modal>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-9 fvm-button-group">
                <BootstrapTooltip title={'Profilbild löschen'}>
                  <IconButton
                    aria-label="Delete"
                    className="fvm-btn-delete"
                    onClick={async e => {
                      e.preventDefault();
                      const resp = await deletePersonAccount(
                        this.props.accountId,
                      );
                      store.dispatch(setDefaultUserImage());
                      let dialog = {
                        title: 'Delete failed',
                        contentText: '',
                        actionButtons: [],
                        time: 2000,
                      };
                      // des
                      if (resp.status === 204) {
                        dialog = {
                          title: 'Delete successful',
                          contentText: '',
                          actionButtons: [],
                          time: 2000,
                        };
                      }
                      this.props.showDialog({ ...dialog });
                    }}
                  >
                    <DeleteIcon/>
                  </IconButton>
                </BootstrapTooltip>
                <FvmGAItemTracker
                  category={'Profile'}
                  label={'Save New Image'}
                  action={'Save'}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    // disabled={this.props.isDemoUser || !isNewImage}
                    disabled={!isNewImage}
                    className="fvm-btn fvm-save-button"
                    onClick={async e => {
                      e.preventDefault();
                      const accountId = this.props.accountId;
                      const resp = await updatePersonAccountAvatarImage(
                        accountId,
                        imgBase64.replace('data:image/jpeg;base64,', ''),
                      );

                      let dialog = {
                        title: 'Upload failed',
                        contentText: '',
                        actionButtons: [],
                        time: 2000,
                      };
                      // // des
                      if (resp.status === 204) {
                        dialog = {
                          title: 'Upload successful',
                          contentText: '',
                          actionButtons: [],
                          time: 2000,
                        };
                        store.dispatch(setUserAvatarUrlRefresh());
                        // setUserAvatarUrl()
                      }
                      this.props.showDialog({ ...dialog });
                    }}
                  >
                    {t('button.save')}
                  </Button>
                </FvmGAItemTracker>
              </div>
            </div>
          </section>
          <section className="fvm-sp fvm-sp-user-personal-info container-fluid">
            <div className="row">
              <div className="col">
                <header className="fvm-section-header">
                  <h2 className="fvm-section-title">
                    {t('labels.languageSettings')}
                  </h2>
                </header>
              </div>
            </div>
            <div className="row">
              <div className="col-2 fvm-user-language-container">
                {this.getLanguageForm()}
              </div>
            </div>
            <div className="row">
              <div className="col-9 fvm-button-group">
                <FvmGAItemTracker
                  category={'Profile'}
                  label={'Save Language Settings'}
                  action={'Save'}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={
                      this.state.userLang ===
                      localStorage.getItem('savedUserLang')
                    }
                    className="fvm-btn fvm-save-button"
                    onClick={async e => {
                      e.preventDefault();
                      // const userLanChanged = await this.myComm.updateUserLocale(this.state.userLang);
                      const userLanChanged = await addToPersonAccountSettings({
                        language: this.state.userLang,
                      });
                      let dialog = {
                        title: t('labels.profilePage.saveFailed'),
                        contentText: '',
                        actionButtons: [],
                        time: 2000,
                      };
                      //
                      store.dispatch(fetchUserData());

                      if (userLanChanged.status === 204) {
                        dialog = {
                          title: t('labels.profilePage.saveSuccess'),
                          contentText: '',
                          actionButtons: [],
                          time: 2000,
                        };
                        i18n.changeLanguage(this.state.userLang);
                        this.props.userLanguageChanged(this.state.userLang);
                      }
                      if (userLanChanged.status !== 200) {
                        this.props.showDialog({ ...dialog });
                      }
                    }}
                  >
                    {t('button.save')}
                  </Button>
                </FvmGAItemTracker>
              </div>
            </div>
          </section>
        </div>
      </article>
    );
  }
}

function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

Profile.propTypes = {
  newView: PropTypes.func.isRequired,
  isDemoUser: PropTypes.bool.isRequired,
  activeUserLocomotiveListIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  userLocomotiveListObject: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ( {
  activeUserLocomotiveListIds: state.activeUserLocomotiveListIds,
  isDemoUser: state.isDemoUser,
  locListUpdated: state.locListUpdated,
  userLocomotiveListObject: state.userLocomotiveList,
  isMapLoading: state.isMapLoading,
  languageFilesReloaded: state.languageFilesReloaded,
  accountId: state.user.user.accountId,
  user: state.user.user,
} );

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      newView: changedView,
      userLanguageChanged: userLanguageChanged,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
