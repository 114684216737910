import React from 'react';
const TrainLoadingPoint = () => {

  const a = {
    fill: '#002fc9',
  };
  const b = {
    opacity: 0,
  };
  const c = {
    fill: '#fff',
    stroke: 'rgba(0,0,0,0)',
  };

  return (
    <svg  xmlns="http://www.w3.org/2000/svg" width="33.999" height="33.999" viewBox="0 0 33.999 33.999">
        {/*<style>.a,.b{fill:#002fc9;}.b{opacity:0;}.c{fill:#fff;stroke:rgba(0,0,0,0);}</style>*/}
      <g transform="translate(-726 -784)">
        <g transform="translate(635 701.999)">
          <rect style={a} width="33.999" height="33.999" rx="5" transform="translate(91 82.001)" />
        </g>
        <g transform="translate(-19 170)">
          <rect style={{ ...a, ...b }} width="24" height="24" transform="translate(750 619)" />
          <path
            style={c} d="M-970,303l-3-6-3,6h-1l2-10-1-8h-5v5h-1v-5h-3v-1l9-1,1,2,4,8,2,10Zm-15-6v-6h7v6Z"
            transform="translate(1739 338)"
          />
        </g>
      </g>
    </svg>
  );
};
export default TrainLoadingPoint;
