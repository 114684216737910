import React, { useEffect, useState } from 'react';
import { Avatar, CardHeader, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SignAndSubmit, { SignatureInput } from '../SignAndSubmit';
import NrlFormLabel from '../core/NrlFormLabel';
import { commentAuthScheme } from '../../../utils/validation_scheme';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { logbookCommentSchema, logbookDefectCommentSchema, logbookSignatureSchema } from '../logbook-utils/validation-schema';
import NrlRadioButtonGroup from '../core/NrlRadioButtonGroup';

const useStyles = makeStyles(() => ({
  avatar: {
    width: 32,
    height: 32,
  },
  commentTextHeader: {
    alignItems: 'initial',
    // paddingTop: 0,
    // paddingBottom: 0,
  },
  saveButtonContainer: {
    height: 72,
    display: 'flex',
    paddingLeft: 24,
    alignItems: 'center',
  },
  sectionLabel: {
    marginTop: 16,
    marginBottom: 16,
  },
  chip: {
    marginLeft: 16,
    borderRadius: 4,
  },
  resolvedEntry: {
    color: '#06DE3A',
    backgroundColor: '#06DE3A1F',
    border: '1px solid #06DE3A1F',
  },
  openEntry: {
    color: '#FF0000',
    backgroundColor: '#FF00001F',
    border: '1px solid #FF00001F',
  },
  commentHeader: {
    paddingBottom: 0,
    paddingTop: 8,
  },
  commentBody: {
    marginLeft: 56,
    paddingTop: 0,
    paddingBottom: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  commenterName: {
    fontWeight: 700,
  },
  radioButtons: {
    marginLeft: 64,
  },
  signatureImage: {
    maxWidth: 150,
  },

  addSignature: {
    boxShadow: '0px 0px 24px #00000029',
    borderTop: '1px solid #E0E0E0',
    marginLeft: -8,
    marginRight: -8,
    paddingLeft: 72,
  },
}));

const AddComment = props => {
  const classes = useStyles();
  const {
    t,
    user,
    commentSaved,
    onSaveComment,
    setIsUserMakingComment,
    entry
  } = props;
  const [isMultiline, setIsMultiline] = useState(false);


  const addCommentForm = useForm({
    mode: 'all',
    defaultValues: {
      remarks: null,
      newEntryState: 'none',
      signatureImage: null,
    },
    resolver: joiResolver(entry?.defects !== null ? logbookDefectCommentSchema : logbookCommentSchema, {
      abortEarly: false,
      allowUnknown: true,
    }),
  });

  const handleUserIsInteracting = () => {
    setIsMultiline(true);
    setIsUserMakingComment(true);
  };
  const handleSubmit = (...inputArgs) => {
    addCommentForm.handleSubmit(onSubmit)(...inputArgs);
  };

  const onSubmit = async (data) => {
    onSaveComment(data);
  };

  useEffect(() => {

    if (commentSaved) {
      setIsMultiline(false);
      addCommentForm.reset({
        remarks: null,
        newEntryState: null,
        signatureImage: null,
      });
    }
  }, [commentSaved]);

  const radioButtonSettings = {
    size: 'small',
    color: 'primary',
    labelPlacement: 'right',
  };

  useEffect(() => {
    if (addCommentForm.getValues()?.newEntryState)
      addCommentForm.setValue('remarks', addCommentForm?.getValues()?.remarks, { shouldValidate: true })
  }, [addCommentForm.getValues()?.newEntryState]);

  return (
    <div>
      <CardHeader
        className={classes.commentTextHeader}
        avatar={
          <Avatar
            className={classes.avatar}
            aria-label="commenting-use-image"
            src={user.userAvatarUrl}
          />
        }
        title={
          <Controller
            control={addCommentForm.control}
            name="remarks"
            render={({ field }) => (
              <TextField
                placeholder={t('labels.makeAComment')}
                name="remarks"
                autoFocus={isMultiline}
                rows={4}
                multiline={isMultiline}
                variant="outlined"
                fullWidth
                {...field}
                error={!!addCommentForm?.formState?.errors.remarks}
                onFocus={handleUserIsInteracting}
              />
            )}
          />

        }
      />

      {entry?.defects !== null &&
        <FormControl
          className={classes.radioButtons}
          component="fieldset"
          required
          error={addCommentForm.formState?.errors?.newEntryState}
        >
          <NrlFormLabel component="legend">
            {t(`logbook.labels.newState`)}
          </NrlFormLabel>

          <Controller
            control={addCommentForm.control}
            name={'newEntryState'}
            rules={{ required: true }}
            render={({ field }) => (
              <NrlRadioButtonGroup
                row
                aria-label="mode-of-operation"
                {...field}
              >
                {['open', 'resolved'].map(option => (
                  <FormControlLabel
                    value={option}
                    control={<Radio {...radioButtonSettings} />}
                    label={t(`logbook.labels.${option}`)}
                    labelPlacement="end"
                  />
                ))}
              </NrlRadioButtonGroup>
            )}
          />
        </FormControl>
      }
      {addCommentForm?.formState?.isDirty ? (
        <div className={classes.saveButtonContainer}>
          <SignatureInput
            addCommentForm={addCommentForm}
            onSaveSig={handleSubmit}
            entry={entry}
            hasErrors={async () => {
              return !!Object.keys(addCommentForm.formState.errors).length;
            }}
            t={t}
            handleChange={(sig) => addCommentForm.setValue('signatureImage', sig)}
          />
        </div>
      ) : null}
    </div>
  );
};


AddComment.propTypes = {};

export default AddComment;
