import { decode, encode } from 'jwt-simple';
import {
  AccountsApi,
  AccountsPersonsApi,
  AccountsSettingsApi,
  AuthBasicApi,
  AuthPasskeyApi,
  Configuration,
  RightsObjectsApi,
  RolesApi,
} from 'nrail-user-management-api-client';
import { store } from '../index';
import { Gateway } from 'nrail-gateway-api-client';
import { Logbook } from 'nrail-logbook-api-client';
import { logbookUser401Logout } from './user-apis';
import lsChangeDetector from 'js-localstorage-change-detector';
import { cleanUpLocalStorage } from '../wp-rest-api/STEC-Communication';
import { getCurrentView } from '../StatelessFunctions/nummericManapulation';

// let basePath = `https://nrail-${process.env.REACT_APP_NRL_ENV}.westeurope.cloudapp.azure.com`;
let basePath = window.REACT_APP_NRL_LOGBOOK_URL || `https://api${(process.env.REACT_APP_NRL_ENV && process.env.REACT_APP_NRL_ENV !== 'production') ?
  `-${process.env.REACT_APP_NRL_ENV}` :
  ''
  }.nrail.de`;

const envVariables = {
  NRL_LOGBOOK_URL: process.env.REACT_APP_NRL_LOGBOOK_URL,
  NRL_ENV: process.env.REACT_APP_NRL_ENV,
};
console.log('basePath:' + basePath);
console.log('envVariables:', envVariables);
let umVersion = process.env.REACT_APP_NRL_UM_VERSION || 'v1';
let logbookVersion = process.env.REACT_APP_NRL_LOGBOOK_VERSION || 'v1';
let gatewayVersion = process.env.REACT_APP_NRL_GATEWAY_VERSION || 'v1';

export const userManagementBasePath = `${basePath}/um/${umVersion}`;
// const gatewayBasePath = `${basePath}/gateway/api/${gatewayVersion}`;
const gatewayBasePath = `${basePath}/logbook/api/logbook/${gatewayVersion}`;
const logbookBasePath = `${basePath}/logbook/api/logbook/${logbookVersion}`;
const { LogbookEntriesApi, LogbookEntriesCommentsApi, LogbookEntriesCommentsApiAxiosParamCreator, MetaApi } = Logbook;


let configurationUserManagement = new Configuration({ basePath: userManagementBasePath });
let basicApi = new AuthBasicApi(configurationUserManagement);
let passkeyApi = new AuthPasskeyApi(configurationUserManagement);
let accPersonApi = new AccountsPersonsApi(configurationUserManagement);
let accSettingsApi = new AccountsSettingsApi(configurationUserManagement);

let configurationGateway = new Gateway.Configuration({ basePath: gatewayBasePath });
let configurationLogbook = new Logbook.Configuration({ basePath: logbookBasePath });

let gatewayExportApi = new Gateway.ExportsApi(configurationGateway);
let gatewayMetaApi = new Gateway.MetaApi(configurationGateway);
let gatewayReportsApi = new Gateway.ReportsApi(configurationGateway);
let gatewayMapsApi = new Gateway.MapsApi(configurationGateway);
// let gatewayExportApi = new Gateway.ExportsApi(configurationGateway);
let logbookEntriesApi = new LogbookEntriesApi(configurationLogbook);
let logbookMetaApi = new MetaApi(configurationLogbook);
let logbookEntriesCommentsApi = new LogbookEntriesCommentsApi(configurationLogbook);
let logbookEntriesApiAxiosParamCreator = new Logbook.LogbookEntriesApiAxiosParamCreator(configurationLogbook);


let rightsObj = new RightsObjectsApi(configurationUserManagement);
let rolesApi = new RolesApi(configurationUserManagement);
let accountsApi = new AccountsApi(configurationUserManagement);


export const getCurrentUserToken = (refreshTokenStr = localStorage.getItem('userToken')) => {
  let refreshToken = null;
  try {
    refreshToken = decode(refreshTokenStr, '', true);
  } catch (e) {
    console.log(e);
  }
  return refreshToken;
};

const currentUser = getCurrentUserToken()?.accountId;

lsChangeDetector.addChangeListener('onChange', 'userToken', () => {
  const newUserId = getCurrentUserToken()?.accountId;
  if (newUserId !== currentUser) {
    window.location.reload();
  }
});


export const getBasicAuthRefreshApi = (accessToken = window.localStorage.getItem('userToken')) => new AuthBasicApi(new Configuration({
  basePath: userManagementBasePath,
  accessToken,
}));

const checkUserStatus = () => {
  try {
    const jwtToken = decode(localStorage.getItem('userToken'), '', true);
    // if token is no loner valid log user out
    if (new Date(jwtToken.exp * 1000) < new Date()) {
      setTimeout(() => {
        cleanUpLocalStorage(undefined, false);
        window.location = 'login';
      }, 100);
    }
  } catch (e) {
    console.log(e);
  }
};

if (localStorage.getItem('userToken')) {
  checkUserStatus();
}


let tokenUpdateTimeoutId;
let accessTokenExpiresAt;

const clearPolling = () => {
  if (tokenUpdateTimeoutId) {
    clearInterval(tokenUpdateTimeoutId);
    tokenUpdateTimeoutId = null;
  }
};

const keepTokenFresh = (newTokenStr) => {
  const newToken = getCurrentUserToken(newTokenStr);
  if (navigator.onLine && window.localStorage.getItem('userToken') && newToken?.exp && newToken?.iat && accessTokenExpiresAt) {
    const intervalTime = Math.floor((accessTokenExpiresAt - (Date.now() / 1000)) * 1000);
    // ( newToken.exp - accessTokenExpiresAt - 60 ) * 1000);
    if (intervalTime > 1 && store.getState()?.isSessionValid) {
      tokenUpdateTimeoutId = setInterval(() => {
        configureRequestHeaders().then((accessToken) => {
        });
      }, intervalTime);
    }
    return;
  }
  clearPolling();
};

let requestInflight;

const setInvalidSessionInStore = () => {
  if (store?.dispatch) {
    // store.dispatch({
    //   type: 'IS_SESSION_VALID',
    //   payload: false,
    // });
    store.dispatch({
      type: 'NRL_ACCESS_TOKEN',
      payload: null,
    });
    store.dispatch({
      type: 'IS_USER_LOGGED_IN',
      payload: false,
    });
  }
};

export const configureRequestHeaders = async (token = window.localStorage.getItem('userToken')) => {
  if (!token) {
    return;
  }

  if (requestInflight) {
    await requestInflight;
  }
  let refreshToken;
  try {
    refreshToken = decode(token, '', true);
  } catch (e) {
    console.log('bad token');
  }

  if (!refreshToken) {
    return;
  }

  let isInvalidRefreshToken = refreshToken.exp;
  let { accessToken } = store.getState();

  if (accessToken) {
    const { exp } = decode(accessToken, '', true);
    let tokenExpIn = (exp * 1000) - Date.now();

    isInvalidRefreshToken = exp > refreshToken.exp;
    const tokenExpInMin = tokenExpIn / (60 * 1000);

    if (tokenExpInMin < 2) {
      accessToken = null;
      tokenUpdateTimeoutId = null;
    }
  }


  if (!accessToken && refreshToken?.expiredToken) {
    console.log(isInvalidRefreshToken, !!accessToken);
    setInvalidSessionInStore();
    return null;
  }


  if (!accessToken) {

    requestInflight = getBasicAuthRefreshApi().createAccessToken().catch(error => error.response);
    const resp = await requestInflight;

    if (resp?.status === 401) {
      accessTokenExpiresAt = null;
      setInvalidSessionInStore();
      refreshToken.expiredToken = true;
      localStorage.setItem('userToken', encode(refreshToken, 'qwe'));
      logbookUser401Logout();
      // logout();
    }

    if (!isInvalidRefreshToken) {

    }

    if (resp?.status !== 201) {
      console.dir(resp);
      return;
    }

    if (resp.data && resp.data.accessToken) {
      const newTokenDecoded = getCurrentUserToken(resp.data.accessToken);
      if (newTokenDecoded?.exp && newTokenDecoded?.iat) {
        accessTokenExpiresAt = (Date.now() / 1000) + newTokenDecoded.exp - newTokenDecoded?.iat;
        accessToken = resp.data.accessToken;
      }

    }

  }

  if (accessToken) {
    store.dispatch({
      type: 'NRL_ACCESS_TOKEN',
      payload: accessToken,
    });
    store.dispatch({
      type: 'IS_SESSION_VALID',
      payload: true,
    });
    store.dispatch({
      type: 'IS_USER_LOGGED_IN',
      payload: true,
    });


    configurationUserManagement = new Configuration({ basePath: userManagementBasePath, accessToken });
    configurationGateway = new Gateway.Configuration({ basePath: gatewayBasePath, accessToken });
    configurationLogbook = new Logbook.Configuration({ basePath: logbookBasePath, accessToken });
    gatewayExportApi = new Gateway.ExportsApi(configurationGateway);
    gatewayMetaApi = new Gateway.MetaApi(configurationGateway);
    gatewayMapsApi = new Gateway.MapsApi(configurationGateway);
    gatewayReportsApi = new Gateway.ReportsApi(configurationGateway);

    basicApi = new AuthBasicApi(configurationUserManagement);
    passkeyApi = new AuthPasskeyApi(configurationUserManagement);
    accPersonApi = new AccountsPersonsApi(configurationUserManagement);
    accSettingsApi = new AccountsSettingsApi(configurationUserManagement);
    logbookEntriesApi = new LogbookEntriesApi(configurationLogbook);
    logbookMetaApi = new MetaApi(configurationLogbook);
    logbookEntriesCommentsApi = new LogbookEntriesCommentsApi(configurationLogbook);
    logbookEntriesApiAxiosParamCreator = new Logbook.LogbookEntriesApiAxiosParamCreator(configurationLogbook);
    // console.log(gatewayMapsApi, configurationGateway)

    rightsObj = new RightsObjectsApi(configurationUserManagement);
    rolesApi = new RolesApi(configurationUserManagement);
    accountsApi = new AccountsApi(configurationUserManagement);
  }

  if (!tokenUpdateTimeoutId && accessToken) {
    keepTokenFresh(accessToken);
  }
  requestInflight = null;
  return accessToken;
};


window.addEventListener('focus', () => {
  clearPolling();
  setTimeout(configureRequestHeaders, 100);
});


export const getUMConfigs = async () => await configureRequestHeaders().then(() => configurationUserManagement);
export const getLogBookConfigs = async () => await configureRequestHeaders().then(() => configurationLogbook);


export const getGatewayExportApi = async () => await configureRequestHeaders().then(() => gatewayExportApi);
export const getGatewayMetaApi = async () => await configureRequestHeaders().then(() => gatewayMetaApi);
export const getGatewayReportsApi = async () => await configureRequestHeaders().then(() => gatewayReportsApi);
export const getGatewayMapsApi = async () => await configureRequestHeaders().then(() => gatewayMapsApi);
export const getLogbookApi = async () => await configureRequestHeaders().then(() => logbookEntriesApi);
export const getLogbookMetaApi = async () => await configureRequestHeaders().then(() => logbookMetaApi);
export const getLogbookEntriesCommentsApi = async () => await configureRequestHeaders().then(() => logbookEntriesCommentsApi);
export const getAuthenticationApi = async () => await configureRequestHeaders().then(() => basicApi);
export const getPassKeyApi = async () => await configureRequestHeaders().then(() => passkeyApi).catch((err) => err);
// export const getLogbookApiRequestObj = async () => await configureRequestHeaders().then(() =>
// LogbookEntriesApiAxiosParamCreator);
export const getLogbookApiRequestObj = async (organisationId, logbookEntryId) => await configureRequestHeaders()
  .then(() => logbookEntriesApiAxiosParamCreator)
  .then(({ getLogbookEntryCreatorSignature }) => getLogbookEntryCreatorSignature(organisationId, logbookEntryId))
  .then(({ url, ...options }) => {
    return { url: `${configurationLogbook.basePath}${url}`, ...options };
  });

export const getAccPersonApi = async () => await configureRequestHeaders().then(() => accPersonApi);
export const getAccSettingsApi = async () => await configureRequestHeaders().then(() => accSettingsApi);


export const getRolesApi = async () => await configureRequestHeaders().then(() => rolesApi);
export const getRightsObj = async () => await configureRequestHeaders().then(() => rightsObj);
export const getAccountsApi = async () => await configureRequestHeaders().then(() => accountsApi);


