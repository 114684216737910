import ReactGA from 'react-ga';

const options = {};

const trackPage = (page) => {
  ReactGA.set({
    page,
    title: page.split('/').pop() || 'dashboard',
    language: localStorage.getItem('i18nextLng'),
    ...options,
  });
  ReactGA.pageview(page);
};

let currentPage = '';

const googleAnalytics = store => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = `${action.payload.pathname}${action.payload.search}`;
    if (currentPage !== nextPage) {
      currentPage = nextPage;
      trackPage(nextPage);
    }
  }
  return next(action);
};

const trackButtonClick = (category, label, action = 'click', value= 0) => {
  const userLang = localStorage.getItem('i18nextLng');
  const page = document.location.pathname;
  ReactGA.set({
    title: page.split('/').pop() || 'dashboard',
    language: userLang,
  });
  ReactGA.event({
    language: localStorage.getItem('i18nextLng'),
    // category:`${userLang}: ${category}`,
    category: category,
    action,
    value,
    label,
  });
};

export {
  trackPage,
  googleAnalytics,
  trackButtonClick
}

