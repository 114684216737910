import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import BarChart from '@material-ui/icons/BarChart';
import GetApp from '@material-ui/icons/GetApp';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TrainIcon from '@material-ui/icons/TrainOutlined';
import debounce from 'lodash/debounce';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import connect from 'react-redux/es/connect/connect';
import { Link as RouterLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { changedView } from '../actions';
import FvmGAItemTracker from '../components/FvmGAItemTracker';
import BootstrapTooltip from '../components/FvmMaterilUiTooltip';
import LoadingSpinner from '../components/LoadingSpinner';
import NrailBootstrapTable from '../components/NrailBootstrapTable/index';

import ReportExportDrawer from '../components/ReportExportDrawer';
import ReportGraphing from '../components/ReportGraphing';
import FVMTrackedButton from '../components/TrackedButton';
import ViewTitle from '../components/ViewTitle';
import MapControls from '../containers/MapControls';
import {
  getCurrentView,
  kmFormatTableCol,
  setNumberUnit,
  timeFormatTableCol,
} from '../StatelessFunctions/nummericManapulation';
import calculateFuelConsumption from '../StateLessLocomotiveFunctions/fuelConsumption';
import { fetchSTECDetailedReport, fetchSTECReporting } from '../wp-rest-api/STEC-Communication';

const buildTabelHead = (t, formatter) => {
  const tableHeaders = [
    {
      dataField: 'total',
      translationKey: 'tables.tHead.total',
    }, {
      dataField: 'today',
      translationKey: 'tables.tHead.todayLive',
      // headerFormatter: cell => <>{cell.text}<sup>*</sup></>,
    }, {
      dataField: 'thisWeek',
      translationKey: 'tables.tHead.currentWeek',
    }, {
      dataField: 'thisMonth',
      translationKey: 'tables.tHead.currentMonth',
    },
  ];

  return tableHeaders.map(({ translationKey, ...rest }) => {
    return {
      text: t(translationKey),
      formatter,
      ...rest,
    };
  });
};

const buildNotificationTableHead = (t) => {
  const notApp = t('tables.tRow.notApplicable');
  const tableHeaders = [
    {
      dataField: 'timestamp',
      translationKey: 'tables.tHead.date',
      formatter: cell => cell !== null && cell !== undefined
        ? `${moment(new Date(cell)).format('DD.MM.YYYY, HH:mm:ss')} `
        : notApp,
    },
    {
      dataField: 'type',
      translationKey: 'tables.tHead.notificationType',
      formatter: cell => cell !== null && cell !== undefined ? `${cell}` : notApp,
    },
    {
      dataField: 'note',
      translationKey: 'tables.tHead.notes',
    },
    {
      dataField: 'location',
      translationKey: 'tables.tHead.eventLocation',
      sort: true,
    },
  ];
  return tableHeaders.map(({ dataField, translationKey, formatter }) => {
    return {
      formatter,
      dataField,
      text: t(translationKey),
    };
  });
};

const getDefaultElogLables = (t) => {
  const eLocoLabel = t('labels.eLoc');
  return [{
    day: eLocoLabel,
    today: eLocoLabel,
    timeSpan: eLocoLabel,
    drivingHours: eLocoLabel,
    equippedHours: eLocoLabel,
    mileageKm: eLocoLabel,
    standByHours: eLocoLabel,
    idleHours: eLocoLabel,
    thisMonth: eLocoLabel,
    thisWeek: eLocoLabel,
    total: eLocoLabel,
    vehicleId: eLocoLabel,
    workingHours: eLocoLabel,
    isElocOrCrane: true,
  }];
};

const getDefaultCraneLables = (t) => {
  const craneLabel = t('labels.crane');
  return [{
    day: craneLabel,
    today: craneLabel,
    timeSpan: craneLabel,
    drivingHours: craneLabel,
    equippedHours: craneLabel,
    mileageKm: craneLabel,
    standByHours: craneLabel,
    idleHours: craneLabel,
    thisMonth: craneLabel,
    thisWeek: craneLabel,
    total: craneLabel,
    vehicleId: craneLabel,
    workingHours: craneLabel,
    isElocOrCrane: true,
  }];
};

/**
 *
 */
class Reporting extends Component {
  constructor(props) {
    super(props);
    this.liveData = [{}];
    this.period = {
      startDate: props.period.startDate.format('DD.MM.YYYY'),
      endDate: props.period.endDate.format('DD.MM.YYYY'),
    };
    const { t } = this.props;

    this.notApp = t('tables.tRow.notApplicable');

    this.updateAllTables = this.updateAllTables.bind(this);
    this.syncUpdating = this.syncUpdating.bind(this);
    this.fetchNewHoursData = debounce(this.fetchNewHoursData.bind(this), 500);
    this.fetchReportExportData = this.fetchReportExportData.bind(this);
    this.onSheetOutputChange = this.onSheetOutputChange.bind(this);
    this.handleHideExportView = this.handleHideExportView.bind(this);
    this.handleHideReport = this.handleHideReport.bind(this);
    this.elogFormatter = this.elogFormatter.bind(this);
    this.kmFormatTableCol = this.kmFormatTableCol.bind(this);
    this.leterTableCol = this.leterTableCol.bind(this);
    this.isDeviceAnElog = this.isDeviceAnElog.bind(this);

    this.activeUserLocoId = props.activeUserLocoId;
    this.state = {
      // userDateFormat: localStorage.getItem('i18nextLng') === 'en' ? 'DD/MM/YYYY' : 'DD/MM/YYYY',
      userDateFormat: 'DD.MM.YYYY',
      outputFormat: 'by-id',
      exportData: {},
      ...this.period,
      ...this.initialValues(props),
      // isEloc: false,
      isEloc: props.activeUserLocoId && props.userLocomotiveListObject[props.activeUserLocoId].isEloc,
      isExportView: false,
      isShowGraph: false,
      showGraphs: {
        workingHours: false,
        equippedHours: false,
        standByHours: false,
        idleHours: false,
        drivingHours: false,
        mileageKm: false,
        Notifications: false,
        fuelConsumption: false,
      },
    };

    this.commonClassNames = 'section-heading';
  }

  componentDidMount() {
    // this.props.newView();
    if (this.props.activeUserLocoId) {
      this.fetchNewHoursData(this.props);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeUserLocoId, period, userLocomotiveListObject, languageFilesReloaded, t } = this.props;
    const { endDate, startDate } = prevProps.period;

    if (period.startDate && period.endDate && (activeUserLocoId !== prevProps.activeUserLocoId ||
      !period.startDate.isSame(startDate) || !period.endDate.isSame(endDate))) {
      this.setState({ isLoading: true });

      if (activeUserLocoId) {
        const locoFuelConsValues = calculateFuelConsumption(
          userLocomotiveListObject[activeUserLocoId].fuelConsumption,
        );
        this.setState({ locoFuelConsValues });
        setTimeout(() => this.fetchNewHoursData(), 300);
      }
      if (!activeUserLocoId) {
        this.setState({ ...this.initialValues({period, t }) });
        this.setState({ isLoading: false });
      }
    }
    if (prevProps.languageFilesReloaded !== languageFilesReloaded) {
      this.setState({
        componentLanguageUpdateTrigger: prevProps.languageFilesReloaded,
        ...this.initialValues({period, t }),
      }, this.fetchNewHoursData);
    }
  }

  async fetchNewHoursData(newProps = this.props) {
    const { isDemoUser, userSessionID, period, activeUserLocoId } = newProps;

    if (!activeUserLocoId || this.props.userLocomotiveListObject[activeUserLocoId].isCrane) {
      this.setState({ isLoading: false });
      return;
    }
    const currentView = getCurrentView();
    const resp = await fetchSTECReporting({
      vehicleId: activeUserLocoId,
      // orderBy: 'dateTime',
      // page: 1,
      // top: 3,
      // skip: 30,
      // currentView,
      limitedAccessStartDate: this.props.userLocomotiveListObject[activeUserLocoId].startDate,
      limitedAccessEndDate: this.props.userLocomotiveListObject[activeUserLocoId].endDate,
      // isDemoUser,
      // userSessionID,
      ...period,
    });

    if (activeUserLocoId === this.props.activeUserLocoId) {
      this.updateAllTables(activeUserLocoId, resp);
      this.setState({ isLoading: false });
    }
  }

  async fetchReportExportData(newProps) {
    const { isDemoUser, userSessionID, period, activeUserLocoId } = newProps;
    const dataFields = ['reportExportRequest', 'idleHours', 'drivingHours'];
    const currentView = getCurrentView();
    const groupByType = this.state.outputFormat;
    const resp = await fetchSTECDetailedReport({
      vehicleIds: activeUserLocoId,
      currentView,
      isDemoUser,
      userSessionID,
      ...period,
      dataFields,
      groupByType,
    });

    if (activeUserLocoId === this.props.activeUserLocoId) {
      this.setState({ exportData: resp });
      return true;
    }

    return false;
  }

  initialValues({period, t } ) {
    const startDate = period.startDate.format('DD.MM.YYYY');
    const endDate = period.endDate.format('DD.MM.YYYY');
    return {
      reportingOperatingTable: {
        columns: [
          ...buildTabelHead(t, (cell, row) => row.isElocOrCrane ? cell : this.elogFormatter(cell, row)),
          {
            dataField: 'timeSpan',
            text: `${startDate} - ${endDate}`,
            formatter: (cell, row) => row.isElocOrCrane ? cell : this.elogFormatter(cell, row),
          },
        ],
        data: [],
      },
      reportingWorkingTable: {
        columns: [
          ...buildTabelHead(t, (cell, row) =>  row.isElocOrCrane ? cell :  this.elogFormatter(cell, row)),
          {
            dataField: 'timeSpan',
            text: `${startDate} - ${endDate}`,
            formatter: (cell, row) =>  row.isElocOrCrane ? cell :  this.elogFormatter(cell, row),
          },
        ],
        data: [],
      },
      fuelConsumptionTable: {
        columns: [
          ...buildTabelHead(t, (cell, row) =>  row.isElocOrCrane ? cell :  this.leterTableCol(cell, row)),
          {
            dataField: 'timeSpan',
            text: `${startDate} - ${endDate}`,
            formatter: (cell, row) =>  row.isElocOrCrane ? cell :  this.leterTableCol(cell, row),
          },
        ],
        data: [],
      },
      reportingStandingTable: {
        columns: [
          ...buildTabelHead(t, (cell, row) => row.isElocOrCrane ? cell : this.elogFormatter(cell, row)),
          {
            dataField: 'timeSpan',
            text: `${startDate} - ${endDate}`,
            formatter: cell => this.elogFormatter(cell),
          },
        ],
        data: [],
      },
      reportingStandingEnginOnTable: {
        columns: [
          ...buildTabelHead(t, (cell, row) =>  row.isElocOrCrane ? cell :  this.elogFormatter(cell)),
          {
            dataField: 'timeSpan',
            text: `${startDate} - ${endDate}`,
            formatter: (cell, row) =>  row.isElocOrCrane ? cell :  this.elogFormatter(cell),
          },
        ],
        data: [],
      },
      reportingDrivingHrsTable: {
        columns: [
          ...buildTabelHead(t, (cell, row) => row.isElocOrCrane ? cell : this.elogFormatter(cell, row)),
          {
            dataField: 'timeSpan',
            text: `${startDate} - ${endDate}`,
            formatter: (cell, row) => row.isElocOrCrane ? cell : this.elogFormatter(cell, row),
          },
        ],
        data: [],
      },
      reportingKiloTable: {
        columns: [
          ...buildTabelHead(t, (cell, row) => row.isElocOrCrane ? cell : this.kmFormatTableCol(cell, row) ),
          {
            dataField: 'timeSpan',
            text: `${startDate} - ${endDate}`,
            formatter: (cell, row) => row.isElocOrCrane ? cell : this.kmFormatTableCol(cell, row),
          },
        ],
        data: [],
      },
      reportingTable: {
        columns: [
          ...buildNotificationTableHead(t),
        ],
        data: [],
      },
    };
  }

  kmFormatTableCol(cell) {
    return kmFormatTableCol(cell);
  }

  leterTableCol(cell, row) {
    return setNumberUnit(cell, 'l');
  }

  elogFormatter(cell, row) {
    return timeFormatTableCol(cell, this.props.t);
  }

  isDeviceAnElog(cell) {
    const { activeUserLocoId, userLocomotiveListObject } = this.props;

    return (userLocomotiveListObject && (activeUserLocoId && userLocomotiveListObject[activeUserLocoId].isEloc))
      || userLocomotiveListObject[activeUserLocoId].isCrane;
  }

  updateAllTables(activeUserLocoId, resp) {
    const { generalResponse, notificationResponse } = resp;

    this.setState({
      TimestampMileage: generalResponse.timestamp.status,
      generalTs: generalResponse.timestamp.mileage,
    });
    this.liveData = [
      {
        ...this.props.userLocomotiveListObject[activeUserLocoId],
        ...generalResponse,
        notificationResponse,
      }];


    this.syncUpdating();
  }

  syncUpdating() {
    const {
      equippedHours,
      serviceHours,
      workingHours,
      standByHours,
      drivingHours,
      standbyAndIdle,
      idleHours,
      mileageKm,
      notificationResponse,
    } = { ...this.liveData[0] };
    let { locoFuelConsValues } = this.state;
    const { userLocomotiveListObject, activeUserLocoId } = this.props;
    const { isEloc } = userLocomotiveListObject[activeUserLocoId];

    if (!locoFuelConsValues) {
      locoFuelConsValues = calculateFuelConsumption(userLocomotiveListObject[activeUserLocoId].fuelConsumption);
      this.setState({ locoFuelConsValues });
    }

    // if (equippedHours) {
    //   equippedHours.total += initialValues ? Number(initialValues.drivinghours) : 0;
    // }
    // if (workingHours) {
    //   workingHours.total += initialValues ? Number(initialValues.workinghours) : 0;
    // }
    // if (mileageKm) {
    //   // mileageKm.total += initialValues ? Number(initialValues.mileageKm) : 0;
    //   mileageKm.total += initialValues ? Number(initialValues.kmdriven) : 0;
    // }
    // if (standByHours) {
    //   standByHours.total += initialValues ? Number(initialValues.standingnomovement) : 0;
    // }
    // if (idleHours) {
    //   idleHours.total += initialValues ? Number(initialValues.standingwithengineon) : 0;
    // }
    // if (drivingHours) {
    //   drivingHours.total += initialValues ? Number(initialValues.drivinghours) : 0;
    // }

    const {
      reportingOperatingTable,
      reportingKiloTable,
      fuelConsumptionTable,
      reportingStandingTable,
      reportingDrivingHrsTable,
      reportingStandingEnginOnTable,
      reportingWorkingTable,
      reportingTable,
    } = this.initialValues( this.props);

    const fuelConsumpVals = {
      kmValues: { ...mileageKm},
      timeValues: { ...idleHours},
      totalConsumprion: {},
    };

    Object.keys(fuelConsumpVals.timeValues).forEach((key) => {
      fuelConsumpVals.kmValues[key] *= locoFuelConsValues.totalLetersPerKm;
      fuelConsumpVals.timeValues[key] *= locoFuelConsValues.standingWithEngRunning;
      fuelConsumpVals.totalConsumprion[key] = fuelConsumpVals.kmValues[key] + fuelConsumpVals.timeValues[key];
    });

    // overwriting the data property
    reportingOperatingTable.data = [{ ...serviceHours }];
    reportingWorkingTable.data = [{ ...workingHours }];
    reportingKiloTable.data = [{ ...mileageKm }];
    reportingStandingTable.data = [{ ...standbyAndIdle }];
    reportingDrivingHrsTable.data = [{ ...drivingHours }];
    reportingStandingEnginOnTable.data = [{ ...idleHours }];
    reportingTable.data = [...notificationResponse];
    fuelConsumptionTable.data = [{ ...fuelConsumpVals.totalConsumprion }];

    this.setState({
      reportingOperatingTable,
      reportingWorkingTable,
      fuelConsumptionTable,
      reportingStandingTable,
      reportingDrivingHrsTable,
      reportingStandingEnginOnTable,
      reportingKiloTable,
      reportingTable,
      isEloc,
    });
  }

  onSheetOutputChange(event) {
    this.setState({
      outputFormat: event.target.value,
      exportData: {},
    });
  }

  handleHideExportView(event) {
    this.setState({
      isExportView: false,
    });
  }

  handleHideReport() {
    const { showGraphs } = this.state;
    Object.keys(showGraphs).forEach((metricName) => {
      showGraphs[metricName] = false;
    });

    this.setState({
      isShowGraph: false,
      showGraphs: { ...showGraphs },
    });
  }

  showGraphs(name, activeGraph = 'xyChart') {
    const { showGraphs } = this.state;
    if (name === 'showAll') {
      Object.keys(showGraphs).forEach((metricName) => {
        showGraphs[metricName] = true;
      });
    } else {
      showGraphs[name] = true;
    }

    this.setState({
      isShowGraph: true,
      showGraphs: { ...showGraphs },
      activeGraph,
    });
  }

  getTableToolTip(text, tootltipText, url, superScript = '') {
    return (
      <span className="fvm-extend-info-icon">
          {url ? <Link color="inherit" component={RouterLink} to={url}>{text}</Link> : text}
        {superScript ? <sup>{superScript}</sup> : ''}
        <BootstrapTooltip
          title={tootltipText}
        >
            <i className="fvm-info-icon">
              <InfoOutlinedIcon/>
            </i>
          </BootstrapTooltip>
      </span>
    );
  }

  render() {
    const {
      reportingOperatingTable,
      reportingStandingTable,
      fuelConsumptionTable,
      reportingStandingEnginOnTable,
      reportingDrivingHrsTable,
      reportingKiloTable,
      reportingWorkingTable,
      reportingTable,
      isLoading,
      outputFormat,
      exportData,
      isExportView,
      isShowGraph,
      showGraphs,
      activeGraph,
      userDateFormat,
      TimestampMileage,
      generalTs,
    } = this.state;

    const {
      t,
      isDemoUser,
      userSessionID,
      period,
      activeUserLocoId,
      isFvmFeaturesActive,
      isZoomEighty,
      userLocomotiveListObject,
    } = this.props;

    const { isActive } = !!activeUserLocoId && userLocomotiveListObject[activeUserLocoId].fuelConsumption;
    const { name } = userLocomotiveListObject[activeUserLocoId]?.vehicle || {};
    const { isEloc, isCrane } = userLocomotiveListObject[activeUserLocoId] || {};

    if (isEloc) {
      reportingWorkingTable.data = getDefaultElogLables(t);
      reportingStandingEnginOnTable.data = getDefaultElogLables(t);
      fuelConsumptionTable.data = getDefaultElogLables(t);
    }

    if (isCrane) {
      reportingOperatingTable.data = getDefaultCraneLables(t);
      reportingStandingTable.data = getDefaultCraneLables(t);
      reportingStandingEnginOnTable.data = getDefaultCraneLables(t);
      reportingDrivingHrsTable.data = getDefaultCraneLables(t);
      reportingKiloTable.data = getDefaultCraneLables(t);
      reportingWorkingTable.data = getDefaultCraneLables(t);
      reportingTable.data = getDefaultCraneLables(t);
    }
    const timestamps = [generalTs, TimestampMileage].map(ts => ts ? moment(new Date(ts)) : null);

    return (
      <article className="fvm-page fvm-page-reporting">
        <div className="fvm-map-page-toolbar-container">
          <Toolbar
            className="fvm-map-page-toolbar"
          >
            {t ? <MapControls reportCategory="Reporting" t={this.props.t} isDatePickerDisabled={isCrane}/>
              : null
            }
            <div className="fvm-reporting-button-group">
              <FVMTrackedButton
                trackingInfo={{
                  category: 'Reporting',
                  label: 'Graphing Drawer Opened',
                  action: 'Open',
                }}
                icon={<BarChart/>}
                btnText={t('button.graphing')}
                variant="outlined"
                color="primary"
                size="medium"
                disabled={!activeUserLocoId || isCrane}
                onClick={() => {
                  this.showGraphs('showAll', 'xyChartAccum');
                }}
              />
              <FVMTrackedButton
                trackingInfo={{
                  category: 'Reporting',
                  label: 'Export Drawer Opened',
                  action: 'Open',
                }}
                icon={<GetApp/>}
                btnText={t('button.export')}
                variant="outlined"
                color="primary"
                size="medium"
                disabled={isCrane}
                onClick={() => {
                  this.setState({ isExportView: true });
                }}
              />
            </div>
          </Toolbar>
        </div>
        <div className="fvm-general-page-content">

          <header className="fvm-page-header">
            <ViewTitle title={t('navigationMenu.reporting')}/>
            <div className="fvm-action-bar">
              <Chip
                label={name || ''}
                avatar={
                  <Avatar>
                    <TrainIcon/>
                  </Avatar>
                }
                className="fvm-locomotive-chip"
              />
            </div>
          </header>

          <section className="fvm-sp fvm-sp-reporting-data container-fluid">
            <div className="row">
              <div className="col">
                {/*<NrailBootstrapTable*/}
                {/*  keyField="vehicleId"*/}
                {/*  className={this.commonClassNames}*/}
                {/*  {...reportingOperatingTable}*/}
                {/*  title={*/}

                {/*    <div className="fvm-reporting-table-caption">*/}
                {/*      {this.getTableToolTip(t('tables.caption.equippedHours'), t('tooltip.equippedHours'), null, 1)}*/}
                {/*      <span>*/}
                {/*        <FvmGAItemTracker*/}
                {/*          category={'Reporting'}*/}
                {/*          label={`Graphing Drawer - Direct Metric - Equipped Hours`}*/}
                {/*          action={`Open`}*/}
                {/*        >*/}
                {/*          <IconButton*/}
                {/*            color="inherit"*/}
                {/*            aria-label="Open drawer"*/}
                {/*            onClick={() => this.showGraphs('equippedHours', 'xyChartAccum')}*/}
                {/*            label={'Railation'}*/}
                {/*            disabled={!activeUserLocoId || isCrane}*/}
                {/*            children={<BarChart color="inherit"/>}*/}
                {/*          />*/}
                {/*        </FvmGAItemTracker>*/}
                {/*      </span>*/}
                {/*    </div>*/}
                {/*  }*/}
                {/*/>*/}
                <NrailBootstrapTable
                  keyField="vehicleId"
                  className={`${this.commonClassNames}${isEloc ? ' fvm-disabled-elem' : ''}`}
                  {...reportingWorkingTable}
                  title={
                    <div className="fvm-reporting-table-caption">

                      {this.getTableToolTip(t('tables.caption.workingHours'), t('tooltip.workingHours'), null, 1)}
                      <span>
                        <FvmGAItemTracker
                          category={'Reporting'}
                          label={`Graphing Drawer - Direct Metric - Working Hours`}
                          action={`Open`}
                        >
                          <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={() => this.showGraphs('workingHours', 'xyChartAccum')}
                            label={'Railation'}
                            disabled={!activeUserLocoId || isCrane}
                            children={<BarChart color="inherit"/>}
                          />
                      </FvmGAItemTracker>
                    </span>
                    </div>

                  }
                />
                {/*{*/}
                {/*  isActive && (*/}
                {/*    <NrailBootstrapTable*/}
                {/*      keyField="vehicleId"*/}
                {/*      className={`${this.commonClassNames}${isEloc || !isActive ? ' fvm-disabled-elem' : ''}`}*/}
                {/*      {...fuelConsumptionTable}*/}
                {/*      title={*/}
                {/*        <div className="fvm-reporting-table-caption">*/}
                {/*          <span>*/}
                {/*            {*/}
                {/*              this.getTableToolTip(*/}
                {/*                t('tables.caption.fuelConsumption'),*/}
                {/*                t('tooltip.fuelConsumption'),*/}
                {/*                isEloc || isCrane ? null : '/datasheet',*/}
                {/*                1,*/}
                {/*              )*/}
                {/*            }*/}
                {/*          </span>*/}
                {/*          <span>*/}
                {/*            <FvmGAItemTracker*/}
                {/*              category={'Reporting'}*/}
                {/*              label={`Graphing Drawer - Direct Metric - Fuel Consumption`}*/}
                {/*              action={`Open`}*/}
                {/*            >*/}
                {/*              <IconButton*/}
                {/*                color="inherit"*/}
                {/*                aria-label="Open drawer"*/}
                {/*                onClick={() => this.showGraphs('fuelConsumption', 'xyChartAccum')}*/}
                {/*                label={'Railation'}*/}
                {/*                disabled={!activeUserLocoId || isEloc || isCrane}*/}
                {/*                children={<BarChart color="inherit"/>}*/}
                {/*              />*/}
                {/*            </FvmGAItemTracker>*/}
                {/*          </span>*/}
                {/*        </div>*/}
                {/*      }*/}
                {/*    />*/}
                {/*  )*/}
                {/*}*/}

                {/*<NrailBootstrapTable*/}
                {/*  keyField="vehicleId"*/}
                {/*  className={this.commonClassNames}*/}
                {/*  {...reportingStandingTable}*/}
                {/*  title={*/}
                {/*    <div className="fvm-reporting-table-caption">*/}
                {/*      {this.getTableToolTip(t('tables.caption.standingNoMovement'), t('tooltip.standingNoMovement'), null, 1)}*/}
                {/*      <span>*/}
                {/*        <FvmGAItemTracker*/}
                {/*          category={'Reporting'}*/}
                {/*          label={`Graphing Drawer - Direct Metric - Standing No Movement`}*/}
                {/*          action={`Open`}*/}
                {/*        >*/}
                {/*          <IconButton*/}
                {/*            color="inherit"*/}
                {/*            aria-label="Open drawer"*/}
                {/*            onClick={() => this.showGraphs('standByHours', 'xyChartAccum')}*/}
                {/*            label={'Railation'}*/}
                {/*            disabled={!activeUserLocoId || isCrane}*/}
                {/*            children={<BarChart color="inherit"/>}*/}
                {/*          />*/}
                {/*        </FvmGAItemTracker>*/}
                {/*      </span>*/}
                {/*    </div>*/}
                {/*  }*/}

                {/*/>*/}

                <NrailBootstrapTable
                  keyField="vehicleId"
                  className={this.commonClassNames}
                  {...reportingDrivingHrsTable}
                  title={
                    <div className="fvm-reporting-table-caption">
                      {this.getTableToolTip(t('tables.caption.DrivingHours'), t('tooltip.drivingHours'), null, 1)}
                      <span>
                        <FvmGAItemTracker
                          category={'Reporting'}
                          label={`Graphing Drawer - Direct Metric - Driving Hours`}
                          action={`Open`}
                        >
                          <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={() => this.showGraphs('drivingHours', 'xyChartAccum')}
                            label={'Railation'}
                            disabled={!activeUserLocoId || isCrane}
                            children={<BarChart color="inherit"/>}
                          />
                        </FvmGAItemTracker>
                      </span>
                    </div>
                  }

                />
                <NrailBootstrapTable
                  keyField="vehicleId"
                  className={`${this.commonClassNames}${isEloc ? ' fvm-disabled-elem' : ''}`}
                  {...reportingStandingEnginOnTable}
                  title={
                    <div className="fvm-reporting-table-caption">
                      {
                        this.getTableToolTip(
                          t('tables.caption.standingWithEngineOn'),
                          t('tooltip.dailyEquippedHoursInfoIcon'),
                          null,
                          1,
                        )
                      }
                      <span>
                        <FvmGAItemTracker
                          category={'Reporting'}
                          label={`Graphing Drawer - Direct Metric - Standing With EngineOn`}
                          action={`Open`}
                        >
                        <IconButton
                          color="inherit"
                          aria-label="Open drawer"
                          onClick={() => this.showGraphs('idleHours', 'xyChartAccum')}
                          label={'Railation'}
                          disabled={!activeUserLocoId || isCrane}
                          children={<BarChart color="inherit"/>}
                        />
                        </FvmGAItemTracker>
                      </span>
                    </div>
                  }

                />

                <NrailBootstrapTable
                  keyField="vehicleId"
                  className={this.commonClassNames}
                  {...reportingKiloTable}
                  title={
                    <div className="fvm-reporting-table-caption">
                      {this.getTableToolTip(t('tables.caption.kmDriven'), t('tooltip.kmDriven'), null, 2)}
                      <span>
                        <FvmGAItemTracker
                          category={'Reporting'}
                          label={`Graphing Drawer - Direct Metric - Km Driven`}
                          action={`Open`}
                        >
                          <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={() => this.showGraphs('mileageKm', 'xyChartAccum')}
                            label={'Railation'}
                            disabled={!activeUserLocoId || isCrane}
                            children={<BarChart color="inherit"/>}
                          />
                        </FvmGAItemTracker>
                      </span>
                    </div>
                  }
                />

                <NrailBootstrapTable
                  keyField="Id"
                  className={this.commonClassNames}
                  {...reportingTable}
                  title={
                    <div className="fvm-reporting-table-caption">
                      {this.getTableToolTip(t('tables.caption.notificationDate'), t('tooltip.notificationDate'))}
                    </div>
                  }
                />
              </div>
            </div>
          </section>
          <ReportExportDrawer
            open={isExportView}
            // isLive={!this.props.isDevelopment}
            t={t}
            userDateFormat={userDateFormat}
            isDemoUser={isDemoUser}
            userSessionID={userSessionID}
            period={period}
            activeUserLocoId={activeUserLocoId}
            outputFormat={outputFormat}
            exportData={exportData}
            handleDrawClose={this.handleHideExportView}
            userLocomotiveListObject={this.props.userLocomotiveListObject}
            isZoomEighty={isZoomEighty}
          />
          {
            isShowGraph ? (
              <ReportGraphing
                open={isShowGraph}
                showGraphs={showGraphs}
                userDateFormat={userDateFormat}
                t={t}
                activeGraph={activeGraph}
                locoFuelConsValues={{ ...this.state.locoFuelConsValues, isActive }}
                isDemoUser={isDemoUser}
                userSessionID={userSessionID}
                period={period}
                activeUserLocoId={activeUserLocoId}
                outputFormat={outputFormat}
                exportData={exportData}
                isFvmFeaturesActive={isFvmFeaturesActive}
                handleDrawClose={this.handleHideReport}
                userLocomotiveListObject={this.props.userLocomotiveListObject}
                name={this.props.userLocomotiveListObject[activeUserLocoId]?.vehicle?.name  || ''}
                isZoomEighty={isZoomEighty}
                isEloc={isEloc}
              />
            ) : null
          }
          {isLoading ? <LoadingSpinner/> : null}
          <span className="table-footnotes">
            {
              timestamps.length && (
                timestamps.map((timestamp, index) =>
                  timestamp ?
                    <span key={`timestamp-${index}`}>
                    {`${index + 1}) ${timestamp.format('DD.MM.YYYY HH:mm:ss')}`}
                  </span>
                    : null,
                )
              )
            }
            {/*<span key={`timestamp-exception`}>*/}
            {/*  {t('labels.footerTs')}*/}
            {/*</span>*/}
          </span>
        </div>
        {/*<ReactTooltip className="fvm-tooltip" />*/}
      </article>
    );
  }
}

Reporting.propTypes = {
  newView: PropTypes.func.isRequired,
  isDemoUser: PropTypes.bool.isRequired,
  activeUserLocoId: PropTypes.number.isRequired,
  userLocomotiveListObject: PropTypes.objectOf(PropTypes.object).isRequired,
  period: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  activeUserLocoId: [...state.activeUserLocomotiveListIds].pop() || 0,
  period: state.period,
  isDemoUser: state.isDemoUser,
  userLocomotiveListObject: state.userLocomotiveList,
  isFvmFeaturesActive: state.isFvmFeaturesActive,
  userSessionID: state.userSessionID,
  isDevelopment: state.isDevelopment,
  isZoomEighty: state.isZoomEighty,
  languageFilesReloaded: state.languageFilesReloaded,

});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    newView: changedView,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Reporting);
