import React from 'react';
const SubCatOffice = () => {
  const a = {
    fill: '#e5e5e5',
    opacity: 0,
  }
  const b = {
    fill: '#002fc9',
    fillRule: 'evenodd',
  }
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g transform="translate(-441 -501)">
      <rect style={{ ...a}} width="24" height="24" transform="translate(441 501)" />
      <path
        style={{...b }}
        d="M-75.5,803H-79v-7.5a1.5,1.5,0,0,0-1.5-1.5h-11a1.5,1.5,0,0,0-1.5,1.5V814h5v-5h4v5h10v-9.5A1.5,1.5,0,0,0-75.5,803ZM-87,805h-3v-3h3Zm0-5h-3v-3h3Zm5,5h-3v-3h3Zm0-5h-3v-3h3Zm5,10h-3v-3h3Z"
        transform="translate(537 -291)"
      />
    </g>
  </svg>


  );
};
export default SubCatOffice;
