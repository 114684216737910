import { List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import FVMDrawer from '../FVMDrawer';
import SelectableGridArea from '../SelectableGridArea';
import SelectionAreaControls from '../SelectionAreaControls';
import FVMTrackedButton from '../TrackedButton';

const useStyles = makeStyles(theme => ( {
  actionButton: {
    // marginLeft: 15,
    padding: 0,
    display: 'flex',
  },
} ));

const actionButtons = ({ actionButtonsArray = [], itemsSelected = 0, callbackData = {}, classes }) => {
  return <List
    className={classes.actionButton}
  >
    {
      actionButtonsArray.map(({
        name = '', trackingInfo = {}, onClick = () => {
        }, ...buttonProps
      }, index) => {
        return (
          <ListItem disableGutters={!index} key={`drawer-${name}-button`}>
            <FVMTrackedButton
              trackingInfo={trackingInfo}
              btnText={name}
              variant="contained"
              size="large"
              onClick={() => onClick(callbackData)}
              {...buttonProps}
              disabled={name === 'Export' ? itemsSelected === 0 : buttonProps.disabled}
            />
          </ListItem>
        );
      }).reverse()
    }
  </List>;

};

const SelectionDrawer = ({
  isOpen,
  title,
  gridData,
  selectedItems,
  hasDatePicker = false,
  selectAll,
  actionButtonsArray,
  onClose,
  period = {
    startDate: null,
    endDate: null,
  }, ...props
}) => {
  const [filterTerm, setFilterChange] = React.useState('');
  const [internalSelectedItems, setInternalSelectedItems] = React.useState(selectedItems);
  const [internalSelectAll, setInternalSelectAll] = React.useState(selectAll);
  const [internalPeriod, setInternalPeriod] = React.useState(period);

  const classes = useStyles();

  const toggleSelection = id => {
    if (internalSelectedItems.includes(id)) {
      setInternalSelectedItems([...internalSelectedItems].filter(vehicleId => vehicleId !== id));
      return;
    }
    if (internalSelectedItems.length === gridData.length) {
      setInternalSelectedItems([...internalSelectedItems, id]);
    }
    setInternalSelectedItems([...internalSelectedItems, id]);
  };
  const isOneSelected = () => {
    const searchTermLowerCase = filterTerm.toLowerCase();
    const filteredLst = gridData.filter(loco => !filterTerm || ( filterTerm && loco.vehicle.name.toLowerCase().match(searchTermLowerCase) )).map(train => train.id);
    return !!internalSelectedItems.find(id => filteredLst.includes(id));
  };

  const isAllSelected = () => {
    const searchTermLowerCase = filterTerm.toLowerCase();
    const filteredLst = gridData.filter(loco => !filterTerm || ( filterTerm && loco.vehicle.name.toLowerCase().match(searchTermLowerCase) )).map(train => train.id);
    return !filteredLst.find(id => !internalSelectedItems.includes(id));
  };

  const toggleSelectAll = () => {
    let internalSelectedItemsTemp = [...internalSelectedItems];
    const searchTermLowerCase = filterTerm.toLowerCase();
    const filteredLst = gridData.filter(loco => !filterTerm || ( filterTerm && loco.vehicle.name.toLowerCase().match(searchTermLowerCase) )).map(train => train.id);
    const foundOneSelected = isOneSelected();

    if (foundOneSelected) {
      internalSelectedItemsTemp = internalSelectedItemsTemp.filter(id => !filteredLst.includes(id));
    } else {
      internalSelectedItemsTemp = [...new Set([...internalSelectedItemsTemp, ...filteredLst])];
    }
    setInternalSelectAll(!foundOneSelected);
    setInternalSelectedItems(internalSelectedItemsTemp);
  };
  const handleDateChange = (newPeriod) => {
    setInternalPeriod({ ...newPeriod });
  };


  // useEffect(()=>{
  //     actionButtonsArray[0].itemsSelected  = internalSelectedItems.length;
  //     },[internalSelectedItems])
  return (
    <FVMDrawer
      isOpen={isOpen}
      onClose={onClose}
      height={'calc(100vh - 81px - 300px)'}
      controls={<SelectionAreaControls
        onSearchChange={setFilterChange}
        hasDatePicker={period.startDate && period.startDate}
        handleSelectAll={toggleSelectAll}
        allSelected={isAllSelected()}
        period={internalPeriod}
        handleDateChange={handleDateChange}
        selectedItems={internalSelectedItems.length}
        indeterminate={!isAllSelected() && isOneSelected()}
      />}
      title={title}
      actionButtons={actionButtons({
        actionButtonsArray,
        itemsSelected: internalSelectedItems.length,
        callbackData: { selectedItems: [...internalSelectedItems], period: internalPeriod },
        classes,
      })}
    >
      <SelectableGridArea
        listItems={gridData}
        selected={internalSelectedItems}
        toggleSelection={toggleSelection}
        filterTerm={filterTerm}
      />
    </FVMDrawer>
  );
};

SelectionDrawer.propTypes = {};

export default SelectionDrawer;
