import React from 'react';
import Communication from '../../assets/svgs/FilterCategories/Communication';
import Education from '../../assets/svgs/FilterCategories/Education';
import Factory from '../../assets/svgs/FilterCategories/Factory';
import Finance from '../../assets/svgs/FilterCategories/Finance';
import FuelStation from '../../assets/svgs/FilterCategories/FuelStation';
import Infrastructure from '../../assets/svgs/FilterCategories/Infrastructure';
import NorthRail from '../../assets/svgs/FilterCategories/NorthRail';
import Office from '../../assets/svgs/FilterCategories/Office';
import Personnel from '../../assets/svgs/FilterCategories/Personnel';
import ServiceCenter from '../../assets/svgs/FilterCategories/ServiceCenter';
import Ship from '../../assets/svgs/FilterCategories/Ship';
import TrainLoadingPoint from '../../assets/svgs/FilterCategories/TrainLoadingPoint';
import SubCatCommunication from '../../assets/svgs/FilterSubCategoies/Communication';
import SubCatEducation from '../../assets/svgs/FilterSubCategoies/Education';
import SubCatFactory from '../../assets/svgs/FilterSubCategoies/Factory';
import SubCatFinance from '../../assets/svgs/FilterSubCategoies/Finance';
import SubCatFuelStation from '../../assets/svgs/FilterSubCategoies/FuelStation';
import SubCatInfrastructure from '../../assets/svgs/FilterSubCategoies/Infrastructure';
import SubCatOffice from '../../assets/svgs/FilterSubCategoies/Office';
import SubCatPersonnel from '../../assets/svgs/FilterSubCategoies/Personnel';
import SubCatServiceCenter from '../../assets/svgs/FilterSubCategoies/ServiceCenter';
import SubCatShip from '../../assets/svgs/FilterSubCategoies/Ship';
import SubCatTrainLoadingPoint from '../../assets/svgs/FilterSubCategoies/TrainLoadingPoint';
import SubCatNorthRail from '../../assets/svgs/FilterSubCategoies/NorthRail';

const machineStatus = {
  railRelatedService: {
    main: <Communication />,
    secondary: <SubCatCommunication />,
  },
  educationTraining: {
    main: <Education />,
    secondary: <SubCatEducation />,
  },
  fuelStation: {
    main: <FuelStation />,
    secondary: <SubCatFuelStation />,
  },
  infrastructure: {
    main: <Infrastructure />,
    secondary: <SubCatInfrastructure />,
  },
  constructionEquipment: {
    main: <Factory />,
    secondary: <SubCatFactory />,
  },
  vehicleRental: {
    main: <Finance />,
    secondary: <SubCatFinance />,
  },
  staff: {
    main: <Personnel />,
    secondary: <SubCatPersonnel />,
  },
  nrail: {
    main: <NorthRail />,
    secondary: <SubCatNorthRail />,
  },
  powerCompanies: {
    main: <Office />,
    secondary: <SubCatOffice />,
  },
  maintenanceServicing: {
    main: <ServiceCenter />,
    secondary: <SubCatServiceCenter />,
  },
  ships: {
    main: <Ship />,
    secondary: <SubCatShip />,
  },
  accessPointsTerminals: {
    main: <TrainLoadingPoint />,
    secondary: <SubCatTrainLoadingPoint />,
  },
};



// const getFilterCategory = (status) => (
//   {
//     ...machineStatus[status],
//     // tooltip: t(machineStatus[status] ? machineStatus[status].tooltip : ''),
//   });

const getFilterCategory = (status) => (
  {
    ...machineStatus[status],
    // tooltip: t(machineStatus[status] ? machineStatus[status].tooltip : ''),
  });


export default getFilterCategory;
