import { withStyles } from '@material-ui/core';
// import styles from '../LocomotiveList/styles';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Iframe from 'react-iframe';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { detailedDrawClosed, mapLoadingData, setRemoveFocusId } from '../../actions';
import DetailedDrawerView from '../../components/DetailedDrawerView';
import PrimaryFilterDrawers from '../../components/PrimaryFilterDrawers';
import SecondaryFilterDrawers from '../../components/SecondaryFilterDrawers';
import { getCurrentView } from '../../StatelessFunctions/nummericManapulation';

const IFRAME_CONST_SETTINGS = {
  autoFollow: false,
};

const styles = theme => ({
  root: {
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
  },
  fvmLocControl: {
    width: 270 * theme.fvm.zoomFactor,
    minWidth: 270 * theme.fvm.zoomFactor,
    fontSize: theme.typography.fontSize,
  },
  openDrawRightFirstLevel: {
    paddingRight: 340 * theme.fvm.zoomFactor,
  },
  openDrawLeftFirstLevel: {
    paddingLeft: 340 * theme.fvm.zoomFactor,
  },
  openDrawRightSecondLevel: {
    paddingRight: (340 + 280) * theme.fvm.zoomFactor,
  },
  openDrawLeftSecondLevel: {
    paddingLeft: (340 + 280) * theme.fvm.zoomFactor,
  },
});

const getIframeUrl = (props) => {
  const IS_STEC_DEMO = localStorage.getItem('isTestProductionApis');
  const controls = [];

  if (['trackingmap'].includes(props.currentView)) {
    // controls.push('view=Heatmap');
    controls.push('routeStatus=1');
    // controls.push('colors=001044');
    if (props.isTrackingMap && props.activeUserLocoId) {
      controls.push(`routesVehicleId=t${props.activeUserLocoId}`);

    }
    if (props.isTrackingMap && props.activeUserLocoId) {
      controls.push(`heatmapVehicleId=t${props.activeUserLocoId}`);
    }
  }

  if (['dashboard', 'livedata'].includes(props.currentView) || props.isRailation) {
    if (!props.isRailation) {
      // controls.push('hideAllTros=1');
      if (Object.keys(props.userLocomotiveListObject).length) {
        // controls.push(`defaultVisible=t${Object.keys(props.userLocomotiveListObject).join(',t')}`);
      }
      controls.push('tooltips=hover');
    }

    if (props.isRailation) {
      controls.push('tooltips=minimized,hideminimized,toggleclick,nocurrent');
    }
  }

  if (props.isDemoUser) {
    controls.push('demo=1');
  }

  controls.push(`cid=${localStorage.getItem('i18nextLng') === 'de' ? 1031 : 2057}`);
  // this.iframeUrl = `https://nrail.stec-cloud.de${IS_STEC_DEMO ? ':444' : ''}/wt?id=${IS_STEC_DEMO ? 'dbd7e0e5-e71e-4838-b41f-7ac2f5b78ee2' :
  // '57656685-94c2-4843-8b1c-9a897d05b69a'}&${controls.join('&')}`; this.iframeUrl = 'https://nrail.stec-cloud.de:444/wt?id=dbd7e0e5-e71e-4838-b41f-7ac2f5b78ee2';
  return `https://nrail.stec-cloud.de${IS_STEC_DEMO ? ':444' : ''}/wt?id=${IS_STEC_DEMO ? 'dbd7e0e5-e71e-4838-b41f-7ac2f5b78ee2' : '57656685-94c2-4843-8b1c-9a897d05b69a'}&${controls.join('&')}`;
};

class MapIframe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      detailedDrawOpen: false,
      center: null,
      isIframeLoaded: false,
      initialZoomSet: false,
      mapLoaded: false,
      selected: {},
      catLst: [],
      detailedCatLst: [],
      foundItemCount: 0,
      secondLevel: false,
      focusedItem: null,
      resultPageNumber: 1,
      searchTerm: '',
      autoFocus: false,
      currentView: props.currentView,
    };

    this.initialZoom = null;
    this.center = null;

    this.iframeUrl = getIframeUrl(props);
    /* building iframe once */
    this.iframe = this.buildIfraim();
    /* binding of functions */
    this.receiveMessage = this.receiveMessage.bind(this);
    this.updateFrameValues = debounce(this.updateFrameValues, 300);
    this.handleClearAll = this.handleClearAll.bind(this);
    this.handleItemSelected = this.handleItemSelected.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateFilterCatInfo = this.updateFilterCatInfo.bind(this);
    this.getCatName = this.getCatName.bind(this);
    this.onSearchTermChanged = this.onSearchTermChanged.bind(this);
    this.getFilteredIds = this.getFilteredIds.bind(this);
    this.settingCustomAutoFocus = this.settingCustomAutoFocus.bind(this);
    this.loadNextPage = this.loadNextPage.bind(this);
    this.isTrackingMapToBeUpdated = this.isTrackingMapToBeUpdated.bind(this);
    this.clearFocuedItem = this.clearFocuedItem.bind(this);
  }

  componentDidMount() {
    const { showLoading } = this.props;
    showLoading(true);
    window.addEventListener('message', this.receiveMessage, false);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.initialZoomSet) {
      if (this.state.currentView === 'trackingmap') {
        if (this.isTrackingMapToBeUpdated(nextProps)) {
          this.trackingMapSettings(nextProps);
        }
      } else {
        this.generalMaSettings(nextProps);
      }
    }
    if (nextProps.closeDetailedDraw) {
      this.setState({ detailedDrawOpen: false });
      this.props.detailedDrawClosed(false);
    }
    if (nextProps.open && this.state.focusedItem) {
      this.setState({ detailedDrawOpen: true });
    }

    if (this.props.languageFilesReloaded !== nextProps.languageFilesReloaded) {
      this.iframeUrl = getIframeUrl(nextProps);
      this.iframe = this.buildIfraim();
      this.initialZoom = null;
      this.center = null;
      this.generalMaSettings(nextProps);
      this.setState({
        componentLanguageUpdateTrigger: this.props.languageFilesReloaded,
      });
    }
  }

  receiveMessage(event) {
    if (!event.origin.match('https://nrail.stec-cloud.de') || this.state.currentView !== getCurrentView()) {
      return;
    }

    if (event.data) {
      const { mapLoaded, zoom, center, railationFilterCats, railation, loading, processed } = JSON.parse(event.data);
      if (center) {
        if (this.props.selectTro === this.state.idInFocus && this.state.center && (center.lat !== this.state.center.lat || center.lng !== this.state.center.lng)) {
          this.props.setRemoveFocusId(this.props.selectTro);
        }
        this.setState({ center });
        if (this.props.selectTro !== this.state.idInFocus) {
          this.setState({ idInFocus: this.props.selectTro });
        }
      }


      if (processed && this.state.autoFocus) {
        const autoFocus = false;
        this.setState({ autoFocus });
        this.updateFrameValues({ autoFocus });
        return;
      }

      if (railation && railation.mapSearchItems) {
        const { mapSearchItems } = railation;
        const newdetailedCatLst = [].concat(...Object.values(mapSearchItems).filter(item => Array.isArray(item)));
        const { foundItemCount } = mapSearchItems;
        newdetailedCatLst.sort((itemA, itemB) => {
          const nameA = itemA.name.toUpperCase().trim(); // ignore upper and lowercase
          const nameB = itemB.name.toUpperCase().trim(); // ignore upper and lowercase
          // names are equal
          if (nameA === nameB) {
            return 0;
          }

          return nameA < nameB ? -1 : 1;
        });

        if (this.state.resultPageNumber > 1) {
          const { detailedCatLst } = this.state;
          this.setState({ detailedCatLst: [...detailedCatLst, ...newdetailedCatLst] });
        } else {
          this.setState({ detailedCatLst: [...newdetailedCatLst], foundItemCount });
        }
      }
      if (railation && railation.userSelectedIcon) {
        const { userSelectedIcon } = railation;
        if (userSelectedIcon) {
          const focusedItem = [].concat(...Object.values(userSelectedIcon)).pop();
          if (focusedItem.id.match(/^[sp]\d*/i)) {
            this.setState({ focusedItem: [].concat(...Object.values(userSelectedIcon)).pop() });
          }
        }
      }

      if (mapLoaded) {
        const selected = {};
        if (railationFilterCats) {
          const catLst = Object.values(railationFilterCats).map((item, index) => ({ id: index, ...item }));
          catLst.forEach((category) => {
            selected[category.Name] = false;
          });

          this.setState({ selected, catLst });

          if (this.props.isRailation) {
            selected.powerCompanies = true;
            setTimeout(() => {
              this.updateFilterCatInfo(selected);
            }, 2000);
          }
        }

        if (this.state.currentView === 'trackingmap') {
          // this.initialZoom = true;
          // setTimeout(() => {
          //   this.setState({ initialZoomSet: true });
          // }, 500);
          // if (!this.props.isRailation) {
          //   this.trackingMapSettings(this.props, true);
          // }
          // if (this.props.activeUserLocomotiveListIds.length) {
          //   return;
          // }
          // this.props.showLoading(false);
          // return;
        }
        const initialFrameSettings = this.initialiseIframeSettings(true);
        this.updateFrameValues(initialFrameSettings);
        // this.generalMaSettings(this.props, true);
        this.setState({ mapLoaded });
      }

      if (center && (!this.center || this.props.selectTro)) {
        this.center = center;
      }

      if (center && zoom && this.initialZoom === null) {
        if (['livedata', 'dashboard'].includes(this.state.currentView)) {
          this.initialZoom = zoom < 6 ? zoom : 6;
          if (!this.props.isRailation) {
            setTimeout(() => {
              this.generalMaSettings(this.props, true);
            }, 500);

            setTimeout(() => {
              this.setState({ initialZoomSet: true });
              this.props.showLoading(false);
            }, 2000);
          }
        }
        if (['trackingmap'].includes(this.state.currentView)) {
          this.initialZoom = zoom < 6 ? zoom : 6;
          if (!this.props.isRailation) {
            this.trackingMapSettings(this.props, true);
            this.setState({ initialZoomSet: true });
          }
        }

        if (this.props.activeUserLocomotiveListIds.length) {
          return;
        }
        setTimeout(() => {
          this.setState({ initialZoomSet: true });
          this.props.showLoading(false);
        }, 750);
        return;
      }

      if (this.initialZoom || loading === 0 || processed) {
        if (!this.state.initialZoomSet) {
          this.setState({ initialZoomSet: true });
        }
        this.props.showLoading(false);
      }
    }
  }

  trackingMapSettings(nextProps, intitialMapSetup = false) {
    const {
      period,
      activeUserLocoId,
      isShowSatellite,
      isShowRailway,
      isTrackingMap,
      isHeatMap,
    } = nextProps;

    const frameSettings = {};
    const locoChanged = activeUserLocoId !== this.props.activeUserLocoId || intitialMapSetup;

    if (period) {
      if (period.startDate.format('YYYYMMDD') !== this.props.period.startDate.format('YYYYMMDD') ||
        period.endDate.format('YYYYMMDD') !== this.props.period.endDate.format('YYYYMMDD') ||
        locoChanged) {
        if (activeUserLocoId && activeUserLocoId !== 123456) {
          frameSettings.heatmapVehicleId = isHeatMap ? `t${activeUserLocoId}` : '';
          frameSettings.routesVehicleId = activeUserLocoId;
          frameSettings.mapFitBounds = true;
          frameSettings.autoFocus = true;
          this.setState({ autoFocus: true });
        } else {
          frameSettings.routesVehicleId = '';
          frameSettings.heatmapVehicleId = '';
          frameSettings.mapZoom = this.initialZoom;
        }
        frameSettings.dateFrom = period.startDate.format('YYYYMMDD');
        frameSettings.dateTo = period.endDate.format('YYYYMMDD');
        frameSettings.showRailway = isShowRailway;
        frameSettings.mapType = isShowSatellite ? 'satellite' : 'roadmap';
        if (activeUserLocoId && activeUserLocoId !== 123456) {
          this.props.showLoading(true);
        }
      }

      if (isTrackingMap !== this.props.isTrackingMap || isHeatMap !== this.props.isHeatMap || intitialMapSetup) {
        frameSettings.heatmapVehicleId = isHeatMap ? `t${activeUserLocoId}` : '';
        frameSettings.routesVehicleId = isTrackingMap ? `t${activeUserLocoId}` : '';
        this.props.showLoading(true);
      }
    }

    if (this.props.isShowRailway !== isShowRailway || intitialMapSetup) {
      frameSettings.showRailway = isShowRailway;
    }
    //
    if (this.props.isShowSatellite !== isShowSatellite || intitialMapSetup) {
      frameSettings.mapType = isShowSatellite ? 'satellite' : 'roadmap';
    }
    if (Object.keys(frameSettings).length) {
      this.updateFrameValues(frameSettings);
    }
  }

  isTrackingMapToBeUpdated(nextProps) {

    const {
      period,
      activeUserLocoId,
      isShowSatellite,
      isShowRailway,
      isTrackingMap,
      isHeatMap,
    } = nextProps;

    const locoChanged = activeUserLocoId !== this.props.activeUserLocoId;
    if (period) {
      if (period.startDate.format('YYYYMMDD') !== this.props.period.startDate.format('YYYYMMDD')) {
        return true;
      }
      if (period.endDate.format('YYYYMMDD') !== this.props.period.endDate.format('YYYYMMDD')) {
        return true;
      }
      if (locoChanged) {
        return true;
      }

      if (isTrackingMap !== this.props.isTrackingMap || isHeatMap !== this.props.isHeatMap) {
        return true;
      }
    }

    if (this.props.isShowRailway !== isShowRailway) {
      return true;
    }

    if (this.props.isShowSatellite !== isShowSatellite) {
      return true;
    }

    return false;
  }

  settingCustomAutoFocus() {
    const { selectTro } = this.props;
    const { focusedItem } = this.state;
    if (selectTro && !focusedItem) {
      const focus = { tros: [`t${selectTro}`] };
      this.updateFrameValues({ focus });
    }
  }

  generalMaSettings(nextProps, intitialMapSetup = false, showAllDevices = (this.props.currentView === 'dashboard') && localStorage.getItem('fvm_user_initial_load')) {
    const {
      isShowRailway,
      isShowSatellite,
      selectTro,
      // activeUserLocomotiveListIds,
    } = nextProps;

    let {
      activeUserLocomotiveListIds,
    } = nextProps;

    // this.props.userLocomotiveListObject.map(loc => loc.vehicleId).filter(loc => loc.isCrane);
    if (showAllDevices) {
      activeUserLocomotiveListIds = Object.keys(this.props.userLocomotiveListObject);
    }

    const frameSettings = { ...IFRAME_CONST_SETTINGS };
    const locoChanged = activeUserLocomotiveListIds.join('-') !== this.props.activeUserLocomotiveListIds.join('-');
    // checking if there is a change and updating the active train, zoom and the focus
    if (!this.props.isRailation) {
      if (intitialMapSetup || locoChanged || selectTro !== this.props.selectTro) {
        const tros = activeUserLocomotiveListIds.map(itemId => `t${itemId}`);

        if (selectTro) {
          frameSettings.focus = { tros: [`t${selectTro}`] };
          // frameSettings.selectTro = `t${selectTro}`;
          // frameSettings.autoFollow = true;
          this.setState({ focusedItem: null, detailedDrawOpen: true });
          frameSettings.mapZoom = 12;
        } else {
          // frameSettings.focus = { tros: activeUserLocomotiveListIds.map(itemId => `t${itemId}`) };
          // frameSettings.mapZoom = this.initialZoom;
          // frameSettings.mapZoom = 14;

        }

        if (!selectTro && activeUserLocomotiveListIds.length) {
          // frameSettings.focus = { tros };
          // frameSettings.selectTro = 0;
        }

        frameSettings.visible = { tros };
      }
    }

    if (frameSettings.visible) {
      const activeplaceIds = this.getFilteredIds();
      if (!selectTro && activeplaceIds.length) {
        if (!nextProps.selectTro && this.props.selectTro) {
          // frameSettings.mapFitBounds = true;
          // delete frameSettings.mapZoom;
        }
      }
    }
    /** Useful iframe params */
    // frameSettings.mapFitBounds = true;

    /** Removal of properties that are not needed for a specific view */
    if (this.props.isRailation) {
      delete frameSettings.setVisibility;
      delete frameSettings.selectTro;
    }
    if (!this.initialZoom) {
      delete frameSettings.mapZoom;
      frameSettings.mapFitBounds = true;
    }

    // updating show railway
    if (this.props.isShowRailway !== isShowRailway) {
      frameSettings.showRailway = isShowRailway;
    }
    // updating show satellite
    if (this.props.isShowSatellite !== isShowSatellite) {
      frameSettings.mapType = isShowSatellite ? 'satellite' : 'roadmap';
    }
    // sending the new settings to the iframe
    if (Object.keys(frameSettings).length > 1) {
      this.updateFrameValues(frameSettings);
    }
  }

  initialiseIframeSettings(getInitialZoom) {
    const setVisibility = {};
    const {
      isTrackingMap,
      isHeatMap,
    } = this.props;
    const {
      currentView,
    } = this.state;

    const trackingMapPage = currentView === 'trackingmap';
    const tros = Object.keys(this.props.userLocomotiveListObject).map(itemId => `t${itemId}`);
    const mode = [];
    Object.keys(this.props.userLocomotiveListObject).forEach((Id) => {
      setVisibility[Id] = 1;
    });
    const settings = {
      focus: { tros },
      // visible: { tros: getInitialZoom ? tros : this.props.activeUserLocomotiveListIds.map(itemId => `t${itemId}`) },
      // setAllVisibile: this.props.isRailation ? 1 : 0,
      // autoFocus: false,
      // clearTraces: 1,
      mapFitBounds: !!getInitialZoom,
    };

    if (localStorage.getItem('fvm_user_initial_load') && (this.props.currentView === 'dashboard')) {
      settings.visible = { tros };
    }
    if (trackingMapPage && isTrackingMap) {
      mode.push('routes');
    }
    if (trackingMapPage && isHeatMap) {
      mode.push('heatmap');
    }
    if (mode.length) {
      settings.mode = mode.join(',');
    }
    return settings;
  }


  updateFrameValues(newVals) {
    if (window.stecMapIframe) {
      window.stecMapIframe.postMessage({
        ...newVals,
      }, '*');
    }
  }

  buildIfraim() {
    return (
      <Iframe
        url={this.iframeUrl}
        width="100%"
        height="100%"
        id="stecMapIframe"
        className="iframeFinder"
        display="initial"
        position="relative"
        name="stecMapIframe"
        allowFullScreen
      />);
  }

  handleItemSelected(item) {
    const focusedItem = item ? { ...item } : null;
    this.setState({ focusedItem, detailedDrawOpen: true });
    if (focusedItem) {
      this.props.setRemoveFocusId(this.props.selectTro);
    }
    if (item && item.id) {
      const frameSettings = {
        railation: {
          focusedFilterItemId: item.id,
        },
        mapZoom: 14,
        // selectTro: item.id,
        // ...IFRAME_CONST_SETTINGS,
        focus: { tros: [item.id] },
      };
      frameSettings.autoFocus = false;
      setTimeout(() => {
        this.updateFrameValues(frameSettings);
      }, 300);
    }
  }

  handleClearAll() {
    const { selected } = this.state;
    Object.keys(selected).forEach((item) => {
      selected[item] = false;
    });
    const secondLevel = false;
    this.setState({ focusedItem: null, detailedDrawOpen: false, selected, secondLevel });
    this.updateFilterCatInfo(selected);
  }

  updateFilterCatInfo(selectedItems) {
    const { catLst, searchTerm } = this.state;
    const selected = Object.keys(selectedItems).filter(key => selectedItems[key]);
    const categories = catLst.filter(cat => selected.includes(cat.Name)).map(cat => cat.id);
    this.setState({ resultPageNumber: 1 });

    const frameSettings = {
      railation: {
        filterInfo: {
          categories,
          resultsPerPage: 100,
          page: 1,
        },
      },
    };

    if (searchTerm) {
      frameSettings.railation.filterInfo.search = searchTerm;
    }
    this.updateFrameValues(frameSettings);
  }

  getFilteredIds() {
    const { detailedCatLst } = this.state;
    return detailedCatLst.map(item => item.id);
  }

  onSearchTermChanged(event) {
    const searchTerm = event.target.value;
    const { catLst, selected } = this.state;
    const selectedItems = Object.keys(selected).filter(key => selected[key]);
    const categories = catLst.filter(cat => selectedItems.includes(cat.Name)).map(cat => cat.id);
    this.setState({ resultPageNumber: 1 });

    const frameSettings = {
      // mapFitBounds: true,
      railation: {
        filterInfo: {
          search: categories.length ? searchTerm : '',
          categories,
          resultsPerPage: 100,
          page: 1,
        },
      },
    };

    if (searchTerm.length > 2 || !searchTerm) {
      this.updateFrameValues(frameSettings);
    }
    this.setState({ searchTerm });
  }

  handleChange(selectedCheckbox) {
    const { selected } = this.state;
    selected[selectedCheckbox] = !selected[selectedCheckbox];
    const secondLevel = Object.values(selected).includes(true);
    this.setState({ selected, secondLevel });
    this.updateFilterCatInfo(selected);
  }

  getCatName() {
    const { catLst } = this.state;
    const filterCatObj = {};
    catLst.forEach((cat) => {
      filterCatObj[cat.id] = cat.Name;
    });
    return filterCatObj;
  }

  loadNextPage() {
    const { catLst, searchTerm, resultPageNumber, selected } = this.state;
    const selectedItems = Object.keys(selected).filter(key => selected[key]);
    const categories = catLst.filter(cat => selectedItems.includes(cat.Name)).map(cat => cat.id);

    const frameSettings = {
      railation: {
        filterInfo: {
          categories,
          search: categories.length ? searchTerm : '',
          resultsPerPage: 100,
          page: resultPageNumber + 1,
        },
      },
    };

    this.setState({ resultPageNumber: resultPageNumber + 1 });

    this.updateFrameValues(frameSettings);
  }

  clearFocuedItem() {
    this.setState({ focusedItem: null, detailedDrawOpen: true });
  }

  render() {
    const { selected, catLst, secondLevel, focusedItem, detailedCatLst, searchTerm, currentView, foundItemCount, detailedDrawOpen } = this.state;
    const { isRailation, open, t, classes } = this.props;
    const style = {
      // opacity: !showMap ? 0.2 : 1,
      width: '100%',
    };

    // if (isRailation) {
    //   style['paddingLeft'] = `${padding}px`;
    // } else {
    //   style['paddingRight'] = `${padding}px`;
    // }

    return (
      <div style={{ height: '100%', display: 'flex' }}>
        {
          !isRailation ? (
            <div
              className={classNames(
                'fvm-iframe-container',
                open && !secondLevel && (isRailation ? classes.openDrawLeftFirstLevel : classes.openDrawRightFirstLevel),
                open && secondLevel && (isRailation ? classes.openDrawLeftSecondLevel : classes.openDrawRightSecondLevel),
              )} style={style}
            >
              {this.iframe}
            </div>
          ) : null
        }

        <SecondaryFilterDrawers
          ListItems={detailedCatLst}
          catNames={this.getCatName()}
          open={open && secondLevel}
          right={!isRailation}
          fullHightScroll={isRailation || currentView === 'trackingmap'}
          handleItemSelected={this.handleItemSelected}
          searchTerm={searchTerm}
          onSearchTermChanged={this.onSearchTermChanged}
          loadNextPage={this.loadNextPage}
          foundItemCount={foundItemCount}
          t={t}
        />

        <PrimaryFilterDrawers
          ListItems={catLst}
          open={open}
          selected={selected}
          isRailation={isRailation}
          right={!isRailation}
          handleChange={this.handleChange}
          handleClearAll={this.handleClearAll}
          t={t}
        />

        <DetailedDrawerView
          detailedItem={focusedItem}
          open={(focusedItem !== null) && detailedDrawOpen}
          selected={selected}
          catNames={this.getCatName()}
          clearFocuedItem={this.clearFocuedItem}
          right={!isRailation}
          handleChange={this.handleChange}
          handleItemSelected={this.handleItemSelected}
          t={t}
        />

        {
          isRailation ? (
            <div className="fvm-iframe-container" style={style}>
              {this.iframe}
            </div>
          ) : null
        }

      </div>
    );
  }
}

MapIframe.propTypes = {
  showLoading: PropTypes.func.isRequired,
  isTrackingMap: PropTypes.bool.isRequired,
  selectAll: PropTypes.bool.isRequired,
  isDemoUser: PropTypes.bool.isRequired,
  isHeatMap: PropTypes.bool.isRequired,
  isShowRailway: PropTypes.bool.isRequired,
  isShowSatellite: PropTypes.bool.isRequired,
  selectTro: PropTypes.number.isRequired,
  period: PropTypes.objectOf(PropTypes.object).isRequired,
  userLocomotiveListObject: PropTypes.objectOf(PropTypes.object).isRequired,
  activeUserLocomotiveListIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

const mapStateToProps = state => ({
  activeUserLocoId: [...state.activeUserLocomotiveListIds].pop() || null,
  period: state.period,
  selectTro: state.targetedUserLocomotiveId || 0,
  isShowRailway: state.showRailway,
  // currentView: state.currentView,
  // currentView: getCurrentView(), // temp test solution
  activeUserLocomotiveListIds: state.activeUserLocomotiveListIds,
  isShowSatellite: state.showSatellite,
  selectAll: state.selectAllDevices,
  isTrackingMap: state.trackingMap,
  isHeatMap: state.heatMap,
  isDemoUser: state.isDemoUser,
  locListUpdated: state.locListUpdated,
  userLocomotiveListObject: state.userLocomotiveList,
  isFvmFeaturesActive: state.isFvmFeaturesActive,
  closeDetailedDraw: state.closeDetailedDraw,
  languageFilesReloaded: state.languageFilesReloaded,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    showLoading: mapLoadingData,
    setRemoveFocusId,
    detailedDrawClosed,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(MapIframe));
