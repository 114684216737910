import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
// import 'babel-polyfill';
// import { unregister } from '../../ServiceWorker.js';
import * as serviceWorker from '../../service-worker-bk';

import classNames from 'classnames';
import { decode } from 'jwt-simple';
import moment from 'moment';
import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import { Redirect, Route, Switch } from 'react-router';
import { bindActionCreators } from 'redux';
import { changedView, setDemoUser, setIsOnline, userLanguageChanged } from '../../actions';
import FvmStatusDrivingIcon from '../../assets/svgs/status-driving';
import FvmStatusEquippedIcon from '../../assets/svgs/status-equipped';

import FvmStatusOffIcon from '../../assets/svgs/status-off';
import FvmStatusStandingIcon from '../../assets/svgs/status-standing';
import MediaCard from '../../components/MediaCard';
import NavigationSidebar from '../../components/NavigationSidebar/index';

import AccessDenied from '../../components/AccessDenied';
import Datasheet from '../../Pages/Datasheet';
import User from '../../components/User/index';
import LocomotiveList from '../../containers/LocomotiveList/index';
import Dashboard from '../../Pages/Dashboard';
import Faq from '../../Pages/Faq';
import LiveData from '../../Pages/LiveData';
import Logbook from '../../Pages/Logbook';
import Login from '../../Pages/AuthenticationViews/Login';
import ResetPassword from '../../Pages/AuthenticationViews/ResetPassword';
import Notification from '../../Pages/Notification';
import NotificationSettings from '../../Pages/NotificationSettings';
import Profile from '../../Pages/Profile';
import Railation from '../../Pages/Railation';
import Reporting from '../../Pages/Reporting';
import TrackingMap from '../../Pages/TrackingMap';
import UserAdministration from '../../Pages/UserAdministration';
import {
  cleanUpLocalStorage,
  fetchNrailUserSettings,
  FVM_BASE_URL,
  getUserLang,
  shouldUserReload,
} from '../../wp-rest-api/STEC-Communication';
import { VEHICLE_STATUS_NAMES } from '../../globalConstants/vechicelConsts';
import LogbookDrafts from '../../Pages/Logbook/DraftEntries';
import { NotConnectionBanner } from '../../components/BannerNotification';
import ModalLoginWindow from '../../Pages/AuthenticationViews/ModalLoginWindow';

// const USER_LANG = window.localStorage.getItem('i18nextLng');
// const getUsrLang = ()=> localStorage.getItem('i18nextLng') || 'en';
const ZOOM_ADJUSTED = window.localStorage.getItem('zoomActive');

if (getUserLang() === 'de') {
  moment.locale('de');
}
// const zoomFactor = ZOOM_ADJUSTED ? 0.8 : 1;
const zoomFactor = ZOOM_ADJUSTED ? Number(ZOOM_ADJUSTED) : 1;

const myNrailTheme = createMuiTheme({
  spacing: 8 * zoomFactor,
  typography: {
    // Use the system font.
    fontFamily: '"IBM Plex Sans", sans-serif;',
    fontSize: 14 * zoomFactor,
    '@media (max-width: 1024px)': {
      fontSize: 10 * zoomFactor,
    },
    caption: {
      // fontSize: 50 * zoomFactor,
    },



    h1: {
      fontSize: 32,
      marginBottom: '0.5rem',
      //lineHeight: '1.25rem',
      fontWeight: '300',
      '@media (max-width: 1024px)': {
        fontSize: 24,
      },
    },
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontSize: 14 * zoomFactor,
        fontFamily: '"IBM Plex Sans", sans-serif;',
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 16 * zoomFactor,
        paddingRight: 16 * zoomFactor,
      },
    },
    MuiTableHead: {
      fontWeight: 700,
    },
    MuiButtonItem: {
      label: {
        whiteSpace: 'no-wrap',
      },
      palette: {
        primary: {
          main: '#1cdd43',
        },
        error: {
          main: '#F44336',
          contrastText: '#FFFFFF',
          background: '#FDECEB',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#1cdd43',
      contrastText: '#FFFFFF',
      background: '#FDECEB',
    },
    secondary: {
      main: '#F44336',
      contrastText: '#FFFFFF',
      background: '#FDECEB',
    },
  },
  fvm: {
    zoomFactor,
  },
});

const setHiddenFlag = key => {
  localStorage.setItem(key, Date.now());
};

/**
 *
 */
class App extends Component {
  constructor(props) {
    super(props);
    const { t, i18n } = props;
    this.state = {
      showUserMsg: false,
      isDisableDismissButton: false,
      isDemoUser:
        localStorage.getItem('fvm_wp_api_user_display_name') === 'NRail Demo',
      focusedLocomotive: 0,
      loco: [],
      currentView: props.currentView || 'initial',
      userInfo: {
        imageUrl: localStorage.getItem('fvm_user_profile_image'),
        email: localStorage.getItem('fvm_wp_api_user_email'),
        displayName: localStorage.getItem('fvm_wp_api_user_display_name'),
        userFirstName: localStorage.getItem('fvm_wp_api_user_firstName'),
        userLastName: localStorage.getItem('fvm_wp_api_user_lastName'),
        userLng: localStorage.getItem('i18nextLng'),
      },
      isModalOpen: false,
      modalInfo: null,
    };
    this.props.newView();

    /*
     *
     * Global dependent events
     * */
    window.onhashchange = function () {
      this.props.newView();
    };
    /**
     * Using the line below will activate the demo account
     * on initial load if the user is already logged in
     */
    props.setDemoUser(
      localStorage.getItem('fvm_wp_api_user_display_name') === 'NRail Demo',
    );

    /**
     ********************************
     *  Controls for status icons.
     ********************************
     * - Off = Aus
     * - Equipped = Maschine aufgerüstet
     * -Standing = Motor an
     * - Driving = Lok fährt
     */

    this.machineStatus = {
      off: {
        icon: <FvmStatusOffIcon />,
        tooltip: t('tootltip.off'),
        value: 1,
      },
      equipped: {
        icon: <FvmStatusEquippedIcon />,
        tooltip: t('tootltip.machineStatusSystemsOn'),
        value: 2,
      },
      standing: {
        icon: <FvmStatusStandingIcon />,
        tooltip: t('tootltip.machineStatusMotorOn'),
        value: 3,
      },
      driving: {
        icon: <FvmStatusDrivingIcon />,
        tooltip: t('tootltip.machineStatusDriving'),
        value: 4,
      },
    };

    this.profilePage = this.profilePage.bind(this);
    // this.checkVerNo = this.checkVerNo.bind(this);
    this.notificationSettingsPage = this.notificationSettingsPage.bind(this);
    this.loogbookPage = this.loogbookPage.bind(this);
    this.logbookDraftPage = this.logbookDraftPage.bind(this);
    this.faq = this.faq.bind(this);
    this.accessDenied = this.accessDenied.bind(this);
    this.userAdministrationPage = this.userAdministrationPage.bind(this);
    // This function can be reduced to only the necessary functions for each view
    this.appFunctions = {
      updateUserInfo: this.updateUserInfo.bind(this),
      getIsLive: this.getIsLive.bind(this),
      displayHtmlInModal: this.displayHtmlInModal.bind(this),
      showDialog: this.showDialog.bind(this),
      showModal: this.showModal.bind(this),
      closeModal: this.closeModal.bind(this),
      t,
      locale: i18n?.language || 'en',
    };

    this.isLive = FVM_BASE_URL?.match(/fuerstvonmartin/) ? false : true;
    this.isDev = FVM_BASE_URL?.match(/dev2.fuerstvonmartin/) ? true : false;

    this.appConsts = {
      fvmURL: FVM_BASE_URL,
      fvmMyURL: FVM_BASE_URL?.replace('https://', 'https://my.'),
      stecBaseUrl: `https://nrail.stec-cloud.de/wt?id=c82f0bc3-6074-416b-8924-38e4b4b68e01${this.state.isDemoUser ? '&demo=true' : ''
        }`,
    };


  }

  componentDidMount() {
    // this.checkVerNo();
    // setInterval(this.checkVerNo, 60000); // checks every half hr
    window.removeEventListener('online', this.props.setIsOnline);
    window.removeEventListener('offline', this.props.setIsOnline);
    window.addEventListener('online', this.props.setIsOnline);
    window.addEventListener('offline', this.props.setIsOnline);

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.languageFilesReloaded &&
      this.props.languageFilesReloaded !== prevProps.languageFilesReloaded
    ) {
      // this.setState({ componentLanguageUpdateTrigger: this.props.languageFilesReloaded });
    }
    if (this.props.user?.accountName !== prevProps.user?.accountName) {
      this.initiateTracking();
    }
  }

  async checkVerNo() {
    const oldUserMsgTs = localStorage.getItem('userMsgTs') || null;
    const oldUserSuggestReloadTs =
      localStorage.getItem('userSuggestReloadTs') || null;
    if (!(oldUserMsgTs && oldUserSuggestReloadTs)) {
      return;
    }
    let {
      suggestRefresh,
      showUserMsg,
      userMsg,
      userMsgTs,
      isDisableDismissButton,
    } = await shouldUserReload(oldUserSuggestReloadTs, oldUserMsgTs);

    const hiddenTs = localStorage.getItem('hideReload');
    if (hiddenTs) {
      const timeElapsed = Date.now() - Number(hiddenTs);

      // if (timeElapsed > 30 * 1000) {
      //24 * (60 * (60 * (1000 = [1sec])) = [1min]) = [1hr]) =[1day]
      if (timeElapsed > 24 * 60 * 60 * 1000) {
        //(24hr * (60min * (60* 1000 [1sec])  [1min]) [1hr])) [1day]
        localStorage.removeItem('hideReload');
        if (this.props.isDevelopment) {
          suggestRefresh = suggestRefresh && true;
        }
      } else {
        suggestRefresh = false;
      }
    }

    this.setState({
      suggestRefresh,
      showUserMsg,
      userMsgTs,
      userMsg,
      isDisableDismissButton,
    });
  }

  getIsLive() {
    return this.isLive;
  }

  async initiateTracking() {
    // const rightsObj = await getRightsObjects();
    // const rightsObj2 = await getRightsObj().getRightsObjects();
    const notTrackableEmailDomains = ['.fuerstvonmartin.de', '.nrail.de'];
    const { trackable } = !notTrackableEmailDomains.find(domain =>
      this.props.user?.accountName?.match(domain),
    );

    if (!trackable) {
      return;
    }
    const trackingCode = {
      production: 'UA-125265242-2',
      staging: 'UA-125265242-3',
    };

    const platform =
      FVM_BASE_URL.replace('https://', '') === 'nrail.de'
        ? 'production'
        : 'staging';
    ReactGA.initialize(trackingCode[platform], {
      titleCase: false,
    });
  }

  showDialog({ title, contentText, actionButtons, time }) {
    this.setState({
      modalInfo: {
        classNames: 'fvm-dialog-show-accept-dialog',
        overlayClassName: 'modal-dialog-settings',
        modalData: {
          title,
          contentText,
          actionButtons,
          closeModal: () => {
            this.setState({ isModalOpen: false });
          },
        },
      },
      isModalOpen: true,
    });

    if (time) {
      setTimeout(this.closeModal.bind(this), 2000);
    }
  }

  displayHtmlInModal({ overlayClassName, classes, modalData }) {
    this.setState({
      modalInfo: {
        classNames: classes,
        overlayClassName,
        modalData,
      },
      isModalOpen: true,
    });
  }

  showModal({ forceShow, modalData, props }) {
    return (
      <Dialog
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        open={forceShow || this.state.isModalOpen}
        TransitionComponent={this.transition}
        keepMounted
        {...props}
        onClose={modalData.closeModal}
      >
        <DialogTitle className="fvm-dialog-title">
          {modalData.title}
        </DialogTitle>
        <DialogContent>
          {modalData.contentText && (
            <DialogContentText>{modalData.contentText}</DialogContentText>
          )}

          {modalData.dialogContent && modalData.dialogContent}
        </DialogContent>

        <DialogActions>{modalData.actionButtons}</DialogActions>
      </Dialog>
    );
  }

  transition(props) {
    return <Slide direction="up" {...props} />;
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  buildRoutes() {
    const allPages = [
      {
        path: 'profile',
        renderer: this.profilePage,
      },
      {
        path: 'notificationsettings',
        renderer: this.notificationSettingsPage,
      },
      {
        path: 'trackingmap',
        renderer: () => <TrackingMap {...this.appFunctions} />,
      },
      {
        path: 'reporting',
        renderer: () => <Reporting {...this.appFunctions} />,
      },
      {
        path: 'livedata',
        renderer: () => (
          <LiveData {...this.appFunctions} appConsts={this.appConsts} />
        ),
      },
      {
        path: 'datasheet',
        renderer: () => (
          <Datasheet {...this.appFunctions} appConsts={this.appConsts} />
        ),
      },
      {
        path: 'notification',
        renderer: () => (
          <Notification {...this.appFunctions} appConsts={this.appConsts} />
        ),
      },
      {
        path: 'useradministration',
        renderer: this.userAdministrationPage,
      },

      {
        path: 'logbook-all-entries',
        renderer: this.props.user.accessRights?.logbook_entries?.canRead
          ? this.loogbookPage
          : this.accessDenied,
      },
      {
        path: 'logbook-draft-entries',
        renderer: this.props.user.accessRights?.logbook_entries?.canRead
          ? this.logbookDraftPage
          : this.accessDenied,
      },

      // {
      //   path: 'faq',
      //   renderer: this.faq,
      // },
      // {
      //   path: 'login',
      //   renderer: () => (
      //     <Login
      //       appConsts={this.appConsts}
      //       {...this.appFunctions}
      //       setUserStateFunc={this.setIsLoggedIn}
      //       userLng={this.state.userInfo.userLng}
      //     />),
      // },
      {
        component: Railation,
        path: 'railation',
        renderer: () => (
          <Railation
            appConsts={this.appConsts}
            {...this.appFunctions}
          />
        ),
      },
    ];

    let key = 0;

    return allPages.map(page => {
      key += 1;

      if (!!Object.keys(this.props.user.accessRights).length)
        if (!['logbook-all-entries', 'faq', 'logbook-draft-entries'].includes(page?.path) && !this.props.user.accessRights?.['gateway']?.canRead) {
          return false;
        }

      return (
        <Route
          key={`route-${key}`}
          path={`/${page.path}`}
          render={page.renderer}
          exact
        />
      );
    });
  }

  loogbookPage() {
    return (
      <Logbook
        {...this.appFunctions}
        enqueueSnackbar={this.props.enqueueSnackbar}
        appConsts={this.appConsts}
      />
    );
  }

  logbookDraftPage() {
    return (
      <LogbookDrafts
        {...this.appFunctions}
        enqueueSnackbar={this.props.enqueueSnackbar}
        appConsts={this.appConsts}
      />
    );
  }

  faq() {
    return <Faq {...this.appFunctions} appConsts={this.appConsts} />;
  }

  accessDenied() {
    return <AccessDenied {...this.appFunctions} />;
  }

  profilePage() {
    return (
      <Profile
        setCurrentView={this.setCurrentView}
        appConsts={this.appConsts}
        {...this.state.userInfo}
        {...this.appFunctions}
        isDemoUser={this.state.isDemoUser}
        i18n={this.props.i18n}
      />
    );
  }

  notificationSettingsPage() {
    return (
      <NotificationSettings appConsts={this.appConsts} {...this.appFunctions} />
    );
  }

  userAdministrationPage() {
    return (
      <UserAdministration appConsts={this.appConsts} {...this.appFunctions} />
    );
  }

  updateUserInfo() {
    const userInfo = {
      imageUrl: localStorage.getItem('fvm_user_profile_image'),
      email: localStorage.getItem('fvm_wp_api_user_email'),
      displayName: localStorage.getItem('fvm_wp_api_user_display_name'),
      userFirstName: localStorage.getItem('fvm_wp_api_user_firstName'),
      userLastName: localStorage.getItem('fvm_wp_api_user_lastName'),
      userLang: localStorage.getItem('i18nextLng'),
      savedUserLang: localStorage.getItem('savedUserLang'),
    };

    const isDemoUser =
      localStorage.getItem('fvm_wp_api_user_display_name') === 'NRail Demo';
    /** Using this line will activate the demo account */
    this.props.setDemoUser(isDemoUser);
    this.setState({ isDemoUser });

    this.appConsts.stecBaseUrl = `https://nrail.stec-cloud.de/wt?id=c82f0bc3-6074-416b-8924-38e4b4b68e01${isDemoUser ? '&demo=true' : ''
      }`;
    this.setState({ userInfo });
  }

  renderLoggedInView() {
    const {
      // isLoggedIn,
      suggestRefresh,
      showUserMsg,
      userMsgTs,
      userMsg,
      isDisableDismissButton,
    } = this.state;
    const { isLoggedIn, isSessionValid, isOnline, isHideLoginUntilOnline } = this.props;
    const allDevices = Object.values(this.props.userLocomotiveListObject);
    const allLocomotivies = allDevices.filter(train => train.isTrain);
    const cranesList = allDevices.filter(train => train.isCrane);
    const drivingTrainList = allLocomotivies.filter(
      ({ live }) => live.statusVehicle === VEHICLE_STATUS_NAMES.DRIVING,
    );
    const equippedTrainList = allLocomotivies.filter(
      ({ live }) => live.statusVehicle === VEHICLE_STATUS_NAMES.EQUIPPED,
    );
    const standingTrainList = allLocomotivies.filter(
      ({ live }) => live.statusVehicle === VEHICLE_STATUS_NAMES.STANDING,
    );
    const offTrainList = allLocomotivies.filter(
      ({ live }) => live.statusVehicle === VEHICLE_STATUS_NAMES.OFF,
    );


    return (
      <div
        className={classNames(
          'page',
          this.props.isZoomEighty ? 'fvm-80-per-zoom' : '',
        )}
      >
        <User
          user={this.props.user}
          username={this.state.userInfo.displayName}
          userAvatarUrl={this.state.userInfo.imageUrl}
          baseUrl={this.appConsts.fvmMyURL}
          {...this.appFunctions}
          currentView={this.props.currentView}
          allLocomotivies={allLocomotivies}
          standingTrainList={standingTrainList}
          drivingTrainList={drivingTrainList}
          equippedTrainList={equippedTrainList}
          cranesList={cranesList}
          offTrainList={offTrainList}
          userLng={this.state.userInfo.userLng}
          i18n={this.props.i18n}
        />
        <NavigationSidebar
          currentView={this.state.currentView}
          {...this.appFunctions}
          baseUrl={FVM_BASE_URL}
          className=""
          isDev={this.isDev}
        />
        {
          <main className="fvm-main-content">
            <LocomotiveList
              hideControls={['profile', 'useradministration'].includes(
                this.props.currentView,
              )}
              {...this.appFunctions}
              enqueueSnackbar={this.props.enqueueSnackbar}
              appConsts={this.appConsts}
            />
            <div
              className={classNames(
                'fvm-detailed-page',
                this.props.isNavigationBarExpanded
                  ? 'fvm-menu-opened'
                  : 'fvm-menu-closed',
              )}
            >
              <NotConnectionBanner />
              <ModalLoginWindow {...this.appFunctions} open={!isLoggedIn && !isHideLoginUntilOnline} />
              <Switch>
                <Route
                  path="/"
                  render={() => <Dashboard {...this.appFunctions} />}
                  exact
                />
                {this.buildRoutes()}
                {/*<Route render={() => <Redirect to="/login"/>}/>*/}
                <Route render={() => <Redirect to={"/"} />} />
              </Switch>
            </div>
            {
              <MediaCard
                hidden={!showUserMsg && !suggestRefresh}
                title={
                  suggestRefresh
                    ? (userMsg &&
                      userMsg[getUserLang()].releaseNotes.updateAvailable) ||
                    this.props.t('userAdmin.updateAvailable')
                    : (userMsg &&
                      userMsg[getUserLang()].releaseNotes.informUserTitle) ||
                    this.props.t('userAdmin.informUserTitle')
                }
                // title={suggestRefresh ? this.props.t('userAdmin.updateAvailable') :
                // this.props.t('userAdmin.informUserTitle')}
                mainText={
                  suggestRefresh
                    ? (userMsg &&
                      userMsg[getUserLang()].releaseNotes
                        .updateAvailableText) ||
                    this.props.t('userAdmin.updateAvailable')
                    : (userMsg &&
                      userMsg[getUserLang()].releaseNotes.informUserText) ||
                    this.props.t('userAdmin.informUserText')
                }
                // mainText={suggestRefresh ? this.props.t('userAdmin.updateAvailableText') :
                // this.props.t('userAdmin.informUserText')}
                rejectButton={{
                  text: suggestRefresh ? 'button.later' : 'button.dismiss',
                  hidden:
                    !suggestRefresh && showUserMsg && isDisableDismissButton,
                  callBk: () => {
                    if (suggestRefresh) {
                      setHiddenFlag('hideReload');
                      this.setState({ suggestRefresh: false });
                      return;
                    }

                    if (showUserMsg) {
                      this.setState({ showUserMsg: false });
                      localStorage.setItem('userMsgTs', userMsgTs);
                    }
                  },
                }}
                details={
                  suggestRefresh
                    ? null
                    : {
                      text: 'button.showDetails',
                      callBk: async () => {
                        if (showUserMsg && isDisableDismissButton) {
                          localStorage.setItem('userMsgTs', userMsgTs);
                          this.setState({ showUserMsg: false });
                        }
                      },
                    }
                }
                releaseNotes={
                  suggestRefresh
                    ? null
                    : userMsg && userMsg[getUserLang()].releaseNotes
                }
                releaseNotesTitle={
                  suggestRefresh
                    ? null
                    : userMsg && userMsg[getUserLang()].releaseNotesTitle
                }
                approveButton={
                  suggestRefresh
                    ? {
                      text: 'button.refresh',
                      callBk: () => {
                        // window.location = `${}?etag=true`
                        // serviceWorker.unregister();
                        if (caches) {
                          // Service worker cache should be cleared with caches.delete()
                          caches.keys().then(function (names) {
                            for (let name of names) caches.delete(name);
                          });
                        }
                        localStorage.removeItem('userSuggestReloadTs');
                        setTimeout(() => window.location.reload(true), 700);
                      },
                    }
                    : null
                }
                imgUrl={null}
              />
            }
          </main>
        }
        {this.state.isModalOpen &&
          this.state.modalInfo &&
          this.showModal({ ...this.state.modalInfo })}
        {/*{console.log('valid sess', isSessionValid, isLoggedIn)}*/}
        {!isSessionValid &&
          this.showDialog({
            title: 'Please Login!',
            contentText: 'Session has expired please login again',
            actionButtons: [],
            time: 3000,
          })}
      </div>
    );
  }

  render() {
    const { isLoggedIn } = this.props;
    const token = this.props.location.search.match(/[?&]token=([^&]+).*$/);
    const userHasAnOldToken = localStorage.getItem('userToken');
    return (
      <MuiThemeProvider theme={myNrailTheme}>
        <Switch>
          {isLoggedIn || userHasAnOldToken ? (
            <Route render={() => this.renderLoggedInView()} />
          ) : null}
          <Route
            path="/login"
            render={() => (
              <Login
                appConsts={this.appConsts}
                t={this.props.t}
                {...this.appFunctions}
                userLng={this.state.userInfo.userLng}
              />
            )}
            exact
          />
          <Route
            path="/reset-passkey"
            render={() => (
              <ResetPassword
                t={this.props.t}
                token={token ? token[1] : token}
                exact
              />
            )}
          />
          <Route render={() => <Redirect to="/login" />} />
        </Switch>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  activeUserLocomotiveListIds: state.activeUserLocomotiveListIds,
  isOnline: state.isOnline,
  currentView: state.currentView,
  isDemoUser: state.isDemoUser || false,
  locListUpdated: state.locListUpdated,
  userLocomotiveListObject: state.userLocomotiveList,
  isNavigationBarExpanded: state.isNavigationBarExpanded,
  isZoomEighty: state.isZoomEighty,
  languageFilesReloaded: state.languageFilesReloaded,
  isDevelopment: state.isDevelopment,
  isLoggedIn: state.isLoggedIn,
  isHideLoginUntilOnline: state.isHideLoginUntilOnline,
  isSessionValid: state.isSessionValid,
  user: state.user.user,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      newView: changedView,
      setIsOnline,
      setDemoUser,
      userLocaleChanged: userLanguageChanged,
    },
    dispatch,
  );

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(App),
);
