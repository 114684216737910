import { withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classNames from 'classnames';
import React, { Component } from 'react';
import getFilterCategory from '../FilterCategories/';

const drawerWidth = 280 + 340;
const styles = theme => ({
  drawer: {
    // width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth * theme.fvm.zoomFactor,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    display: 'none',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6),
  },
  content: {
    flexGrow: 1,
  },
  listItemText: {
    color: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    fontFamily: 'inherit',
    lineHeight: 'inherit',
  },
  paperAnchorLeft: {
    width: drawerWidth * theme.fvm.zoomFactor,
  },
  paperAnchorRight: {
    width: drawerWidth * theme.fvm.zoomFactor,
  },
  externalLink: {
    cursor: 'pointer',

  },
  ItemTitle: {
    fontSize: '24px',
    fontWeight: 700,
    marginBottom: '10px',
  },
  textOverFlowEllip: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  detailedDescriptionText: {
    paddingLeft: 0,
    paddingTop: '10px',
  },
  detailedDescriptionTextDetailed: {
    whiteSpace: 'normal',
  },
  detailedDescriptionContainer: {
    flexWrap: 'wrap',
  },
});

class DetailedDrawerView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: {},
      open: false,
    };

    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.htmlGeneratorFunction = {
      paragraph: this.paragraphOutput,
      list: this.listOutput,
      heading: this.paragraphOutput,
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { open } = this.props;

    if (open !== this.state.open) {
      this.setState({ open });
    }
  }

  paragraphOutput(classes, data) {
    return (
      <React.Fragment>
        <Typography
          className={classNames(classes.detailedDescriptionTextDetailed)}
          component="span"
          color="textPrimary"
        >
          {data.text}
        </Typography>
      </React.Fragment>
    );
  }

  listOutput(classes, data) {
    return (
      <React.Fragment>
        <Typography
          className={classNames(classes.detailedDescriptionTextDetailed)}
          component="span"
          color="textPrimary"
        >
          <ul>
            {data.items.map(item => <li>{item}</li>)}
          </ul>

        </Typography>
      </React.Fragment>
    );
  }

  handleDrawerClose() {
    this.setState({ open: false });
    this.props.clearFocuedItem();
  }

  render() {
    const { right, detailedItem, classes, open, t } = this.props;

    if (detailedItem) {
      detailedItem.categories = detailedItem.categories || 0;
    }
    if (detailedItem && detailedItem.id === 'p324') {
      // if (detailedItem && detailedItem.id) {
      detailedItem.imgUrl = 'https://nrail.de/wp-content/uploads/2019/06/northrail.png';
      detailedItem.description = {
        time: 1559133321512,
        title: 'Unsere Dienstleitungen',
        blocks: [
          {
            type: 'paragraph',
            data: {
              text: `northrail ist als spezialisierter Assetmanager ein erfahrener und kompetenter Partner für die
                Vermietung von Diesel- und Elektrolokomotiven Wir wissen, dass es für unsere Kunden wichtig ist,
                schnell und flexibel auf Marktentwicklungen reagieren zu können – und das unabhängig von 
                langfristigen Investitionen. Darauf sind wir eingestellt. Und so verstehen wir auch unser Leistungspaket:`,
            },
          },
          {
            type: 'list',
            data: {
              text: '',
              items: [
                'Wir vermieten Diesellokomotiven für den Rangier-, Zubringer- und Streckendienst sowie Elektrolokomotiven',
                'Wir decken schnell und unkompliziert Ihren kurz-, mittel- und langfristigen Lokbedarf',
                'Wir finden eine individuelle und effiziente Mietlösung für Ihre Traktionsaufgaben. Das Spektrum verschiedener Leistungsklassen in unserer Flotte ermöglicht ein bedarfsweises Up- oder Downgrading – auch bei kurzfristiger Änderung Ihrer Anforderungen',
                'Wir bieten mit unseren Lokomotiven die Vermittlung passender Dienstleistungen rund um deren Betrieb an: Logistikberatung, Instandhaltung sowie Versicherung',
                'Wir begleiten Lokprojekte verschiedener Größenordnungen. Wir organisieren deren Finanzierung und die Vermietung',
              ],
            },
          },
        ],
      };
    }

    return (
      <Drawer
        className={classNames(classes.drawer, 'fvm-map-drawer')}
        classes={{
          paper: classNames({ [classes.drawerOpen]: open, [classes.drawerClose]: !open }),
        }}
        variant="persistent"
        anchor={right ? 'right' : 'left'}
        open={open}
      >
        <span className={`filter-close-button fvm-filter-controls${right ? ' right' : ' left'}`}>
          <IconButton onClick={this.handleDrawerClose}>
            {right ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </span>
        <Divider className="fvm-filter-list-divider" />
        <div className="fvm-detailed-filter-draw-img">
          <img
            className="img-fluid"
            alt="cat-img"
            src={detailedItem && detailedItem.imgUrl ? detailedItem.imgUrl : 'https://my.nrailone.dev2.fuerstvonmartin.de/assets/images/loc-default.jpg'}
          />
          {

            detailedItem && !detailedItem.imgUrl && (
              <i>{getFilterCategory(this.props.catNames[detailedItem.categories]).secondary}</i>
            )

          }

        </div>

        {
          detailedItem ?
            (
              <List>
                <ListItem
                  key={`${detailedItem.categoryName}-name`}
                >
                  <ListItemText
                    className="fvm-detailed-filter-item-body name"
                    primary={detailedItem.name}
                    classes={{
                      primary: classNames(classes.ItemTitle, classes.textOverFlowEllip),
                    }}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          color="textPrimary"
                          className="fvm-filter-item-name-text-group"
                        >
                          <i className="fvm-filter-item-icon">
                            {getFilterCategory(this.props.catNames[detailedItem.categories]).secondary}
                          </i>
                          <span className="fvm-sub-filter-item-category-name">
                          {t(`railationCategories.${this.props.catNames[detailedItem.categories]}`)}
                    </span>
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider />
                {
                  this.props.catNames[detailedItem.categories] === 'ships'
                    ?
                    <ListItem
                      key={`${detailedItem.categoryName}-address`}
                    >
                      <ListItemText
                        className="fvm-detailed-filter-item-body"
                        primary={
                          <React.Fragment>
                            <Typography component="span" color="textPrimary">
                              {t('railationDraws.detailed.destination')}: {detailedItem.destination}
                              <br />
                              {t('railationDraws.detailed.heading')}: {detailedItem.heading}°
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    :
                    <ListItem
                      key={`${detailedItem.categoryName}-address`}
                    >
                      <ListItemText
                        className="fvm-detailed-filter-item-body"
                        primary={
                          <React.Fragment>
                            <Typography component="span" color="textPrimary">
                              {detailedItem.contact.street},
                              <br />
                              {detailedItem.contact.postcode} {detailedItem.contact.city}, {detailedItem.contact.country}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                }
                <Divider />
                <ListItem
                  key={`${detailedItem.categories}-contact`}
                >

                  {
                    this.props.catNames[detailedItem.categories] === 'ships'
                      ? (
                        <ListItemText
                          className="fvm-detailed-filter-item-body"
                          primary={
                            <React.Fragment>
                              <Typography component="span" color="textPrimary">
                                {t('railationDraws.detailed.length')}: {detailedItem.length} m
                                <br />
                                {t('railationDraws.detailed.speed')}: {detailedItem.sog} kn
                                <br />
                                ETA: {detailedItem.eta}
                                <br />
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      )

                      : (
                        <ListItemText
                          className="fvm-detailed-filter-item-body"
                          primary={
                            <React.Fragment>
                              <Typography
                                className={classNames(classes.textOverFlowEllip)} component="span" color="textPrimary"
                              >
                                {/*{detailedItem.contact.phone || '###-###-####'}*/}
                                {detailedItem.contact.phone || ''}
                                <br />
                                <span
                                  className={classNames(classes.externalLink)}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.open(`${detailedItem.contact.website}`, '_blank');
                                  }}
                                >
                                  {detailedItem.contact.website}
                                </span>
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      )
                  }


                </ListItem>
                {detailedItem.description && (<Divider />)}
                {
                  detailedItem.description && (
                    <ListItem
                      key={`${detailedItem.categories}-description`}
                      className={classNames(classes.detailedDescriptionContainer)}
                    >
                      <ListItemText
                        className="fvm-detailed-filter-item-body"
                        primary={
                          <React.Fragment>
                            <Typography component="span" color="textPrimary">
                              {detailedItem.description.title}:
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      {
                        detailedItem.description.blocks.map((block) => {
                          return <ListItemText
                            className={classNames(classes.detailedDescriptionText)}
                            primary={this.htmlGeneratorFunction[block.type](classes, block.data)}
                          />;
                        })
                      }
                    </ListItem>
                  )
                }


              </List>
            ) : null
        }
      </Drawer>
    );
  }
}

DetailedDrawerView.propTypes = {};

export default withStyles(styles, { withTheme: true })(DetailedDrawerView);
