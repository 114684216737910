import { store } from '../../../index';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPersonAccount, getPersonAccountSettings } from '../../../apis/accounts-persons-api';
import { AccountsPersonsApiAxiosParamCreator } from 'nrail-user-management-api-client';
import { decode } from 'jwt-simple';
import { configureRequestHeaders, getUMConfigs } from '../../../apis';
import { getAccountAccessRights } from '../../../apis/user-apis';

const initialState = {
  user: {
    activeOrganisationId: null,
    settings: {
      language: localStorage.getItem('i18nextLng'),
    },
    userAvatarUrl:'/assets/images/default-user-image.png',
    accessRights: {},
  },
  status: 'idle',
  error: null,
};

export const fetchUserData = createAsyncThunk('user/fetchUserData', async () => {
  let { accountId, oldLoginId } = decode(localStorage.getItem('userToken'), '', true);
  // setting access token in store
  await configureRequestHeaders().then(resp => resp);
  const settings = await getPersonAccountSettings() || initialState.user.settings;

  let organisations = [];
  const { accessToken } = store.getState();
  if (accessToken) {
    try {
      const accessToken = decode(store.getState().accessToken, '', true);
      accountId = accessToken.accountId;
      oldLoginId = accessToken.oldLoginId || 0;
      organisations = accessToken.organisations;
    } catch (e) {
      console.log(e);
    }
  }

  let activeOrganisationId =  localStorage.getItem('activeOrganisationId');
  if(!activeOrganisationId || ( activeOrganisationId && !organisations.find(({ id }) => id === activeOrganisationId))){
    activeOrganisationId =  organisations[0].id;
    localStorage.setItem('activeOrganisationId', activeOrganisationId);
  }

  const umConfigs = await getUMConfigs();
  const userData = await getPersonAccount(accountId);
  const userAvatarUrl = await AccountsPersonsApiAxiosParamCreator(umConfigs).getPersonAccountAvatarImage(accountId, userData.avatarImageUrlSignature);
  const { status} = await fetch(`${umConfigs.basePath}${userAvatarUrl.url}`);
  const accessRights = (await getAccountAccessRights())[activeOrganisationId] || { };

  return {
    ...userData,
    activeOrganisationId,
    settings,
    oldLoginId,
    organisations,
    accessRights,
    userAvatarUrl: status === 200 ? `${umConfigs.basePath}${userAvatarUrl.url}` : initialState.user.userAvatarUrl,
  };
});


export const fetchUserImage = createAsyncThunk('persons/fetchUserImage', async () => {

});


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeUserLanguage: (state, action) => {
      localStorage.setItem('i18nextLng', action.payload);
      state.user.settings.language = action.payload;
    },
    addUserProps: (state, action) => {
      localStorage.setItem('i18nextLng', action.payload);
      state.user.settings = { ...state.user.settings, ...action.payload };
    },
    setUserActiveOrganisation: (state, action) => {
      localStorage.setItem('activeOrganisationId', action.payload);
      state.user.activeOrganisationId = action.payload;
    },
    setUserAvatarUrlRefresh: (state, action) => {
      state.user.userAvatarUrl = `${state.user.userAvatarUrl.replace(/&refresh=.+/, '')}&refresh=${`${Date.now()}`}`;
    },
    setDefaultUserImage: (state, action) => {
      state.user.userAvatarUrl = initialState.user.userAvatarUrl;
    },
  },
  extraReducers: {
    [fetchUserData.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.user = { ...state.user, ...action.payload };
    },
    [fetchUserData.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchUserData.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});


export const selectUser = state => state.user;
export const selectUserAccountId = state => state.user.accountId;

export const {
  changeUserLanguage,
  addUserProps,
  setUserActiveOrganisation,
  setUserAvatarUrlRefresh,
  setDefaultUserImage,
} = userSlice.actions;

export default {
  user: userSlice.reducer,
  userLanguage: userSlice.reducer,
};
