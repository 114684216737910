import '../logbook-utils/utils';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import LocomotiveSectionHeader from '../LocomotiveSectionDivider';
import Header from '../Header';
import Inspections from '../Inspections';
import { makeStyles } from '@material-ui/core/styles';
import DefectsTabPanel from '../DefectsTabPanel';
import StoppageTabPanel from '../Stoppage';
import { joiResolver } from '@hookform/resolvers/joi';
import classNames from 'classnames';

import {
  Box, Button,
  Grid,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  getLogbookEntryCreatorSignature,
  saveLogbookCommentEntry,
} from '../../../apis/logbook-apis';
import moment from 'moment';
import Comments from '../Comments';
import { useSelector } from 'react-redux';
import Badge from '@material-ui/core/Badge';
import SignAndSubmit from '../SignAndSubmit';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import {
  addEntriesToLocalStorage,
  markLogbookEntriesForDeletion,
  sendUnsavedEntriesToDb,
  setInitialValues, syncLogbookEntries,
} from '../logbook-utils/utils';
import { logbookSchema } from '../logbook-utils/validation-schema';
import GeneralTabPanel from '../GeneralTabPanel';
import DialogTwoButtons from '../../Dialogs/DialogTwoButtons';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../LoadingSpinner';
import { localeNumberFormatter } from '../../../StatelessFunctions/nummericManapulation';

const gridItemsSpacing = 2;

const useStyles = makeStyles((theme) => ({
  entryFormHeader: {
    backgroundColor: '#FFFFFF',
    borderBottom: '1px solid #dfe3e6',
    padding: '0 24px',
  },
  entryFormContainer: {
    borderTop: '1px solid #dfe3e6',
    display: 'flex',
    flexDirection: 'column',
    height: ' calc(100%)',
  },

  tabContainer: {
    overflowY: 'auto',
    padding: '0 24px 16px',
    height: combinedErrorList => (combinedErrorList.length ? '85%' : '100%'),
  },
  commentTab: {
    marginLeft: -24,
    marginRight: -24,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  commentContainer: {
    overflow: 'auto',
    flex: '1 1 auto',
    backgroundColor: '#F8F9FA',
    paddingBottom: 172,
    padding: '24px 8px',
    '&.commenting': {
      marginBottom: 321,
    },
  },
  addComment: {
    marginBottom: -32,
    marginTop: 'auto',
    padding: '0 8px',
    // backgroundColor: '#F8F9FA',
    boxShadow: ' 0px 0px 24px #00000029',
    borderTop: '1px solid #E0E0E0',
  },
  addCommentsContainer: {
    backgroundColor: '#FFFFFF',
    boxShadow: ' 0px 0px 24px #00000029',
    borderTop: '1px solid #E0E0E0',
    //position: 'absolute',
    // zIndex: 99,
    width: '100%',
    bottom: 0,
    '&.second-child': {
      flex: 1,
    },
  },

  driverSignatureContainer: {
    borderTop: '1px solid #dedede',
    marginTop: 32,
  },
  sectionLabel: {
    marginTop: 24,
    marginBottom: 16,
  },

  signatureImage: {
    maxWidth: 150,
  },
  errorListItem: {
    display: 'inline-flex',
    width: '100%',
  },
  errorList: {
    //height: '15vh',
    maxHeight: '160px',
    minHeight: '160px',
    overflow: 'auto',
    padding: '16px 24px',
    color: '#6b150b',
    backgroundColor: '#ffcac4',
  },
  tabContainerWrapper: {
    display: 'flex',
    flex: ' 1 1 auto',
    overflowY: 'hidden',
    flexDirection: 'column',
  },

  entryFormControlButtons: {
    marginLeft: 56,
    marginTop: -8,
    // paddingLeft: 20,
    // paddingRight: 20,
    '& >*': {
      marginRight: 16,
    },
  },
  tabErrorIndicatorIcon: {
    marginRight: 8,
  },
  tableHeaderWithIcon: {
    '& > span': {
      right: -8,
      color: '#FFFFFF',
    },
  },
  tabs: {
    '&>div>div': {
      display: 'inline-flex',
      borderBottom: '1px solid #E0E0E0',
      margin: '8px 24px 0 24px',
    },
  },
  validatorDivider: {
    '&:before': {
      content: '":"',
      paddingRight: 5,
    },
  },
  validatorArrow: {
    '&:before': {
      content: '">"',
      paddingRight: 5,
      paddingLeft: 5,
    },
  },

  [theme.breakpoints.down(378)]: {
    entryFormControlButtons: {
      display: 'none',
    },
  },
}));

const errorListOrderArray = [
  'general.referenceTimestamp',
  'general.referenceLocation',
  'general.vehicleOperatingHours_h',
  'general.mileage_km',
  'general.preparationService',
  'general.selectedV',
  'general.inspectionPerformedTimestamp_V1',
  'general.inspectionPerformedTimestamp_V3',
  'general.inspectionPerformedTimestamp_F1',
  'general.inspectionPerformedTimestamp_A1',
  'general.fuelAdded_l',
  'general.fuelFillingLevel_l',
  'general.hydraulicsState',
  'general.motorOilState',
  'general.motorOilAdded_l',
  'general.sandState',
  'general.isSandAdded',
  'defects.description',
  'defects.state',
  'stoppage.startTimestamp',
  'stoppage.vehicleLocation',
  'stoppage.state',
  'stoppage.endTimestamp',
  'stoppage.wasAuxiliaryVehicleUsed',
  'stoppage.lastOperationMode',
  'stoppage.description',
];

const tabNames = {
  stoppage: 'create-entry-stoppage',
  defects: 'create-entry-defects',
  general: 'create-entry-general',
  comments: 'entry-comments',
};

const FvMTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    fontWeight: 400,
    // color: 'rgba(0,0,0,0.87)'
    '&$wrapper': {
      backgroundcolor: 'red',
    },
  },

  disabled: {
    color: 'rgba(0,0,0,0.38)',

    '&$selected': {},
  },
  selected: {
    fontWeight: 500,
  },
  privateTabIndicator: {
    colorSecondary: {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(Tab);

const LogbookEntryForm = ({
  entry,
  locale,
  isOnline,
  activeLocoId,
  organisationId,
  lastInspectionPerformedTimestamp_A1,
  responseIndicator,
  updateLogbookEntriesLst = () => {
  },
  updateActiveEntryId = () => {
  },
  setIsFormModified,
  closeDrawer = () => {
  },
  commentsSignatures,
  comments,
  locoImgUrl,
  locoName = 'Loc name not provided'
}) => {
  const classes = useStyles();
  // const [adjustedFormInput] = useState({ ...( entry ) });
  const [currentEntryId, setCurrentEntryId] = useState(entry?.header?.logbookEntryId);
  const [isNewEntry, setIsNewEntry] = useState(entry?.header?.status === 'newEntry');
  const [isFinalised, setIsFinalised] = useState(entry?.header?.status === 'finalized' || !!entry.signatureImage);
  const [isEditable, setIsEditable] = useState(!isFinalised && ['created', 'newEntry'].includes(entry?.header?.status));
  const [isSaving, setIssSaving] = useState(false);
  const [isUnknownEntry, setIsUnknownEntry] = useState(entry?.unknownEntry);
  const { t, i18n } = useTranslation('');

  if (isNewEntry) {
    entry.header.vehicleIdRef = activeLocoId;
  }

  const [combinedErrorList, setCombinedErrorList] = useState([]);
  const [commentSaved, setCommentSaved] = useState(false);
  const [isDefect, setIsDefect] = useState(false);
  const [isStoppage, setIsStoppage] = useState(false);
  const [selectedV, setSelectedV] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [showSendNotificationCheckbox, setShowSendNotificationCheckbox] = useState(!!(entry?.createTimestamp && entry?.logbookEntryId));
  const [showInitialNotificationMessage, setShowInitialNotificationMessage] = useState(false);
  const [isSendOptionalNotification, setIsSendOptionalNotification] = useState(isDefect || isStoppage);
  const [isA1Required, setA1isRequired] = useState(false);
  const [userDialog, setUserDialog] = useState({});
  const [errorTabs, setErrorTabs] = useState({
    header: false,
    defects: false,
    stoppage: false,
  });


  const resetAllStates = () => {
    setIsNewEntry(entry?.header?.status === 'newEntry');
    setIsFinalised(entry?.header?.status === 'finalized' || !!entry.signatureImage);
    setIsEditable(!isFinalised && ['created', 'newEntry'].includes(entry?.header?.status));
    setIssSaving(false);
    setIsUnknownEntry(entry?.unknownEntry);
    setCombinedErrorList([]);
    setCommentSaved(false);
    setIsDefect(false);
    setIsStoppage(false);
    setSelectedV('');
    setShowErrors(false);
    setShowSendNotificationCheckbox(!!(entry?.header?.createTimestamp && entry?.header?.logbookEntryId));
    setShowInitialNotificationMessage(false);
    setIsSendOptionalNotification(isDefect || isStoppage);
    setA1isRequired(false);
    setUserDialog({});
    setCurrentEntryId(entry?.header?.logbookEntryId);
    setErrorTabs({
      header: false,
      defects: false,
      stoppage: false,
    });

    logbookForm.reset({ ...entry });
  };

  const [signatureImage, setSignatureImage] = useState(
    entry ? entry.signatureImage : null,
  );

  const [activeTab, setActiveTab] = useState(tabNames.general);
  const logbookForm = useForm({
    mode: 'all',
    defaultValues: {
      ...entry,
    },
    resolver: joiResolver(logbookSchema, {
      abortEarly: false,
      context: true,
      errors: {
        language: 'de'
      },
      // artifacts: true,
      allowUnknown: true,
    }),
  });

  useEffect(() => {
    if (isNewEntry && currentEntryId !== entry?.header?.logbookEntryId) {
      resetAllStates();
    }
  }, [entry?.header?.logbookEntryId]);

  const handleSubmit = (checkRequired = false) => async (...inputArgs) => {
    logbookForm.setValue('checkRequired', checkRequired, { shouldDirty: true });
    const resp = await logbookForm.handleSubmit(onSubmit)(...inputArgs);
    setShowErrors(true);
  };

  const finalErrorCheck = async () => {
    logbookForm.setValue('checkRequired', true, { shouldValidate: true });
    await logbookForm.trigger();

    console.log(logbookForm.formState.errors);
    if (!!Object.keys(logbookForm.formState.errors).length) {
      updateErrors(logbookForm.formState.errors);
    }
    setShowErrors(true);
    return !!Object.keys(logbookForm.formState.errors).length;
  };


  const user = useSelector(state => state.user.user);

  const handleTabChange = (event, newValue) => {
    const tabToValidate = activeTab.replace('create-entry-', '');

    if (entry) {
      if ('stoppage' === tabToValidate) {
        logbookForm.trigger('stoppage');
      }
      if ('defects' === tabToValidate) {
        logbookForm.trigger('defects');
      }
      if ('general' === tabToValidate) {
        logbookForm.trigger('header');
        logbookForm.trigger('inspections');
        logbookForm.trigger('vehicleState');
      }
    }

    setActiveTab(newValue);
  };
  const handleDefectsToggled = () => {
    setIsDefect(!isDefect);
    if (isDefect) {
      setErrorTabs({
        ...errorTabs,
        defects: false,
      });
    }

    logbookForm.setValue('defects', logbookForm.getValues()?.defects, { shouldDirty: true });
  };

  const handleStoppageToggled = () => {
    setIsStoppage(!isStoppage);
    if (isStoppage) {
      //logbookForm.control.register('stoppage.affectedComponents', { value: [] });
      setErrorTabs({
        ...errorTabs,
        stoppage: false,
      });
    }
    logbookForm.setValue('stoppage', logbookForm.getValues()?.stoppage, { shouldDirty: true });
  };

  const handleLoadSignature = () => {
    const { logbookEntryId } = entry.header;
    if (logbookEntryId && organisationId) {
      getLogbookEntryCreatorSignature({
        organisationId,
        logbookEntryId,
      }).then((sigBlog) => {
        if (sigBlog) {
          const fileReader = new FileReader();
          fileReader.onload = e => setSignatureImage(e.target.result);
          fileReader.readAsDataURL(sigBlog);
        }
      });
    }
  };


  const updateErrors = (errors) => {
    let tempErrors = [];
    const tempErrorsMap = {};
    const tempErrorsJoi = logbookSchema.validate(logbookForm.getValues(), {
      abortEarly: false,
      // artifacts: true,
      allowUnknown: true,
    });
    tempErrorsJoi?.error?.details?.forEach((err) => {
      if (err?.context?.key) {
        tempErrorsMap[err?.context.key] = {
          ...err.context,
          ...(err.context?.limit !== undefined ? { limit: localeNumberFormatter(err.context.limit, i18n.language) } : {}),
        };
      }
    });
    // console.log(tempErrorsJoi, errors);
    const extractInfo = (errorObj, sectionName) => {
      tempErrors = [
        ...tempErrors,
        ...Object.keys(errorObj).map((errorField) => {
          return {
            key: `${sectionName}.${errorField}`,
            label: `${t(`labels.${sectionName}`)} >  ${t(`joi.messages.${errorObj[errorField].type}`, {
              ...tempErrorsMap[errorField],
              label: `${t(`logbook.labels.${sectionName}.${errorField}`)}: `,
            })}`,
            // label: `${t(`labels.${sectionName}`)} > ${t(`logbook.labels.${sectionName}.${errorField}`)}:
            // ${t(`labels.error`)}`,
          };
        })];
    };

    if (errors.header) {
      extractInfo(errors.header, 'general');
    }

    if (errors.inspections) {
      extractInfo(errors.inspections, 'general');
    }

    if (errors.vehicleState) {
      extractInfo(errors.vehicleState, 'general');
    }

    if (errors.defects) {
      extractInfo(errors.defects, 'defects');
    }

    if (errors.stoppage) {
      extractInfo(errors.stoppage, 'stoppage');
    }

    setCombinedErrorList(tempErrors);
    setErrorTabs({
      defects: !!errors.defects,
      header: !!errors.header,
      stoppage: !!errors.stoppage,
    });
  };

  const cleanUpSignature = (entry) => {
    if (!entry?.signatureImage) {
      console.warn('entry has no signature');
      return entry;
    }
    return {
      ...entry,
      signatureImage: entry.signatureImage?.replace(
        'data:image/png;base64,',
        '',
      ),
    };
  };
  const handleSaveComment = async (commentData) => {
    const { logbookEntryId } = entry?.header;
    saveLogbookCommentEntry(organisationId, logbookEntryId, cleanUpSignature(commentData))
      .then(({ data }) => {
        if (data && data.resourceId) {
          responseIndicator(t('labels.commentSaved'), 'success');
          updateLogbookEntriesLst();
          setTimeout(() => {
            updateActiveEntryId(logbookEntryId);
          }, 500);
          setIsFormModified(false);
          setCommentSaved(true);
        }
      }).catch((err) => {
        responseIndicator(
          `${t('labels.errorSavingLogEntry')} (${err?.response?.status})`,
          'error',
        );
      });
  };

  const onFinaliseEntry = async (data) => {
    if (data?.signatureImage) {
      logbookForm.setValue('signatureImage', cleanUpSignature(data));
      const resp = await handleSubmit(true)();
      console.log(resp);
    }
  };
  const onSubmit = async (data) => {
    //clone using stringify since no methods are needed
    const logbookEntryStr = JSON.stringify(data);
    const logbookEntry = JSON.parse(logbookEntryStr);
    logbookEntry.isUnSynced = true;


    if (!logbookEntry.hasOwnProperty('vehicleState')) {
      logbookEntry.vehicleState = {};
    }

    Object.keys(logbookEntry?.vehicleState).map(key => {
      if ([null, undefined].includes(logbookEntry?.vehicleState?.[key]))
        delete logbookEntry?.vehicleState?.[key];
      else {
        if (['fuelAdded_l', 'fuelFillingLevel_l', 'mileage_km', 'motorOilAdded_l', 'vehicleOperatingHours_h'].includes(key)) {
          if (logbookEntry?.vehicleState?.[key])
            logbookEntry.vehicleState[key] = +logbookEntry?.vehicleState?.[key];
        }
      }
    });
    if (logbookEntry?.stoppage?.trainWeight_t)
      logbookEntry.stoppage.trainWeight_t = +logbookEntry?.stoppage?.trainWeight_t;

    if (selectedV !== 'V1' && logbookEntry.inspections) {
      logbookEntry.inspections.inspectionPerformedTimestamp_V1 = null;
    }

    if (selectedV !== 'V3' && logbookEntry.inspections) {
      logbookEntry.inspections.inspectionPerformedTimestamp_V3 = null;
    }

    logbookEntry.header.referenceTimestamp = moment(logbookEntry.header.referenceTimestamp).toISOString();

    if (!isDefect) {
      logbookEntry.defects = null;
    }

    if (!isStoppage) {
      logbookEntry.stoppage = null;
    } else {
      if (logbookEntry?.stoppage) {
        if (logbookEntry.stoppage?.state !== 'resolved') {
          logbookEntry.stoppage.endTimestamp = null;
        }
      }
    }

    if (organisationId && logbookEntry?.header?.vehicleIdRef) {
      setIssSaving(true);
      let msg = 'labels.logbookEntryFailedToSave';
      let msgType = 'error';
      let closeOutEntry = false;

      const processResponse = (resp) => {
        if (Array.isArray(resp?.savedEntries)) {
          const { savedEntries } = resp;
          let objMap = {};
          let lastObect = {};
          savedEntries.flat().forEach(({ logbookEntryId, response, err }) => {
            objMap[logbookEntryId] = { ...response, err };
            lastObect = { ...response, err };
          });
          const {
            resourceId,
            notificationResultCode,
            err,
          } = objMap[logbookEntry?.header?.logbookEntryId] || {};

          if (!err && (resourceId || notificationResultCode)) {
            msg = 'labels.logbookEntrySaved';
            msgType = 'success';
            closeOutEntry = !!logbookEntry.signatureImage;
            updateLogbookEntriesLst(resourceId);
          } else {
            if (lastObect?.response?.status === 400)
              msg = 'labels.logbookValidationError';
            else if (lastObect?.response?.status === 500)
              msg = 'labels.logbookServerError';
          }

          cleanupAfterSave(msg, msgType, closeOutEntry);
          if (!isNewEntry) {
            logbookForm.reset({ ...logbookEntry });
          }
        }

        return { msg, msgType, closeOutEntry };
      };

      const errorOnSaveResponse = (err) => {
        msg = 'labels.logbookEntryFailedToSave';
        msgType = 'error';
        closeOutEntry = false;
        console.dir(err);
      };

      const syncFunctionPointer = () => syncLogbookEntries().then((resp) => {
        if (resp === 'sync_in_process') {
          setTimeout(syncFunctionPointer, 1000);
        }

        return processResponse(resp);
      }).catch((err) => errorOnSaveResponse(err)).finally(() => {
        setIssSaving(false);
      });

      addEntriesToLocalStorage({
        organisationId,
        logbookEntry,
      }).then((resp) => {
        if (resp === 'saved_locally') {
          logbookForm.reset({ ...logbookEntry });
          cleanupAfterSave(undefined, undefined, !!logbookEntry.signatureImage);
        }

        if (resp === 'sync_in_process') {
          setTimeout(syncFunctionPointer, 1000);
        } else {
          return processResponse(resp);
        }

      }).catch((err) => errorOnSaveResponse(err));
    }
  };

  const cleanupAfterSave = (msg = 'labels.logbookEntrySavedLocally', msgType = 'success', closeDrawerAfterOperation = false) => {
    responseIndicator(t(msg), msgType);
    updateLogbookEntriesLst();
    setIsFormModified(false);
    setIssSaving(false);
    if (closeDrawerAfterOperation) {
      setTimeout(() => closeDrawer(true), 700);
    }
  };

  // UseEffects
  useEffect(() => {
    logbookForm.control.register('sendOptionalNotification', { value: isSendOptionalNotification });
    logbookForm.control.register('checkRequired', { value: false });
    logbookForm.control.register('defects.isDefect', { value: isDefect });
    logbookForm.control.register('stoppage.isStoppage', { value: isStoppage });
    logbookForm.control.register('inspections.selectedV', { value: selectedV });
    logbookForm.control.register('isA1Required', { value: isA1Required });


    const tempIsDefect = !!logbookForm.getValues('defects.description');
    const tempIsStoppage = !!logbookForm.getValues('stoppage.startTimestamp');
    setIsDefect(tempIsDefect);
    setIsStoppage(tempIsStoppage);
    setShowSendNotificationCheckbox(tempIsStoppage || tempIsDefect);
    setIsSendOptionalNotification(tempIsStoppage || tempIsDefect);

    let foundSelectedV = '';
    if (logbookForm.getValues('inspections.inspectionPerformedTimestamp_V1')) {
      foundSelectedV = 'V1';
    }
    if (foundSelectedV === '' && logbookForm.getValues('inspections.inspectionPerformedTimestamp_V3')) {
      foundSelectedV = 'V3';
    }

    setSelectedV(foundSelectedV);
    setInitialValues();
  }, [entry?.header?.logbookEntryId]);

  useEffect(() => {
    if (!entry) {
      //logbookForm.reset({ ...entry });
    }
  }, [entry]);

  useEffect(() => {
    logbookForm.setValue('sendOptionalNotification', isSendOptionalNotification);
  }, [isSendOptionalNotification]);

  useEffect(() => {
    if (isEditable) {
      const { referenceTimestamp } = entry.header || {};
      if (!lastInspectionPerformedTimestamp_A1) {
        setA1isRequired(true);
      } else if (referenceTimestamp) {
        const lastA1timestamp = moment(lastInspectionPerformedTimestamp_A1);
        if (moment(referenceTimestamp).isAfter(lastA1timestamp)) {

          const duration = moment.duration(moment(referenceTimestamp).diff(lastA1timestamp));
          const hours = duration.asHours();
          if (hours < 24) {
            setA1isRequired(false);
          } else {
            setA1isRequired(true);
          }
        } else {
          setA1isRequired(false);
        }
      }
    }

  }, [lastInspectionPerformedTimestamp_A1]);

  useEffect(() => {
    if (isFinalised && !signatureImage) {
      handleLoadSignature();
    }
  }, [handleLoadSignature, isFinalised, signatureImage]);

  useEffect(() => {
    const { errors } = logbookForm.formState;
    if (errors) {
      //console.log(errors?.vehicleState?.mileage_km?.ref?.select())
      updateErrors(errors);
    }
  }, [logbookForm.formState.errors]);

  useEffect(() => {
    logbookForm.setValue('defects.isDefect', isDefect);
  }, [isDefect, logbookForm.formState]);

  useEffect(() => {
    logbookForm.setValue('stoppage.isStoppage', isStoppage);
    setShowInitialNotificationMessage(
      isEditable &&
      !showSendNotificationCheckbox &&
      (
        [undefined, null].includes(logbookForm?.getValues('defects.description')) ||
        [undefined, null].includes(logbookForm?.getValues('stoppage.description'))
      ) &&
      (isDefect || isStoppage),
    );

  }, [isStoppage, logbookForm.formState, isEditable, showSendNotificationCheckbox]);

  useEffect(() => {
    logbookForm.setValue('inspections.selectedV', selectedV);
  }, [selectedV]);

  useEffect(() => {
    if (isEditable) {
      setIsFormModified(logbookForm.formState.isDirty);
    }

  }, [logbookForm.formState.isDirty]);

  return (
    <div key={entry?.header?.logbookEntryId} className={classes.entryFormContainer}>
      <LocomotiveSectionHeader
        className={classes.entryFormHeader}
        locoName={locoName}
        locoImgUrl={locoImgUrl}
        entry={entry}
        entryState={isFinalised ? entry?.header?.entryState : null}
        allowDelete={isEditable && !isNewEntry}
        allowRecreateEntry={isUnknownEntry}
        recreateEntry={() => {
          logbookForm.setValue('header.status', 'newEntry');
          logbookForm.setValue('unknownEntry', null);
          setIsUnknownEntry(false);
          setIsNewEntry(true);
        }}
        deleteEntry={() => {
          setUserDialog({
            title: t('logbook.labels.deleteEntryTitle_one', { count: 1 }),
            text: t('logbook.labels.confirmDeletionOfSelectedEntry_one', { count: 1 }),
            buttonLabels: {
              confirm: t('button.delete'),
              cancel: t('button.cancel'),
              color: 'secondary',
            },
            onAcceptClick: () => markLogbookEntriesForDeletion(organisationId, entry.header.logbookEntryId, (success) => {
              logbookForm.reset();
              const wasDeleted = ['successfully_deleted', 'only_deleted_locally'].includes(success);
              cleanupAfterSave(`labels.logbook_entry_${success}`,
                wasDeleted ? 'success' : 'error',
                wasDeleted,
              );
            }),
          });

        }}
        entryCreator={
          isNewEntry
            ? { ...user }
            : {
              ...user,
              firstName: entry?.header?.creatorAccountFirstName,
              lastName: entry?.header?.creatorAccountLastName,
            }
        }

        t={t}
        backGroundColorNumber={activeLocoId % 6}
      />
      {showErrors && combinedErrorList.length ? (
        <Box
          className={classes.errorList}
          key={`tab-panel-${tabNames.stoppage}`}
        >
          {errorListOrderArray.map(key => {
            let tempObj = combinedErrorList.find((errItem) => errItem?.key === key);
            if (tempObj?.label) {
              return (
                <p className={classes.errorListItem}>
                  <ErrorOutline
                    className={classes.tabErrorIndicatorIcon}
                    color="error"
                  />
                  {tempObj?.label}
                </p>
              );
            }
            return null;
          })}
        </Box>
      ) : null}

      <Tabs
        value={activeTab}
        className={classes.tabs}
        onChange={handleTabChange}
        scrollButtons="auto"
        indicatorColor="primary"
        textColor={'rgba(0,0,0,0.87)'}
        aria-label="scrollable auto tabs example"
      >
        <FvMTab
          label={
            <Fragment>
              <Badge
                className={classes.tableHeaderWithIcon}
                variant="dot"
                invisible
                color={'error'}
              >
                {
                  entry && logbookForm.formState.errors.header ||
                    logbookForm.formState.errors.inspections ||
                    logbookForm.formState.errors.vehicleState
                    ? (
                      <ErrorOutline
                        className={classes.tabErrorIndicatorIcon}
                        color="error"
                      />
                    ) : null}
                {t('labels.general')}
              </Badge>
            </Fragment>
          }
          {...a11yProps(tabNames.general)}
        />
        <FvMTab
          label={
            <Badge
              className={classes.tableHeaderWithIcon}
              variant="dot"
              invisible={
                !isDefect ||
                (entry && !entry.defects)
              }
              color={
                !entry && isDefect && errorTabs.defects ? 'error' : 'primary'
              }
            >
              {entry && logbookForm.formState.errors.defects ? (
                <ErrorOutline
                  className={classes.tabErrorIndicatorIcon}
                  color="error"
                />
              ) : null}
              {t('labels.defects')}
            </Badge>
          }
          disabled={(isFinalised && !entry.defects?.hasOwnProperty('state'))}
          {...a11yProps(tabNames.defects)}
        />
        <FvMTab
          label={
            <Badge
              className={classes.tableHeaderWithIcon}
              variant="dot"
              invisible={
                !isStoppage ||
                (entry && !entry.stoppage)
              }
              color={
                !entry && isStoppage && errorTabs.stoppage ? 'error' : 'primary'
              }
            >
              {entry && logbookForm.formState.errors.stoppage ? (
                <ErrorOutline
                  className={classes.tabErrorIndicatorIcon}
                  color="error"
                />
              ) : null}
              {t('labels.stoppage')}
            </Badge>
          }
          disabled={(isFinalised && !entry.stoppage?.hasOwnProperty('state'))}
          {...a11yProps(tabNames.stoppage)}
        />

        {entry?.header?.status === 'finalized' && isFinalised && user?.accessRights?.['logbook_entries/comments'].canRead ? (
          <FvMTab
            label={
              entry.header ? (
                <Badge
                  className={classes.tableHeaderWithIcon}
                  badgeContent={entry.header.commentsCount}
                  color="primary"
                >
                  {t('labels.comments')}
                </Badge>
              ) : (
                t('labels.comments')
              )
            }
            {...a11yProps(tabNames.comments)}
          />
        ) : null}

      </Tabs>
      <div
        className={classNames(
          classes.tabContainerWrapper,
          showInitialNotificationMessage || (showSendNotificationCheckbox && isSendOptionalNotification) ? 'notification-message' : '',
          isFinalised ? '' : 'new-entry',
          combinedErrorList.length && showErrors ? 'error-list' : '',
        )}
      >
        <Box className={`${classes.tabContainer}`}>
          <GeneralTabPanel
            t={t}
            hidden={activeTab !== tabNames.general}
            logbookForm={logbookForm}
            vehicleIdRe={activeLocoId}
            isA1Required={isA1Required}
            lastInspectionPerformedTimestamp_A1={lastInspectionPerformedTimestamp_A1}
            handleVSelectionChange={(e) => {
              setSelectedV(e.target.value);
              logbookForm.setValue('inspections.selectedV', e.target.value);
            }}
            selectedV={selectedV}
            gridItemsSpacing={gridItemsSpacing}
            isReadOnly={isFinalised}
            locale={locale}
          />
          <Box
            className={classes.tabContainerItem}
            hidden={activeTab !== tabNames.general}
            key={`tab-panel-${tabNames.general}`}
          >
            {isFinalised && signatureImage ? (
              <Grid className={classes.driverSignatureContainer}>
                <Typography variant="h6" className={classes.sectionLabel}>
                  {t('logbook.labels.locDriver')}
                </Typography>
                <img signatureImage
                  className={classes.signatureImage}
                  src={entry.isUnSynced && signatureImage ? `data:image/png;base64,${signatureImage.signatureImage}` : signatureImage}
                  alt="creator-signature"
                />
                <Typography variant="body1">
                  {
                    `${logbookForm.getValues('header.creatorAccountFirstName')} 
                    ${logbookForm.getValues('header.creatorAccountLastName')}`
                  }
                </Typography>
              </Grid>
            ) : null}
          </Box>
          <DefectsTabPanel
            form={logbookForm}
            hidden={activeTab !== tabNames.defects}
            isReadOnly={isFinalised}
            t={t}
            isDefect={isDefect}
            entryState={isFinalised ? entry?.header?.entryState : null}
            handleDefectsToggled={handleDefectsToggled}
          />
          <StoppageTabPanel
            isReadOnly={isFinalised}
            hidden={activeTab !== tabNames.stoppage}
            form={logbookForm}
            locale={locale}
            t={t}
            gridItemsSpacing={gridItemsSpacing}
            handleStoppageToggled={handleStoppageToggled}
            isStoppage={isStoppage}
          />
          {

            isFinalised
              ? (<Comments
                t={t}
                title={null}
                comments={comments}
                organisationId={organisationId}
                logbookEntryId={entry?.header?.logbookEntryId}
                entry={entry}
                user={user}
                commentsSignatures={commentsSignatures}
                commentSaved={commentSaved}
                onResetForm={() => setCommentSaved(false)}
                handleSaveComment={handleSaveComment}
                showSnackBar={responseIndicator}
                hidden={isEditable || activeTab !== tabNames.comments}
                userCanComment={user?.accessRights?.['logbook_entries/comments']?.canCreate}
              />) : null
          }
        </Box>
        <Box
          className={[
            classes.addCommentsContainer,
            !isNewEntry ? 'second-child' : '',
          ].join(' ')}
        >
          {isEditable ? (
            <SignAndSubmit
              t={t}
              disabled={isSaving}
              showDefaultNotificationMsg={showInitialNotificationMessage}
              showSendNotificationCheckbox={(!(entry?.defects || entry?.stoppage) && (isDefect || isStoppage)) || showSendNotificationCheckbox}
              isSendOptionalNotification={(!(!entry?.defects?.description || !entry?.stoppage?.startTimestamp) && (isDefect || isStoppage)) || isSendOptionalNotification}
              toggleSendNotification={() => {
                setIsSendOptionalNotification(!isSendOptionalNotification);
              }}
              formFields={{
                signatureImage: null,
              }}
              allowSave={logbookForm.formState.isDirty}
              isReadOnly={isFinalised}
              handleSave={handleSubmit()}
              onSaveSig={onFinaliseEntry}
              hasErrors={finalErrorCheck}
            />
          ) : null}
        </Box>
      </div>

      {
        userDialog?.onAcceptClick ?
          <DialogTwoButtons
            classes={{
              paper: classes.exportModal,
            }}
            id="entry-for-decision"
            title={userDialog?.title}
            dialogText={userDialog?.text}

            t={t}
            keepMounted
            value={true}
            open={true}
            buttonLabels={userDialog?.buttonLabels}
            onClose={() => {
              setUserDialog({});
            }}
            onSelectionMade={userDialog?.onAcceptClick}
          /> : null
      }

      {isSaving ? <LoadingSpinner /> : null}
    </div>
  );
}
  ;

const ignoredKeys = (sections = ['defects', 'stoppage', 'header']) => {
  const ignoredKeys = [
    'remarks',
    'measuresDescription',
    'state',
    'description',
  ];
  return sections
    .map(sec => {
      return ignoredKeys.map(key => {
        return `${sec}_${key}`;
      });
    })
    .flat();
};

function a11yProps(name) {
  return {
    key: `tab-${name}`,
    id: `nrl-tab-${name}`,
    'aria-controls': `tab-panel-${name}`,
    value: name,
  };
}

LogbookEntryForm.propTypes = {};

export default LogbookEntryForm;