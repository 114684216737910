import React from 'react';
import { login, logout } from '../../../apis/user-apis';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { generateTestAttribute } from '../../../utils/helperUtilities';
import PropTypes from 'prop-types';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import FVMNrailLogo from '../../../assets/svgs/nrail-logo';
import { useAuthStyles } from '../authStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**
 * page shown to the end user
 * @component
 * @return {JSX.Element} React component
 * @example
 * return (<Login />)
 */
const LoginForm = ({isModalLogin= false}) => {
  const componentName = LoginForm.displayName || '';
  const classes = useAuthStyles();
  const [accountName, setAccountName] = React.useState('');
  const [passkey, setPasskey] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [isAccountNameReadOnly, setAccountNameReadOnly] = React.useState(isModalLogin);
  const [isLoginFailed, setIsLoginFailed] = React.useState(false);
  const [animateMessage, setAnimateMessage] = React.useState('animated');
  const dispatch = useDispatch();
  const userHasDataToDelete = localStorage.getItem('userToken') && localStorage.getItem('userToken');
  const {t} = useTranslation('');

  const user = useSelector(({ user }) => user?.user);
  const isOnline = useSelector(({ isOnline }) => isOnline);
  const isHideLoginUntilOnline = useSelector(({ isHideLoginUntilOnline }) => isHideLoginUntilOnline);

  const handleAccountNameChanged = ({ target }) => {
    setAccountName(target.value);
  };

  const toggleShowPassword = ({ target }) => {
    setShowPassword(!showPassword);
  };
  const setFailedLogin = isLoginFailedParam => {
    setIsLoginFailed(isLoginFailedParam);
    if (isLoginFailed) {
      const animateMessageTemp =
        animateMessage === 'animated'
          ? 'shake animated'
          : 'bounceInDown animated';
      setAnimateMessage(animateMessageTemp);
    }
  };

  React.useEffect(() => {
    if(isModalLogin){
      setAccountName(user.accountName);
      setAccountNameReadOnly(true);
    }
  }, [isModalLogin]);

  const handlePasskeyChanged = ({ target }) => {
    setPasskey(target.value);
  };

  const handleLogin = async () => {
    const loginInfo = await login(accountName, passkey);

    if ([403, 401].includes(loginInfo?.status)) {
      setFailedLogin(true);
    }
  };

  return (
    <form className={classes.loginForm} noValidate autoComplete="off">
      <div className="fvm-logo fvm-cp-image">
        <FVMNrailLogo/>
      </div>
      {isLoginFailed ? (
        <p className={`${animateMessage} fvm-fill-red`}>
          {t('labels.incorrectPassUser')}
        </p>
      ) : null}
      <Grid container spacing={4} >
        <Grid item xs={12}>
          <TextField
            fullWidth
            disabled={isAccountNameReadOnly}
            id="outlined-basic"
            {...generateTestAttribute(componentName, 'username-input')}
            label={t('labels.username')}
            variant="outlined"
            value={accountName}
            onChange={handleAccountNameChanged}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="outlined-password-input"
            {...generateTestAttribute(componentName, 'password-input')}
            label={t('labels.password')}
            value={passkey}
            variant="outlined"
            onChange={handlePasskeyChanged}
            type={showPassword ? 'text' : 'password'}
            autoComplete="current-password"
            // variant="outlined"
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {passkey ? <Visibility/> : <VisibilityOff/>}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>

      <Button
        disabled={!isOnline || !( accountName && passkey )}
        variant="contained"
        {...generateTestAttribute(componentName, 'submit-button')}
        color="primary"
        onClick={handleLogin}
      >
        {t('labels.login')}
      </Button>
      <Button
        hidden={!userHasDataToDelete}
        disabled={!isOnline }
        variant="contained"
        {...generateTestAttribute(componentName, 'submit-button')}
        color="primary"
        onClick={() => {
          logout().then(() => {
            console.log('ended');
          });
        }}
      >
        {t('button.switchUser')}
      </Button>
      <Button
        hidden
        // hidden={isOnline || isHideLoginUntilOnline}
        variant="contained"
        {...generateTestAttribute(componentName, 'submit-button')}
        color="primary"
        onClick={() => {
          //dispatch(setHideLoginUntilOnline(true));
        }}
      >
        {t('labels.remindMeWhenIHaveInternetConnection')}
      </Button>
    </form>
  );
};

LoginForm.propTypes = {
  /**
   * Use to tell how the component should be rendered. If true the component will render in the center
   * of the page. If false it will render left aligned.
   */
  isFrontpage: PropTypes.bool,
};

//For dynamic key, id and test attribute creation
LoginForm.displayName = 'Login';
export default LoginForm;
