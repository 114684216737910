import React from 'react';
const TrainStatus = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
      <g transform="translate(-1493 -406)">
        <circle className={props.styleClass} cx="17" cy="17" r="17" transform="translate(1493 406)" />
        <g transform="translate(0 -0.001)">
          <path
            fill="#fff"
            d="M16,3,13,0H3L0,3V14l3.059,2.824L.519,20H3.082l1.6-2h6.637l1.6,2h2.563l-2.515-3.144L16,14ZM2,3.828,3.828,2H10V5H2ZM2.563,13.5v-1a.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1-.146-.029.5.5,0,0,1-.354-.471Zm11,0a.385.385,0,0,1-.012.059.491.491,0,0,1-.463.436c-.009,0-.016.005-.025.005h-2a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,.5.5ZM14,11H2.063V7H14Zm0-6H11V2h1.172L14,3.828Z"
            transform="translate(1502 413.001)"
          />
          <rect fill="#fff" width="10" height="2" transform="translate(1505.063 421.001)" />
        </g>
      </g>
    </svg>
  );
};
export default TrainStatus;
