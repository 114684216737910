import React from 'react';
import InfoOutlinedIcon from '../../node_modules/@material-ui/icons/InfoOutlined';

const numFormatter = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 1 });

const formatNumber = num => numFormatter.format(num);

export const localeNumberFormatter = (num, locale = 'de-DE') => {
  return new Intl.NumberFormat(locale, { maximumFractionDigits: 1 }).format(num);
};

const setNumberUnit = (cell, unit = '', defaultMsg = 'N/A') => {
  if (cell !== null && cell !== undefined) {
    return `${formatNumber(cell)} ${unit.trim()}`;
  }
  return defaultMsg;
};
const setNumberUnit2 = (cell, t, defaultMsg = 'tables.tRow.notApplicable') => {
  if (cell !== null && cell !== undefined) {
    const hrs = formatNumber(Math.floor(cell));
    const min = Math.round(( cell % 1 ) * 60);
    return `${hrs} ${t('units.time.hours')} ${min > 9 ? '' : 0}${min} ${t('units.time.minute')}`;
  }
  return t(defaultMsg);
};

const timeFormatTableCol = (cell, transFunc = () => 'Trans func missing!!!') => setNumberUnit2(cell, transFunc);
// const timeFormatTableCol = cell => setNumberUnit2(cell, localStorage.getItem('i18nextLng') === 'de' ? 'Std.' : 'h');

const kmFormatTableCol = (cell, unit = 'km') => setNumberUnit(cell, unit);

const elogFormatter = (text = 'k. A. E-Lok', tooptip = '') => {
  return (
    <span
      data-html
      data-tip={tooptip}
      className="fvm-extend-info-icon fvm-elog-tooltip"
    >
      {text}
      <i
        className="fvm-info-icon"
      >
        <InfoOutlinedIcon/>
      </i>
    </span>
  );
};

const getCurrentView = () => {
  const { origin, pathname } = window.location;
  const page = pathname.split('/')[1];
  const isWpSite = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  if (isWpSite()) {
    return 'railation';
  }

  if (!page) {
    return 'dashboard';
  }
  return page;

  // if (process.env.REACT_APP_FVM_ENV !== 'production'){
  //     if (!page) {
  //       return 'dashboard';
  //     }
  //     return page;
  // } else {
  //   if (origin.match('my.nrail') && origin.match('my.nrail').length) {
  //     if (!page) {
  //       return 'dashboard';
  //     }
  //     return page;
  //   }
  //   return 'railation';
  // }
};

export {
  elogFormatter,
  formatNumber,
  timeFormatTableCol,
  kmFormatTableCol,
  setNumberUnit,
  getCurrentView,
};
