const VEHICLE_STATUS_NAMES = {
  DRIVING: 'driving',
  EQUIPPED: 'standby',
  IDLE: 'idle',
  STANDING: 'idle',
  OFF: 'off',
}

export {
  VEHICLE_STATUS_NAMES
}