const zoomFactor = 1;
const drawerWidth = 210 * zoomFactor;
const fontWeightBold = 700;
const fvmColorBg03 = 'rgb(240,243,246)'; // bg-04 grey // #DFE3E6
const fvmColorBg04 = 'rgb(223,227,230)'; // bg-04 grey // #DFE3E6
const bodyColor = 'rgb(21,41,53)'; // bg-04 grey // #DFE3E6
// const brandPrimary = 'rgb(6,222,58)'; // bg-04 grey // #DFE3E6

const styles = theme => ({
  root: {
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
  },
  menuButton: {
    marginLeft: 12 * theme.fvm.zoomFactor,
    marginRight: 36 * theme.fvm.zoomFactor,
  },
  drawer: {
    // overflow: 'hidden',
  },
  drawerOpen: {
    width: drawerWidth * theme.fvm.zoomFactor,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    // width: theme.spacing(6)
    width: 52 * theme.fvm.zoomFactor,
    // width: 34,
  },
  closedDrawIcon: {
    // marginLeft: '10px',
    marginRight: `${15 * theme.fvm.zoomFactor}px !important`,
  },
  content: {
    flexGrow: 1,
  },
  listItemText: {
    extend: 'root',
    color: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    marginRight: 0,
    minWidth: 24,
  },
  paperAnchorLeft: {
    borderRight: 'unset',
  },
  fvmNavLink: {
    '&:hover': {},
  },
  fvmNavLinkFstDiv: {
    '&:hover': {
      position: 'fixed',
      width: `${220 * theme.fvm.zoomFactor}px`,
      maxWidth: `${220 * theme.fvm.zoomFactor}px`,
      zIndex: '10',
      backgroundColor: fvmColorBg03,
      left: 0,
    },
    fvmNavLinkFstDiv:{

    }
  },

  fvmMainNavigationFistDiv: {
    position: 'relative',
    overflow: 'hidden',
  },
  fvmMainNavigation: {
    zIindex: 1030,
    paddingTop: '80px',
    backgroundColor: '#ffffff',
    borderRight: `1px solid ${fvmColorBg04}`,
    height: '100vh',
    // height: '111vh',
  },
  fvmDrawerControls: {
    height: '61px',
    display: 'inline-flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  fvmDrawerControlsOpenedButton: {
    float: 'right',
    marginRight: '5px',
  },
  fvmDrawerControlsClosedButton: {
    marginRight: '2px',
  },
  fvmMenuList: {
    padding: 0,
    span: {
      whiteSpace: 'nowrap',
    },
  },

  fvmNavLinkLi: {
    padding: 0,
    fontWeight: fontWeightBold,
    height: '44px',
  },
  fvmMenuListAnchor: {
    fontWeight: fontWeightBold,
    color: bodyColor,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    maxHeight: '44px',
    '&:hover': {
      color: bodyColor,
      textDecoration: 'none',

    },
  },
  fvmNavLinkIcon: {
    display: 'inline-flex',
    marginRight: '5px',
  },
  fvmNavLinkIconClosed: {
    marginRight: `${15 * theme.fvm.zoomFactor}px`,
  },
  navMenuSubEntry: {
    marginLeft: 12,
    fontSize: 10
  },
  fvmMenuListItemOuter: {
    padding: 0,
  },
  fvmMenuListItemInner: {
    whiteSpace: 'nowrap',
  },
  divider:{
    width: "85%",
  },
  fontSizeRadioButton:{
    display: 'flex',
    justifyContent: 'center'
  },

  radioButtonLabel:{
   '&>span':{
     padding: 0,
   }
  },
  reloadButtonContainer:{
    width: '100%',
    marginTop: 100,
    display:'flex',
    justifyContent:'center'
  },
  zoomLevelContainer:{
    // width: '100%',
    marginTop: 100,
    display:'flex',
    justifyContent:'center',
    // flexDirection: 'column',
    // '& label': {
    //   width: '100%',
    //   margin: 0
    // }
  }



});

export default styles;
