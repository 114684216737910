import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControlLabel, Radio, RadioGroup,
} from '@material-ui/core';
import NrlPrimaryButtonWhite from '../../../Pages/Logbook/core/NrlPrimaryButton';
import DialogContentText from '@material-ui/core/DialogContentText';

const DialogTwoButtons = props => {
  const { onClose, open, t, title, buttonLabels, dialogText, lstVariant, optionsLst, ...other } = props;
  const [value, setValue] = React.useState(other.value);
  const radioGroupRef = React.useRef(null);
  React.useEffect(
    open => {
      if (!open) {
        setValue(other.value);
      }
    },
    [other.value],
  );

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    props.onSelectionMade(value);
    onClose();
  };


  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="two-buttons-dialog-title"
      open={open}
      onClose={handleCancel}
      {...other}
    >
      {title && <DialogTitle id="radio-dialog-title">{title}</DialogTitle>}
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          {dialogText}
        </DialogContentText>
        {optionsLst && Array.isArray(optionsLst) && (
          <RadioGroup
            row
            ref={radioGroupRef}
            aria-label="modal-radio-options"
            name="modal-radio-options"
            value={value}
            onChange={handleChange}
          >
            {optionsLst.map(({ id, name }) => (
              <FormControlLabel
                value={id}
                key={`modal-radio-options-${id}`}
                control={<Radio color="primary" />}
                label={name}
              />
            ))}
          </RadioGroup>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          variant="outlined"
          onClick={handleCancel}
          color={buttonLabels?.color || 'primary'}
        >
          { buttonLabels?.cancel
            ? buttonLabels.cancel
            : t('button.cancel')}
        </Button>
        <Button
          onClick={handleOk}
          disabled={value === null}
          color={buttonLabels?.color || 'primary'}
          variant="contained"
        >
          { buttonLabels?.confirm
            ? buttonLabels.confirm
            : t('button.apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogTwoButtons.propTypes = {
  // onClose: PropTypes.func.isRequired,
  // open: PropTypes.bool.isRequired,
  // value: PropTypes.string.isRequired,
};

export default DialogTwoButtons;
