import { withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/AddCircleOutline';
import SortArrow from '@material-ui/icons/ArrowUpward';
import Clear from '@material-ui/icons/Clear';
import Face from '@material-ui/icons/Face';
import Settings from '@material-ui/icons/Settings';
import Translate from '@material-ui/icons/Translate';
import Close from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/DeleteForeverSharp';
import Edit from '@material-ui/icons/EditSharp';
import Filter from '@material-ui/icons/Filter';
import FirstPage from '@material-ui/icons/FirstPage';
import DetailPanel from '@material-ui/icons/KeyboardArrowDown';
import LastPage from '@material-ui/icons/LastPage';
import Save from '@material-ui/icons/Save';
import Search from '@material-ui/icons/SearchSharp';
import NextPage from '@material-ui/icons/SkipNext';
import PreviousPage from '@material-ui/icons/SkipPrevious';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import Select from '@material-ui/core/Select';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import MaterialTable, { MTableEditField } from 'material-table';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import MarkdownRenderer from 'react-markdown-renderer';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { changedView } from '../actions';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FVMDialog from '../components/FVMDialog';
import LoadingSpinner from '../components/LoadingSpinner';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers';

import {
  updateTranslationEntry,
  getUserList,
  getUserLocoList,
  updateUserDateSetting,
  getUserTranslations,
  deployLanguageChanges,
  deployMyNrailApplication,
  isUserPermittedToSeePage,
  getDeploymentStatus,
  getVersionNumber,
  updateDeployment,
  updateVersionNo,
  updateUserInteraction,
  restoreSiteFromServer,
} from '../wp-rest-api/STEC-Communication.js';
import BarChart from '@material-ui/icons/BarChart';
import Refresh from '@material-ui/icons/Refresh';
import FVMTrackedButton from '../components/TrackedButton';

const lookup = {};
const lookupFirstName = {};
const lookupLastName = {};
const drawerWidth = '80%';
const dateOptions = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
};
const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    padding: '20px',
    backgroundColor: '#F8F8F8',
    // maxWidth: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6),
  },
  content: {
    flexGrow: 1,
  },
  listItemText: {
    // fontWeight: '700',
    fontSize: theme.typography.fontSize,
  },
  inline: {
    display: 'inline',
    maxWidth: '20%',
  },
  outerGrid: {
    maxHeight: 'calc(100vh - 160px - 81px)',
    backgroundColor: '#FFFFFF',
  },
  gridItems: {
    borderRight: '1px solid #DFE3E6',
  },
  formGroup: {
    // display: 'inline-flex',
    // flexDirection: 'row',
  },

  root: {
    whiteSpace: 'normal',
    ...theme.typography,
  },
  exportButtons: {
    backgroundColor: '#FFFFFF',
  },
  sheetTypeRadio: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      marginLeft: '18px',
    },
    display: 'inline-flex',
    flexDirection: 'row',
    marginLeft: '18px',
    label: {
      marginBottom: '0',
    },
  },
  radioButtonLabel: {
    marginBottom: '0',
  },

  sheetType: {
    display: 'flex',
  },
  sheetTypeLabel: {
    alignItems: 'center',
    display: 'flex',
    padding: '24px 0',
  },
  upperControlsCheckbox: {
    paddingLeft: '0',
    minWidth: '275px',
  },
  upperControlsInput: {
    '&:after': {
      borderBottom: 'none',
    },
    '&:before': {
      borderBottom: 'none',
    },

  },

  searchFieldContaier: {
    // margin: theme.spacing(6),
    // paddingLeft: '15px !important',
    backgroundColor: '#FFFFFF !important',
    border: '1px solid #DFE3E6 !important',
    // boxShadow: 'unset !important',
    // margin: 0,
    fontSize: '14px !important',
    underline: {
      '&:before': {
        display: 'none',
      },
    },
  },
  flexSpacebetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawLoader: {
    margin: '0 -20px',
  },
  zoomed: {
    zoom: '80%',
  },
  formControl: {
    width: '100%',
  },
  formControlLabel: {
    marginBottom: 30,
  },
  releaseNotes: {
    display: 'flex',
    justifyContent: 'space-between',
    '&>:first-child': {
      width: 750,
      padding: 50,
    },
    '&>:nth-child(2)': {
      width: 450,
      padding: 100,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      verticalAlign: 'middle',
    },

    marginTop: 30,
  },
  relaseNotesNav: {
    justifyContent: 'flex-start',
    width: 750,

  },
  submitButtons: {
    maxWidth: 300,
  },
  divider: {
    minWidth: '100%',
    marginTop: 50,
    marginBottom: 50,
  },
});

const TableIcons = {
  Delete,
  Add,
  Edit,
  LastPage,
  ResetSearch: Clear,
  Clear,
  Search,
  PreviousPage,
  NextPage,
  Check: Save,
  FirstPage,
  DetailPanel,
  SortArrow,
  Filter,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

/**
 *
 */
class UserAdministration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      myUserRole: '',
      isShowLoading: false,
      updateVerNum: false,
      versionNum: {
        saved: {
          major: null,
          minor: null,
          bugFix: null,
        },
        unsaved: {
          major: null,
          minor: null,
          bugFix: null,
        },
      },
      approvedBy: {
        mynrail_user: {
          status: true,
          approvedBy: ""
        },
        administrator: {
          status: true,
          approvedBy: ""
        },
      },
      deploymentApproved: false,
      userConfisDevEnv: false,
      deployToProduction: false,
      tabValue: 0,
      suggestRefresh: false,
      msgLinedToReleased: false,
      disableDismissButton: false,
      showUserMsg: false,
      dataSet: {},
      userLang: localStorage.getItem('i18nextLng'),
      defaultFilterTerm: '',
      open: false,
      userColumns: [
        {
          title: 'Avatar',
          field: 'imageUrl',
          render: rowData => <img
            src={rowData.imageUrl} style={{ width: 40, borderRadius: '50%' }} alt="user-avatar"
          />,
        },
        {
          title: 'Fist Name',
          field: 'firstName',
          filtering: false,
          lookupFirstName,
          editable: 'never',
        },
        {
          title: 'Surname',
          field: 'lastName',
          filtering: false,
          lookupLastName,
          editable: 'never',
        },
        {
          title: 'Company',
          field: 'company',
          filtering: false,
          lookupLastName,
          editable: 'never',
        },
        {
          title: 'Role',
          field: 'role',
          filtering: false,
          lookupLastName,
          editable: 'never',
        },
      ],
      columns: [

        {
          title: 'Locomotive Name',
          field: 'locName',
          editable: 'never',
          defaultFilter: '',
          cellStyle: {
            backgroundColor: '#039be5',
            color: '#FFF',
          },
          grouping: false,
        },
        {
          title: 'Locomotive ID',
          field: 'vehicleId',
          editable: 'never',
          grouping: false,
        },
        {
          title: 'Start Date',
          field: 'startDate',
          emptyValue: 'Not Limited',
          type: 'date',
        },
        {
          title: 'End Date',
          field: 'endDate',
          emptyValue: 'Not Limited',
          // cellStyle: (val, more) => more && more.startDate ? this.formatDate(more.startDate, val) : null,
          // (val, more) => more && more.endDate ? this.formatDate(more.endDate) : null,
          type: 'date',
        },
      ],
      data: [],
      userDataSet: [],
      drawData: [],

      LanColumns: [
        {
          title: 'Group',
          field: 'group',
          editable: 'never',
          grouping: true,

        },
        {
          title: 'Unique Identifier',
          field: 'specificKey',
          editable: 'never',
          grouping: false,

        },
        {
          title: 'Key',
          field: 'key',
          editable: 'never',
          grouping: false,
          hidden: true,

        },
        { title: 'German', field: 'de', grouping: false },
        { title: 'English', field: 'en', grouping: false },
        { title: 'Last Modified By', field: 'lstEditedBy', editable: 'never' },
        { title: 'Modification Date', field: 'modifiedDate', editable: 'never' },
        { title: 'Time', field: 'modifiedTime', editable: 'never', grouping: false },
      ],
      LanData: [],
      userCanViewPage: null,
      selectFile: null,
      activeReleaseNotesLng: 'EN',
      backupFiles: {},
      msgStartDate: new Date(),
      msgEndDate: moment(new Date()).add(3, 'days'),
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.uploadImageChanged = this.uploadImageChanged.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.addNewEntry = this.addNewEntry.bind(this);
    this.onRowUpdate = this.onRowUpdate.bind(this);
    this.onRowDelete = this.onRowDelete.bind(this);
    this.setDrawDataState = this.setDrawDataState.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deployLanguageFile = this.deployLanguageFile.bind(this);
    this.deployMyNrailApp = this.deployMyNrailApp.bind(this);
    this.setUpView = this.setUpView.bind(this);
    this.handleVersionNumChange = this.handleVersionNumChange.bind(this);
    this.handleReleaseNotesChange = this.handleReleaseNotesChange.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.updateReleaseNotes = this.updateReleaseNotes.bind(this);
    this.handleMsgEndDate = this.handleMsgEndDate.bind(this);
    this.handleMsgStartDate = this.handleMsgStartDate.bind(this);
    this.get_update_status = this.get_update_status.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.languageFilesReloaded !== prevProps.languageFilesReloaded) {
      this.setState({ componentLanguageUpdateTrigger: this.props.languageFilesReloaded });
    }
  }

  componentDidMount() {
    // this.setUpView();
    this.get_update_status();
  }

  componentWillUnmount() {

  }

  handleInputChange(event) {

  }

  uploadImageChanged(e) {

  }

  async setUpView() {
    // const { userIsAllowed, myUserRole } = await this.isUserPermittedToSeePage();
    const { userIsAllowed, myUserRole } = { };

    if (userIsAllowed) {
      this.fetchUserlst();
      this.get_update_status();
      this.fetchTranslation();
      this.setState({ userCanViewPage: userIsAllowed });
      this.setState({ myUserRole });
    }
  }

  handleLanguageChange(e) {
    const userLang = e.target.value;
    this.setState({ userLang });
  }

  formatDate(dateStr) {
    if (typeof dateStr === 'string') {
      const dateArr = dateStr.split('.');
      return new Date(`${dateArr[1]}-${dateArr[0]}-${dateArr[2]}`);
    }

    return dateStr;
  }

  isUserPermittedToSeePage() {
    return isUserPermittedToSeePage();
  }

  async fetchUserlst() {
    const userLst = await getUserList();
    const userDataSet = userLst.map(user => {
      const { userId, userName, lastName, firstName } = user;
      lookup[userId] = userName;
      lookupFirstName[userId] = firstName;
      lookupLastName[userId] = lastName;

      return user;
    });

    this.setState({ userDataSet });
    return userLst;
  }

  async fetchTranslation() {
    const resp = await getUserTranslations();
    const excludeArr = ['userAdmin->releaseNotes'];
    const LanData = Object.keys(resp).filter(key => !excludeArr.includes(key)).map(key => {
      const keyArray = key.split('->');
      const specificKey = keyArray.pop();
      const group = keyArray.join(',');
      return { key, group, specificKey, ...resp[key] };
    });
    const deReleaseNotes = resp['userAdmin->releaseNotes'].de;
    const enReleaseNotes = resp['userAdmin->releaseNotes'].en;

    delete
      this.setState({ LanData, enReleaseNotes, deReleaseNotes });
    // return userLst;
  }

  async updateUserSettings(data) {
    await updateUserDateSetting(data);
  }

  updateTranslation(data) {
    return updateTranslationEntry(data);
  }

  onRowUpdate(newData, oldData) {
    return new Promise(resolve =>
      setTimeout(() => {
        const { activeUser } = this.state;
        const { startDate, endDate } = newData;

        if (startDate) {
          newData.startDate = this.formatDate(startDate).toLocaleDateString('de-DE', dateOptions);
        }

        if (endDate) {
          newData.endDate = this.formatDate(endDate).toLocaleDateString('de-DE', dateOptions);
        }

        const { drawData } = this.state;
        const index = drawData.indexOf(oldData);

        const newDrawData = [...drawData];
        newDrawData[index] = newData;

        this.setState({ drawData: [...newDrawData] });
        this.updateUserSettings({ userId: activeUser.id, ...newData });
        resolve();
      }, 600));
  }

  addNewEntry(newData) {
    return new Promise(resolve =>
      setTimeout(() => {
        resolve();
        const data = [...this.state.data];
        data.push(newData);
        this.setState({ data });
      }, 600));
  }

  onRowDelete(oldData) {
    return new Promise(resolve =>
      setTimeout(() => {
        resolve();
        const data = [...this.state.data];
        data.splice(data.indexOf(oldData), 1);
        this.setState({ data });
      }, 600));
  }

  async setDrawDataState(event, dataRow) {
    const { userId } = dataRow;
    const open = true;
    // const drawData = addKeyToEntry(this.state.dataSet[user].locos);

    const drawData = Object.values(await getUserLocoList(userId));

    // userLocoLstData
    this.setState({ drawData, open, activeUser: { name: lookup[userId], id: userId } });
  }

  handleDrawerClose() {
    this.setState({ open: false });
  }

  handleChange(event, tabValue) {
    this.setState({ tabValue });
  };

  async deployLanguageFile() {
    const resp = await deployLanguageChanges();
    const msg = resp ? 'Files were successfully updated' : 'Fail to copy';
    this.setState({ deployToProduction: !resp });
    alert(msg);
  };

  async deployMyNrailApp() {
    this.setState({ isShowLoading: true });
    const resp = await deployMyNrailApplication();
    const msg = resp ? 'Files were successfully updated' : 'Fail to copy';
    this.updateDeploymentStatus(false);
    this.setState({ isShowLoading: false });
    this.setState({ deployToProduction: !resp });
    alert(msg);
  };

  buildUserDrawer({ open, classes, columns, drawData, activeUser }) {
    return (
      <Drawer
        className={classNames(classes.drawer)}
        classes={{
          paper: classNames({
            [classes.drawerClose]: !open,
            [classes.drawerOpen]: open,
          }),
        }}
        variant="temporary"
        anchor={'right'}
        open={open}
        onClose={this.handleDrawerClose}
      >
        <div className="fvm-report-upper-controls">
          <div className={classNames(classes.flexSpacebetween)}>
            <h1>{activeUser && activeUser.name}</h1>
            <IconButton
              color="inherit"
              aria-label="Open Close"
              onClick={this.handleDrawerClose}
              children={<Close/>}
            />
          </div>
        </div>

        <MaterialTable
          title={`Locomotive List`}
          columns={columns}
          data={drawData}
          editable={{
            // onRowAdd: this.addNewEntry,
            onRowUpdate: this.onRowUpdate,
            // onRowDelete: this.onRowDelete,
          }}
          options={{
            sorting: true,
            actionsColumnIndex: -1,
            grouping: true,
            headerStyle: {
              backgroundColor: '#01579b',
              color: '#FFF',
            },
            maxBodyHeight: '70vh',
            pageSize: 20,
            pageSizeOptions: [10, 20, 50],
            emptyRowsWhenPaging: false,

          }}

          components={{
            EditField: props => {
              if (props.value) {
                return <MTableEditField {...props} value={this.formatDate(props.value)}/>;
              }
              return <MTableEditField {...props} />;
            },
          }}

          icons={TableIcons}
        />
      </Drawer>
    );
  }

  async get_update_status() {
    const { deploymentStatus, backupFiles } = await getDeploymentStatus();
    const { versionNos } = await getVersionNumber();

    if (deploymentStatus) {
      const { administrator, mynrail_user } = deploymentStatus;
      const { approvedBy } = this.state;
      let deploymentApproved = false;

      if (administrator) {
        approvedBy.administrator = administrator;
      }
      if (mynrail_user) {
        approvedBy.mynrail_user = mynrail_user;
      }
      if (administrator && mynrail_user) {
        deploymentApproved = administrator.status && mynrail_user.status;
      }
      this.setState({ approvedBy, deploymentApproved, backupFiles });
    }

    if (versionNos) {
      const { current } = versionNos;
      this.setState({
        versionNum: {
          saved: { ...current },
          unsaved: { ...current },
        },
      });
    }
  }

  async updateDeploymentStatus(approvalStatus) {
    const { deploymentStatus } = await updateDeployment({ approvalStatus });
    let deploymentApproved = false;

    if (deploymentStatus) {
      const { administrator, mynrail_user } = deploymentStatus;
      const { approvedBy } = this.state;
      if (administrator) {
        approvedBy.administrator = administrator;
      }
      if (mynrail_user) {
        approvedBy.mynrail_user = mynrail_user;
      }

      if (administrator && mynrail_user) {
        deploymentApproved = administrator.status && mynrail_user.status;
      }
      this.setState({ approvedBy, deploymentApproved });
    }
  }

  getAprovalButton(approved) {
    return (
      <Button
        color="primary"
        variant="contained"
        size="small"
        // component="span"
        onClick={() => {
          this.updateDeploymentStatus(approved);
        }}

      >
        {/*{approved ? 'Approve Deployment' : 'Cancel Deployment'}*/}
        Save
      </Button>
    );
  }

  handleVersionNumChange(event) {
    const versionKey = event.target.name;
    const versionVal = event.target.value;

    if (versionKey) {
      const { versionNum } = this.state;
      versionNum.unsaved[versionKey] = versionVal && parseInt(versionVal);
      this.setState({ versionNum });
    }
  }

  handleReleaseNotesChange(event) {
    const { value } = event.target;
    const { activeReleaseNotesLng } = this.state;
    if (activeReleaseNotesLng === 'EN') {
      this.setState({ enReleaseNotes: value });
      return;
    }
    this.setState({ deReleaseNotes: value });
  }

  async updateReleaseNotes() {
    const { deReleaseNotes, enReleaseNotes } = this.state;
    await this.updateTranslation({
      key: 'userAdmin->releaseNotes',
      de: deReleaseNotes,
      en: enReleaseNotes,
    });
  }

  async updateTransVn() {
    const { minor, major, bugFix } = this.state.versionNum.unsaved;
    this.updateTranslation({
      key: 'version',
      de: `${major}.${minor}.${bugFix} `,
      en: `${major}.${minor}.${bugFix} `,
    });
  }

  handleCloseDialog() {
    this.setState({ isPreview: false });
  };

  handleMsgEndDate(msgEndDate) {
    this.setState({ msgEndDate });
  };

  handleMsgStartDate(msgStartDate) {
    this.setState({ msgStartDate });
  };

  render() {
    const {
      open,
      activeUser,
      userColumns,
      userDataSet,
      tabValue,
      columns,
      drawData,
      LanColumns,
      LanData,
      deployToProduction,
      approvedBy,
      updateVerNum,
      suggestRefresh,
      msgLinedToReleased,
      disableDismissButton,
      userConfisDevEnv,
      userCanViewPage,
      myUserRole,
      deploymentApproved,
      versionNum,
      backupFiles,
      selectFile,
      restoreSite,
      deReleaseNotes,
      enReleaseNotes,
      activeReleaseNotesLng,
      isPreview,
      showUserMsg,
      msgStartDate,
      msgEndDate,
    } = this.state;

    const activeReleaseNotes = activeReleaseNotesLng === 'EN' ? enReleaseNotes : deReleaseNotes;

    const { classes } = this.props;
    const { saved, unsaved } = versionNum;
    const IS_EDITABLE_ENV = (window.location.origin.match(/(staging)|(localhost)/ig));

    const oldVrNoStr = Object.values(saved).join('.');
    const newVrNoStr = Object.values(unsaved).join('.');
    const { administrator, mynrail_user } = approvedBy;

    return (
      <Suspense fallback={<LoadingSpinner/>}>
        {
          (userCanViewPage === false) && (<div>Unauthorised User</div>)
        }
        {
          (userCanViewPage === null) && (<LoadingSpinner/>)
        }
        {
          userCanViewPage && (<div>
            <AppBar position="static" color="default">
              <Tabs
                value={tabValue}
                onChange={this.handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs example"
              >
                <Tab label="Individual User Train Settings" icon={<Face/>}/>
                <Tab label="Site Translation" icon={<Translate/>}/>
                <Tab label="Other Settings" icon={<Settings/>}/>
              </Tabs>
            </AppBar>

            <TabPanel value={tabValue} index={0}>

              {userConfisDevEnv || !IS_EDITABLE_ENV ? (
                <MaterialTable
                  title="User List"
                  columns={userColumns}
                  data={userDataSet}
                  options={{
                    sorting: true,
                    // filtering: true,
                    maxBodyHeight: 'calc(100vh - 330px)',
                    pageSizeOptions: [10, 20, 50],
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                  }}
                  onRowClick={this.setDrawDataState}
                  icons={TableIcons}
                />) : (
                <div>
                  This tab is for test purposes only and should not be used to make changes on production!!
                  <br/>

                  By clicking the button below you are Acknowledging that this is a test ENV and your
                  changes will not be seeing in the production environment.
                  <br/>
                  <br/>
                  The above statement apply ONLY to this tab.
                  <br/>
                  <br/>
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={() => {
                      this.setState({ userConfisDevEnv: !userConfisDevEnv });
                    }}
                  >
                    Start Testing
                  </Button>
                </div>

              )}
              {open ? this.buildUserDrawer({ open, classes, columns, drawData, activeUser }) : null}
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              {
                IS_EDITABLE_ENV ? (
                  <MaterialTable
                    title="Translation List"
                    columns={LanColumns}
                    data={LanData}
                    editable={{
                      onRowUpdate: (newData, oldData) =>
                        new Promise(resolve => {
                          setTimeout(async () => {
                            resolve();
                            if (oldData) {
                              const resp = await this.updateTranslation(newData);
                              const { lstEditedBy, modifiedDate } = resp;
                              if (lstEditedBy && modifiedDate) {
                                this.setState(prevState => {
                                  const LanData = [...prevState.LanData];
                                  LanData[LanData.indexOf(oldData)] = { ...newData, ...resp.data };
                                  return { ...prevState, LanData };
                                });
                              }

                            }
                          }, 600);
                        }),
                    }}
                    options={{
                      sorting: true,
                      // filtering: true,
                      grouping: true,
                      maxBodyHeight: 'calc(100vh - 375px)',
                      pageSizeOptions: [10, 20, 50],
                      pageSize: 20,
                      emptyRowsWhenPaging: false,
                    }}
                    icons={TableIcons}
                  />
                ) : (
                  <div>
                    For better QA, changes must be made on the staging environment and deployed from there to the
                    production
                    environment.
                    <br/>
                  </div>
                )
              }
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              {
                IS_EDITABLE_ENV ? (
                  <div>
                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormLabel component="legend">Language files deployment options</FormLabel>
                      <FormGroup className={classes.formGroup}>
                        <FormControlLabel
                          control={<Checkbox
                            tabIndex={-1}
                            color="primary"
                            checked={deployToProduction}
                            onChange={() => {
                              this.setState({ deployToProduction: !deployToProduction });
                            }}
                            value={deployToProduction}
                          />}
                          label="I am sure I want to overwrite the language files for the my-NRail application."
                          labelPlacement="end"
                        />
                        <br/>
                        <Button
                          color="primary"
                          variant="contained"
                          className={classes.submitButtons}
                          size="large"
                          disabled={!deployToProduction}
                          onClick={this.deployLanguageFile}
                        >
                          Deploy Language Changes Only
                        </Button>
                      </FormGroup>
                    </FormControl>

                    <Divider className={classes.divider}/>

                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormLabel className={classes.formControlLabel} component="legend">Version Number
                        Controls</FormLabel>
                      <FormGroup>
                        <TextField
                          label="Major release"
                          id="major-release-number"
                          onChange={this.handleVersionNumChange}
                          type="number"
                          disabled={'administrator' !== myUserRole}
                          name="major"
                          value={unsaved.major}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">current ver
                              - {saved.major}</InputAdornment>,
                          }}
                          variant="outlined"
                        />
                        <br/>
                        <TextField
                          label="Minor release"
                          id="bugfix-release-number"
                          name="minor"
                          disabled={'administrator' !== myUserRole}
                          value={unsaved.minor}
                          type="number"
                          onChange={this.handleVersionNumChange}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">current ver
                              - {saved.minor}</InputAdornment>,
                          }}
                          variant="outlined"
                        />
                        <br/>
                        <TextField
                          label="Bug fix"
                          name="bugFix"
                          id="minor-release-number"
                          onChange={this.handleVersionNumChange}
                          type="number"
                          value={unsaved.bugFix}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">current ver
                              - {saved.bugFix}</InputAdornment>,
                          }}
                          variant="outlined"
                        />
                        <FormControlLabel
                          control={<Checkbox
                            tabIndex={-5}
                            color="primary"
                            checked={updateVerNum}
                            onChange={() => {
                              this.setState({ updateVerNum: !updateVerNum });
                            }}
                          />}
                          label={`I would like update the version number from 
                          ${oldVrNoStr} to ${newVrNoStr}`}
                          labelPlacement="end"
                        />

                        <Button
                          color="primary"
                          className={classes.submitButtons}
                          variant="contained"
                          size="large"
                          disabled={!(suggestRefresh || updateVerNum || showUserMsg)}
                          onClick={async () => {
                            await this.updateTransVn();
                            const { versionNos } = await updateVersionNo(this.state.versionNum.unsaved);
                            if (versionNos && versionNos.current) {
                              const { current } = versionNos;
                              this.setState({
                                versionNum: {
                                  saved: { ...current },
                                  unsaved: { ...current },
                                },
                                updateVerNum: false,
                              });
                            }
                          }}
                        >
                          Update Version Number
                        </Button>

                      </FormGroup>
                    </FormControl>

                    <Divider className={classes.divider}/>

                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormLabel className={classes.formControlLabel} component="legend">User Interaction</FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox
                            tabIndex={-1}
                            color="primary"
                            checked={suggestRefresh}
                            onChange={() => {
                              this.setState({ suggestRefresh: !suggestRefresh, msgLinedToReleased: false });
                            }}
                          />}
                          label="Suggest user refreshes page."
                          labelPlacement="end"
                        />

                        <FormControlLabel
                          control={<Checkbox
                            tabIndex={-1}
                            color="primary"
                            checked={showUserMsg}
                            onChange={() => {
                              this.setState({
                                showUserMsg: !showUserMsg,
                                msgLinedToReleased: false,
                                disableDismissButton: false,
                              });
                            }}
                          />}
                          label="Show user the message below"
                          labelPlacement="end"
                        />

                        <FormControlLabel
                          disabled={!showUserMsg || !suggestRefresh}
                          control={<Checkbox
                            tabIndex={-1}
                            color="primary"
                            checked={msgLinedToReleased}
                            onChange={() => {
                              this.setState({ msgLinedToReleased: !msgLinedToReleased });
                            }}
                          />}
                          label="Message relies on update"
                          labelPlacement="end"
                        />

                        <FormControlLabel
                          disabled={!showUserMsg}
                          control={<Checkbox
                            tabIndex={-1}
                            color="primary"
                            checked={disableDismissButton}
                            onChange={() => {
                              this.setState({ disableDismissButton: !disableDismissButton });
                            }}
                          />}
                          label="Disable dismiss option"
                          labelPlacement="end"
                        />
                        <BottomNavigation
                          value={activeReleaseNotesLng}
                          onChange={(event, activeReleaseNotesLng) => {
                            // const activeReleaseNotesLng = event.target.value;
                            this.setState({ activeReleaseNotesLng });
                          }}
                          className={classes.relaseNotesNav}
                          showLabels
                        >
                          <BottomNavigationAction
                            label={this.props.t('singleWords.german')} value="DE" icon={<Translate/>}
                          />
                          <BottomNavigationAction
                            label={this.props.t('singleWords.english')} value="EN" icon={<Translate/>}
                          />
                        </BottomNavigation>

                        <div className={classes.releaseNotes}>
                          <TextField
                            multiline
                            rows="8"
                            rowsMax="24"
                            variant="outlined"
                            // disabled={!informUser}
                            label="Mark Down Editor"
                            value={activeReleaseNotes}
                            onChange={this.handleReleaseNotesChange}
                          />
                          <div>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DateTimePicker
                                label="Message Start Date"
                                format="dd.MM.yyyy @ HH:mm"
                                value={msgStartDate}
                                minDate={new Date()}
                                variant="inline"
                                // orientation="landscape"
                                disablePast={true}
                                ampm={false}
                                inputVariant="outlined"
                                onChange={this.handleMsgStartDate}
                              />
                              <br/>
                              <DateTimePicker
                                label="Message End Date"
                                format="dd.MM.yyyy @ HH:mm"
                                value={msgEndDate}
                                minDate={msgStartDate}
                                variant="inline"
                                disablePast={true}
                                ampm={false}
                                inputVariant="outlined"
                                onChange={this.handleMsgEndDate}
                              />
                            </MuiPickersUtilsProvider>
                            <br/>
                            <Button
                              color="primary"
                              variant="contained"
                              size="small"
                              // disabled={!updateVerNum}
                              onClick={this.updateReleaseNotes}
                            >
                              Save Message
                            </Button>
                            <br/>
                            <Button
                              color="primary"
                              variant="contained"
                              size="small"
                              // disabled={!updateVerNum}
                              onClick={() => {
                                this.setState({ isPreview: true });
                              }}
                              isPreview
                            >
                              preview
                            </Button>
                            <br/>
                            <a
                              href="https://www.markdownguide.org/basic-syntax/"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Mark down docs
                            </a>
                            <br/>
                            <a
                              href="https://dillinger.io/"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Examples
                            </a>
                          </div>
                          <br/>
                        </div>

                        <Button
                          color="primary"
                          className={classes.submitButtons}
                          variant="contained"
                          size="large"
                          disabled={!(suggestRefresh || updateVerNum || showUserMsg)}
                          onClick={async () => {
                            const period = {};

                            if (showUserMsg) {
                              period.start_date = moment(msgStartDate).format('DD-MM-YYYY HH:mm:ss');
                              period.end_date = moment(msgEndDate).format('DD-MM-YYYY HH:mm:ss');
                            }
                            const { userSuggestReloadTs, userMsgTs, msgIsDependent, isDisableDismissButton } = await updateUserInteraction(suggestRefresh, msgLinedToReleased, showUserMsg, disableDismissButton, period);
                            if (userSuggestReloadTs || userMsgTs || msgIsDependent) {
                              this.setState({
                                suggestRefresh: userSuggestReloadTs ? false : suggestRefresh,
                                msgLinedToReleased: msgIsDependent ? false : msgLinedToReleased,
                                disableDismissButton: isDisableDismissButton ? false : disableDismissButton,
                                showUserMsg: userMsgTs ? false : showUserMsg,
                              });
                            }
                          }}
                        >
                          Trigger User Interaction
                        </Button>
                      </FormGroup>
                    </FormControl>

                    <Divider className={classes.divider}/>

                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormLabel className={classes.formControlLabel} component="legend">
                        My-NRail Deployment Options
                        <IconButton children={<Refresh color="inherit"/>} onClick={this.get_update_status}/>
                      </FormLabel>
                      <FormGroup>
                        <span>
                          <FormControlLabel
                            control={
                              <Checkbox
                                tabIndex={-1}
                                color="primary"
                                checked={administrator.status}
                                onChange={() => {
                                  const { approvedBy } = this.state;
                                  approvedBy.administrator.status = !administrator.status;
                                  this.setState({ approvedBy });
                                }}
                              />}
                            disabled={'administrator' !== myUserRole}
                            label={`Approved by FVM Technik ${administrator.status ? administrator.approvedBy : ''}`}
                            labelPlacement="end"
                          />
                          {'administrator' === myUserRole && this.getAprovalButton(administrator.status)}

                        </span>
                        <span>

                          <FormControlLabel
                            control={<Checkbox
                              tabIndex={-1}
                              color="primary"
                              checked={mynrail_user.status}
                              onChange={() => {
                                const { approvedBy } = this.state;
                                approvedBy.mynrail_user.status = !mynrail_user.status;
                                // this.setState({ approvedBy, deploymentApproved });
                                this.setState({ approvedBy });
                              }}
                            />}
                            disabled={'mynrail_user' !== myUserRole}
                            label={`Approved by NRail Admin. ${mynrail_user.status ? mynrail_user.approvedBy : ''}`}
                            labelPlacement="end"
                          />
                          {'mynrail_user' === myUserRole && this.getAprovalButton(mynrail_user.status)}
                        </span>

                        <br/>
                        <span>
                          The version number above will be used when deploying({oldVrNoStr}).</span>
                        <Button
                          color="primary"
                          variant="contained"
                          className={classes.submitButtons}
                          size="large"
                          disabled={!deploymentApproved}
                          onClick={this.deployMyNrailApp}
                        >
                          Deploy MyNrail Application
                        </Button>
                      </FormGroup>

                      <Divider className={classes.divider}/>

                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Backup File Name</InputLabel>
                        <Select
                          id="simple-select"
                          value={selectFile}
                          onChange={(event) => {
                            const selectFile = event.target.value;
                            this.setState({ selectFile });
                          }}
                        >
                          {
                            Object.values(backupFiles).map(
                              filename => <MenuItem value={filename}>{filename}</MenuItem>,
                            )
                          }
                        </Select>


                        <span>
                          THIS WILL RESET THE SITE TO THE SELECTED BACKUP. <br/>
                          THIS OPTION SHOULD ONLY BE USED IF A DEPLOYMENT HAS FAILED.<br/>
                          CONTACT FVM IF YOU ARE NOT SURE!!!!!
                        </span>
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              color="primary"
                              checked={restoreSite}
                              onChange={() => {
                                const restoreSite = !this.state.restoreSite;
                                this.setState({ restoreSite });
                              }}
                            />}
                          label={'Enable restore button.'}
                          labelPlacement="end"
                        />
                        <Button
                          color="secondary"
                          variant="contained"
                          className={classes.submitButtons}
                          size="large"
                          disabled={(!restoreSite || !selectFile)}
                          onClick={async () => {
                            this.setState({ isShowLoading: true });
                            const resp = await restoreSiteFromServer(selectFile);
                            this.setState({ isShowLoading: false });
                            const msg = resp ? 'Restore Complete' : 'Fail to restore';
                            alert(msg);

                          }}
                        >
                          Restore site
                        </Button>
                      </FormControl>
                    </FormControl>
                  </div>
                ) : <div> Please use the staging env to see the available options</div>
              }
            </TabPanel>
            {this.state.isShowLoading ? <LoadingSpinner/> : null}
          </div>)
        }

        {
          isPreview ?
            <FVMDialog
              isDialogOpen={isPreview}
              title={this.props.t('userAdmin.releaseNotesTitle')}
              details={<MarkdownRenderer markdown={activeReleaseNotes}/>}
              onCloseDialog={this.handleCloseDialog}
            /> : null
        }

      </Suspense>

    );
  }
}

UserAdministration.propTypes = {
  newView: PropTypes.func.isRequired,
  isDemoUser: PropTypes.bool.isRequired,
  activeUserLocomotiveListIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  userLocomotiveListObject: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  activeUserLocomotiveListIds: state.activeUserLocomotiveListIds,
  isDemoUser: state.isDemoUser,
  isDevelopment: state.isDevelopment,
  locListUpdated: state.locListUpdated,
  userLocomotiveListObject: state.userLocomotiveList,
  userSessionID: state.userSessionID,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    newView: changedView,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(UserAdministration));