import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NumberFormatInt } from '../../../components/NrlNumericInput';
import NrlFormLabel from '../core/NrlFormLabel';
import NrlRadioButtonGroup from '../core/NrlRadioButtonGroup';
import { Controller } from 'react-hook-form';
import { localeNumberFormatter } from '../../../StatelessFunctions/nummericManapulation';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  sectionLabel: {
    marginTop: 16,
    marginBottom: 16,
  },

  textField: {
    '& label[data-shrink=false]': {
      overflow: 'hidden',
      maxWidth: 'calc(100% - 56px)',
      whiteSpace: 'nowrap',
      textOverflow: '...*',
      height: 18,
      '& span': {
        // position: 'absolute',
      },
    },
  },

  conditionField: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 16,
    },
  },
}));

const SAND_STATE_ENUM_LST = [
  { label: 'selectOption.ok', value: 'ok' },
  { label: 'selectOption.not_ok', value: 'not_ok' },
];

const BOOLEAN_ENUM_LST = [
  { label: 'selectOption.true', value: true },
  { label: 'selectOption.false', value: false },
];

const radioButtonSettings = {
  size: 'small',
  color: 'primary',
  labelPlacement: 'right',
};

const VehicleState = props => {
  const classes = useStyles();
  const { isReadOnly } = props;
  const { t, i18n } = useTranslation('');

  const {
    form: { control, watch, getValues, setValue, formState: { errors } },
    gridItemsSpacing,
  } = props;

  const getJoiError = (errorResponse, options) => {
    // return t(`joi.messages.${errorResponse}`, options);
    const isDefaultRequired = !options?.label || errorResponse === 'any.required';
    return t([`joi.messages.${isDefaultRequired ? 'default.required' : errorResponse}`, 'joi.messages.'], { ...options, label: 'field' });
  };

  const inputConst = { t, classes, isReadOnly };
  const [isValid] = useState({});

  const sharedData = {
    disabled: isReadOnly,
    control,
  };

  const watchSandState = watch('vehicleState.sandState');
  const watchMotorOilState = watch('vehicleState.motorOilState');

  useEffect(() => {
    if (watchSandState === 'ok') {
      setValue('vehicleState.isSandAdded', null);
    }
  }, [watchSandState]);
  useEffect(() => {
    if (watchMotorOilState !== 'not_ok') {
      setValue('vehicleState.motorOilAdded_l', null);
    }

  }, [watchMotorOilState]);

  useEffect(() => {
    if (watchMotorOilState !== 'not_ok') {
      console.log(errors);
      console.log(errors?.vehicleState?.mileage_km);
    }

  }, [errors]);

  return (
    <Fragment>
      <Typography
        component={'div'}
        variant="h6"
        className={classes.sectionLabel}
      >
        {t('labels.resources')}
      </Typography>
      <Grid container item spacing={gridItemsSpacing}>
        {[
          {
            ...sharedData,
            label: t('labels.fuelAdded_l'),
            name: 'vehicleState.fuelAdded_l',
            error: !!errors?.vehicleState?.fuelAdded_l,
            helperText: errors?.vehicleState?.fuelAdded_l?.type &&
              getJoiError(errors?.vehicleState?.fuelAdded_l?.type, {
                label: t('labels.fuelAdded_l'),
                limit: localeNumberFormatter(1e5, i18n.language),
                minLimit: 1,
              }),
            // required: true,
            postfix: 'l',
            component: item => NumericInputField(inputConst, item),
            // options: ['ok', 'not_ok'],
            breakpoints: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },

          {
            ...sharedData,
            error: !!errors?.vehicleState?.fuelFillingLevel_l,
            helperText: errors?.vehicleState?.fuelFillingLevel_l?.type &&
              getJoiError(errors?.vehicleState?.fuelFillingLevel_l?.type, {
                label: t('labels.fuelFillingLevel_l'),
                limit: localeNumberFormatter(1e5, i18n.language),
                minLimit: 1,
              }),
            label: t('labels.fuelFillingLevel_l'),
            name: 'vehicleState.fuelFillingLevel_l',
            // required: true,
            postfix: 'l',
            component: item => NumericInputField(inputConst, item),
            breakpoints: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
        ].map(({ component, ...item }) => (
          component(item)
        ))}

        {[
          {
            ...sharedData,
            label: t('labels.hydraulicsState'),
            error: !!errors?.vehicleState?.hydraulicsState,
            // helperText: errors?.vehicleState?.hydraulicsState?.type || '',
            helperText: errors?.vehicleState?.hydraulicsState?.type &&
              getJoiError(errors?.vehicleState?.hydraulicsState?.type, {
                label: t('labels.hydraulicsState'),
                limit: localeNumberFormatter(1e3, i18n.language),
              }),
            name: 'vehicleState.hydraulicsState',
            // required: true,
            component: item => RadioButtonGroup(inputConst, item),
            options: ['ok', 'not_ok'],
            breakpoints: {
              xs: 12,
            },
          },
          {
            ...sharedData,
            label: t('labels.motorOilState'),
            error: !!errors?.vehicleState?.motorOilState,
            // helperText: errors?.vehicleState?.motorOilState?.type || '',
            helperText: errors?.vehicleState?.motorOilState?.type &&
              getJoiError(errors?.vehicleState?.motorOilState?.type, {
                label: t('labels.motorOilState')
              }),
            name: 'vehicleState.motorOilState',
            // required: true,
            component: item => RadioButtonGroup(inputConst, item),
            options: ['ok', 'not_ok'],
            breakpoints: {
              xs: 12,
            },
            secondaryFieldValue: {
              ...sharedData,
              error: !!errors?.vehicleState?.motorOilAdded_l,
              helperText: errors?.vehicleState?.motorOilAdded_l?.type &&
                getJoiError(errors?.vehicleState?.motorOilAdded_l?.type, {
                  label: t('labels.motorOilAdded_l'),
                  limit: localeNumberFormatter(1e3, i18n.language),
                  minLimit: 1,
                }),
              visible: getValues('vehicleState.motorOilState') === 'not_ok',
              className: classes.conditionField,
              required: true,
              name: 'vehicleState.motorOilAdded_l',
              label: t('labels.motorOilAdded_l'),
              component: NumericInputField,
              // component: item => NumericInputField(inputConst, item),
              postfix: 'l',
              breakpoints: {
                xs: 4,
              },
            },
          },
          {
            ...sharedData,
            label: t('labels.sandState'),
            error: !!errors?.vehicleState?.sandState,
            // helperText: errors?.vehicleState?.sandState?.type || '',
            helperText: errors?.vehicleState?.sandState?.type &&
              getJoiError(errors?.vehicleState?.sandState?.type, { label: t('labels.sandState') }),
            name: 'vehicleState.sandState',
            component: item => RadioButtonGroup(inputConst, item),
            isValid: isValid,
            // required: true,
            options: ['ok', 'not_ok'],
            secondaryFieldValue: {
              ...sharedData,
              error: !!errors?.vehicleState?.isSandAdded,
              helperText: errors?.vehicleState?.isSandAdded?.type &&
                getJoiError(errors?.vehicleState?.isSandAdded?.type, { label: t('labels.isSandAdded') }),
              visible: getValues('vehicleState.sandState') === 'not_ok',
              required: true,
              name: 'vehicleState.isSandAdded',
              label: t('labels.isSandAdded'),
              options: [true, false],
              component: RadioButtonGroup,
              breakpoints: {
                xs: 12,
              },
            },
          },
        ].map(({ component, ...item }) => (
          <Grid container spacing={2} item>
            {component(item)}
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};

const RadioButtonGroup = (
  { t, classes, hidden = false },
  {
    control,
    error = false,
    helperText = '',
    required,
    name,
    label,
    secondaryFieldValue = {},
    options = [],
    breakpoints = { xs: 12, sm: 4, md: 3 },
    ...rest
  },
) => {

  const {
    component: secondaryComponent, ...secondaryInputs
  } = secondaryFieldValue;

  return (
    <Fragment>
      <Grid item hidden={hidden} {...breakpoints} xs={12} sm={4} md={3}>
        {label ? (
          <NrlFormLabel
            disabled={rest?.disabled}
            error={error}
            helperText={helperText}
            required={required}
            id={`radio-button-label${label}`}
            component="legend">
            {label}
          </NrlFormLabel>
        ) : null}
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <NrlRadioButtonGroup
              row
              aria-label={label}
              name={label}

              {...field}
              onChange={(event, data) => {
                let tempData = data;
                if (['true', 'false'].includes(data)) {
                  tempData = tempData === 'true';
                }
                field.onChange(tempData, tempData);
              }}
            >
              {options.map(option => (
                <FormControlLabel
                  disabled={rest?.disabled}
                  // disabled
                  value={option}
                  control={<Radio {...radioButtonSettings} />}
                  label={t(`labels.${option}`)}
                  labelPlacement="end"
                />
              ))}
            </NrlRadioButtonGroup>
          )}
        />
      </Grid>

      {secondaryComponent && secondaryInputs.visible
        ? secondaryComponent(
          { t, classes, hidden: !secondaryInputs.visible },
          secondaryInputs,
        )
        : null}
    </Fragment>
  );
};

const NumericInputField = (
  { t, classes, hidden = false },
  {
    control,
    name,
    secondaryFieldValue = {},
    postfix,
    className = '',
    breakpoints = { xs: 12, sm: 4, md: 3 },
    ...rest
  },
) => {
  const {
    component: secondaryComponent, ...secondaryInputs
  } = secondaryFieldValue;
  return (
    <Fragment>
      <Grid className={className} {...breakpoints} item hidden={hidden}>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <TextField
              fullWidth
              className={classes.textField}
              variant="outlined"
              InputProps={{
                inputComponent: NumberFormatInt,
                endAdornment: (
                  <InputAdornment position="start">{postfix}</InputAdornment>
                ),
              }}
              {...rest}
              {...field}
            />)}
        />
      </Grid>
      {secondaryComponent && secondaryInputs.visible
        ? secondaryComponent(
          { t, classes, hidden: !secondaryInputs.visible },
          secondaryInputs,
        )
        : null}
    </Fragment>
  );
};

VehicleState.propTypes = {
  form: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default VehicleState;
