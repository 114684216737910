import {
  getLogbookApi,
  getLogbookApiRequestObj,
  getLogBookConfigs,
  getLogbookEntriesCommentsApi,
  getLogbookMetaApi,
} from './index';
import {
  LogbookEntriesApiAxiosParamCreator,
  LogbookEntriesCommentsApiAxiosParamCreator,
} from 'nrail-gateway-api-client/dist/logbook';

const getLogbookEntries = async (organisationId, top, skip, filter, count = true, orderby = ['createTimestamp desc']) => {
  return await ( await getLogbookApi() )
    .getLogbookEntries(organisationId, top, skip, filter, count, orderby)
    .then(({ data }) => data);
  // .catch((err ) => console.dir(err))
  // .catch(({ response }) => ( { items: ['crap'], pagination: {}, response } ));
};

const getLogbookEntryById = async (organisationId, logbookEntryId) => {
  return await ( await getLogbookApi() )
    .getLogbookEntries(organisationId, undefined, undefined, `logbookEntryId eq ${logbookEntryId}`)
    .then(({ data }) => data?.items?.[0])
    .catch(({ response }) => ( { items: [], pagination: {}, response } ));
};

const getVehicleMetadataInOrganisation = async (organisationId, top, skip, filter, count = true, orderby) => {
  return await ( await getLogbookMetaApi() )
    .getVehicleMetadataInOrganisation(organisationId, top, skip, filter, count, orderby)
    .then(({ data }) => data)
    .catch(({ response }) => ( { items: [], pagination: {}, response } ));
};


const getLogbookEntriesExport = async (organisationId, $filter, $count = false, $orderby = ['createTimestamp desc'], customOptions = {
  headers: { accept: 'application/vnd.ms-excel' },
  responseType: 'blob',
}) => {
  const configs = await getLogBookConfigs();
  const { resourceId, response } = await ( await getLogbookApi() )
    .createLogbookEntriesExport(organisationId, {
      odataParameters: { $count, $filter, $orderby },
      acceptedMediaType: customOptions.headers.accept,
    }, customOptions)
    .then(({ data }) => data).catch(({ response }) => ( { response } ));

  const { url } = await LogbookEntriesApiAxiosParamCreator(configs).getLogbookEntriesExportWithId(resourceId, customOptions);

  if (resourceId) {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = `${configs.basePath}${url}`;
    // a.target = '_blank';
    // a.download = 'export.xlsx';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } else {
    return { response };
  }
  return { resourceId };
};

const getLogbookEntryComments = async (organisationId, logbookEntryId, top = 100, skip = 0, filter, orderby = ['createTimestamp desc']) => {
  return await ( await getLogbookEntriesCommentsApi() ).getLogbookEntryComments(organisationId, logbookEntryId, top, skip, filter, orderby);
};

const getLogbookEntryCommentSignature = async (organisationId, logbookEntryId, commentId) => {
  const configs = await getLogBookConfigs();
  // const temp = await(await getLogbookEntriesCommentsApi())
  //   .getLogbookEntryCommentSignature(organisationId, logbookEntryId, commentId, { basePath: configs.basePath,
  // responseType: 'blob' }) .then(response => response.blob()) .then(blob => blob);   return new Blob([temp.data],
  // {type: ''}); console.log(temp) return temp;
  const {
    url,
    options,
  } = await LogbookEntriesCommentsApiAxiosParamCreator(configs).getLogbookEntryCommentSignature(organisationId, logbookEntryId, commentId);
  return await fetch(`${configs.basePath}${url}`, options).then(response => response.blob()).then(blob => blob);
};

const saveLogbookCommentEntry = async (organisationId, logbookEntryId, entry) => {
  return await ( await getLogbookEntriesCommentsApi() )
    .createLogbookEntryComment(organisationId, logbookEntryId, entry);
  // .catch(({ response }) => response);
};

const saveLogbookEntry = async ({ organisationId, logbookEntry }) => {
  return await ( await getLogbookApi() ).createLogbookEntry(organisationId, logbookEntry);
};


const finaliseEntry = async (organisationId, logbookEntryId, logbookEntryFinalization) => {
  return await ( await getLogbookApi() ).createLogbookEntryFinalization(organisationId, logbookEntryId, logbookEntryFinalization);
};

const deleteLogbookEntry = async (organisationId, logbookEntryId) => {
  return await ( await getLogbookApi() ).deleteLogbookEntry(organisationId, logbookEntryId);
};


const updateLogbookEntry = async ({ organisationId, logbookEntryId, logbookEntry, sendOptionalNotification }) => {
  return await ( await getLogbookApi() ).updateLogbookEntry(organisationId, logbookEntryId, logbookEntry, sendOptionalNotification);
};

const getLogbookEntryCreatorSignature = async ({ organisationId, logbookEntryId }) => {
  const { url, options } = await getLogbookApiRequestObj(organisationId, logbookEntryId);
  return await fetch(url, options).then(response => response.blob()).then(blob => blob);
};


export {
  getLogbookEntries,
  getLogbookEntryById,
  getLogbookEntryComments,
  saveLogbookCommentEntry,
  saveLogbookEntry,
  getLogbookEntryCreatorSignature,
  getLogbookEntryCommentSignature,
  getLogbookEntriesExport,
  getVehicleMetadataInOrganisation,
  updateLogbookEntry,
  deleteLogbookEntry,
  finaliseEntry,
};