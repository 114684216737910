import { v4 as uuidv4 } from 'uuid';

const logbookFormDefaults2 = {
  'header': {
    'vehicleIdRef': 0,
    'remarks': 'remarks data',
    'referenceTimestamp': '2022-11-10T04:58:55Z',
    'referenceLocation': 'locomotives',
  },
  'vehicleState': {
    'hydraulicsState': 'not_ok',
    'vehicleOperatingHours_h': 40,
    'mileage_km': 30,
    'fuelFillingLevel_l': 12,
    'fuelAdded_l': 19,
    'sandState': 'ok',
    'isSandAdded': true,
    'motorOilState': 'not_ok',
    'motorOilAdded_l': 10,
  },
  'inspections': {
    // 'preparationService': '',
    'inspectionPerformedTimestamp_A1': '2022-12-30T17:58:55Z',
    'inspectionPerformedTimestamp_V1': '2022-12-30T00:58:55Z',
    'inspectionPerformedTimestamp_V3': '2022-12-30T03:58:55Z',
    'inspectionPerformedTimestamp_F1': '2022-12-30T04:58:55Z',
  },
  'defects': {
    'state': 'open',
    'description': 'description string',
    'measuresDescription': 'measuresDescription string',
  },
  'stoppage': {
    'vehicleLocation': 'locomotive vehicleLocation',
    'trainNumber': 'locomotives trainNumber',
    'state': 'resolved',
    'startTimestamp': '2022-12-27T04:58:55Z',
    'endTimestamp': '2022-12-30T04:58:55Z',
    'lastOperationMode': 'remote_control',
    'trainWeight_t': 0,
    'wasAuxiliaryVehicleUsed': true,
    'errorCodesDescription': 'string',
    'affectedComponents': [
      'engine',
    ],
    'description': 'string',
    'remarks': 'string',
    'measuresDescription': 'string',
  },
};
export const logbookFormDefaults = {
  'header': {
    'vehicleIdRef': null,
    'remarks': '',
    'referenceTimestamp': new Date(),
    'referenceLocation': '',
  },
  'vehicleState': {
    'hydraulicsState': '',
    'vehicleOperatingHours_h': null,
    'mileage_km': null,
    'fuelFillingLevel_l': null,
    'fuelAdded_l': null,
    'sandState': '',
    'isSandAdded': null,
    'motorOilState': '',
    'motorOilAdded_l': null,
  },
  'inspections': {
    'inspectionPerformedTimestamp_A1': null,
    'inspectionPerformedTimestamp_V1': null,
    'inspectionPerformedTimestamp_V3': null,
    'inspectionPerformedTimestamp_F1': null,
  },
  'defects': {
  },
  'stoppage': {
  },
};

export const createNewEntry = (overrides = {})=> {
  const now = new Date().toUTCString();
  return {
    'header': {
      'status': 'newEntry',
      'logbookEntryId': uuidv4(),
      'vehicleIdRef': null,
      'remarks': '',
      'referenceTimestamp': now,
      'referenceLocation': '',
      ...(typeof overrides?.header === 'object' ? overrides?.header :  {})
    },
    'vehicleState': {
      'hydraulicsState': '',
      'vehicleOperatingHours_h': null,
      'mileage_km': null,
      'fuelFillingLevel_l': null,
      'fuelAdded_l': null,
      'sandState': '',
      'isSandAdded': null,
      'motorOilState': '',
      'motorOilAdded_l': null,
      ...(typeof overrides?.vehicleState === 'object' ? overrides.vehicleState :  {})
    },
    'inspections': {
      'inspectionPerformedTimestamp_A1': null,
      'inspectionPerformedTimestamp_V1': null,
      'inspectionPerformedTimestamp_V3': null,
      'inspectionPerformedTimestamp_F1': null,
    },
    'defects': {
      ...(typeof overrides?.defects === 'object' ? overrides.defects :  {})
    },
    'stoppage': {
      ...(typeof overrides?.stoppage === 'object' ? overrides.stoppage :  {})
    },
  }
}