import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid, InputAdornment,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import NrlDatePicker, { NrlDateTimePicker, NrlTimePicker } from '../NrlDatePicker';
// import FormLabel from '@material-ui/core/FormLabel';
import NrlRadioButtonGroup from '../core/NrlRadioButtonGroup';
import NrlFormLabel from '../core/NrlFormLabel';
import { Controller } from 'react-hook-form';
import { NumberFormatInt } from '../../NrlNumericInput';
import { useTranslation } from 'react-i18next';
import { localeNumberFormatter } from '../../../StatelessFunctions/nummericManapulation';

const useStyles = makeStyles(theme => ({
  switch: {
    paddingTop: 21,
    marginLeft: 0,
    // paddingBottom:16,
  },
  stoppageEndTimestamp: {
    marginTop: 16,
  },
  sectionLabel: {
    marginTop: 16,
    marginBottom: 16,
  },

  checkBoxFormGroup: {
    marginLeft: 3,
    '& label': {
      marginBottom: 0,
    },
  },
  radioButtonGroup: {
    // flexDirection: 'row',
    required: {},
  },
  [theme.breakpoints.down(600)]: {
    checkBoxGroupContainer: {
      '&>*:first-child': {
        paddingBottom: 0
      },
      '&>*:last-child': {
        paddingTop: 0
      }
    },
  },
  [theme.breakpoints.up(600)]: {
    timeInputGridItem: {
      maxWidth: 240,
    },
  },
}));
const radioButtonSettings = {
  size: 'small',
  color: 'primary',
};

const OPERATION_STATE_ENUM_LST = [
  { label: 'labels.remote_control', value: 'remote_control' },
  { label: 'labels.manual', value: 'manual' },
];

const BOOLEAN_ENUM_LST = [
  { label: 'labels.yes', value: true },
  { label: 'labels.no', value: false },
];

const OPEN_RESOLVED_LST = [

  { label: 'labels.yes', value: 'resolved' },
  { label: 'labels.nodefectonopen', value: 'open' },
];

const AFFECTED_COMPONENTS_LIST = [
  'engine',
  'remote_control',
  'fuel_system',
  'cooling_system',
  'power_control',
  'transmission',
  'undercarriage',
  'generators',
  'battery',
  'safety_deadman_circuit',
  'pzb_system',
  'gsm_rail_system',
  'misc_electronic_systems',
  'brakes_and_air_pressure_system',
];

const StoppageTabPanel = ({ title = 'Stoppage', ...props }) => {
  const classes = useStyles();
  const {
    hidden,
    useSplitDates = false,
    isReadOnly,
    form: { control, watch, formState: { errors }, setValue, getValues },
    gridItemsSpacing,
    handleStoppageToggled,
    isStoppage,
    locale = undefined,
  } = props;
  const { t, i18n } = useTranslation('');

  const getJoiError = (errorResponse, options) => {
    // return t(`joi.messages.${errorResponse}`, options);
    const isDefaultRequired = !options?.label || errorResponse === 'any.required';
    return t([`joi.messages.${isDefaultRequired ? 'default.required' : errorResponse}`, 'joi.messages.'], { ...options, label: 'field' });
  };

  const watchState = watch('stoppage.state');

  useEffect(() => {
    if (watchState !== 'resolved') {
      setValue('stoppage.endTimestamp', null);
    }
  }, [watchState]);

  const sharedProps = {
    disabled: isReadOnly,
  };

  return (
    <Box
      hidden={hidden}
      key={'tab-panel-stoppage'}
    >
      <FormControlLabel
        className={classes.switch}
        control={
          <Switch
            checked={isStoppage}
            onChange={handleStoppageToggled}
            name="isDefect"
            color="primary"
            {...sharedProps}
          />
        }
        labelPlacement="start"
        label={
          <Typography
            variant="h6"
            component={'span'}
            className={classes.sectionLabel}
          >
            {t('labels.stoppage')}
          </Typography>
        }
      />

      <Collapse in={isStoppage}>
        <Typography
          variant="h6"
          component={'div'}
          className={classes.sectionLabel}
        >
          {t('labels.malfunctionDetermination')}
        </Typography>
        <Grid className container item spacing={gridItemsSpacing}>
          {!useSplitDates ?
            <Grid item xs={12} sm={6}>
              <Controller
                fullwidth
                control={control}
                name="stoppage.startTimestamp"
                render={({ field }) => (
                  <NrlDateTimePicker
                    fullwidth
                    error={!!errors.stoppage?.startTimestamp}
                    helperText={
                      errors?.stoppage?.startTimestamp?.type &&
                      getJoiError(errors?.stoppage?.startTimestamp?.type, { label: t('labels.startTimestamp') })
                    }
                    {...sharedProps}
                    required
                    locale={locale}
                    label={t('logbook.labels.stoppage.startTimestamp')}
                    time={field.value}
                    {...field}
                  />
                )}
              />
            </Grid> : (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    fullwidth
                    control={control}
                    name="stoppage.startTimestamp"
                    render={({ field }) => (
                      <NrlDatePicker
                        fullwidth
                        {...sharedProps}
                        required
                        locale={locale}
                        label={t('labels.date')}
                        /*{...pickerProps['referenceTimestamp_date']}*/
                        date={field.value}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    control={control}
                    name="stoppage.startTimestamp"
                    render={({ field }) => (
                      <NrlTimePicker
                        {...sharedProps}
                        required
                        label={t('labels.time')}
                        time={field.value}
                        {...field}
                      />)}
                  />
                </Grid>
              </>
            )}

          {[
            { name: 'vehicleLocation', required: true, limit: localeNumberFormatter(50, i18n.language) },
            { name: 'trainNumber', limit: localeNumberFormatter(50, i18n.language) },
            { name: 'trainWeight_t', isNumeric: true, postFix: 't', limit: localeNumberFormatter(1e5, i18n.language) },
          ].map(({ name, isNumeric, postFix, limit, ...props }) => (
            <Grid key={`stoppage-${name}`} className={classes.textFieldFormControlStyles} xs={12} sm={6} md={6} item>
              <Controller
                control={control}
                name={`stoppage.${name}`}
                render={({ field }) => (
                  <TextField
                    {...sharedProps}
                    fullWidth
                    error={!!errors.stoppage?.[name]}
                    helperText={
                      errors?.stoppage?.[name]?.type &&
                      getJoiError(
                        errors?.stoppage?.[name]?.type,
                        { label: t(`labels.${name}`), limit },
                      )
                    }
                    label={t(`labels.${name}`)}
                    variant="outlined"
                    InputProps={isNumeric ? {
                      inputComponent: NumberFormatInt,
                      endAdornment: (
                        <InputAdornment position="start">{postFix}</InputAdornment>
                      ),
                    } : {}}
                    {...field}
                    {...props}
                  />
                )}
              />
            </Grid>))}
        </Grid>

        <Typography
          variant="h6"
          component={'div'}
          className={classes.sectionLabel}
        >
          {t('labels.fixedMalfunction')}
        </Typography>

        <Grid container item spacing={gridItemsSpacing}>
          <Grid item xs={12} sm={4}>
            <FormControl
              fullWidth
              // required
              variant="outlined"
            >
              <NrlFormLabel component="legend" error={!!errors.stoppage?.state}>
                {t('labels.malfunctionFixed')}
              </NrlFormLabel>
              <Controller
                control={control}
                name={'stoppage.state'}
                render={({ field }) => (
                  <NrlRadioButtonGroup
                    row
                    aria-label="mode-of-operation"
                    {...field}
                  >
                    {[...OPEN_RESOLVED_LST].map(({ label, value }, index) => (
                      <FormControlLabel
                        {...sharedProps}
                        value={value}
                        control={<Radio {...radioButtonSettings} />}
                        label={t(label)}
                        labelPlacement="end"
                      />
                    ))}
                  </NrlRadioButtonGroup>
                )}
              />
            </FormControl>
          </Grid>
          <Grid
            container
            hidden={watchState !== 'resolved'}
            item
            style={{ marginTop: 16 }}
            spacing={gridItemsSpacing}
            xs={12}
            sm={8}
            md={6}
          >
            <Grid item xs={12} className={classes.timeInputGridItem}>
              <Controller
                fullwidth
                control={control}
                name="stoppage.endTimestamp"
                render={({ field }) => (
                  <NrlDateTimePicker
                    fullwidth
                    error={!!errors.stoppage?.endTimestamp}
                    helperText={
                      errors?.stoppage?.endTimestamp?.type &&
                      getJoiError(errors?.stoppage?.endTimestamp?.type, { label: t(`logbook.stoppage.labels.endTimestamp`) })
                    }
                    {...sharedProps}
                    required={watchState === 'resolved'}
                    locale={locale}
                    label={t('logbook.labels.stoppage.endTimestamp')}
                    time={field.value}
                    {...field}
                  />
                )}
              />
            </Grid>

          </Grid>

          <Grid item xs={12}>
            <FormControl
              fullWidth
              // required
              variant="outlined"
              error={!!errors.stoppage?.wasAuxiliaryVehicleUsed}
              helperText={
                errors?.stoppage?.wasAuxiliaryVehicleUsed?.type &&
                getJoiError(errors?.stoppage?.wasAuxiliaryVehicleUsed?.type, { label: t(`labels.wasAuxiliaryVehicleUsed`) })
              }

            >
              <NrlFormLabel component="legend" error={!!errors.stoppage?.wasAuxiliaryVehicleUsed}>
                {t('labels.wasAuxiliaryVehicleUsed')}
              </NrlFormLabel>
              <Controller
                control={control}
                name={'stoppage.wasAuxiliaryVehicleUsed'}
                render={({ field }) => (
                  <NrlRadioButtonGroup
                    row
                    aria-label="wasAuxiliaryVehicle"
                    {...field}
                    onChange={(event, data) => {
                      field.onChange(['true', true].includes(data));
                    }}
                    value={field.value?.toString?.()}
                  >
                    {[...BOOLEAN_ENUM_LST].map(({ label, value }, index) => (
                      <FormControlLabel
                        {...sharedProps}
                        value={value?.toString?.()}
                        key={`wasAuxUsed-${label}`}
                        control={<Radio {...radioButtonSettings} />}
                        label={t(label)}
                        labelPlacement="end"
                      />
                    ))}
                  </NrlRadioButtonGroup>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Typography
          variant="h6"
          component={'div'}
          className={classes.sectionLabel}
        >
          {t('labels.malfunctionInformation')}
        </Typography>

        <Grid container item spacing={gridItemsSpacing}>
          <Grid item xs={12}>
            <FormControl
              // required
              variant="outlined"
            >
              <NrlFormLabel component="legend" error={!!errors.stoppage?.lastOperationMode}>
                {t('labels.lastOperationMode')}
              </NrlFormLabel>
              <Controller
                control={control}
                name={'stoppage.lastOperationMode'}
                render={({ field }) => (
                  <NrlRadioButtonGroup
                    row
                    aria-label="mode-of-operation"
                    {...field}
                  >
                    {[...OPERATION_STATE_ENUM_LST].map(
                      ({ label, value }, index) => (
                        <FormControlLabel
                          {...sharedProps}
                          value={value}
                          control={<Radio {...radioButtonSettings} />}
                          label={t(label)}
                          labelPlacement="end"
                        />
                      ),
                    )}
                  </NrlRadioButtonGroup>
                )}
              />
            </FormControl>
          </Grid>

          {['errorCodesDescription'].map((name) => (
            <Grid key={`defects-${name}`} className={classes.textFieldFormControlStyles} xs={12} item>
              <Controller
                control={control}
                name={`stoppage.${name}`}
                render={({ field }) => (
                  <TextField
                    error={!!errors.stoppage?.errorCodesDescription || getValues('stoppage.description') <= localeNumberFormatter(4e3, i18n.language)}
                    helperText={
                      errors?.stoppage?.errorCodesDescription?.type &&
                      getJoiError(
                        errors?.stoppage?.errorCodesDescription?.type,
                        {
                          label: t(`labels.${name}`),
                          limit: localeNumberFormatter(4e3, i18n.language)
                        },
                      )}
                    {...sharedProps}
                    // required
                    fullWidth
                    label={t(`labels.${name}`)}
                    variant="outlined"
                    rows={4}
                    multiline
                    {...field}
                  />
                )}
              />
            </Grid>))}

          <Grid item xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
            >
              <NrlFormLabel component="legend">
                {t('labels.affectedComponents')}
              </NrlFormLabel>
              <Grid className={classes.checkBoxGroupContainer} container xs={12} spacing={gridItemsSpacing}>
                {[0, 1].map(oddOrEven => (
                  <Grid item xs={12} sm={6}>
                    <FormGroup key={`affected-component-items-lst`} className={classes.checkBoxFormGroup}>
                      <Controller
                        fullwidth
                        control={control}
                        name="stoppage.affectedComponents"
                        render={({ field }) => {
                          return AFFECTED_COMPONENTS_LIST
                            .filter((key, index) => index % 2 === oddOrEven)
                            .map(key => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...field}
                                    value={key}
                                    checked={getValues('stoppage.affectedComponents')?.includes?.(key)}
                                    {...sharedProps}
                                    onChange={() => {
                                      let tempArr = getValues('stoppage.affectedComponents');
                                      tempArr = Array.isArray(tempArr) ? tempArr : [];

                                      if (tempArr.includes(key)) {
                                        field.onChange(tempArr.filter(fieldValue => fieldValue !== key));
                                      } else {
                                        field.onChange([...tempArr, key]);
                                      }
                                    }}

                                    name={`affectedComponents-${key}`}
                                    color="primary"
                                  />
                                }
                                label={t(
                                  `logbook.labels.affectedComponentsList.${key}`,
                                )}
                              />
                            ));
                        }}

                      />
                    </FormGroup>
                  </Grid>
                ))}
              </Grid>
            </FormControl>
          </Grid>
          {[
            { name: 'description', required: true, limit: localeNumberFormatter(4e3, i18n.language) },
            { name: 'measuresDescription', limit: localeNumberFormatter(4e3, i18n.language) },
            { name: 'remarks', limit: localeNumberFormatter(4e3, i18n.language) }].map(({
              name,
              required,
              limit,
            }) => (
              <Grid key={`defects-${name}`} className={classes.textFieldFormControlStyles} xs={12} item>
                <Controller
                  control={control}
                  name={`stoppage.${name}`}
                  render={({ field }) => (
                    <TextField
                      error={!!errors.stoppage?.[name]}
                      name={`stoppage.${name}`}
                      helperText={
                        errors?.stoppage?.[name]?.type &&
                        getJoiError(errors?.stoppage?.[name]?.type, { label: t(`labels.${name}`), limit })
                      }
                      {...sharedProps}
                      required={required}
                      fullWidth
                      label={t(`labels.${name}`)}
                      variant="outlined"
                      rows={4}
                      multiline
                      {...field}
                    />
                  )}
                />
              </Grid>))
          }
        </Grid>

      </Collapse>
    </Box>
  );
};

StoppageTabPanel.propTypes = {};

export default StoppageTabPanel;

//
// 8 Malfunction -> logbook.labels.stoppage.vehicleLocation
//
// 9 Malfunction -> logbook.labels.stoppage.lastOperationMode
//
// 10 Malfunction -> logbook.labels.stoppage.wasAuxiliaryVehicleUsed
//
// 11 Malfunction -> logbook.labels.stoppage.startTimestamp_date
//
// 12 Malfunction -> logbook.labels.stoppage.startTimestamp_time
//
// 13 Malfunction -> labels.stoppage_state
