import React, { Component } from 'react';

import {
  AssignmentOutlined,
  BusinessOutlined,
  DirectionsTransitOutlined,
  HomeOutlined,
  MyLocationOutlined,
  PeopleOutlined,
} from '@material-ui/icons';
import * as PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { Menu, MenuItem, Tooltip, Typography, useTheme } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import AppsOutlined from '@material-ui/icons/AppsOutlined';
import { makeStyles } from '@material-ui/core/styles';


function PrecisionManufacturing(props) {
  return <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PrecisionManufacturingOutlinedIcon"><path d="m19.93 8.35-3.6 1.68L14 7.7V6.3l2.33-2.33 3.6 1.68c.38.18.82.01 1-.36.18-.38.01-.82-.36-1l-3.92-1.83c-.38-.18-.83-.1-1.13.2L13.78 4.4c-.18-.24-.46-.4-.78-.4-.55 0-1 .45-1 1v1H8.82C8.4 4.84 7.3 4 6 4 4.34 4 3 5.34 3 7c0 1.1.6 2.05 1.48 2.58L7.08 18H6c-1.1 0-2 .9-2 2v1h13v-1c0-1.1-.9-2-2-2h-1.62L8.41 8.77c.17-.24.31-.49.41-.77H12v1c0 .55.45 1 1 1 .32 0 .6-.16.78-.4l1.74 1.74c.3.3.75.38 1.13.2l3.92-1.83c.38-.18.54-.62.36-1-.18-.37-.62-.54-1-.36zM6 8c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm5.11 10H9.17l-2.46-8h.1l4.3 8z"></path></svg>;
}

PrecisionManufacturing.propTypes = { fontSize: PropTypes.string };

function AdminPanelSettingsOutlined(props) {
  return <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true"
    viewBox="0 0 24 24" data-testid="HomeOutlinedIcon">
    <path d="m12 5.69 5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3 2 12h3v8h6v-6h2v6h6v-8h3L12 3z"></path>
  </svg>
}

AdminPanelSettingsOutlined.propTypes = { fontSize: PropTypes.string };
export const staticApData = {
  home: {
    icon: <HomeOutlined fontSize="medium" />,
    name: 'home',
  },
  parts: {
    icon: <PrecisionManufacturing fontSize="medium" />,
    name: 'parts',
  },
  settings: {
    icon: <PeopleOutlined fontSize="medium" />,
    name: 'userManagement',
  },
  live: {
    icon: <MyLocationOutlined fontSize="medium" />,
    name: 'live',
  },
  logbook: {
    icon: <AssignmentOutlined fontSize="medium" />,
    name: 'logbook',
  },
  deployment: {
    icon: <AdminPanelSettingsOutlined fontSize="medium" />,
    name: 'deploymentSettings',
  },
  fleet: {
    icon: <BusinessOutlined fontSize="medium" />,
    name: 'fleet',
  },
};

export const getUserApps = (domain = '') => {
  const separator = ['development', 'staging', 'integration'].includes(domain.split('.')[0]) ? '-' : '.';
  return [
    {
      key: 'home',
      link: `https://home${separator}${domain}`,
      external: true,
    },
    {
      key: 'parts',
      link: `https://parts${separator}${domain}`,
      external: true,
    },
    {
      key: 'live',
      link: `https://iot${separator}${domain}`,
      external: true,
    },
    // {
    //   key: 'fleet',
    //   link: `https://backoffice${separator}${domain}`,
    //   external: true,
    // },

    // {
    //   key: 'logbook',
    //   link: `https://logbook.${domain}`,
    // },
    // {
    //   key: 'settings',
    //   link: `https://accounts${separator}${domain}`,
    //   external: true,
    // },
    // {
    //   key: 'deployment',
    //   link: `https://deployment-admin${separator}${domain}`,
    //   external: true,
    // },
  ];
};

const useStyles = makeStyles(theme => ({
  appsIconStyles: {
    padding: 16,
    marginLeft: -8
  },
  listLink: {
    alignItems: 'center',
    display: 'inline-flex',
    width: '100%',
    textDecoration: 'none !important',
    color: 'inherit !important'
  },
  listItemIcon: {
    svg: {
      height: 24,
      width: 24,
      // fill:
      //   app.link && window.location?.origin?.match(app.link)
      //     ? theme.palette.primary.main
      //     : theme.palette.grey.A700,
    },
  },
  activeMenuItemTitle: {
    color: theme.palette.primary.light,
    backgroundColor: 'rgb(235, 253, 239)',

    '& svg': {
      fill: theme.palette.primary.light,
    }

  },

  menuItemTitle: {
    cursor: 'default',
    pointerEvents: 'none',
    '& p': {
      fontWeight: 500,
      fontSize: '1rem'
    },
    '& .MuiAvatar-root': {
      width: 56,
      height: 56,
      ml: -0.5,
      mr: 2,
    },
  },
  paperStyles: {
    boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
    borderRadius: 0,
    border: 'none',
    minWidth: 320,
    left: '0 !important',
    maxWidth: '100vw',
    overflow: 'visible',
    top: `80px !important`,
    // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    '& .MuiListItemIcon-root': {
      width: 32,
      height: 32,
      ml: 0,
      mr: 2,
      display: 'flex',
      alignItems: 'center',
    },
    '& li:': {
      // textTransform: 'capitalize',
    },

    [theme.breakpoints.down('sm')]: {
      // height: ' 100%',
      // width: '100%',
      // left: '0 !important',
      width: '96%',
      margin: '2%',
      left: '0 !important',
    },
  },
}));

export const AppBarUserMenu = ({ t }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  // const { t, i18n } = useTranslation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const applications = getUserApps('nrail.de').map((userApp) => ({
    ...userApp,
    // @ts-ignore
    ...staticApData[userApp.key],
  }));

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      // setOpen(false);
    } else if (event.key === 'Escape') {
      // setOpen(false);
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  // @ts-ignore
  return (
    <>
      <Tooltip title={t('components.appsMenu.toolTipText')}>
        <IconButton className={classes.appsIconStyles} onClick={handleClick} size="small">
          <AppsOutlined />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // variant='menu'
        PaperProps={{
          elevation: 0,
          variant: 'outlined',
          className: classes.paperStyles
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          key="navigation-user_account-menu_item-avatar"
          className={classes.menuItemTitle}
        >
          <Typography component="p" fontWeight={500}>
            {t('components.appsMenu.name')}
          </Typography>
        </MenuItem>
        {/* eslint-disable-next-line react/jsx-no-bind */}
        {applications.map((app) => (
          <MenuItem
            className={app.link && window.location?.origin?.match(app.link) ? classes.activeMenuItemTitle : ''}
            sx={{
              ...(app.link && window.location?.origin?.match(app.link)
                ? { backgroundColor: theme.palette.primary.light }
                : {}),
            }}
            key="navigation-user_account-menu_item-organisation"
            onKeyDown={handleListKeyDown}
          >
            <Link
              href={app.link}
              color={app.link && window.location?.origin?.match(app.link) ? 'primary' : 'inherit'}
              underline="none"
              className={classes.listLink}
            >
              <>
                <ListItemIcon
                  className={classes.listItemIcon}
                >
                  {app.icon}
                </ListItemIcon>
                {t(`components.appsMenu.menuItem.${app.name}`)}
              </>
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

