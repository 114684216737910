import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { getOrientation } from 'get-orientation/browser'
import { getCroppedImg, getRotatedImage } from '../../utils/image-processing/canvasUtils';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
}
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  // cropContainer: {
  //   position: 'absolute',
  //   top: 0,
  //   width: '100%',
  //   height: 300,
  //   background: '#333',
  //   [theme.breakpoints.up('sm')]: {
  //     height: 400,
  //   },
  // },
  cropButton: {
    color: '#FFFFFF'
  },
  controls: {
    padding: 16,
    bottom: 24,
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      marginRight: 16,
    },
    [theme.breakpoints.up('sm')]: {
      // flexDirection: 'row',
      // alignItems: 'center',
    },
  },
}));
const ImageUpload = ({ imageSrc, onImageChangesSaved }) => {
  const classes = useStyles();

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null);
  const { t } = useTranslation();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )

      setCroppedImage(croppedImage);
      onImageChangesSaved(croppedImage);

    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation])

  const onClose = useCallback(() => {
    setCroppedImage(null)
  }, [])


  return (
    <div>
      {imageSrc ? (
        <React.Fragment>
          <div className={classes.cropContainer}>
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={1}
              // cropSize={{
              //   width: 600,
              //   height: 600
              // }}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className={classes.controls}>
            <Button
              onClick={showCroppedImage}
              variant="contained"
              color="primary"
              classes={{ root: classes.cropButton }}
            >
              {t('button.done')}
            </Button>
            <Button
              onClick={showCroppedImage}
              variant="contained"
              // color="primary"
              // classes={{ root: classes.cropButton }}
            >
              {t('button.cancel')}
            </Button>
          </div>
          {/*<ImgDialog img={croppedImage} onClose={onClose} />*/}
        </React.Fragment>
      ) : null}
    </div>
  )
}

export default ImageUpload;
