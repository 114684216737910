import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './utils/FVMInitializerScripts'
import App from './containers/App';
import { googleAnalytics } from './containers/GATracker';
import * as serviceWorker from './service-worker-bk';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import RailationEntry from './containers/RailationEntry/index';
import i18n from './i18n';
import createRootReducer from './reducers';
import './style.scss';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router';
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux';
import thunk from "redux-thunk";
import LoadingSpinner from './components/LoadingSpinner';
import { options } from '@amcharts/amcharts4/.internal/core/Options';
import { __spread } from 'tslib';

const browserHistory = createBrowserHistory();
const routerMw = routerMiddleware(browserHistory);
function fvmLog(val) {
  if (process.env.REACT_APP_FVM_ENV === 'development') {
    if (console) {
      // console.log(val);
    }
  }
}

global.fvmLog = fvmLog;
console.fvmLog = fvmLog;

const isWpSite = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const appElem = document.querySelector('#app');

export const store = createStore(
  createRootReducer(browserHistory),
  {},
  compose(
    applyMiddleware(routerMw),
    applyMiddleware(thunk),
    applyMiddleware(googleAnalytics),
  ),
);

// noinspection JSUnresolvedVariable
if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./reducers', () => {
    store.replaceReducer(createRootReducer(browserHistory));
  });
}

export const history = syncHistoryWithStore(browserHistory, store);

function MyApp() {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Router history={history}>
      {
        <App enqueueSnackbar={enqueueSnackbar} i18n={i18n} location={window.location} />
      }
    </Router>
  );
}

ReactDOM.render(
  <Suspense fallback={<LoadingSpinner />}>
    <Provider
      store={store}
    >
      <SnackbarProvider
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        maxSnack={1}
      >
        <MyApp />
      </SnackbarProvider>
    </Provider>
  </Suspense>,
  appElem,
);

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();