import React from 'react';
const Communication = () => {
  const a = {
    fill: '#002fc9',
  }
  const b = {
    fill: '#002fc9',
    opacity: 0,
  }
  const c = {
    fill: '#fff',
    fillRule: 'evenodd',
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.999" height="33.999" viewBox="0 0 33.999 33.999">
      <g transform="translate(-726 -400)">
        <g transform="translate(635 317.999)">
          <rect style={a} width="33.999" height="33.999" rx="5" transform="translate(91 82.001)" />
        </g>
        <g transform="translate(-300)">
          <rect style={{ ...a, ...b }} width="24" height="24" transform="translate(1031 405)" />
          <path
            style={c}
            d="M-76,843H-92a2,2,0,0,0-1.99,2L-94,862l4-4h14a2.006,2.006,0,0,0,2-2V845A2.006,2.006,0,0,0-76,843Zm-5,10h-9v-2h9Zm3-4H-90v-2h12Z"
            transform="translate(1127 -435)"
          />
        </g>
      </g>
    </svg>
  );
};
export default Communication;
