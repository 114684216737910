import { decode } from 'jwt-simple';
import {
  configureRequestHeaders,
  getAccountsApi,
  getAuthenticationApi,
  getBasicAuthRefreshApi,
  getCurrentUserToken,
  getPassKeyApi,
  getRightsObj,
  getRolesApi,
  userManagementBasePath,
} from './index';

import { generateToken } from 'wordpress-jwt-auth';
import { cleanUpLocalStorage, FVM_BASE_URL } from '../wp-rest-api/STEC-Communication';
import { store } from '../index';
import { fetchUserData } from '../reducers/accounts/user';
import { getPersonAccountSettings, UpdatePersonAccountSettings } from './accounts-persons-api';
import axios from 'axios';
import { AuthPasskeyApi, Configuration } from 'nrail-user-management-api-client';
import { history } from '../index';

/***
 * Login Function
 * @param accountName
 * @param passkey
 * @param allowFvmRest
 *
 */
export const login = async (
  accountName,
  passkey,
  allowFvmRest = true,
) => {
  let resetPass = false;
  let resetTokenReceived = false;
  const { data, response, status } = await (
    await getAuthenticationApi()
  )
    .createRefreshToken({
      accountName,
      passkey,
    })
    .catch(e => {
      console.dir(e);
      if (e?.response?.status === 401) {
        resetPass = true;
      }
      return { ...e };
    });


  const currentUser = getCurrentUserToken()?.accountId;


  if (data?.refreshToken) {
    localStorage.setItem('userToken', data.refreshToken);
    // return;
  }

  if (allowFvmRest && resetPass) {
    // const nrlToken = await fvmLoginResetUmPass(accountName, passkey, true);
    if (data?.refreshToken) {
      await (await getBasicAuthRefreshApi())
        .createRefreshTokenRevocation()
        .catch(e => e);
    }

    const nrlToken = await fvmLoginResetUmPass(accountName, passkey, true);
    if (nrlToken) {
      localStorage.setItem('userToken', nrlToken);
      await configureRequestHeaders(nrlToken).then(resp => resp);
      resetTokenReceived = true;
    }
  }

  const newUserId = getCurrentUserToken()?.accountId;

  if (newUserId !== currentUser) {
    window.location.reload();
  }
  // console.log('nrlToken', nrlToken)
  if (resetTokenReceived === false && data?.refreshToken) {
    await configureRequestHeaders(data?.refreshToken).then(resp => resp);
  }

  if (data?.refreshToken || resetTokenReceived) {
    // window.history.pushState('dashboard', 'Dashboard', '/');
    // history.push('/');
  }
  //else if (([403, 401].includes(response?.status )) && resetTokenReceived === false) {
  //errorCallback(true);
  //}
  return response;
};
//userToken
// TODO: may not succeed. UI returns to logged in state
export const logout = async () => {
  try {
    const basicApiConfigs = await (getBasicAuthRefreshApi());
    await basicApiConfigs.createRefreshTokenRevocation();
  } catch (err) {
    console.log(err);
  }
  cleanUpLocalStorage(undefined, true);
  window.location.reload();
  return 204;
};

export const logbookUser401Logout = () => {

  // cleanUpLocalStorage(['userToken', '']);
};

const fvmLoginResetUmPass = (accountName, passkey, resetPass = false) => {
  return generateToken(FVM_BASE_URL, accountName, passkey).then(async (userConnection) => {
    const resetAuthenticationToken = userConnection.passkeyResetToken;
    const nRailUserRefreshToken = userConnection.nRailUserRefreshToken;
    const fvmWpToken = userConnection.token;
    const userEmail = userConnection.user_email;
    const fvmUserTokenDecoded = decode(fvmWpToken, '', true);
    const wpUserID = fvmUserTokenDecoded?.data?.user?.id;

    localStorage.setItem('fvm_wp_api_token', fvmWpToken);
    // do not rest max password
    if (typeof Storage !== 'undefined' && resetPass && resetAuthenticationToken && ![11, 24817, '24817', '11'].includes(wpUserID)) {
      const nrlRespStatus = await resetPassword(passkey, resetAuthenticationToken).catch(resp => resp);

      if (nrlRespStatus === 204 && nRailUserRefreshToken) {
        localStorage.setItem('userToken', nRailUserRefreshToken);
        await configureRequestHeaders().then(resp => resp);
        const accountSetting = await getPersonAccountSettings() || {};
        // accountSetting.wpData = {
        //   ...fvmUserTokenDecoded.data,
        // };

        UpdatePersonAccountSettings(accountSetting).then(() => {
          store.dispatch(fetchUserData());
        });
      }

      if (nrlRespStatus === 204 && fvmWpToken) {
        const wpResp = await axios.post(
          `${FVM_BASE_URL}/wp-json/wp/v2/users/me`,
          {
            // password: `${passkey}`,
            email: `fvm-nrl-${userEmail}`,
            // password: `${passkey}-fvm`,
          },
          {
            headers: {
              Authorization: `Bearer ${fvmWpToken}`,
            },
          },
        );
      }

      return nRailUserRefreshToken;
    }
    return nRailUserRefreshToken;
  })
    .catch(() => null);
};

export const newPasswordRequest = async (accountName, origin = 'telematics', emailLocale = 'de-DE') => {
  return await (
    await getPassKeyApi()
  )
    .createPasskeyResetRequest({ accountName, origin, emailLocale: emailLocale?.split?.('-')[0] || 'de' })
    .then(resp => resp.status)
    .catch(e => {
      return e.response.status;
    });
};

export const newPasswordRequestWithOrigin = newPasswordRequest;

export const updateUserPassword = async (newPasskey, oldPasskey) => {
  return await (
    await getPassKeyApi()
  ).updatePasskey({ newPasskey, oldPasskey }).catch(err => err);
};

export const resetPassword = async (newPasskey, resetAuthentificationToken) => {
  // return await (await getPassKeyApi())
  return await new AuthPasskeyApi(
    new Configuration({ basePath: userManagementBasePath }),
  )
    .createPasskeyReset({ newPasskey, resetAuthentificationToken })
    .then(resp => resp.status)
    .catch(e => {
      console.dir(e);
      return e.response.status;
    });
};

export const getRightsObjects = async () => {
  const { data } = await (await getRightsObj()).getRightsObjects();
  return data;
};

export const getRoles = async (queryParams = { orderby: ['roleName'] }) => {
  const { search, top, skip, filter, count, orderby, select } = queryParams;
  const { data } = await (await getRolesApi()).getRoles(search, top, skip, filter, count, orderby, select).catch(e => e);

  return data;
};

export const getRoleAccessRights = async (roleId) => {
  const { data } = await (await getRolesApi()).getRoleAccessRights(roleId).catch(e => ({
    data: { message: e + '' },
  }));

  return data;
};

export const getAccountAccessRights = async () => {
  const refreshToken = localStorage.getItem('userToken');
  const activeOrganisationId = localStorage.getItem('activeOrganisationId');
  const { accountId } = decode(refreshToken, '', true);
  const { data, rightsObj } = await (await getAccountsApi()).getAccountAccessRights(accountId).then(({ data }) => {
    const rightsObj = {};
    data?.accessRights.forEach(rightsOrg => {
      if (rightsOrg?.issuingOrganisationId) {
        rightsObj[rightsOrg.issuingOrganisationId] = {};
        rightsOrg.accessRights.forEach(rights => {
          rightsObj[rightsOrg.issuingOrganisationId][rights.rightsObjectName] = rights;
          // activeOrganisationId
        });
      }

    });
    return { data, rightsObj };
  }).catch(e => ({
    data: { message: e + '' },
  }));


  return rightsObj;
};
//
//
// export const getAccountAccessRights = async () => {
//   const refreshToken = localStorage.getItem('userToken');
//   const { accountId } = decode(refreshToken, '', true);
//   const { data } = await accountsApi.getAccountAccessRights(accountId).catch(e => ({
//     data: { message: e + '' },
//   }));
//   return data;
// };
//
//
// export const getPersonAccount = async () => {
//   const refreshToken = localStorage.getItem('userToken');
//   const { accountId } = decode(refreshToken, '', true);
//   const { data } = await accPersonApi.getPersonAccount(accountId).catch(e => ({
//     data: { message: e + '' },
//   }));
//   return data;
// };
//
// export const getPersonAccountMemberships = async () => {
//   const refreshToken = localStorage.getItem('userToken');
//   const { accountId } = decode(refreshToken, '', true);
//   const { data } = await accPersonMembershipApi.getPersonAccountMemberships(accountId).catch(e => ({
//     data: { message: e + '' },
//   }));
//   return data;
// };
// {
//   "safety_deadman_circuit": "safety_deadman_circuit"
//   "power_control": "power_control",
//   "misc_electronic_systems": "misc_electronic_systems",
//
// }
