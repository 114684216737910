import { withStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import IconButton from '@material-ui/core/IconButton';
import Slider from '@material-ui/core/Slider';
import ExpandMoreIcon from '@material-ui/core/SvgIcon/SvgIcon';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';

import Typography from '@material-ui/core/Typography';
import TrainIcon from '@material-ui/icons/TrainOutlined';
import axios from 'axios';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PictureAsPdfRounded from '@material-ui/icons/PictureAsPdfRounded';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';

import { changedView } from '../actions';
import FvmGAItemTracker from '../components/FvmGAItemTracker';
import LoadingSpinner from '../components/LoadingSpinner';
import NrailBootstrapTable from '../components/NrailBootstrapTable/index';
import ViewTitle from '../components/ViewTitle';
import { FUEL_CONSUMPTION_DEFAULTS } from '../globalConstants/defaultValues';
import calculateFuelConsumption from '../StateLessLocomotiveFunctions/fuelConsumption';
import { fetchDatasheet } from '../wp-rest-api/STEC-Communication';

const getFuelconsumptionLabels = (t)=>{
  return {
    application: t('labels.application'),
      averageFuelConsumption: t('labels.averageFuelConsumption'),
      heavy: t('labels.heavy'),
      light: t('labels.light'),
      distanceLong: t('labels.distanceLong'),
      distanceShort: t('labels.distanceShort'),
      standingWithEngRunning: t('labels.standingWithEngRunning'),
      totalEstimatedConsumption: t('labels.totalEstimatedConsumption'),
  };
};
/**
 * @desc The function is used to generate the styles to be applied to various elemets of the view
 * @param theme
 * @returns {{craneSeparater: {width: number, height: number}, fvmDisabled: {color: string}, rail: {color: *, opacity: number, height: number}, fuelConsHeader:
 *   {maxHeight: number, display: string, marginBottom: number, justifyContent: string}, labelContainer: {display: string, width: string, '&:first-child': {whiteSpace:
 *   string, textAlign: string, justifyContent: string}, '&:last-child': {whiteSpace: string, textAlign: string, justifyContent: string}}, labels: {fontWeight: number},
 *   textField: {maxWidth: number}, sliderlabels: {textAlign: string, marginBottom: number}, root: {fontSize: *}, sliderTitle: {textAlign: string}, disabled:
 *   {backgroundColor: string, color: string, markActive: {backgroundColor: string, color: string, opacity: number}}, markActive: {backgroundColor: *, opacity: number},
 *   track: {height: number}, mark: {backgroundColor: *, opacity: number}}}
 */
const styles = theme => ({
  labels: {
    fontWeight: 700,
  },
  textField: {
    maxWidth: 100,
  },
  sliderTitle: {
    textAlign: 'center',
  },
  labelContainer: {
    display: 'inline-flex',
    width: '20%',
    '&:first-child': {
      justifyContent: 'flex-end',
      textAlign: 'right !important',
      whiteSpace: 'nowrap',
    },
    '&:last-child': {
      justifyContent: 'flex-start',
      textAlign: 'left !important',
      whiteSpace: 'nowrap',
    },
  },
  sliderlabels: {
    textAlign: 'center',
    marginBottom: 20,
  },
  mark: {
    backgroundColor: theme.palette.primary.main,
    opacity: 1,

  },
  markActive: {
    opacity: 1,
    backgroundColor: theme.palette.primary.main,
  },
  fvmDisabled: {
    color: 'rgb(189, 189, 189)',
  },
  disabled: {
    color: 'rgb(189, 189, 189)',
    backgroundColor: 'rgb(189, 189, 189)',
    markActive: {
      opacity: 1,
      backgroundColor: 'rgb(189, 189, 189)',
      color: 'rgb(189, 189, 189)',
    },
  },
  fuelConsHeader: {
    maxHeight: 40,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  rail: {
    color: theme.palette.primary.main,
    opacity: 1,
    height: 1,
  },
  track: {
    height: 1,
  },
  root: {
    fontSize: theme.typography.fontSize,
  },
  craneSeparater: {
    // borderLeft: 'solid rgb(128, 128, 128) 2px',
    width: 2,
    height: 40,
  },
});

const numFormatter = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 });
const formatNumber2dp = num => numFormatter.format(num);

/**
 *
 * ### General Description
 * ---
 *
 * The view displays the relevant data associated with a locomotive, allows the user to modify its estimated fuel
 * consumption as well as save comments as it relates to the locomotive.<br>
 * The view is divided into 4 sections:
 *
 * - The fuel consumption (For trains which use diesel)
 * - Downloadable items
 * - The data sheet of the locomotive
 * - Comments
 *
 * Additional to this there is the Loc control which can be found under the components section of this documentation.
 *
 * #### Fuel Consumption
 *
 * The __Fuel Consumption Section__ was designed to give the user the possibility of making an estimate of the amount of
 * fuel consumed by a non-electric locomotive. The calculation is dependent on 4 main factors:
 *
 * 1\. The Application (the usage type: Shunting or Distance)
 *
 *   | Input Type(s)|   Type       |  Step size  | Unit      |
 *   |:-------------|-------------:| -----------:|----------:|
 *   | Slider       | -            | 10          |%          |
 *
 * 2\. Shunting (~Short distance usage: Heavy || light usageUnit: l/km)
 *
 *  | Input Type(s)|   Type       |  Step size  | Unit      |
 *  |:-------------|-------------:| -----------:|----------:|
 *  | Slider       | -            | 10          |%          |
 *  | Text Field   | Number       | 1           |l/km       |
 *
 * 3\. Distance (~Long distance usage: Heavy || light usage. Unit: l/km)
 *
 *  | Input Type(s)|   Type       |  Step size  | Unit      |
 *  |:-------------|-------------:| -----------:|----------:|
 *  | Slider       | -            | 10          |%          |
 *  | Text Field   | Number       | 1           |l/km       |
 *
 * 4\. Idle time with engine on (fuel used while the engine is **on** and the train is not moving. Unit: l/h)
 *
 *  | Input Type(s)|   Type       |  Step size  | Unit      |
 *  |:-------------|-------------:| -----------:|----------:|
 *  | Text Field   | Number       | 1           |l/h        |
 *
 * #### Download Item
 *
 * The __Download Items Section__ was designed to allow the user to download documents related to the locomotive.
 * These documents include but are not limit to:
 *
 * 1. The full data sheet of the locomotive.
 * 2. Operating instructions
 * 3. Service Manual
 *
 * This functionality is not always visible and depends on if the selected locomotive has any items to be
 * downloaded.<br>
 *
 * #### Data Sheet
 *
 * The __Data Sheet Section__ wast designed to give the user a quick overview of the most important data of a
 * locomotive.<br />
 * The data is presented in a tabular format with two columns as describe below:
 *
 * | Field Name|   Value      |
 * |:----------|-------------:|
 *
 * #### Comments
 * The __Comments Section__ was designed to give the user the possibility of saving data as it relates to a locomotive.
 *
 *
 *
 * ***
 * ### Detailed Technical Description
 * ***
 * @augments React
 */
class Datasheet extends Component {
  /**
   * @desc Constructor for the class.
   * @param {object} props - property items passed to the class. Both user and react defined.
   */
  constructor(props) {
    super(props);
    const activeLoco = this.props.userLocomotiveListObject[this.props.activeUserLocoId] || {};
    this.state = {
      comments: '',
      datasheetTable: {
        columns: [{
          dataField: 'label',
          text: '',
        }, {
          dataField: 'value',
          text: '',
        }],
        data: [],
      },
      labels: {},
      fuelConsumption: activeLoco.fuelConsumption || { ...FUEL_CONSUMPTION_DEFAULTS },
      fuelConsumptionInteraction: false,
    };
    this.commonClassNames = 'section-heading';
    this.updateDatasheetTableData = this.updateDatasheetTableData.bind(this);
    this.saveComments = this.saveComments.bind(this);
    this.onCommentsChange = this.onCommentsChange.bind(this);
    this.updateLoadingState = this.updateLoadingState.bind(this);
    this.fetchNewHoursData = this.fetchNewHoursData.bind(this);
    this.renderFuelConsumption = this.renderFuelConsumption.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.onClickFuelConsumptionToggler = this.onClickFuelConsumptionToggler.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.updateLoadingState(true);
    this.updateDatasheetTableData(this.props.staticTableInfo);
    this.fetchNewHoursData(this.props.activeUserLocoId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      activeUserLocoId,
      staticTableInfo,
      userLocomotiveListObject,
      languageFilesReloaded,
    } = this.props;

    if (staticTableInfo !== prevProps.staticTableInfo && prevProps.languageFilesReloaded === languageFilesReloaded) {
      if(activeUserLocoId){
        this.fetchNewHoursData(activeUserLocoId);
      }else{
        this.updateDatasheetTableData(staticTableInfo);
      }
    }

    if (activeUserLocoId !== prevProps.activeUserLocoId) {
      this.updateLoadingState(true);
      this.fetchNewHoursData(activeUserLocoId);
      const activeLoco = userLocomotiveListObject[activeUserLocoId] || {};
      const fuelConsumption = activeLoco.fuelConsumption || { ...FUEL_CONSUMPTION_DEFAULTS };
      this.setState({ fuelConsumption, fuelConsumptionInteraction: false });
    }
    if(prevProps.languageFilesReloaded !== languageFilesReloaded){
      this.setState({ componentLanguageUpdateTrigger: prevProps.languageFilesReloaded });
      this.fetchNewHoursData(activeUserLocoId);
    }

  }

  onCommentsChange(event) {
    const { target } = event;

    if (target.value || target.value === '') {
      this.setState({ comments: target.value });
    }
  }

  /**
   * The function is usd to update the datasheet table.
   * @param {object} staticTableInfo _Object contains static data that is common to all locomotives
   * @param {object} datasheet The specifics of each train.
   */
  updateDatasheetTableData(datasheet = {}) {
    let datasheetKeys = Object.keys(datasheet);
    if(!datasheetKeys.length){
      datasheetKeys  = Object.keys(this.props.staticTableInfo);
    }

    const datasheetDataArray = [];
    datasheetKeys.forEach((key, index) => {
      if (key !== 'meterstatus') {
        datasheetDataArray.push({
          name: key,
          label: this.props.t(`tables.tRow.datasheet.${key}`),
          // label: this.props.t(`${key}`),
          value: datasheet[key],
        });
      }
    });
    const datasheetTable = { ...this.state.datasheetTable, data: datasheetDataArray };
    this.setState({ datasheetTable, comments: '' });
  }

  async fetchNewHoursData(activeUserLocoId = 0) {
    if (!activeUserLocoId) {
      this.updateLoadingState(false);
      this.updateDatasheetTableData(this.props.staticTableInfo);
      return;
    }
    const {vehicleId} = this.props.userLocomotiveListObject[activeUserLocoId];
    const resp = await fetchDatasheet({vehicleId});
    this.updateDatasheetTableData(resp);
    this.updateLoadingState(false);
  }

  /**
   * @desc The function is used to indicate that a action is in process. The usual behaviour of this function is th
   * trigger tge modal.
   * @param {boolean} status _the state to indicate_
   * @param {number} time _the timeout for the state to be adjusted_
   */
  updateLoadingState(status, time = 0) {
    setTimeout(() => {
      this.setState({ isLoading: status });
    }, time);
  }

  /**
   * @desc The function is used to save the uer inputs to the WordPress poss that is associated to with the
   * select locomotive.
   * @returns {Promise<void>}
   */
  async saveComments() {
    const data = {
      comment: this.state.comments,
      fuelConsumption: this.state.fuelConsumption,
    };

    if (this.state.fuelConsumption.isActive) {
      data.fuelConsumption = this.state.fuelConsumption;
    }
    const { vehicleId, wpId } = this.props.userLocomotiveListObject[this.props.activeUserLocoId] || {};
    const { t } = this.props;

    if (vehicleId) {
      const resp = await axios.post(
        `${this.props.appConsts.fvmURL}/wp-json/fvm/v1/locomotive/comment/${wpId}`,
        data,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
        },
      );
      let dialog = {
        title: 'updates failed to save',
        contentText: '',
        actionButtons: [],
        time: 2000,
      };

      if (resp.status === 200) {
        const { comments } = resp.data;
        this.setState({ comments, fuelConsumptionInteraction: false });
        dialog = {
          title: '',
          contentText: t('messages.saved'),
          actionButtons: [],
          time: 2000,
        };
      }
      this.props.showDialog({ ...dialog });
    }
  }

  /**
   * Used to control the visibility of the fuel consumption feature.
   * the default state is on.
   */
  onClickFuelConsumptionToggler() {
    const { fuelConsumption } = this.state;
    fuelConsumption.isActive = !fuelConsumption.isActive;
    this.setState({ fuelConsumption });
  }

  /**
   * Function is ued to render the pdf download buttons (Icons).
   * @TODO: Rename method to a more suitable name.
   * @param pdfObj
   * @returns {*}
   */
  generatePDFs(pdfObj) {
    return (
      <div key={pdfObj.name} className="fvm-cp-item">
        <a target="_blank" href={pdfObj.url} rel="noopener noreferrer">
          <div className="fvm-cp-body">
            <IconButton>
              <PictureAsPdfRounded fontSize="large" />
            </IconButton>
            <div className="fvm-cp-title">
              {pdfObj.name}
            </div>
          </div>
        </a>
      </div>
    );
  }

  /**
   * Function is used to determine which train will display the download pdf option.
   *
   * @TODO: This feature is for demonstration purposes and has not been fully implemented. The client should provide
   * @TODO: proper download items.
   *
   * @returns {*[]}
   */
  findPdfs() {
    const baseUrl = window.location.origin;
    let pdfLst = [];
    if ([].includes(this.props.activeUserLocoId)) {
      pdfLst = [
        {
          name: 'Bedienungsanweisung',
          url: `${baseUrl}/assets/pdfs/G1000_Bedienungsanweisung.pdf`,
        },
        {
          name: 'Servicehandbuch',
          url: `${baseUrl}/assets/pdfs/G1000_Servicehandbuch.pdf`,
        },
        {
          name: 'Technische Beschreibung',
          url: `${baseUrl}/assets/pdfs/G100_Technische_Beschreibung.pdf`,
        },
      ];
    }

    if ([].includes(this.props.activeUserLocoId)) {
      pdfLst = [
        {
          name: 'Ersatzteilkatalog',
          url: `${baseUrl}/assets/pdfs/G1206_500_Ersatzteilkatalog.pdf`,
        },
        {
          name: 'Servicehandbuch',
          url: `${baseUrl}/assets/pdfs/G1206_500_Servicehandbuch.pdf`,
        },
        {
          name: 'Technische Beschreibung',
          url: `${baseUrl}/assets/pdfs/G1206_500_Technische_Beschreibung.pdf`,
        },
      ];
    }
    return [...pdfLst];
  }

  /**
   * The method modifies the value of a selected slider. The slider is determined by the @target parameter.
   * @param {number} newVal
   * @param {String} target
   */
  handleSliderChange(newVal, target) {
    const group = target.split('-');
    const { fuelConsumption } = this.state;

    if (group.length === 2) {
      fuelConsumption[group[0]][group[1]] = newVal;
    }
    if (group.length === 1) {
      fuelConsumption[group[0]] = newVal;
    }
    if (group.length) {
      this.setState({ fuelConsumption, fuelConsumptionInteraction: true });
    }
  }

  /**
   * General function for handling all input changes in the view
   * @param event
   */
  handleInputChange(event) {
    const { target } = event;
    const { value } = target;
    const group = target.name.split('-');
    const { fuelConsumption } = this.state;

    if (value < 0) {
      return;
    }

    if (group.length === 2) {
      fuelConsumption[group[0]][group[1]] = value;
    }
    if (group.length === 1) {
      fuelConsumption[group[0]] = value;
    }
    if (group.length) {
      this.setState({ fuelConsumption, fuelConsumptionInteraction: true });
    }
  }

  /**
   * The function is used to render the fuel consumption section of the view. <br />
   * @returns {React view} view
   */
  renderFuelConsumption() {
    const {
      usageTypePercentage,
      shortDistance,
      longDistance,
      standingWithEngRunning,
      isActive,
    } = this.state.fuelConsumption;
    const {  fuelConsumptionInteraction } = this.state;

    const {
      classes,
      t
    } = this.props;
    const labels = getFuelconsumptionLabels(t);

    const marks = [...Array(11).keys()].map((val, index) => ({ value: 10 * index }));
    const {
      totalLetersPerKm,
    } = calculateFuelConsumption(this.state.fuelConsumption);

    return (
      <section>
        <header className={classNames(classes.fuelConsHeader)}>
          <h2 className={classNames(' mb-4', !isActive ? classes.fvmDisabled : '')}>
            {labels.averageFuelConsumption}
            <Switch
              checked={isActive}
              onChange={this.onClickFuelConsumptionToggler}
              value="status"
              color="primary"
            />
          </h2>
          <FvmGAItemTracker
            category={'Save Locomotive Settings'}
            label={`Save Locomotive - ${fuelConsumptionInteraction ? 'Fuel Consumption and' : ''} Comments`}
            action={`Save`}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={this.props.isDemoUser || !this.props.activeUserLocoId}
              className="fvm-btn fvm-save-button fvm-right-save-button"
              onClick={(e) => {
                e.preventDefault();
                this.saveComments();
              }}
            >
              {this.props.t('button.save')}
            </Button>
          </FvmGAItemTracker>
        </header>


        <ExpansionPanel className="mb-4" expanded={isActive} disabled={!isActive}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={!isActive ? 'd-none' : ''}
          >
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>

            <div className="w-100 justify-content-center fvm-fuel-slider-container">
              <div className="w-100 ">
                <Typography
                  className={classNames(classes.labels, classes.sliderlabels)} id="discrete-slider" gutterBottom
                >
                  {labels.application}
                </Typography>
                <div className="d-flex w-100 mb-5 justify-content-between">
              <span
                className={classNames(classes.labelContainer, classes.labels)}
              >
                {labels.distanceShort} ({100 - usageTypePercentage}%)
              </span>
                  <Slider
                    disabled={!isActive}
                    className={classNames('mr-3 ml-3 fvm-fuel-slider')}
                    classes={isActive ? classes : {}}
                    defaultValue={usageTypePercentage}
                    getAriaValueText={value => `${value}`}
                    onChange={(event, value) => {
                      this.handleSliderChange(value, 'usageTypePercentage');
                    }}
                    aria-labelledby="continuous-slider"
                    step={10}
                    id="usageTypePercentage"
                    name="usageTypePercentage"
                    marks={marks}
                    valueLabelDisplay="off"
                  />
                  <span className={classNames(classes.labelContainer, classes.labels)}>
                {labels.distanceLong} ({usageTypePercentage}%)
              </span>
                </div>
                <Typography
                  className={classNames(classes.labels, classes.sliderlabels)} id="discrete-slider" gutterBottom
                >
                  {labels.distanceShort}
                </Typography>
                <div className="d-flex  justify-content-between w-100 mb-5">
              <span className={classNames(classes.labelContainer)}>
                <TextField
                  disabled={!isActive}
                  label="l/km"
                  className={classNames(classes.textField)}
                  type="number"
                  value={shortDistance.light}
                  name="shortDistance-light"
                  onChange={this.handleInputChange}
                  id="ss-numberformat-input"
                />
                <span
                  className={classNames('ml-3', classes.labels)}
                >
                  {labels.light} ({100 - shortDistance.usagePercentage}%)
                </span>
              </span>

                  <Slider
                    className="mr-3 ml-3 fvm-fuel-slider"
                    disabled={!isActive}
                    classes={isActive ? classes : {}}
                    defaultValue={shortDistance.usagePercentage}
                    onChange={(event, value) => {
                      this.handleSliderChange(value, 'shortDistance-usagePercentage');
                    }}
                    marks={marks}
                    step={10}
                    valueLabelDisplay="off"
                  />
                  <span className={classNames(classes.labelContainer)}>
                <span
                  className={classNames('mr-3', classes.labels)}
                >
                {labels.heavy} ({shortDistance.usagePercentage}%)
              </span>
              <TextField
                disabled={!isActive}
                className={classNames(classes.textField)}
                label="l/km"
                type="number"
                value={shortDistance.heavy}
                name="shortDistance-heavy"
                onChange={this.handleInputChange}
                id="ss-numberformat-input"
              />
             </span>
                </div>
                <Typography
                  className={classNames(classes.labels, classes.sliderlabels)} id="discrete-slider" gutterBottom
                >
                  {labels.distanceLong}
                </Typography>
                <div className="d-flex align-items-center justify-content-between w-100 mb-5">
              <span className={classNames(classes.labelContainer)}>
                <TextField
                  value={longDistance.light}
                  className={classNames(classes.textField)}
                  disabled={!isActive}
                  label="l/km"
                  type="number"
                  name="longDistance-light"
                  onChange={this.handleInputChange}
                  id="ss-numberformat-input"
                />
                <span
                  className={classNames('ml-3', classes.labels)}
                >{
                  labels.light} ({100 - longDistance.usagePercentage}%)
                </span>
              </span>
                  <Slider
                    disabled={!isActive}
                    className="mr-3 ml-3 fvm-fuel-slider"
                    // classes={isActive ? classes : {}}
                    classes={isActive ? classes : classes}
                    id="longDistance-usagePercentage"
                    value={longDistance.usagePercentage}
                    onChange={(event, value) => {
                      this.handleSliderChange(value, 'longDistance-usagePercentage');
                    }}
                    marks={marks}
                    step={10}
                    valueLabelDisplay="off"
                  />
                  <span className={classNames(classes.labelContainer)}>
                <span
                  className={classNames('mr-3', classes.labels)}
                >
                  {labels.heavy} ({longDistance.usagePercentage}%)
                </span>
                <TextField
                  disabled={!isActive}
                  className={classNames(classes.textField)}
                  value={longDistance.heavy}
                  classes={classes.labels}
                  label="l/km"
                  type="number"
                  name="longDistance-heavy"
                  onChange={this.handleInputChange}
                  id="ss-numberformat-input"
                />
              </span>
                </div>
              </div>
              <div className="d-block w-100 align-items-center justify-content-between text-center mb-3">
                <span className={classNames(classes.labels)}>{labels.standingWithEngRunning} : </span>
                <TextField
                  disabled={!isActive}
                  className={classNames(classes.textField)}
                  label="l/h"
                  type="number"
                  value={standingWithEngRunning}
                  name="standingWithEngRunning"
                  onChange={this.handleInputChange}
                  id="ss-numberformat-input"
                />
              </div>
              <div className="d-block  w-100 justify-content-between text-center mb-3">
                <h4 className=" mb-3">{labels.totalEstimatedConsumption}: {formatNumber2dp(totalLetersPerKm)} l/km
                </h4>
              </div>
            </div>
          </ExpansionPanelDetails>

        </ExpansionPanel>
      </section>);
  }

  render() {
    const { datasheetTable, isLoading, fuelConsumptionInteraction } = this.state;
    const pdfLst = this.findPdfs();
    const { t, classes, activeUserLocoId, userLocomotiveListObject } = this.props;
    const { name } = userLocomotiveListObject[activeUserLocoId]?.vehicle || {};

    return (
      datasheetTable.data.length ?
        (
          <article className="fvm-page fvm-page-datasheet">
            <div className="fvm-general-page-content">
              <header className="fvm-page-header">
                <ViewTitle title={t('navigationMenu.datasheet')}/>
                {
                  <Chip
                    label={name}
                    avatar={
                      <Avatar>
                        <TrainIcon />
                      </Avatar>
                    }
                    className="fvm-locomotive-chip"
                  />
                }
              </header>
              {/*{this.renderFuelConsumption({ classes })}*/}
              {
                pdfLst.length
                  ?
                  <section className="fvm-sp fvm-sp-datasheet-downloads container-fluid">
                    <div className="row">
                      <div className="col">
                        <header className="fvm-section-header">
                          <h2 className="fvm-section-title">{t('labels.datasheetDownload')}</h2>
                        </header>
                      </div>
                    </div>
                    <div className="row fvm-pdf-downloads">
                      {pdfLst.map(this.generatePDFs)}
                    </div>
                  </section>
                  : null
              }
              <section className="fvm-sp fvm-sp-datasheet-data container-fluid">
                <div className="row">
                  <div className="col">
                    <NrailBootstrapTable
                      keyField="name"
                      className={`${this.commonClassNames} fvm-datasheet-table`}
                      {...datasheetTable}
                      title={t('tables.caption.datasheet')}
                    />
                  </div>
                </div>
              </section>
              {/*<section className="fvm-sp fvm-sp-datasheet-note container-fluid">*/}
              {/*  <div className="row">*/}
              {/*    <div className="col">*/}
              {/*      <header className="fvm-section-header">*/}
              {/*        <h2 className="fvm-section-title">{t('labels.datasheetComments')}</h2>*/}
              {/*      </header>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div className="row">*/}
              {/*    <div className="col">*/}
              {/*      <form>*/}
              {/*      <textarea*/}
              {/*        onChange={this.onCommentsChange}*/}
              {/*        value={this.state.comments}*/}
              {/*        className="fvm-textarea"*/}
              {/*      />*/}
              {/*        <FvmGAItemTracker*/}
              {/*          category={'Save Locomotive Settings'}*/}
              {/*          label={`Save Locomotive - ${fuelConsumptionInteraction ? 'Fuel Consumption and' : ''} Comments`}*/}
              {/*          action={`Save`}*/}
              {/*        >*/}
              {/*          <Button*/}
              {/*            variant="contained"*/}
              {/*            color="primary"*/}
              {/*            size="large"*/}
              {/*            disabled={this.props.isDemoUser || !this.props.activeUserLocoId}*/}
              {/*            className="fvm-btn fvm-save-button fvm-right-save-button"*/}
              {/*            onClick={(e) => {*/}
              {/*              e.preventDefault();*/}
              {/*              this.saveComments();*/}
              {/*            }}*/}
              {/*          >*/}
              {/*            {t('button.save')}*/}
              {/*          </Button>*/}
              {/*        </FvmGAItemTracker>*/}
              {/*      </form>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</section>*/}
            </div>
            {isLoading ? <LoadingSpinner /> : null}
          </article>)
        : null
    );
  }
}

Datasheet.propTypes = {
  // newView: PropTypes.func.isRequired,
  isDemoUser: PropTypes.bool.isRequired,
  userLocomotiveListObject: PropTypes.objectOf(PropTypes.object).isRequired,
  staticTableInfo: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  activeUserLocoId: [...state.activeUserLocomotiveListIds].pop() || 0,
  isDemoUser: state.isDemoUser || false,
  userLocomotiveListObject: state.userLocomotiveList,
  staticTableInfo: state.dataSheetStaticData,
  languageFilesReloaded: state.languageFilesReloaded,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    newView: changedView,
  }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Datasheet));
