import React from 'react';
const FuelStation = () => {
  const a = {
    fill: '#002fc9',
  }
  const b = {
    ...a,
    opacity: 0,
  }
  const c = {
    fill: '#fff',
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.999" height="33.999" viewBox="0 0 33.999 33.999">
      {/*<style>.a,.b{fill:#002fc9;}.b{opacity:0;}.c{fill:#fff;}</style>*/}
      <g transform="translate(-726 -688)">
        <g transform="translate(635 605.999)">
          <rect style={a} width="33.999" height="33.999" rx="5" transform="translate(91 82.001)" />
        </g>
        <g transform="translate(577 117)">
          <rect style={b} width="24" height="24" transform="translate(154 576)" />
          <path
            style={c}
            d="M-76.656,510.313l-2.814-2.843-1.06,1.06,2.089,2.089A1.986,1.986,0,0,0-79,512a2,2,0,0,0,2,2v8.5a1,1,0,0,1-1,1,1,1,0,0,1-1-1v-5a1.5,1.5,0,0,0-1.5-1.5H-82v-7a2.006,2.006,0,0,0-2-2h-6a2.006,2.006,0,0,0-2,2v17h10v-8.5h1.5v5A2.5,2.5,0,0,0-78,525a2.5,2.5,0,0,0,2.5-2.5v-9.209A3.814,3.814,0,0,0-76.656,510.313ZM-84,513h-6v-4h6Z"
            transform="translate(250 72)"
          />
        </g>
      </g>
    </svg>
  );
};
export default FuelStation;
