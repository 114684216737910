// import PropTypes from 'prop-types';
import React, { Component } from 'react';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import connect from 'react-redux/es/connect/connect';
import NotificationSettings from '../components/NotificationSetting';
import { bindActionCreators } from 'redux';
import { changedView } from '../actions';
import LoadingSpinner from '../components/LoadingSpinner';

/**
 *
 */
class NotificationSettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };

  }

  render() {
    const { isLoading } = this.state;
    const { t, appConsts } = this.props;

    return (
      <article className="fvm-page">
        <NotificationSettings
          appConsts={appConsts}
          t={t}
          singleSelect={true}
        />
        {isLoading ? <LoadingSpinner /> : null}
      </article>
    );
  }
}

NotificationSettingsPage.propTypes = {};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    newView: changedView,
  }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsPage);
