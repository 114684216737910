import { FormLabel, withStyles } from '@material-ui/core';

const NrlFormLabel = withStyles((theme) => ({
  root: {
    '&.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.54)',
    }
    // '&': {
    //   color: 'inherit',
    // }
  },
}))(FormLabel);


export default NrlFormLabel;
