import classNames from 'classnames';
import React from 'react';

export const ACTIVE_CRANE_LIST = 'ACTIVE_CRANE_LIST';
export const DATA_LODING = 'DATA_LODING';
export const LOCALE_CHANGED = 'LOCALE_CHANGED';
export const LOCOMOTIVE_CARD_CLICKED = 'LOCOMOTIVE_CARD_CLICKED';
export const SEARCH_TERM_CHANGED = 'SEARCH_TERM_CHANGED';
export const LOCOMOTIVE_LIST_FILTERED = 'LOCOMOTIVE_LIST_FILTERED';
export const UPDATE_USER_LOCOMOTIVE_LIST = 'UPDATE_USER_LOCOMOTIVE_LIST';
export const SELECT_ALL_LOCO = 'SELECT_ALL_LOCO';
export const SELECT_ALL_DEVICES = 'SELECT_ALL_DEVICES';
export const SELECT_DRIVING = 'SELECT_DRIVING';
export const SELECT_STANDING = 'SELECT_STANDING';
export const SELECT_EQUIPPED = 'SELECT_EQUIPPED';
export const SELECT_OFF = 'SELECT_OFF';
export const CURRENT_VIEW = 'CURRENT_VIEW';
export const HAS_INTERNET_CONNECTION = 'HAS_INTERNET_CONNECTION';
export const IS_HIDE_LOGIN_UNTIL_ONLINE = 'IS_HIDE_LOGIN_UNTIL_ONLINE';
export const HAS_OPEN_LOGBOOK_ENTRIES = 'HAS_OPEN_LOGBOOK_ENTRIES';
export const HEAT_MAP = 'HEAT_MAP';
export const SHOW_SATELLITE = 'SHOW_SATELLITE';
export const SHOW_RAILATION = 'SHOW_RAILATION';
export const TRACKING_MAP = 'TRACKING_MAP';
export const SHOW_RAILWAY = 'SHOW_RAILWAY';
export const DATE_CHANGED = 'DATE_CHANGED';
export const DATA_LOADING = 'DATA_LOADING';
export const MAP_LOADING = 'MAP_LOADING';
export const ACTIVE_LOCOMOTIVE_LIST = 'ACTIVE_LOCOMOTIVE_LIST';
export const TARGET_LOCOMOTIVE = 'TARGET_LOCOMOTIVE';
export const TRACKED_LOCOMOTIVE = 'TRACKED_LOCOMOTIVE';
export const DEMO_USER = 'DEMO_USER';
export const LIST_VIEW = 'LIST_VIEW';
export const DATA_SHEET_STATIC_DATA = 'DATA_SHEET_STATIC_DATA';
export const FVM_FEATURE = 'FVM_FEATURE';
export const STEC_CONNECT = 'STEC_CONNECT';
export const NAV_BAR_EXPANDED = 'NAV_BAR_EXPANDED';
export const FVM_USER_SESSION_ID = 'FVM_USER_SESSION_ID';
export const IS_LOC_CONTROL_HIDDEN = 'IS_LOC_CONTROL_HIDDEN';
export const LOGBOOK_ENTRIES_SYNCED_TIMESTAMP = 'LOGBOOK_ENTRIES_SYNCED_TIMESTAMP';
export const IS_LIVE_SYSTEM = 'IS_LIVE_SYSTEM';
export const IS_REMOVE_FOCUS_ID = 'IS_REMOVE_FOCUS_ID';
export const IS_TWITTER = 'IS_TWITTER';
export const IS_RAILATION = 'IS_RAILATION';
export const IS_ZOOMED_EIGHTY = 'IS_ZOOMED_EIGHTY';
export const USER_MADE_INITIAL_DEVICE_SELECTION = 'USER_MADE_INITIAL_DEVICE_SELECTION';
export const TOGGLE_ALL_CRANES = 'TOGGLE_ALL_CRANES';
export const IS_HAVE_CRANES = 'IS_HAVE_CRANES';
export const IS_NEW_RECEIVED_TIMESTAMP = 'IS_NEW_RECEIVED_TIMESTAMP';
export const CLOSE_DETAILED_DRAW = 'CLOSE_DETAILED_DRAW';
export const FETCH_ACTIVE_LOCO_LIST_DATA = 'FETCH_ACTIVE_LOCO_LIST_DATA';
export const UPDATE_VIEW = 'UPDATE_VIEW';
export const NRL_ACCESS_TOKEN = 'NRL_ACCESS_TOKEN';
export const IS_USER_LOGGED_IN = 'IS_USER_LOGGED_IN';
export const IS_SESSION_VALID = 'IS_SESSION_VALID';
export const IS_DEVELOPMENT = 'IS_DEVELOPMENT';
