const calculateFuelConsumption = (fuelConsumption) => {
  const {
    usageTypePercentage,
    shortDistance,
    longDistance,
    standingWithEngRunning,
  } = fuelConsumption;

  const shortDistanceUsagePercentage = (100 - usageTypePercentage) / 100;
  const shortDisleterLightPerKm = (shortDistance.light * shortDistanceUsagePercentage) * ((100 - shortDistance.usagePercentage) / 100);
  const shortDisleterHeavyPerKm = (shortDistance.heavy * shortDistanceUsagePercentage) * (shortDistance.usagePercentage / 100);

  const longDistanceUsagePercentage = usageTypePercentage / 100;
  const longDisleterLightPerKm = (longDistance.light * longDistanceUsagePercentage) * ((100 - longDistance.usagePercentage) / 100);
  const longDisleterHeavyPerKm = (longDistance.heavy * longDistanceUsagePercentage) * (longDistance.usagePercentage / 100);

  const totalLetersPerKm = shortDisleterLightPerKm + shortDisleterHeavyPerKm + longDisleterLightPerKm + longDisleterHeavyPerKm;

  return {
    shortDistanceUsagePercentage,
    shortDisleterLightPerKm,
    shortDisleterHeavyPerKm,
    longDistanceUsagePercentage,
    longDisleterLightPerKm,
    longDisleterHeavyPerKm,
    totalLetersPerKm,
    standingWithEngRunning,
  };
};

export default calculateFuelConsumption;
