import { combineReducers } from 'redux';
import * as reducers from './locomotive-reducer';
import userReducer from './accounts/user'
import { routerReducer } from 'react-router-redux';
import { connectRouter } from 'connected-react-router'

const createRootReducer = (history) => combineReducers({
  ...reducers,
  ...userReducer,
  routing: routerReducer,
  router: connectRouter(history),

});

export default createRootReducer;
