import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { changedView } from '../../actions';
import ViewHeader from '../../components/ViewHeader';

import { withStyles } from '@material-ui/core/styles';
import FVMDrawer from '../../components/FVMDrawer';
import LogBookTable from './LogbookTable';
// import LogbookEntryForm from './LogbookEntryForm';
import {
  getLogbookEntries,
  getLogbookEntriesExport,
  getVehicleMetadataInOrganisation,
} from '../../apis/logbook-apis';
import Toolbar from '@material-ui/core/Toolbar';
import { Box, Button, List, ListItem, Modal, Paper } from '@material-ui/core';
import FVMTrackedButton from '../../components/TrackedButton';
import GetApp from '@material-ui/icons/GetApp';
import ReactPaginate from 'react-paginate';
import DialogTwoButtons from '../../components/Dialogs/DialogTwoButtons';
import LoadingSpinner from '../../components/LoadingSpinner';
import LogbookEntryForm from '../../components/Logbook/LogbookEntryForm';
import { createNewEntry } from '../../components/Logbook/LogbookEntryForm/helper';
import LogbookEntryState from '../../components/Logbook/core/LogbookEntryState';
import { Link } from 'react-router-dom';
import { buildTableColumns } from './DraftEntries/logbook-page-helpers';
import classNames from 'classnames';
import ViewTitle from '../../components/ViewTitle';
import { Trans, useTranslation } from 'react-i18next'

const styles = theme => ({
  headerWrapperClass: {
    '& th:nth-child(3)': {
      width: 216,
    }
  },
  fvmTableData: {
    height: 'calc(100vh - 370px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 370px)',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 410px)',
    },
    border: ' 1px solid #dfe3e6',
    borderLeft: 0,
    overflow: 'auto',
    '& div > table': {
      minWidth: 2100,
      '& > thead > tr > th ': {
        position: 'sticky',
        top: 0,
        background: '#F0F3F6',
        boxShadow: 'inset 0.5px -1px 0 #dfe3e6',
      },
      '& td ': {
        boxShadow: 'inset 0.5px -0.5px 0 #dfe3e6',
      },

      '& > thead > tr > th, & > thead > tr ': {
        whiteSpace: 'nowrap',
        border: 0,
      },

      '& > thead > tr > th[data-row-selection]': {
        width: 48,
      },
    },
  },
  openEntriesLink: {
    padding: 8,
    '& a': {
      color: '#FF0000',
      textDecorationLine: 'none',
    },
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 25,
  },
  clickableTableItem: {
    cursor: 'pointer',
    color: 'blue',
  },
  logbookHeaderContainer: {
    display: 'flex',
    '& > header': {
      minWidth: 260,
    },
  },
  exportAllPagesOptions: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      display: 'inline-flex',

    },
    '& div> button': {
      marginRight: 8,
    },
    '& div> p': {
      marginBottom: 0,
      alignSelf: 'center',
    },
  },
  articleClass: {
    position: 'relative',
  },
  fvmTableHead: {
    // '&:first-child': {
    //   width: 300
    // }
  },
  exportModal: {
    width: 435,
    maxHeight: 435,
  },
  capitalized: {
    textTransform: 'Capitalize',
  },
  defectDisplay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: 20,
    borderRadius: 4,
    backgroundColor: '#fff',
    whiteSpace: 'pre-line'
  }
});

// ********************************************************

/**
 *
 */
class Logbook extends Component {
  constructor(props) {
    super(props);
    const { t, classes } = this.props;
    const getTableHeadText = tableHeadKey => {
      return t(`${tableHeadKey}`);
      // return t(`tables.tHead.${tableHeadKey}`);
    };

    const savedLogbookSettings = this.getUserSettings();
    this.state = {
      defectLoading: false,
      defectNotice: {
        copy: "",
        open: false
      },
      liveDataStaticCol: buildTableColumns({
        t, classes, openLogEntryById: this.openLogEntryById, userLocoListObj: this.props.userLocomotiveListObject, user: this.props.user
      }),
      entriesMap: {},
      selectedTableItem: {},
      topNumberOfEntries: 20,
      skip: 0,
      logbookEntryId: null,
      isEntryModifiedAndUserTriesToExit: false,
      isDrawerOpen: false,
      isEntryModified: false,
      isLoading: false,
      lastInspectionPerformedTimestamp_A1: null,
      isDriver: false,
      isSaved: false,
      isDialogOpen: false,
      activeUserLocoId: props.activeUserLocoId,
      name: props.name,
      logbook: {
        columns: buildTableColumns({
          t, classes, openLogEntryById: this.openLogEntryById, userLocoListObj: this.props.userLocomotiveListObject, user: this.props.user
        }),
        data: [],
      },
      isFormActive: false,
      form: {
        date: moment().format('DD.MM.YYYY HH:mm:ss'),
        shiftnumber: '',
        drivername: '',
        work: [{ task: '', time: '' }],
        currentkm: '',
        operatinghours: '',
        motoroil: '',
        coolingwater: '',
        fueltank: '',
        note: '',
      },
      tableData: [],
      comments: { items: [] },
      orderby: savedLogbookSettings?.orderby || ['createTimestamp desc'],
      exportOptions: [
        {
          id: 'csv',
          accept: 'text/csv; charset=utf-8',
          name: t('button.exportAsCsv'),
        },
        {
          id: 'excel',
          accept: 'application/vnd.ms-excel',
          name: t('button.exportAsExcel'),
        },
      ],
      selectedExportType: null,
      isSelectAllEntriesActive: false,


      totalNumberOfSelectEntries: 0,
      isSelectALlEntries: 0,
    };

    this.handleGetEntries = this.handleGetEntries.bind(this);
    this.handleDownloadEntries = this.handleDownloadEntries.bind(this);
    this.handleTableSort = this.handleTableSort.bind(this);
  }

  componentDidMount() {
    if (Object.keys(this.props.userLocomotiveListObject).length) {
      this.handleGetEntries({});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeUserLocoId, name, activeOrganisationId, user } = this.props;
    if (
      prevProps.activeUserLocoId !== activeUserLocoId ||
      ((prevProps.activeOrganisationId !== activeOrganisationId) && Object.keys(this.props.userLocomotiveListObject).length)
    ) {
      this.setState({ activeUserLocoId });
      this.setState({ name });
      this.setState({ tableData: [] });
      this.setState({ selectedTableItem: {} });
      this.handleLanguageChanged(user.settings.language);
      this.setState({ skip: 0 });
      this.handleGetEntries({ skip: 0 });
      this.setState({
        totalNumberOfSelectEntries: 0,
        isSelectALlEntries: false,
        isOnAllPagesSelectALlEntries: false,
        selectedTableItem: {},
      });
    }
    if (user.settings.language !== prevProps.user.settings.language) {
      this.handleLanguageChanged(user.settings.language);
    }

    if (
      Object.keys(this.props.userLocomotiveListObject).length !==
      Object.keys(prevProps.userLocomotiveListObject).length
    ) {
      this.handleGetEntries({});
    }

    if (user.accessRights !== prevProps.user.accessRights) {
      // console.log(user.accessRights)
    }
  }

  componentWillUnmount() {
    clearInterval(this.updaterId);
  }

  closeModal = () => {
    this.setState({ isFormActive: false, isDialogOpen: false });
  };

  handleLanguageChanged = languageFilesReloaded => {
    const columns = {
      liveDataStaticCol: buildTableColumns({
        t: this.props.t,
        classes: this.props.classes,
        openLogEntryById: this.openLogEntryById,
        userLocoListObj: this.props.userLocomotiveListObject,
        user: this.props.user
      }),
    };

    this.setState(columns);

    this.setState({
      tableData: this.state.tableData.map(
        ({ header, defects, stoppage, lastComment, vehicleState }) => ({
          ...header,
          header,
          defects,
          stoppage,
          lastComment,
          vehicleState,
          language: languageFilesReloaded,
        }),
      ),
    });
  };

  openLogEntryById = async e => {
    e.stopPropagation();
    this.setState({ commentsSignatures: {} });
    this.setState({ comments: { items: [] } });
    if (e.target.getAttribute('data-entry-id')) {
      this.updateActiveEntryId(e.target.getAttribute('data-entry-id'));
    }
  };

  updateActiveEntryId = (logbookEntryId) => {
    this.setState({ logbookEntryId });
    const activeEntry = logbookEntryId && this.state?.entriesMap?.[logbookEntryId];
    if (activeEntry) {
      this.setState({ isDrawerOpen: true, activeEntry });
    } else {
      this.closeDrawer();
    }

  };

  closeDrawer = () => {
    this.setState({ isDrawerOpen: false });
    this.setState({ logbookEntryId: null });
    this.setState({
      showUserInteractionDialog: false,
      showUserInteractionDialogButtonLabels: {
        confirm: this.props.t('button.yes'),
        cancel: this.props.t('button.no'),
      },
      userInteractionAcceptCallback: () => {
      },
      userInteractionDialogTitle: null,
      userInteractionDialogText: null,
    });
    this.setState({ isEntryModified: false });
  };

  handleCloseFormDrawer = (ignoreEntryModified = false) => {
    if (this.state.isEntryModified || !ignoreEntryModified) {
      this.setState({
        showUserInteractionDialog: true,
        userInteractionAcceptCallback: () => this.closeDrawer(true),
        userInteractionDialogTitle: this.props.t('logbook.labels.unsavedChangesTitle'),
        showUserInteractionDialogButtonLabels: {
          confirm: this.props.t('button.yes'),
          cancel: this.props.t('button.no'),
        },
        userInteractionDialogText: this.props.t('logbook.labels.unsavedChangesBody'),
      });
      return;
    }
    this.closeDrawer(false);
  };

  updateUserSettings(newLogbookSettings) {
    let logbookSettings = this.getUserSettings();
    window.localStorage.setItem('logbookSettings', JSON.stringify({ ...logbookSettings, ...newLogbookSettings }));
  }

  getUserSettings() {
    let logbookSettings = {};
    const logbookJson = window.localStorage.getItem('logbookSettings');
    if (logbookJson) {
      logbookSettings = JSON.parse(logbookJson);
    }
    return logbookSettings;
  }

  handleTableSort(type, { sortField, sortOrder }) {
    const adjustedSortField = sortField.replace('.', '_').replace('header_', '');
    const orderby = [`${adjustedSortField} ${sortOrder}`];
    this.setState({ orderby });
    this.updateUserSettings({ orderby });
    this.handleGetEntries({ orderby });
  }

  // filter = this.props.activeUserLocoId
  //   ? `vehicleIdRef eq ${this.props.activeUserLocoId} and status eq 'finalized'`
  //   : 'vehicleIdRef ne null and status eq \'finalized\'',
  async handleGetEntries({
    top = this.state.topNumberOfEntries,
    skip = this.state.skip,
    filter = this.props.activeUserLocoId
      ? `vehicleIdRef eq ${this.props.activeUserLocoId}`
      : 'vehicleIdRef ne null',
    count = true,
    orderby = this.state.orderby,
  }, newActiveId) {
    this.showSpinner();
    this.setState({ isLoading: true });
    const { items, pagination, response } = await getLogbookEntries(
      this.props.activeOrganisationId,
      top,
      skip,
      filter,
      count,
      orderby,
    ).catch((err) => {
      console.dir(err);
      if (err?.response?.status > 204) {
        this.showSnackBar(`${this.props.t('labels.errors')} (${err.response.status})`, 'error');
      }
      return {};
    });

    if (items && Array.isArray(items)) {
      const entriesMap = {};
      if (this.props?.activeUserLocoId && this.props?.activeOrganisationId) {
        getVehicleMetadataInOrganisation(this.props.activeOrganisationId, undefined, undefined, `vehicleId eq ${this.props.activeUserLocoId}`).then(({ items }) => {
          this.handleLastInspectionPerformedTimestamp_A1Changed(items[0]?.lastInspectionPerformedTimestamp_A1 || null);
        });
      }

      (items || []).forEach(entry => (entriesMap[entry.header.logbookEntryId] = entry));
      this.setState({ entriesMap, pagination });

      this.setState({
        tableData: items.map(
          ({ header, defects, stoppage, lastComment, vehicleState }) => ({
            ...header,
            header,
            defects,
            stoppage,
            lastComment,
            vehicleState,
            language: this.props.languageFilesReloaded,
          }),
        ),
      });

      if (newActiveId) {
        this.updateActiveEntryId(newActiveId);
      }

      if (this.state.isOnAllPagesSelectALlEntries) {
        const selectedTableItem = { ...this.state.selectedTableItem };
        Object.keys(this.state.entriesMap).forEach(
          itemKey => {
            if (selectedTableItem[itemKey] === undefined) {
              selectedTableItem[itemKey] = true;
            }
          });
        this.setState({ selectedTableItem });
      }
    }

    this.hideSpinner();
  }

  showSpinner = (timeOutToShow = 500) => {
    const spinnerTimeoutID = setTimeout(
      () => this.setState({ isLoading: true }),
      timeOutToShow,
    );
    this.setState({ spinnerTimeoutID });
    return spinnerTimeoutID;
  };

  hideSpinner = (
    spinnerTimeoutID = this.state.spinnerTimeoutID,
    timeOutToShow = 500,
  ) => {
    spinnerTimeoutID && clearTimeout(this.state.spinnerTimeoutID);
    setTimeout(() => this.setState({ isLoading: false }), timeOutToShow);
  };

  async handleDownloadEntries({
    // top = this.state.topNumberOfEntries,
    // skip = 0,
    filter = this.props.activeUserLocoId
      ? `vehicleIdRef eq ${this.props.activeUserLocoId}`
      : 'vehicleIdRef ne null',
    count = true,
    orderby = this.state.orderby,
    options,
  }) {

    this.showSpinner();
    getLogbookEntriesExport(
      this.props.activeOrganisationId,
      filter,
      count,
      orderby,
      options,
    ).then(({ resourceId }) => {
      if (resourceId) {
        this.showSnackBar(this.props.t('labels.downloading'), 'success');
      }
    }).catch((err) => {
      this.showSnackBar(`${this.props.t('labels.error')} (${err?.response?.status})`, 'error');
    }).finally(() => {
      this.hideSpinner();

    });

  }

  showSnackBar = (message, variant, duration = 3000) => {
    this.props.enqueueSnackbar(message, {
      variant: variant,
      autoHideDuration: duration,
    });
  };

  handleCloseExportModal = () => {
    this.setState({ isExportModalOpen: false });
  };

  handleLastInspectionPerformedTimestamp_A1Changed = (a1Date) => {
    this.setState({ lastInspectionPerformedTimestamp_A1: a1Date });
  };

  handleIsEntryModified = (val = true) => {
    this.setState({ isEntryModified: val });
  };
  handleIsEntryModifiedAndUserTriesToExit = (val = false) => {
    this.setState({ isEntryModifiedAndUserTriesToExit: val });
  };

  handleExportFormatSelected = selectedExportType => {
    const selectExportType = this.state.exportOptions.find(
      ({ id }) => id === selectedExportType,
    );
    this.setState({ selectedExportType });
    let listOfIds = [];

    if (this.state.isOnAllPagesSelectALlEntries) {
      listOfIds = Object.keys(this.state.selectedTableItem).filter(
        key => !this.state.selectedTableItem[key],
      );
    } else {
      listOfIds = Object.keys(this.state.selectedTableItem).filter(
        key => this.state.selectedTableItem[key],
      );
    }

    // const filter =  `vehicleIdRef eq ('${includingList.join("'vehicleIdRef eq '")}')`
    let filter = this.props.activeUserLocoId
      ? `vehicleIdRef eq ${this.props.activeUserLocoId}`
      : 'vehicleIdRef ne null';

    if (listOfIds.length) {
      if (this.state.isOnAllPagesSelectALlEntries) {
        filter += ` and (logbookEntryId ne '${listOfIds.join(
          '\' and logbookEntryId ne \'',
        )}')`;

      } else {
        filter += ` and (logbookEntryId eq '${listOfIds.join(
          '\' or logbookEntryId eq \'',
        )}')`;
      }
    }

    const { accept } = selectExportType;

    this.handleDownloadEntries({ filter, options: { headers: { accept } } });
    this.setState({ isExportModalOpen: false });
  };

  handleSelectAllEntries = () => {
    const selectedTableItem = {};
    Object.keys(this.state.entriesMap).forEach(
      itemKey => {
        selectedTableItem[itemKey] = true;
      });

    this.setState({
      totalNumberOfSelectEntries: this.state.pagination.foundCount,
      isSelectALlEntries: true,
      isOnAllPagesSelectALlEntries: true,
      selectedTableItem,
    });
  };

  handleDeselectAllEntries = () => {
    this.setState({
      totalNumberOfSelectEntries: 0,
      isSelectALlEntries: false,
      isOnAllPagesSelectALlEntries: false,
      selectedTableItem: {},
    });
  };

  handleCreatenewEntry = async (user, vehicleId) => {
    const newEntry = {
      isDrawerOpen: true,
      activeEntry: createNewEntry({
        header: {
          creatorAccountLastName: user?.firstName,
          creatorAccountFirstName: user?.lastName,
          commentsCount: 0,
        },
      }),
    };

    this.setState({ defectLoading: true });
    const { items, pagination, response } = await getLogbookEntries(
      this.props.activeOrganisationId,
      1,
      0,
      `vehicleIdRef eq ${vehicleId}`,
      true,
      ['createTimestamp desc'],
    ).catch((err) => {

    });
    this.setState({ defectLoading: false });

    if (!!items?.length) {
      if (items[0]?.defects?.state === "open" && items[0]?.header?.entryState === 'open') {
        newEntry.activeEntry.defects = items[0]?.defects;
        setTimeout(() => {
          this.setState({ defectNotice: { open: true, copy: '' } })
        }, 300);
      }
    }

    this.setState(newEntry);
  }


  render() {
    const {
      isLoading,
      showUserInteractionDialog,
      userInteractionDialogTitle,
      userInteractionDialogText,
      showUserInteractionDialogButtonLabels,
      userInteractionAcceptCallback,
      activeUserLocoId,
      isDrawerOpen,
      entriesMap,
      logbookEntryId,
      comments,
      commentsSignatures,
      pagination,
      topNumberOfEntries,
      activeEntry,
      selectedTableItem,
      tableData,
      isExportModalOpen,
      exportOptions,
      selectedExportType,
      liveDataStaticCol,
    } = this.state;

    const totalNumberSelected = Object.values(selectedTableItem).filter((item) => !!item).length;
    const { t, classes, locale, user, userLocomotiveListObject, isOnline, activeOrganisationId } = this.props;
    const { vehicleIdRef } =
      logbookEntryId && entriesMap[logbookEntryId]
        ? entriesMap[logbookEntryId].header
        : {};
    const { image, name } = userLocomotiveListObject[activeUserLocoId || vehicleIdRef]?.vehicle || {};
    const headerHeight = 57;

    return (
      // <article className="fvm-page fvm-page-logbook">
      <article className={classes.articleClass}>
        <Toolbar className="fvm-map-page-toolbar">
          <div />
          <List>
            {
              user?.accessRights?.logbook_entries?.canCreate ? (
                <>
                  {this.state.defectLoading ?
                    <LoadingSpinner />
                    :
                    <ListItem>
                      <FVMTrackedButton
                        trackingInfo={{
                          category: 'Logbook',
                          label: 'New Logbook Entry',
                          action: 'Open',
                        }}
                        disabled={!activeUserLocoId}
                        icon={<AddIcon />}
                        btnText={t('button.addNewLogbookEntry')}
                        variant="outlined"
                        color="primary"
                        size="medium"
                        onClick={() => this.handleCreatenewEntry(user, userLocomotiveListObject[activeUserLocoId]?.vehicleId)}
                      />
                    </ListItem>
                  }
                </>
              ) : null
            }
            <ListItem disableGutters>
              <FVMTrackedButton
                trackingInfo={{
                  category: 'Logbook Export',
                  label: 'Logbook Export Drawer Opened',
                  action: 'Open',
                }}
                icon={<GetApp />}
                btnText={t('button.export')} //Must adjust
                disabled={!this.state.totalNumberOfSelectEntries}
                variant="outlined"
                color="primary"
                size="medium"
                onClick={() => {
                  this.setState({ isExportModalOpen: true });

                  /** DEBUGGING **/
                  let listOfIds = {};
                  if (this.state.isOnAllPagesSelectALlEntries) {
                    listOfIds = Object.keys(this.state.selectedTableItem).filter(
                      key => !this.state.selectedTableItem[key],
                    );
                  } else {
                    listOfIds = Object.keys(this.state.selectedTableItem).filter(
                      key => this.state.selectedTableItem[key],
                    );
                  }

                  let filter = this.props.activeUserLocoId
                    ? `vehicleIdRef eq ${this.props.activeUserLocoId}`
                    : 'vehicleIdRef ne null';

                  if (listOfIds.length) {
                    if (this.state.isOnAllPagesSelectALlEntries) {
                      filter += ` and (logbookEntryId ne '${listOfIds.join(
                        '\' and logbookEntryId ne \'',
                      )}')`;

                    } else {
                      filter += ` and (logbookEntryId eq '${listOfIds.join(
                        '\' or logbookEntryId eq \'',
                      )}')`;
                    }
                  }
                  // if (this.state.isOnAllPagesSelectALlEntries) {
                  //   console.log('excluding', listOfIds);
                  //   console.log('excluding', filter);
                  // } else {
                  //   console.log('including', listOfIds);
                  //   console.log('including', filter);
                  // }
                }}
              /** DEBUGGING **/
              />
            </ListItem>
          </List>
        </Toolbar>

        <div className={classes.logbookHeaderContainer}>
          <ViewHeader
            title={t('navigationMenu.logbook')}
            name={userLocomotiveListObject[activeUserLocoId]?.vehicle?.name || t('labels.noDeviceSelected')}
            leftAlignChild={
              <div className={classes.exportAllPagesOptions}>
                <div>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={this.handleDeselectAllEntries}
                    disabled={!totalNumberSelected}
                  >
                    {t('logbook.buttons.deselectAll')}
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    disabled={this.state.isSelectALlEntries || !tableData.length}
                    onClick={this.handleSelectAllEntries}
                  >
                    {t('logbook.buttons.selectAll')}
                  </Button>

                  {
                    !!totalNumberSelected && (
                      totalNumberSelected === pagination?.foundCount
                        ? <p>{t('logbook.labels.allEntriesSelected', { selected: pagination?.foundCount })}</p>
                        : <p>{t('logbook.labels.totalEntriesSelectedFrom', {
                          selected: totalNumberSelected,
                          selectedFrom: pagination?.foundCount,
                        })}</p>
                    )
                  }
                </div>
              </div>
            }
            rightAlignChild={
              <Box hidden={!this.props.hasOpenLogbookEntries} className={classes.openEntriesLink} style={{}}>
                <Link color="#FF0000" to="/logbook-draft-entries">{t('labels.viewOpenEntries')}</Link>
              </Box>
            }
          />
        </div>

        <LogBookTable
          data={tableData}
          columns={liveDataStaticCol}
          className={classes.fvmTableData}
          baseProps={{
            remote: { sort: true },
            noDataIndication: () => t('labels.noEntriesFound'),
            headerWrapperClasses: classes.headerWrapperClass,
            onTableChange: this.handleTableSort,
            selectRow: {
              mode: 'checkbox',
              selected: Object.keys(this.state.selectedTableItem).filter(key => this.state.selectedTableItem[key]),
              onSelect: (row, isSelect) => {
                const selectedTableItem = { ...this.state.selectedTableItem };
                selectedTableItem[row.logbookEntryId] = isSelect;
                this.setState({
                  selectedTableItem,
                  isSelectALlEntries: (Object.keys(selectedTableItem).length === pagination.foundCount),
                  totalNumberOfSelectEntries: this.state.totalNumberOfSelectEntries + (isSelect ? 1 : -1),
                });
              },

              onSelectAll: (isSelect, rows) => {
                const selectedTableItem = { ...this.state.selectedTableItem };
                rows.forEach(({ logbookEntryId }) => {
                  selectedTableItem[logbookEntryId] = isSelect;
                });

                let totalSelectedInCurrentList = 0;
                Object.keys(this.state.entriesMap).forEach(entryId => {
                  if (this.state.selectedTableItem[entryId]) {
                    totalSelectedInCurrentList += 1;
                  }
                });

                const differenceInNumberOfSelectEntries = this.state.totalNumberOfSelectEntries - totalSelectedInCurrentList;
                const totalNumberOfSelectEntries = differenceInNumberOfSelectEntries + (isSelect ? Object.keys(this.state.entriesMap).length : 0);

                this.setState({
                  selectedTableItem: totalNumberOfSelectEntries ? selectedTableItem : {},
                  totalNumberOfSelectEntries,
                  isOnAllPagesSelectALlEntries: totalNumberOfSelectEntries ? this.state.isOnAllPagesSelectALlEntries : false,
                  isSelectALlEntries: totalNumberOfSelectEntries ? (Object.keys(selectedTableItem).length === pagination.foundCount) : false,
                });
              },
            },
            bordered: false,
          }}
          keyField="logbookEntryId"
        />

        {tableData.length ? (
          <div className={classes.paginationContainer}>
            <ReactPaginate
              pageCount={pagination.foundCount / topNumberOfEntries}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={({ selected }) => {
                if (selected >= 0) {
                  this.handleGetEntries({
                    skip: selected * topNumberOfEntries,
                  });
                  this.setState({ skip: selected * topNumberOfEntries });
                }
              }}
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              containerClassName={'fvm-pagination-lst'}
              subContainerClassName={'pages pagination'}
              activeClassName={'fvm-pagination-active'}
            />
          </div>
        ) : null}
        <Box hidden className={classNames(classes.lastSyncedTs)}>
          {t('labels.lastSyncedTimestamp')}: {this.props?.lastLogbookSyncedTimeStamp ? new Date(this.props?.lastLogbookSyncedTimeStamp).toLocaleTimeString(locale, {
            year: 'numeric',
            // month: "short",
            month: 'long',
            day: 'numeric',
          }) : ''}</Box>
        <FVMDrawer
          isOpen={isDrawerOpen && activeEntry}
          drawerWidth={`${100}%`}
          height={`calc(100vh - ${headerHeight}px)`}
          maxWidth={868}
          drawerPadding={0}
          drawerHeaderPadding="10px 24px"
          onClose={this.handleCloseFormDrawer}
          title={<p>
            {t('drawer.title.logbook')} {
              entriesMap[logbookEntryId]?.header?.status === 'finalized' || entriesMap[logbookEntryId]?.signatureImage
                ? <LogbookEntryState state="finalized" size="small" label={t('logbook.labels.finalized')} />
                : <LogbookEntryState state="draft" size="small" label={t('logbook.labels.started')} />
            }
          </p>}
          headerVariant="h6"
        >
          <LogbookEntryForm
            locale={locale}
            isOnline={isOnline}
            locoImgUrl={image}
            t={t}
            locoName={name || activeEntry?.header.vehicleIdRef || 'Missing Vehicle Ref'}
            lastInspectionPerformedTimestamp_A1={this.state.lastInspectionPerformedTimestamp_A1}
            closeDrawer={this.handleCloseFormDrawer}
            comments={comments}
            organisationId={activeOrganisationId}
            setIsFormModified={this.handleIsEntryModified}
            updateActiveEntryId={this.updateActiveEntryId}
            entry={activeEntry}
            responseIndicator={this.showSnackBar}
            updateLogbookEntriesLst={(newActiveId) => this.handleGetEntries({}, newActiveId)}
            activeLocoId={activeUserLocoId}
          />
        </FVMDrawer>
        {isExportModalOpen ? (
          <DialogTwoButtons
            classes={{
              paper: classes.exportModal,
            }}
            id="ringtone-menu"
            title={t('labels.exportOptions')}
            t={t}
            keepMounted
            optionsLst={exportOptions}
            lstVariant="row"
            value={selectedExportType}
            open={isExportModalOpen}
            buttonLabels={{ confirm: t('button.export') }}
            onClose={this.handleCloseExportModal}
            onSelectionMade={this.handleExportFormatSelected}
          />
        ) : null}

        {showUserInteractionDialog ? (
          <DialogTwoButtons
            classes={{
              paper: classes.exportModal,
            }}
            id="entry-for-decision"
            title={userInteractionDialogTitle}
            dialogText={userInteractionDialogText}
            t={t}
            keepMounted
            value={true}
            open={showUserInteractionDialog}
            buttonLabels={showUserInteractionDialogButtonLabels}
            onClose={() => {
              this.setState({ showUserInteractionDialog: false });
            }}
            onSelectionMade={userInteractionAcceptCallback}
          />
        ) : null}
        {isLoading && this.props.isOnline ? <LoadingSpinner /> : null}

        <Modal
          open={this.state.defectNotice.open}
          onClose={() => { }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.defectDisplay}>
            <Box sx={{ whiteSpace: "pre-wrap" }}>
              <ViewTitle title={t('logbook.labels.defects.modal.title')} />
              <br />
              <Trans
                i18nKey="defectmessages"
                defaults={t('logbook.labels.defects.modal.message')}
                values={{}}
                components={{ italic: <i />, bold: <strong /> }}
              />
              {/* {JSON.stringify(this.state)} */}
            </Box>
            <Box display="flex" justifyContent="flex-end" sx={{ mt: 4 }}>
              <Button variant="outlined" onClick={() => this.setState({ defectNotice: { open: false, copy: '' } })} color="primary">{t('button.close')} </Button>
            </Box>
          </Box>
        </Modal>
      </article >
    );
  }
}

Logbook.propTypes = {
  newView: PropTypes.func.isRequired,
  showDialog: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  activeUserLocoId: PropTypes.number.isRequired,
  appConsts: PropTypes.object.isRequired,
  isDemoUser: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  hasOpenLogbookEntries: state.hasOpenLogbookEntries,
  selectTro: state.targetedUserLocomotiveId || 0,
  activeUserLocoId: [...state.activeUserLocomotiveListIds].pop() || 0,
  isDemoUser: state.isDemoUser || false,
  locListUpdated: state.locListUpdated,
  userLocomotiveListObject: state.userLocomotiveList,
  activeOrganisationId: state.user.user.activeOrganisationId,
  isOnline: state.isOnline,
  user: state.user.user,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      newView: changedView,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Logbook));
