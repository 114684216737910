import React from 'react';
import CraneStatus from '../../assets/svgs/crane-status';
import TrainStatus from '../../assets/svgs/TrainStatus';
import FallbackStatus from '../../assets/svgs/loc-standing';

const machineStatus = {
  loco:{
    off: { // grey
      icon: <TrainStatus styleClass="fvm-off" />,
      tooltip: 'tooltip.machineStatusOff',
      tooltipUser: 'tooltip.offLocomotives',
      value: 1,
    },
    fallback: { // red
      icon: <FallbackStatus styleClass="fvm-fallback" />,
      tooltip: 'tooltip.statusNotDefined',
      tooltipUser: 'tooltip.locoError',
      value: 6,
    },
    standby: { // blue
      icon: <TrainStatus styleClass="fvm-equipped" />,
      tooltip: 'tooltip.machineStatusSystemsOn',
      tooltipUser: 'tooltip.engineOnLocomotives',
      value: 2,
    },
    idle: { // orange
      icon: <TrainStatus styleClass="fvm-standing" />,
      tooltip: 'tooltip.machineStatusMotorOn',
      tooltipUser: 'tooltip.engineRunningLocomotives',
      value: 3,
    },
    driving: { // green
      icon: <TrainStatus styleClass="fvm-driving" />,
      tooltip: 'tooltip.machineStatusDriving',
      tooltipUser: 'tooltip.drivingLocomotives',
      value: 4,
    },
    total: { //dark grey
      icon: <TrainStatus styleClass="fvm-total" />,
      tooltip: 'Total',
      tooltipUser: 'tooltip.totalLocomotives',
      value: 5,
    },
  },
  crane:{
    off: { // yellow cranes
      icon: <CraneStatus styleClass="fvm-crane" />,
      tooltip: 'tooltip.crane',
      tooltipUser: 'tooltip.totalMaintenanceVehicle',
      value: 5,
    },
    fallback: { // red
      icon: <FallbackStatus styleClass="fvm-fallback" />,
      tooltip: 'tooltip.statusNotDefined',
      tooltipUser: 'tooltip.locoError',
      value: 6,
    },
  }
};

const getStatusIcon = ({ status, type = 'loco' }, t, userIcons = false) => {
  const vehicleType = (type && machineStatus[type]) || machineStatus['loco'];
  const statusFound = (status && vehicleType[status]) || vehicleType['fallback'];
  const tooltip = t( userIcons ?  statusFound.tooltipUser :  statusFound.tooltip);

  return {
    ...statusFound,
    tooltip,
  };
}


export default getStatusIcon;
