import React from 'react';
const StatusOff = () => {
  return (
    <svg width="60px" height="16px" viewBox="0 0 60 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Component/Toggle/Status-Alternativ/1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Background">
          <rect fill="#CCCFDA" x="0" y="0" width="60" height="16" rx="8"></rect>
          <rect fill="#8C9BA5" x="0" y="0" width="16" height="16" rx="8"></rect>
        </g>
      </g>
    </svg>
  );
};
export default StatusOff;
