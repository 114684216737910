const IS_DEV = process.env.NODE_ENV === 'development';
const IS_TEST = process.env.NODE_ENV === 'test';

/**
 * @function
 * @description is used to generate html standard id names
 * @param {string} name
 * @param {string} joinDelimiter - The item to symbol to be used when joining the the string back together
 * @return {string}
 */
export const getComponentHtmlName = (name = '', joinDelimiter = '-') => {
  return name.replace(/[A-Z]/g, v => ` ${v.toLowerCase()}`)
             .trim()
             .split(/\s+/)
             .join(joinDelimiter);
};

/**
 * @function
 * @description used to generate the name that matches the the component in the dome based on the component displayName
 * @param {string} componentDisplayName
 * @param {string} name
 * @return {string}
 */
export const generateAttributeTargetName = (componentDisplayName, name = '') => {
  return `${getComponentHtmlName(componentDisplayName || '')}-${getComponentHtmlName(name)}`;
};

/**
 * @function
 * @description generates the test attribute used for automated testing testing
 * @param {string} componentDisplayName
 * @param {string} name
 * @return {{' data-test': string}|{}}
 */
export const generateTestAttribute = (componentDisplayName, name = '') => {
  return (IS_TEST || IS_DEV) ? { 'data-test': generateAttributeTargetName(componentDisplayName, name) } : { };
};