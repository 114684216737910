import { withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ProfileIcon from '@material-ui/icons/PersonOutlineSharp';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import classNames from 'classnames';
import { SimpleLanguageSwitcher, UserDropdownMenu } from '../UserDropdownMenu';
import AppsOutlined from '@material-ui/icons/AppsOutlined';

import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  changedView,
  clickedSelectAllCranes,
  clickedSelectAllDevices,
  clickedSelectAllDriving,
  clickedSelectAllEquipped,
  clickedSelectAllLoco,
  clickedSelectAllOff,
  clickedSelectAllStanding,
  setUserMadeInitialDeviceSelection,
  updateActiveLocomotiveIds,
  userLanguageChanged,
} from '../../actions';

import FVMNrailLogo from '../../assets/svgs/nrail-logo';
import FvmGAItemTracker from '../FvmGAItemTracker';
import BootstrapTooltip from '../FvmMaterilUiTooltip';
import getStatusIcon from '../LocomotiveStatusIcons';
import { VEHICLE_STATUS_NAMES } from '../../globalConstants/vechicelConsts';
import IconButton from '@material-ui/core/IconButton';
import { AppBarUserMenu } from '../UserApps';

const getTime = () => moment().format('HH:mm');
const getDate = () => moment().format('DD.MM.YYYY');
const styles = theme => ({
  logoContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  logoStyles: {
    alignItems: 'center',
    display: 'flex',
    '& svg': {
      height: 40,
      width: 60,
    }
  },
  header: {
    fontSize: 16 * theme.fvm.zoomFactor,
  },
  root: {
    fontSize: theme.typography.fontSize,
  },
  craneSeparater: {
    // borderLeft: 'solid rgb(128, 128, 128) 2px',
    width: 2,
    height: 40,
  },
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: getTime(),
      date: getDate(),
      userLang: props.languageFilesReloaded,
    };
    this.toggleTrains = this.toggleTrains.bind(this);
    this.changeTime = this.changeTime.bind(this);
  }

  componentDidMount() {
    this.changeTime();
    setTimeout(() => {
      this.changeTime();
      setInterval(this.changeTime, 60000);
    }, ((59 - new Date().getSeconds()) * 1080));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.languageFilesReloaded !== prevProps.languageFilesReloaded) {
      this.setState({ userLang: localStorage.getItem('i18nextLng') });
    }
  }

  changeTime() {
    this.setState({
      time: getTime(),
      date: getDate(),
    });
  }

  toggleTrains(status) {
    const {
      selectAllLoco,
      isSingleSelect,
      selectAllDriving,
      selectAllStanding,
      selectAllEquipped,
      selectAllOff,
      toggleAllCranes,
      selectAllDevices,
    } = this.props;

    const isAllSeclected = selectAllDevices || (selectAllDriving && selectAllStanding && selectAllEquipped && selectAllOff);

    if (status === 'selectAllLoco') {
      if (!isSingleSelect) {
        // this.props.clickedSelectAllLoco(isAllSeclected ? false : !selectAllLoco, true);
        this.props.clickedSelectAllLoco(!selectAllLoco, true);
        this.props.clickedSelectAllDevices(false);

        this.props.clickedSelectAllCranes(false);
      }

      return;
    }

    this.props.setUserMadeInitialDeviceSelection(true);
    if (!isSingleSelect) {
      this.props.clickedSelectAllLoco(false);
      this.props.clickedSelectAllDevices(false);
      this.props.clickedSelectAllDriving(status !== VEHICLE_STATUS_NAMES.DRIVING ? false : isAllSeclected ? true : !selectAllDriving);
      this.props.clickedSelectAllStanding(status !== VEHICLE_STATUS_NAMES.STANDING ? false : isAllSeclected ? true : !selectAllStanding);
      this.props.clickedSelectAllEquipped(status !== VEHICLE_STATUS_NAMES.EQUIPPED ? false : isAllSeclected ? true : !selectAllEquipped);
      this.props.clickedSelectAllOff(status !== VEHICLE_STATUS_NAMES.OFF ? false : isAllSeclected ? true : !selectAllOff);

      // this.props.clickedSelectAllOff(status !== 'cranes' ? false : isAllSeclected ? true : !toggleAllCranes);

      if (status === 'cranes' || selectAllDevices) {
        this.props.clickedSelectAllCranes(selectAllDevices && toggleAllCranes ? true : !toggleAllCranes);
      } else {
        this.props.clickedSelectAllCranes(false);

      }
    }
  }

  render() {
    const {
      username,
      userAvatarUrl,
      currentView,
      allLocomotivies,
      drivingTrainList,
      standingTrainList,
      equippedTrainList,
      offTrainList,
      cranesList,
      isRailation,
      classes,
      t,
      i18n,
      userLocaleChanged,
    } = this.props;

    const { time, date, userLang } = this.state;

    const menu = [
      {
        name: 'navigationMenu.profile',
        path: 'profile',
        icon: ProfileIcon,
        onClick: () => {
          this.props.changedView();
        },
      },
      // ...(this.props.isDevelopment ?
      //   [{
      //     name: 'navigationMenu.notificationSettings',
      //     path: 'notificationsettings',
      //     icon: SettingsIcon,
      //     onClick: () => {
      //       this.props.changedView();
      //     },
      //   }] : []),
      // {
      //   name: 'navigationMenu.faq',
      //   path: 'faq',
      //   icon: QuestionAnswerIcon,
      //   onClick: () => {
      //     this.props.changedView();
      //   },
      // }
    ];

    let locoSelectObjs = [
      {
        name: 'selectAllLoco',
        length: allLocomotivies.length,
        active: this.props.selectAllLoco,
        ...getStatusIcon({ status: 'total' }, this.props.t, true),
      },
      {
        name: VEHICLE_STATUS_NAMES.DRIVING,
        length: drivingTrainList.length,
        active: this.props.selectAllDriving,
        ...getStatusIcon({ status: VEHICLE_STATUS_NAMES.DRIVING }, this.props.t, true),
      },
      {
        name: VEHICLE_STATUS_NAMES.STANDING,
        length: standingTrainList.length,
        active: this.props.selectAllStanding,
        ...getStatusIcon({ status: VEHICLE_STATUS_NAMES.STANDING }, this.props.t, true),
      },
      {
        name: VEHICLE_STATUS_NAMES.EQUIPPED,
        length: equippedTrainList.length,
        active: this.props.selectAllEquipped,
        ...getStatusIcon({ status: VEHICLE_STATUS_NAMES.EQUIPPED }, this.props.t, true),
      },
      {
        name: VEHICLE_STATUS_NAMES.OFF,
        length: offTrainList.length,
        active: this.props.selectAllOff,
        ...getStatusIcon({ status: VEHICLE_STATUS_NAMES.OFF }, this.props.t, true),
      },
      {
        name: 'divider',
      },
      {
        name: 'cranes',
        length: cranesList.length,
        active: this.props.toggleAllCranes,
        ...getStatusIcon({ status: VEHICLE_STATUS_NAMES.OFF, type: 'crane' }, this.props.t, true),
      },

    ];

    if (!this.props.isHaveCranes) {
      locoSelectObjs = locoSelectObjs.filter(menuEntry => menuEntry.name !== 'cranes');
    }

    return (
      <header className={classNames(classes.root, 'fvm-header')}>
        <AppBarUserMenu t={t} />

        <div className={classes.logoContainer}>
          <FvmGAItemTracker
            key={`GA-dashboard-home`}
            category={'User Controls'}
            label={`Nrail main logo(top left page) `}
            action={`Navigate Home`}
          >
            <Link className={classes.logoStyles} to="/dashboard"style={{ fontSize: '17px', textDecoration: 'none', color: 'black' }}>
              <FVMNrailLogo className="smallLogo" />NRail Logbook
            </Link>
          </FvmGAItemTracker>
        </div>
        {
          !isRailation
            ? (
              <div className="fvm-my-locomotives">
                {
                  this.props?.user?.accessRights?.['gateway/telematics']?.canRead &&
                  locoSelectObjs.map((locoSelObjItem, index) => {
                    const { name, tooltip, active, length, icon } = locoSelObjItem;
                    if (name === 'divider' && index !== locoSelectObjs.length - 1) {
                      return (
                        <Divider
                          key={`fvm-${name}-${index}`}
                          className={classNames(classes.craneSeparater)}
                          orientation="vertical"
                        />);
                    }

                    return (
                      <FvmGAItemTracker
                        key={`GA-${name}-${index}`}
                        category={'User Controls'}
                        label={`${name} toggler`}
                        action={`Toggled`}
                        value={!active ? 1 : 0}
                        onClick={() => this.toggleTrains(name)}
                      >
                        <BootstrapTooltip
                          title={t(tooltip)}
                          key={`fvm-user-tooltip-${name}`}
                        >

                          <i
                            key={name}
                            className={classNames('fvm-cp-icon')}
                          // onClick={() => this.toggleTrains(name)}
                          >

                            {icon}
                            <span
                              className="fvm-user-train-counter"
                              style={active ? { fontWeight: 'bold' } : {}}
                            >
                              {length}
                            </span>
                          </i>
                        </BootstrapTooltip>
                      </FvmGAItemTracker>

                    );
                  })
                }
              </div>
            )
            : null
        }

        {
          !isRailation
            ? <UserDropdownMenu
              menuItems={menu}
              currentView={currentView}
              t={t}
              i18n={i18n}
              userLocaleChanged={userLocaleChanged}
              classes={classes}
            />
            : <SimpleLanguageSwitcher userLang={userLang} t={t} i18n={i18n} userLocaleChanged={userLocaleChanged} />
        }
      </header>
    );
  }
}

User.propTypes = {
  isDemoUser: PropTypes.bool.isRequired,
  userLocomotiveList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  isDemoUser: state.isDemoUser,
  isListView: state.isListView,
  targetedUserLocomotiveId: state.targetedUserLocomotiveId,
  currentView: state.currentView,
  selectAllLoco: state.selectAllLoco,
  selectAllDriving: state.selectAllDriving,
  selectAllStanding: state.selectAllStanding,
  selectAllEquipped: state.selectAllEquipped,
  selectAllOff: state.selectAllOff,
  selectAllDevices: state.selectAllDevices,
  toggleAllCranes: state.toggleAllCranes,
  isSingleSelect: state.isSingleSelect,
  isHaveCranes: state.isHaveCranes,
  userLocomotiveList: Object.values(state.userLocomotiveList),
  languageFilesReloaded: state.languageFilesReloaded,
  isDevelopment: state.isDevelopment,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    updateActiveLocomotiveIds,
    clickedSelectAllLoco,
    clickedSelectAllDriving,
    clickedSelectAllStanding,
    clickedSelectAllEquipped,
    clickedSelectAllCranes,
    clickedSelectAllDevices,
    clickedSelectAllOff,
    changedView,
    userLocaleChanged: userLanguageChanged,
    setUserMadeInitialDeviceSelection,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(User));
