import React from 'react';
const Infrastructure = () => {

  const a = {
    fill: '#002fc9',
  }
  const b = {
    opacity: 0,
  }
  const c = {
    fill: '#fff',
  }

  return (
    <svg  xmlns="http://www.w3.org/2000/svg" width="33.999" height="33.999" viewBox="0 0 33.999 33.999">
      {/*<style>.a,.b{fill:#002fc9;}.b{opacity:0;}.c{fill:#fff;}</style>*/}
      <g transform="translate(-726 -592)">
        <g transform="translate(635 509.999)">
          <rect style={a} width="33.999" height="33.999" rx="5" transform="translate(91 82.001)" />
        </g>
        <g transform="translate(-300)">
          <rect style={{ ...a, ...b }} width="24" height="24" transform="translate(1031 597)" />
          <path
            style={c}
            d="M58.763,358.408a2.237,2.237,0,0,0-1.869,1.006l-3.821-1.579c0-.048.014-.092.015-.14a3.4,3.4,0,0,0-.8-2.205l1.983-1.883a1.95,1.95,0,1,0-.923-1.652,1.922,1.922,0,0,0,.241.92l-2.036,1.933a3.5,3.5,0,0,0-4.218.24l-2.175-1.785a1.66,1.66,0,0,0,.117-.6,1.695,1.695,0,1,0-.735,1.389l2.118,1.739a3.433,3.433,0,0,0-.579,1.884,3.543,3.543,0,0,0,.273,1.393L44.5,360.372a2.167,2.167,0,1,0,.625.78l1.747-1.235a3.44,3.44,0,0,0,2.66,1.3,3.816,3.816,0,0,0,.675-.061l1.307,3.766a2.717,2.717,0,1,0,1.348-.367,2.782,2.782,0,0,0-.4.04l-1.305-3.758a3.394,3.394,0,0,0,1.728-2l3.667,1.516a2.054,2.054,0,0,0-.029.284,2.238,2.238,0,1,0,2.238-2.23Z"
            transform="translate(992 249)"
          />
        </g>
      </g>
    </svg>
  );
};
export default Infrastructure;
