import Button from '@material-ui/core/Button';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import DoneAll from '@material-ui/icons/DoneAll';

import Switch from '@material-ui/core/Switch';
import axios from 'axios/index';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import NumericInput from 'react-numeric-input';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import FvmGAItemTracker from '../FvmGAItemTracker';
import LoadingSpinner from '../LoadingSpinner';
import DeviceChip from '../DeviceChip';
import FVMDialog from '../FVMDialog';
import SelectionDrawer from '../SelectionDrawer';
import ViewTitle from '../ViewTitle';

import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';

/**
 *
 */
class NotificationSettings extends Component {
  constructor(props) {
    super(props);
    const selected = props.activeUserLocomotiveListIds.length
      ? props.activeUserLocomotiveListIds.map(vehicleId => props.userLocomotiveListObject[vehicleId].wpId)
      : [];
    this.state = {
      isSaved: false,
      isDialogOpen: false,
      selectAll: Object.keys(props.userLocomotiveListObject).length === selected.length,
      selectedOption: {
        value: props.activeUserLocoId,
        label: props.activeUserLocoId ? props.userLocomotiveListObject[props.activeUserLocoId]?.vehicle?.name : '',
      },
      filterTerm: '',
      activeUserLocoId: props.activeUserLocoId,
      applyToMany: false,
      selected: selected,
      form: [...this.buildNotificationArray()],
      name: '',
      isLoading: false,
    };

    this.fetchNewData = this.fetchNewData.bind(this);
    this.onStatusClick = this.onStatusClick.bind(this);
    this.onSpecificationChange = this.onSpecificationChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openListOptions = this.openListOptions.bind(this);
    this.changeActiveUserLocoId = this.changeActiveUserLocoId.bind(this);
    this.toggleSelection = this.toggleSelection.bind(this);

    this.activeFields = {
      NSLeavingDefinedArea: true,
      NSSpeedlimitExeeded: true,
      NSSuspectedCrash: true,
      NSSuspectedDerailment: false,
      NSVoltageDrop: false,
      NSMaxOperatingHoursDay: false,
      NSMaxOperatingHoursMonth: false,
      NSMaxOperatingHoursWeek: false,
      NSMaxMileageDay: false,
      NSOutsideTemperature: false,
      NSMaxMileageMonth: false,
      NSSuspiciousDrivingBehaviour: false,
      NSShuntingImpacts: false,
      NSFlatSpot: false,
      NSSuspectedColdStart: false,
      NSLongDelayBreaks: false,

    };

  }

  componentDidMount() {
    // this.fetchNewData(this.props.activeUserLocoId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeUserLocoId, languageFilesReloaded, userLocomotiveListObject, isSingleSelect } =  this.props;
    if ((prevProps.activeUserLocoId !== activeUserLocoId) && isSingleSelect) {
      const { name, wpId } = userLocomotiveListObject[activeUserLocoId]?.vehicle || {};
      this.setState({ activeUserLocoId });
      const selected = userLocomotiveListObject[activeUserLocoId]
        ? [wpId]
        : [];
      this.setState({ selected });
      if (activeUserLocoId) {
        this.setState({ name });
        // this.fetchNewData(activeUserLocoId);
      }
    }
    if (this.props.languageFilesReloaded !== languageFilesReloaded) {
      this.setState({ componentLanguageUpdateTrigger: this.props.languageFilesReloaded });
    }
  }

  componentWillUnmount() {
  }

  buildNotificationArray() {
    const notifiObj = [
      {
        label: 'NSLeavingDefinedArea',
        status: false,
      },
      {
        label: 'NSSpeedlimitExeeded',
        status: false,
        unit: 'kmPerHr',
        max: {
          value: 0,
          text: '',
        },
      },
      {
        label: 'NSSuspectedCrash',
        status: false,
      },
      {
        label: 'NSSuspectedDerailment',
        status: false,
      },
      {
        label: 'NSOutsideTemperature',
        status: false,
        unit: 'celsius',
        max: {
          value: 0,
          text: '',
        },
        min: {
          value: 0,
          text: '',
        },
      },
      {
        label: 'NSVoltageDrop',
        status: false,
        unit: 'percentage',

        max: {
          value: 0,
          text: '',
        },
      },
      {
        label: 'NSMaxOperatingHoursDay',
        status: false,
        unit: 'hoursDays',
        max: {
          value: 0,
          text: '',
        },
      },
      // {
      //   label: 'NSMaxOperatingHoursWeek',
      //   status: false,
      //   unit: 'hoursWeeks',
      //   max: {
      //     value: 0,
      //     text: '',
      //   },
      // },
      {
        label: 'NSMaxOperatingHoursMonth',
        status: false,
        unit: 'hoursMonths',
        max: {
          value: 0,
          text: '',
        },
      },
      {
        label: 'NSMaxMileageDay',
        status: false,
        unit: 'kmDays',
        max: {
          value: 0,
          text: '',
        },
      },
      // {
      //   label: 'NSMaxMileageWeek',
      //   status: false,
      //   unit: 'kmWeeks',
      //   max: {
      //     value: 0,
      //     text: '',
      //   },
      // },
      {
        label: 'NSMaxMileageMonth',
        status: false,
        unit: 'kmMonths',
        max: {
          value: 0,
          text: '',
        },
      },
      {
        label: 'NSSuspiciousDrivingBehaviour',
        status: false,
      },
      {
        label: 'NSLongDelayBreaks',
        status: false,
      },
      {
        label: 'NSShuntingImpacts',
        status: false,
      },
      {
        label: 'NSFlatSpot',
        status: false,
      },
      {
        label: 'NSSuspectedColdStart',
        status: false,
      }];
    return notifiObj;
  }

  /**
   * to be ued when translation improves
   *
   **/

  KeyChanger(key) {
    const keyMap = {
      'Geobereich verlassen': 'NSLeavingDefinedArea',
      'Geschwindigkeit überschritten': 'NSSpeedlimitExeeded',
      'Verdacht auf Crash': 'NSSuspectedCrash',
      'Verdacht auf Entgleisung': 'NSSuspectedDerailment',
      'Umgebungstemperatur': 'NSOutsideTemperature',
      'Spannungsabfall': 'NSVoltageDrop',
      'Betriebsstunden maximal pro Monat': 'NSMaxOperatingHoursMonth',
      'Laufleistung maximal pro Monat': 'NSMaxMileageMonth',
      'Starke Beschleunigung': 'NSSuspiciousDrivingBehaviour',
      'Starke Verzögerung (Bremsen)': 'NSLongDelayBreaks',
      'Rangierstöße': 'NSShuntingImpacts',
      'Flachstellenverdacht': 'NSFlatSpot',
      'Verdacht auf Kaltstart': 'NSSuspectedColdStart',
    };

    return keyMap[key];
  }

  unitChanger(key) {
    const keyMap = {
      '°C': 'celsius',
      'km/h': 'kmPerHr',
      '%': 'percentage',
      'Stunden/Monat': 'hoursMonths',
      'km/Monat': 'kmMonths',
    };

    return keyMap[key];
  }

  changeActiveUserLocoId(activeUserLocoId) {
    this.setState({ deviceLstOpen: false, activeUserLocoId });
    // this.fetchNewData(activeUserLocoId);
  }

  async fetchNewData(activeUserLocoId) {
    if (activeUserLocoId) {
      this.updateLoadingState(true);

      const { wpId } = this.props.userLocomotiveListObject[activeUserLocoId];
      const dataUrl = `${this.props.appConsts.fvmURL}/wp-json/fvm/v1/notification/${wpId}`;

      const resp = await axios.get(dataUrl, {
        headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
      });

      if (activeUserLocoId === this.state.activeUserLocoId) {
        const DbForm = {};
        if (resp.data && resp.data.length) {
          resp.data.map((row) => { // new implementation. this can be changed
            // to a forEach
            // temp code to fix the way the json is saved with user notification settings
            const newRow = {};
            if (!row.label.match(/^NS/)) {
              newRow.label = this.KeyChanger(row.label);
            }
            if (row.unit && (row.unit.match(/\//) || row.unit.match(/°C/) || row.unit.match(/%/))) {
              newRow.unit = this.unitChanger(row.unit);
            }
            if (Object.keys(newRow).length) {
              DbForm[newRow.label] = Object.assign({}, row, newRow);
              return Object.assign({}, row, newRow);
            }
            DbForm[row.label] = row;
            return row;
          });
        }

        // const { form } = this.state;
        this.setState({
          form: this.buildNotificationArray().map(entry => DbForm[entry.label] || entry),
        });
      }
      this.updateLoadingState(false);
    }
  }

  onSpecificationChange(valueAsNumber, valueAsString, input) {
    if (!input) {
      return;
    }
    const { form } = this.state;
    const inputRefArray = input.dataset.inputref.split('-');
    const index = Number(inputRefArray[1]);
    const property = inputRefArray[0];
    if (valueAsNumber) {
      form[index][property].value = valueAsNumber;
      return;
    }
    form[index][property].value = input.value;
  }

  onStatusClick(e) {
    const { form } = this.state;
    const inputRefArray = e.currentTarget.id.split('-');
    const index = Number(inputRefArray[1]);
    form[index].status = !form[index].status;
    this.setState({ form });
  }

  async saveChanges(selectedList = []) {
    const { form, activeUserLocoId } = this.state;
    const wpIds = this.state.applyToMany ? selectedList : [activeUserLocoId];
    const { t } = this.props;

    if (!wpIds.length) {
      this.setState({
          dialogData: {
            title: 'Auswahl fehlt!',
            details: 'Bitte wählen Sie eine Lokomotive um einen Eintrag zu tätigen.',
            actionButtons: [],
            time: 2000,
            onCloseDialog: () => {
              this.setState({
                isDialogOpen: false,
              });
            },
          },
          isDialogOpen: true,

        },
      );
      return;
    }

    const dataUrl = `${this.props.appConsts.fvmURL}/wp-json/fvm/v1/notification/`;
    const data = {
      form,
      wpIds,
    };

    const resp = await axios.post(dataUrl, data, {
      headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
    });

    if (resp.status === 200) {
      this.setState({
          dialogData: {
            title: '',
            details: <span><DoneAll color="primary" />{t('messages.saved')} </span>,
            actionButtons: [],
            contentPosition: 'center',
            showCloseBtn: false,
            time: 2000,
            onCloseDialog: () => {
              this.setState({
                isDialogOpen: false,
              });
            },
          },
          isDialogOpen: true,

        },
      );

      setTimeout(() => {
        this.setState({ isSaved: false, isDialogOpen: false });
        if (this.state.triggerParentCallbk) {
          this.props.onClose && this.props.onClose();
          this.setState({ triggerParentCallbk: false });
        }

      }, 3000);

      this.setState({ applyToMany: false });

      const selected = this.props.userLocomotiveListObject[activeUserLocoId]
        ? [this.props.userLocomotiveListObject[activeUserLocoId].wpId]
        : [];

      this.setState({ selected });
    }
  }

  toggleSelection(id) {
    const { selected } = this.state;
    if (selected.includes(id)) {
      this.setState({ selected: [...selected].filter(vehicleId => vehicleId !== id) });
      return;
    }
    this.setState({ selected: [...selected, id] });
  }

  closeModal() {
    const applyToMany = false;
    this.setState({ applyToMany });
  }

  updateLoadingState(status, time = 0) {
    setTimeout(() => {
      this.setState({ isLoading: status });
    }, time);
  }

  openListOptions() {
    const { deviceLstOpen } = this.state;
    this.setState({ deviceLstOpen: !deviceLstOpen });
  }

  render() {
    const { isSaved, form, isLoading, deviceLstOpen, activeUserLocoId, dialogData, isDialogOpen, selectedOption, selected } = this.state;
    const { name } = this.props.userLocomotiveListObject[activeUserLocoId]?.vehicle || {};
    const { t, activeUserLocomotiveListIds, hideTitle, userLocomotiveListObject } = this.props;
    const selectedLocos = activeUserLocomotiveListIds.length > 1
      ? activeUserLocomotiveListIds.length
      : null;

    const options = activeUserLocomotiveListIds.map(locoId => ({
      value: locoId,
      label: userLocomotiveListObject[locoId]?.vehicle?.name,
    }));


    const data = Object.keys(this.props.userLocomotiveListObject).map((vehicleId) => {
      const { name, image, wpId } = this.props.userLocomotiveListObject[vehicleId]?.vehicle || {};
      return { name, id: wpId, image };
    });

    const style = {};

    return (
      <div className="fvm-page-notification-settings fvm-general-page-content">
        <header>
          {hideTitle ? null : <ViewTitle title={t('navigationMenu.notificationSettings')} />}
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItem onClick={this.openListOptions}>
              <DeviceChip
                name={name}
                selectedLocos={selectedLocos}
              />
              {selectedLocos ? ((deviceLstOpen && <ExpandLess />) || <ExpandMore />) : null}
            </ListItem>
            {
              selectedLocos ?
                <Collapse in={deviceLstOpen} timeout="auto" unmountOnExit>
                  <Select
                    value={selectedOption}
                    // autoFocus
                    menuIsOpen
                    isClearable
                    color={'primary'}
                    onChange={selectedOption => {
                      this.setState({ selectedOption });
                      if (selectedOption) {
                        this.changeActiveUserLocoId(selectedOption.value);
                        this.openListOptions();
                      }
                    }}
                    options={options}
                    styles={
                      {
                        control: styles => ({ ...styles, backgroundColor: 'white' }),
                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                          return {
                            ...styles,
                            backgroundColor: isDisabled
                              ? null
                              : isSelected
                                ? '#1cdd43'
                                : isFocused
                                  ? 'rgba(28, 221, 67, 0.2)'
                                  : null,
                            ':active': {
                              backgroundColor: '#1cdd43',
                            },
                          };
                        },
                      }
                    }
                  />
                </Collapse> : null
            }
          </List>
        </header>
        <section className="fvm-sp fvm-sp-settings container-fluid">
          <div className="row">
            <form className="col">
              {
                form.filter(({ label }) => this.activeFields[label]).map((field, index) => (
                  <div key={field.label} className="row fvm-settings-group">
                    <div className="col-4 fvm-cp-label">
                      {t(`tables.tRow.notificationSetting.${field.label}`)}
                    </div>
                    <div className="col-2 fvm-cp-switch">
                      <Switch
                        id={`status-${index}`}
                        checked={field.status}
                        onChange={this.onStatusClick}
                        inputref={`status-${index}`}
                        value="status"
                        color="primary"
                      />
                    </div>
                    {
                      (field.max || field.min) && (
                        <div className={`col-4 fvm-cp-specs ${!field.status ? 'fvm-cp-specs-disabled' : ''}`}>
                          {field.min && (
                            <div className="fvm-cp-specs-inner">
                              <span className="fvm-label-prefix">Min</span>
                              {/*<NumericInput min={0} max={100} value={50}/>*/}
                              <NumericInput
                                onChange={this.onSpecificationChange}
                                onValid={this.onSpecificationChange}
                                data-inputref={`min-${index}`}
                                value={field.min.value}
                                className="fvm-input mb-2"
                                disabled={!field.status}
                                min={field.unit === '°C' ? -1000 : 0}
                                max={10000}
                                style={style}
                                // snap={true}
                                // strict
                              />
                              <span
                                className="fvm-label-suffix"
                              >
                                {t(`singleWords.${field.unit}`)}
                              </span>
                            </div>
                          )
                          }
                          {
                            field.max && (
                              <div className="fvm-cp-specs-inner">
                                <span className="fvm-label-prefix">Max</span>
                                <NumericInput
                                  onValid={this.onSpecificationChange}
                                  onChange={this.onSpecificationChange}
                                  data-inputref={`max-${index}`}
                                  value={field.max.value}
                                  className="fvm-input"
                                  style={style}
                                  disabled={!field.status}
                                  min={field.unit === '°C' ? -273 : 0}
                                  max={10000}
                                  // snap={true}
                                  // strict
                                />
                                <span
                                  className="fvm-label-suffix"
                                >
                                  {t(`singleWords.${field.unit}`)}
                                </span>
                              </div>
                            )
                          }
                        </div>
                      )
                    }
                  </div>
                ))
              }
            </form>

          </div>
        </section>
        <div className="fvm-btn-group text-right">
          <FvmGAItemTracker
            category={'Notification Settings'}
            label={'Save'}
            action={'Save'}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={this.props.isDemoUser || !activeUserLocoId}
              className="fvm-btn fvm-save-button"
              onClick={(e) => {
                e.preventDefault();
                this.saveChanges();
              }}
            >
              {isSaved ? t('button.saved') : t('button.save')}

            </Button>
          </FvmGAItemTracker>
          <FvmGAItemTracker
            category={'Notification Settings'}
            label={'Apply to many'}
            action={'Open Apply to Many'}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              className="fvm-btn fvm-save-button ml-3"
              onClick={(e) => {
                e.preventDefault();
                const applyToMany = true;
                this.setState({ applyToMany });
                this.setState({
                  selectAll: Object.keys(userLocomotiveListObject).length === this.state.selected.length,
                });
              }}
            >
              {t('button.moreLocomotive')}
            </Button>
          </FvmGAItemTracker>
        </div>

        {
          this.state.applyToMany ? <SelectionDrawer
            isOpen
            onClose={this.closeModal}
            gridData={data}
            title={t('tables.tHead.myLocomotives')}
            selectedItems={[...selected]}
            actionButtonsArray={[
              {
                name: 'save',
                disabled: this.props.isDemoUser,
                color: 'primary', onClick: selectionAreaObj => {
                  this.setState({ triggerParentCallbk: true });
                  this.saveChanges(selectionAreaObj.selectedItems);
                },
                trackingInfo: {
                  category: 'Notifications Setting',
                  label: 'Saved',
                  action: 'Saved',
                },
              },
              {
                name: 'Cancel', onClick: this.closeModal, trackingInfo: {
                  category: 'Notifications Setting',
                  label: 'Save Multiple Drawer Closed',
                  action: 'Close',
                },
              },
            ]}
          /> : null
        }
        <FVMDialog
          isDialogOpen={isDialogOpen}
          {...dialogData}
        />
        {isLoading ? <LoadingSpinner /> : null}
      </div>
    );
  }
}

NotificationSettings.propTypes = {
  appConsts: PropTypes.object.isRequired,
  activeUserLocoId: PropTypes.number.isRequired,
  isDemoUser: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  activeUserLocoId: [...state.activeUserLocomotiveListIds].pop() || 0,
  activeUserLocomotiveListIds: state.activeUserLocomotiveListIds || [],
  isDemoUser: state.isDemoUser,
  isSingleSelect: state.isSingleSelect,
  userLocomotiveListObject: state.userLocomotiveList,
  userSessionID: state.userSessionID,
  languageFilesReloaded: state.languageFilesReloaded,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({}, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);

setTimeout(function () {

}, 3000);