import React from 'react';
import { ScaleLoader } from 'react-spinners';
import classNames from 'classnames';

const Index = (props) => (
  <div className={classNames('fvm-loading-spinner', props.classNames)}>
    <ScaleLoader
      color="#1cdd43"
      loading
    />
  </div>
);

export default Index;
