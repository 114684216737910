import { Box, Grid } from '@material-ui/core';
import Header from '../Header';
import Inspections from '../Inspections';
import VehicleState from '../VehicleState';
import React from 'react';

const GeneralTabPanel = ({
  t,
  hidden,
  logbookForm,
  vehicleIdRe,
  isA1Required,
  handleVSelectionChange,
  selectedV,
  gridItemsSpacing,
  locale,
  lastInspectionPerformedTimestamp_A1,
  isReadOnly
}) => {

  return (
    <Box
      hidden={hidden}
      key='tab-panel-general'
    >
      <Grid
        container
        spacing={0}
      >
        <Header
          isReadOnly={isReadOnly}
          form={logbookForm}
          vehicleIdRe={vehicleIdRe}
          t={t}
          locale={locale}
          gridItemsSpacing={gridItemsSpacing}
        />
        <Inspections
          form={logbookForm}
          gridItemsSpacing={gridItemsSpacing}
          isReadOnly={isReadOnly}
          locale={locale}
          handleVSelectionChange={handleVSelectionChange}
          selectedV={selectedV}
          t={t}
          isA1Required={isA1Required}
          lastInspectionPerformedTimestamp_A1={lastInspectionPerformedTimestamp_A1}
        />
        <VehicleState
          form={logbookForm}
          isReadOnly={isReadOnly}
          t={t}
          gridItemsSpacing={gridItemsSpacing}
        />
      </Grid>
    </Box>
  )
}

export default GeneralTabPanel;