import Toolbar from '@material-ui/core/Toolbar';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import debounce from 'lodash/debounce';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactAnimatedWeather from 'react-animated-weather';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { changedView, setUpdateView } from '../actions';
import BootstrapTooltip from '../components/FvmMaterilUiTooltip';
import LoadingSpinner from '../components/LoadingSpinner';
import getStatusIcon from '../components/LocomotiveStatusIcons';
import NrailBootstrapTable from '../components/NrailBootstrapTable/index';
import MapControls from '../containers/MapControls';
import { getCurrentView, setNumberUnit, timeFormatTableCol } from '../StatelessFunctions/nummericManapulation';
import { fetchSTECLiveData } from '../wp-rest-api/STEC-Communication';
import FVMGoogleMaps from '../containers/FVMGoogleMaps';

/**
 *
 */
class LiveData extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.getCellValue = this.getCellValue.bind(this);
    this.getCellWithUnit = this.getCellWithUnit.bind(this);

    this.state = {
      activeUserLocoId: props.activeUserLocoId,
      ...this.initialValues(),
      datasheetTable: {
        columns: [{
          dataField: 'label',
          text: '',
        }, {
          dataField: 'value',
          text: '',
        }],
        data: [],
      },
    };

    this.commonClassNames = 'section-heading';

    this.updateAllTables = this.updateAllTables.bind(this);
    this.updateLiveDataInfo = this.updateLiveDataInfo.bind(this);
    this.fetchNewHoursData = debounce(this.fetchNewHoursData.bind(this), 200);
    this.updateLoadingState = this.updateLoadingState.bind(this);
    this.getTableToolTip = this.getTableToolTip.bind(this);
    this.updateTableWIthLanguageChanges = this.updateTableWIthLanguageChanges.bind(this);
    // props.newView('livedata');
  }

  static getDerivedStateFromProps(nextProps, prevState) {
  }

  componentDidMount() {
    // this.props.newView();
    this.props.setUpdateView(false);
    this.updaterId = setInterval(this.fetchNewHoursData, 30000);
    this.updateDatasheetTableData(this.props.staticTableInfo);
    if (this.props.activeUserLocoId) {
      this.updateLoadingState(true);
      this.fetchNewHoursData();
    }
    window.addEventListener('message', this.receiveMessage, false);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      activeUserLocoId,
      staticTableInfo,
      languageFilesReloaded,
    } = this.props;


    if (staticTableInfo !== prevProps.staticTableInfo) {
      this.updateDatasheetTableData(staticTableInfo);
    }

    if (activeUserLocoId !== prevProps.activeUserLocoId) {
      this.updateLoadingState(true);
      this.fetchNewHoursData();
    }

    if (languageFilesReloaded !== prevProps.languageFilesReloaded) {
      this.setState({ componentLanguageUpdateTrigger: languageFilesReloaded }, this.updateTableWIthLanguageChanges);

    }

    if (( prevProps.isUpdateView !== this.props.isUpdateView ) && this.props.isUpdateView) {
      this.updateLiveDataInfo();
      setTimeout(() => {
        this.props.setUpdateView(false);
        this.updateLoadingState(false);
      }, 750);
    }
  }

  componentWillUnmount() {
    clearInterval(this.updaterId);
  }

  reportingHrsCol() {
    const { t } = this.props;
    return [
      // {
      //   dataField: 'serviceHours',
      //   text: t('tables.tHead.dailyServiceHours'),
      //   headerFormatter: cell => this.getTableToolTip(cell, t('tooltip.serviceHours'), 2),
      //   formatter: this.getCellValue,
      // },
      {
        dataField: 'workingHours',
        text: t('tables.tHead.dailyWorkingHours'),
        headerFormatter: cell => this.getTableToolTip(cell, t('tooltip.workingHours'), 2),
        formatter: (cell, row) => this.getCellValue(cell, row, true),
      },
      // {
      //   dataField: 'standByHours',
      //   text: t('tables.tHead.dailyStandingNoMovementHours'),
      //   headerFormatter: cell => this.getTableToolTip(cell, t('tooltip.standingNoMovement'), 2),
      //   formatter: cell => this.getCellValue(cell),
      // },
      // {
      //   dataField: 'standbyAndIdle',
      //   text: t('tables.tHead.dailyStandbyAndIdle'),
      //   headerFormatter: cell => this.getTableToolTip(cell, t('tooltip.standbyAndIdle'), 2),
      //   formatter: this.getCellValue,
      // },
      {
        dataField: 'idleHours',
        text: t('tables.tHead.dailyStandingWithEngineOnHours'),
        headerFormatter: cell => this.getTableToolTip(cell, t('tooltip.standingWithEngineOn'), 2),
        formatter: (cell, row) => this.getCellValue(cell, row, true),
      }];
  }

  liveDataStaticCol() {
    const { t } = this.props;

    return [
      {
        dataField: 'speed',
        text: t('tables.tHead.speed'),
        formatter: cell => this.getCellWithUnit(cell, 'km/h'),
      },
      {
        dataField: 'mileageKm',
        text: t('tables.tHead.dailyMileage'),
        headerFormatter: cell => (
          <span
            className="fvm-extend-info-icon"
          >
            <span>{cell.text}<sup>{1}</sup></span>
            <BootstrapTooltip
              title={t('tooltip.dailyMileage')}
            >
            <i
              className="fvm-info-icon"
            >
              <InfoOutlinedIcon/>
            </i>
            </BootstrapTooltip>
        </span> ),
        // formatter: kmFormatTableCol,
        formatter: cell => this.getCellWithUnit(cell, 'km'),

      },
      {
        dataField: 'externalPower',
        text: t('tables.tHead.actualExternalPower'),
        formatter: cell => this.getCellWithUnit(cell, 'V'),
      },
      {
        dataField: 'statusVehicle',
        text: t('tables.tHead.machineStatus'),
        formatter: (cell, row) => {
          const machineStat = getStatusIcon({ status: row.statusVehicle, type: row.isCrane ? 'crane' : row.type }, t);
          if (machineStat) {
            return (
              <BootstrapTooltip
                title={machineStat.tooltip}
              >
                <i
                  className="fvm-info-icon"
                >
                  {machineStat.icon}
                </i>
              </BootstrapTooltip>
            );
          }
          return null;
        },
      }];
  }

  notificationCol() {
    const { t } = this.props;
    return [
      {
        dataField: 'timestamp',
        text: t('tables.tHead.lastNotificationDateTime'),
        formatter: cell => cell !== null && cell !== undefined
          ? `${( new Date(cell) ).toLocaleString('de-DE')}`
          : t('tables.tRow.notApplicable'),
      },
      {
        dataField: 'type',
        text: t('tables.tHead.notificationType'),
        formatter: cell => cell !== null && cell !== undefined ? `${cell}` : t('tables.tRow.notApplicable'),
      }];
  }

  weatherCol() {
    const { t } = this.props;
    return [
      {
        dataField: 'temperatureSensor',
        text: t('tables.tHead.tempratureSensor'),
        headerFormatter: cell => this.getTableToolTip(cell, t('tooltip.weatherInfoIcon')),
        formatter: cell => `${setNumberUnit(cell, ' °C', t('tables.tRow.notApplicable'))}`,
      },
      {
        dataField: 'weatherIcon',
        text: t('tables.tHead.weather'),
        classes: 'fvm-weather',
        formatter: cell =>
          cell.icon !== null && cell.icon !== undefined
            ? (
              <span>
              <ReactAnimatedWeather
                size={25}
                color="black"
                icon={cell.icon.toUpperCase().replace(/-/g, '_')}
                animate
              />
                {cell.text}
            </span>
            )
            : 'NA',
      }];
  }

  getTableToolTip(cell, tootltipText, footNotsRef = '') {
    return (
      <span className="fvm-extend-info-icon">
        <span>{cell.text}<sup>{footNotsRef}</sup></span>
        <BootstrapTooltip
          title={tootltipText}
        >
          <sup>
            <i className="fvm-info-icon">
              <InfoOutlinedIcon/>
            </i>
          </sup>
          </BootstrapTooltip>
      </span>
    );
  }

  getCellValue(cell, row, checkEloc) {
    const { activeUserLocoId, t } = this.props;
    const vehicle = this.props.userLocomotiveListObject && activeUserLocoId && this.props.userLocomotiveListObject[activeUserLocoId];
    if (vehicle && vehicle.isCrane) {
      return t('labels.crane');
    }

    if (checkEloc && vehicle && vehicle.isEloc) {
      return t('labels.eLoc');
    }
    return timeFormatTableCol(cell, t);
  }

  getCellWithUnit(cell, unit) {
    const { activeUserLocoId, t } = this.props;
    if (this.props.userLocomotiveListObject &&
      activeUserLocoId &&
      this.props.userLocomotiveListObject[activeUserLocoId].isCrane) {
      return t('labels.crane');
    }

    if (cell === -1) {
      return t('labels.eLoc');
    }
    return setNumberUnit(cell, unit, t('tables.tRow.notApplicable'));
  }

  async fetchNewHoursData(activeUserLocoId = this.props.activeUserLocoId) {
    if (!activeUserLocoId) {
      this.updateLoadingState(false);
      this.updateDatasheetTableData(this.props.staticTableInfo);
      this.setState({ ...this.initialValues() });
      return;
    }

    if (activeUserLocoId === this.props.activeUserLocoId) {
      // this.updateDatasheetTableData(this.props.staticTableInfo, resp);
      const { isDemoUser, userSessionID } = this.props;
      const currentView = getCurrentView();
      const response = await fetchSTECLiveData({
        vehicleId: activeUserLocoId,
        currentView,
        userSessionID,
        isDemoUser,
      });
      // const response = await fetchSTECLiveData(activeUserLocoId, this.props.isDemoUser);
      if (activeUserLocoId === this.props.activeUserLocoId) {
        this.updateAllTables(response);
      }
    }

    this.updateLoadingState(false);
  }

  updateAllTables({ notificationResp, weatherResp, datasheet }) {
    const { live } = this.props.userLocomotiveListObject[this.props.activeUserLocoId];
    const {
      weatherTable,
      notificationTable,
    } = this.state;

    const { icon, summary, temperature } = weatherResp || {};
    // over writing the data property
    weatherTable.data = [{
      weatherIcon: {
        icon: icon || null,
        text: `${summary} | ${setNumberUnit(Math.round(temperature), '°C', this.props.t('tables.tRow.notApplicable'))}` || '',
      },
      temperatureSensor: this.props.isDemoUser
        ? temperature
        : live.temperatureSensor,
    }];
    notificationTable.data = notificationResp;

    this.updateDatasheetTableData(datasheet); // this.setState({ datasheetTable });
    this.updateLiveDataInfo();
    this.setState({
      notificationTable,
      weatherTable,
    });
  }

  updateLiveDataInfo() {
    if (!Object.keys(this.props.userLocomotiveListObject).length || !this.props.activeUserLocoId) {
      return;
    }
    const { liveDataTable, reportingHoursDataTable } = this.state;
    const {
      vehicle,
      timestampMileage,
      timestampRecentRawDataStatus,
      live,
      todays,
    } = this.props.userLocomotiveListObject[this.props.activeUserLocoId];

    liveDataTable.data = [{ ...todays, ...live, ...vehicle }];
    reportingHoursDataTable.data = [todays];

    this.setState({
      reportingHoursDataTable,
      liveDataTable,
      timestampMileage,
      timestampRecentRawDataStatus,
    });
  }

  updateLoadingState(status, time = 0) {
    setTimeout(() => {
      this.setState({ isLoading: status });
    }, time);
  }

  initialValues() {
    return {
      reportingHoursDataTable: {
        columns: this.reportingHrsCol(),
        data: [],
      },
      liveDataTable: {
        columns: this.liveDataStaticCol(),
        data: [],
      },
      notificationTable: {
        columns: this.notificationCol(),
        data: [],
      },
      weatherTable: {
        columns: this.weatherCol(),
        data: [],
      },
      TimestampMileage: null,
      TimestampWorkingHours: null,
    };
  }

  updateTableWIthLanguageChanges() {
    const {
      reportingHoursDataTable,
      liveDataTable,
      notificationTable,
      weatherTable,
    } = this.state;

    this.setState({
      reportingHoursDataTable: {
        columns: this.reportingHrsCol(),
        data: reportingHoursDataTable.data.map(dataEntry => ( { ...dataEntry } )),
      },
      liveDataTable: {
        columns: this.liveDataStaticCol(),
        data: liveDataTable.data.map(dataEntry => ( { ...dataEntry } )),
      },
      notificationTable: {
        columns: this.notificationCol(),
        data: notificationTable.data.map(dataEntry => ( { ...dataEntry } )),
      },
      weatherTable: {
        columns: this.weatherCol(),
        data: weatherTable.data.map(dataEntry => ( { ...dataEntry } )),
      },
    });

    this.fetchNewHoursData();// to be removed once a better was is found to change the data in the tables based on
                             // locale
  }

  updateDatasheetTableData(datasheet = {}) {
    const datasheetKeys = [
      'vehicleManufacturerName',
      'modelName',
      'fabricNumber',
      'constructionYear',
      'minOperationWeight_kg'];
    const datasheetDataArray = [];
    datasheetKeys.forEach((key, index) => {
      if (index > 4) {
        return;
      }

      if (key !== 'meterstatus') {
        datasheetDataArray.push({
          name: key,
          label: this.props.t(`tables.tRow.datasheet.${key}`),
          // label: this.props.t(`${key}`),
          value: datasheet[key],
        });
      }
    });
    const datasheetTable = { ...this.state.datasheetTable, data: datasheetDataArray };
    this.setState({ datasheetTable, comments: '' });
  }

  render() {
    const {
      reportingHoursDataTable,
      notificationTable,
      weatherTable,
      liveDataTable,
      datasheetTable,
      isLoading,
      timestampRecentRawDataStatus,
      timestampMileage,
    } = this.state;
    const { t, isFvmFeaturesActive, activeUserLocoId } = this.props;

    const { vehicle } = this.props.userLocomotiveListObject[activeUserLocoId] || {};
    const displayFallbk = !activeUserLocoId || !vehicle?.image || !!vehicle.image?.match('loc-default');
    const displayFallbkUrl = vehicle && vehicle.image
      ? `https://my.nrail.de/assets/images/${this.props.userLocomotiveListObject[activeUserLocoId].isCrane
        ? 'crane' : 'loc'}-default-img.png`
      : 'https://my.nrail.de/assets/images/loc-default.jpg';

    const timestamps = [
      timestampMileage,
      timestampRecentRawDataStatus,
    ].map(ts => ts ? moment(new Date(ts)) : null);

    return (
      <article className="fvm-page fvm-page-live-data">
        <div className="fvm-map-page-toolbar-container">
          <Toolbar
            className="fvm-map-page-toolbar"
            disableGutters
          >
            {this.props.t ? <MapControls t={this.props.t}/> : null}
          </Toolbar>
        </div>
        <div className="fvm-general-page-content">

          <section className="fvm-sp fvm-sp-map-iframe no-gutters container-fluid">
            <div className="row">
              <div className={`col fvm-map-iframe ${isFvmFeaturesActive ? 'map-detailed-page-control' : ''}`}>
                {
                  Object.keys(this.props.userLocomotiveListObject).length > 0
                    // ? <MapIframe currentView="livedata" open={false} t={t} />
                    // ? (localStorage.getItem('fvmfeatureactive') ? <FVMGoogleMaps t={t} railationFeatures={[]}/> :
                    // <MapIframe currentView="livedata" open={false} t={t}/>)
                    ? <FVMGoogleMaps t={t} railationFeatures={[]}/>
                    : null
                }
              </div>
              <div className={`col fvm-locomotive-datasheet ${isFvmFeaturesActive ? 'map-detailed-page-control' : ''}`}>
                <div className="fvm-image">
                  <img
                    className="img-fluid"
                    // alt="Locomotive Image"
                    src={!displayFallbk ? vehicle.image : displayFallbkUrl}
                    alt="default-loco"
                    onError={(e) => {
                      this.setState({ displayFallbk: true });
                      if (!displayFallbk &&  e) {
                        e.target.src = displayFallbkUrl;
                      }
                    }
                    }
                  />
                  <p className="fvm-title">{vehicle?.name || ''}</p>
                </div>
                <NrailBootstrapTable
                  keyField="label"
                  className={`${this.commonClassNames} fvm-datasheet-table`}
                  {...datasheetTable}
                />
              </div>
            </div>
          </section>

          <section className="fvm-sp fvm-sp-live-data container-fluid">
            <div className="row">
              <div className="col">
                <NrailBootstrapTable
                  keyField="vehicleId"
                  className={this.commonClassNames}
                  {...liveDataTable}
                  title={t('tables.caption.liveData')}
                />
                <NrailBootstrapTable
                  keyField="vehicleId"
                  className={this.commonClassNames}
                  {...reportingHoursDataTable}
                  timeStamp
                />
                <div className="row">
                  <div className="col-6">
                    <NrailBootstrapTable
                      keyField="vehicleId"
                      className={this.commonClassNames}
                      {...notificationTable}
                    />
                  </div>
                  <div className="col-6">
                    <NrailBootstrapTable
                      keyField="temperatureSensor"
                      className={this.commonClassNames}
                      {...weatherTable}
                    />
                  </div>
                </div>

              </div>
            </div>
          </section>
          {isLoading || this.props.isMapLoading ? <LoadingSpinner/> : null}
          <span className="table-footnotes">
            {
              timestamps.length && (
                timestamps.map((timestamp, index) =>
                  timestamp ?
                    <span key={`timestamp-${index}`}>
                    {`${index + 1}) ${timestamp.format('DD.MM.YYYY HH:mm:ss')}`}
                  </span>
                    : null,
                )
              )
            }
          </span>
        </div>
        {/*<ReactTooltip className="fvm-tooltip" />*/}
      </article>
    );
  }
}

LiveData.propTypes = {
  newView: PropTypes.func.isRequired,
  isDemoUser: PropTypes.bool.isRequired,
  isMapLoading: PropTypes.bool.isRequired,
  activeUserLocoId: PropTypes.number.isRequired,
  userLocomotiveListObject: PropTypes.objectOf(PropTypes.object).isRequired,
  staticTableInfo: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ( {
  activeUserLocoId: [...state.activeUserLocomotiveListIds].pop() || 0,
  isDemoUser: state.isDemoUser,
  locListUpdated: state.locListUpdated,
  userLocomotiveListObject: state.userLocomotiveList,
  isMapLoading: state.isMapLoading,
  staticTableInfo: state.dataSheetStaticData,
  isFvmFeaturesActive: state.isFvmFeaturesActive,
  userSessionID: state.userSessionID,
  languageFilesReloaded: state.languageFilesReloaded,
  isUpdateView: state.isUpdateView,
} );

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    newView: changedView,
    setUpdateView: setUpdateView,

  }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(LiveData);