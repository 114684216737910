import React from 'react';
const Personnel = () => {
  const a = {
    fill: '#002fc9',
  };
  const b = {
    opacity: 0,
  };
  const c = {
    fill: '#fff',
    fillRule: 'evenodd',
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.999" height="33.999" viewBox="0 0 33.999 33.999">
      {/*<style>.a,.b{fill:#002fc9;}.b{opacity:0;}.c{fill:#fff;stroke:rgba(0,0,0,0);}*/}
      <g transform="translate(-726 -640)">
        <g transform="translate(635 557.999)">
          <rect style={a} width="33.999" height="33.999" rx="5" transform="translate(91 82.001)" />
        </g>
        <g transform="translate(520 135)">
          <rect style={{ ...a, ...b }} width="24" height="24" transform="translate(211 510)" />
          <path
            style={c}
            d="M-1524,334v-1a7,7,0,0,1,7-7h2a7,7,0,0,1,7,7v1Zm4.5-12.5a3.5,3.5,0,0,1,3.5-3.5,3.5,3.5,0,0,1,3.5,3.5,3.5,3.5,0,0,1-3.5,3.5A3.5,3.5,0,0,1-1519.5,321.5Z"
            transform="translate(1739 196)"
          />
        </g>
      </g>
    </svg>
  );
};
export default Personnel;
