import React, { useEffect } from 'react';
import { newPasswordRequest, resetPassword } from '../../../apis/user-apis';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { generateTestAttribute } from '../../../utils/helperUtilities';
import PropTypes from 'prop-types';
// import { gridDimensions } from '../../../app-consts';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import FVMNrailLogo from '../../../assets/svgs/nrail-logo';
import { Link } from 'react-router-dom';
import { getUserLang } from '../../../wp-rest-api/STEC-Communication';
import { useAuthStyles } from '../authStyles';
import { useDispatch } from 'react-redux';
import { userLanguageChanged } from '../../../actions';
import { changeUserLanguage } from '../../../reducers/accounts/user';
import i18n, { DEFAULT_LOCALE_MAP } from '../../../i18n';
import PasswordValidator from 'password-validator';


export const getPasswordValidator = (t) => {
  const nrlPasswordValidator = new PasswordValidator();
  const additionCheckRegexPattern = new RegExp('([^\\x00-\\x7F])', 'gm');

  nrlPasswordValidator
    .is().min(8, t(`profilePage.fields.password.error.min`)) // Minimum length 8
    .is().max(100, t(`profilePage.fields.password.error.max`)) // Maximum length 100
    .has().uppercase(1, t(`profilePage.fields.password.error.uppercase`))// Must have uppercase
    // letters
    .has().lowercase(1, t(`profilePage.fields.password.error.lowercase`)) // Must have lowercase letters
    .has().digits(1, t(`profilePage.fields.password.error.digits`)) // Must have digits
    .has().symbols(1, t(`profilePage.fields.password.error.symbols`)) // Should have one of the following
    .has().not(additionCheckRegexPattern, t('profilePage.fields.password.error.notAllowedChars'))
    .has().not().spaces(1, t(`profilePage.fields.password.error.spaces`)); // Should not have spaces

  nrlPasswordValidator.additionCheckRegexPattern = additionCheckRegexPattern;
  return nrlPasswordValidator;
};
/**
 * page shown to the end user
 * @component
 * @return {JSX.Element} React component
 * @example
 * return (<Login />)
 */
const ResetPassword = ({ t, token = null, ...props }) => {
  const componentName = ResetPassword.displayName || '';
  const classes = useAuthStyles();
  const [accountName, setAccountName] = React.useState('');
  const [passkey, setPasskey] = React.useState('');
  const [passkeyErrorLst, setPasskeyErrorLst] = React.useState([]);
  const [newPasskey, setNewPasskey] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [responseStatus, setResponseStatus] = React.useState(null);
  const dispatch = useDispatch();
  const nrlPasswordValidator = getPasswordValidator(t);
  const handleAccountNameChanged = ({ target }) => {
    setAccountName(target.value);
  };

  const toggleShowNewPassword = ({ target }) => {
    setShowNewPassword(!showNewPassword);
  };
  const toggleShowPassword = ({ target }) => {
    setShowPassword(!showPassword);
  };

  const handlePasskeyChanged = ({ target }) => {
    setPasskey(target.value);
  };
  const handleNewPasskeyChanged = ({ target }) => {
    setNewPasskey(target.value);
  };

  const handleRequestPassKeyReset = async () => {
    const resp = await newPasswordRequest(accountName, undefined, DEFAULT_LOCALE_MAP[i18n.language]);
    setResponseStatus(resp);
  };

  const changePasskey = async () => {
    const resp = await resetPassword(newPasskey, token);
    setResponseStatus(resp);
  };
  const changeLang = lang => {
    i18n.changeLanguage(lang, resp => {
      dispatch(userLanguageChanged(lang)); // to be removed later
      dispatch(changeUserLanguage(lang));
    });
  };

  useEffect(() => {
    const notMatchingError = passkeyErrorLst.find(error => error.validation === 'passwordDoesNotMatch') || null;
    if (notMatchingError) {
      setPasskeyErrorLst([notMatchingError, ...nrlPasswordValidator.validate(passkey, { details: true })]);

    } else {
      setPasskeyErrorLst(nrlPasswordValidator.validate(passkey, { details: true }));
    }
  }, [passkey]);

  useEffect(() => {
    if (passkey && newPasskey && newPasskey !== passkey) {
      if (!passkeyErrorLst.find(error => error.validation === 'passwordDoesNotMatch')) {
        setPasskeyErrorLst(
          [
            ...passkeyErrorLst,
            {
              validation: 'passwordDoesNotMatch',
              arguments: 8,
              message: 'Passwords entered do not match.',
            },
          ],
        );
      }

    } else if (passkeyErrorLst.find(error => error.validation === 'passwordDoesNotMatch')) {
      setPasskeyErrorLst(passkeyErrorLst.filter(error => error.validation !== 'passwordDoesNotMatch'));
    }
  }, [newPasskey, passkey]);


  return (
    <Grid
      container
      className={classes.additionalWrapper}
      {...generateTestAttribute(componentName, 'component')}
      justify="center"
      direction="column"
      alignItems="center"
      {...props}
    >
      <Grid
        justify="center"
        direction="column"
        className={classes.formGridContainer}
        alignItems="center"
        spacing={2}
        container
      >
        <Grid
          item
          sx={12}
          className={classes.innerFormContainer}
          direction={'column'}
        >
          <div className={classes.title}>
            <h1>{t('labels.smartData')}</h1>
            <p>{t('labels.reExperienceRailway')}!</p>
          </div>
          <form className={classes.loginForm} noValidate autoComplete="off">
            <div className="fvm-logo fvm-cp-image">
              <FVMNrailLogo/>
            </div>
            {/*<Typography variant='h5'>Password Rest Request</Typography>*/}
            <Typography variant="h5">
              {t(`labels.${token ? 'resetPassword' : 'passwordRestRequest'}`)}
            </Typography>
            {/*<Typography variant='h5'>{token ? t('label.passwordRestRequest') : t('label.passwordRestRequest')}</Typography>*/}
            {token ? (
              <React.Fragment>
                <p
                  className={`${
                    responseStatus === 204
                      ? 'shake animated fvm-fill-green'
                      : 'bounceInDown animated fvm-fill-red'
                  }`}
                >
                  {
                    responseStatus === 204
                      ? t('labels.passwordResetSuccessful')
                      : null
                  }
                  {
                    responseStatus && responseStatus !== 204
                      ? t('labels.passwordResetFailed')
                      : null
                  }


                  {/*{ [404, 502].includes(responseStatus) ? 'labels.passwordResetFailed' : ''}*/}
                  {/*{responseStatus > 204 ? 'labels.passwordResetFailed' : ''}*/}
                </p>

                {passkey && passkeyErrorLst.length
                  ? <ul
                    style={{
                      textAlign: 'left',
                      height: 'auto',
                      minHeight: 60,
                      maxHeight: 90,
                      overflow: 'scroll',
                      backgroundColor: '#FF00001F',
                      padding: 10,
                      paddingLeft: 20,
                    }}
                    className={`${
                      responseStatus === 204
                        ? 'shake animated fvm-fill-green'
                        : 'bounceInDown animated fvm-fill-red'
                    }`}
                  >
                    {
                      passkeyErrorLst.map(error =>
                        <li>{t(`profilePage.fields.password.error.${error.validation}`)}</li>)
                    }
                  </ul>
                  : null
                }

                {responseStatus !== 204 ? (
                  <React.Fragment>
                    <TextField
                      id="outlined-password-input"
                      {...generateTestAttribute(
                        componentName,
                        'password-input',
                      )}
                      label={t('labels.newPassword')}
                      value={passkey}
                      onChange={handlePasskeyChanged}
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="current-password"
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={toggleShowPassword}
                            >
                              {passkey ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="outlined-password-input"
                      {...generateTestAttribute(
                        componentName,
                        'new-password-input',
                      )}
                      label={t('labels.confirmNewPassword')}
                      value={newPasskey}
                      onChange={handleNewPasskeyChanged}
                      type={showNewPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={toggleShowNewPassword}
                            >
                              {newPasskey ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p
                  className={`${
                    responseStatus === 204
                      ? 'shake animated fvm-fill-green'
                      : 'bounceInDown animated fvm-fill-red'
                  }`}
                >
                  {responseStatus === 204
                    ? t('labels.passwordResetRequestSuccessful')
                    : ''}
                  {responseStatus === 404
                    ? t('labels.passwordResetRequestFailed')
                    : ''}
                </p>
                {responseStatus !== 204 ? (
                  <TextField
                    id="outlined-basic"
                    {...generateTestAttribute(componentName, 'username-input')}
                    label={t('labels.username')}
                    name={'email'}
                    type="email"
                    value={accountName}
                    onChange={handleAccountNameChanged}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                ) : null}
              </React.Fragment>
            )}

            {responseStatus !== 204 ? (
              <Button
                disabled={
                  token
                    ? !( passkey && newPasskey ) ||
                      !( passkey === newPasskey )
                    : !accountName
                }
                variant="contained"
                {...generateTestAttribute(componentName, 'submit-button')}
                color="primary"
                onClick={token ? changePasskey : handleRequestPassKeyReset}
              >
                {t(`button.${token ? 'resetPassword' : 'requestNewPassword'}`)}
              </Button>
            ) : null}
          </form>
        </Grid>
        <Grid
          className={classes.formFooterText}
          item
          container
          sx={12}
          spacing={2}
        >
          <Grid
            className={`${classes.languageSeparator}  ${classes.centerTextAlign}`}
            xs={12}
            sm={6}
            item
          >
            <span onClick={() => changeLang('de-DE')}>
              {' '}
              {t('singleWords.german')}{' '}
            </span>
            <span onClick={() => changeLang('en-GB')}>
              {' '}
              {t('singleWords.english')}
            </span>
          </Grid>
          <Grid
            className={`${classes.rightTextAlign} ${classes.centerTextAlign}`}
            item
            xs={12}
            sm={6}
          >
            <Link to="/login">{t('labels.login')}</Link>
          </Grid>
        </Grid>
        <Grid
          className={classes.formFooterText}
          item
          container
          sx={12}
          justify={'center'}
        >
          <Grid item>
            <a
              href={`https://nrail.de/${
                getUserLang() === 'de' ? '' : 'en/'
              }datenschutz`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('labels.privacyPolicy')}
            </a>
          </Grid>
        </Grid>
      </Grid>
      {/*{isFrontpage ? null : JsonOutput({ loginInfo, outputName: componentName })}*/}
    </Grid>
  );
};

ResetPassword.propTypes = {
  /**
   * Use to tell how the component should be rendered. If true the component will render in the center
   * of the page. If false it will render left aligned.
   */
  isFrontpage: PropTypes.bool,
};

//For dynamic key, id and test attribute creation
ResetPassword.displayName = 'Login';
export default ResetPassword;
