import React from 'react';

const SubCatCommunication = () => {
  const a = {
    fill: '#e5e5e5',
    opacity: 0,
  }
  const b = {
    fill: '#002fc9',
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g transform="translate(-441 -405)">
        <rect style={a} width="24" height="24" transform="translate(441 405)" />
        <path
          style={b}
          d="M-76,843H-92a2,2,0,0,0-1.99,2L-94,862l4-4h14a2.006,2.006,0,0,0,2-2V845A2.006,2.006,0,0,0-76,843Zm-5,10h-9v-2h9Zm3-4H-90v-2h12Z"
          transform="translate(537 -435)"
        />
      </g>
    </svg>
  );
};
export default SubCatCommunication;
