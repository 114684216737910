import React from 'react';
const Accumulative = ({ color }) => {
  const st1 = {
    fill: color==='primary' ? '#1cdd43' : '#152935',
  };

  return (
    <svg
      id="Ebene_1" dataname="Ebene 1" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
    >
      <path className="st1" style={st1} d="M24,6.2,22.31,4.5,15.8,11.1,7,11.3l-7,7,1.8,1.8L8,13.9l8.8-.2Z" />
    </svg>
  );
};

export default Accumulative;
