import React from 'react';
const SubCatFactory = () => {
  const a = {
    fill: '#e5e5e5',
    opacity: 0,

  };
  const b = {
    fill: '#002fc9',
    fillRule: 'evenodd',
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        {/*<style>.a{fill:#e5e5e5;opacity:0;}.b{fill:#002fc9;fill-rule:evenodd;}</style>*/}
      <g transform="translate(-441 -453)">
        <rect style={a} width="24" height="24" transform="translate(441 453)" />
        <path
          style={b}
          d="M-78,699v8.2l-5-3.2v3.2l-5-3.2v3.2l-5-3.2v12h19V699Zm-9,14h-3v-2h3Zm5,0h-3v-2h3Zm5,0h-3v-2h3Z"
          transform="translate(537 -243)"
        />
      </g>
    </svg>
  );
};
export default SubCatFactory;
