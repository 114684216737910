import axios from 'axios';
import moment from 'moment';

import * as configs from '../configs/site-config.json';
import { getGatewayExportApi, getGatewayMapsApi, getGatewayMetaApi, getGatewayReportsApi } from '../apis';
import { decode } from 'jwt-simple';

/** @namespace configs.default Default configurations for API **/

const {
  FVM_API_V_NUM,
  FVM_API_PATH,
} = configs.default;


export const cleanUpLocalStorage = (additionalKeysToKeep = [], clearLogbookEntries = true) => {
  const keys = Object.keys(localStorage);
  if (clearLogbookEntries) {
    const refreshToken = localStorage.getItem('userToken');
    const unsavedLogbookItemsStr = localStorage.getItem('unsavedLogbookItems');
    const unsavedLogbookItems = unsavedLogbookItemsStr ? JSON.parse(unsavedLogbookItemsStr) : {};
    const { accountId } = decode(refreshToken, '', true);
    if (accountId && unsavedLogbookItems && Object.keys(unsavedLogbookItems).length && unsavedLogbookItems[accountId]) {
      if (Object.keys(unsavedLogbookItems).length > 1) {
        delete unsavedLogbookItems[accountId];
        localStorage.setItem('unsavedLogbookItems', JSON.stringify(unsavedLogbookItems));
      } else {
        localStorage.removeItem('unsavedLogbookItems');
      }
    }
  }

  const itemsToCache = [
    ...additionalKeysToKeep,
    'activeOrganisationId',
    'unsavedLogbookItems',
    'i18nextLng',
  ];

  if (keys?.length) {
    keys.filter(itemName => !itemsToCache.includes(itemName)).forEach(item => localStorage.removeItem(item));
  }
};

if (!localStorage.getItem('userId') && localStorage.getItem('fvm_wp_api_token')) {
  cleanUpLocalStorage(undefined, false);
}

const FVM_BASE_URL = window.REACT_APP_NRL_LOGBOOK_URL;
console.log("FVM_BASE_URL::", FVM_BASE_URL);
// const FVM_BASE_URL = fvmBaseUrlMap[process.env.REACT_APP_NRL_ENV];
// const FVM_BASE_URL = process.env.REACT_APP_FVM_BASE_URL || window.location.origin.replace('my.', '');
const testProductionApis = localStorage.getItem('isTestProductionApis');
const FVM_API_URL = `${FVM_BASE_URL}/${FVM_API_PATH}/${FVM_API_V_NUM}`;
const USER_LANG = localStorage.getItem('i18nextLng');
const getUserLang = () => localStorage.getItem('i18nextLng') || 'en-GB';

/**
 *
 * @returns {Promise<void>}
 */
const get_user_interaction_data = async () => {
  const url = '/configs/mynrail_user_interaction.json';

  const resp = await axios.get(url).then(sResp => sResp.data);
  const { userMsgTs, userSuggestReloadTs } = resp;
  if (!localStorage.getItem('userSuggestReloadTs') && userMsgTs) {
    localStorage.setItem('userSuggestReloadTs', userSuggestReloadTs);
  }
  if (!localStorage.getItem('userMsgTs') && userMsgTs) {
    localStorage.setItem('userMsgTs', `${userMsgTs - 2}`);
  }
};

get_user_interaction_data().then(() => {
});

/**
 *
 * @param listOfIds
 * @param isDemoUser
 * @returns {Promise<{isError: boolean, data: *[]}|{isError: boolean}>}
 */
const fetchSTECSimpleData = async (vehiclesIdLst) => {
  // try {
  //   let data = await (await getGatewayReportsApi())
  //     .getLive(vehiclesIdLst)
  //     .then(res => res.data);
  //   //START: To be removed after added to live api
  //   return { isError: false, data };
  // } catch (e) {
  //   return { isError: true };
  // }
  return { isError: true };
};

/**
 *
 * @param params
 * @returns {Promise<any|*[]>}
 */
const fetchSTECDashboardData = async (vehiclesIdLst) => {
  // let serverJsonResponse = await (await getGatewayReportsApi())
  //   .getSimpleLive(vehiclesIdLst)
  //   .then(res => res.data)
  //   .catch(error => {
  //     console.dir(error);
  //     return [];
  //   },
  //   );

  // //START: To be removed after added to live api
  // const mapsLiveLocoObj = {};
  // serverJsonResponse.forEach(({
  //   vehicleId,
  //   ...tempMapLocoObj
  // }) => mapsLiveLocoObj[vehicleId] = { vehicleId, ...tempMapLocoObj });


  // await fetchMapLiveData(vehiclesIdLst)
  //   .then(resp => resp.forEach(({ vehicleId, live }) => {
  //     if (mapsLiveLocoObj[vehicleId]?.live) {
  //       mapsLiveLocoObj[vehicleId].live = { ...live, ...mapsLiveLocoObj[vehicleId].live };
  //     }
  //   }));

  // serverJsonResponse = Object.values(mapsLiveLocoObj);
  // return serverJsonResponse || [];
};

/**
 *
 * @param vehicleId String
 * @param params
 * @returns {Promise<{stecResponse: any, fvmResponse: any}|*[]>}
 */
const fetchSTECLiveData = async ({ vehicleId, ...params }) => {
  const { isDemoUser } = params;

  const modifiedParams = {
    ...params,
    vehicleIds: vehicleId,
    startDate: moment(0),
    endDate: moment(),
  };

  const notificationResp = await fetchSTECNotifications({ ...modifiedParams, skip: 0, top: 1 });
  const datasheet = await fetchDatasheet({ vehicleId }).catch(e => {
  });
  const lang = getUserLang();
  const weatherResp = await (await getGatewayReportsApi()).getWeatherInfo(vehicleId, lang).then(res => res.data).catch(e => ({}));
  return { notificationResp, weatherResp, datasheet };
};

const fetchDatasheet = async ({ vehicleId }) => {
  return await (await getGatewayMetaApi()).getLocoMetaData(vehicleId).then(res => res.data).catch(e => ({}));
};


const fetchUserDevices = async ({ userId }) => {
  return await (await getGatewayMetaApi()).getUsersVehicles(Number(userId));
  // return await axios.get(`${NRAIL_BASE_URL}/gateway/api/v1/users/${userId}/vehicles`, {});
  /**
   * ! TODO: replace line above with the line below when token added to gateway
   *
   */
};

/**
 *
 * @param vehicleId
 * @param limitedAccessStartDate
 * @param limitedAccessEndDate
 * @param remainingParams
 * @returns {Promise<{times: {}, notificationResponse: any, dateResponse: any, generalResponse: any}>}
 */
const fetchSTECReporting = async ({ vehicleId, limitedAccessStartDate, limitedAccessEndDate, ...remainingParams }) => {
  const startDate = remainingParams.startDate.format('YYYY-MM-DD');
  const stopDate = remainingParams.endDate.format('YYYY-MM-DD');
  const generalResponse = await (await getGatewayReportsApi())
    .getReport(vehicleId, startDate, stopDate)
    .then(res => res.data);

  const notificationResponse = [];
  /* getNotifications is missing from api

   await (await getGatewayReportsApi())
   .getNotifications(startDate, stopDate, vehicleId, 0, 3)
   .then(res => res.data.items);

   */

  const times = {};
  const { Date } = generalResponse;
  const { LastDataDate } = generalResponse && generalResponse.mileageKm;
  if (Date) {
    times.Date = Date;
  }
  if (LastDataDate) {
    times.LastDataDate = LastDataDate;
  }

  return { generalResponse, notificationResponse, times };
};

/**
 *
 * @param params
 * @returns {Promise<any>}
 */
const fetchPieChartData = async ({ vehicleId, ...params }) => {
  const startDate = params.startDate.format('YYYY-MM-DD');
  const stopDate = params.endDate.format('YYYY-MM-DD');
  return await (await getGatewayReportsApi())
    .getPieChart(vehicleId, startDate, stopDate, true, true, true)
    .then(res => res.data)
    .catch(err => {
      console.dir(err);
      return [];
    });
  ;
};

/**
 *
 * @param endDate
 * @param groupByType
 * @param remainingParams
 * @returns {Promise<any>}
 */
const fetchSTECDetailedReport = async ({ vehicleIds, groupByType, skip, top, ...params }) => {
  const startDate = params.startDate.format('YYYY-MM-DD');
  // const stopDate = moment(params.endDate).add(1, 'day').format('YYYY-MM-DD');
  const stopDate = moment(params.endDate).format('YYYY-MM-DD');

  const isGroupById = groupByType === 'by-id';
  const isGroupMetric = groupByType === 'by-metric';

  const dateResponse = isGroupById
    ? await (await getGatewayExportApi()).getExportReportById(startDate, stopDate, vehicleIds, skip, top).then(res => res.data.items)
    : await (await getGatewayExportApi()).getExportReportByMetric(startDate, stopDate, vehicleIds, 0, 20).then(res => res.data.items);

  if (isGroupMetric) {
    let obj = {};
    Array.isArray(dateResponse) && dateResponse.forEach(mertic => {
      obj = { ...obj, ...mertic };
    });
    return obj;
  }
  return dateResponse;
};

const fetchGraphReport = async ({ vehicleId, groupByType, ...params }) => {
  const startDate = params.startDate.format('YYYY-MM-DD');
  // const stopDate = moment(params.endDate).add(1, 'day').format('YYYY-MM-DD');
  const stopDate = moment(params.endDate).format('YYYY-MM-DD');
  return await (await getGatewayReportsApi())
    .getGraph(vehicleId, startDate, stopDate)
    // .getGraph(vehicleId, startDate, stopDate, [`groupBy=${groupByType}`])
    .then(res => res.data)
    .catch(err => {
      console.dir(err);
      return [];
    });
};


/**
 *
 * @param params
 * @returns {Promise<{docs: []}|any>}
 */
const fetchSTECNotifications = async ({ skip, top, orderBy, ...params }) => {
  const startDate = params.startDate.format('YYYY-MM-DD');
  const stopDate = params.endDate.format('YYYY-MM-DD');

  let { vehicleIds } = params;
  if (Array.isArray(vehicleIds)) {
    vehicleIds = vehicleIds.join(',');
  }

  return [];
  /* getNotifications is missing from api
   return await (await getGatewayReportsApi())
   .getNotifications(startDate, stopDate, vehicleIds, skip, top)
   .then(res => res.data.items)
   .catch(err=>{
   console.dir(err);
   return [];
   });
   */
};


const fetchTrackingMapData = async ({ vehicleId, ...params }) => {
  const startDate = params.startDate.format('YYYY-MM-DD');
  const stopDate = params.endDate.format('YYYY-MM-DD');
  return await (await getGatewayMapsApi())
    .getTracks(startDate, stopDate, vehicleId).then(res => res.data)
    .then(data => ({ features: data || [] }))
    .catch(err => {
      console.dir(err);
      return [];
    });
};

const fetchHeatMapData = async ({ vehicleId, ...params }) => {
  const startDate = params.startDate.format('YYYY-MM-DD');
  const stopDate = params.endDate.format('YYYY-MM-DD');

  return await (await getGatewayMapsApi())
    .getHeatmap(startDate, stopDate, vehicleId)
    .then(res => res.data)
    .then(data => ({ track: data }))
    .catch(e => ({ track: [] }));
};

const fetchMapLiveData = async (vehicleIds) => {
  return await (await getGatewayMapsApi())
    .getLivePosition(vehicleIds)
    .then(res => res.data)
    .catch(err => {
      console.dir(err);
      return [];
    });
};

/**
 *
 * @returns {Promise<any>}
 */
const getFaqs = async () => await axios.get(`${FVM_API_URL}/faq`, {
  // headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
}).then(res => res.data);

/**
 *
 * @returns {Promise<any>}
 */
const getUserList = async () => await axios.get(`${FVM_API_URL}/userAdministration`, {
  headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
}).then(res => res.data);

/**
 *
 * @returns {Promise<any>}
 */
const getUserTranslations = async () => await axios.get(`${FVM_API_URL}/my-nrail-translation`, {
  headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
}).then(res => res.data);

/**
 *
 * @param userId
 * @returns {Promise<any>}
 */
const getUserLocoList = async (userId) => {
  await axios.get(`${FVM_API_URL}/usertrainsbyid?user_id=${userId}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
  }).then(res => res.data);
};

/**
 *
 * @returns {Promise<any>}
 */
const deployLanguageChanges = async () => await axios.get(`${FVM_API_URL}/deploy-language-files/`, {
  headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
}).then(res => res.data);

/**
 *
 * @returns {Promise<any>}
 */
const deployMyNrailApplication = async () => await axios.get(`${FVM_API_URL}/deploy-mynrail-app/`, {
  headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
}).then(res => res.data);

/**
 *
 * @returns {Promise<*>}
 */
const getMyUserRole = async () => axios.get(`${FVM_API_URL}/whats-my-user-role/`, {
  headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
}).then(res => res.data);

/**
 *
 * @param de
 * @param en
 * @param key
 * @returns {Promise<any>}
 */
const updateTranslationEntry = async ({ de, en, key }) => await axios.post(`${FVM_API_URL}/my-nrail-translation`, {
  de,
  en,
  key,
}, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}`,
  },
}).then(res => res.data);

/**
 *
 * @param userData
 * @returns {Promise<any>}
 */
const updateUserDateSetting = async (userData) => {
  const { userId, vehicleId, startDate, endDate } = userData;

  const data = {
    'user_id': userId,
    'train_id': vehicleId,
    'start_date': startDate ? moment(startDate, 'DD.MM.YYYY').format('DD-MM-YYYY HH:mm:ss') : '',
    'end_date': endDate ? moment(endDate, 'DD.MM.YYYY').format('DD-MM-YYYY HH:mm:ss') : '',
  };

  return await axios.post(`${FVM_API_URL}/user/user-trains-date-update`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
  }).then(res => res.data);
};

/**
 *
 * @returns {Promise<{myUserRole: *, userIsAllowed: *}|{myUserRole: null, userIsAllowed: boolean}>}
 */

const isUserPermittedToSeePage = async () => {
  const resp = await axios.get(`${FVM_API_URL}/user/view-permitted`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}`,
    },
  });

  const { userIsAllowed, myUserRole } = resp.data;

  if (userIsAllowed && myUserRole) {
    return { userIsAllowed, myUserRole };
  }
  return { userIsAllowed: false, myUserRole: null };
};

/**
 *
 * @returns {Promise<{trackable: *}>}
 */
const fetchNrailUserSettings = async () => {
  const resp = await axios.get(`${FVM_API_URL}/user/view-permitted`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}`,
    },
  });

  const { trackable } = resp.data;
  return { trackable };
};

/**
 *
 * @param data
 * @returns {Promise<{msg: *, deploymentStatus: *}|{msg: null, deploymentStatus: []}>}
 */
const updateDeployment = async (data) => {
  const resp = await axios.post(`${FVM_API_URL}/user/deployment-status`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
  });

  const { deploymentStatus, msg } = resp.data;

  if (deploymentStatus || msg) {
    return { deploymentStatus, msg };
  }
  return { deploymentStatus: [], msg: null };
};

/**
 *
 * @param verNos
 * @returns {Promise<{msg: *, versionNos: *}|{msg: null, versionNos: []}>}
 */
const updateVersionNo = async (verNos) => {
  const data = { ...verNos };

  const resp = await axios.post(`${FVM_API_URL}/user/version-number`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
  });

  const { versionNos, msg } = resp.data;
  if (versionNos || msg) {
    return { versionNos, msg };
  }
  return { versionNos: [], msg: null };
};

/**
 *
 * @param informUser
 * @param msgLinedToReleased
 * @param showUserMsg
 * @param disableDismissButton
 * @param period
 * @returns {Promise<{msg: null, versionNos: []}|*>}
 */
const updateUserInteraction = async (informUser, msgLinedToReleased, showUserMsg, disableDismissButton, period) => {
  const data = { informUser, msgLinedToReleased, showUserMsg, disableDismissButton, period };

  const respData = await axios.post(`${FVM_API_URL}/user/update-user-interaction`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
  }).then(({ data }) => data);
  /** @namespace respData.usrInteraction Information data to show in message box **/
  if (respData && respData.usrInteraction) {
    return respData.usrInteraction || {};
  }
  return { versionNos: [], msg: null };
};

/**
 *
 * @returns {Promise<{msg: *, deploymentStatus: *, backupFiles: {}}|{msg: null, approvedBy: []}>}
 */
const getDeploymentStatus = async () => {
  const resp = await axios.get(`${FVM_API_URL}/user/deployment-status/`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
  });

  const { deploymentStatus, msg } = resp.data;
  let { backupFiles } = resp.data;

  if (!backupFiles) {
    backupFiles = {};
  }

  if (deploymentStatus || msg) {
    return { deploymentStatus, backupFiles, msg };
  }
  return { approvedBy: [], msg: null };
};

/**
 *
 * @returns {Promise<{msg: *, versionNos: *}|{msg: null, versionNos: {}}>}
 */
const getVersionNumber = async () => {
  const resp = await axios.get(`${FVM_API_URL}/user/version-number/`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
  });

  const { versionNos, msg } = resp.data;
  if (versionNos || msg) {
    return { versionNos, msg };
  }
  return { versionNos: {}, msg: null };
};

/**
 *
 * @param versionNo
 * @param lstRelNot
 * @returns {Promise<any>}
 */
const shouldUserReload = async (versionNo, lstRelNot = '') => {
  const resp = await axios.get(`${FVM_API_URL}/should-user-reload?my_ver_no=${versionNo.trim()}&lst_notes=${lstRelNot.trim()}`, {
    // headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
  });

  return { ...resp.data };
};

/**
 *
 * @param fileName
 * @returns {Promise<{msg: null, showReloadCard: boolean}|{showReloadCard: any}>}
 */
const restoreSiteFromServer = async (fileName) => {
  const resp = await axios.get(`${FVM_API_URL}/restore-site?file_name=${fileName}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('fvm_wp_api_token')}` },
  });

  const showReloadCard = resp.data;
  if (showReloadCard) {
    return { showReloadCard };
  }
  return { showReloadCard: false, msg: null };
};


export {
  FVM_BASE_URL,
  USER_LANG,
  fetchSTECDashboardData,
  fetchSTECDetailedReport,
  fetchSTECSimpleData,
  fetchSTECLiveData,
  fetchSTECReporting,
  fetchGraphReport,
  fetchSTECNotifications,
  getFaqs,
  getUserList,
  getUserLang,
  getUserTranslations,
  getUserLocoList,
  updateUserDateSetting,
  updateTranslationEntry,
  deployLanguageChanges,
  deployMyNrailApplication,
  fetchNrailUserSettings,
  isUserPermittedToSeePage,
  getMyUserRole,
  getDeploymentStatus,
  getVersionNumber,
  updateDeployment,
  updateVersionNo,
  updateUserInteraction,
  shouldUserReload,
  restoreSiteFromServer,
  fetchPieChartData,
  fetchHeatMapData,
  fetchTrackingMapData,
  fetchDatasheet,
  fetchUserDevices,
};