import React, { Fragment } from 'react';
import {
  Button,
  FormControl,
  Modal,
  Paper,
  withStyles,
  Checkbox,
  Typography, FormControlLabel, Box, Switch,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SignaturePad from 'react-signature-pad-wrapper';
import NrlPrimaryButton from '../core/NrlPrimaryButton';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { logbookSignatureSchema } from '../logbook-utils/validation-schema';
import BannerNotification from '../../BannerNotification';

const useStyles = makeStyles(theme => ({
  sectionLabel: {
    marginTop: 16,
    marginBottom: 16,
  },
  displayValidSigModal: {
    display: 'flex',
    justifyContent: 'center',
  },
  textFieldFormControlStyles: {
    // marginTop: 8,
    // marginBottom: 8,
  },
  checkBoxFormGroup: {
    marginLeft: 3,
    '& label': {
      marginBottom: 0,
    },
  },
  modalTitle: {
    padding: '16px 24px',
  },
  saveButtonContainer: {
    position: 'relative',
    justifyContent: 'space-between',
    padding: '16px 24px',
    alignItems: 'center',
    '& div > button': {
      marginRight: 16,
    }
  },
  validSignature: {
    height: '100%',
    width: '100%',
    // backgroundRepeat: 'no-repeat, repeat',
    // backgroundPosition: 'center',
    // backgroundColor: ' #dfe3e6',
    display: 'flex',
    justifyContent: 'center',
  },

  messageNotificationStyles: {
    // position: 'absolute',
    width: '100%',
    padding: '4px 16px !important',
    // marginLeft: -24,
    // top: '-42px'
  },
  defaultMessageNotificationStyles: {
    width: '100%',
    padding: '4px 16px !important',
  },
  signatureImage: {
    height: 'auto',
    maxWidth: '300px',
  },

  signatureModalContainer: {
    backgroundColor: '#FFFFFF',
  },
  signatureActionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #0000001F',
    '& > *': {
      margin: '16px',
      alignSelf: 'end',
    },
  },
  signatureAgreement: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '6px',
    '& label': {
      margin: 0
    }
  },
  rightButtonsWrapper: {
    '&>*': { marginLeft: '16px' },
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: 768,
    backgroundColor: '#FFFFFF',
    border: '1px solid #0000001F',
    boxShadow: theme.shadows[5],
    '& > canvas': {
      backgroundColor: '#e2e2e294',
      borderBottom: '1px solid #b7b7b794',
      borderTop: '1px solid #b7b7b794',
    },
  },

  [theme.breakpoints.up(768)]: {
    timeInputGridItem: {
      maxWidth: '20%',
      flexBasis: '20%',
    },
  },
}));

const SignAndSubmit = ({
  className = '',
  isReadOnly = true,
  formFields = true,
  allowSave,
  handleSave,
  isSendOptionalNotification,
  showDefaultNotificationMsg,
  showSendNotificationCheckbox,
  toggleSendNotification,
  onSaveSig,
  hasErrors = () => false,
  disabled = false,
  ...props
}) => {
  const classes = useStyles();
  const { t } = props;

  const signatureForm = useForm({
    mode: 'all',
    defaultValues: {
      ...formFields,
    },
    resolver: joiResolver(logbookSignatureSchema, {
      abortEarly: false,
    }),
  });

  const handleSubmit = (...inputArgs) => {
    signatureForm.handleSubmit(onSubmit)(...inputArgs);
  };

  const onSubmit = async (data) => {
    onSaveSig(data);
  };
  return (
    <Grid className={`${classes.saveButtonContainer} ${className}`} container item spacing={2}>
      <Grid item hidden={!showDefaultNotificationMsg && !isSendOptionalNotification} xs={12}>
        {
          showDefaultNotificationMsg && !isSendOptionalNotification ?
            <BannerNotification classNames={classes.defaultMessageNotificationStyles} message={t('labels.subscribersWillBeNotifiedDefault')} />
            : null
        }
        {
          isSendOptionalNotification && !showDefaultNotificationMsg ?
            <BannerNotification classNames={classes.messageNotificationStyles} message={t('labels.subscribersWillBeNotified')} />
            : null
        }
      </Grid>
      {isReadOnly ? null : (
        <Grid item>
          <NrlPrimaryButtonWhite
            variant="contained"
            size="small"
            disabled={!allowSave || disabled}
            onClick={handleSave}
          >
            {t('button.save')}
          </NrlPrimaryButtonWhite>
          <SignatureInput
            buttonText={t('button.finalise')}
            onSaveSig={handleSubmit}
            disabled={disabled}
            hasErrors={hasErrors}
            t={t}
            handleChange={(sig) => signatureForm.setValue('signatureImage', sig)}
          />
        </Grid>

      )}
      {
        !showDefaultNotificationMsg && showSendNotificationCheckbox ?
          <Grid item spacing={2}>
            <FormControlLabel
              className={classes.switch}
              labelPlacement="end"
              control={
                <Switch
                  checked={isSendOptionalNotification}
                  onChange={toggleSendNotification}
                  name="isSendNotification"
                  color="primary"
                  disabled={isReadOnly}
                />
              }
              label={
                <Typography
                  variant="p"
                  component={'span'}
                  className={classes.sectionLabel}
                >
                  {t('labels.sendNotification')}
                </Typography>
              }
            />
          </Grid>
          : null
      }

    </Grid>
  );
};

export const SignatureInput = ({
  handleChange,
  disabled = false,
  onSaveSig,
  buttonText,
  hasErrors = async () => false,
  addCommentForm = () => { },
  t,
  entry = {}
}) => {
  const classes = useStyles();
  const [isSigning, setIsSigning] = React.useState(false);
  const [signaturePadRef, setSignaturePadRef] = React.useState(false);
  const [padHasData, setPadHasData] = React.useState(false);
  const [signCheck, setSignCheck] = React.useState(false);
  const [finalSubmission, setFinalSubmission] = React.useState(false);

  return (
    <Fragment>
      <NrlPrimaryButtonWhite
        variant="contained"
        disabled={disabled}
        size="small"
        onClick={() => {
          if (typeof hasErrors === 'function') {
            hasErrors().then((err) => {
              if (!err) {
                if (!!Object.keys(addCommentForm).length && !addCommentForm?.getValues()?.newEntryState && entry?.defects !== null) {
                  addCommentForm?.trigger('newEntryState', { shouldFocus: true });
                } else {
                  console.log(err);
                  setIsSigning(true);
                }
              }
            });
          }
        }}
      >
        {buttonText || t('button.save')}
      </NrlPrimaryButtonWhite>
      <Modal
        open={isSigning}
        onClose={() => {
        }}
        className={`${classes.modal} fvm-modal-tag`}
      >
        <Paper className={classes.paper}>
          <Typography className={classes.modalTitle} variant="h6">
            {t('labels.signatureModalTitle')}
          </Typography>
          <SignaturePad
            ref={ref => {
              setSignaturePadRef(ref);
              if (ref) {
                ref.onEnd = () => {
                  setPadHasData(true);
                  handleChange(signaturePadRef.toDataURL());
                };
              }
            }}
            redrawOnResize={true}
          />
          <div className={classes.signatureAgreement}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => setSignCheck(!signCheck)}
                  checked={signCheck}
                  disabled={!padHasData}
                  color="primary"
                />
              }
              label={t('labels.signatureCheckbox')}
            />
          </div>
          <div className={classes.signatureAgreement}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => setFinalSubmission(!finalSubmission)}
                  checked={finalSubmission}
                  disabled={!padHasData}
                  color="primary"
                />
              }
              label={t('labels.finalSubmissionConfirmation')}
            />
          </div>

          <div className={classes.signatureActionButtons}>
            <Button
              variant="outlined"
              size="small"
              disabled={!padHasData}
              onClick={e => {
                e.stopPropagation();
                signaturePadRef.clear();
                setPadHasData(false);
                setSignCheck(false);
                setFinalSubmission(false);
              }}
              color="primary"
            >
              {t('button.clearSignature')}
            </Button>
            <div className={classes.rightButtonsWrapper}>
              <NrlPrimaryButtonWhite
                variant="contained"
                size="small"
                disabled={!signCheck || !padHasData || !finalSubmission}
                onClick={e => {
                  e.stopPropagation();
                  if (!signaturePadRef.isEmpty()) {
                    onSaveSig();
                    setIsSigning(false);
                    setPadHasData(false);
                    setSignCheck(false);
                    setFinalSubmission(false);
                  }
                }}
                color="primary"
              >
                {t('button.save')}
              </NrlPrimaryButtonWhite>
              <Button
                variant="outlined"
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  setIsSigning(false);
                  setPadHasData(false);
                  setSignCheck(false);
                  setFinalSubmission(false);
                }}
                color="primary"
              >
                {t('button.cancel')}
              </Button>
            </div>
          </div>
        </Paper>
      </Modal>
    </Fragment >
  );
};

const NrlPrimaryButtonWhite = withStyles(theme => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: '#06DE3A',
    '&:hover': {
      backgroundColor: '#06DE3A',
    },
  },
}))(Button);

SignAndSubmit.propTypes = {};

export default SignAndSubmit;
